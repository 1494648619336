import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService {

    apiUrl = environment.apiUrl;

    constructor(private http: HttpClient) {
        const temp = new HttpHeaders({'Accept-Encoding':'gzip, deflate, br'});
    }

    claimDaily(params) : any {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/users/claimDaily', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    getDaily(params) : any {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/users/getDaily', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    getGlobalMod() : any {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.apiUrl + '/data/globalMod')
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    getConfig() : any {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.apiUrl + '/users/getConfig')
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    getRules(u,t) : any {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/users/getRules', { user: u, type: t })
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    rerollRules(params) : any {
        let promise = new Promise((resolve, reject) => {
            console.log(this.apiUrl + 'users/rerollRules', params);
            this.http.post(this.apiUrl + '/users/rerollRules', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    verifyUser(u) : any {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.apiUrl + '/users/verify?user=' + u)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    getUserAirdropInfo(u) : any {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.apiUrl + '/users/userAirdropInfo?user=' + u)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    getScTx(user) {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.apiUrl + '/users/getScTx?user=' + user)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    loadEventPoints(params) {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/users/eventPoints', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    login(u,m,tos) : any {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/users/authenticate', { username: u, memo: m, accepted: tos })
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    keyLogin(user, key) {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/users/keyLogin', { username: user, key: key })
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    refreshUser(params) {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/users/refresh', { username: params.name, token: params.token })
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    requestEncodedMessage(user, source, type) {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.apiUrl + '/users/requestEncodedMessage?user=' + user + '&source=' + source + '&type=' + type)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    verifyDecodedMessage(user, message, source) {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.apiUrl + '/users/verifyDecodedMessage?user=' + user + '&message=' + message + '&source=' + source)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    pollNotifications(user) {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.apiUrl + '/users/checkForNotifications?user=' + user)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    changeFlag(params) {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/users/changeFlag', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }
}
