import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from './_models/user';
import { DataService } from './_services/data.service';
import { HelperService } from './_services/helper.service';
import { environment } from '../environments/environment';
import { AuthService } from './_services';
import { fade, flip_up, pointer } from './app.animations';
import { SoundsService } from './_services/sounds.service';
@Component({ 
  selector: 'app', 
  animations: [fade, flip_up, pointer],
  providers: [ SoundsService ],
  templateUrl: 'app.component.html', 
  styleUrls: ['./app.component.styl'] 
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    constructor(private data: DataService, 
                public helper: HelperService, 
                private auth: AuthService,
                private sound: SoundsService,
                private cdr: ChangeDetectorRef) 
    {
      this.data.onLoadingChange().subscribe(l => this.loading = l);
      this.data.onUserChange().subscribe(u => this.currentUser = u);
      this.data.onSnackbarMessageChange().subscribe(m => this.snackbarMessage = m);
    }
    currentUser: User;
    timeInterval: Subscription;
    loading: any = false;
    showDonate = false;
    useStamina = false;
    loginModal = false;
    noKeychainModal = false;
    requested = false;
    moveSC = false;
    acceptedToS = false;
    needToS = false;
    username: string;
    LoginSatatus: string = '';
    snackbarMessage: string = '';
    notification = [];
    stam_regen: any;
    socket: any;
    balance: any;
    poller: any;
    hf:boolean;
    showNotifs:boolean;
    login_error:boolean;
    apiURL = environment.apiUrl;
    showStaminaWindow() {
      this.useStamina=true;
    }
    ngOnInit() {
    }
    page_clicked(e?) {}
    ngAfterViewInit() {
    }
    ngOnDestroy() {
      //this.socket.disconnect();
    }
    ngAfterContentChecked(): void {
      this.cdr.detectChanges();
    }
    show_modal: boolean;
    modal_title = 'Success';
    modal_desc = 'Successfully loaded.';
    showModal(title, desc, cb?) {
      this.show_modal = true;
      this.modal_title = title;
      this.modal_desc = desc;
      if(cb)cb();
    }
    hideModal() {
      this.show_modal = false;
      this.modal_title = '';
      this.modal_desc = '';
    }
    hide_login(e?) {
      this.loginModal=false;
      this.helper.login_results='';  
    }
    getImgURL(type) { return this.apiURL + '/' + type; }
    async openLoginModal() {
        let ready = await this.helper.checkKeychain();
        if (ready) {
          this.loginModal = true;
          //console.log('loginModal');
        } else { 
          this.noKeychainModal = true;
          //console.log('noKeychainModal');
        }
    }
    hide_snackbar() {
      this.helper.hideSnackbarMessage();
    }
    async getHEBalances() {
      let temp = await this.helper.loadBalances(this.username);
      this.balance = temp.find(b => b.symbol === 'FORGE');
    }
    async login_done(e?) {
      if(e==1){
        this.loginModal=false;
        await this.getHEBalances();
        this.helper.getSFCards();  
        this.helper.getSFCardDetails();
        if (this.helper.detail_id) {
          this.helper.navigate('cards'); //, this.helper.detail_id);
        }
      }
    }
    done_noKeychainModal(e?) {
      this.noKeychainModal=false;
    }
    disabledAlert() {
      alert('Sorry! This feature is temporarily disabled.');
    }
    async useConsumable(item) {
      this.data.setLoading(true);
      let temp = await this.helper.useConsumable(item, 1);
      this.data.setLoading(false);
    }
    ack(notification) {
      //this.notification = this.notification.filter(n => n !== notification);
    }
    gourl(url) { open(url); }
    go(notification) {
      //console.log('notification', notification);
      this.notification = this.notification.filter(n => n !== notification);
      if (notification?.loc !== '') this.navigate(notification.loc);
    }
    openTab(event, tabName) {
      this.helper.openTab(event, tabName);
    }
    navigate(loc) {
      this.helper.navigate(loc);
    }
}
