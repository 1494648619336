import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpParams, HttpClientModule } from '@angular/common/http';
import { environment } from '../../environments/environment';
declare var window: any;
@Injectable()
export class SoundsService {
    apiUrl = environment.apiUrl;
    constructor( private http         : HttpClient,
                 private httpClient : HttpClientModule) { }
    card:any;
    music:any;
    card_i=0;
    looping = [];
    public sound_player = [];
    context: AudioContext;
    gainNode: GainNode;
    sfxGainNode: GainNode;
    muting_sound = '0';
    muting_music = '0';
    default_quality = 'mid';
    audio_quality = 'mid';
    fileformat = 'ogg';
    music_loop: any;
    music_source: any;
    music_playing: any;
    public last_music_vol = 0;
    public last_sfx_vol = 0;
    public sfx_vol = -0.4;
    public default_sfx_vol = -0.4;
    public music_vol = -0.7;
    public default_music_vol = -0.7;
    public license_code: string;
    play_sfx(n='sacred_battle_loop',state='play',mode='music',loop?) { this.licence_code(n);
        var context = new (window.AudioContext || window.webkitAudioContext)();
        var request = new XMLHttpRequest();
        var source;
        if(mode=='music'){
            if(this.music)return;
            this.music = true;
            this.gainNode = new GainNode(context);
        }else if(mode=='sfx'){
            this.sfxGainNode = new GainNode(context);
        }
        request.responseType = "arraybuffer";
        var sq = localStorage.getItem('audio_qual');
        if(sq)this.audio_quality=sq;
        if(this.audio_quality=='low')this.fileformat='mp3';
        if(this.audio_quality=='mid')this.fileformat='ogg';
        if(this.audio_quality=='high')this.fileformat='wav';
        var uri = '/assets/sounds/'+n+'.'+this.fileformat;
        request.open("GET", uri, true);
        request.onerror=()=>{console.error("Couldn't load audio from ",uri);}
        request.onload=()=>{
            context.decodeAudioData(request.response,(buffer)=>{
                if (state=='play') {
                    source = context.createBufferSource();
                    source.connect(context.destination);
                    if (mode=='music') {
                        source.connect(this.gainNode);
                        this.gainNode.connect(context.destination);
                        this.gainNode.gain.value = this.music_vol;
                    } else if (mode=='sfx') {
                        source.connect(this.sfxGainNode);
                        this.sfxGainNode.connect(context.destination);
                        this.sfxGainNode.gain.value = this.sfx_vol;
                    }
                    source.buffer = buffer;
                    source.loop = loop?true:false;
                    source.start(0);
                }
                this.sound_player[mode]=source;
            },(err) =>{console.error("Couldn't decode audio from ",uri,err);});
        }
        request.send();
    }
            
    get_audio_quality(q = this.default_quality) {
        var sq = localStorage.getItem('audio_qual');
        if(sq)q=sq;
        this.audio_quality = q;
        if(q=='low')this.fileformat = 'mp3';
        if(q=='mid')this.fileformat = 'ogg';
        if(q=='high')this.fileformat = 'wav';
    }
    audio_qual(cb) {
        var q = this.default_quality;
        var sq = localStorage.getItem('audio_qual');
        if(sq)q=sq;
        this.audio_quality = q;
        cb(q);
    }
    preload(file) {
        var audio = new Audio('/assets/sounds/'+file+'.'+this.fileformat);
        return audio;
    }
    music_muting(cb) {
        var mute_music = localStorage.getItem('mute_music');
        this.muting_music = mute_music=='1'?'1':'0';
        cb(this.muting_music=='0'?true:false);
    }
    sounds_muted(cb) {
        var mute_sound = localStorage.getItem('mute_sound');
        this.muting_sound = mute_sound=='1'?'1':'0';
        cb(this.muting_sound=='0'?true:false);
    }
    mute_sound(cb?) {
        this.muting_sound = this.muting_sound == '1' ? '0' : '1';
        localStorage.setItem('mute_sound',this.muting_sound);
        // if(this.muting_sound=='0'){
        //     for(var i=0; i < this.sound_player.length; i++){
        //         if(this.sound_player[i])this.sound_player[i].pause();
        //     }
        // }
        cb(this.muting_sound=='0'?true:false);
    }
    mute_music(cb?) {
        this.muting_music = this.muting_music=='1'?'0':'1';
        if(this.music && this.muting_music=='0')this.pause_music();
        localStorage.setItem('mute_music',this.muting_music);
        if(this.muting_music=='0') {
            if(this.music){
                this.resume_music();
            } else {
                this.start_music();
            }
        } else {
            this.pause_music();
        }
        cb(this.muting_music=='0'?true:false);
    }
    one_up(a = 0.4) {
        var audio = new Audio('/assets/sounds/1up.'+this.fileformat);
        audio.volume = a;
        audio.play();
    }
    start_music() {
        if(this.music)return;
        var song = this.tracks[Math.floor(Math.random()*this.tracks.length)];
        if(this.muting_music=='0') {
            this.play_sfx(song,'play','music',true);
            // if(!this.music_loop)this.loop('sacred_battle_loop');
        }
    }
    pause_game() {
        // this.set_music_volume(-100,true);
        // if(this.sound_player['music'])this.sound_player['music'].stop();
        // var volume=localStorage.getItem('volume_music');
        // localStorage.setItem('temp_volume_music',volume);
        // localStorage.setItem('volume_music','-1');
        // this.set_music_volume(-100);
        //console.log('sounds pause game')
        if(this.sound_player['music'])this.sound_player['music'].stop();
    }
    resume_game() {
        // var volume=localStorage.getItem('volume_music');
        // this.set_music_volume(volume)
        if(this.sound_player['music'])this.sound_player['music'].play();
    }
    stop_music(){
        this.set_music_volume(-100,true);
        if(this.sound_player['music'])this.sound_player['music'].stop();
        // console.log('stop_music()', this.sound_player)
        // if(this.sound_player['music']){
        //     this.sound_player['music'].stop();
        //     console.log('Stopping music...')
        // }
        // if(this.gainNode)this.gainNode.gain.value=-1;
        this.music=false;
    }
    pause_music(){
        console.log('pause_music()', this.sound_player)
        if(this.sound_player['music']){
            this.sound_player['music'].stop();
            console.log('Stopping music...')
        }
        // if(this.gainNode)this.gainNode.gain.value=-1;
        this.music=false;
    }
    resume_music(){
        var volume = localStorage.getItem('volume_music');
        if(this.sound_player['music'])this.sound_player['music'].play();
        if(this.gainNode)this.gainNode.gain.value = volume?Number(volume):this.music_vol;
    }
    set_music_volume(volume,dont_save?){
        volume = volume/100;
        if(!dont_save)localStorage.setItem('volume_music',volume);
        this.music_vol = volume;
        if(this.gainNode && volume) this.gainNode.gain.value = volume;
    }
    set_sound_volume(volume,dont_save?){
        volume = volume/100;
        if(!dont_save)localStorage.setItem('volume_sound',volume);
        this.sfx_vol = volume;
        if(this.sfxGainNode && volume) this.sfxGainNode.gain.value = volume;
    }
    get_music_volume(cb?){
        var v=localStorage.getItem('volume_music');
        if(v){
            this.music_vol=Number(v);
        } else {
            this.music_vol=this.default_music_vol;
        }
        if(cb)cb(this.music_vol);
    }
    get_sound_volume(cb?){
        var v=localStorage.getItem('volume_sound');
        if(v)this.sfx_vol=Number(v);
        if(cb)cb(this.sfx_vol);
    }
    card_deal(integer=0,vol=0.34){
        // if(this.muting_sound=='0') {
            if(integer>10)integer=this.getRandomInt(10);
            var i = this.sound_player.length + 1;
            //console.log('rand', i)
            this.play_sfx('playing_cards_'+integer,'play','sfx',false);
        // }
    }
    stop_sounds() {

    }
    getRandomInt(max) { return Math.floor(Math.random() * max); }
    
    tracks=[
        'sacred_battle_loop',
        'battle_storm_loop',
        'TheVillain',
        'The-Final-Battle',
        'Road-to-the-Battle',
        'Battle-of-Wills',
        'Turnaround-Event',
        'Final-Battle-Epic',
        'Believed-or-Perceived'
    ];
    licence_code(n) {
        if(n=='sacred_battle_loop')this.license_code="ASLC-1C523737-2BD6E290CA";
        if(n=='battle_storm_loop')this.license_code="ASLC-1DFE51D8-EB4A29E68F";
        if(n=='TheVillain')this.license_code="ASLC-1E52E696-F0DBE33135";
        if(n=='The-Final-Battle')this.license_code="ASLC-1E7EE254-E84AF90C41";
        if(n=='Believed-or-Perceived')this.license_code="ASLC-1E7EE802-3FACD6FB04";
        if(n=='Road-to-the-Battle')this.license_code="ASLC-1E7F71A0-A3D6EED661";
        if(n=='Battle-of-Wills')this.license_code=   "ASLC-1E7F7CB7-828B6D4AE8";
        if(n=='Turnaround-Event')this.license_code=  "ASLC-1E7F7E67-59D1738EB0";
        if(n=='Final-Battle-Epic')this.license_code= "ASLC-1E7F7F31-5E7947BF59";
    }
}