import { Component, OnInit, ChangeDetectorRef, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { loader } from '../slcards.animations';
import { fade, fade_long, enter_down, width_in } from '../../app.animations';
@Component({
  selector: 'card-abilities',
  animations: [loader, fade, fade_long, enter_down, width_in],
  templateUrl: './abilities.component.html',
  styleUrls: ['./abilities.component.styl']
})
export class StatsAbilitiesComponent implements OnInit, OnChanges {
  constructor(private changeDetection: ChangeDetectorRef) { }
  loaded = [];
  loaded_a = [];
  heroAbilities = ["Life Force","Fire Force","Earth Force","Death Force","Water Force","Dragon Force","Mana Mastery","Vassal Mastery","Critical Strike","Hearts Blood"];
  detailed = [];
  smaller_stats: boolean;
  @Input('show_card_abilities') show_card_abilities = true;
  @Input('abilities') abilities: any;
  @Input('name') name: any;
  @Input('just_image') _img: boolean;
  @Input('smaller') smaller: boolean;
  @Input('hero') hero: boolean;
  @Input('rules') rules: boolean;
  @Input('boss') boss: boolean;
  @Input('only_effects') effects: boolean;
  @Output('hoverstate') hoverstate = new EventEmitter();
  ngOnInit() {
    this.get_abilities();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.abilities) this.get_abilities();
  }
  get_abilities() {
    this.loaded = [];
    this.loaded_a = [];
    this.detailed=[];
    //console.log(this.abilities);
    if (this.abilities && this.abilities.length > 0) {
      this.smaller_stats = this.abilities.length > 3 ? true : false;
      for (let i = 0; i < this.abilities.length; i++) {
        if (this.abilities[i]) {
          var img_url = this.getImgURL(this.abilities[i]);
          var img = {
            ability: this.abilities[i], 
            imgURL: img_url
          };
          this.detailed.push(img);
        }
        if(i==this.abilities.length-1) {
          this.changeDetection.detectChanges();
        }
      }
    }
  }
  _m(i){this.hoverstate.emit(i);}
  getImgURL(a) {
    //if (a.indexOf('-') > -1 || a.indexOf('+') > -1) console.log(a);
    let ret = '';
    if(!a)return '';
    if (this.heroAbilities.includes(a)) return 'https://splinterforge.s3.us-east-2.amazonaws.com/' + a + ' Rune.png';
    else {
      if (a === '1.5x DMG') return 'https://splinterforge.s3.us-east-2.amazonaws.com/abilities/multiplier.png';
      else if (a === 'Against All Odds') return 'https://splinterforge.s3.us-east-2.amazonaws.com/' + a + '.png';
      else if (a === 'Even Odds') return 'https://splinterforge.s3.us-east-2.amazonaws.com/' + a + '.png';
      else if (a === 'Fallen Legendaries') return 'https://splinterforge.s3.us-east-2.amazonaws.com/' + a + '.png';
      else if (a === 'Peasant Infantry') return 'https://splinterforge.s3.us-east-2.amazonaws.com/' + a + '.png';
      else if (a === 'Training Crew') return 'https://splinterforge.s3.us-east-2.amazonaws.com/' + a + '.png';
      else return 'https://d36mxiodymuqjm.cloudfront.net/website/abilities/ability_' + a.toString().replace(' ', '-').toLowerCase() + '.png';
    }
  }
}