<section class="forgemodal" [@fade]="show">
	<div class="boxwrap" [@flip_up]="show">
		<h1 *ngIf="!resurrected">Dead Monsters, you have {{credits}} rez credits</h1>
		<h1 *ngIf="resurrected">Resurrection Successful!</h1>
		<h5 *ngIf="!resurrected">Rez specific monster for 2 rez credits, click on desired monster to select.</h5>
		<h5 *ngIf="!resurrected">Rez random monster for 1 rez credit. <button class="button purple" (click)="rez('random')">RANDOM Rez</button></h5>
		<div class="itemwrapper" *ngIf="!resurrected">
			<div class="monster card_wrapper" *ngFor="let card of dead" (click)="rez(card)">
				<slcard-selector [card]="card" [fav]="false"></slcard-selector>
			</div>
		</div>
		<div class="itemwrapper" *ngIf="resurrected">
			<div class="monster card_wrapper">
				<slcard-selector [card]="resurrected" [fav]="false"></slcard-selector>
			</div>
		</div>
		<button class="button purple" (click)="close()">Close</button>
		<div class="closewrapper" (click)="close()"><i class="fa-solid fa-x"></i></div>
    </div>
	<div class="backdrop" [@pointer]="show" (click)="close()"></div>
</section>