<accordion [showArrows]="true" [closeOthers]="true" [expandAll]="false">
    <accordion-group heading="Do I need to create an account?">
        No, your Splinterlands account is a full Hive account and you can log in here with keychain.
    </accordion-group>
    <accordion-group heading="Can I get Forgium from anywhere other than fighting or Boss Defeated Rewards?">
        When the game goes live, Forgium are a Hive-Engine token and will be able to be traded on Hive-Engine. NOTE: during testing, fake tokens are used.
    </accordion-group>
    <accordion-group heading="Are you part of the Splinterlands team?">
        No, we are a 3rd party site where you can use your Splinterlands cards, we are however open to future collaboration with the Splinterlands team.
    </accordion-group>
</accordion>