import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { loader } from '../slcards.animations';
import { fade, flip_up, flip, display_animation } from '../../app.animations';
import { dropdown } from '../../hero/hero.animations';
@Component({
  selector: 'sl-card-filters',
  animations: [loader, fade, flip, dropdown],
  templateUrl: './card-filters.component.html',
  styleUrls: ['../slcards.component.styl','./card-filters.component.styl']
})
export class CardFiltersComponent implements OnInit, OnChanges {
  constructor() { }
  @Input('show_monsters') show_monsters = true;
  @Input('show_summoners') show_summoners = true;
  @Input('show_damage') show_damage: boolean;
  @Input('show_sort') show_sort: boolean;
  @Input('show_abilities') show_abilities: boolean;
  @Input('selectedDamage') selectedDamage: any;
  @Input('selectedAbility') selectedAbility: any;
  @Input('copiedCards') copiedCards: any;
  @Input('summoners_or_monsters') summoners_or_monsters: any;
  @Input('mana_placeholder') mana_placeholder: any;
  @Input('abilities') abilities: any;
  @Input('filters') filters: any;
  @Output('filters') _filters = new EventEmitter();
  @Output('abilities') _abilities = new EventEmitter();
  ngOnInit() {

  }  
  ngOnChanges(event) {
    if(event.filters)this._filters.emit(this.filters);
    if(event.abilities)this._abilities.emit(this.abilities);
    
  }
      clearFilters() {
        this.filters.mana = -1;
        this.filters.ability = 'All';
        this.selectedAbility = 'All';
        this.selectedDamage = 'All';
        this.filters.favorites = false;
    }
      filterByDamage(s?) {
        if(s)this.selectedDamage = s;
        this.show_damage = false;
        this.filters.dmgType = this.filters.dmgType === this.selectedDamage ? 'All' : s;
    }
    filterByMana(n) {
        this.filters.mana === n ? this.filters.mana = -1 : this.filters.mana = n;
    }
  filterByAbility(s?) {
      if(s)this.selectedAbility = s;
      this.show_abilities = false;
      this.filters.ability = this.filters.ability === this.selectedAbility ? 'All' : s;
  }
  filterBySorting(s?, d?) {
      this.show_sort = false;
      this.filters.sort = s;
      this.filters.direction = d;
  }

  filterFavorites() {
    this.filters.favorites = !this.filters.favorites;
  }

  hideFavorites() {
    this.filters.hide = !this.filters.hide;
  }
}