<div *ngIf="loading" class="loader"></div>
<div class="page_heading" *ngIf="!viewCard && pageHeading">
    <div class="bottom_border"></div>
    <div>
        <h1>
            <span class="card_title">{{card&&card.name?card.name:'My Cards'}}</span>
        </h1>
    </div>
    <div class="tab_bar">
        <button [disabled]="loading" [attr.class]="tabName=='SelectedCard'?'tablinks active':'tablinks'"
            (click)="openTab($event, 'SelectedCard')">
            Card
        </button>
        <button *ngIf="card.slot !== 'relic'" [disabled]="loading" [attr.class]="tabName=='Market'?'tablinks active':'tablinks'"
            (click)="openTab($event, 'Market')">
            Market
        </button>
        <button *ngIf="card.type !== 'socket' && card.sockets > 0 && card.slot !== 'relic'" [disabled]="loading"
            [attr.class]="tabName=='Imbue'?'tablinks active':'tablinks'" (click)="openTab($event, 'Imbue');">
            Imbue
        </button>
        <button *ngIf="card.type !== 'socket' && !max_sockets && card.slot !== 'relic'" [disabled]="loading"
            [attr.class]="tabName=='Socket'?'tablinks active':'tablinks'" (click)="openTab($event, 'Socket')">
            Add Socket
        </button>
        <button *ngIf="card.slot === 'relic'" [disabled]="loading"
            [attr.class]="tabName=='Recharge'?'tablinks active':'tablinks'" (click)="openTab($event, 'Recharge')">
            Recharge
        </button>
        <button [disabled]="loading" [attr.class]="tabName=='Stats'?'tablinks active':'tablinks'"
            (click)="openTab($event, 'Stats')">
            Stats
        </button>
        <button [disabled]="loading" class="tablinks red" (click)="close()">
            <i class="fa-solid fa-chevron-left"></i>
            Back
        </button>
    </div>
</div>
<success-modal [show]="showSuccessModal" [title]="successModalTitle" [label]="successModalMessage"
    (close)="showSuccessModal=false">
</success-modal>
<notice-modal [label]="message" [title]="modal_title" [show]="show_modal" [mode]="modal_mode" [button]="modal_button"
    [type]="type" (done)="modal_done($event)">
</notice-modal>
<transfer-card [selected_item]="selected.length>0?selected[0]:card" [show]="transfer_card" [cards]="cards"
    [selected]="selected" [card]="selected.length>0?selected[0]:card" (done)="transfer_card_now($event)">
</transfer-card>
<burn-card [label]="message" [title]="type === 'confirm transfer' ? 'Transfer Card' : type === 'confirm salvage' ? 'Salvage Card' : 'Burn Card'" [cards]="cards"
    [card]="selected.length>0?selected[0]:card" [burn_gems]="selected" [show]="show_burn_modal" [mode]="modal_mode"
    [type]="type" [burnType]="burnType" [burn_value]="burn_value" (done)="modal_done($event);show_burn_modal=false">
</burn-card>
<div class="selectedcardbox">
    <div *ngIf="equippable || equipped">
        <div class="cardgroup">
            <div class="row" *ngIf="equipped">
                <div class="leftside">
                    <div class="selected_card">
                        <h4>Card Equipped</h4>
                        <sfcard-selector class="sfcard" [stackView]="false" [hover]="true" [sfcards]="cards"
                            [sfcard]="equipped" (select_socket)="openTab($event, 'Imbue')">
                        </sfcard-selector>
                    </div>
                    <div class="unequip_button_container">
                        <div [disabled]="loading" class="purple button unequip_button" *ngIf="equipped"
                            (click)="unequip()">Unequip</div>
                    </div>
                </div>
                <div class="rightside" *ngIf="equippable">
                    <h4 class="eligible_cards" *ngIf="equippable.length>0">
                        {{equippable.length}} Equippable Card{{equippable.length==1?'':'s'}}
                    </h4>
                    <!-- <h3>{{equippable.length}} items eligible to be equipped</h3> -->
                    <div>
                        <div class="sfcard" *ngFor="let c of equippable">
                            <sfcard-selector [stackView]="false" [smaller]="smaller" [hover]="true" [sfcards]="cards"
                                [sfcard]="c" (click)="equipCard(c)">
                            </sfcard-selector>
                        </div>
                    </div>
                </div>
            </div>
            <div class="withoutequipped" *ngIf="!equipped && equippable.length > 0">
                <h4 class="equip_cards" *ngIf="equippable.length > 0">
                    {{equippable.length}} Equippable Card{{equippable.length==1?'':'s'}}
                </h4>
                <div class="sfcard" *ngFor="let c of equippable">
                    <sfcard-selector [stackView]="false" [smaller]="smaller" [hover]="true" [sfcards]="cards"
                        [sfcard]="c" (click)="equipCard(c)"></sfcard-selector>
                </div>
            </div>
            <div *ngIf="!equippable || equippable.length < 1 || equippable.length == 0"
                [attr.class]="!equipped?'not_equippable_container full':'not_equippable_container'">
                <p class="not_equippable">You have no items that can be equipped.</p>
                <div class="purple button" (click)="close()">Close</div>
            </div>
            <!-- 
            <button [disabled]="loading" *ngIf="equipped" class="btn" (click)="unequip()">
                Unequip
            </button> 
        -->
            <!-- 
            <button [disabled]="loading" class="btn" (click)="close()">Close</button> 
        -->
        </div>
    </div>
    <div *ngIf="card" class="boxwrap">
        <div class="tab_container">
            <div class="selected_view">
                <div id="SelectedCard" class="tabcontent">
                    <div class="selectedcardcontainer account-card">
                        <sfcard-selector class="sfcard" [sfcards]="cards" [sfcard]="card" [smaller]="smaller"
                            (select_socket)="openTab($event, 'Imbue')" [stackView]="false">
                        </sfcard-selector>
                        <div class="card_details">
                            <div class="pow">
                                Name: 
                                <span class="details_level">
                                    <b>{{card.name}}</b>
                                </span>
                            </div>
                            <div class="pow">
                                Level: 
                                <span class="details_level">
                                    <i class="fa-solid fa-star"></i>
                                    {{card.level}}
                                </span>
                            </div>
                            <div class="pow">
                                Rarity: 
                                <span class="details_rarity">
                                    {{card.rarity}}
                                </span>
                            </div>
                            <div class="pow">
                                Equipped: 
                                <span class="details_equipped">
                                    {{!card.equipped?'No':'Yes'}}
                                </span>
                            </div>
                            <div class="pow">
                                Slot: 
                                <span class="details_slot">
                                    {{card.slot|camelcase}}
                                </span>
                            </div>
                            <div class="pow">
                                Foil: 
                                <span class="details_foil">
                                    {{!card.foil?'None':card.foil}}
                                </span>
                            </div>
                            <div class="pow">
                                Stat: 
                                <span class="details_stat">
                                    {{card.stat}}
                                </span>
                            </div>
                            <div class="pow">
                                ID: 
                                <span class="details_type">
                                    {{card.cid}}
                                </span>
                            </div>
                            <div *ngIf="card.market_type === 'SELL'" class="pow">
                                Market: 
                                <span class="details_type">
                                    Sell: ${{card.market_price}}
                                </span>
                            </div>
                        </div>
                        <button [disabled]="loading" class="change_button btn" (click)="close()">
                            Change Selection
                        </button>
                        <button *ngIf="tabName == 'Imbue'" [disabled]="loading" class="change_button btn" (click)="emptyAllSockets()">
                            Remove All
                        </button>
                    </div>
                    <div *ngIf="tabName=='SelectedCard'" class="upgrade_box">
                        <div class="combinables">
                            <h4 *ngIf="card.type !== 'socket'">Cards eligible to be combined into {{card.name}}</h4>
                            <h4 *ngIf="card.type === 'socket'">Other Gems/Runes of the same rarity</h4>
                            <div class="combination_table">
                                <div class="combination_table_header">
                                    <span class="card_selector">
                                        <i class="fa-solid fa-arrows-left-right"></i>
                                    </span>
                                    <span (click)="checkAll();" class="combination_selector">
                                        <i class="fa-solid ck fa-check"></i>
                                        <i class="fa-regular fa-square-full"></i>
                                    </span>
                                    <span (click)="checkAll();">
                                        <span class="combination_select">Select All</span>
                                    </span>
                                </div>
                                <div *ngFor="let c of combinables">
                                    <div class="combinables_list">
                                        <span class="card_selector" (click)="selectDifferentCard(c)"
                                            *ngIf="combinables.length > 1">
                                            <i *ngIf="c.cid != card.cid" class="fa-solid fa-circle disabled"></i>
                                            <i *ngIf="c.cid == card.cid" class="fa-solid fa-circle-check"></i>
                                        </span>
                                        <span [attr.class]="(c.equipped?'disabled ':'')+'combination_selector'"
                                            (click)="checkSFCardToCombine($event, c)">
                                            <i *ngIf="c.checked" class="fa-solid ck fa-check"></i>
                                            <i class="fa-regular fa-square-full"></i>
                                        </span>
                                        <!-- [attr.class]="'item_title '+(c.cid==card.cid?'bold ':'')+(c.equipped?'disabled ':'')"  -->
                                        <span
                                            [attr.class]="'item_title '+(c.cid==card.cid?'bold ':'')+(c.equipped?'disabled ':'')"
                                            *ngIf="card.type !== 'socket'" (click)="checkSFCardToCombine($event, c)">
                                            <!-- {{c.cid === card.cid ? '*Currently Selected* ' : ''}} -->
                                            <span class="card_level">Level {{c.level}}</span>
                                            <span>
                                                {{c.combined}} card points
                                                {{ (c.sockets>0 ? ' - ' + c.sockets + (c.sockets==1?' socket':'
                                                sockets') : '') }}
                                                {{c.UsedSocketText}}
                                            </span>
                                            <span *ngIf="c.equipped && c.equippedOn!==''">Equipped on {{c.equippedOn |
                                                capitalize}}</span>
                                            <span *ngIf="c.market_type === 'SELL'">Sell: ${{c.market_price}} </span>
                                            <span *ngIf="getCooldown(c)" title="{{getCooldown(c)}}">  <i class="fa">&#9200;</i></span>
                                        </span>
                                        <span
                                            [attr.class]="'item_title '+(c.cid==card.cid?'bold ':'')+(c.equipped?'disabled ':'')"
                                            *ngIf="card.type === 'socket'" (click)="checkSFCardToCombine($event, c)">
                                            {{c.name + (c.equipped ? ' (used in equipment)' : '')}}
                                            <span *ngIf="c.market_type === 'SELL'">Sell: ${{c.market_price}} </span>
                                            <span *ngIf="getCooldown(c)" title="{{getCooldown(c)}}">  <i class="fa">&#9200;</i></span>
                                        </span>
                                        <div class="info_dot">
                                            <i class="fa-solid fa-circle-info"></i>
                                            <div class="hover_info"
                                                [innerHTML]="this.card.cid === c.cid ? 'The currently selected card is the one that other cards will combine into.<br />It will also be the target card for Imbuing Gems/Runes and Adding Sockets.' : 'This card can be chosen to one of the following:<ul><li>Combine into the selected card.</li><li>Burn for tokens.</li><li>Transfer to another player.</li></ul>'">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <input type="text" placeholder="Player HIVE Wallet Address" [(ngModel)]="receiver" /> -->
                        <button *ngIf="card.slot !== 'relic' && card.type !== 'socket' && card.level < 4 && card_eligible['combine']"
                            class="combine_points button blue floatright" (click)="combine()">
                            <i class="fa-solid fa-arrows-to-dot"></i>
                            Combine
                            <span *ngIf="CardPoints">
                                {{CardPoints}}
                            </span>
                            {{CardPoints===1?'point':'points'}}
                        </button>
                        <button *ngIf="card.slot !== 'relic' && card_eligible['burn']" class="burn_card button blue floatright"
                            (click)="burn('FORGE')">
                            <i class="fa-solid fa-fire"></i>
                            Burn
                            <span *ngIf="NumberChecked">
                                {{NumberChecked}}
                            </span>
                            card{{NumberChecked>1?'s':''}}
                        </button>
                        <button *ngIf="card.slot !== 'relic' && card_eligible['transfer']" class="burn_card floatleft button blue"
                            (click)="transferCards()">
                            <i class="fa-solid fa-arrow-right-arrow-left"></i>
                            Transfer
                            <span *ngIf="NumberChecked">
                                {{NumberChecked}}
                            </span>
                            Card{{NumberChecked>1?'s':''}}
                        </button>
                        <button *ngIf="card.slot === 'relic'" class="burn_card floatleft button blue"
                            (click)="salvageRelics()">
                            <i class="fa-solid fa-arrow-right-arrow-left"></i>
                            Salvage
                            <span *ngIf="NumberChecked">
                                {{NumberChecked}}
                            </span>
                            Card{{NumberChecked>1?'s':''}}
                        </button>
                        <!-- <input *ngIf="card_eligible['transfer']" type="text" placeholder="Player HIVE Wallet Address" [(ngModel)]="receiver" /> -->
                    </div>
                    <div *ngIf="tabName=='Socket'" id="Socket" class="upgrade_box">
                        <h4>Add a Socket</h4>
                        
                        <div>
                            <h4>Electrum required for 100% by rarity & # sockets</h4>
                            <table *ngIf="socketOdds">
                                <tr>
                                    <th>Rarity/Sockets</th>
                                    <th>1 Socket</th>
                                    <th>2 Sockets</th>
                                    <th>3 Sockets</th>
                                    <th>4 Sockets</th>
                                </tr>
                                <tr *ngFor="let odds of socketOdds; let i = index">
                                    <td>{{i===0?'Common':i===1?'Rare':i===2?'Epic':'Legendary'}}</td>
                                    <td>{{odds[0]?odds[0]:'X'}}</td>
                                    <td>{{odds[1]?odds[1]:'X'}}</td>
                                    <td>{{odds[2]?odds[2]:'X'}}</td>
                                    <td>{{odds[3]?odds[3]:'X'}}</td>
                                </tr>
                            </table>
                        </div>
                        <div *ngIf="!selectedToImbue" class="fill_box">
                            <h4>Required for 100% on this card: {{socketOdds[card.rarity-1][card.sockets]}} Electrum</h4>
                            <div *ngIf="!card.isMaxSockets" class="electrum_box">
                                <div class="imagewrap">
                                    <img src="https://splinterforge.s3.us-east-2.amazonaws.com/Electrum.png" width="200"
                                        height="300" loading="lazy">
                                </div>
                                <table class="socket">
                                    <tr class="titles">
                                        <td>Owned</td>
                                        <td>Chance</td>
                                        <td>Quantity</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="inner_balance">{{ElectrumBalance|number}} Electrum</div>
                                        </td>
                                        <td>
                                            <div class="inner_balance chance">{{calcAddSocketSuccess()}}%</div>
                                        </td>
                                        <td>
                                            <input type="number" min="1" max="{{ElectrumBalance}}" [(ngModel)]="qty"
                                                (change)="getCardPointsSelected()" />
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <button *ngIf="qty > 0" [disabled]="loading || qty > ElectrumBalance"
                                class="button blue enhancement_button" (click)="addSocketToItem()">
                                Attempt Enhancement
                            </button>
                            <div *ngIf="card.isMaxSockets" class="col">
                                <div class="combinables">
                                    <h4>This card already has max sockets</h4>
                                </div>
                            </div>
                            <div class="col"></div>
                        </div>
                    </div>
                    <div *ngIf="tabName=='Imbue'" id="Socket" class="upgrade_box">
                        <h4>Imbue a Gem or Rune into an item to make it more powerful!</h4>
                        <div *ngIf="!selectedToImbue">
                            <div>
                                <h5>Imbue rules:</h5>
                                <ul class="imbue_rule_list">
                                    <li><span>Damage gems can only go in their matching weapon, bow = ranged, wand = magic,
                                        sword = attack.</span></li>
                                    <li><span>Imbuing an piece of equipment that has full sockets will replace a random
                                        socketed gem/rune and return it to your inventory.</span></li>
                                    <li><span>All other stats and abilities stack.</span></li>
                                </ul>
                            </div>
                            <div *ngIf="socketCards && socketCards.length > 0">
                                <!-- <h4>Runes & Gems available to socket into {{card.name}}</h4> -->
                                <div class="imbue_cards">
                                    <div class="sfcard card_hover" *ngFor="let c of socketCards">
                                        <sfcard-selector [sfcards]="cards" [hover]="false" [sfcard]="c"
                                            [stackView]="false" [smaller]="true" (click)="selectCardToImbue(c)">
                                        </sfcard-selector>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!socketCards || socketCards.length == 0">
                                <section class="no_available_cards">
                                    <div class="cards no_cards">
                                        <h1>No Available Cards</h1>
                                        <h3>You have no cards available to imbue on this item.</h3>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <div *ngIf="card && selectedToImbue" class="row imbue_card_container">
                            <div class="col col-3 imbue_selection" (click)="selectedToImbue = null">
                                <div>
                                    <div class="align_right">
                                        <sfcard-selector class="sfcard" [sfcards]="cards" [sfcard]="selectedToImbue"
                                            [stackView]="false"></sfcard-selector>
                                        <button [disabled]="loading" class="change_button btn"
                                            (click)="selectedToImbue = null">
                                            Change Card
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-3 imbue_result">
                                <div>
                                    <div>
                                        <sfcard-selector class="sfcard" [sfcards]="cards" [sfcard]="imbuedPreview"
                                            [stackView]="false"></sfcard-selector>
                                        <button [disabled]="loading" class="imbue_button btn" (click)="imbueItem()">
                                            Imbue Cards
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="imbue_buttons">
                                <!-- <h4 class="imbue_indi">
                                <span (click)="selectedToImbue = null">
                                    <i class="fa-solid fa-arrow-left"></i>
                                    Imbue Selected
                                </span>
                            </h4> -->
                                <h4 class="imbue_names">{{card.name}}</h4>
                                <!-- <h4 class="imbue_names_plus"><i class="fa-solid fa-plus"></i></h4> -->
                                <h4 class="imbue_names_plus">Imbue</h4>
                                <h4 class="imbue_names">{{selectedToImbue.name}}</h4>
                                <!-- <h4 class="result_indi align_right" >
                                <span (click)="imbueItem()">
                                    Imbue Result 
                                    <i class="fa-solid fa-arrow-right"></i>
                                </span>
                            </h4> -->
                            </div>
                        </div>
                    </div>
                    <div *ngIf="tabName=='Stats'" id="Stats" class="upgrade_box">
                        <h4>{{card.name}} - {{(card.type === 'socket' || card.slot === 'relic' ? '' : 'Lvl ' + card.level)}}</h4>
                        <div *ngIf="display.stats && card.stat !== 'socket' && card.slot !== 'relic'">
                            <table>
                                <tr>
                                    <th>{{card.type !== 'socket' ? 'Level' : 'Rarity'}}</th>
                                    <th *ngIf="card.type !== 'socket'">Cards</th>
                                    <th>{{card.type !== 'socket' ? card.stat : 'Ability'}}</th>
                                </tr>
                                <tr *ngFor="let s of display.stats; let i = index">
                                    <td>
                                        <i class="fa-solid fa-star star"></i>
                                        {{card.type !== 'socket' ? i+1 : i+1|stringify_rarity }}
                                    </td>
                                    <td *ngIf="card.type !== 'socket'">
                                        {{display.levels[i]}}
                                    </td>
                                    <td>
                                        <i class="fa-solid fa-plus"></i>
                                        {{s}}
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div *ngIf="card.slot === 'relic'">
                            <table>
                                <tr>
                                    <th>Rarity</th>
                                    <th>Base Max Charges</th>
                                </tr>
                                <tr *ngFor="let s of display.stats; let i = index">
                                    <td>
                                        <i class="fa-solid fa-star star"></i>
                                        {{i+1|stringify_rarity}}
                                    </td>
                                    <td>
                                        {{i===3?100:i===2?50:i===1?25:10}}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div *ngIf="tabName=='Recharge'" id="Socket" class="upgrade_box">
                        <h5>Recharge Relic - {{card.charges.current}}/{{card.charges.max}} charges</h5>
                        
                        <div>
                            <table>
                                <tr>
                                    <th>Rarity</th>
                                    <th>Charges per BCX</th>
                                </tr>
                                <tr *ngFor="let odds of ['Common','Rare','Epic','Legendary']; let i = index">
                                    <td>{{odds}}</td>
                                    <td *ngIf="i === 0">2</td>
                                    <td *ngIf="i === 1">4</td>
                                    <td *ngIf="i === 2">6</td>
                                    <td *ngIf="i === 3">10</td>
                                </tr>
                            </table>
                        </div>
                        <div *ngIf="card.charges.current >= card.charges.max">
                            <h5>This Relic is already at max charges!</h5>
                        </div>
                        <div *ngIf="card.charges.current < card.charges.max">
                            <h5>Select gems/runes to be burn for recharge</h5>
                            <div class="itemwrapper elixirs reforge_container">
                                <div class="reforgable_trade">
                                    <h5>
                                        <i class="fa-solid fa-circle-info info-circle"></i>
                                        <span>
                                            Each time you recharge, selected relic will lose 1 MAX charges.
                                        </span>
                                    </h5>
                                </div>
                
                                <div *ngIf="rechargable && rechargable.length > 0" class="cardgroup">
                                    <div class="cards_available">
                                        <span>Available Cards: 
                                            <b>{{rechargable.length}}</b>
                                        </span>
                                        <div class="right">
                                            <span class="show_more_info" (click)="toggle_info_window();">
                                                <i *ngIf="card_details=='1'" class="fa-solid fa-check"></i>&nbsp;
                                                Card Details
                                            </span>
                                        </div>
                                    </div>
                                    <div class="cards_available_spacer"></div>
                                    <div [attr.class]="rechargable.length==1?'reforgable single':rechargable.length==2?'reforgable double':'reforgable'">
                                        <div *ngFor="let c of rechargable " class="reforgable_item">
                                            <div class="card_reforge" (click)="selectToRecharge(c)">
                                                <span class="reforgable_selector">
                                                    <i *ngIf="c.checked" class="fa-solid ck fa-check"></i>
                                                    <i class="fa-regular fa-square-full"></i>
                                                </span>
                                                <span>{{c.name}} - {{c.combined + ' BCX'}}</span>
                                                <span class="only_full">{{c.stat}}&nbsp;<i class="fa-solid fa-star"></i>&nbsp;{{c.rarity===4?'L':c.rarity===3?'E':c.rarity===2?'R':'C'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="reforge_controls">
                                        <div *ngIf="rechargable[0].rarity > 0" class="reforgable_show">
                                            <h3>
                                                <div class="label">Missing Charges</div>
                                                <span class="label_max">{{rechargePointsSelected()}} / {{card.charges.max-card.charges.current}}</span>
                                            </h3>
                                        </div> 
                                        <button [disabled]="chargesSelected < 1" 
                                                class="button orange reforge_cards_button"
                                                (click)="rechargeRelic()"
                                                title="All FORGE spent recharging will be BURNT!">
                                                <i class="fa-solid fa-fire"></i> Recharge <i class="fa-solid fa-fire"></i>
                                        </button>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div *ngIf="tabName=='Market'" id="Market" class="upgrade_box">
                        <h4>
                            {{card.name}} 
                            <button (click)="marketAction = 'Sell'">Sell</button>
                            <button (click)="marketAction = 'Cancel'">Cancel</button>
                            <button (click)="marketAction = 'Update'">Update</button>
                            <button (click)="marketAction = 'Buy'">Buy</button>
                        </h4>
                        <div *ngIf="marketAction === 'Sell'">
                            <div *ngIf="sellable && sellable.length > 0" title="Only cards with a price equal or greater than 0.01 will be listed.">
                                <h4>
                                    Salable Cards
                                </h4>
                                <div *ngFor="let c of sellable">
                                    <div class="combinables_list">
                                        <span [attr.class]="'item_title'">
                                            <span (click)="removeFromSellable(c)"><i class="fa">&#xf014;</i></span>
                                            <span class="card_level">Level {{c.level}}</span>
                                            <span>
                                                {{c.combined}} BCX
                                                {{(c.sockets>0 ? ' - ' + c.sockets + (c.sockets==1?' socket':' sockets') : '')}}
                                                {{c.UsedSocketText}}
                                            </span>
                                        </span>
                                        <span [attr.class]="'item_title'" [title]="c.equipped ? 'Gems/Runes can not be sold if they are imbued into equipment.' : ''">
                                            {{c.name + (c.equipped ? ' (used in equipment)' : '')}}
                                        </span>
                                        <div class="info_dot">
                                            $<input type="number" step="0.01" value="0" [(ngModel)]="c.market_price" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <button class="burn_card floatleft button blue"
                                        (click)="listSelected()"
                                        *ngIf="numWithPrice('salable') > 0">
                                        <i class="fa-solid fa-arrow-right-arrow-left"></i>
                                        List {{numWithPrice('salable')}} card{{numWithPrice('salable')>1?'s':''}} for {{total('salable')}}
                                    </button>
                                </div>
                            </div>
                            <div *ngIf="!sellable || sellable.length === 0">
                                <h5>Nothing available to sell.</h5>
                            </div>
                        </div>
                        <div *ngIf="marketAction === 'Update'">
                            <div *ngIf="selling && selling.length > 0" title="Only cards with a price change to a value equal or greater than 0.01 will be updated.">
                                <h4>Your Cards on Market</h4>
                                <div *ngFor="let c of selling">
                                    <div class="combinables_list">
                                        <span [attr.class]="'item_title'">
                                            <span (click)="removeFromSelling(c)"><i class="fa">&#xf014;</i></span>
                                            <span class="card_level">Level {{c.level}}</span>
                                            <span>
                                                {{c.combined}} BCX
                                                {{(c.sockets>0 ? ' - ' + c.sockets + (c.sockets==1?' socket':' sockets') : '')}}
                                                {{c.UsedSocketText}}
                                            </span>
                                        </span>
                                        <span [attr.class]="'item_title'" [title]="c.equipped ? 'Gems/Runes can not be sold if they are imbued into equipment.' : ''">
                                            {{c.name + (c.equipped ? ' (used in equipment)' : '')}}
                                        </span>
                                        <div class="info_dot">
                                            $<input type="number" min="0.01" step="0.01" value="0.01" [(ngModel)]="c.market_price" />
                                        </div>
                                    </div>
                                </div>
                                <button class="burn_card floatleft button blue"
                                    (click)="updateSale()"
                                    *ngIf="numWithPrice('selling') > 0">
                                    <i class="fa-solid fa-arrow-right-arrow-left"></i>
                                    Update {{numWithPrice('selling')}} card{{numWithPrice('selling')>1?'s':''}} to {{total('selling')}}
                                </button>
                            </div>
                            <div *ngIf="!selling || selling.length === 0">
                                <h5>Nothing listed on market to update.</h5>
                            </div>
                        </div>
                        <div *ngIf="marketAction === 'Cancel'">
                            <div *ngIf="selling && selling.length > 0">
                                <h4>Your Cards on Market</h4>
                                <div *ngFor="let c of selling">
                                    <div class="combinables_list">
                                        <span [attr.class]="'item_title'">
                                            <span (click)="removeFromSelling(c)"><i class="fa">&#xf014;</i></span>
                                            <span class="card_level">Level {{c.level}}</span>
                                            <span>
                                                {{c.combined}} BCX
                                                {{(c.sockets>0 ? ' - ' + c.sockets + (c.sockets==1?' socket':' sockets') : '')}}
                                                {{c.UsedSocketText}}
                                            </span>
                                        </span>
                                        <span [attr.class]="'item_title'" [title]="c.equipped ? 'Gems/Runes can not be sold if they are imbued into equipment.' : ''">
                                            {{c.name + (c.equipped ? ' (used in equipment)' : '')}}
                                        </span>
                                        <span *ngIf="c.market_type === 'SELL'">Sell: ${{c.market_price}}</span>
                                    </div>
                                </div>
                                <button class="burn_card floatleft button blue"
                                    (click)="cancelSelling()">
                                    <i class="fa-solid fa-arrow-right-arrow-left"></i>
                                    Cancel {{selling.length}} card{{selling.length>1?'s':''}}
                                </button>
                            </div>
                            <div *ngIf="!selling || selling.length === 0">
                                <h5>Nothing listed on market to cancel.</h5>
                            </div>
                        </div>
                        <div *ngIf="marketAction === 'Buy'">
                            <div class="left">
                                <a target="_blank" href="https://splex.gg/sf/market">
                                  <img src="/assets/splex_gg.webp">
                                  <h1>Splex has implemented the market browsing and buying features for the Alpha Market.</h1>
                                </a>
                              </div>
                        </div>
                    </div>
                    <div *ngIf="tabName=='Lore'" id="Lore" class="upgrade_box">
                        Lore
                    </div>
                </div>
                <div class="clear"></div>
            </div>
        </div>
    </div>
</div>
