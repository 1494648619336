<div class="home_page">
  <div class="background"></div>
  <div class="logo_container">
    <img src="/assets/splinter-forge-logo-finalx400-min.png" loading="lazy">
  </div>
  <div class='clouds'>
    <div class='clouds-1'></div>
    <div class='clouds-2'></div>
    <div class='clouds-3'></div>
  </div>
  <section id="topcallouts">
    <div class="home_box">

        <div [attr.class]="loaded?'slider open':'slider'">
          <div class="background_1"></div>
          <div (click)="slide=slide<2?slide+1:0">
            <div class="itembox" *ngIf="slide==0">
              <div class="centerbox">
                <div class="center_img">
                  <img src="/assets/badges/dual_gold.png" />
                  <i class="lg fa-solid fa-dice-d20"></i>
                </div>
                <h2>Splinterlands meets PvE</h2>
                <p>Earn rewards for fighting bosses, adventure mode and more!</p>
              </div>
            </div>
            <div class="itembox" *ngIf="slide==1">
              <div class="centerbox">
                <div class="center_img">
                  <i class="lg fa-solid fa-crosshairs"></i>
                  <img src="/assets/badges/spellbook_gold.png" />
                </div>
                <h2>Use your SL Cards</h2>
                <p>Use your existing Splinterlands cards to play a whole different way!</p>
              </div>
            </div>
            <div class="itembox" *ngIf="slide==2">
              <div class="centerbox">
                <div class="center_img">
                  <img src="/assets/badges/gem_gold.png" />
                  <i class="lg fa-solid fa-dollar-sign"></i>
                </div>
                <h2>New combos</h2>
                <p>Play monsters and summoners together in ways which were not previously possible!</p>
              </div>
            </div>
          </div>
          <button (click)="slide=slide==0?2:slide-1" class="left" tabindex="12">
            <i class="fa-solid fa-chevron-left"></i>
          </button>
          <button (click)="slide=slide<2?slide+1:0" class="right" tabindex="13">
            <i class="fa-solid fa-chevron-right"></i>
          </button>
          <div class="bars">
            <div (click)="slide=2" [attr.class]="slide==2?'bar lit':'bar'"></div>
            <div (click)="slide=1" [attr.class]="slide==1?'bar lit':'bar'"></div>
            <div (click)="slide=0" [attr.class]="slide==0?'bar lit':'bar'"></div>
          </div>
        </div>

    </div>
  </section>

  <div class="heading" tabindex="14" (click)="login()" (keyup.enter)="login()">
    <h2>
      <div class="border_gradient top"></div>
        <div class="top_potions">
          <img class="potion" src="/assets/potions/Unlock Commons & Rares.png" />
          <img class="potion" src="/assets/potions/Stamina Expansion.png" />
          <img class="potion" src="/assets/potions/Slot Bonus.png" />
        </div>
        <span>The Adventure Begins</span>
        <div class="bottom_potions">
          <img class="potion" src="/assets/potions/Unlock Legendaries.png" />
          <img class="potion" src="/assets/potions/Stamina Potion.png" />
          <img class="potion" src="/assets/potions/Unlock Epics.png" />
        </div>
      <div class="border_gradient bottom"></div>
    </h2>
  </div>
</div>

  <!-- about section -->
  <div class="information">
    <div>
      <div class="updates">
        <div class="slider open">
          <div (click)="slide_cards=slide_cards<3?slide_cards+1:0">
            <div class="itembox" *ngIf="slide_cards==0">
              <div class="centerbox">
                <h2>Updates</h2>
                <p>Updates will be announced on our Discord Server.</p>
                <a alt="Discord Server" class="link_icon" href="https://discord.gg/umMfWvu2v7" target="_blank">
                  <i class="lg fa-solid fa-arrows-rotate"></i>
                </a>
              </div>
            </div>
            <div class="itembox" *ngIf="slide_cards==1">
              <div class="centerbox">
                <h2>Bug Reports</h2>
                <p>Bug reports channel located on our Discord Server.</p>
                <a alt="Discord Server" class="link_icon" href="https://discord.gg/umMfWvu2v7" target="_blank">
                  <i class="lg fa-solid fa-spider"></i>
                </a>
              </div>
            </div>
            <div class="itembox" *ngIf="slide_cards==2">
              <div class="centerbox">
                <h2>Feedback</h2>
                <p>All feedback welcome on our Discord Server.</p>
                <a alt="Discord Server" class="link_icon" href="https://discord.gg/umMfWvu2v7" target="_blank">
                  <i class="lg fa-solid fa-message"></i>
                </a>
              </div>
            </div>
            <div class="itembox" *ngIf="slide_cards==3">
              <div class="centerbox">
                <h2>Developer Access</h2>
                <p>Direct access to the dev on our Discord Server.</p>
                <a alt="Discord Server" class="link_icon" href="https://discord.gg/umMfWvu2v7" target="_blank">
                  <i class="lg fa-solid fa-laptop-code"></i>
                </a>
              </div>
            </div>
          </div>
          <button (click)="slide_cards=slide_cards==0?3:slide_cards-1"
                  (keyup.enter)="slide_cards=slide_cards==0?3:slide_cards-1" 
                  tabindex="15" class="left">
            <i class="fa-solid fa-chevron-left"></i>
          </button>
          <button (click)="slide_cards=slide_cards<3?slide_cards+1:0"
                  (keyup.enter)="slide_cards=slide_cards<3?slide_cards+1:0" t
                  abindex="16" class="right">
            <i class="fa-solid fa-chevron-right"></i>
          </button>
          <div class="bars">
            <div (click)="slide_cards=3" [attr.class]="slide_cards==3?'bar lit':'bar'"></div>
            <div (click)="slide_cards=2" [attr.class]="slide_cards==2?'bar lit':'bar'"></div>
            <div (click)="slide_cards=1" [attr.class]="slide_cards==1?'bar lit':'bar'"></div>
            <div (click)="slide_cards=0" [attr.class]="slide_cards==0?'bar lit':'bar'"></div>
          </div>
        </div>

        <!-- <div class="clear"></div> -->
        <div class="info_panel"> 
          <div class="roadmap dark_box orange_border">
            <div class="header">
              <h1>Latest News</h1>
            </div>
            <div class="post">
              <h2 class="title">Roadmap</h2>
              <div class="body">
                <ul>
                    <li>Hero vs. Hero PvP</li>
                    <li>Quests</li>
                    <li>Revamp Boss battles</li>
                    <li>Continuous QoL updates</li>
                </ul>
              </div>
            </div>

          </div>
        </div>
        <div class="clear"></div>
      </div>

      <div class="links_panel"> 
        <div class="left">
          <a target="_blank" href="https://splex.gg/">
            <img src="/assets/splex_gg.webp">
            <p>Add SplinterLands cards to your account on the Splex.GG rental market. Bid and purchase your card collection from the marketplace. Simply rent or buy your playing cards from the Rent Golem. Use your HIVE account to pit your card collection against the bosses on SplinterForge to gain knowledge & wisdom.</p>
          </a>
        </div>
        <div class="right">
        </div>
      </div>

      <div class="donate_link dark_box" (click)="donate()">
        <div class="top_border"></div>
        <div class="bottom_border"></div>
        <a target="_blank">Donate to SplinterForge</a>
      </div>
      <div class="clear"></div>
    </div>
  </div>
  