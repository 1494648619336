import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'name_includes'
})
export class NameIncludesPipe implements PipeTransform {
	transform(value: any, args?: any): any {
		let values = [];
		if(value)for(var i=0;i<value.length;i++)if(value[i].name.toLowerCase().includes(args.toLowerCase()))values.push(value[i]);
		return values;
	}
}