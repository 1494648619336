import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'fw-show',
  templateUrl: './fw-show.component.html',
  styleUrls: ['./fw-show.component.css']
})
export class FWShowComponent {

	@Input('fade') fade = true;
	@Input('trigger') show = true;
	@Output() trigger = new EventEmitter();

	constructor(
		private r: Router,
		private l: Location) { 
	}
	
	firework(e) {
		console.log(e)
	}

	rmCanvas() {
		this.show = false;
		this.trigger.emit('hidden');
		if ( this.l.path() == '/fw' ) this.r.navigate(['shop']);
	}
}
