import { Component, OnInit, OnDestroy } from '@angular/core';
import { User } from '@app/_models';
import { DataService } from '@app/_services/data.service';
import { HelperService } from '@app/_services/helper.service';
import { HeroComponent } from '../hero/hero.component';
@Component({ 
    templateUrl: 'battle.component.html',
    styleUrls: [
        '../cards/cards.component.styl',
        '../shop/shop.component.styl',
        './battle.component.styl'
    ]
})
export class BattleComponent implements OnInit, OnDestroy {
    loading: Boolean = false;
    user: User;
    show = 'hvh';
    subCat = 'selectHeroes';
    config: any;
    mod: any;
    heroes: any;
    leaderboard: any;
    enemies: any;
    position: any;
    enemy: any;
    selectedHeroes = [];
    teamPower = 0;
    opponents = [];

    constructor(private helper: HelperService, private data: DataService, private heroComp: HeroComponent) {
        this.data.onLoadingChange().subscribe(b => this.loading = b);
        this.data.onUserChange().subscribe(c => { this.user = c; });
        this.data.setUser(this.helper.currentUser);
    }
    
    ngOnInit() {
        this.loadInfo();
    }

    calcHeroPower(hero) {
        try {
            let li = this.helper.heroLevelInfo(hero.xp);
            let count = 0;
            //hero.power = 0;
            count += li.level; //hero.power += li.level;
            count += hero.rank*3; //hero.power += parseInt(hero.rank*3);
            hero.skills.forEach(x => {
                if (x.type === 'trained') {
                    count += x.level; //hero.power += x.level;
                    count += x.tier*3; //hero.power += parseInt(x.tier*3);
                } else if (x.type === 'passive') {
                    count += x.tier*3; //hero.power += parseInt(x.tier*3);
                    if (x.name === 'Nartors Familiarity') count += 20; //hero.power += 20;
                    else if (x.name === 'Force Master') count += 10; //hero.power += 10;
                }
            });
            hero.equipped.forEach(x => {
                let itemScore = x.type === 'socket' ? x.rarity*3 : x.rarity*x.level;
                count += itemScore; //hero.power += itemScore;
            })
            hero.power = count;
        } catch (e) {
            console.log(e);
        }
    }

    getHeroAbi(equippedCards) {
        let config = this.helper.getSettings();
        let abilities = [
            { type: "Life Force", bonus: 0 },
            { type: "Fire Force", bonus: 0 },
            { type: "Earth Force", bonus: 0 },
            { type: "Death Force", bonus: 0 },
            { type: "Water Force", bonus: 0 },
            { type: "Dragon Force", bonus: 0 },
            { type: "Gladius Force", bonus: 0 },
            { type: "Mana Mastery", bonus: 0 },
            { type: "Vassal Mastery", bonus: 0 },
            { type: "Splash Heal", bonus: 0 },
            { type: "Hearts Blood", bonus: 0 },
            { type: "Critical Strike", bonus: 0 }
        ];
        if (!equippedCards || equippedCards.length < 1) return abilities;

        let socketAbilities = config['socketItems'];
        let nartorsSet = [57,58,59,60,61,62];
        let nartors = 0;
        for (let i = 0; i < equippedCards.length; i++) {
            let card = equippedCards[i];
            if (nartorsSet.includes(card.detail_id)) nartors += 1;
            let abi = abilities.find(a => { return card.name.indexOf(a.type) > -1; });
            if (abi) {
                let bonusDetails = socketAbilities.find(x => { return x.type.indexOf(abi.type) > -1 });
                //console.log(bonusDetails);
                abi.bonus += bonusDetails.bonus[card.rarity-1];
            }
        }
        if (nartors === 6) abilities[8].bonus += 1;
        return abilities;
    }

    async getHeroes() {
        this.heroes = await this.heroComp.getCompleteHeroes();
        console.log(this.heroes);
        if (this.heroes?.length > 0) {
            //this.heroes.forEach(x => { x.uid = x.id + ' hero'; });
            this.heroes.forEach(x => { 
                this.calcHeroPower(x);
                console.log(x.name, x.power);
            });
            await this.setHeroesStats();
        }
    }

    getOpponents(arr, target) {
        const n = arr.length;
        const k = 6;
    
        let left = 0;
        let right = n - 1;
     
        // Use binary search to find the closest elements
        while (right - left >= k) {
            // Compare the absolute differences between the target and elements at left and right pointers
            if (Math.abs(arr[left].teamPower - target) > Math.abs(arr[right].teamPower - target)) {
                left++;
            } else {
                right--;
            }
        }
     
        // Print the `k` closest elements
        console.log('target: ', target);
        let opponents = [];
        while (left <= right) {
            opponents.push(arr[left]);
            //console.log(arr[left].teamPower, end=" ");
            left++;
        }
        return opponents;
    }

    async loadInfo() {
        this.mod = await this.helper.getGlobalMod();
        let temp: any = await this.helper.loadLeaderboard('HvH leaderboard');
        this.leaderboard = temp.leaderboard;
        this.leaderboard.leaderboard.forEach(x => {
            x.heroes.forEach(y => {
                y.stats = this.helper.getCompleteHeroStats(y.equipped, y);
                for (let key in y.stats) {
                    y[key] = y.stats[key];
                }
            })
        })
        console.log(this.leaderboard);
        this.position = this.leaderboard.leaderboard.find(x => { return x.player === this.user.username; });
        console.log(this.position);
        await this.getHeroes();
    }

    pickEnemy(enemy) {
        console.log('picked', enemy.player);
        this.enemy = enemy;
        this.subCat = 'hvhPreview';
    }

    selectHero(hero) {
        let found = this.selectedHeroes.find(x => { return x.id === hero.id; });
        if (found) {
            this.selectedHeroes = this.selectedHeroes.filter(x => { return x.id !== hero.id; });
            hero.selected = false;
        } else {
            if (this.selectedHeroes.length < 2) {
                this.selectedHeroes.push(hero);
                hero.selected = true;
            } else alert('May only select 2 heroes for battle, please remove one before adding another.');
        }

    }

    setTeam() {
        if (this.selectedHeroes.length === 2) {
            this.teamPower = this.selectedHeroes[0].power + this.selectedHeroes[1].power;
            // todo narrow opponent list to 4-6 players
            this.opponents = this.getOpponents(this.leaderboard.leaderboard, this.teamPower);
            this.opponents = this.opponents.filter(x => { return x.player !== this.user.username; });
            console.log(this.opponents);
            this.position = {
                player: this.user.username,
                points: 0,
                reward: 0,
                newReward: 0,
                heroes: this.selectedHeroes,
                teamPower: this.teamPower,
                lastFight: Date.now()
            }
            this.subCat = 'selectOpponent';
        } else alert('Team must have 2 heroes!');
    }

    async setHeroesStats() {
        if (!this.heroes) return;
        for (let i = 0; i < this.heroes.length; i++) {
            let tempHero = this.heroes[i];
            let equippedCards = await this.helper.getEquippedCards(tempHero);
            let heroAbilities = this.getHeroAbi(equippedCards);
            let levelDetails = this.helper.heroLevelInfo(tempHero.xp);
            tempHero.uid = tempHero.id + tempHero.currentClass + ' hero';
            tempHero.color = 'ALL';
            tempHero.levelDetails = levelDetails;
            tempHero.abilities = heroAbilities;
            tempHero.level = levelDetails.level;
            tempHero.promotable = tempHero.rank < Math.floor(tempHero.level/10);
            tempHero.equipped = equippedCards;

            let stats = this.helper.getCompleteHeroStats(equippedCards, tempHero);
            tempHero.stats = stats;
            //console.log(tempHero.stats);
            for (let key in tempHero.stats) {
                tempHero[key] = tempHero.stats[key];
            }
            //tempHero.stats.forEach(x => {
            //    tempHero[x] = x;
            //})
        }
    }

    ngOnDestroy() {
    }
    
}
