<div [attr.class]="(smaller?smaller:'')+' statbar armor'" *ngIf="original_armor">
    <div class="icon">
    	<img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/defense.png" loading="lazy">
    </div>
    <!--     
    <div class="armor_numbers">{{armor}} {{original_armor}}</div>
    <div class="wrapper" data-anim="base wrapper">
        <div class="circle_rotated">
            <div class="circle" [style.transform]="'rotate('+degrees+'deg)'" data-anim="base left"></div>
        </div>
        <div class="circle_background">
            <div class="circle background" data-anim="base left"></div>
        </div>
    </div> 
    -->
    <div [attr.class]="armor_active?'active barwrap':'barwrap'" [attr.title]="armor+'/'+original_armor+' Armor'">
        <div class="overlay"></div>
        <div class="base"></div>
        <div class="overlay colorfill" *ngIf="armor>0" [ngStyle]="{'width':(armor/original_armor)*100+'%'}">
            <div class="overlay"></div>
        </div>
        <div class="overlay text" *ngIf="armor>0">{{armor|number}}</div>
        <div class="overlay text" *ngIf="!armor || armor<0 || armor==0">None</div>
    </div>
</div>
<div [attr.class]="(smaller?smaller:'')+' statbar health'" *ngIf="original_health">
    <div class="icon">
    	<img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/health.png" loading="lazy">
    </div>
    <div [attr.class]="health_active?'active barwrap':'barwrap'" [attr.title]="health+'/'+original_health+' Health'">
        <div class="overlay"></div>
        <div class="base"></div>
        <div class="overlay colorfill" *ngIf="health>0" [ngStyle]="{'width':(health/original_health)*100+'%'}">
            <div class="overlay"></div>
        </div>
        <div class="overlay text" *ngIf="health>0">{{health|number}}</div>
        <div class="overlay text" *ngIf="health<=0">DEAD</div>
    </div>
</div>