import type { FireworksOptions } from '../../../../custom_modules/fireworks-js'

export const blue_fw_options: FireworksOptions = {
    hue: {min: 175, max: 233},
    acceleration: 1.02,
    brightness: {min: 1, max: 100},
    decay: {min: 0.012, max: 0.012},
    delay: {min: 1, max: 1},
    explosion: 1,
    flickering: 17.30,
    intensity:1,
    friction: 0.94,
    gravity: 0,
    opacity: 0.5,
    particles: 3,
    traceLength: 1.1,
    traceSpeed: 89,
    boundaries: {},
    rocketsPoint: {min: 34, max: 34},
    lineWidth: {
        explosion: {min: 1, max: 3.71},
        trace: {min: 0.10, max: 1}
    },
    lineStyle: 'round'
}

export const red_fw_options: FireworksOptions = {
    hue: {min: 0, max: 35},
    acceleration: 1.75,
    brightness: {min: 51, max: 100},
    decay: {min: 0.012, max: 0.012},
    delay: {min: 1, max: 1},
    explosion: 1,
    flickering: 0,
    intensity:1,
    friction: 0.94,
    gravity: 0,
    opacity: 0.4,
    particles: 3,
    traceLength: 1,
    traceSpeed: 100,
    boundaries: {},
    rocketsPoint: {min: 34, max: 34},
    lineWidth: {
        explosion: {min: 1, max: 3.71},
        trace: {min: 0.10, max: 1}
    },
    lineStyle: 'round'
}

export const fw_options: FireworksOptions = {
    hue: {min: 0, max: 35},
    acceleration: 1.75,
    brightness: {min: 51, max: 100},
    decay: {min: 0.012, max: 0.012},
    delay: {min: 1, max: 1},
    explosion: 1,
    flickering: 0,
    intensity:60,
    friction: 0.94,
    gravity: 0,
    opacity: 0.4,
    particles: 2,
    traceLength: 1,
    traceSpeed: 100,
    boundaries: {},
    rocketsPoint: {min: 34, max: 34},
    lineWidth: {
        explosion: {min: 1, max: 3.71},
        trace: {min: 0.10, max: 1}
    },
    lineStyle: 'round'
}

export const green_fw_options: FireworksOptions = {
    hue: {min: 67, max: 101},
    acceleration: 1.02,
    brightness: {min: 1, max: 38},
    decay: {min: 0.015, max: 0.036},
    delay: {min: 1, max: 1},
    explosion: 1,
    flickering: 0,
    intensity:60,
    friction: 0.89,
    gravity: 0.11,
    opacity: 0.6,
    particles: 37,
    traceLength: 1.98,
    traceSpeed: 10,
    boundaries: {},
    rocketsPoint: {min: 50, max: 50},
    lineWidth: {
        explosion: {min: 1, max: 4},
        trace: {min: 0.10, max: 1}
    },
    lineStyle: 'round'
}

export const red_orange_fw_options: FireworksOptions = {
    hue: {min: 29, max: 58},
    acceleration: 1.08,
    brightness: {min: 73, max: 100},
    decay: {min: 0.05, max: 0.05},
    delay: {min: 100, max: 100},
    explosion: 1,
    flickering: 0,
    intensity:60,
    friction: 0.95,
    gravity: 0,
    opacity: 1,
    particles: 4,
    traceLength: 1,
    traceSpeed: 1,
    boundaries: {},
    rocketsPoint: {min: 50, max: 50},
    lineWidth: {
        explosion: {min: 2.09, max: 2.09},
        trace: {min: 3.56, max: 3.56}
    },
    lineStyle: 'square'
}

export const purple_fw_options: FireworksOptions = {
    hue: {min: 258, max: 288},
    acceleration: 1.02,
    brightness: {min: 65, max: 100},
    decay: {min: 0.05, max: 0.05},
    delay: {min: 100, max: 100},
    explosion: 1,
    flickering: 0,
    intensity:30,
    friction: 0.95,
    gravity: 0.02,
    opacity: 1,
    particles: 4,
    traceLength: 1,
    traceSpeed: 1,
    boundaries: {},
    rocketsPoint: {min: 50, max: 50},
    lineWidth: {
        explosion: {min: 1, max: 4},
        trace: {min: 0.1, max: 1}
    },
    lineStyle: 'round'
}

export const white_fw_options: FireworksOptions = {
    hue: {min: 0, max: 0},
    acceleration: 1.75,
    brightness: {min: 100, max: 100},
    decay: {min: 0.012, max: 0.012},
    delay: {min: 100, max: 100},
    explosion: 1,
    flickering: 0,
    intensity:60,
    friction: 0.95,
    gravity: 0,
    opacity: 1,
    particles: 2,
    traceLength: 1,
    traceSpeed: 1,
    boundaries: {},
    rocketsPoint: {min: 50, max: 50},
    lineWidth: {
        explosion: {min: 1, max: 3.71},
        trace: {min: 0.1, max: 1}
    },
    lineStyle: 'round'
}
export const celebration_fw_options: FireworksOptions = {
    hue: {min: 0, max: 365},
    acceleration: 1.75,
    brightness: {min: 100, max: 100},
    decay: {min: 0.012, max: 0.012},
    delay: {min: 1, max: 1},
    explosion: 4,
    flickering: 0,
    intensity: 24.09,
    friction: 0.94,
    gravity: 0,
    opacity: 0,
    particles: 1,
    traceLength: 1,
    traceSpeed: 100,
    boundaries: {},
    rocketsPoint: {min: 50, max: 50},
    lineWidth: {
        explosion: {min: 10, max: 10},
        trace: {min: 10, max: 10}
    },
    lineStyle: 'round'
}