import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({ providedIn: 'root' })
export class BossService {

    apiUrl = environment.apiUrl;

    constructor(private http: HttpClient) {  }

    getBosses() : any {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.apiUrl + '/boss/getBosses')
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }

    getBossBattles(id) : any {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.apiUrl + '/boss/getBossBattles?id=' + id)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }

    getOldBosses() {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.apiUrl + '/boss/getOldBosses')
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }

    fightBoss(params): any {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/boss/fight_boss', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
        return promise;
    }

    getBossLeaderboard(id): any {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.apiUrl + '/boss/getBossLeaderboard?id=' + id)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
        return promise;
    }

    getFullBattle(id): any {
        //console.log(id);
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.apiUrl + '/boss/getFullBattle?id=' + id)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
        return promise;
    }

    getLeaderboard(id): any {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.apiUrl + '/data/getLeaderboard?id=' + id)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
        return promise;
    }

    userFights(params): any {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/data/userFights', params)
                .toPromise().then(res => {
                    //console.log(res);
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }
}