<section class="forgemodal team_result" [@fade]="show">
    <div class="boxwrap large" [@flip_up]="show">
        <div class="topwrap">
            <h1>
                <!-- <img class="icon" src="https://d36mxiodymuqjm.cloudfront.net/website/stats/melee-attack.png"> -->
                {{player}}'s team result
            </h1>
            <div>
                Rules: <b>{{rules}}</b>
            </div>
            <div *ngIf="gear === 2">
                Used double stamina to get double damage, points, xp, and rewards!
            </div>
        </div>
        <div class="replay_stats">
            <div class="left">
                Player: <b>{{player}}</b>
            </div>
            <div class="left orange">
                Damage: <b>{{totalDmg}}</b>
            </div>
            <div class="left purple">
                Points: <b>{{points}}</b>
            </div>
        </div>
        <div class="centerbox first_box">
            <div *ngFor="let c of team">
                <div class="flexbox summoner_cards" *ngIf="c.type === 'Summoner' || c.uid.indexOf('hero') > - 1">
                    <div class="name"><span>{{c.name}}</span></div>
                    <splinterforge-card
                        class="itembox"
                        [showTitle]="false"
                        [showStats]="false"
                        [card]="c.uid.indexOf('hero') > - 1 ? null : c"
                        [hero]="c.uid.indexOf('hero') > - 1 ? c : null">
                    </splinterforge-card>
                </div>
            </div>
            <div class="clear"></div>
        </div>
        <div class="centerbox first_box">
            <div class="flexbox" *ngFor="let c of team|type:'Monster'">
                <div class="name">{{c.name}}</div>
                <splinterforge-card 
                    *ngIf="c.uid.indexOf('hero') < 0" 
                    class="itembox"
                    [card]="c">
                </splinterforge-card>
            </div>
        </div>
        <div *ngIf="1>1" class="watchwrapper">
            <button class="btn button-watch" tabindex="1" title="Watch Fight Replay" (keyup.enter)="watchReplay()" (click)="watchReplay()">Watch</button>
        </div>
        <div class="closewrapper" tabindex="2" (click)="close()" (keyup.enter)="close()" title="Close Team Viewer">
            <i class="fa-solid fa-x"></i>
        </div>
    </div>
    <div class="backdrop" [@pointer]="show" (click)="close()"></div>
</section>