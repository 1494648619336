import { Component, AfterViewInit, OnInit, OnDestroy, Input, Output, HostListener, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';  
import { User, Boss } from '@app/_models';
import { DataService } from '@app/_services';
import { DecimalPipe } from '@angular/common';
import { TypePipe } from '../_pipes/type.pipe';
import { SoundsService } from '../_services/sounds.service';
import { HelperService } from '@app/_services/helper.service';
import { Options, LabelType } from '@angular-slider/ngx-slider';
import { selected,attacking,attack,miss,died,hit,hit_damage,hit_damage_type,slow_fade,darken} from '../slcard/slcard.animations';
import { intro_right, selected_team, fade, intro_left, black_background } from '../app.animations';
import { environment } from '../../environments/environment';
import type { FireworksOptions } from '../../../custom_modules/fireworks-js'
import type { FireworksDirective } from '../../../custom_modules/fireworks/projects/ng-fireworks/src/lib/ng-fireworks.directive';
import { 
    blue_fw_options,
    red_fw_options,
    fw_options,
    green_fw_options,
    red_orange_fw_options,
    purple_fw_options,
    white_fw_options,
    celebration_fw_options,
} from './fw-show/fw-options';

@Component({ 
    selector: 'replay', 
    templateUrl: 'replay.component.html',
    providers: [ TypePipe, SoundsService ],
    animations: [selected,selected_team,fade,attacking,attack,miss,died,hit,hit_damage,slow_fade,darken,hit_damage_type,intro_right,intro_left,black_background],
    styleUrls: ['replay.component.styl']
})
export class ReplayComponent implements AfterViewInit, OnInit, OnDestroy {
    constructor(
            private decimalPipe: DecimalPipe,
            private data: DataService, 
            private type: TypePipe, 
            public sound: SoundsService,
            private helper: HelperService
        ) {
    }
    @ViewChild('SummonerContainer') SummonerContainer: ElementRef;
    @ViewChild('MonsterContainer') MonsterContainer: ElementRef;
    @ViewChild('monster_cont') monster_container : ElementRef;
    @HostListener('window:keyup', ['$event'])
        keyEvent(event: KeyboardEvent) {
        if(this.verbose)console.log('Keyboard press', event);
        if (event.code === "Enter") {
            this.skip_intro();
        }
    }
    @HostListener('window:resize', ['$event'])
    resizeWindow() {
        this.get_card_widths()
        this.set_height();
    }
    fw_trace = [];
    @ViewChild('ngFireworks') fireworks?: FireworksDirective;

    boundry = {
        // x: 160,
        // y: 225,
        // width: 500,
        // height: 500
    }
    running_fw_options: FireworksOptions = {intensity: 0,opacity:0}
    browserSupportsRaf:any;
    requestTimeout:any;
    selected_team: boolean;
    battle_zone_click(e?) {
        this.show_sound_vol=false;
        this.show_music_vol=false;
        // this.fire_fw(e.offsetX,e.offsetY,'blue')
    }

    fire_fw(x,y,n='blue',i=4,boss?) {
        if(this.running_fw_options.particles){
            var a = i>0?Number(i):4;
            this.running_fw_options.particles=a;
        }
        var d = Number(Number(0.06-Number(this.logSpeed)/100000));
        console.log('particles decay', this.running_fw_options.particles, d);
        if(this.running_fw_options.decay) this.running_fw_options.decay = { min: d, max: d };
        this.fw_trace = [x,y,x,y];
        this.start_fw= true;
        // this.stop_fw= false;
        this.requestTimeoutRaf(()=>{
            this.fw_trace = [];
            this.update_fw=false;
            this.start_fw= false;
            // this.stop_fw= true;
        },200);
    }    
    weapons = [
        "gold_sword.png",
        "golden-longsword.png",
        "red_gem_sword.png",
        "red-sword.png",
        "golden-handle-sword.png",
        "golden-sword.png",
        "battle-axe.png"
    ]
    heroid: any = '0';
    heroAbilities = [];
    ee = false;
    verbose = false;
    selected = [];
    attacking = [];
    miss = [];
    hit = [];
    hit_magic = [];
    hit_down = [];
    hit_damage = [];
    hit_damage_type = [];
    blue_spark = [];
    red_spark = [];
    dissolve = [];
    attack = [];
    shaken = [];
    ranged = [];
    dead = [];
    fade = [];
    jump_down = [];
    spart_burst = [];
    spark_across = [];
    blue_spark_across = [];
    blue_crash_across = [];
    forge = [];
    forge_attack_plus = [];
    forge_attack_minus = [];
    ability = [];
    ability_popover = [];
    boss_ability = [];
    uniqueRules = [];
    staggered = [];
    damage_top = 0;
    damage_left = 0;
    weapon_top = 0;
    weapon_left = 0;
    damage_rotate = 0;
    weapon_rotate = 0;
    weapon_container_rotate = 0;
    user: User;
    boss: Boss;
    result: any;
    battle_result: any;
    viewRewards: Boolean = false;
    bossView = '';
    scrolling = [];
    running = false;
    fresh_entry = false;
    DamageTransitionTime = 'transform 0ms, opacity 100ms';
    intro_cards = false;
    fade_vs = false;
    sound_blip = true;
    running_fight = false;
    red_spark_down = false;
    blue_spark_down = false;
    preamble_fade = true;
    preamble_animations = false;
    showDamageArrow = false;
    SimpleDamageArrow = false;
    show_splash_damage = [];
    boss_effects = false;
    staggering = false;
    dealCards = false;
    show_skip_intro = false;
    damageArrowType = 'attack';
    currentClass: string;
    show_log = 0;
    rewardedForgium = 0;
    rewardedElectrum = 0;
    playfeild_min_height = 0;
    card_style_float = [];
    winning_message = [];
    log_extra_wait=0;
    stagger_cards=0;
    Damage: any;
    Points: any;
    pause_checker: any;
    delt_cards: boolean;
    production: boolean;
    enable_fw = false;
    enable_sound = true;
    enable_music = true;
    mute_music = false;
    mute_sounds = true;
    show_music_vol = false;
    show_sound_vol = false;
    battle_stopped = false;
    show_effects = false;
    total_damage: number;
    last_sound_vol: number;
    last_music_vol: number;
    music_vol: number;
    sound_vol: number;
    fx_vol: number;
    rand: number;
    card_dealer:any;
    dmg_delay = 1000;
    forge_speed = 1500;
    card_width:any = 'auto';
    VolumeControls: Options = {
        ceil: 0,
        floor: -100,
        vertical: true,
        translate: (value: number, label: LabelType): string => {
            switch (label) {
                case LabelType.Low:
                    return "";
                break;
                case LabelType.High:
                    return "";
                break;
                default:
                    return "";
                break;
            }
        }
    };
    @Output('change_speed') change_speed = new EventEmitter();
    @Output('pause') pause = new EventEmitter();
    @Output('reset') reset = new EventEmitter();
    @Output('resume') resume = new EventEmitter();
    @Output('damage') damage = new EventEmitter();
    @Output('loaded') loaded = new EventEmitter();
    @Input('battle_paused') battle_paused = false;
    @Input('replay') replay = false;
    @Input('play') play: any;
    @Input('preamble') preamble = false;
    @Input('logSpeed') logSpeed = 900;
    update_fw: boolean;
    start_fw: boolean;
    stop_fw: boolean;
    total_summoner: number;
    total_monsters: number;
    total_cards: number;
    show_fw = true;
    show_smoke = false;
    show_starter_ribbon = false;

    userSub = new Subscription();
    resultSub = new Subscription();
    replayBossSub = new Subscription();
    scrollingSub = new Subscription();
    ce(e?) { }
    resume_game() {
        this.resume.emit(1);
        console.log('Resume Game Mark')
    }
    pause_game() {
        this.pause.emit(1);
        console.log('Pause Game Mark')
    }

    increase_damage(dmg) {
        this.total_damage=Number(Number(this.total_damage)+Number(dmg));
        this.damage.emit(this.total_damage);
    }
    toggle_effects() {
        // this.show_effects = this.show_effects ? false : true;
        this.enable_fw = this.enable_fw ? false : true;
    }
    stagger_deal_cards(s=0) {
        // if(this.delt_cards)return;
        // if(s>this.total_cards)return;
        this.stagger_cards=s;
        this.staggered[this.stagger_cards]=true;
        this.requestTimeoutRaf( () => {
            if(this.stagger_cards<=this.total_cards) {
                this.stagger_deal_cards(s+1);    
            } else {
                this.delt_cards = true;
            }
        }, 170);
    }
    carddeal_sound(s=0){
        this.requestTimeoutRaf( () => {
            if(this.enable_sound&&this.mute_sounds)this.sound.card_deal(s);
            if(s<=this.total_cards) {
                this.carddeal_sound(s+1);
            }
        }, 170);
    }
    ngOnInit() {
        this.rand=this.getRandomInt(1);
        this.viewRewards = false;
        this.production = environment.production;
        this.sound.get_music_volume((v)=>{
            this.music_vol=v*100;
            this.mute_music=true;
            if(this.music_vol==-100)this.mute_music=false;
        });
        this.sound.get_sound_volume((v)=>{
            this.mute_sounds=true;
            this.sound_vol=v*100;
            if(this.sound_vol==-100)this.mute_sounds=false;
        });
        this.get_card_widths();
        this.browserSupportsRaf = window.requestAnimationFrame;
        this.requestTimeout = this.browserSupportsRaf ? this.requestTimeoutRaf : this.requestTimeoutNoRaf;
    }
    ngOnDestroy() {
        this.userSub.unsubscribe();
        this.resultSub.unsubscribe();
        this.replayBossSub.unsubscribe();
        this.scrollingSub.unsubscribe();
        if (this.pause_checker) clearInterval(this.pause_checker);
        this.battle_stopped = true;
        this.viewRewards = false;
        this.sound.stop_sounds();
        this.sound.stop_music();
        this.reset_all_items();
    }
    @Input('run_fight') set fight(now: boolean) {
        if(now){
            if(this.boss&&this.boss.name)this.position_by_name(this.boss.name);
            this.startLog();
            if(this.enable_music&&this.mute_music)this.sound.start_music();
            this.running_fight=true;
            this.preamble_fade = true;
        }
    }
    @Input('quit') set quit(now: boolean) {
        this.boss=null;
    }
    @Input('skipToRewards') set skipToRewards(now: boolean) {
        if(now) {
            this.winning_message['items'] = this.getRewardsText('items');
            this.winning_message['points'] = this.getRewardsText('points');
            this.viewRewards=true;
        }
    }
    close_rewards(replay?) {
        this.viewRewards = false;
        this.data.setShowRewards(false);
        //console.log('close_rewards',this.viewRewards)
        if(replay) {
            this.reset_all_items();
            this.reset.emit(1);
        }
    }
    set_height() {
        if(window.innerWidth < 768) {
            if(this.SummonerContainer && this.MonsterContainer) {
                var s = this.SummonerContainer.nativeElement.offsetHeight;
                var h = this.MonsterContainer.nativeElement.offsetHeight;
                this.playfeild_min_height = h+s+225;
            }
        } else {
        }
    }
    changeLogSpeed(n) {
        this.change_speed.emit(n);
    }
    skip_intro() {
        this.fade_vs=false;
        this.intro_cards=false;
        this.preamble_fade=false;
        this.red_spark_down=false;
        this.blue_spark_down=false;
        this.preamble=false;
        this.log_extra_wait=0;
        this.updateResult();
    }
    reset_all(turn) {
        this.selected[turn] = 'off';
        this.attacking[turn] = 'off';
        this.miss[turn] = 'off';
        this.hit[turn] = 'off';
        this.hit_down[turn] = 'off';
        this.hit_damage[turn] ='off';
        this.hit_damage_type[turn] = 'off';
        this.dissolve[turn] = 'off';
        this.attack[turn] = 'off';
        this.shaken[turn] = 'off';
        this.ranged[turn] = 'off';
        this.fade[turn] = 'off';
        this.jump_down[turn] = 'off';
        this.spart_burst[turn] = 'off';
        this.spark_across[turn] = 'off';
        this.blue_spark_across[turn] = 'off';
        this.blue_crash_across[turn] = 'off';
        // this.damageArrowType = '';
        // this.hit_damage = 0;
    }
    reset_all_items() {
        this.selected = [];
        this.attacking = [];
        this.miss = [];
        this.hit = [];
        this.hit_down = [];
        this.hit_damage = [];
        this.hit_damage_type = [];
        this.dissolve = [];
        this.attack = [];
        this.shaken = [];
        this.ranged = [];
        this.fade = [];
        this.jump_down = [];
        this.spart_burst = [];
        this.spark_across = [];
        this.blue_spark_across = [];
        this.blue_crash_across = [];
        this.viewRewards = false;
        this.rewardedForgium = 0;
        this.rewardedElectrum = 0;
        this.sound.stop_sounds();
        this.sound.stop_music();
        this.data.setShowRewards(false);
    }
    get_card_position(name) {
        var sel = '[name="'+name+'"]';
        var doc = document.querySelector(sel);
        if(doc) {
            var offsets = doc.getBoundingClientRect();
            return {
                x:offsets.x,
                y:offsets.y,
                height: doc.clientHeight,
                width: doc.clientWidth
            }
        }
    }
    get_card_widths() {
        if(window.innerWidth < 768) {
            this.card_width = 'auto';
        } else if(this.monster_container) {
            var width = this.monster_container.nativeElement.offsetWidth;
            this.card_width = this.card_width=='auto'?Number(width):Number(Number(this.card_width)+Number(width));
            return width;    
        }
    }
    toggle_sound_volume() {
        this.show_music_vol=false;
        this.show_sound_vol=this.show_sound_vol?false:true; 
    }
    toggle_music_volume() { 
        this.show_sound_vol=false;
        this.show_music_vol=this.show_music_vol?false:true; 
    }
    toggle_sound_mute() {
        if(this.sound_vol == -100) {
            this.sound_vol = this.sound.last_sfx_vol
        } else {
            this.sound.last_sfx_vol = this.sound_vol;
            this.sound_vol = -100;
        }
        this.sound_volume();
    }
    toggle_music_mute() {
        if(this.music_vol == -100) {
            this.music_vol = this.sound.last_music_vol;
        } else {
            this.sound.last_music_vol = this.music_vol;
            this.music_vol = -100;
        }
        this.music_volume();
    }
    blip_sound(e?){
        // this.sound.play_sfx('tone','play','sfx',false);
        console.log('blip_sound');
    }
    blip_music(e?){
        // this.sound.play_sfx('tone','play','music',false);
        console.log('blip_music');
    }
    music_volume(e?) {
        this.mute_music=true;
        this.sound.set_music_volume(this.music_vol);
        if(this.music_vol==-100)this.mute_music=false;
        if(this.mute_music)this.sound.start_music();
    }
    sound_volume(e?) {
        this.mute_sounds=true;
        this.sound.set_sound_volume(this.sound_vol);
        if(this.sound_vol==-100)this.mute_sounds=false;
        //if(this.mute_sounds)this.sound.resume_music();
    }
    sound_volume_end(e?) {
        if(this.sound_blip)this.blip_sound();
    }
    music_volume_end(e?) {
        if(this.sound_blip)this.blip_music();
    }
    position_angle(turn, target) {
        if(turn && target) {
            var card_position = this.get_card_position(turn);
            var dmg = document.querySelector('#damage_arrow_weapon');
            if(card_position && dmg){
                var ux = card_position.y - (dmg.clientHeight/2) + (card_position.height/4) + window.scrollY;
                var uy = card_position.x - (dmg.clientWidth/2) + (card_position.width/2);
            }
            var target_position = this.get_card_position(target);
            if(target_position && dmg){
                var tx = target_position.y - (dmg.clientHeight/2) + (target_position.height/4) + window.scrollY;
                var ty = target_position.x - (dmg.clientWidth/2) + (target_position.width/2);
            }
            this.damage_angle(ux, uy, tx, ty);
        }
    }
    damage_angle(cx, cy, ex, ey) {
        // var dy = ey - cy;
        // var dx = ex - cx;
        // var theta = Math.atan2(dy, dx); // range (-PI, PI]
        // theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
        // if (theta < 0) theta = 360 + theta; // range [0, 360)
        this.weapon_rotate = Number(0-Math.atan2(cy - ey, cx - ex) * 180 / Math.PI);
        this.weapon_container_rotate=0;
    }
    positioning_weapon: boolean;
    positioning_marker: boolean;
    positioning_: any;
    position_by_name(name) {
        //console.log('position_by_name',name,this.hit_damage)
        if(name) {
            this.positioning_marker=false;
            // if(this.positioning_)clearTimeout(this.positioning_);
            var card_position = this.get_card_position(name);
            var wep = document.querySelector('#damage_arrow_weapon');
            var dmg = document.querySelector('#damage_arrow');
            if(card_position){
                this.positioning_marker = true;
                this.positioning_weapon = true;
                var addition = name==this.boss.name ? 100 : 50;
                var subtraction = 142
                if(wep)this.weapon_top = Number(card_position.y - (wep.clientHeight/2) + (card_position.height/4) + window.scrollY + addition - subtraction);
                if(wep)this.weapon_left = Number(card_position.x - (wep.clientWidth/2) + (card_position.width/2));
                if(dmg)this.damage_top = Number(card_position.y - (dmg.clientHeight/2) + (card_position.height/4) + window.scrollY + addition - subtraction);
                if(dmg)this.damage_left = Number(card_position.x - (dmg.clientWidth/2) + (card_position.width/2));
                this.positioning_= this.requestTimeoutRaf(()=>{
                    this.positioning_marker=false;
                    this.positioning_weapon=false;
                },1800);
            }
        }
    }


    card_style_top = [];
    card_style_left = [];
    card_style_top_og = [];
    card_style_left_og = [];
    position_card(name, target) {
        this.card_style_float[name]=true;
        var sel = '[name="'+name+'"]';
        var card = document.querySelector(sel);
        var doc = document.querySelector(sel);
        if(doc) {
            var offsets = doc.getBoundingClientRect();
            this.card_style_top[name] = offsets.y-(doc.clientHeight/4)+window.scrollY;
            this.card_style_left[name] = offsets.x-(doc.clientWidth/2);
            if(this.verbose)console.log('position card','top',this.card_style_top[name],'left',this.card_style_left[name]);
            this.requestTimeoutRaf( () => {
                this.reset_card_position(name);
            }, 500);
        }
    }
    reset_card_position(name) {
        this.card_style_top[name] = this.card_style_top_og[name];
        this.card_style_left[name] = this.card_style_left_og[name];
        if(this.verbose)console.log('reset card position','top',this.card_style_top[name],'left',this.card_style_left[name]);
        this.requestTimeoutRaf( () => {
            this.card_style_top_og[name] = 0;
            this.card_style_left_og[name] = 0;
            this.card_style_float[name] = false;
        }, 500)
    }
    setup_fw() {
        this.update_fw=true;
        this.requestTimeoutRaf(()=>{this.update_fw= false;},300);
    }
    changed:any;
    ngAfterViewInit() {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        this.data.setShowRewards(false);
        this.userSub = this.data.onUserChange().subscribe((u)=>{
            this.user = u;
        });
        this.resultSub = this.data.onResultChange().subscribe((r) =>  {
            if (r) {
                this.result = r;
                if (this.result?.uniqueRules && this.boss) this.boss.uniqueRules = this.result.uniqueRules;
                if (this.result&&this.result.team) {
                    if(!this.total_summoner)this.total_summoner=Number(this.type.transform(this.result.team,'Summoner').length);
                    if(!this.total_monsters)this.total_monsters=Number(this.type.transform(this.result.team,'Monster').length);
                    if(!this.total_cards)this.total_cards=Number(this.total_summoner+this.total_monsters+2);
                    this.requestTimeoutRaf(()=>{
                        if(!this.staggering && this.result.team && !this.delt_cards){
                            this.stagger_deal_cards();
                            this.delt_cards=true;
                            this.setup_fw();
                        }
                        this.staggering=true;
                    },1000);
                    this.set_height();
                    this.result.team.forEach((c) => {
                        c.imgURL = this.helper.tempGetCardImgUrl(c);
                        if(c.uid)c['uid_split'] = c.uid.split(' ')[0];
                        if(c.uid)c['uid_hero_index'] = c.uid.indexOf('hero');
                        if(c.uid_hero_index>-1) {
                            c.id = 0; //console.log(c);
                            this.currentClass = '';
                            //if (c.uid.indexOf('Warrior') > -1) this.currentClass = 'Warrior';
                            //if (c.uid.indexOf('Wizard') > -1) this.currentClass = 'Wizard';
                            //if (c.uid.indexOf('Ranger') > -1) this.currentClass = 'Ranger';
                            this.currentClass = c.currentClass;
                            c.imgURL = '/assets/heroes/' + this.currentClass.toLowerCase() + '_sketch.png';
                            console.log(c.imgURL, c.type, this.currentClass, c);
                        }
                        if (c.abilities.length > 0) {
                            let newAbilities = [];
                            c.abilities.forEach(a => { if(!newAbilities.includes(a) && !a.match(/\d+/g)) newAbilities.push(a);  }); 
                            c.abilities = newAbilities;
                        }
                    });
                }
            }
        });
        this.replayBossSub = this.data.onReplayBossChange().subscribe((b)=>{
            this.boss = b;
            if(this.boss)this.loaded.emit(true);
            if (this.result?.uniqueRules) {
                this.result.uniqueRules.forEach(r => {
                    if (!this.boss.stats.abilities.includes(r)) this.boss.stats.abilities.push(r);
                })
            } //this.boss.stats.abilities = this.boss.stats.abilities.concat(this.result.uniqueRules);// this.boss.uniqueRules = this.result.uniqueRules;
        });
        this.data.onBossViewChange().subscribe((v)=>{
            this.bossView = v;
        });
        this.data.onLogSpeedChange().subscribe((ls)=>{
            this.logSpeed = ls;
        });
        this.data.onShowRewardsChange().subscribe((sr)=>{
            this.viewRewards = sr;
            //console.log('on Show Rewards Change',this.viewRewards)
            if(this.viewRewards){
                this.winning_message['items'] = this.getRewardsText('items');
                this.winning_message['points'] = this.getRewardsText('points');
                this.showDamageArrow = false;
                this.sound.stop_music();
            }
        });
        this.scrollingSub = this.data.onScrollingChange().subscribe((s) => {
            this.scrolling = s;
            //this.requestTimeoutRaf(()=>{ this.show_log = s.length; },500);
            this.show_log = s.length;
        });
    }
    cards_loaded(type) {
        this.set_height();
    }
    updateResult() {
        if(this.battle_stopped) return;
        if(this.battle_paused){
            this.pause_checker = setInterval(()=>{
                if(!this.battle_paused && this.logSpeed>0) {
                    this.updateResult();
                    if(this.pause_checker)clearInterval(this.pause_checker);
                }
            }, 250);
        } else {
            if (this.boss&&this.boss.stats&&this.boss.stats.health&&this.boss.stats.health < 1) {
                this.boss.stats.health = this.boss.ogStats.health;
                this.boss.stats.armor = this.boss.ogStats.armor;
            }
            let res = this.result;
            if (!res) return;
            let len = res.actions.length;
            let act = res.actions[0];
            this.dmg_delay = Number(1001-this.logSpeed);
            if (len > 0) {
                let team = res.team;
                let turn = team.find(c => c.name === act.turn);
                let targ = team.find(c => c.name === act.target);
                if (this.boss && this.boss.name) act.targetURL = targ ? targ.imgURL : '/assets/boss/' + this.boss.name + '.png';
                if (this.boss && this.boss.name) act.turnURL = turn ? turn.imgURL : act.turnID === 0 ? '/assets/boss/' + this.boss.name + '.png' : '';
                act.dmgType = (act.action.indexOf('magic hit') > -1 || act.action.indexOf('reflect by')) > -1 ? 'magic' : 
                (act.action.indexOf('ranged hit') > -1 || act.action.indexOf('return fire by')) > -1 ? 'ranged' : 
                (act.action.indexOf('attack hit') > -1 || act.action.indexOf('thorns on') > -1 || act.action.indexOf('retaliate by') > -1) ? 'attack' : 
                act.turn.indexOf('DEATH') > -1 ? 'death' : '';
                if (act.target === 'team') {
                    for(let i = 0; i < team.length; i++ ) {
                        if(!this.result.team[i]['effects']) this.result.team[i]['effects'] = [];
                        var appl = act.action.split('apply ');
                        if(appl.length > 0 && !this.result.team[i]['effects'].includes(appl[1])){
                            this.result.team[i]['effects'].push(appl[1]);
                        }
                        if (act.action === 'apply Inspire' && this.result.team[i].ogAttack > 0) {
                            this.result.team[i].ogAttack += act.value;
                        } else if (act.action === 'apply Strengthen') {
                            this.result.team[i].ogHealth += act.value; this.result.team[i].ogMaxHealth += act.value;
                        } else if (act.action === 'apply Protect') {
                            this.result.team[i].ogArmor += act.value; this.result.team[i].ogMaxArmor += act.value;
                        } else if (act.action === 'plus attack' && this.result.team[i].ogAttack > 0) {
                            this.result.team[i].ogAttack += act.value;
                            act['dmgType']='attack';
                        } else if (act.action === 'plus ranged' && this.result.team[i].ogRanged > 0) {
                            this.result.team[i].ogRanged += act.value;
                            act['dmgType']='ranged';
                        } else if (act.action === 'plus magic' && this.result.team[i].ogMagic > 0) {
                          this.result.team[i].ogMagic += act.value;
                          act['dmgType']='magic';
                        } else if (act.action === 'plus armor') { 
                            this.result.team[i].ogArmor += act.value; this.result.team[i].ogMaxArmor += act.value;
                            act['dmgType']='armor';
                        } else if (act.action === 'plus health') { 
                            this.result.team[i].ogHealth += act.value; this.result.team[i].ogMaxHealth += act.value;
                            act['dmgType']='health';
                        } else if (act.action === 'plus speed') {
                            this.result.team[i].ogSpeed += act.value;
                            act['dmgType']='speed';
                        }
                    }
                } else if (act.target === 'boss' || act.target === this.boss.name) {
                    var appl = act.action.split('apply ');
                    if(appl.length>0&&!this.boss.effects.includes(appl[1])){
                        this.boss.effects.push(appl[1]);
                    }
                    if (act.action === 'apply Rust' && this.boss.stats.armor > 0) {
                        this.boss.stats.armor > 1 ? this.boss.stats.armor -= act.value : this.boss.stats.armor = 0;
                    } else if (act.action === 'apply Slow' && this.boss.stats.speed > 3) {
                        this.boss.stats.speed -= act.value;
                    } else if (act.action === 'apply Weakness') {
                        this.boss.stats.health -= act.value;
                    } else if (act.action === 'apply Demoralize' && this.boss.stats.attack > 3) {
                        this.boss.stats.attack -= act.value;
                    } else if (act.action === 'apply Headwinds' && this.boss.stats.ranged > 3) {
                        this.boss.stats.ranged -= act.value;
                    } else if (act.action === 'apply Silence' && this.boss.stats.magic > 3) {
                        this.boss.stats.magic -= act.value;
                    } else if (act.action === 'minus attack' && this.boss.stats.attack > 3) {
                        this.boss.stats.attack -= act.value;
                        act['dmgType']='attack';
                    } else if (act.action === 'minus ranged' && this.boss.stats.ranged > 3) {
                        this.boss.stats.ranged -= act.value;
                        act['dmgType']='ranged';
                    } else if (act.action === 'minus magic' && this.boss.stats.magic > 3) {
                        this.boss.stats.magic -= act.value;
                        act['dmgType']='magic';
                    } else if (act.action === 'minus armor' && this.boss.stats.armor > 0) {
                        this.boss.stats.armor -= act.value;
                        act['dmgType']='armor';
                        if (this.boss.stats.armor < 0) this.boss.stats.armor = 0;
                    } else if (act.action === 'minus health') {
                        this.boss.stats.attack -= act.value;
                        act['dmgType']='health';
                    } else if (act.action === 'minus speed' && this.boss.stats.speed > 3) {
                        this.boss.stats.speed -= act.value;
                        act['dmgType']='speed';
                    } else if (act.action.indexOf('hit') > -1 || act.action.indexOf('retaliate') > -1 || act.action.indexOf('thorns') > -1 || act.action.indexOf('return fire') > -1 || act.action.indexOf('reflect') > -1) {
                        let voidArmor = this.boss.stats.abilities.includes('Void Armor');
                        if (act.action.indexOf('magic') > -1) {
                            if (voidArmor && this.boss.stats.armor > 0) {
                                this.requestTimeoutRaf( () => { this.boss.stats.armor -= act.value; }, this.dmg_delay+500);
                            } else {
                                this.requestTimeoutRaf( () => { this.boss.stats.health -= act.value; }, this.dmg_delay+500);
                            }
                        } else {
                            if (this.boss.stats.armor > 0) {
                                this.requestTimeoutRaf( () => { this.boss.stats.armor -= act.value;  }, this.dmg_delay+500);
                            } else {
                                this.requestTimeoutRaf( () => { this.boss.stats.health -= act.value; }, this.dmg_delay+500);
                            }
                        }
                        // let mob = document.getElementById(act.turnID);
                        // if (mob) {
                        //     if (mob.className.length > 0) {
                        //         mob.className = '';
                        //         mob.className = act.action.indexOf('attack') > -1 ? 'meleeattack' : act.action.indexOf('ranged') > -1 ? 'rangedattack' : act.action.indexOf('magic') > -1 ? 'magicattack' : '';
                        //     } else {
                        //         mob.className = act.action.indexOf('attack') > -1 ? 'meleeattack' : act.action.indexOf('ranged') > -1 ? 'rangedattack' : act.action.indexOf('magic') > -1 ? 'magicattack' : '';
                        //     }
                        // }
                    }
                } else {
                    if (act.action.indexOf('hit') > -1) {
                        act.success = true;
                        let temp = team.find(c => c.name === act.target);
                        if (temp) {
                            this.requestTimeoutRaf(()=>{
                                if (act.action.indexOf('magic') > -1) {
                                    if (temp.ogAbilities.includes('Void Armor') && temp.ogArmor > 0 ) {
                                        this.requestTimeoutRaf( () => { temp.ogArmor -= act.value; 
                                        }, this.dmg_delay);
                                    } else {
                                        this.requestTimeoutRaf( () => { temp.ogHealth -= act.value; 
                                        }, this.dmg_delay);
                                    } 
                                } else {
                                    if(temp.ogArmor > 0) {
                                        this.requestTimeoutRaf( () => { 
                                            temp.ogArmor -= act.value; 
                                        }, this.dmg_delay);
                                    } else {
                                        this.requestTimeoutRaf( () => { 
                                            temp.ogHealth -= act.value; 
                                        }, this.dmg_delay);
                                    }
                                }
                                if (temp.ogArmor < 0) {
                                    this.requestTimeoutRaf( () => { temp.ogArmor = 0; }, this.dmg_delay);
                                }
                            },1000);
                        } else {
                            if(this.verbose)console.log('no target found for attack - should never e logged');
                        }
                    }
                }
                var k = act.action[act.action.length-1];
                // if(k!='!'||k!='.')act.action=act.action+'.';
                // TODO clense, divine shield, affliction, dispel, halving, shatter, snare
                if (act.action.toLowerCase().indexOf('attack ') > -1) {
                    act.dmgType='attack';
                }
                if (act.action.toLowerCase().indexOf('ranged ') > -1) {
                    act.dmgType='ranged';
                }
                if (act.action.toLowerCase().indexOf('backfire ') > -1) {
                    act.dmgType='backfire';
                }
                if (act.action.toLowerCase().indexOf('scavenged') > -1) {
                    let temp = team.find(c => c.name === act.target);
                    if (temp) {
                        temp.ogHealth += act.value;
                        temp.ogMaxHealth += act.value;
                    }
                } else if (act.action.indexOf('becomes enraged') > -1) {
                    if(this.verbose)console.log('boss becomes enraged');
                    this.boss.stats.attack += act.value;
                    this.boss.stats.ranged += act.value;
                    this.boss.stats.magic += act.value;
                    this.boss.stats.speed += act.value;
                } else if (act.action.indexOf('heal') > -1) {
                    if (act.action.indexOf('failed') > -1) {
                        act['dmgType']='heal_failed';
                        var b = act.action.indexOf(' becasue of ');
                        if (b > -1) act['healFailure']==act.action.split(' becasue of ')[1];
                    } else {
                        act['dmgType']='heal';
                    }
                } else if (act.action.indexOf('retaliate') > -1) {
                    if (act.action.indexOf('failed') > -1) {
                        act['dmgType']='retaliation_failed';
                    } else {
                        act['dmgType']='retaliate';
                    }
                }  else if (act.action.indexOf('repair') > -1) {
                    if (act.action.indexOf('failed') > -1) {
                        act['dmgType']='repair_failed';
                    } else {
                        act['dmgType']='repair';
                    }
                } else if (act.action.indexOf('cripple') > -1) {
                    act['dmgType']='cripple';
                } else if (act.action.indexOf('leech') > -1) {
                    act['dmgType']='leech';
                    let temp = team.find(c => c.name === act.turn);
                    if (temp) {
                        temp.ogHealth += act.value;
                        temp.ogMaxHealth += act.value;
                    } else {
                        if(this.verbose)console.log('no target found for life leech - should never be logged');
                    }
                } else if (act.action.indexOf('poison') > -1) {
                    this.boss.stats.health -= act.value;
                    act['dmgType']='poison';
                } else if (act.action.indexOf('cripple') > -1) {
                    this.boss.stats.armor -= act.value;
                    if (this.boss.stats.armor < 0 ) this.boss.stats.armor = 0;
                } else if (turn === 'fatigue') {
                    let temp = team.find(c => c.name === act.target);
                    if (temp) {
                        temp.ogHealth -= act.value;
                    } else {
                        if(this.verbose)console.log('no target found for fatigue');
                    }
                } else if (act.action.indexOf('heal ') > -1 || act.action.indexOf('triage') > -1) {
                    if (act.action.indexOf('failed') > -1) {
                        act['dmgType']='heal_failed';
                        var b = act.action.indexOf(' becasue of ');
                        if (b > -1) act['healFailure']==act.action.split(' becasue of ')[1];
                    } else {
                        act['dmgType']='heal';
                    }
                    let temp = team.find(c => c.name === act.target);
                    if (temp) {
                        temp.ogHealth + act.value > temp.ogMaxHealth ? temp.ogHealth = temp.ogMaxHealth : temp.ogHealth += act.value;
                    } else {
                        if(this.verbose)console.log('no target found for heal/triage - should not trigger - heal amount: ' + act.value, act);
                    }
                } else if (act.action.indexOf('repair') > -1) {
                    let temp = team.find(c => c.name === act.target);
                    if (temp) {
                        temp.ogArmor + act.value > temp.ogMaxArmor ? temp.ogArmor = temp.ogMaxArmor : temp.ogArmor += act.value;
                    } else {
                        if(this.verbose)console.log('no target found for repair - should not trigger - repair amount: ' + act.value);
                    }
                } else if (turn === 'RESURRECT') {
                    let temp = team.find(c => c.name === act.target);
                    if (temp) {
                        temp.ogHeath = 1;
                        temp.ogArmor = temp.ogMaxArmor;
                    } else {
                        if(this.verbose)console.log('no target found for resurrect - should never trigger');
                    }
                } else if (act.action.indexOf('Last Stand ') > -1) {
                    let mob = team.find(c => c.name === act.turn);
                    if (mob) {
                        mob.ogHealth = Math.floor(mob.ogHealth * 1.5);
                        mob.ogMaxHealth = Math.floor(mob.ogMaxHealth * 1.5);
                        mob.ogSpeed = Math.floor(mob.ogSpeed * 1.5);
                        mob.ogAttack = Math.floor(mob.ogAttack * 1.5);
                        mob.ogRanged = Math.floor(mob.ogRanged * 1.5);
                        mob.ogMagic = Math.floor(mob.ogMagic * 1.5);
                    } else {
                        if(this.verbose)console.log('no target found for last stand - should never trigger')
                    }
                }
            }
            this.forge_speed = Number(2000-this.logSpeed);
            //console.log('this.forge_speed',this.forge_speed)
            if (len === 0) {
                this.requestTimeoutRaf(() => {
                    let message = 'You have received '
                    for (let i = 0; i < res.rewards.length; i++) {
                        let reward = res.rewards[i];
                        if (i === 0) {
                            message += reward.qty + ' ' + reward.name;
                            if (reward.type === 'potion') message += ' potion(s)';
                        } else if (i === res.rewards.length - 1) {
                            message += ', and ' + reward.qty + ' ' + reward.name;
                            if (reward.type === 'potion') message += ' potion(s).';
                            else message += '.';
                        } else {
                            message += ', ' + reward.qty + ' ' + reward.name;
                            if (reward.type === 'potion') message += ' potion(s)';
                        }
                    };
                    //this.isBossEntry(message, this.boss.name);
                    //this.isDmg(message);
                    this.data.setScrolling(this.scrolling);
                    if (this.boss.stats.health < 0) {
                        res.actions[0] = {
                            type: 'action', 
                            turn: 'post fight', 
                            action: 'You have killed ' +this.boss.name+ '!!' +' You recieved ' + res.points + ' points!' 
                        };
                        this.scrolling.push(res.actions[0]);
                    } else {
                        res.actions[0] = {
                            type: 'action', 
                            turn: 'post fight', 
                            action: 'All your monsters are dead, you recieved ' + res.points + ' points!' 
                        }
                        this.scrolling.push(res.actions[0]);
                        this.data.setShowRewards(true);
                    }
                    var log = { 
                        type: 'action', 
                        turn: 'post fight', 
                        action: message
                    };
                    console.log('post fight',log)
                    this.scrolling.push(log);
                    this.scroll_log();
                }, this.logSpeed * 2);
            } else {
                //this.isBossEntry(res.actions[0], this.boss.name);
                //this.isDmg(res.actions[0]);
                res.actions[0]['type']='action';
                if(res.actions[0].turn=='RESURRECT' && res.actions[0].action.includes('resurrected')){
                    var apply = res.actions[0].action.split(' resurrected ');
                    res.actions[0]['team']=apply[0];
                    this.dead[res.actions[0].target]=false;
                } else if ((res.actions[0].turn=='pre fight'&&res.actions[0].action.includes('plus '))||(res.actions[0].turn=='pre fight'&&res.actions[0].action.includes('minus '))){
                    var apply = res.actions[0].action.split(' ');
                    res.actions[0]['dmgType']=apply[1]
                    res.actions[0]['type']='buff';
                } else if ((res.actions[0].turn=='pre fight'&&res.actions[0].action.includes('apply +'))||(res.actions[0].turn=='pre fight'&&res.actions[0].action.includes('apply -'))){
                    var apply = res.actions[0].action.split(' ');
                    res.actions[0]['dmgType']=apply[2].toLowerCase();
                    var spl = apply[1].split('+');
                    res.actions[0]['value']=spl[1];
                    res.actions[0]['type']='buff';
                } else if (res.actions[0].turn=='pre fight'&&res.actions[0].action.includes('apply ')){
                    var apply = res.actions[0].action.split('apply ');
                    res.actions[0]['ability']=apply[1];
                    res.actions[0]['type']='ability';
                }
                if(res&&res.actions[0]&&res.actions[0].action.includes(' miss '))res.actions[0].dmgType='miss';
                if(res&&res.actions[0]&&res.actions[0].action.includes('ranged miss '))res.actions[0].dmgType='ranged_miss';
                var scrolling = false;
                for(let s of this.scrolling){
                    if(s.turnID!=0 && s.turnID==res.actions[0].turnID)scrolling=true;
                }
                //if(!this.scrolling.includes(res.actions[0])) {
                this.scrolling.push( res.actions[0] );
                //    //console.log( res.actions[0] );
                //}
                this.data.setScrolling(this.scrolling);
                this.data.setResult(this.result);
                this.scroll_log();
                this.showDamageArrow = false;
                this.requestTimeoutRaf(() => {
                    if(res && res.actions[0] && res.actions[0].turn) {
                        this.battle_result = res;
                        this.animate(res);
                        res.actions = res.actions.slice(1);
                    }
                }, this.logSpeed-100);
            }
        }
    }
    animate(res, cb?) {
        this.increase_damage(res.totalDmg);
        var turn   = res.actions[0].turn;
        var target = res.actions[0].target;
        var damage = res.actions[0].dmgType;
        if(turn=='DEATH')damage='death';
        this.play = res;
        this.log_extra_wait = 150;
        if(res.actions[0].action == 'preamble') {
            if(res.actions[0].boss_starting_health)this.boss.stats.health = res.actions[0].boss_starting_health;
            if(res.actions[0].boss_starting_armor)this.boss.stats.armor = res.actions[0].boss_starting_armor;
            this.preamble=true;
            this.preamble_animations=true;
            this.log_extra_wait = 6000;
            this.show_skip_intro=true;
            this.intro_cards=true;
            this.requestTimeoutRaf(()=>{
                this.fade_vs=true;
            }, 1700);
            this.requestTimeoutRaf(()=>{
                if(!this.battle_paused)this.sound.play_sfx('mixkit-cinematic-impact-thunder-1286','play','sfx',false);
                if(this.rand==0) {
                    this.red_spark_down=true;
                } else {
                    this.blue_spark_down=true;
                }
            }, 2300);
            this.requestTimeoutRaf(()=>{
                this.fade_vs=false;
                this.intro_cards=false;
                this.preamble_fade=false;
                this.red_spark_down=false;
                this.blue_spark_down=false;
                this.show_skip_intro=false;
            }, 5000);
            this.requestTimeoutRaf(()=>{
                this.preamble=false;
                this.boss_effects=true;
            }, 7000);
        } else {
            // console.log('turn',turn)this.damageArrowType = 'buff';
            // if(turn == this.boss.name) { // } else 
            if(target=='team') {
                this.selected_team = true;
                this.requestTimeoutRaf(()=>{
                    this.selected_team = false;
                },this.logSpeed);
            }
            if(turn == 'pre fight') {
                this.preamble_animations=true;
                if(res&&res.actions[0]&&res.actions[0].turn&&res.actions[0].action.includes('apply')){
                    this.hit_damage_type = damage;
                    if(res.actions[0].type=="ability") {
                        if(target=='boss') {
                            this.boss_ability=res.actions[0].ability;
                            this.requestTimeoutRaf( ()=>{ this.boss_ability=null; },1350);
                        } else if(target=='team') {
                            this.ability=res.actions[0].ability;
                            this.requestTimeoutRaf( ()=>{ this.ability=null; },1350);
                        } else {
                            this.ability_popover[target]=res.actions[0].ability;
                            this.requestTimeoutRaf( ()=>{ this.ability_popover[target]=null; },1350);
                        }
                    }
                    // if(!this.battle_paused)this.sound.play_sfx('playing_cards_'+1,'play','sfx',false);
                    // this.selected[target] = true;
                }
            } else if (target == 'swipe') {
                if(this.verbose)console.log('Swipes all your monsters');
            } else {
                var changeSpeed = this.logSpeed+600;
                this.preamble_animations=false;
                this.attacking[turn] = true;
                this.show_splash_damage[target]=false;
                this.DamageTransitionTime = 'transform 0ms, opacity 100ms';
                this.hit_damage = res.actions[0].value;
                this.position_by_name(turn);
                this.position_angle(turn,target);
                this.damageArrowType = damage;
                this.showDamageArrow = true;
                var delay_time = 0;
                var damage_time = Number( this.logSpeed-100 );
                var damage_transition_time = Number(300+damage_time);
                this.DamageTransitionTime = 'transform '+damage_transition_time+'ms, opacity 100ms';
                if(res&&res.actions[0]&&res.actions[0].action.includes('repair ')) damage='repair'; 
                if( (res.actions[0].target != res.actions[0].turn) &&(res.actions[0].turn!=this.boss.name && res.actions[0].target!=this.boss.name)&&res.actions[0].turn!='DEATH')damage='buff';
                this.damageArrowType=damage;
                this.requestTimeoutRaf(()=>{
                    if(turn!='DEATH'){
                        if(res&&res.actions[0]&&res.actions[0].action.includes(' miss '))damage='miss';
                        this.position_by_name(target);
                        // this.weapon_rotate = Number(this.weapon_rotate-21);
                        this.weapon_container_rotate = Number(this.weapon_container_rotate+71);
                        if(this.show_effects&&damage!='buff' && Number(this.hit_damage)>0) {
                            this.requestTimeoutRaf(()=>{this.show_splash_damage[target]=this.hit_damage;},300+damage_time);
                            this.requestTimeoutRaf(()=>{this.show_splash_damage[target]=false;},300+damage_time);
                        }
                    }
                    //console.log('changeSpeed', changeSpeed, this.logSpeed);
                    switch (damage) {
                        case "attack":
                            this.running_fw_options = red_orange_fw_options;
                            this.update_fw=true;
                            if(this.logSpeed<700&&this.enable_fw)this.requestTimeoutRaf(()=>{
                                this.fire_fw(this.damage_left+25, this.damage_top+25, 'red_orange',res.actions[0].value,(target==this.boss.name));
                            },damage_transition_time-100);
                            this.hit_damage_type = damage;
                            this.requestTimeoutRaf(()=>{
                                if(!this.battle_paused){
                                    var r=Math.floor(Math.random()*3);
                                    if(r==0)this.sound.play_sfx('Magic_Sword_Attack_0'+1,'play','sfx',false);
                                    if(r==1)this.sound.play_sfx('Magic_Sword_Attack_0'+2,'play','sfx',false);
                                    if(r==2)this.sound.play_sfx('Magic_Sword_Attack_0'+3,'play','sfx',false);
                                    if(r==3)this.sound.play_sfx('Magic_Sword_attack_withBlood_0'+1,'play','sfx',false);
                                }
                                this.hit[target] = true;
                            }, damage_time-delay_time);
                        break;
                        case "magic":
                            this.running_fw_options = purple_fw_options;
                            this.update_fw=true;
                            if(this.logSpeed<700&&this.enable_fw)this.requestTimeoutRaf(()=>{
                                this.fire_fw(this.damage_left+25, this.damage_top+25, 'purple',res.actions[0].value,(target==this.boss.name));
                            },damage_transition_time-100);
                            this.hit_damage_type = damage;
                            this.requestTimeoutRaf(()=>{
                                if(!this.battle_paused){
                                    var r=Math.floor(Math.random()*2);
                                    if(r==0)this.sound.play_sfx('Elemental_Sword_IceAttack V'+1,'play','sfx',false);
                                    if(r==1)this.sound.play_sfx('Elemental_Sword_IceAttack V'+2,'play','sfx',false);
                                    if(r==2)this.sound.play_sfx('Elemental_Sword_IceAttack V'+3,'play','sfx',false);
                                }
                                this.hit[target] = true;
                            }, damage_time-delay_time);
                        break;
                        case "ranged":
                            this.running_fw_options = red_orange_fw_options;
                            this.update_fw=true;
                            this.hit_damage_type = damage;
                            if(this.logSpeed<700&&this.enable_fw)this.requestTimeoutRaf(()=>{
                                this.fire_fw(this.damage_left+25, this.damage_top+25, 'red_orange',res.actions[0].value,(target==this.boss.name));
                            },damage_transition_time-100);
                            this.requestTimeoutRaf(()=>{
                                if(!this.battle_paused){
                                    /*var r=Math.floor(Math.random()*2);
                                    if(r==0)*/this.sound.play_sfx('Elemental_Sword_EarthAttack_0'+1,'play','sfx',false);
                                }
                                this.hit[target] = true;
                            }, damage_time-delay_time);
                        break;
                        case "heal":
                            this.running_fw_options = red_fw_options;
                            this.update_fw=true;
                            this.hit_damage_type = damage;
                            if(this.logSpeed<700&&this.enable_fw)this.requestTimeoutRaf(()=>{
                                this.fire_fw(this.damage_left+25, this.damage_top+25, 'red',res.actions[0].value,(target==this.boss.name));
                            },damage_transition_time-100);
                            this.requestTimeoutRaf(()=>{
                                if(!this.battle_paused){
                                    var r=Math.floor(Math.random()*2);
                                    if(r==0)this.sound.play_sfx('Elemental_Sword_EarthAttack_0'+1,'play','sfx',false);
                                }
                                this.hit[target] = true;
                            }, damage_time-delay_time);
                        break;
                        case "poison":
                            this.running_fw_options = green_fw_options;
                            this.update_fw=true;
                            this.hit_damage_type = damage;
                            if(this.logSpeed<700&&this.enable_fw)this.requestTimeoutRaf(()=>{
                                this.fire_fw(this.damage_left+25, this.damage_top+25, 'green',res.actions[0].value,(target==this.boss.name));
                            },damage_transition_time-100);
                            this.requestTimeoutRaf(()=>{
                                if(!this.battle_paused){
                                    var r=Math.floor(Math.random()*2);
                                    if(r==1)this.sound.play_sfx('Elemental_Sword_PoisonAttack_0'+1,'play','sfx',false);
                                    if(r==2)this.sound.play_sfx('Elemental_Sword_PoisonAttack_0'+2,'play','sfx',false);
                                }
                                this.hit[target] = true;
                            }, damage_time-delay_time);
                        break;
                        case "death":
                            this.damageArrowType = 'death';
                            this.hit_damage_type = damage;
                            //console.log('damage death:', damage);
                            this.requestTimeoutRaf(()=>{
                                if(!this.battle_paused){
                                    this.sound.play_sfx('playing_cards_'+1,'play','sfx',false);
                                }
                                this.hit_down[target] = true;
                                this.dead[target] = true;
                                this.requestTimeoutRaf(()=>{
                                    this.dead[target] = true;
                                },changeSpeed);
                            }, damage_time-delay_time);
                        break;
                        case "miss":
                            //console.log("miss FIRED!")
                            this.running_fw_options = white_fw_options;
                            this.update_fw=true;
                            if(this.logSpeed<700&&this.enable_fw)this.requestTimeoutRaf(()=>{
                                this.fire_fw(this.damage_left+25, this.damage_top+25,'white',1,(target==this.boss.name));
                            },damage_transition_time-100);
                            this.hit_damage_type = damage;
                            this.requestTimeoutRaf(()=>{
                                if(!this.battle_paused){
                                    this.sound.play_sfx('playing_cards_'+4,'play','sfx',false);
                                }
                                this.miss[target] = true;
                                this.requestTimeoutRaf(()=>{
                                    this.miss[target] = false;
                                },changeSpeed);
                            }, damage_time-delay_time);
                        break;
                    }
                }, damage_time);
                this.requestTimeoutRaf(()=>{
                    this.hit[target] = false;
                    this.card_style_float[turn] = false;
                },Number(changeSpeed+damage_time));
            }
        }
        if(this.battle_paused||this.logSpeed==0) {
            this.pause_checker = setInterval(()=>{
                if(!this.battle_paused && this.logSpeed>0) {
                    this.updateResult();
                    if(this.pause_checker)clearInterval(this.pause_checker);
                }
            }, 250);
        } else {
            var t =this.log_extra_wait+this.logSpeed*4;
            this.requestTimeoutRaf(()=>{
                this.updateResult();
            }, t);
        }
    }
    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    }
    scroll_log() {
        this.requestTimeoutRaf(()=>{
            this.fresh_entry = true;
            this.requestTimeoutRaf(()=>{this.fresh_entry = false;},100);
        },100);
    }
    getBossImgUrl() {
        return this.boss ? '/assets/boss/' + this.boss.name + '.png' : '';
    }
    getResultID() {
        if (this.result) {
            if (this.result?.id?.length > 0) return this.result.id;
        }
    }
    healthLeft() { 
        return (this.boss.stats.health / this.boss.ogStats.health) * 100; 
    }
    armorLeft() { 
        return  (this.boss.stats.armor / this.boss.ogStats.armor) * 100; 
    }
    async dismissResults() {
        this.data.setResult(null);
        this.data.setScrolling([]);
        this.data.setBossView('main');
        this.data.setReplayBoss(null);
    }
    async startLog() {
        if(this.result) {
            if (!this.result.actions || this.result.actions.length < 1) {
                let temp = await this.helper.loadFullBattle(this.result.id);
                this.result.actions = temp;
                console.log(this.result);
                let act = [];
                this.result.actions.forEach(x => {
                    if (x.action.indexOf('absorption') > -1) {
                        let found = act.find(x => { return x.name === 'absorption'; });
                        if (!found) {
                            act.push({ name: 'absorption', qty: x.value, count: 1 });
                        } else {
                            found.qty += x.value;
                            found.count += 1;
                        }
                    } else if (x.action.indexOf('Sweet Spot') > -1) {
                        let found = act.find(x => { return x.name === 'Sweet Spot'; });
                        if (!found) {
                            act.push({ name: 'Sweet Spot', qty: 0, count: 1 });
                        } else {
                            found.count += 1;
                        }
                    } else if (x.action.indexOf('acrobatics') > -1) {
                        let found = act.find(x => { return x.name === 'acrobatics'; });
                        if (!found) {
                            act.push({ name: 'acrobatics', qty: 0, count: 1 });
                        } else {
                            found.count += 1;
                        }
                    } else if (x.action.indexOf('nulify') > -1) {
                        let found = act.find(x => { return x.name === 'nulify'; });
                        if (!found) {
                            act.push({ name: 'nulify', qty: 0, count: 1 });
                        } else {
                            found.count += 1;
                        }
                    } else if (x.action.indexOf('Elecrumagnet') > -1) {
                        let found = act.find(x => { return x.name === 'Elecrumagnet'; });
                        if (!found) {
                            act.push({ name: 'Elecrumagnet', qty: x.value, count: 1 });
                        } else {
                            found.qty += x.value;
                            found.count += 1;
                        }
                    } else if (x.action.indexOf('Advanced Training') > -1) {
                        let found = act.find(x => { return x.name === 'Advanced Training'; });
                        if (!found) {
                            act.push({ name: 'Advanced Training', qty: x.value, count: 1 });
                        } else {
                            found.qty += x.value;
                            found.count += 1;
                        }
                    }
                })
                let disp = '';
                act.forEach(x => {
                    disp += x.name + ' triggered ' + x.count + ' times\n';
                })
                //alert(disp);
            }
            this.battle_stopped=false;
            this.updateResult();
            this.running = true;
        }
    }
    getRewardsText(type) {
        let r = this.result;
        if (r && r.rewards && r.rewards.length) {
            let message = '';
            if (type === 'items') {
                message = this.replay ? r.player + ' received ' : 'You have received ';
                //console.log(r,r.rewards);
                for (let i = 0; i < r.rewards.length; i++) {
                    let reward = r.rewards[i];
                    if (reward && reward.name && reward.type=="FORGE") {
                        //console.log('Forgium', reward);
                        this.rewardedForgium = reward.qty;
                        reward.name='Forgium';
                    } else if (reward && reward.name && reward.name=="Electrum") {
                        this.rewardedElectrum = reward.qty;
                    }
                    if (i === 0) {
                        message += reward.qty + ' ' + reward.name;
                        if (reward.type === 'potion') message += ' potion';
                    } else if (i === r.rewards.length - 1) {
                        message += (r.rewards.length>2?',':'') + ' and ';
                        message += (reward.type === 'FORGE' ? this.decimalPipe.transform(reward.qty,'1.2-2') : this.decimalPipe.transform(reward.qty,'1.0-0')) + ' ' + reward.name;
                        if (reward.type === 'potion') message += ' potion.';
                        else message += '.';
                    } else {
                        message += (r.rewards.length>2?', ':'');
                        message += (reward.type === 'FORGE' ? this.decimalPipe.transform(reward.qty,'1.2-2') : this.decimalPipe.transform(reward.qty,'1.0-0')) + ' ' + reward.name;
                        if (reward.type === 'potion') message += ' potion';
                    }
                }
            } else if (type === 'points') {
                this.Damage = this.decimalPipe.transform(r.totalDmg); this.Points = this.decimalPipe.transform(r.points);
                if (this.replay) message = r.player+' did '+this.Damage+' damage and recieved '+this.Points+' points towards their ranking in the '+this.boss.name+' leaderboard!';
                if (!this.replay) message = 'You did '+this.Damage+' damage and recieved '+this.Points+' points towards your ranking in the '+this.boss.name+' leaderboard!';
            }
            return message;
        }
    }
    isBossEntry(entry, name) {
        if(entry.action) {
            var a = entry.action.indexOf('hit by ' + name) > -1 || entry.action.indexOf('boss missed') > -1 || entry.action.indexOf('boss swipes') > -1 || entry.action.indexOf('DIED!') > -1 || entry.action.indexOf('poison') > -1 || entry.action.indexOf('enraged') > -1;
            return a;
        } else {
            return 0;
        }
    }
    isDmg(entry) {
        return (entry.dmgType === 'ranged' || entry.dmgType === 'attack' || entry.dmgType === 'magic') && entry.value > 0;
    }
    armorDmg() {
        return this.boss.stats.armor ? Math.abs(this.boss.stats.armor) : 0;
    }
    getImgURL(a) {
        let heroAbilities = ["Life Force","Fire Force","Earth Force","Death Force","Water Force","Dragon Force","Mana Mastery","Vassal Mastery","Splash Heal"];
        let ret = '';
        if (heroAbilities.includes(a)) return 'https://splinterforge.s3.us-east-2.amazonaws.com/' + a + ' Rune.png';
        else {
          if (a === '1.5x DMG') return 'https://splinterforge.s3.us-east-2.amazonaws.com/abilities/multiplier.png';
          else return 'https://d36mxiodymuqjm.cloudfront.net/website/abilities/ability_' + a.toString().replace(' ', '-').toLowerCase() + '.png';
        }
    }
    healthDmg() {
        return this.boss.stats.health ? Math.abs(this.boss.stats.health) : 0;
    }
    noop() {};
    requestTimeoutNoRaf(fn, delay, registerCancel) {
      const timeoutId = setTimeout(fn, delay);
      registerCancel(() => clearTimeout(timeoutId));
    }
    requestTimeoutRaf(fn, delay, registerCancel?) {
      const start = window.performance.now();
      const loop = () => {
        const delta = window.performance.now() - start;
        if (delta >= delay) {
          fn();
          if(registerCancel)registerCancel(this.noop);
          return;
        }
        const raf = requestAnimationFrame(loop);
        if(registerCancel)registerCancel(() => cancelAnimationFrame(raf));
      };
      const raf = requestAnimationFrame(loop);
      if(registerCancel)registerCancel(() => cancelAnimationFrame(raf));
    };
}
