<section class="forgemodal blue" [@fade]="show">
	<div class="boxwrap" [@boxsmaller]="transfer_confrim" [@flip_up]="show">
	  <div class="topwrap">
		<h1>Transfer Pack</h1>
		<h1 class="blue" *ngIf="transfer_mode=='to'">Transfer Pack to Hive-Engine/Tribaldex</h1>
		<h1 class="blue" *ngIf="transfer_mode=='from'">Transfer Pack from Hive-Engine/Tribaldex</h1>
		<h1 class="blue" *ngIf="transfer_mode=='player'">Transfer Pack to Player</h1>
		<ul class="selection_btn">
			<li [attr.class]="transfer_mode=='to'?'active blue':''" (click)="transfer_mode='to';transfer_confrim=false">
				To Hive-Engine/Tribaldex
			</li>
			<li [attr.class]="transfer_mode=='player'?'active blue':''" (click)="transfer_mode='player';transfer_confrim=false">
				To Player
			</li>
			<li [attr.class]="transfer_mode=='from'?'active blue':''" (click)="transfer_mode='from';transfer_confrim=false">
				From Hive-Engine/Tribaldex
			</li>
		</ul>
		<div class="information" *ngIf="!transfer_confrim">
			<div class="bottomwrap item_description" *ngIf="selected_item">
		        <div [attr.class]="selected_item.name+' image_wrapper'">
		            <div [attr.class]="imagewrapper">
		                <img *ngIf="(selected_item.name === 'Alpha Pack')" src="https://splinterforge.s3.us-east-2.amazonaws.com/packs/crate.png" loading="lazy">
		                <img *ngIf="(selected_item.name === 'Enhancement Pack')" src="https://splinterforge.s3.us-east-2.amazonaws.com/packs/tempbag.png" loading="lazy">
		            </div>
		        </div>
		        <div class="details">
					<h1 class="title" *ngIf="selected_item">{{selected_item.name}}</h1>
					<div class="symbol" *ngIf="selected_item && (transfer_mode === 'to' || transfer_mode === 'from')">
						Transfer up to <b>{{transfer_mode === 'from' ? balance : selected_item.qty|number}} {{selected_item.name}}{{selected_item.qty==1?'':'s'}}</b> {{transfer_mode}} Hive-Engine/Tribaldex.
					</div>
					<div class="symbol" *ngIf="selected_item && transfer_mode === 'player'">
						Transfer up to <b>{{selected_item.qty|number}} {{selected_item.name}}{{selected_item.qty==1?'':'s'}}</b> to another player.
					</div>
					<div class="quantity">
						<input type="number" [attr.placeholder]="selected_item.qty>0?1:0" [attr.min]="selected_item.qty>0?1:0" [attr.max]="selected_item.qty" [(ngModel)]="amount" />
						<div class="qty_label">Quantity to Transfer: </div>
					</div>
					<!-- 
						<div class="quantity" *ngIf="selected_item && transfer_mode === 'player'">
							<input type="number" [attr.disabled]="selected_item.qty>0?false:true" [attr.placeholder]="selected_item.qty>0?1:0" [attr.min]="selected_item.qty>0?1:0" [attr.max]="selected_item.qty" [(ngModel)]="amount" />
							<div class="qty_label">Quantity to Transfer: </div>
						</div> 
					-->
				</div>
				<div class="clear"></div>
			</div>
		  	<div class="bottomwrap">
			  	<div class="wallet" *ngIf="transfer_mode=='player'">
			  		<div class="qty_label">Player Wallet Address: </div><br />
			  		<input type="text" placeholder="HIVE username" [(ngModel)]="receiver" />
			  	</div>
		  	</div>
			<div class="bottomwrap button_container">
				<div>
				  	<div class="gray button _cancel" (click)="close()"><span>Cancel</span></div>
			  		<div [attr.class]="'blue' + ' button _transfer'" (click)="confirm_transfer(selected_item)">
			  			<span>Transfer</span>
			  		</div>
			  		<div class="clear"></div>
				</div>
			</div>
		</div>
		<div class="confirmation" *ngIf="transfer_confrim">
	  		<div [attr.class]="selected_item.name+' image_wrapper'">
	            <div class="imagewrapper">
	                <img *ngIf="(selected_item.name === 'Alpha Pack')" src="https://splinterforge.s3.us-east-2.amazonaws.com/packs/crate.png" loading="lazy">
	                <img *ngIf="(selected_item.name === 'Enhancement Pack')" src="https://splinterforge.s3.us-east-2.amazonaws.com/packs/tempbag.png" loading="lazy">
	            </div>
	        </div>
		  	<div class="desc">
		        <div class="bottomwrap" *ngIf="!quantity_invalid">
			  		<h1 class="title" *ngIf="selected_item">{{selected_item.name}}{{amount==1?'':'s'}}</h1>
					<span *ngIf="transfer_mode=='to'">Transfer {{amount|number}} Pack{{amount==1?'':'s'}} to Hive-Engine/Tribaldex.</span>
					<span *ngIf="transfer_mode=='from'">Transfer {{amount|number}} Pack{{amount==1?'':'s'}} from Hive-Engine/Tribaldex.</span>
					<span *ngIf="transfer_mode=='player'">Transfer {{amount|number}} Pack{{amount==1?'':'s'}} to {{receiver?receiver:'Player'}}.</span>
		  		</div>
		  		<div class="bottomwrap" *ngIf="quantity_invalid">
			  		<h1 class="title">Invalid Quantity</h1>
					<span>You selected a quantity that is too large to transfer.</span>
		  		</div>
		  	</div>
			<div class="button_group" *ngIf="!quantity_invalid">
				<button (click)="transfer_confrim = false;" class="button red">No</button>
				<button (click)="start_transfer()" class="button green">Yes</button>
				<div class="clear"></div>
			</div>
		  	<div class="button_group" *ngIf="quantity_invalid">
				<button (click)="transfer_confrim = false;" class="ok_btn">Ok</button>
				<div class="clear"></div>
			</div>
		</div>

		<div class="closewrapper" (click)="close()">
		  <i class="fa-solid fa-x"></i>
		</div>
	  </div>
	</div>
	<div class="backdrop" [@pointer]="show" (click)="close()"></div>
</section>