<div class="background"></div>
<mineRunRules 
    [show]="showRules"
    (done)="showRules = false;">
</mineRunRules>
<rezModal *ngIf="mineRun"
    [show]="rezModal"
    [dead]="dead"
    [credits]="mineRun.rezs"
    [resurrected]="resurrected"
    (rezMonster)="rezMonster($event)"
    (done)="resurrected = null; rezModal = false;">
</rezModal>
<fightSummary
    [show]="showSummary"
    [battleLog]="battleLog"
    (done)="showSummary = false;">
</fightSummary>
<leaders
    [show]="showLeaders"
    [user]="username()"
    [tier]="mineRun ? mineRun.tier : 1"
    (done)="showLeaders = false;">
</leaders>
<section class="mineRunSection">
    <h5>
        The Electrum Mine 
        <span><button class="button purple" (click)="showRules = true;">Rules</button></span>
        <span><button class="button purple" (click)="showLeaders = true;">Leaderboard</button></span>
    </h5>
    <div *ngIf="display === 'newRun'" id="survival">
        <div *ngIf="!mineRun || mineRun.expired">
            <div *ngIf="tier === 0 && !freeSplinter">
                <h5>Due to Earth and Dragon Force cards being unavailable yet, you may choose one splinters to be available to choose from.</h5>
                <div>Free Splinter:
                    <span><button class="button purple" (click)="freeSplinter = 'Earth'">Earth</button></span>
                    <span><button class="button purple" (click)="freeSplinter = 'Dragon'">Dragon</button></span>
                </div>
            </div>
            <div *ngIf="tier === 0 && freeSplinter">
                <h5>Please select a tier to fight</h5>
                <div>
                    <button class="button purple" (click)="setTier(1)">Bronze</button>
                    <button class="button purple" (click)="setTier(2)">Silver</button>
                    <button class="button purple" (click)="setTier(3)">Gold</button>
                    <button class="button purple" (click)="setTier(4)">Diamond</button>
                </div>
            </div>
            <div *ngIf="tier > 0 && heroOrder && heroOrder.length !== 3 && heroes">
                <h5>Please select Heroes to use</h5>
                <div class="card_wrapper" *ngFor="let hero of heroes">
                    <div *ngIf="!hero.selected" (click)="setHero(hero)">
                        <slcard-selector [card]="hero" [blur]="'no'" [fav]="false"></slcard-selector>
                    </div>
                </div>
                <h5>Selected</h5>
                <div class="card_wrapper" *ngFor="let hero of heroes">
                    <div *ngIf="hero.selected">
                        <slcard-selector [card]="hero" [blur]="'no'" [fav]="false"></slcard-selector>
                    </div>
                </div>
            </div>
            <div *ngIf="available && tier > 0 && heroOrder && heroOrder.length === 3">
                <h5>
                    You have chosen to do a {{enum[tier]}} Mine Run.
                    <button class="button purple" *ngIf="!burnReset" (click)="startMineRun()">Start {{enum[tier]}} mine run!</button>
                    <button class="button purple" *ngIf="burnReset" (click)="startMineRun()">BURN {{resetCost()}} FORGE to reset {{enum[tier]}} mine run early!</button>
                </h5>
                <h5>Select up to 30 Splinterlands Monsters to take with you on you're {{enum[tier]}} mine run.</h5>
                <div>
                    <span>
                        You have selected {{getNumSelected()}} of 30 cards. 
                        <button class="button purple" *ngIf="mineRun" title="Select eligible monsters from last mine run." (click)="reSelectMonsters()">ReSelect</button>
                    </span>
                </div>
                
                <div>
                    <div *ngIf="freeSplinter">Free Splinter:
                        <span>{{freeSplinter}}</span>
                    </div>
                    <div>Damage:
                        <span><button class="button purple" (click)="toggleFilter('damage', 'attack')">Attack</button></span>
                        <span><button class="button purple" (click)="toggleFilter('damage', 'magic')">Magic</button></span>
                        <span><button class="button purple" (click)="toggleFilter('damage', 'ranged')">Ranged</button></span>
                        <span *ngIf="filters.damage !== 'ALL'"><button class="button purple" (click)="toggleFilter('damage', 'ALL')">ALL</button></span>
                        <span>Current: {{filters.damage}}</span>
                    </div>
                    <div>Mana:
                        <span><button class="button purple" (click)="toggleFilter('mana', 0)">0</button></span>
                        <span><button class="button purple" (click)="toggleFilter('mana', 1)">1</button></span>
                        <span><button class="button purple" (click)="toggleFilter('mana', 2)">2</button></span>
                        <span><button class="button purple" (click)="toggleFilter('mana', 3)">3</button></span>
                        <span><button class="button purple" (click)="toggleFilter('mana', 4)">4</button></span>
                        <span><button class="button purple" (click)="toggleFilter('mana', 5)">5</button></span>
                        <span><button class="button purple" (click)="toggleFilter('mana', 6)">6</button></span>
                        <span><button class="button purple" (click)="toggleFilter('mana', 7)">7</button></span>
                        <span><button class="button purple" (click)="toggleFilter('mana', 8)">8</button></span>
                        <span><button class="button purple" (click)="toggleFilter('mana', 9)">9</button></span>
                        <span><button class="button purple" (click)="toggleFilter('mana', 10)">10+</button></span>
                        <span *ngIf="filters.mana !== 99"><button class="button purple" (click)="toggleFilter('mana', 99)">ALL</button></span>
                        <span>Current: {{filters.mana === 99 ? 'ALL' : filters.mana}}</span>
                    </div>
                </div>
                <div class="monsterDisplay">
                    <h5 title="Splinters available are based on the Force Runes your chose heroes are using.">
                        Available Monsters
                    </h5>
                    <div class="monster card_wrapper" *ngFor="let card of available">
                        <slcard-selector *ngIf="!card.filtered" [card]="card" (select)="selectForTeam(card)" [fav]="false"></slcard-selector>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="mineRun && display === 'currentRun'" id="currentRunDisplay">
        <div>
            <p>Earned this run: XP: {{totals.xp}}, Electrum: {{totals.electrum}}, Frags: {{totals.frags}}, Essence: {{totals.essence}}</p>
            FORGE BURNT this run: {{totals.cost}}
        </div>
        <div id="nodeDisplay" class="column left">
            <h5>Bosses in your path...</h5>
            <div *ngIf="mineRun.nodes">
                <section *ngIf="showBoss" class="slider-wrapper">
                    <h5>Boss {{showBossIDX+1}} of 12</h5>
                    <button *ngIf="showBossIDX > 0" class="slide-arrow" id="slide-arrow-prev" (click)="rotate('prev')">
                        &#8249;
                    </button>
                    <button *ngIf="showBossIDX < 11" class="slide-arrow" id="slide-arrow-next" (click)="rotate('next')">
                        &#8250;
                    </button>
                    <ul class="slides-container" id="slides-container">
                        <li class="slide">
                            <div class="boss">
                                <slcard-selector [card]="showBoss" [fav]="false" [miniBoss]="true"></slcard-selector>
                            </div>
                        </li>
                    </ul>
                    <span *ngIf="showBoss.stats.health < 1">DEFEATED!</span>
                    <span *ngIf="(showBoss.id && showBoss.id === currentBoss.id) || (showBoss._id && showBoss._id === currentBoss._id) && showBoss.stats.health > 0">
                        <button class="button purple" *ngIf="alive.length > 0" (click)="display = 'pickTeam'">Deploy</button>
                        <span *ngIf="alive.length < 1">No more monsters...</span>
                        
                        <!--<button [disabled]="!mineRun || !mineRun.rerolls || mineRun.rerolls < 1" class="button purple" (click)="reroll()">ReRoll MiniBoss Abilities ({{mineRun.rerolls?mineRun.rerolls:0}})</button>-->
                        <button class="button purple" (click)="reroll()">ReRoll Abilities ({{mineRun.rerolls?mineRun.rerolls:0}})</button>
                    </span>
                </section>
            </div>
        </div>
        <div class="column right">
            <div id="heroDisplay">
                <h5>Your Heroes</h5>
                <div *ngIf="mineRun.heroes">
                    <div class="monster card_wrapper" *ngFor="let hero of mineRun.heroes">
                        <slcard-selector [card]="hero" [fav]="false" [showEnergy]="true"></slcard-selector>
                    </div>
                </div>
            </div>
            <div class="monsterDisplay">
                <div>
                    <h5>Alive Monsters</h5>
                    <div class="monster card_wrapper" *ngFor="let card of alive">
                        <slcard-selector [card]="card" [fav]="false"></slcard-selector>
                    </div>
                    <h5>Dead Monsters <button class="button purple" (click)="rezModal=true">Resurrect ({{mineRun.rezs}} credits)</button></h5>
                    <div class="monster card_wrapper" *ngFor="let card of dead">
                        <slcard-selector [card]="card" [fav]="false"></slcard-selector>
                    </div>
                </div>
            </div>
        </div>
        
        <div>
            <button class="button purple" [disabled]="resetModCost > 0" (click)="resetMineRun()" >FREE RESET {{getNextFree() > 0 ? 'in ' + getNextFree() + ' mins' : 'now'}}</button>
            <button class="button purple" *ngIf="resetModCost > 0" (click)="resetMineRun(true)" title="BURN FORGE to reset this run early (FORGE is not burnt until new team is submitted).">BURN {{resetCost()}} FORGE TO RESET</button>
        </div>
    </div>

    <div *ngIf="mineRun && display === 'pickTeam'" id="miniBossFight">
        <div class="column left">
            <div><button class="button purple" (click)="display = 'currentRun'">Back</button></div>
            <div class="bossCard" *ngIf="currentBoss">
                <h5>Mini Boss</h5>
                <slcard-selector [card]="currentBoss" [fav]="false"></slcard-selector>
            </div>
            <div>
                <button *ngIf="selectedHero" class="button purple" (click)="fightMiniBoss()">{{getFightButtonText()}}</button>
                <h5>Your Team </h5>
                <div>{{getManaStatus()}} Mana</div>
                <div>{{getMonsterStatus()}} Monsters</div>
                <div *ngIf="selectedHero">
                    <div class="card_wrapper">
                        <slcard-selector [card]="selectedHero" [fav]="false" (click)="selectHeroForBattle(selectedHero)"></slcard-selector>
                    </div>
                    <div class="monster card_wrapper" *ngFor="let card of alive">
                        <div *ngIf="card.selected" (click)="selectForBattle(card)">
                            <slcard-selector [card]="card" [blur]="'no'" [fav]="false"></slcard-selector>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="column right">
            <h5>Available {{!selectedHero ? 'Heroes' : 'Monsters'}}</h5>
            <div *ngIf="!selectedHero" id="hero">
                <div class="monster card_wrapper" *ngFor="let hero of mineRun.heroes" (click)="selectHeroForBattle(hero)">
                    <slcard-selector [card]="hero" [fav]="false" [showEnergy]="true"></slcard-selector>
                </div>
            </div>
            <div *ngIf="selectedHero" class="monsterSelectDisplay">
                <div class="monster card_wrapper" *ngFor="let card of alive" (click)="selectForBattle(card)">
                    <slcard-selector [card]="card" [blur]="'yes'" [fav]="false"></slcard-selector>
                </div>
            </div>
        </div>
        <div *ngIf="1 > 2 && selectedHero" class="startMine">
            <button [disabled]="loading" class="button purple" (click)="fightMiniBoss()">{{getFightButtonText()}}</button>
        </div>
    </div>

    <div *ngIf="battleLog && display === 'fightResult'" id="resultDisplay">
        <h5>
            Battle result vs. {{battleLog.boss}} 
            <span><button class="button purple" (click)="loadInfo()">Back</button></span>
            <span><button class="button purple" (click)="showSummary = true">Summary</button></span>
        </h5>
        <div *ngIf="battleLog">
            <div class="column battle_left">
                <h5>Mini Boss</h5>
                <div class="boss_holder" *ngIf="battleLog.fullBoss">
                    <div class="boss">
                        <slcard-selector [card]="battleLog.fullBoss" [fav]="false"></slcard-selector>
                    </div>
                </div>
                <div *ngIf="battleLog.rewards.length > 0">
                    <h5>You have defeated {{battleLog.fullBoss.name}}!!</h5>
                    <div *ngFor="let r of battleLog.rewards">You earned {{r.qty + ' ' + r.name}}</div>
                </div>
                <div *ngIf="battleLog.rewards.length === 0">
                    <h5>{{battleLog.fullBoss.name}} has defeated you...</h5>
                    <div>You must defeat the mini boss to earn rewards.</div>
                </div>
            </div>
            <div class="column battle_right">
                <h5>Your Team</h5>
                <div *ngIf="battleLog.team" id="monsters">
                    <div class="monster card_wrapper" *ngFor="let card of battleLog.team">
                        <slcard-selector [card]="card" [fav]="false"></slcard-selector>
                    </div>
                </div>
            </div>
            <div class="log_box">
                <div *ngIf="battleLog.actions" class="battle_log">
                    <h5>Battle Log</h5>
                    <div>
                        <div *ngFor="let a of battleLog.actions">
                            <div *ngIf="a.action !== 'preamble'">{{a.action}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>

<div *ngIf="loading" class="loader"></div>
