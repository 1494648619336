<section class="forgemodal blue" [@fade]="show">
	<div class="boxwrap" [@boxsmaller]="transfer_confrim" [@flip_up]="show">
	  <div class="topwrap">
		<h1>Transfer Card{{selected.length==1?'':'s'}}</h1>
		<div class="information" *ngIf="!transfer_confrim">
			<div class="left account-card">
			   <sfcard-selector
					class="sfcard"
					[sfcards]="cards"
					[sfcard]="card"
					[smaller]="true"
					[stackView]="false">
				</sfcard-selector>
			</div>
			<div class="right">
				<div class="bottomwrap item_description" *ngIf="selected && selected.length > 0">
			        <div [attr.class]="selected[0].name+' image_wrapper'">
			            <div [attr.class]="imagewrapper">
			                <img *ngIf="(selected[0].name === 'Alpha Pack')" src="https://splinterforge.s3.us-east-2.amazonaws.com/packs/crate.png" loading="lazy">
			                <img *ngIf="(selected[0].name === 'Enhancement Pack')" src="https://splinterforge.s3.us-east-2.amazonaws.com/packs/tempbag.png" loading="lazy">
			            </div>
			        </div>
			        <div class="details">
						<h1 class="title" *ngIf="selected">
							Transfer {{selected[0].name}}
						</h1>
						<div class="symbol" *ngIf="selected">
							Transfer <b>{{selected.length|number}} {{selected[0].name}}{{(selected.length==1?'':'s')}}</b> to another player.
						</div>
					</div>
					<div class="clear"></div>
				</div>
			  	<div class="bottomwrap">
				  	<div class="wallet">
				  		<div class="qty_label">Player Wallet Address: </div><br />
				  		<input type="text" placeholder="HIVE username" [@field_error]="username_required" [(ngModel)]="receiver" />
				  	</div>
			  	</div>
			</div>
			<div class="buttonrow">
				<button (click)="close()" class="red button left">Cancel</button>
				<button (click)="confirm_transfer()" class="green button">Transfer</button>
			</div>
		</div>
		<div class="confirmation" *ngIf="transfer_confrim">
		  	<div class="desc">
		        <div class="bottomwrap" *ngIf="!quantity_invalid">
			  		<h1 class="title" *ngIf="selected_item">{{selected_item.name}}{{amount==1?'':'s'}}</h1>
					<span>Transfer {{selected.length}} item{{selected.length==1?'':'s'}} to {{receiver?receiver:'Player'}}.</span>
		  		</div>
		  		<div class="bottomwrap" *ngIf="quantity_invalid">
			  		<h1 class="title">Invalid Quantity</h1>
					<span>You selected a quantity that is too large to transfer.</span>
		  		</div>
		  	</div>
			<div class="button_group" *ngIf="!quantity_invalid">
				<button (click)="transfer_confrim = false;" class="button red">No</button>
				<button (click)="start_transfer()" class="button green">Yes</button>
				<div class="clear"></div>
			</div>
		  	<div class="button_group" *ngIf="quantity_invalid">
				<button (click)="transfer_confrim = false;" class="ok_btn">Ok</button>
				<div class="clear"></div>
			</div>
		</div>
		<div class="closewrapper" (click)="close()">
		  <i class="fa-solid fa-x"></i>
		</div>
	  </div>
	</div>
	<div class="backdrop" [@pointer]="show" (click)="close()"></div>
</section>