import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rarity'
})
export class RarityPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    var rarity = 0;
    if (args === 'Common') rarity = 1;
    else if (args === 'Rare') rarity = 2;
    else if (args === 'Epic') rarity = 3;
    else if (args === 'Legendary') rarity = 4;
    if(args == -1 || args == 'All' || args == false) return value;
    let values = [];
    for (var i = 0; i < value.length; i++) {
      if (value[i].rarity == rarity) values.push(value[i]);
    }
    return values;
  }
}