import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'audio_placement'
})
export class AudioPlacementPipe implements PipeTransform {
	transform(value: any, args?: any): any {
		let values;
		if (value) {
			switch(value) {
				case '':
					return true;
				break;
				default:
					return false
				break;
			}
		} else {
			values = value;
		}
		return values;
	}
}

