import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { fade, active_stat, active_overlay, popin } from '../../slcard/slcard.animations';
@Component({
  selector: 'card-stats-icons',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.styl'],
  animations: [ fade, active_stat, active_overlay, popin ],
})
export class StatsComponent implements OnInit {
  constructor() { }
  @Input('stats') stats:any;
  @Input('smaller') small: boolean;
  @Input('extra_smaller') extra_small: boolean;
  @Input('original_stats') original_stats:any;
  @Input('og_health') og_health:any;
  @Input('og_armor') og_armor:any;
  @Input('level') level:number;
  @Input('mana') mana:number;
  @Input('attack') attack:number;
  @Input('ranged') ranged:number;
  @Input('magic') magic:number;
  @Input('health') health:number;
  @Input('armor') armor:number;
  @Input('speed') speed:number;
  @Input('showog') showog: boolean;
  @Input('center') center: boolean;
  @Input('hero') hero: boolean;
  @Input('show_health') show_health = true;
  @Input('show_armor') show_armor = true;
  @Input('show_mana') show_mana = true;
  @Input('fade_stats') fade_stats = true;
  @Input('showTitles') showTitles = false;
  @Input('boss') boss = false;
  @Input('effects') effects = false;
  @Input('force_show_stats') force_show_stats= true;
  @Input('active_speed') active_speed: boolean;
  @Input('active_melee') active_melee: boolean;
  @Input('active_magic') active_magic: boolean;
  @Input('active_ranged') active_ranged: boolean;
  @Input('active_health') active_health: boolean;
  @Input('active_armor') active_armor: boolean;
  @Output('effect') effect = new EventEmitter();
  show_stats = true;
  color = [''];
  active = [];
  change = [];
  change_timer = [];
  adjust_magic = false;
  adjust_ranged_2 = false;
  adjust_ranged = false;
  adjust_speed = false;
  adjust_melee = false;
  display_mana = false;
  display_speed = false;
  display_attack = false;
  display_magic = false;
  display_ranged = false;
  display_health = false;
  display_armor = false;
  fire_attack = '0';
  check_display(a, c) {
    this['display_'+a] = c > 0 ? true : false;
  }
  ngOnChanges(item) {
    for(let a of ['health','armor','ranged','magic','speed','attack','mana']) {this.check_display(a,this[a]);}
    if(this.effects){
      if(item.health||item.armor||item.ranged||item.magic||item.speed||item.attack){
        for(let a of ['health','armor','ranged','magic','speed','attack','mana']) {
          if(item[a]||(item.stats&&item.stats[a])){
            this.active[a]=true;this.change[a]=false;
            clearTimeout(this.change_timer[a]);
            // this.check_display(a, item[a].currentValue);
            this.change[a]=item[a].currentValue-item[a].previousValue;
            this.color[a]=(item[a].currentValue>item[a].previousValue)?'green':'red';
            this.effect.emit(['forge_'+a+'_'+((item[a].currentValue>item[a].previousValue)?'plus':'minus'),this.change[a],this.color[a]]);
            setTimeout(()=>{this.active[a]=false;this.color[a]='';},900);
            this.change_timer[a]=setTimeout(()=>{this.change[a]=false;this.check_display(a,item[a].currentValue);},1200);
          }
        }
      }
      this.adj_items();
    }
  }
  ngOnInit(): void {
    this.adj_items();
    this.active = [];
    for(let a of ['health','armor','ranged','magic','speed','attack','mana']) {this.check_display(a,this[a]);}
  }
  adj_items() {
    if(!this.boss){
      this.adjust_speed = false;
      this.adjust_melee = false;
      this.adjust_magic = false;
      this.adjust_ranged_2 = false;
      if(this.speed>0&&this.ranged>0&&!this.magic&&!this.attack)this.adjust_ranged_2=true;
      // if(this.attack>0&&this.magic>0&&this.speed>0)this.adjust_magic=true;
      if(this.attack>0&&this.magic>0)this.adjust_melee=true;
      if(this.attack>0&&this.magic>0&&this.speed>0)this.adjust_speed=true;
      if(this.attack>0&&this.magic>0&&this.ranged>0&&this.speed>0){
        this.adjust_ranged_2=false;
        this.adjust_speed = true;
        this.adjust_melee = true;
        this.adjust_magic = false;
      } else {

      }
      if(this.ranged>0&&this.attack==0&&this.magic==0)this.adjust_ranged_2=true;
    }
  }
}