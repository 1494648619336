<section class="forgemodal gold" [@fade]="show">
    <div class="boxwrap" [@flip_up]="show">
		<h1 [innerHTML]="title"></h1>
		<div>
			<item-image *ngIf="item || top_label" [item]="item"></item-image>
			<div [attr.class]="item?'item_info adj':'item_info full'">
				<h2 class="item_title" *ngIf=item&&item.name>
					{{item.name}}
				</h2>
				<h2 class="item_title no_cards" *ngIf=!item&&title>
					{{title}}
				</h2>
				<p [innerHTML]="top_label"></p>
				<div class="button_group" *ngIf="modal_mode=='confirmation'">
					<button *ngIf="!purchase" (click)="close()" class="button red">No</button>
					<button *ngIf="!purchase" (click)="done_button(type)" class="button green">Yes</button>
					<button *ngIf="purchase" (click)="done_button(type)" class="button gold purchase">
						<div *ngIf="!loading">Purchase</div>
						<div *ngIf="loading">{{loading}}</div>
					</button>
					<div class="clear"></div>
				</div>
				<div class="button_group" *ngIf="modal_mode==''">
					<button (click)="close()" class="button gold">{{button}}</button>
					<div class="clear"></div>
				</div>
			</div>
		</div>
		<div class="closewrapper" (click)="close()">
			<i class="fa-solid fa-x"></i>
		</div>
	</div>
	<div class="backdrop" [@pointer]="show" (click)="close()"></div>
</section>