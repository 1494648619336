<section class="forgemodal" [@fade]="show">
    <div class="boxwrap large" [@flip_up]="show">
		<h1 *ngIf="!equippable">No Equippable Items</h1>
		<div *ngIf="!equippable" class="not_equippable_container">
			<p class="not_equippable">You have no items that can be equipped.</p>
			<div class="purple button" (click)="close()">Close</div>
		</div>
		<h1 *ngIf="equippable">Equip A Card</h1>

		<div *ngIf="equippable && !equipped">
			<h4 class="equip_cards">
				{{equippable.length}} Equippable Card{{equippable.length==1?'':'s'}}
			</h4>
			<div class="sfcard" *ngFor="let c of equippable">
				<sfcard-selector 
					[stackView]="false" 
					[smaller]="smaller" 
					[hover]="true"
					[sfcard]="c" 
					(click)="equipCard(c)">
				</sfcard-selector>
			</div>
		</div>
		<div *ngIf="equipped">
			<div class="leftside">
				<div class="selected_card">
					<h4>Card Equipped</h4>
					<sfcard-selector 
						class="sfcard" 
						[stackView]="false" 
						[hover]="true" 
						[sfcard]="equipped">
					</sfcard-selector>
				</div>
				<div class="unequip_button_container">
					<div [disabled]="loading" class="purple button unequip_button" *ngIf="equipped" (click)="unequipCard()">
						Unequip
					</div>
				</div>
			</div>
			<div class="rightside" *ngIf="equippable">
				<h4 class="eligible_cards">
					{{equippable.length}} Equippable Card{{equippable.length==1?'':'s'}}
				</h4>
				<div>
					<div class="sfcard" *ngFor="let c of equippable">
						<sfcard-selector 
							[stackView]="false" 
							[smaller]="smaller" 
							[hover]="true" 
							[sfcard]="c" 
							(click)="equipCard(c)">
						</sfcard-selector>
					</div>
				</div>
			</div>
		</div>

        <div class="closewrapper" (click)="close()">
            <i class="fa-solid fa-x"></i>
        </div>
    </div>
    <div class="backdrop" [@pointer]="show" (click)="close()"></div>
</section>

<notice-modal [label]="message" [title]="modal_title" [show]="show_modal" [mode]="modal_mode" [button]="modal_button"
    [type]="type" (done)="modal_done($event)">
</notice-modal>
