import { Component, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { fade, flip_up, pointer } from '../../app.animations';
@Component({
  selector: 'select-card',
  animations: [fade, flip_up, pointer],
  templateUrl: './select-card.component.html',
  styleUrls: ['./select-card.component.styl']
})
export class SelectCardComponent implements AfterViewInit {
	constructor() { }
	@Output('done') done = new EventEmitter();  
	@Output('watch') watch = new EventEmitter();  
	@Input('show') show=true;
	@Input('cards') cards: any;
	@Input('equipped') equipped: any;
	@Input('equippable') equippable: any;
	ngAfterViewInit(): void {
	}
	close() {
		this.show=false;
		this.done.emit(1);
	}
}