import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({ providedIn: 'root' })
export class HeroService {

    apiUrl = environment.apiUrl;

    constructor(private http: HttpClient) {  } 

    burnForXP(params) {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/hero/burnForXP', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }
    
    changeHeroName(params) {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/hero/changeName', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }
    
    getHeroCards(u,t) {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.apiUrl + '/hero/getHeroes?user=' + u + '&token=' + t)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    getHeroLeaders() {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.apiUrl + '/data/topHeroes')
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    getSkills(u,t) {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.apiUrl + '/data/getSkills')
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    equipCard(params) {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/hero/equipCard', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    learnSkill(params) {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/hero/buySkill', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    levelHero(params) {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/hero/levelHero', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    levelSkill(params) {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/hero/levelSkill', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    manageLoadOuts(params) {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/hero/loadOuts', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    promoteHero(params) {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/hero/promoteHero', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    resetHeroSkills(params) {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/hero/resetHeroSkills', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    unequipCard(params) {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/hero/unequipCard', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }


    // STAKING calls
    stake(u,t,q) {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/hero/stake', { username: u, token: t, qty: q })
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }    

    unstake(u,t,q) {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/hero/unstake', { username: u, token: t, qty: q })
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }    

    getStakeInfo(u,t) {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/hero/getStakeInfo', { username: u, token: t })
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    xpRewardHistory(u,t) {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/data/xpRewardHistory', { username: u, token: t })
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    getTotalStakeInfo() {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.apiUrl + '/data/totalStaked')
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }
}
