import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { fade, flip_up, pointer } from '../../app.animations';
import { HelperService } from '@app/_services/helper.service';
@Component({
  selector: 'skills',
  animations: [fade, flip_up, pointer],
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.styl']
})
export class SkillsComponent implements OnInit {
	constructor(private helper: HelperService) { }
	@Output('done') done = new EventEmitter();  
	@Output('doAction') doAction = new EventEmitter();  
	@Input('show') show = false;
	@Input('skills') skills: any;
    @Input('hero') hero: any;
	skill_to_buy: any;
	modal_title: string = '';
	message: string;
	type: string;
	modal_mode: string = 'confirmation';
	show_modal = false;
	modal_button = 'Close';

	ngOnInit() {
		//console.log(this.skills);
	}

	close() {
		this.done.emit(1);
	}

	buySkill(s) {
        //console.log(s);
        this.skill_to_buy = s;
        this.modal_title = 'Learn skill';
        this.message = 'Would you like to learn ' + s.name + ' for ' + s.cost[s.next] + ' skill points?';
        this.type = 'confirm learn';
        this.modal_mode = 'confirmation';
        this.show_modal = true;
        return;
	}

	async modal_done(e) {
		let event = e;
        //console.log(this.skill_to_buy);
		if (event === 'confirm learn') {
            //let temp: any = { message: 'success' };
            let temp: any = await this.helper.learnSkill(this.skill_to_buy.name, this.hero.id, (this.skill_to_buy.next+1));
            if (temp?.message === 'success') {
                this.helper.showSnackbarMessage('Successfully learned ' + this.skill_to_buy.name);
				this.doAction.emit({ type: 'skillUp', skill: this.skill_to_buy });
				this.done.emit();
            } else {
                //console.log(temp);
                if (temp['message']) this.helper.showSnackbarMessage(temp['message']);
            }
		}

        this.show_modal = false;
	}
}