<div class="category_container" [@airdrop_sidedown]="show">
	<div class="shop_items airdrop_info" [@shop_category]="!panel_hidden" [attr.id]="'airdrop_'+name">
		<i class="close fa-solid fa-xmark" (click)="close_airdrop()"></i>
		<div class="shop_item">
			<h1 class="title">
				<span>Airdrop {{name}}</span>
			</h1>
			<div class="airdrop_zone">
				<div class="airdrop_container">
					<div>
						<div class="sfcard_selector">
							<div class="sf_card">
								<sfcard-selector
									[sfcard]="alphaAirdropCard"
									[sfcards]="cards"
									[stackView]="false"
									[smaller]="false">
								</sfcard-selector>
							</div>
						</div>

						<div *ngIf="alphaAirdropCard" [attr.class]="!alphaAirdropCard?'sf_descriptor no_cards':'sf_descriptor'">
							<div *ngIf="!alphaAirdropCard" class="border_left_l first"></div>
							<h1>
								<span class="white">Airdrop</span>
								<span class="yellow">{{alphaAirdropCard.name}}</span>
							</h1>
							<h2 class="text_left">
								<span class="red">{{alphaAirdropCard.slot}} Equipment</span>
							</h2>
							<h2 class="text_right">
								<span class="red">Adds {{alphaAirdropCard.stat}}</span>
							</h2>
							<p class="left first_airdrop_info">
								The first airdrop will happen after <b>200k</b> packs have sold.
							</p>
							<p class="left gaurentee_packs">
								Players are guaranteed <b>1 Airdrop</b> card for every {{airdrop_cards_per_pack}} packs purchased.
							</p>
							<p class="left gaurenteed_packs">
								You are guaranteed {{guaranteedDrops==0?'no':guaranteedDrops}} {{alphaAirdropCard.name}} based on {{packs_purchased==0?'no':'your '+packs_purchased}} packs purchased.
								<br />
								<span *ngIf="packs_purchased==0">Purchase an {{name}} to get a gaurenteed Airdrop.</span>
							</p>
							<button *ngIf="packs_purchased > 0 && 1 > 2" class="button purple" (click)="showAirdropClaim = true;">Claim</button>
							<div class="clear"></div>
							<div class="clear"></div>
						</div>

						<div class="clear"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<app-airdrops class="airdrop_overlay"
	*ngIf="showAirdropClaim"
	(hide)="showAirdropClaim = false;">
</app-airdrops>
