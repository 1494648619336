<div class="filter_control">
    <span class="mana_title">Mana:</span>
    <div *ngFor="let n of mana_placeholder" 
    [attr.class]="(filters.mana>0&&filters.mana!=n)?'mana_filter disabled':(filters.mana>0&&filters.mana==n)?'mana_filter active':'mana_filter'">
        <button (click)="filterByMana(n)">
            <span>{{n === 10 ? n + '+' : n}}</span>
        </button>
    </div>
    
    <div class="filter_control anchor equipped_toggle" (click)="filterFavorites()">
        <span class="ability_title">
          <span>Favorites</span>
          <div class="icon">
            <i *ngIf="filters.favorites" class="fa-solid ck fa-check"></i>
            <i class="fa-regular fa-square-full"></i>
          </div>
        </span>
    </div>
    <div class="filter_control anchor equipped_toggle" (click)="hideFavorites()" title="Hide the favorites button on the cards">
        <span class="ability_title">
          <span>Hide Fav Toggle</span>
          <div class="icon">
            <i *ngIf="filters.hide" class="fa-solid ck fa-check"></i>
            <i class="fa-regular fa-square-full"></i>
          </div>
        </span>
    </div>
</div>
<div class="filter_control damage_selector">
    <div class="filter_control_right">
        <span class="ability_title">Sort:</span>
        <div class="filter_selector">
            <div *ngIf="filters" class="dropdown">
                <div class="label" (click)="show_abilities=false;show_damage=false;show_sort=show_sort?false:true">
                    <span>
                        {{filters.sort}}
                        <i class="fa-solid fa-angle-up" *ngIf="filters.sort!='None'&&filters.direction=='ASC'"></i>
                        <i class="fa-solid fa-angle-down" *ngIf="filters.sort!='None'&&filters.direction=='DESC'"></i>
                    </span>
                    <i class="fa-solid fa-chevron-down" [@flip]="show_sort"></i>
                </div>
            </div>
            <ul class="menu" [@dropdown]="show_sort==true">
                <li class="item" (click)="filterBySorting('None')">
                    <span>None</span>
                </li>
                <li class="item" (click)="filterBySorting('Name','DESC')">
                    <span>Name</span>
                    <i class="fa-solid fa-angle-down"></i>
                </li>
                <li class="item" (click)="filterBySorting('Name','ASC')">
                    <span>Name</span> 
                    <i class="fa-solid fa-angle-up"></i>
                </li>
                <li class="item" (click)="filterBySorting('Attack','DESC')">
                    <span>Attack</span>
                    <i class="fa-solid fa-angle-down"></i>
                </li>
                <li class="item" (click)="filterBySorting('Attack','ASC')">
                    <span>Attack</span> 
                    <i class="fa-solid fa-angle-up"></i>
                </li>
                <li class="item" (click)="filterBySorting('Magic','DESC')">
                    <span>Magic</span>
                    <i class="fa-solid fa-angle-down"></i>
                </li>
                <li class="item" (click)="filterBySorting('Magic','ASC')">
                    <span>Magic</span> 
                    <i class="fa-solid fa-angle-up"></i>
                </li>
                <li class="item" (click)="filterBySorting('Ranged','DESC')">
                    <span>Ranged</span>
                    <i class="fa-solid fa-angle-down"></i>
                </li>
                <li class="item" (click)="filterBySorting('Ranged','ASC')">
                    <span>Ranged</span> 
                    <i class="fa-solid fa-angle-up"></i>
                </li>
                <li class="item" (click)="filterBySorting('Armor','DESC')">
                    <span>Armor</span>
                    <i class="fa-solid fa-angle-down"></i>
                </li>
                <li class="item" (click)="filterBySorting('Armor','ASC')">
                    <span>Armor</span> 
                    <i class="fa-solid fa-angle-up"></i>
                </li>
                <li class="item" (click)="filterBySorting('Health','DESC')">
                    <span>Health</span>
                    <i class="fa-solid fa-angle-down"></i>
                </li>
                <li class="item" (click)="filterBySorting('Health','ASC')">
                    <span>Health</span> 
                    <i class="fa-solid fa-angle-up"></i>
                </li>
                <li class="item" (click)="filterBySorting('Speed','DESC')">
                    <span>Speed</span>
                    <i class="fa-solid fa-angle-down"></i>
                </li>
                <li class="item" (click)="filterBySorting('Speed','ASC')">
                    <span>Speed</span> 
                    <i class="fa-solid fa-angle-up"></i>
                </li>
                <li class="item" (click)="filterBySorting('Mana','DESC')">
                    <span>Mana</span>
                    <i class="fa-solid fa-angle-down"></i>
                </li>
                <li class="item" (click)="filterBySorting('Mana','ASC')">
                    <span>Mana</span> 
                    <i class="fa-solid fa-angle-up"></i>
                </li>
            </ul>
        </div>
    </div>
    <div class="filter_control_right">
        <span class="ability_title">Ability:</span>
        <div class="filter_selector">
            <div class="dropdown">
                <div class="label" (click)="show_sort=false;show_damage=false;show_abilities=show_abilities?false:true">
                    <span>{{filters.ability}}</span>
                    <i class="fa-solid fa-chevron-down" [@flip]="show_abilities"></i>
                </div>
            </div>
            <ul class="menu" [@dropdown]="show_abilities==true">
                <li class="item" (click)="filterByAbility('All')">All</li>
                <li *ngFor="let ability of abilities | abilities_available:copiedCards" class="item" 
                    (click)="filterByAbility(ability)">
                    {{ability}}
                </li>
            </ul>
        </div>
    </div>
    <div class="filter_control_right">
        <span class="damage_title">Damage:</span>
        <div class="filter_selector">
            <div class="dropdown">
                <div class="label" (click)="show_sort=false;show_abilities=false;show_damage=show_damage?false:true">
                    <span>{{filters.dmgType}}</span>
                    <i class="fa-solid fa-chevron-down" [@flip]="show_damage"></i>
                </div>
            </div>
            <ul class="menu" [@dropdown]="show_damage==true">
                <li class="item" (click)="filterByDamage('All')">All</li>
                <li class="item" (click)="filterByDamage('Attack')">Attack</li>
                <li class="item" (click)="filterByDamage('Magic')">Magic</li>
                <li class="item" (click)="filterByDamage('Ranged')">Ranged</li>
            </ul>
        </div>
    </div>
</div>
<div class="clear"></div>