import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ShopService {

    apiUrl = environment.apiUrl;

    constructor(private http: HttpClient) {  }

    burnForStamina(params) : any {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/shop/burnForStamina', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }

    confirmSale(params) : any {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/shop/confirmSale', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }

    verifyTx(params) : any {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/shop/verifyTx', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }

    getReservation(params) : any {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/shop/getReservation', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }
    
    buy(params) : any {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/shop/buy', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }

    useConsumable(params) : any {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/shop/useConsumable', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }

    getPackStats(symbol, u): any {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.apiUrl + '/shop/packStats?symbol=' + symbol + '&user=' + u)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }

    getShopItems(): any {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.apiUrl + '/shop/getItems')
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }
}