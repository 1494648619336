<div class="background"></div>
<div class="page_heading">
    <h1>Card Shop</h1>
    <ul class="bonus_packs">
        <li><span><b>10%</b> Bonus for Buying <b>50+</b> Packs.</span></li>
        <li><span><b>15%</b> Bonus for Buying <b>250+</b> Packs.</span></li>
        <li><span><b>20%</b> Bonus for Buying <b>1000+</b> Packs.</span></li>
    </ul>
    <div class="bottom_border"></div>
    <div class="clear"></div>
</div>
<section class="fullshop">
    <div class="inner inner_container">
        <div *ngFor="let c of categories; let i = index;" class="category_holder">
            <div class="category_container">
                <div [attr.class]="category_hidden[i]?'titlebox margin':'titlebox margin'" (click)="category_hidden[i]=category_hidden[i]?false:true">
                    <div class="border_gradient bottom"></div>
                    <i *ngIf="!category_hidden[i]" class="fa-solid fa-chevron-down"></i>
                    <i *ngIf="category_hidden[i]" class="fa-solid fa-chevron-up"></i>
                    <h3>{{c.name}}</h3>
                </div>
                <div *ngIf="items" [@shop_category]="!category_hidden[i]" class="itemwrapper elixirs">
                    <div *ngFor="let item of items | type:c.category; let i = index"  [attr.class]="(c.name=='Packs'||c.name=='Presale')?' shop_items orange large':' shop_items orange small'" [@fade]="loaded[i]" [@display_inline_block]="((c.name!='Presale')||(c.name=='Presale'&&item.name=='Alpha Pack'))?true:false">
                        <div class="b_left"></div>
                        <div class="b_right"></div>
                        <div class="b_top"></div>
                        <div class="b_bottom"></div>
                        <div class="shop_item"><div class="bottom_border_stripe"></div><div class="top_border_stripe"></div>
                            <div class="border_top_right"></div>
                            <div class="shopItem">
                                <div class="currency_buttons" *ngIf="item.name==='Alpha Pack'">
                                    <button [attr.class]="selectedCurrency=='DEC'?'active black_red right button':'black_red right button'" 
                                            (click)="select_currency('DEC')"
                                            title="Your DEC tokens must be available on Hive-Engine to place an order using DEC.">DEC</button>
                                    <button [attr.class]="selectedCurrency=='FORGE'?'active black_red left button':'black_red left button'" 
                                            (click)="select_currency('FORGE')"
                                            title="Your FORGE tokens must be available in your SplinterForge game account balance to place an order using FORGE.">FORGE</button>
                                </div>
                                <div class="shopImage">
                                    <item-image  (click)="select_product(item,c.name)" [item]="item" (loaded)="loaded[i]=true"></item-image>
                                    <div class="clear"></div>
                                    <div class="textwrap airdrop_top_button" *ngIf="item.type === 'pack'">
                                        <div class="caption buy air" (click)="airdrop_button(item,c)" >
                                            <div [disabled]="loading" [attr.class]="'add_to_cart airdrop_trigger button'+(airdrop[c.name]==item?' active':'')">
                                                <i class="down_arrow fa-solid fa-arrow-down"></i>
                                                <i class="cloud fa-solid fa-cloud"></i>
                                            </div>
                                            <div [attr.class]="'translucent button a_drop'+(airdrop[c.name]==item?' active':'')">Airdrop</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item_info">
                                    <div *ngIf="item.name==='Alpha Pack'" class="title">Alpha CRATE</div>
                                    <div *ngIf="item.name==='Enhancement Pack'" (click)="select_product(item,c.name)" class="title" title="All FORGE spent on BAG will be BURNT!"><i class="fa-solid fa-fire"> </i> Enhancement BAG</div>
                                    <div *ngIf="item.name!='Enhancement Pack'&&item.name!='Alpha Pack'" (click)="select_product(item,c.name);" class="title" title="All FORGE spent on Elixirs will be BURNT!"><i class="fa-solid fa-fire"> </i> {{item.name}}</div>
                                    <div class="actionbox">
                                        <!-- <span class="qty">Select Packs: </span> -->
                                        <span class="unbox_packs desc">{{item.description|period}}</span>
                                    </div>
                                       <span class="owned">
                                            <span>Owned: </span>
                                            <span class="number_owned" *ngIf="getQtyOwned(item)>0">{{getQtyOwned(item)|number}}</span>
                                            <span class="number_owned none" *ngIf="getQtyOwned(item)==0">None</span>
                                        </span> 
                                    <div *ngIf="item.type === 'pack'" class="badges">
                                        <div class="pack_counts" *ngIf="item.name==='Alpha Pack'&&alphaPackStats">
                                            <div class="sold" [attr.title]="(alphaPackStats.overall|number)+' Sold'">
                                                <div class="bg"></div>
                                                <span class="span_label">Sold</span>
                                                <span class="span_number">{{alphaPackStats.overall|number}}</span>
                                            </div>
                                            <div class="remaining" [attr.title]="(1000000-alphaPackStats.overall|number)+' Remaining'">
                                                <div class="bg"></div>
                                                <span class="span_label">Remaining</span>
                                                <span class="span_number">{{1000000 - alphaPackStats.overall|number}}</span>
                                            </div>
                                        </div>
                                        <div class="pack_counts" *ngIf="item.name==='Enhancement Pack'&&gemPackStats">
                                            <div class="sold" [attr.title]="(gemPackStats.overall|number)+' Sold'">
                                                <div class="bg"></div>
                                                <span class="span_label">Sold</span>
                                                <span class="span_number">{{gemPackStats.overall|number}}</span>
                                            </div>
                                            <div class="remaining" [attr.title]="(1000000-gemPackStats.overall|number)+' Remaining'">
                                                <div class="bg"></div>
                                                <span class="span_label">Remaining</span>
                                                <span class="span_number">{{1000000 - gemPackStats.overall|number}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="air_drop pack_counts" *ngIf="alphaPackStats && alphaPackStats.user>0&&item.name==='Alpha Pack'&&alphaPackStats">
                                        <div *ngIf="currentUser" class="airdrop" [attr.title]="alphaPackStats.user+' Airdrop Eligible'">
                                            <div class="bottom_border_stripe"></div><div class="bottom_border_stripe"></div>
                                            <span class="span_label">Airdrop Eligible</span>
                                            <span class="span_number">{{alphaPackStats.user|number}}</span>
                                        </div>
                                    </div>
                                    <div class="air_drop pack_counts" *ngIf="gemPackStats && gemPackStats.user>0&&item.name!='Alpha Pack'&&c.category!=='elixir'">
                                        <div class="bottom_border_stripe"></div><div class="bottom_border_stripe"></div>
                                        <div *ngIf="currentUser" class="airdrop" [attr.title]="gemPackStats.user+' Airdrop Eligible'">
                                            <span class="span_label">Airdrop Eligible</span>
                                            <span class="span_number">{{gemPackStats.user|number}}</span>
                                        </div>
                                    </div>
                                    <div class="textwrap">
                                        <div class="caption buy">
                                            <input class="buy_pack_quantity" type="number" min="1" step="1" 
                                                [attr.value]="1" 
                                                [(ngModel)]="item.qty" 
                                                (keyup)="update_bonus(item.qty,i,c.category)" 
                                                (click)="update_bonus(item.qty,i,c.category)" />
                                            <div class="price _top">
                                                {{(item.qty>0?item.qty*item.item_price:item.item_price)|number}} {{selectedCurrency}}
                                            </div>
                                            <div [disabled]="loading" class="black add_to_cart gold forgium button" (click)="confirm_purchase(item, c.category)">
                                                <img src="https://splinterforge.s3.us-east-2.amazonaws.com/tokens/forgium.png" width="25" height="25" loading="lazy"> 
                                                <span *ngIf="!showConfirmation || selectedItem != item">
                                                    <span *ngIf="c.name!='Presale'">
                                                        Buy 
                                                        <span class="currency_chip">With {{item.symbol === 'CRATE' ? selectedCurrency : 'FORGE'}}</span>
                                                    </span>
                                                    <span *ngIf="c.name=='Presale'">
                                                        Buy 
                                                        <span class="currency_chip">With {{selectedCurrency=='FORGE'||selectedCurrency=='SC'?'Forgium':selectedCurrency}}</span>
                                                    </span>
                                                </span>
                                                <span *ngIf="showConfirmation && selectedItem == item">
                                                    Purchasing...
                                                </span>
                                            </div>
                                            <div class="price _bot">
                                                {{(item.qty>0?item.qty*item.item_price:item.item_price)|number}} {{item.symbol === 'CRATE' ? selectedCurrency : 'FORGE'}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="textwrap airdrop_bottom_button" *ngIf="item.type === 'pack'">
                                        <div class="caption buy air" (click)="airdrop_button(item,c)" >
                                            <div [attr.class]="'translucent button a_drop'+(airdrop[c.name]==item?' active':'')">Airdrop</div>
                                            <div [disabled]="loading" [attr.class]="'add_to_cart airdrop_trigger button'+(airdrop[c.name]==item?' active':'')">
                                                <i class="down_arrow fa-solid fa-arrow-down"></i>
                                                <i class="cloud fa-solid fa-cloud"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                <div class="clear"></div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="show_crate_airdrop_panel"></div>
                    <div *ngIf="show_bag_airdrop_panel"></div>
                </div>
                <div *ngFor="let item of items | type:c.category; let i = index">
                    <shop-bonus-items *ngIf="item.type !== 'elixir'"
                        [name]="item.name"
                        [create_qty]="item.qty"
                        [bonus_points]="bonus_points[i+'_'+c.category]"
                        [bonus_percentage]="bonus_percentage[i+'_'+c.category]">
                    </shop-bonus-items>
                </div>
                <airdrop-info *ngIf="alphaAirdropCard"
                    [cards]="cards"
                    [name]="item_name"
                    [price]="item_price"
                    [alphaAirdropCard]="alphaAirdropCard"
                    [guaranteedDrops]="guaranteedDrops"
                    [airdrop_cards_per_pack]="currentGuaranteedDropRate"
                    [packs_purchased]="bought"
                    [show]="show_airdrop[c.name]"
                    (hide)="close_airdrop()">
                </airdrop-info>
            </div>
        </div>
    </div>
</section>
<product-modal
    [show]="showProductModal"
    [product]="selectedItem"
    (done)="hide_product_modal($event)">        
</product-modal>
<notice-modal
    [title]="'Purchase'"
    [label]="noticeLabel"
    [show]="showConfirmation"
    [mode]="'confirmation'"
    [item]="selectedItem"
    [type]="true"
    [purchase]="true"
    [loading]="loadingConfirmation"
    (done)="buy($event)">
</notice-modal>
<success-modal
    [show]="showSuccessModal"
    [title]="'Purchase Successful!'"
    [subtitle]="'Congratulations!'"
    [label]="'Your purchase from the shop was successful! Now go to the Forge to open your new '+(selectedItem?selectedItem.name:'packs')+'.'"
    (close)="showSuccessModal=false;selectedItem=null;">
</success-modal>
<notice-modal
    [show]="notice"
    [title]="'IMPORTANT!!!'"
    [label]="'Any packs or potions owned/used during testing phase will be wiped before live launch.'"
    (done)="notice=false">
</notice-modal>
<loading-modal
  [title]="'Loading'"
  [show]="loading"
  (done)="loading=false">
</loading-modal>
