import { Component, OnInit, OnDestroy, OnChanges, Input, EventEmitter, Output } from '@angular/core';
import { DataService } from '@app/_services/data.service';
import { HelperService } from '@app/_services/helper.service';
import { Router, NavigationEnd } from '@angular/router';
import { fade, scale_in } from '../../app.animations';
import { popin } from '../../slcard/slcard.animations';
@Component({ 
  selector: 'sfcard-selector',
  templateUrl: 'sfcard.component.html', 
  animations: [fade,popin,scale_in],
  styleUrls: [
    '../../slcards/stats-icons/stats.component.styl',
    '../../slcards/stats-abilities/abilities.component.styl',
    './sfcard.component.styl'    
  ],
})
export class SFCardComponent implements OnInit, OnDestroy, OnChanges {
  @Input('sfcard') sfcard: any;
  @Input('sfcards') sfcards: any;
  @Input('stackView') stackView: any = true;
  @Input('selected') selected: any = false;
  @Input('hover') hover: any = false;
  @Input('smaller') smaller: any = false;
  @Input('flipped') flipped: any = false;
  @Input('animate_in') animating_in: boolean = false;
  @Output('select_socket') select_socket = new EventEmitter();
  @Output('loaded') loaded = new EventEmitter();
  @Output('flipping') flipping = new EventEmitter();
  @Output('hoverstate') hoverstate = new EventEmitter();
  style_boxshadow: any;
  level_stat: string;
  card_slot_type: any;
  card_stat: number;
  quantity: number;
  animate_in: boolean;
  imgL = 0;
  constructor(
    private helper: HelperService,
    private router: Router,
    private data: DataService
  ) {}
  ngOnInit() {
    this.open();
  }
  ngOnChanges(i){
    if(i.sfcard)this.open();
  }
  open() {
    this.getCardStat();
    this.updateSfCard();
    this.animate_in=this.animating_in;
    if(this.stackView)this.getUniqueQty();
    if(this.animating_in){
      this.flipped=true;
    }
  }
  _h(i){this.hoverstate.emit(i);}
  updateSfCard() {
    if(this.sfcard){
      switch(this.sfcard.slot) {
        case 'All':
          this.card_slot_type = 'All';
        break;
        case 'relic':
          this.card_slot_type = 'Relic';
        break;
        case 'back':
          this.card_slot_type = 'Back';
        break;
        case 'body':
          this.card_slot_type = 'Body';
        break;
        case 'feet':
          this.card_slot_type = 'Feet';
        break;
        case 'hands':
          this.card_slot_type = 'Hands';
        break;
        case 'head':
          this.card_slot_type = 'Head';
        break;
        case 'legs':
          this.card_slot_type = 'Legs';
        break;
        case 'necklace':
          this.card_slot_type = 'Necklace';
        break;
        case 'rangedWeapon':
          this.card_slot_type = 'Bows';
        break;
        case 'rangedOffhand':
          this.card_slot_type = 'Quivers';
        break;
        case 'ring':
          this.card_slot_type = 'Ring';
        break;
        case 'meleeWeapon':
          this.card_slot_type = 'Warrior Weapon';
        break;
        case 'meleeOffhand':
          this.card_slot_type = 'Shields';
        break;
        case 'magicWeapon':
          this.card_slot_type = 'Wizard Weapon';
        break;
        case 'magicOffhand':
          this.card_slot_type = 'Wizard Offhand';
        break;
      }
      this.sfcard.boss_levels = [
        (this.sfcard.rarity==1)?1:0,
        (this.sfcard.rarity==2)?1:0,
        (this.sfcard.rarity==3)?1:0,
        (this.sfcard.rarity==4)?1:0
      ];
      if(this.sfcard.unflipped)this.sfcard.unflipped=this.flipped;
      this.sfcard['ability_description'] = this.helper.getAbilityDesc(this.sfcard);
      this.get_sockets();
    }
  }
  getLevelStatus() {
    if (this.sfcard.type !== 'socket') {
      
      const cardLevels = this.helper.config.sfCardLevels[this.sfcard.rarity-1];
      let next = 0;
      
      for (let i = 0; i < cardLevels.length; i++) {
        let threshold = cardLevels[i];
        if (this.sfcard.combined < threshold) {
          next = threshold;
          i = cardLevels.length;
        }
      }
      
      if (next === 0) {
        next = cardLevels[cardLevels.length-1];
      }

      if (next === cardLevels[cardLevels.length-1]) {
        this.level_stat = 'This card is MAX level';
      } else {
        this.level_stat = this.sfcard.name + ' - ' + this.sfcard.combined + '/' + next + ' towards level ' + (this.sfcard.level+1);
      }

    } else {
      let text = this.helper.getAbilityDesc(this.sfcard);
      if (this.sfcard.stat === 'Ranged') text += '\nThis can only be applied to a ranged weapon.';
      else if (this.sfcard.stat === 'Attack') text += '\nThis can only be applied to a melee weapon.';
      else if (this.sfcard.stat === 'Magic') text += '\nThis can only be applied to a magic weapon.';
      this.level_stat = text;
    }
  }

  getBoxShadow() {
    if (this.isMaxLevel()) {
      this.style_boxshadow = ' 0 0 15px red';
    } else {
      this.style_boxshadow = ' none';
    }
  }

  getEquippedClassDisplay() {
    if (this.stackView) {
      let stack = this.sfcards.filter(c => c.slot === this.sfcard.slot && c.rarity === this.sfcard.rarity && c.equipped);
      let text = '';
      if (stack?.length > 0) {
        stack.forEach(c => {
          if (c.equipped) {
            if (text === '') text = c.equippedOn.slice(0,3).toUpperCase();
            else text += '/' + c.equippedOn.slice(0,3).toUpperCase();
          }
        })
      }
      return text;
    }
    if (this.sfcard.equippedOn === 'warrior') return 'WAR';
    else if (this.sfcard.equippedOn === 'wizard') return 'WIZ';
    else if (this.sfcard.equippedOn === 'ranger') return 'RAN';
    else return '';
  }
  getSlotOverlay(slot) {
    let url = 'https://splinterforge.s3.us-east-2.amazonaws.com/frame_art/slots/';
    if (slot.indexOf('eapon') > -1) url += 'weapon.png';
    else if (slot.indexOf('ffhand') > -1) url += 'offhand.png';
    else url += slot + '.png';
    return url;
  }
  getSocketedImage(cid) {
    if (!this.sfcards) this.sfcards = this.helper.mySFCards;
    let item = this.sfcards ? this.sfcards.find(i => { return i.cid === cid; }) : null;
    return item ? item.imgURL : '';
  }
  getSocketedName(cid) {
    if (!this.sfcards) this.sfcards = this.helper.mySFCards;
    let item = this.sfcards ? this.sfcards.find(i => { return i.cid === cid; }) : null;
    return item ? item.name : '';
  }
  async getCardStat() {
    this.card_stat = await this.helper.getCardStat(this.sfcard);
  }
  getUniqueQty() {
    this.quantity = 0;
    if (!this.sfcards) return;
    let same = this.sfcards.filter(c => { return c.name === this.sfcard.name && c.foil === this.sfcard.foil; });
    this.quantity = same.length;
    return this.quantity;
  }
  get_sockets() {
    if(this.sfcard.socket_items_processed || !this.sfcard.sockets) return;
    var sockets = [];
    for( let sock of this.sfcard.socket_items ) {
      var new_sock = {};
      new_sock['name'] = this.getSocketedName(sock);
      new_sock['img_url'] = this.getSocketedImage(sock);
      new_sock['item'] = new_sock['img_url']!=''?true:false;
      new_sock['description'] = this.sfcards ? this.helper.getAbilityDescFromCID(sock, this.sfcards) : '';
      sockets.push(new_sock);
    }
    //this.sfcard.socket_items = sockets;
    this.sfcard.socketed = sockets;
    this.sfcard.sockets = this.sfcard.socket_items.length;
  }
  img_load() {
    this.imgL++;
    if(this.imgL==3) {
      this.getBoxShadow();
      this.getLevelStatus();
      this.loaded.emit();
      if(this.animating_in)setTimeout(()=>{
        this.animate_in=false;
        setTimeout(()=>{ 
          this.flipped=false;
          this.flipping.emit(true); 
        },300);
      },900);
    }
  }
  isMaxLevel() {
    //const cardLevels = this.helper.config.sfCardLevels[this.sfcard.rarity-1];
    //const maxLevel = cardLevels[cardLevels.length-1];
    //let cardsAwayFromMax = maxLevel - this.sfcard.combined;
    return this.sfcard.level === 4; //cardsAwayFromMax < 1 ? true : false;
  }
  click_socket() {
    this.select_socket.emit(1);
  }
  ngOnDestroy() {
    this.imgL=0;
    this.flipped=false;
  }
}