import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({ providedIn: 'root' })
export class MineRunService {

    apiUrl = environment.apiUrl;

    constructor(private http: HttpClient) {  }    

    setupMineRun(u,t,ids,tier,heroType,burnReset,freeSplinter,resetCost) {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/mine/setupMineRun', { username: u, token: t, uids: ids, tier: tier, hero: heroType, burnReset: burnReset, free: freeSplinter, resetCost: resetCost })
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    reroll(params) {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/mine/rerollAbilities', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    rezMonster(params) {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/mine/rezMonster', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    loadMineRun(u,t) {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/mine/loadMineRun', { username: u, token: t })
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }
}
