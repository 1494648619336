import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Asset } from '../../_models/user';
import { HelperService } from '../../_services/helper.service';
import { DataService } from '../../_services/data.service';
import { AppComponent } from '../../app.component';
import { Item, User } from '@app/_models';
import { fade, flip_up, pointer, boxlarge, boxsmaller, field_error } from '../../app.animations';
@Component({
  selector: 'stamina-elixirs',
  animations: [fade, flip_up, pointer, boxlarge, boxsmaller, field_error],
  templateUrl: './stamina-elixirs.component.html',
  styleUrls: [ './stamina-elixirs.component.less' ]
})
export class StaminaElixirsComponent implements OnInit {
  @Input('low_stamina') low_stamina = false;
  @Input('show') show = false;
  @Output('done') done = new EventEmitter();
  constructor(
    public app: AppComponent,
    public helper: HelperService,
    public data: DataService
  ) {
    //this.helper.loadPrices();
    this.helper.loadShopItems();
  }
  burnRate: number;
  stamQty: number = 10;
  hasPotions: boolean = false;

  currentUser: User;
  asset = new Asset();
  result_message: any;
  selected_item: any;
  selected_asset: any;
  receiver: string = '';
  success: any;
  error: any;
  items: any;
  t: any;
  ngOnChanges(property) {
    if(property.show){
      if (property.show.currentValue) {
        this.calcBurnRate();
      }
    }
  }
  ngOnInit() {
    this.calcBurnRate();
    this.asset.qty = 1;
    this.data.onUserChange().subscribe((u) => {
      this.currentUser = u;
      // this.recount_items(this.items);
    });
    this.data.setUser(this.helper.currentUser);
    this.data.onShopItemsChange().subscribe(i=>{
      this.items = i.filter(x => { return x.type !== 'pack' && x.name.indexOf('Expansion') === -1; });
      this.items.sort((a,b)=>{return b.weight-a.weight;});
      // this.recount_items(this.items);
    });
    
  }
  async calcBurnRate() {
    let mod = await this.helper.getGlobalMod();
    this.burnRate = parseFloat((15 * (mod+5)/ 100).toFixed(1));
    let user = await this.helper.getCurrentUser();
    if (user?.inGameAssets?.length > 0) {
      for (let i = 0; i < user.inGameAssets.length; i++) {
        let item = user.inGameAssets[i];
        if (item.name.indexOf('Potion') > -1 && item.qty > 0) {
          this.hasPotions = true;
          i = user.inGameAssets.length;
        }
      }
    }
  }
  async burnForStamina() {
    let user = await this.helper.getCurrentUser();
    if (user?.sc?.balance > this.burnRate * this.stamQty) {
      let amt = this.burnRate * this.stamQty;
      if(confirm('Are you sure you would like to burn ' + amt + ' FORGE for ' + this.stamQty + ' stamina?')) {
        let res: any = await this.helper.burnForStamina(this.burnRate, this.stamQty);
        //let res = { message: 'success' };
        if (res.message === 'success') {
          this.burnRate = res.actualRate;
          amt = this.burnRate * this.stamQty;
          this.error = false;
          this.success = true;
          this.result_message = 'Burn Successful!';
          this.helper.showSnackbarMessage(this.result_message);
          this.helper.changeSC(-amt);
          this.helper.changeStamina(this.stamQty);
          setTimeout( () => { 
            this.success = false;
            this.result_message = false;
          },7000);
        } else {
          if (res.message) this.helper.showSnackbarMessage(res.message);
          else this.helper.showSnackbarMessage('error sending request');
          this.error = 400; 
          this.success = false;
          this.result_message = res['message'] ? res['message'] : 'Error processing request.';
        }
      }
    } else {
      if (!user) this.helper.showSnackbarMessage('error with user object');
      else console.log('not enough FORGE');
    }
  }
  available(i) {
    let found = this.currentUser.inGameAssets.find(a => a.name === i.name);
    //if (found && found.qty > 0) console.log(found);
    return found ? found.qty : 0;
  }
  recount_items(i) {
    if(!i)return;
    for (var j = 0; j < i.length; ++j) {
      i[j].stamina=i[j].description.split('Restore ').pop().split(' stamina')[0];
      i[j].stamina_number=!isNaN(i[j].stamina);
      i[j]['owned']=this.getQtyOwned(i[j]);
      i[j].qty=1;
    }
    this.items = i;
  }
  close() {
    this.show = false
    this.done.emit(1);
  }
  getQtyOwned(item) {
    if(this.currentUser){
      let found = this.currentUser.inGameAssets.find(i=>i.name === item.name);
      return found ? found.qty : 0;  
    }
  }
  async buy(asset) {
    this.helper.showSnackbarMessage('This item is no longer available, please use burn for stamina method.')
    return;
    let type = asset.type;
    let name = asset.name;
    let qty = 1;
    let price = asset.price[0].amount;
    if (qty < 1) {
        this.error = 400;
        this.success = false;
        this.result_message = 'Enter a Positive Amount';
        return false;
    } else if (type === 'potion' || type === 'elixir' || type === 'pack') {
        let balance = this.currentUser.sc.balance;
        if (price <= balance) {
          this.data.setLoading(true);
          var purchase = await this.helper.buy(type, name, qty);
          if(purchase) {
            let found = this.currentUser.inGameAssets.find(a => a.name === name);
            if (found) {
              found.qty += 1;
              this.data.setUser(this.currentUser);
            }
            this.error = false;
            this.success = true;
            this.result_message = 'Purchase Successful!';
            this.selected_asset = asset;
            this.helper.showSnackbarMessage('Stamina successfully purchased.');
            this.data.setLoading(false);
            setTimeout(()=>{
              this.success = false;
              this.result_message = false;
              this.selected_asset = false;
            }, 5000);
          } else {
            this.error = 400; this.success = false;
            this.result_message = 'Purchase Cancelled';
            this.helper.showSnackbarMessage('Purchase cancelled.');
            setTimeout(()=>{ 
              this.result_message = false; 
              this.success = false;
              this.error = null; 
            }, 5000);
          }
        } else {
          this.error = 400; this.success = false;
          this.result_message = 'Not Enough Balance';
          this.helper.showSnackbarMessage('Not enough balance.');
          setTimeout(()=>{ 
            this.result_message = false; 
            this.success = false;
            this.error = null; 
          }, 5000);
        }
    }
  }
  async useConsumable(item) {
    if (this.getQtyOwned(item)<1){
      this.helper.showSnackbarMessage("You do not not own any.");
    } else {
      this.data.setLoading(true);
      let result = await this.helper.useConsumable(item, 1);
      if(result['message'] === 'success') {
        this.error = false;
        this.success = true;
        this.result_message = 'Stamina potion applied!';
        this.selected_asset = item;
        this.helper.showSnackbarMessage(this.result_message);
        let change = parseInt(item.description.match(/\d+/g)[0]); // pull the number of stamina from the description
        if (item.name.indexOf('Potion') > -1 && typeof(change) === 'number') this.helper.changeStamina(change);
        setTimeout( () => { 
          this.success = false;
          this.selected_asset = false;
          this.result_message = false;
        },7000);
      } else {
        this.error = 400; 
        this.success = false;
        this.result_message = result['message'] ? result['message'] : 'Error processing request.';
      }
      this.data.setLoading(false);
    }
  }
}