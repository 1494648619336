import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { fade, flip_up, pointer } from '../../app.animations';

@Component({
  selector: 'team-results',
  animations: [fade, flip_up, pointer],
  templateUrl: './team-result.component.html',
  styleUrls: ['./team-result.component.styl']
})
export class TeamResultComponent implements OnInit {

  constructor() { }
  @Output('done') done = new EventEmitter();  
  @Output('watch') watch = new EventEmitter();  
  @Input('rules') rules = [];
  @Input('points') points: any;
  @Input('show') show = false;
  @Input('player') player: any;
  @Input('totalDmg') totalDmg: any;
  @Input('gear') gear = 1;
  @Input('team') team: any;
  ngOnInit(): void {
  }
  close() {
    this.show=false;
    this.done.emit(1);
  }
  watchReplay() {
    this.watch.emit(1);
  }
}