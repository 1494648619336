import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Asset } from '../../_models/user';
import { HelperService } from '../../_services/helper.service';
import { DataService } from '../../_services/data.service';
import { AppComponent } from '../../app.component';
import { fade, flip_up, pointer } from '../../app.animations';
@Component({
  selector: 'open-pack-modal',
  animations: [fade, flip_up, pointer],
  templateUrl: './open-pack.component.html',
  styleUrls: ['./open-pack.component.less']
})
export class OpenPackComponent implements OnInit {
  @Input('show') show = false;
  @Input('label') top_label = '';
  @Input('title') title = 'Open Pack';
  @Output('done') done = new EventEmitter();
  constructor(
    public app: AppComponent,
    public helper: HelperService,
    public data: DataService
  ) {
  }
  ngOnInit() {
  }
  done_button(status) {
    this.done.emit(status);
  }
  close() {
    this.show = false
    this.done.emit(0);
  }
}