import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Item, User } from '@app/_models';
import { DataService } from '@app/_services/data.service';
import { HelperService } from '@app/_services/helper.service';
import { Subscription } from 'rxjs';
import { FetchService } from '../_services/services';
import { AppComponent } from '../app.component';
import { environment } from '../../environments/environment';
import { fade, shop_category, sidedown, display_inline_block } from '../app.animations'
@Component({
    selector: 'shop',
    animations: [fade, shop_category, display_inline_block, sidedown],
    templateUrl: 'shop.component.html',
    styleUrls: ['./shop.component.styl']
})
export class ShopComponent implements OnInit, AfterViewInit {
    categories = [
        {name: 'Packs', category: 'pack'},
        //{name: 'Elixirs', category: 'elixir'}
    ];
    presaleable = [ 'Alpha Crate' ]
    alphaAirdropCard: any;
    config: any;
    showProductModal: boolean;
    loading: Boolean = false;
    apiURL = environment.apiUrl;
    currentUser: User;
    cards: [];
    item: [];
    items: any;
    unlimtedManaQty: number = 1;
    colorlessQty: number = 1;
    unlockAllQty: number = 1;
    maxAllQty: number = 1;
    selectedItem: any = {qty: 0,name: '',price: 0};
    selectedCategory: any;
    defaultCurrency: string = 'FORGE';
    selectedCurrency: string = 'FORGE';
    target = 'packs';
    packItem: any;
    qty = 1;
    category_hidden = [];
    notice = false;
    alphaPackStats: any;
    gemPackStats: any;
    loaded = [];
    airdrop = [];
    show_airdrop = [];
    showSuccessModal: boolean;
    loadingConfirmation: string;
    buying_something: any;
    noticeLabel: any;
    message: string;
    message_2: string;
    message_3: string;
    airdrop_cards = [];
    bonus_points = [];
    bonus_percentage = [];
    guaranteedDrops: any;
    airdropCards: any;
    airdropGuarantees: any;
    airdropMilestones: any;
    bought: any;
    item_price: string;
    item_name: string;
    alpha_bonus_points: number;
    currentGuaranteedDropRate: number;
    alpha_bonus_percentage: number;
    constructor(
            private helper: HelperService, 
            private decimalPipe: DecimalPipe, 
            private app: AppComponent, 
            public fetch: FetchService, 
            private data: DataService) 
    {
        this.data.onLoadingChange().subscribe(b => this.loading = b);
        this.data.onUserChange().subscribe(c => { this.currentUser = c; });
        this.data.onConfigChange().subscribe(c => this.config = c);
        this.data.setConfig(this.helper.config);
    }
    airdrop_button(item, c) {
        this.alphaAirdropCard = null;
        if(!this.currentUser) {
            this.app.openLoginModal();
        } else {
            if (this.airdrop[c.name] == item) {
                this.close_airdrop();
            } else {
                this.show_airdrop[c.name] = true;
                this.airdrop[c.name] = item;
                this.item_price = item.item_price;
                this.item_name = item.name;
                if (item.symbol === 'CRATE' && this.alphaPackStats) { this.bought = this.alphaPackStats.user }
                else if (item.symbol === 'BAG' && this.gemPackStats) { this.bought = this.gemPackStats.user }
                else this.bought = 0;
                this.getAirdropInfo(item.name); 
                // airdrop[c.name].name
                // const element = document.querySelector("#airdrop_"+c);
                // element.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }
        }
    }
    select_currency(currency, item_i?) {
        if (currency === 'DEC' && this.config.mode === 'testServer') {
            this.helper.showSnackbarMessage('not available on test server');
            return;
        } else {
            this.selectedCurrency = currency;
        }
    }
    close_airdrop() {
        this.airdrop=[];
        this.show_airdrop=[];
    }
    update_bonus(qty,i,c) {
        if(qty < 50) {
            this.bonus_percentage[i+'_'+c] = 0;
            this.bonus_points[i+'_'+c] = 0;
        } else if(qty >= 50 && qty < 250) {
            this.bonus_percentage[i+'_'+c] = 0.10;
            this.bonus_points[i+'_'+c] = Math.floor(qty*0.10);
        } else if(qty >= 250 && qty < 1000) {
            this.bonus_percentage[i+'_'+c] = 0.15;
            this.bonus_points[i+'_'+c] = Math.floor(qty*0.15);
        } else if(qty >= 1000) {
            this.bonus_percentage[i+'_'+c] = 0.20;
            this.bonus_points[i+'_'+c] = Math.floor(qty*0.20);
        }
    }
    async ngOnInit() {
        this.helper.loadShopItems();
        this.data.onUserChange().subscribe(u => this.currentUser = u);
        this.data.onShopItemsChange().subscribe(i => {
            //console.log('items', i);
            this.items = i;
            if(this.items) for (var j = 0; j < this.items.length; ++j) {
                this.items[j].qty            = 1;
                this.items[j].item_price     = this.getPrice(this.items[j]);
                // i[j].price_sl             = this.getSinglePrice(this.items[j]);
                this.items[j].owned          = this.getQtyOwned(this.items[j]);
                this.items[j].total_packs    = this.getTotalPacks(this.items[j]);
            }
            this.packItem = this.items.find(i => i.type === 'pack');
        });
        if (this.helper.currentUser) this.data.setUser(this.helper.currentUser);
        this.data.onCollectionChange().subscribe((c) => {
            this.cards = c;
        });
        this.getPackStats();
    }
    ngAfterViewInit() { }
    async buyPacks(token) {
        if (!this.packItem || this.qty < 1) return;
        let success = false;
        if (token === 'FORGE') {
            if (this.currentUser.sc.balance < this.qty*3000) { alert('Not enough FORGE!'); return; }
            this.data.setLoading(true);
            this.showSuccessModal = await this.helper.buy('pack', this.packItem.name, this.qty);
            this.data.setLoading(false);
        }
    }
    showConfirmation = false;
    confirm_purchase(product, category) {
        if (this.selectedCurrency === 'DEC' && this.config.mode === 'testServer') {
            this.helper.showSnackbarMessage('not available on test server');
            return;
        }
        if(!this.currentUser) {
            this.app.openLoginModal();
        } else {
            if(product.qty<1)return;
            this.showConfirmation=true;
            product.set_price = this.getPrice(product);
            product.category=category;
            this.selectedItem=product;
            var price = this.decimalPipe.transform(Number(product.item_price*product.qty), '1.2-2');
            this.noticeLabel='You are about to purchase '+product.qty+' '+product.name+(product.qty==1?'':'s')+' for '+price+ ' ' + this.selectedCurrency + '. Continue?'            
        }
    }
    getAirdropInfo(type) {
        if(!this.currentUser) {
            this.app.openLoginModal();
        } else {
            this.airdropCards = (type === 'Alpha Pack' ? this.config.airdropCards : this.config.gemAirdropCards);
            this.airdropGuarantees = this.config.airdropGuarantees;
            this.airdropMilestones = this.config.airdropMilestones;
            let milestone;
            let airdropNum = 0;
            for (let i = 0; i < this.airdropMilestones.length; i++) {
                if ((1000000-this.alphaPackStats.overall) > this.airdropMilestones[i]) {
                    milestone = 1000000-this.airdropMilestones[i];
                    airdropNum = i;
                    i = this.airdropMilestones.length;
                }
            }
            if (airdropNum > -1) {
                this.alphaAirdropCard = this.airdropCards[airdropNum];
            }
            this.currentGuaranteedDropRate = this.airdropGuarantees[airdropNum];
            if (type === 'Alpha Pack' && this.alphaPackStats.user > 0) {
                this.guaranteedDrops = Math.floor(this.alphaPackStats.user/this.currentGuaranteedDropRate);
            } else if (type === 'Enhancement Pack' && this.gemPackStats.user > 0) {
                this.guaranteedDrops = Math.floor(this.gemPackStats.user/this.currentGuaranteedDropRate);
            } else {
                this.guaranteedDrops = 0;
            }
        }
    }
    select_product(product, category) {
        this.selectedCategory=category;
        this.selectedItem = product;
        this.showProductModal = true;
    }
    hide_product_modal(event) {
        this.showProductModal=false;
        if(event){
            this.confirm_purchase(this.selectedItem,this.selectedCategory);
        } else {
            this.selectedCategory=null;
            this.selectedItem=null;
        }
    }
    async buy(buy?) {
        if(!buy || !this.selectedItem){
            this.showConfirmation = false;
            setTimeout(()=>{
                this.noticeLabel=null;
                this.selectedItem=null;
                this.loadingConfirmation=null;
            },500);
            return;
        }
        if ( this.selectedItem ) {
            console.log(this.selectedItem);
            //await this.helper.signTx('');
            //return;
            this.loadingConfirmation = 'Purchasing with ' + this.selectedCurrency + ' ...';
            var asset = this.selectedItem;
            let type = asset.type;
            let name = asset.name;
            let qty = asset.qty;
            let price = asset.symbol === 'BAG' ? 4000 * qty : asset.price.find(a => a.symbol === this.selectedCurrency).amount * qty;
            if (!price) {
                this.helper.showSnackbarMessage('not available for ' + this.selectedCurrency);
                this.loadingConfirmation='not available for ' + this.selectedCurrency;
                this.selectedItem=null;
                return false;
            }
            if(this.currentUser) {
                let mode = this.helper.getMode();
                if (qty < 1) {
                    this.helper.showSnackbarMessage('Please enter a positive amount.');
                    this.loadingConfirmation='Purchase Error';
                    this.selectedItem=null;
                    return false;
                } else if (type === 'potion' || type === 'elixir' || type === 'pack') {
                    let xyz = await this.helper.loadBalances(this.currentUser.username);
                    let heBalance = xyz.find(b => b.symbol === 'DEC');
                    let balance = this.selectedCurrency === 'FORGE' ? this.currentUser.sc.balance : heBalance.balance;
                    if (price <= parseFloat(balance)) {
                        let reservation;
                        if (asset.type === 'pack') {
                            reservation = await this.helper.getReservation(asset.symbol, qty, asset.symbol === 'BAG' ? 'FORGE' : this.selectedCurrency, 'general sale');
                            console.log(reservation);
                            if (reservation?.message !== 'success') {
                                this.helper.showSnackbarMessage('error getting reservation');
                                this.loadingConfirmation='error getting reservation';
                                this.selectedItem=null;
                                return false;
                            }
                        
                            let hive_keychain = window.hive_keychain;
                            reservation = reservation.data;
                            let fixedAmount = reservation.price.toFixed(7).slice(0, -4);
                            let username = this.helper.getCurrentUsername();
                            let totalOrder = reservation.qty+reservation.bonus;
                            if (this.selectedCurrency === 'DEC') {
                                let sendJSON = {
                                    'contractName' : 'tokens',
                                    'contractAction' : 'transfer',
                                    'contractPayload' : {
                                        'symbol' : reservation.paymentToken.toUpperCase(),
                                        'to' : 'forgemaster',
                                        'quantity' : fixedAmount,
                                        'memo' : reservation.memo,
                                        'app' : 'sf_web_app'
                                    }
                                };
                            
                                let res = await new Promise(resolve => {
                                    hive_keychain.requestCustomJson(
                                        username, 'ssc-mainnet-hive', 'Active', JSON.stringify(sendJSON),
                                        'Payment of ' + fixedAmount + ' ' + reservation.paymentToken + ' to SplinterForge.io for ' + reservation.qty+reservation.bonus + ' total CRATEs', response => {
                                            resolve(response);
                                        });
                                    }
                                )
                                console.log(res);

                                if (!res['success'] || !res['result'] || (!res['result']['id'] && !res['result']['tx_id'])) {
                                    this.helper.showSnackbarMessage('transaction not broadcast');
                                    return;
                                }

                                let tempID = res['result']['id'] ? res['result']['id'] : res['result']['tx_id'];

                                this.data.setLoading(true); // this should trigger some kind of loading/waiting screen
                                let saleConfirmation = await this.helper.confirmSale(tempID);
                                this.data.setLoading(false); // this should end the loading/waiting screen
                                if (saleConfirmation?.message === 'success') {
                                    this.helper.showSnackbarMessage('purchase successful');
                                    this.showConfirmation = false;
                                    this.showSuccessModal = true;
                                    if (asset.symbol === 'CRATE') this.helper.changeCRATE(totalOrder);
                                    else if (asset.symbol === 'BAG') this.helper.changeBAG(totalOrder);
                                    setTimeout(()=> {
                                        this.loadingConfirmation=null; 
                                    },1000)
                                    //this.loadingConfirmation=null;
                                } else {
                                    this.loadingConfirmation=saleConfirmation.message;
                                    this.helper.showSnackbarMessage(saleConfirmation.message);
                                }
                            } else if (this.selectedCurrency === 'FORGE') {
                                if (mode !== 'live') {
                                    this.data.setLoading(true);
                                    var purchase = await this.helper.buy(type, name, qty, reservation.memo);
                                    console.log('purchae',purchase);
                                    if(purchase){
                                        this.showConfirmation = false;
                                        this.showSuccessModal = true;
                                        if (asset.symbol === 'CRATE') this.helper.changeCRATE(qty);
                                        else if (asset.symbol === 'BAG') this.helper.changeBAG(qty);
                                        this.helper.changeSC(-price);
                                        setTimeout(()=> {
                                            this.loadingConfirmation=null; 
                                        },1000)
                                        this.loadingConfirmation=null;
                                    } else {
                                        this.loadingConfirmation='Purchase Unsuccessful';
                                    }
                                    this.data.setLoading(false);
                                } else {
                                    var res = await new Promise(resolve => {
                                        hive_keychain.requestCustomJson(
                                            username, 
                                            'sf_shop_order', 
                                            'Active', 
                                            JSON.stringify({ username: username, name: name, qty: qty, memo: reservation.memo, symbol: 'FORGE' }),
                                            username + ' purchasing ' + asset.symbol, 
                                            response => {
                                                resolve(response);
                                            });
                                        })
                                    console.log(res);

                                    if (!res['success'] || !res['result'] || (!res['result']['id'] && !res['result']['tx_id'])) {
                                        this.helper.showSnackbarMessage('transaction not broadcast');
                                        return;
                                    }
    
                                    let tempID = res['result']['id'] ? res['result']['id'] : res['result']['tx_id'];

                                    let verifiedTx = await this.helper.verifyTx(tempID, asset.symbol + ' purchase');
                                    if (!verifiedTx || verifiedTx['message'] !== 'success') {
                                        alert('Could not verify pack was processed yet, processing may be behind, please refresh to see if your packs have arrived.');
                                        //return;
                                    } else {
                                        this.helper.showSnackbarMessage('purchase successful');
                                        this.showConfirmation = false;
                                        this.showSuccessModal = true;
                                        if (asset.symbol === 'CRATE') {
                                            this.helper.changeSC(-qty*3000);
                                            this.helper.changeCRATE(totalOrder);
                                        } else if (asset.symbol === 'BAG') {
                                            this.helper.changeSC(-qty*4000);
                                            this.helper.changeBAG(totalOrder);
                                        }
                                        setTimeout(()=> {
                                            this.loadingConfirmation=null; 
                                        },1000)
                                    }
                                }
                            }
                            this.getPackStats();
                        } else {
                            //if (mode !== 'live') {
                                this.data.setLoading(true);
                                var purchase = await this.helper.buy(type, name, qty);
                                console.log('purchae',purchase);
                                if(purchase){
                                    this.showConfirmation = false;
                                    this.showSuccessModal = true;
                                    this.helper.changeSC(-price);
                                    setTimeout(()=> {
                                        this.loadingConfirmation=null; 
                                    },1000)
                                    this.loadingConfirmation=null;
                                } else {
                                    this.loadingConfirmation='Purchase Unsuccessful';
                                }
                                this.data.setLoading(false);
                            /*} else {
                                var res = await new Promise(resolve => {
                                    hive_keychain.requestCustomJson(
                                        username, 
                                        'sf_shop_order', 
                                        'Active', 
                                        JSON.stringify({ username: username, name: asset.name, qty: qty, symbol: 'FORGE' }),
                                        username + ' purchasing ' + asset.name, 
                                        response => {
                                            resolve(response);
                                        });
                                    })
                                //console.log(res);

                                if (!res['success'] || !res['result'] || (!res['result']['id'] && !res['result']['tx_id'])) {
                                    this.helper.showSnackbarMessage('transaction not broadcast');
                                    return;
                                }

                                let tempID = res['result']['id'] ? res['result']['id'] : res['result']['tx_id'];

                                let verifiedTx = await this.helper.verifyTx(tempID, asset.name + ' purchase');
                                if (!verifiedTx || verifiedTx['message'] !== 'success') {
                                    alert('Could not verify pack was processed yet, processing may be behind, please refresh to see if your packs have arrived.');
                                    //return;
                                } else {
                                    this.helper.showSnackbarMessage('purchase successful');
                                    this.helper.changeSC(-price);
                                    this.showConfirmation = false;
                                    this.showSuccessModal = true;
                                    this.helper.refreshUser(username, this.currentUser.token);
                                    setTimeout(()=> {
                                        this.loadingConfirmation=null; 
                                    },1000)
                                }
                            }*/
                        }
                        //this.helper.navigate('shop');
                    } else {
                        this.helper.showSnackbarMessage('You do not have enough ' + this.selectedCurrency);
                        this.loadingConfirmation='You do not have enough ' + this.selectedCurrency;
                        this.loadingConfirmation=null;
                        this.selectedItem=null;
                        this.helper.navigate('shop');
                    }
                }
            } else {
               this.app.openLoginModal();
               this.selectedItem=null;
            }
            //this.helper.navigate('shop');
        }
    }
    getName(item) {
        if (item.name === 'Alpha Pack') return 'Alpha CRATE';
        else if (item.name === 'Enhancement Pack') return 'Enhancement BAG';
        else return item.name;
    }
    getBonusTiers() { return '10% bonus for 50+ packs\n15% bonus for 500+ packs\n20% bonus for 1000+ packs'; }
    getImgURL(type) {
        return this.apiURL + '/' + type + '.png';
    }
    getPackImgURL(item) {
        if (item.name === 'Alpha Pack') return '';
        else if (item.name === 'Enhancement Pack') return '';
    }
    async getPackStats() {
        this.alphaPackStats = await this.helper.getPackStats('CRATE');
        this.gemPackStats = await this.helper.getPackStats('BAG');
    }
    getTotalPacks(item) {
        if (item.qty < 50) return item.qty;
        else if (item.qty >= 1000) return item.qty + '(+' + Math.floor(item.qty*0.2) + ')';
        else if (item.qty >= 500) return item.qty + '(+' + Math.floor(item.qty*0.15) + ')';
        else if (item.qty >= 50) return item.qty + '(+' + Math.floor(item.qty*0.10) + ')';
    }
    hasFunds(item) {
        let qty = item.qty;
        if (qty < 1) {
            return false;
        } else {
            let balance = this.currentUser.sc.balance;
            let priceObj = item.price.find(p => p.symbol === 'FORGE');
            let total = priceObj.amount * qty;
            return balance < total;
        }
    }
    getSinglePrice(item) {
        let priceObj = item.price.find(p => p.symbol === 'SC');
        console.log('priceObj', priceObj);
        return priceObj.amount + ' FORGE';
    }
    getPrice(item,currency='SC') {
        // if(currency=='FORGE')currency='SC';
        if (item.qty < 1) {
            return 0;
        } else {
            for (var i = 0; i < item.price.length; ++i) {
                return Number(item.price[i].amount * item.qty).toString();
            }
        }
    }
    getQtyOwned(item) {
        if(this.currentUser){
            let found = this.currentUser.inGameAssets.find(i => i.name === item.name);
            return found ? found.qty : 0;    
        }
    }
    open_packs() { 
        this.helper.navigate('openpacks'); 
    }
 }