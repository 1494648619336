import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Asset } from '../../_models/user';
import { HelperService } from '../../_services/helper.service';
import { DataService } from '../../_services/data.service';
import { AppComponent } from '../../app.component';
import { fade, flip_up, pointer } from '../../app.animations';
@Component({
  selector: 'product-modal',
  animations: [fade, flip_up, pointer],
  templateUrl: './product-modal.component.html',
  styleUrls: [
    './product-modal.component.styl',
    '../notice/notice.component.less'
  ]
})
export class ProductModalComponent implements OnInit {
  @Input('show') show: any;
  @Input('product') product: any;
  @Output('done') done = new EventEmitter();
  constructor(
    public app: AppComponent,
    public helper: HelperService,
    public data: DataService
  ) {
  }
  ngOnInit() {
  }
  ngOnDestroy() {
  }
  done_button(status) {
    this.done.emit(status);
  }
  close() {
    this.show = false
    this.done.emit(0);
  }
}