<div class="ability_effects" *ngIf="!_img && effects">
    <div *ngFor="let a of abilities; let i = index">
        <div class="abilities" [@enter_down]="loaded_a[i]" [@width_in]="loaded_a[i]">
            <img *ngIf="getImgURL(a)" 
                 [attr.name]="a" 
                 (load)="loaded_a[i]=true"
                 src="{{getImgURL(a)}}" 
                 [attr.title]="show_card_abilities?'':a">
            <div class="card_abilities" *ngIf="show_card_abilities">
                <card-abilities-window
                    [hero]="hero"
                    [img]="getImgURL(a)"
                    [name]="a"
                    [description]="true">
                </card-abilities-window>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!_img && !effects" [attr.class]="'abil' + (smaller?' smaller_ability':'') + (hero?' hero_ability':'') + (boss?' boss_ability':'') + (smaller_stats?' smaller_item':'')">
    <div *ngFor="let a of detailed; let i = index" [attr.class]="'number_'+(rules?false:detailed).length+' icon abilities i_'+i" >
        <img class="fade_image" [attr.name]="a.ability" [@fade]="loaded[i]" (load)="loaded[i]=true" src="{{a.imgURL}}" [attr.title]="show_card_abilities?'':a.ability">
        <div class="popover_abilities" *ngIf="!show_card_abilities">{{a.ability}}</div>
        <div class="card_abilities" *ngIf="show_card_abilities">
            <card-abilities-window
                [hero]="hero"
                [img]="a.imgURL"
                [name]="a.ability"
                [description]="true">
            </card-abilities-window>
        </div>
    </div>
</div>
<div *ngIf="_img">
    <img *ngFor="let a of detailed" src="{{a.imgURL}}">
</div>