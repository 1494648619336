import { Component, OnInit, Input } from '@angular/core';
import { fade } from '../../../app.animations';
@Component({
  animations: [fade],
  selector: 'card-abilities-window',
  templateUrl: './abilities-window.component.html',
  styleUrls: ['./abilities-window.component.styl']
})
export class AbilitiesWindowComponent implements OnInit {
  constructor() { }
  show_image = false;
  @Input('img') img: any;
  @Input('name') name: any;
  @Input('description') description = true;
  @Input('desc') desc: string;
  @Input('hero') hero: boolean;
  imgLoad() { this.show_image=true; }
  ngOnInit(): void {
  }
}