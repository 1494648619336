import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'type'
})
export class TypePipe implements PipeTransform {
	transform(value: any, args?: any): any {
    	if(args == -1 || args == 'All' || args == false) return value;
		let values = [];
		if (value&&value.length>0) for (var i = 0; i < value.length; i++) {
			if (String(value[i].type) === String(args)) {
				values.push(value[i]);
			} else if (String(args) === 'foil' && value[i].foil) values.push(value[i]);
			else if (String(args) === 'nonFoil' && !value[i].foil) values.push(value[i]);
		}
		return values;
	}
}