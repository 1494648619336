<div class="forge_background_overlay"></div>
<div class="page_heading">
    <h1>Battle Modes</h1>
    <div class="tab_bar tab_buttons">
        <button [attr.class]="show=='hvh'?'tablinks active':'tablinks'" (click)="show='hvh';">
            Heroes VS Heroes
        </button>
        <button *ngIf="1>2" [attr.class]="show=='bosses'?'tablinks active':'tablinks'" (click)="show='bosses';">
            Boss Fight
        </button>
        <button *ngIf="1>2" [attr.class]="show=='mine'?'tablinks active':'tablinks'" (click)="show='mine';">
            Mine Run
        </button>
        <button *ngIf="1>2" [attr.class]="show=='heroboss'?'tablinks active':'tablinks'" (click)="show='heroboss'">
            Hero Boss
        </button>
    </div>
    <div class="bottom_border"></div>
    <div class="clear"></div>
</div> 
<div class="selectedcardbox">
    <div *ngIf="show === 'hvh'">
        <div *ngIf="heroes && subCat === 'selectHeroes'">
            <h5>Please select 2 Heroes to use</h5>
            <div class="card_wrapper" *ngFor="let hero of heroes">
                <div *ngIf="!hero.selected" (click)="selectHero(hero)">
                    <slcard-selector [card]="hero" [blur]="'no'" [fav]="false"></slcard-selector>
                </div>
            </div>
            <h5>Selected</h5>
            <div class="card_wrapper" *ngFor="let hero of selectedHeroes">
                <div (click)="selectHero(hero)">
                    <slcard-selector [card]="hero" [blur]="'no'" [fav]="false"></slcard-selector>
                </div>
            </div>
            <button class="button purple" (click)="setTeam()">Set Team</button>
        </div>
        <div *ngIf="heroes && subCat === 'selectOpponent'">
            <h5>Your Team</h5>
            <div class="card_wrapper" *ngFor="let hero of position.heroes">
                <div>
                    <slcard-selector [card]="hero" [blur]="'no'" [fav]="false"></slcard-selector>
                </div>
            </div>
            <button class="button purple" (click)="subCat='selectHeroes'">Re-select Team</button>
            <h5>Opponents</h5>
            <div *ngFor="let x of opponents">
                <h5>Team Power: {{x.teamPower}}</h5>
                <div class="card_wrapper" *ngFor="let hero of x.heroes" (click)="pickEnemy(x)">
                    <div>
                        <slcard-selector [card]="hero" [blur]="'no'" [fav]="false"></slcard-selector>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="heroes && subCat === 'hvhPreview' && enemy">
            <h5>Preview your matchup!</h5>
            <div>
                <h5>Your Team</h5>
                <h5>Team Power: {{teamPower}}</h5>
                <div class="card_wrapper" *ngFor="let hero of position.heroes">
                    <div>
                        <slcard-selector [card]="hero" [blur]="'no'" [fav]="false"></slcard-selector>
                    </div>
                </div>
                <h5>Enemy</h5>
                <h5>Team Power: {{enemy.teamPower}}</h5>
                <div class="card_wrapper" *ngFor="let hero of enemy.heroes" (click)="pickEnemy(x)">
                    <div>
                        <slcard-selector [card]="hero" [blur]="'no'" [fav]="false"></slcard-selector>
                    </div>
                </div>
            </div>
            <button class="button purple" (click)="enemy = null; subCat = 'selectOpponent'">Back</button>
            <button class="button purple" (click)="sendHvHBattle()">FIGHT!!!</button>
        </div>
    </div>
    <div *ngIf="show === 'bosses'"></div>
    <div *ngIf="show === 'mine'"></div>
    <div *ngIf="show === 'heroboss'"></div>
</div>
