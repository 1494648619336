import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Asset } from '../../_models/user';
import { HelperService } from '../../_services/helper.service';
import { DataService } from '../../_services/data.service';
import { fade, flip_up, pointer } from '../../app.animations';
@Component({
  selector: 'daily-modal',
  animations: [fade, flip_up, pointer],
  templateUrl: './daily.component.html',
  styleUrls: [ './daily.component.less' ]
})
export class DailyComponent implements OnInit {
  @Input('show') show = false;
  @Input('user') user: any;
  @Output('done') done = new EventEmitter();
  @Output('clear') clear = new EventEmitter();

  currentReward: any;
  showDaily = true;
  showHeroEvent = false;
  showElectrumBurnEvent = false;
  topTitle = '30 Day Login Rewards';
  userPoints: any;

  constructor(public helper: HelperService, public data: DataService) { }
  
  ngOnInit() {
    this.doCheck();
  }

  ngOnDestroy() { }

  async claimDaily() {
    let temp: any = await this.helper.claimDaily();
    if (temp.message === 'success') {
      this.helper.showSnackbarMessage('You have received ' + temp.qty + ' ' + temp.type);
      this.currentReward.claimed = new Date();
      await this.doCheck();
    } else {
      if (temp.message) this.helper.showSnackbarMessage(temp.message);
      else this.helper.showSnackbarMessage('error claiming');
    }
  }

  changeView(to) {
    if (to === 'heroEvent') {
      this.topTitle = 'Hero System Event';
      this.showHeroEvent = true;
      this.showDaily = false;
    } else if (to === 'daily') {
      this.topTitle = '30 Day Login Rewards';
      this.showHeroEvent = false;
      this.showDaily = true;
    } else if (to === 'electrumBurnEvent') {
      this.topTitle = 'Electrum Mini Event';
      this.showElectrumBurnEvent = true;
      this.showDaily = false;
    }
  }

  async doCheck() {
    if (!this.user) {
      this.helper.showSnackbarMessage('unable to get user');
      this.close();
    } else if (!this.user.settings || !this.user.settings.daily) {
      let temp: any = await this.helper.dailyRewards();
      console.log(temp);
      //this.helper.showSnackbarMessage('user does not have login rewards available');
      //this.close();
      if (temp.daily) {
        this.user.settings.daily = temp.daily;
        this.doCheck();
      } else if (temp.message) this.helper.showSnackbarMessage(temp.message);
    } else {
      let rew = this.user.settings.daily.rewards;
      for (let i = 0; i < rew.length; i++) {
        if (rew[i].claimed) {
          rew[i].it = false;
        } else if (!rew[i].claimed) {
          this.currentReward = rew[i];
          rew[i].it = true;
          rew[i].ttg = 'Claim';
          if (i > 0) {
            let diff = Date.now() - new Date(rew[i-1].claimed).getTime();
            let oneDay = 1000*60*60*24;
            if (diff < oneDay) { // less than 24 hours ago
                let today = new Date().getDate();
                let previous = new Date(rew[i-1].claimed).getDate();
                //console.log(diff, today, previous);
                if (today === previous) {
                  let mid = new Date().setHours(24,0,0,0);
                  let os = new Date().getTimezoneOffset() * 6e4;
                  let now = Date.now();
                  let mtm = Math.ceil((mid - now - os)/60000);
                  let h = Math.floor(mtm/60);
                  let m = mtm - (h*60);
                  rew[i].ttg = h + ':' + (m.toString().length < 2 ? '0' + m : m);
                }
            }
          } else rew[i].ttg = 'Claim';
          i = rew.length;
        }
      }
    }
  }

  async loadUserPoints() {
    return;
    let temp: any = await this.helper.loadUserPoints('heroEvent');
    if (temp.message === 'success') {
      //let stats = { user: user, crates: 0, bags: 0, heroLevels: 0, skillPts: 0, burntForge: 0, burnForElectrum: 0 };
      this.userPoints = temp.stats;
      this.userPoints.total = (this.userPoints.crates * 30) + (this.userPoints.bags * 40) + (this.userPoints.heroLevels * 10) + (this.userPoints.skillPts * 10) + (this.userPoints.burntForge * 0.05) + (this.userPoints.burnForElectrum * 1);
      this.userPoints.rewards = [
        { name: 'Evolution Essence', qty: Math.round(this.userPoints.total/250) },
        { name: 'Relic Fragment', qty: Math.round(this.userPoints.total/150) },
        { name: 'XP', qty: Math.round(this.userPoints.total/5) },
        { name: 'Electrum', qty: Math.round(this.userPoints.total/10) },
        { name: 'Raffle Chance', qty: Math.round(this.userPoints.total/500) }
      ]
      //console.log(this.userPoints);
    }
    else {
      if (temp.message) this.helper.showSnackbarMessage(temp.message);
      else this.helper.showSnackbarMessage('error getting points... maybe you do not have any...')
    }
  }

  close() {
    this.show = false
    this.done.emit(0);
  }
}