import { OnInit, Component, EventEmitter, Output, ElementRef, Directive, Input, ViewChild} from '@angular/core';
import { shop_category, sidedown, display_inline_block, airdrop_sidedown} from '../../app.animations';
@Component({
    selector: 'shop-bonus-items',
    animations: [shop_category, display_inline_block, sidedown, airdrop_sidedown],
    templateUrl: 'bonus-items.component.html',
    styleUrls: [ 'bonus-items.component.styl' ]
})
export class BonusItemsComponent implements OnInit {
    @Input('name') name: any;
    @Input('create_qty') create_qty: any;
    @Input('bonus_points') bonus_points: any;
    @Input('bonus_percentage') bonus_percentage: any;
    panel_hidden = false;
    ngOnInit() {

    }
}