
<!-- Splinterlands Card -->
<div *ngIf="!_img" [attr.class]="center?'slcard_container center':'slcard_container'">

    <div class="effects" *ngIf="effects" [@blend]="blend_mode">
        <div *ngIf="card_glow" class="glow" [@selected]="selected" [@fade]="card_glow"></div>
        <div class="flame_attack" *ngIf="flame_attack"><div class="attack_hit_2"></div></div>
        <div *ngIf="burn" class="burn" [@quicker_fade]="burn" [@slow_fade]="!burn_down"><div class="burning_fire lg"></div></div>
        <div class="fire" *ngIf="fire_attack!='0'"><div class="fire_attack"></div></div>
        <div class="smok" *ngIf="smoke"><div class="smoke_rising"></div></div>
        <div *ngIf="forge" class="forge" [@selected]="selected" [@fade]="forge">
            <div class="card_forge"></div>
        </div>
        <div *ngIf="forge_attack_minus" class="forge" [@selected]="selected" [@fade]="forge_attack_minus">
            <div>
                <span [attr.class]="'stats_indicator '+forge_color">{{forge_color=='green'?'+':'-'}}{{forge_amount}}</span>
                <img class="stats_icons icon_attack" src="/assets/stats_icons/melee-attack.png">
            </div>
        </div>
        <div *ngIf="forge_attack_plus" class="forge" [@selected]="selected" [@fade]="forge_attack_plus">
            <div>
                <span [attr.class]="'stats_indicator '+forge_color">{{forge_color=='green'?'+':'-'}}{{forge_amount}}</span>
                <img class="stats_icons icon_attack" src="/assets/stats_icons/melee-attack.png">
            </div>
        </div> 
        <div *ngIf="forge_speed_minus" class="forge" [@selected]="selected" [@fade]="forge_speed_minus">
            <div class="card_forge forge_speed_minus"></div>
            <div>
                <span [attr.class]="'stats_indicator '+forge_color">{{forge_color=='green'?'+':'-'}}{{forge_amount}}</span>
                <img class="speed_icons icon_attack" src="/assets/stats_icons/speed.png">
            </div>
        </div>
        <div *ngIf="forge_speed_plus" class="forge" [@selected]="selected" [@fade]="forge_speed_plus">
            <div class="card_forge forge_speed_plus"></div>
            <div>
                <span [attr.class]="'stats_indicator '+forge_color">{{forge_color=='green'?'+':'-'}}{{forge_amount}}</span>
                <img class="speed_icons icon_attack" src="/assets/stats_icons/speed.png">
            </div>
        </div>
        <div *ngIf="forge_magic_minus" class="forge" [@selected]="selected" [@fade]="forge_magic_minus">
            <div>
                <span [attr.class]="'stats_indicator '+forge_color">{{forge_color=='green'?'+':'-'}}{{forge_amount}}</span>
                <img class="stats_icons icon_attack" src="/assets/stats_icons/magic-attack.png">
            </div>
        </div>
        <div *ngIf="forge_magic_plus" class="forge" [@selected]="selected" [@fade]="forge_magic_plus">
            <div>
                <span [attr.class]="'stats_indicator '+forge_color">{{forge_color=='green'?'+':'-'}}{{forge_amount}}</span>
                <img class="stats_icons icon_attack" src="/assets/stats_icons/magic-attack.png">
            </div>
        </div>
        <div *ngIf="forge_ranged_minus" class="forge" [@selected]="selected" [@fade]="forge_ranged_minus">
            <div>
                <span [attr.class]="'stats_indicator '+forge_color">{{forge_color=='green'?'+':'-'}}{{forge_amount}}</span>
                <img class="stats_icons icon_attack" src="/assets/stats_icons/ranged-attack.png">
            </div>
        </div>
        <div *ngIf="forge_ranged_plus" class="forge" [@selected]="selected" [@fade]="forge_ranged_plus">
            <div>
                <span [attr.class]="'stats_indicator '+forge_color">{{forge_color=='green'?'+':'-'}}{{forge_amount}}</span>
                <img class="stats_icons icon_attack" src="/assets/stats_icons/ranged-attack.png">
            </div>
        </div>
        <div *ngIf="forge_armor_minus" class="forge" [@selected]="selected" [@fade]="forge_armor_minus">
            <div>
                <span [attr.class]="'stats_indicator '+forge_color">{{forge_color=='green'?'+':'-'}}{{forge_amount}}</span>
                <img class="stats_icons icon_attack" src="/assets/stats_icons/armor.png">
            </div>
        </div>
        <div *ngIf="forge_armor_plus" class="forge" [@selected]="selected" [@fade]="forge_armor_plus">
            <div>
                <span [attr.class]="'stats_indicator '+forge_color">{{forge_color=='green'?'+':'-'}}{{forge_amount}}</span>
                <img class="stats_icons icon_attack" src="/assets/stats_icons/armor.png">
            </div>
        </div>
        <div *ngIf="forge_health_minus" class="forge" [@selected]="selected" [@fade]="forge_health_minus">
            <div>
                <span [attr.class]="'stats_indicator '+forge_color">{{forge_color=='green'?'+':'-'}}{{forge_amount}}</span>
                <img class="stats_icons icon_health" src="/assets/stats_icons/health.png">
            </div>
        </div>
        <div *ngIf="forge_health_plus" class="forge" [@selected]="selected" [@fade]="forge_health_plus">
            <div>
                <span [attr.class]="'stats_indicator '+forge_color">{{forge_color=='green'?'+':'-'}}{{forge_amount}}</span>
                <img class="stats_icons icon_health" src="/assets/stats_icons/health.png">
            </div>
        </div>
        <div *ngIf="burst_no>0" [attr.class]="'burst cardburst_'+burst_no"></div>
        <div *ngFor="let m of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,31,32,33,34,35,36,37,38,39,40]">
            <div *ngIf="magic_hit[m]"  [attr.class]="(boss?'boss ':'')+'ball_'+(m<10?m:1)"></div>
            <div *ngIf="ranged_hit[m]" [attr.class]="(boss?'boss ':'')+'attack_ranged_'+1"></div>
            <div *ngIf="attack_hit[m]" [attr.class]="(boss?'boss ':'')+'attack_hit_'+(m<8?m:7)"></div>
        </div>
        <div [attr.class]="(boss?'boss ':'')+'blue_spark'"   *ngIf="blue_spark_down"></div>
        <div [attr.class]="(boss?'boss ':'')+'red_spark'"    *ngIf="red_spark_down"></div>
        <div class="died" *ngIf="dead"><div class="red_stripes_bg"></div></div>
    </div>
    <div *ngIf="back" [attr.class]="flip?'content flip':'content'">
        <div class="back card_hover">
            <img (load)="back_img_load()" src="https://splinterforge.s3.us-east-2.amazonaws.com/packs/Alpha+Card+Back.png" width="222" height="310" loading="lazy">
        </div>
    </div>
    <div>
        <div [attr.class]="(dead?'died ':'')+(small?'smaller_card ':'')+(hero?'hero_card ':(boss?'boss ':'slcard '))+(player?' hero':'')+(_loaded?'':' loading')+(grayed?' grayed':'')+((boss&&boss.starter)||(hero&&hero.starter)||(card&&card.starter)?' starter':'')"
             [style.filter]="'brightness('+brightness+')'" 
             [@selected]="selected"
             [@sepia_forge]="forging"
             [@sepia_forge_attack]="forging_attack_plus || forging_attack_minus"
             [@sepia_fire]="burn_down?'down':(burn?'true':'false')"
             [@attacking]="attacking"
             [@ranged]="range"
             [@attack]="attack"
             [@miss]="miss"
             [@hit]="hit"
             [@hit_magic]="hit_magic"
             [@fade]="dissolved||fade=='true'?'false':'true'"
             [@jump]="jump_down"
             [@hit_damage]="hit_damage"
             [@hit_damage_type]="hit_damage_type"
             [@shake]="shaken"
             [attr.name]="(attr_name?name:'')"
             [attr.id]="show_card_id?((card&&card.id?card.id:'')+(hero&&hero.id?hero.id:'')+(boss?'boss_card':'')):''">

            <div *ngIf="effects" [attr.class]="'ability_popover_background '+ability" ></div>
            <div *ngIf="effects" [attr.class]="'ability_popover '+(ability?'active':'')" >
                <img *ngIf="ability" src="https://d36mxiodymuqjm.cloudfront.net/website/abilities/ability_{{ability|url_encode}}.png" loading="lazy">
            </div>
            <div *ngIf="effects" [@quick_fade]="miss" class="miss_container">
                <div *ngIf="miss" class="miss">Miss!</div>
            </div>

            <!-- SplinterForge Boss Card -->
            <div *ngIf="boss && !hero && !card" class="bosswrapper">
                <div class="starter_ribbon" *ngIf="enable_starter_ribbon && boss.starter"></div>

                <img src="https://splinterforge.s3.us-east-2.amazonaws.com/frame_art/alpha_frame.png" class="frame" loading="lazy">
                <div class="imagewrapper">
                    <img src="/assets/boss/{{boss.name}}.png" loading="lazy">
                </div>
                <card-stats-icons
                    [show_mana]="false" 
                    [boss]="true" 
                    [stats]="boss.stats"
                    [force_show_stats]="force_show_stats"
                    [attack]="boss.stats.attack"
                    [ranged]="boss.stats.ranged"
                    [magic]="boss.stats.magic"
                    [og_health]="boss.ogStats.health"
                    [health]="boss.stats.health"
                    [og_armor]="boss.ogStats.armor"
                    [armor]="boss.stats.armor"
                    [showog]="true"
                    (effect)="trigger_effect($event)"
                    [speed]="boss.stats.speed"
                    [effects]="effects"
                    [ogstats]="boss.ogStats">
                </card-stats-icons>
                <div class="titlewrap"><h3>{{boss.name}}</h3></div>
                <div class="statsbars">
                    <card-bars
                        [armor]="boss.stats.armor"
                        [original_armor]="boss.ogStats.armor"
                        [health]="boss.stats.health"
                        [original_health]="boss.ogStats.health">
                    </card-bars>
                </div>
                <div class="boss_statswrap">
                    <card-abilities (hoverstate)="_h($event)" [abilities]="boss.stats.abilities"></card-abilities>
                </div>
            </div>
            <boss-max-levels *ngIf="boss&&maxLevels" [maxLevels]="maxLevels"></boss-max-levels>
            
            <!-- Hero Card -->
            <img *ngIf="hero" class="background_img" (load)="hro_ld()" [attr.src]="'/assets/frame_art/'+hero_card+'.png'">
            <div *ngIf="hero" class="image">
                <div class="picture">
                    <img *ngIf="currentClass" src="/assets/heroes/{{currentClass|lowercase}}_sketch.png" (load)="hro_ld()" loading="lazy" [attr.title]="currentClass">
                    <img *ngIf="!currentClass" [attr.src]="hero.imgURL" (load)="hro_ld()" loading="lazy">
                </div>
            </div>

            <div *ngIf="hero && !boss" class="itemdesc">
                <div class="starter_ribbon" *ngIf="enable_starter_ribbon && hero.starter"></div>

                <div [attr.class]="'title '+((hero&&hero.name?hero.name:user.username).length>10?'_sm':'')">
                    {{hero&&hero.name?hero.name:user.username}}
                </div>
                <div class="card_level">{{hero.level}}</div>
                <card-stats-icons
                    *ngIf="hero && hero.stats"
                    [mana]="0"
                    [show_mana]="false"
                    [smaller]="small"
                    [force_show_stats]="force_show_stats"
                    [attack]="hero.ogAttack ? hero.ogAttack : hero.stats.attack[hero.level -1]"
                    [ranged]="hero.ogRanged ? hero.ogRanged : hero.stats.ranged[hero.level -1]"
                    [magic]="hero.ogMagic ? hero.ogMagic : hero.stats.magic[hero.level -1]"
                    [health]="hero.ogHealth ? hero.ogHealth : hero.stats.health[hero.level -1]"
                    [armor]="hero.ogArmor ? hero.ogArmor : hero.stats.armor[hero.level -1]"
                    [speed]="hero.ogSpeed ? hero.ogSpeed : hero.stats.speed[hero.level -1]"
                    (effect)="trigger_effect($event)"
                    [og_health]="hero.ogMaxHealth"
                    [og_armor]="hero.ogMaxArmor"
                    [effects]="effects"
                    [hero]="true"
                    [show_health]="true"
                    [show_armor]="true">
                </card-stats-icons>
                <card-stats-icons
                    *ngIf="hero && hero.tierStats"
                    [mana]="0"
                    [show_mana]="false"
                    [smaller]="small"
                    [force_show_stats]="force_show_stats"
                    [attack]="hero.tierStats.attack"
                    [ranged]="hero.tierStats.ranged"
                    [magic]="hero.tierStats.magic"
                    [health]="hero.tierStats.health"
                    [armor]="hero.tierStats.armor"
                    [speed]="hero.tierStats.speed"
                    (effect)="trigger_effect($event)"
                    [og_health]="hero.ogMaxHealth"
                    [og_armor]="hero.ogMaxArmor"
                    [effects]="effects"
                    [hero]="true"
                    [show_health]="true"
                    [show_armor]="true">
                </card-stats-icons>
                <card-abilities showTitles
                    [hero]="true"
                    (hoverstate)="_h($event)"
                    [smaller]="small"
                    [abilities]="heroAbilities">
                </card-abilities>
                <div *ngIf="heroAbilities && heroAbilities.length > 0">
                    <div *ngFor="let ability of heroAbilities">
                        <h5 *ngIf="ability.bonus > 0">{{ability.type + ability.bonus}}</h5>
                    </div>
                </div>
            </div>

            <!-- Regular SplinterLands Card -->
            <div *ngIf="card && !hero && !boss">
                <div class="starter_ribbon" *ngIf="enable_starter_ribbon && card.starter"></div>
                <div class="cardimg hero">

                    <!-- <img (load)="img_load(1)" (error)="img_err(entity,1)" [id]="card.card_detail_id" class="card_hero_img" [attr.src]="card.cached_imgURL" /> -->

                    <!-- <div class="hit_damage_type" *ngIf="hit_damage_type">{{hit_damage_type}}</div> -->

                    <!-- <img *ngIf="card.cached_imgURL && !(img_errored==1) && img_loaded!=2" (load)="img_load(1)" (error)="img_err(entity,1)" [id]="card.card_detail_id" class="card_hero_img" [attr.src]="card.cached_imgURL" /> -->

                    <img *ngIf="card.imgURL && !(img_errored==2) && img_loaded!=1" (load)="img_load(2)" (error)="img_err(entity,2)" [id]="card.card_detail_id" class="card_hero_img" [attr.src]="card.imgURL" />

                </div>
                <div class="cardimg_bg"></div>

                <!-- <img *ngIf="card.ogHealth <= 0 && card.type === 'Monster'" class="cardimg death" src="/assets/cards/red-circle.png"> -->

                <div *ngIf="showTitle" class="title">{{card.name}}</div>
                <div *ngIf="showTitle" class="card_level">{{card.level}}</div>
                <card-stats-icons
                    *ngIf="showStats"
                    [smaller]="small"
                    [stats]="card.stats"
                    [force_show_stats]="force_show_stats"
                    [mana]="card.type === 'Summoner' ? card.stats.mana : card.stats.mana[0]"
                    [fade_stats]="FadeStats"
                    [attack]="card.ogAttack ? card.ogAttack : card.stats.attack[card.level -1]"
                    [ranged]="card.ogRanged ? card.ogRanged : card.stats.ranged[card.level -1]"
                    [magic]="card.ogMagic ? card.ogMagic : card.stats.magic[card.level -1]"
                    [health]="card.ogHealth ? card.ogHealth : card.stats.health[card.level-1]!=null ? card.stats.health[card.level-1] : card.health"
                    [armor]="card.ogArmor ? card.ogArmor : card.stats.armor[card.level-1]!=null ? card.stats.armor[card.level-1] : card.armor"
                    [speed]="card.ogSpeed ? card.ogSpeed : card.stats.speed[card.level -1]" 
                    (effect)="trigger_effect($event)"
                    [og_health]="card.ogMaxHealth"
                    [og_armor]="card.ogMaxArmor"
                    [effects]="effects"
                    [showog]="false"
                    [level]="card.level"
                    [show_health]="true"
                    [show_armor]="true">
                </card-stats-icons> 
                <div *ngIf="showAbilities && card.type !== 'Summoner'" 
                    [attr.class]="(card.name|agilities_place)?'statswrap alt':'statswrap'">
                    <card-abilities (hoverstate)="_h($event)" [smaller]="small" [abilities]="card.abilities">
                    </card-abilities>
                </div>
                <div *ngIf="showLeague" class="overlay">
                    <card-league-icon [type]="card.type"></card-league-icon>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="rules_box && (card&&card.effects||hero&&hero.effects||boss&&boss.effects)" [attr.class]="(dead?'died ':'')+' rules_box'">
        <div *ngIf="!boss && !hero && card" [attr.class]="!card.effects?'rules none':'rules'">
            <card-abilities 
                [buffed]="true"
                [only_effects]="true"
                [smaller]="true" 
                [abilities]="card.effects">
            </card-abilities>
        </div>
        <div *ngIf="!boss && hero && !card" [attr.class]="!hero.effects?'rules none':'rules'">
            <card-abilities 
                [buffed]="true"
                [only_effects]="true"
                [smaller]="true" 
                [abilities]="hero.effects">
            </card-abilities>
        </div>
        <div *ngIf="boss && !hero && !card" [attr.class]="!boss.effects?'rules none':'rules'">
            <card-abilities 
                [buffed]="true"
                [only_effects]="true"
                [smaller]="true" 
                [abilities]="boss.effects">
            </card-abilities>
        </div>
    </div>
    <div [attr.class]="_l?'selected bL':'bL'"></div>
</div>
<div *ngIf="_img">
    <!-- <img [attr.src]="card.cached_imgURL" /> -->
    <img [attr.src]="card.imgURL" />
    <!-- <card-abilities [just_image]="true" [abilities]="card.abilities"></card-abilities> -->
</div>