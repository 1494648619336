import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HelperService } from '../../_services/helper.service';
import { fade, flip_up, pointer, boxlarge, boxsmaller, field_error } from '../../app.animations';
@Component({
  selector: 'leaders',
  animations: [fade, flip_up, pointer, boxlarge, boxsmaller, field_error],
  templateUrl: './leaders.component.html',
  styleUrls: [ './leaders.component.less', '../survival.component.styl']
})
export class LeadersComponent implements OnInit {
  
  @Input('show') show = true;
  @Input('tier') tier = 1;
  @Input('user') user;
  @Output('done') done = new EventEmitter();
  leaderboards = { 1: [], 2: [], 3: [], 4: [] };
  player;
  endTime;
  xpPool;
  nextLB;
  lbExpired;
  
  constructor (public helper: HelperService) { }
  
  ngOnInit() {
    this.changeLB(this.tier);
  }

  async changeLB(t) {
    this.tier = t;
    if (this.leaderboards[t]) {
      this.leaderboards[t] = [];
      this.player = null;
      this.endTime = null;
      this.xpPool = null;
      this.nextLB = null;
      this.lbExpired = null;
      let temp: any = await this.helper.loadLeaderboard('tier ' + t + ' mine run leaderboard');
      if (temp && temp.leaderboard && temp.leaderboard.leaderboard.length > 0) {
          this.leaderboards[t] = temp.leaderboard.leaderboard;
          this.endTime = temp.leaderboard.ended;
          this.xpPool = temp.leaderboard.rewardPool;
          this.player = this.leaderboards[t].find(x => { return x.player === this.user });
          this.leaderboards[t].sort((a,b) => { return b.points - a.points; });
          let totalPoints = 0;
          this.leaderboards[t].some((x,i) => {
              if (i < 25) {
                  totalPoints += x.points;
              } else return;
          })
          this.leaderboards[t].some((x,i) => {
              if (i < 25) {
                  x.reward = Math.round((x.points/totalPoints) * this.xpPool);
                  x.ele = Math.round(x.reward*0.1);
              } else return;
          })
          // note that next leaderboard start with the first battle that comes in 23 hours after end time
          let et = new Date(this.endTime).getTime();
          if (et < Date.now()) {
            //this.endTime = null;
            this.nextLB = new Date(et+1000*60*60*23);
            this.lbExpired = Date.now() > this.nextLB.getTime();
          }
      }
    }
  }
  
  close() { 
    this.done.emit(0);
  }
}