import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.styl']
})
export class HeadingComponent implements OnInit {
  @Input('title') title = '';
  @Input('subtitle') subtitle = '';
  constructor() { }
  ngOnInit(): void {
  }
}
