import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common'; 
import { Boss } from '@app/_models';
import { DataService } from '@app/_services';
import { HelperService } from '@app/_services/helper.service';

@Component({ 
    selector:'leaderboard',
    templateUrl: 'leaderboard.component.html', 
    styleUrls: ['./leaderboard.component.styl'] 
})
export class LeaderboardComponent implements OnInit, OnChanges, OnDestroy {
    @Input('boss') boss;
    @Input('autorun') autorun;
    @Input('in_modal') in_modal = false;
    @Input('featured_player') feat_player = true;
    @Input('view') view = 'standings';
    @Input('player') filter_player = '';
    @Input('b') b = 0;
    @Input('limit') b_limit = 10;
    @Input('myFights') myFights;
    @Output('watch') watch = new EventEmitter();
    tier: number;
    lastReward: any;
    rewards: any;
    sort = new Sorter();
    showFilters = false;
    viewTeam = false;
    viewOld = false;
    loading = true;
    config: any;
    team;
    result;
    watchingFight = [];
    loaded = [];
    currentUser: any;
    totalReward = 0;
    perMinute = '';
    killingBlow: any;
    highestAvg: string = '';
    generatedWinner: string = '';
    calculatedWinner: string = '';
    _sum(b,i) {
        var c = Number(b+i+1);
        c=c.toString().length;
        return c;
    }
    constructor(
        private data: DataService, 
        private location: Location, 
        private helper: HelperService) { 
        this.config = this.helper.config;
    }
    ngOnChanges(item?) {
        //if(Object.keys(item)[0]==='boss'){
        //    this.selectBoss();
        //    this.b = 0;
        //}
    }
    ngOnInit() {
        this.data.onConfigChange().subscribe(c => this.config = c);
        this.sort.field='dmg';
        this.sort.direction='descending';
        if (this.boss) this.selectBoss();
        if (this.autorun) this.showTeam(this.boss.battles[0],true);
    }
    ngOnDestroy() {
        this.watchingFight = [];
    }
    predictWinner(type) {
        if (!this.currentUser) this.currentUser = this.helper.currentUser;
        let u = this.currentUser.username;
        if (type === 'last 100') {
            let totals = [];
            this.boss.battles.forEach(x => {
                let found = totals.find(t => { return t.player === x.player });
                if (!found) totals.push({ player: x.player, dmg: x.totalDmg });
                else found.dmg += x.totalDmg;
            })
            console.log(totals);
            if (totals.length > 0) {
                totals.sort((a,b) => { return b.dmg - a.dmg; });
                this.calculatedWinner = totals[0].player;
            }
        } else if (type === 'random') {
            let filtered = this.boss.standings.filter(x => { return x.fights > 69 });
            if (filtered && filtered.length > 0) {
                let rand = Math.floor(Math.random() * (filtered.length+1));
                //console.log(rand);
                this.generatedWinner = filtered[rand].player;
            } else this.generatedWinner = 'N/A';
        }
    }
    async watchReplay() {
        this.currentUser=this.helper.currentUser;
        if (this.currentUser.username !== this.result.player) return;
        let temp = await this.helper.loadFullBattle(this.result.id);
        this.location.replaceState("/slcards/"+this.result.id);
        this.result.actions = temp;
        this.data.setReplayBoss(this.boss);
        this.data.setResult(this.result);
        this.data.setScrolling([]);
        this.boss = null;
        this.watch.emit(0);
        this.closeTeamModal();
    }
    closeTeamModal() {
        this.viewTeam = false;
        this.team = null;
        this.result = null;
    }
    showTeam(result, watch = true, i = 0) {
        this.result = result;
        this.team = this.result.team;
        this.team.forEach(c=>{
            c.imgURL = this.helper.tempGetCardImgUrl(c);
            if (c.uid.indexOf('hero') > -1) {
                let heroClass = '';
                if (c.uid.indexOf('Warrior') > -1) heroClass = 'Warrior';
                if (c.uid.indexOf('Wizard') > -1) heroClass = 'Wizard';
                if (c.uid.indexOf('Ranger') > -1) heroClass = 'Ranger';
                c.imgURL = '/assets/heroes/' + heroClass.toLowerCase() + '_sketch.png';
            }
            if (c.abilities.length > 0) {
                let newAbilities = [];
                c.abilities.forEach(a => { if(!newAbilities.includes(a) && !a.match(/\d+/g)) newAbilities.push(a);  }); 
                c.abilities = newAbilities;
            }
        });
        if(watch){
            //return;
            this.watchingFight[i]=true
            this.watchReplay();
        } else {
            this.viewTeam = true;
        }
    }
    checkReward(type) {
        if (type === 'last') {
            let dmgs = [];
            this.boss.battles.forEach(b => {
                let found = dmgs.find(u => u.name === b.player);
                if (!found) dmgs.push({ name: b.player, dmg: b.totalDmg });
                else found.dmg += b.totalDmg;
            });
            dmgs.sort((a,b) => { return b.dmg - a.dmg; })
            console.log(dmgs);
            if (dmgs.length > 0) this.lastReward = dmgs[0].name + ' - ' + dmgs[0].dmg + ' damage in last 100 battles!';
            else this.lastReward = 'NA';
        }
    }
    lbbd = [
        { xp: 7000, electrum: 1750, frags: 25 },
        { xp: 6500, electrum: 1625, frags: 20 },
        { xp: 6000, electrum: 1500, frags: 18 },
        { xp: 5500, electrum: 1375, frags: 16 },
        { xp: 5000, electrum: 1250, frags: 14 },
        { xp: 4500, electrum: 1125, frags: 12 },
        { xp: 4000, electrum: 1000, frags: 10 },
        { xp: 3500, electrum: 875, frags: 9 },
        { xp: 3000, electrum: 750, frags: 8 },
        { xp: 2900, electrum: 725, frags: 7 },
        { xp: 2800, electrum: 700, frags: 6 },
        { xp: 2700, electrum: 675, frags: 5 },
        { xp: 2600, electrum: 650, frags: 4 },
        { xp: 2500, electrum: 625, frags: 3 },
        { xp: 2400, electrum: 600, frags: 2 },
        { xp: 2300, electrum: 575, frags: 1 },
        { xp: 2200, electrum: 550, frags: 1 },
        { xp: 2100, electrum: 525, frags: 1 },
        { xp: 2000, electrum: 500, frags: 1 },
        { xp: 1950, electrum: 488, frags: 1 },
        { xp: 1900, electrum: 475, frags: 1 },
        { xp: 1850, electrum: 463, frags: 1 },
        { xp: 1800, electrum: 450, frags: 1 },
        { xp: 1750, electrum: 438, frags: 1 },
        { xp: 1700, electrum: 425, frags: 1 },
        { xp: 1650, electrum: 413, frags: 0 },
        { xp: 1600, electrum: 400, frags: 0 },
        { xp: 1550, electrum: 388, frags: 0 },
        { xp: 1500, electrum: 375, frags: 0 },
        { xp: 1450, electrum: 363, frags: 0 },
        { xp: 1400, electrum: 350, frags: 0 },
        { xp: 1350, electrum: 338, frags: 0 },
        { xp: 1300, electrum: 325, frags: 0 },
        { xp: 1250, electrum: 313, frags: 0 },
        { xp: 1200, electrum: 300, frags: 0 },
        { xp: 1150, electrum: 288, frags: 0 },
        { xp: 1100, electrum: 275, frags: 0 },
        { xp: 1050, electrum: 263, frags: 0 },
        { xp: 1000, electrum: 250, frags: 0 },
        { xp: 950, electrum: 238, frags: 0 },
        { xp: 900, electrum: 225, frags: 0 },
        { xp: 850, electrum: 213, frags: 0 },
        { xp: 800, electrum: 200, frags: 0 },
        { xp: 750, electrum: 188, frags: 0 },
        { xp: 700, electrum: 175, frags: 0 },
        { xp: 650, electrum: 163, frags: 0 },
        { xp: 600, electrum: 150, frags: 0 },
        { xp: 550, electrum: 138, frags: 0 },
        { xp: 500, electrum: 125, frags: 0 },
        { xp: 450, electrum: 113, frags: 0 },
    ];
    user_battle = [];
    user_standings = [];
    async selectBoss() {
        this.currentUser=this.helper.currentUser;
        if (this.boss) {
            this.totalReward = 0;
            let t = this.boss.type;
            this.tier = parseInt(t.slice(1));
            //this.perMinute = t === 't4' ? '~16.7k' : t === 't3' ? '~11.8k' : t === 't2' ? '~8.8k' : t === 't1' ? '~5.9k' : '';
            let diff = (Date.now() - new Date(this.boss.spawned).getTime()) / 60000;
            this.killingBlow = 'Killing blow reward for this boss is ' + t.slice(1) + ' CRATE(s)'
            if (diff/60 >= 1) {
                this.killingBlow += ' and ' + (100*Math.floor(diff/60)) + ' FORGE!';
            }
            //console.log(diff);
            let temp = await this.helper.loadLeaderboard(this.boss.id);
            if (temp.message === 'success') {
                /*temp.leaderboard.leaderboard.sort((a,b) => (a.dmg > b.dmg) ? 1 : -1);
                for (let i = 0; i < lbbd.length; i++) {
                    if (this.tier === 4 && i < 25 && temp.leaderboard.leaderboard[i]) {
                        let rew = { xp: Math.round(lbbd[i].xp*4.5), electrum: Math.round(lbbd[i].electrum*4.5), frags: Math.round(lbbd[i].frags*4.5) };
                        temp.leaderboard.leaderboard.newReward = rew;
                    } else if (this.tier === 3 && i < 30 && temp.leaderboard.leaderboard[i]) {
                        let rew = { xp: Math.round(lbbd[i].xp*3), electrum: Math.round(lbbd[i].electrum*3), frags: Math.round(lbbd[i].frags*3) };
                        temp.leaderboard.leaderboard.newReward = rew;
                    } else if (this.tier === 2 && i < 40 && temp.leaderboard.leaderboard[i]) {
                        let rew = { xp: Math.round(lbbd[i].xp*1.5), electrum: Math.round(lbbd[i].electrum*1.5), frags: Math.round(lbbd[i].frags*1.5) };
                        temp.leaderboard.leaderboard.newReward = rew;
                    } else if (this.tier === 1 && temp.leaderboard.leaderboard[i]) {
                        let rew = { xp: Math.round(lbbd[i].xp*3), electrum: Math.round(lbbd[i].electrum*3), frags: Math.round(lbbd[i].frags*3) };
                        temp.leaderboard.leaderboard.newReward = rew;
                    } else if (!temp.leaderboard.leaderboard[i]) i = lbbd.length;
                }*/
                this.boss.standings = temp.leaderboard.leaderboard;
                this.boss.battles = temp.leaderboard.recentBattles;
                this.rewards = temp.leaderboard.rewards;
                if (!this.rewards || this.rewards.length < 1) this.rewards = temp.rewards;
            }
            for (let i = 0; i < this.boss.standings.length; i++) {
                if (i === 0) this.totalReward = 0;
                this.totalReward += this.boss.standings[i].reward;
            }
            if(this.filter_player=='') this.filter_player = this.currentUser.username;
            if(this.filter_player!='' && this.feat_player){
               var i = 0;
                for( let b of this.boss.standings ) {
                    i++;
                    if (b.player.toLowerCase() == this.filter_player.toLowerCase()){ 
                        b['place']=i;
                        this.user_standings.push(b); 
                    }
                }
                i = 0;
                for( let b of this.boss.battles ) {
                    i++;
                    if (b.player.toLowerCase() == this.filter_player.toLowerCase()){ 
                        b['place']=i;
                        this.user_battle.push(b); 
                    }
                } 
            }
            this.loading = false;
            this.stagger_openings();
        }
    }
    next_b_page() { 
        let currentLength = this.view === 'standings' ? this.boss.standings.length : this.boss.battles.length;
        this.b=(this.b+this.b_limit<currentLength)?this.b+this.b_limit:this.b;
    }
    prev_b_page() {
        this.b=(this.b>0)?this.b-this.b_limit:this.b; 
    }

    stagger_openings(i=0) {
        setTimeout(()=>{
            this.loaded[i]=true;
            if(i<this.b_limit) this.stagger_openings(i+1);
        },40);
    }
}
export class Sorter {
   constructor(
       public name?: string,
       public field?: string,
       public direction?: string,
   ) {}
} 