import { Component, AfterViewInit, OnInit, OnDestroy, EventEmitter, Output, ElementRef, Directive, Input, ViewChild,OnChanges,SimpleChanges} from '@angular/core';
import { DataService } from '@app/_services/data.service';
import { HelperService } from '@app/_services/helper.service';
import { User } from '../_models/index';
import { selected,attacking,attack,blend,jump,shake,ranged,miss,died,hit,hit_magic,hit_damage,hit_damage_type,fade,quick_fade,slow_fade,quicker_fade,sepia_fire,sepia_forge,sepia_forge_attack,visibility,popin_fx } from './slcard.animations';
import { display_animation,display_block } from '../app.animations';
@Component({
    selector: 'splinterforge-card',
    templateUrl: 'slcard.component.html',
    animations: [ display_animation,blend,display_block,fade,quick_fade,slow_fade,quicker_fade,sepia_fire,sepia_forge,sepia_forge_attack,shake,ranged,selected,attacking,attack,jump,miss,died,hit,hit_magic,hit_damage,popin_fx,hit_damage_type,fade,visibility ],
    styleUrls: [ '../slcard/slcard.component.styl', 'slcard.component.styl' ]
})
export class SLCardComponent implements AfterViewInit, OnDestroy, OnInit, OnChanges {
    constructor(
        private helper: HelperService, 
        private data: DataService
    ) {}
    config: any;
    dissolve: any;
    burst: any;
    spark: any;
    blue_spark: any;
    blue_crash: any;
    FadeStats = true;
    timeout1: any;
    timeout2: any;
    ability_img: any;
    img_error = false;
    _loaded = false;
    _l = false;
    back = false;
    card_id = 0;
    name = '';
    forge_color = '';
    forge_amount = 0;
    @Input('card') card: any;
    @Input('hero') hero: any;
    @Input('boss') boss: any;
    @Input('ability') ability=null;
    @Input('hero_health') hero_health: any;
    @Input('hero_armor') hero_armor: any;
    @Input('hero_speed') hero_speed: any;
    @Input('hero_attack') hero_attack: any;
    @Input('hero_magic') hero_magic: any;
    @Input('hero_ranged') hero_ranged: any;
    @Input('hero_card') hero_card = 'chaos-fire';
    @Input('flip') flip = false;
    @Input('attack') attack = false;
    @Input('attack_hit') attack_hit = [];
    @Input('ranged_hit') ranged_hit = [];
    @Input('miss_hit') miss_hit = [];
    @Input('magic_hit') magic_hit = [];
    @Input('larger') larger = false;
    @Input('center') center = false;
    @Input('maxLevels') maxLevels: any;
    @Input('attr_name') attr_name = true;
    @Input('showTitle') showTitle = true;
    @Input('showStats') showStats = true;
    @Input('showLeague') showLeague = true;
    @Input('showAbilities') showAbilities = true;
    @Input('force_show_stats') force_show_stats = false;
    @Input('just_img') _img = false;
    @Input('effects') effects = false;
    @Input('smaller') small = true;
    @Input('player') player = true;
    @Input('user') user: any;
    @Input('summoner') summoner = false;
    @Input('grayed') grayed = false;
    @Input('show_card_id') show_card_id = true;
    @Input('dead') dead = false;
    @Input('shadow') shadow = false;
    @Input('rules_box') rules_box = true;
    @Input('preamble') preamble = false;
    @Input('card_glow') card_glow = false;
    @Input('starter') enable_starter_ribbon = true;
    @Input('hit_damage') hit_damage = 0;
    @Input('burst') burst_no = 0;
    @Input('currentClass') currentClass: string;
    @Input('hit_damage_type') hit_damage_type: any;
    @Output('loaded') loaded = new EventEmitter();
    @Output('image_loaded') image_loaded = new EventEmitter();
    @Output('animation_done') animation_done = new EventEmitter();
    @Input('card_style_top') card_style_top = '0';
    @Input('card_style_left') card_style_left = '0';
    @Input('card_style_float') card_style_float = false;
    @Output('hoverstate') hoverstate = new EventEmitter();
    blend_mode = 'normal';
    range = 'false';
    forge_speed_1 = 1450;
    @Input('forge_speed') forge_speed_2 = 1500;
    @Input('fx_splash_dmg') set fx_splash_dmg(amount: number) {
        if(amount) {
            if(this.hit_damage_type=='attack')this.attack_hit[amount]=true;
            if(this.hit_damage_type=='ranged')this.ranged_hit[amount]=true;
            if(this.hit_damage_type=='magic')this.magic_hit[amount]=true;
            if(this.hit_damage_type=='miss')this.miss_hit[amount]=true;
        }
        // setTimeout(()=>{this.attack_hit[amount]=false;},1000);
    }
    @Input('fx_ability') set fx_ability(now: any) {
        this.ability = now;
    }
    @Input('fx_ranged') set fx_ranged(now: boolean) {
        if(now) {
            this._l = true;
            this.range = '1';
            setTimeout(()=>{this.range='2';},290);
            setTimeout(()=>{this.range='3';},700);
            setTimeout(()=>{this.range='4';},990);
            setTimeout(()=>{
                this._l = false;
                this.range='false';
                this.animation_done.emit(1);
            },1430);
        } else {
            this.range = 'false';
            this.animation_done.emit(1);
        }
    }
    fire_attack = '0';
    @Input('fx_fire_attack') set fx_fire_attack(now: boolean) {
        if(now) {
            this._l = true;
            this.fire_attack = '1';
            setTimeout(()=>{
                this.fire_attack = '0';
                this.animation_done.emit(1);
            },1700);
        } else {
            this.fire_attack = '0';
            this.animation_done.emit(1);
        }
    }
    flame_attack = false;
    @Input('fx_flame_attack') set fx_flame_attack(now: boolean) {
        if(now) {
            this._l = true;
            this.flame_attack = true;
            setTimeout(()=>{
                this.flame_attack = false;
                this.animation_done.emit(1);
            },400);
        } else {
            this.flame_attack = false;
            this.animation_done.emit(1);
        }
    }
    burn = false;
    @Input('fx_burn') set fx_burn(now: boolean) {
        if(now) {
            this.blend_mode = 'plus-lighter';
            this.burn = true;
            // setTimeout(()=>{
            //     this.burn = false;
            //     this.animation_done.emit(1);
            // },1700);
        } else {
            this.blend_mode = 'normal';
            this.burn = false;
            this.animation_done.emit(1);
        }
    }
    burn_down = false;
    @Input('fx_burn_down') set fx_burn_down(now: boolean) {
        if(now) {
            this.blend_mode = 'plus-lighter';
            this.burn = true;
            setTimeout(()=>{
                this.burn_down = true;
                setTimeout(()=>{
                    this.burn = false;
                    this.blend_mode = 'normal';
                    this.animation_done.emit(1);
                },2220);
            },1700);
        } else {
            this.burn = false;
            this.burn_down = false;
            this.blend_mode = 'normal';
            this.animation_done.emit(1);
        }
    }
    smoke = false;
    @Input('fx_smoke') set fx_smoke(now: boolean) {
        if(now) {
            this._l = true;
            this.smoke = true;
            setTimeout(()=>{
                this._l = false;
            },1250);
            setTimeout(()=>{
                this.smoke = false;
                this.animation_done.emit(1);
            },1700);
        } else {
            this.smoke = false;
            this.animation_done.emit(1);
        }
    }
    forge = false;
    forging = false;
    @Input('fx_forge') set fx_forge(now: boolean) {
        if(now) {
            this._l = true;
            this.forge = true;
            this.forging = true;
            this.selected = true;
            setTimeout(()=>{
                this.forging=false;
            },this.forge_speed_1);
            setTimeout(()=>{
                this.selected = false;
                this.forge = false;
                this._l = false;
                this.animation_done.emit(1);
            },this.forge_speed_2);
        } else {
            this.forging=false;
            this.selected = false;
            this.forge = false;
            this._l = false;
            this.animation_done.emit(1);
        }
    }
    forge_attack_plus = false;
    forging_attack_plus = false;
    @Input('fx_forge_attack_plus') fx_forge_attack_plus(now: boolean) {
        if(now) {
            this.fx_forge_attack_plus_();
        } else {
            this.forging_attack_plus=false;
            this.selected = false;
            this.forge_attack_plus = false;
            this._l = false;
            this.animation_done.emit(1);
        }
    }
    fx_forge_attack_plus_(change?,color?) {
        this.forge_color = color;
        this.forge_amount = change;
        this._l = true;
        this.forge_attack_plus = true;
        this.forging_attack_plus = true;
        setTimeout(()=>{
            this.forging_attack_plus=false;
        },this.forge_speed_1);
        setTimeout(()=>{
            this.forge_attack_plus = false;
            this._l = false;
            this.animation_done.emit(1);
        },this.forge_speed_2);
    }
    forge_attack_minus = false;
    forging_attack_minus = false;
    @Input('fx_forge_attack_minus') fx_forge_attack_minus(now: boolean) {
        if(now) {
            this.fx_forge_attack_minus_();
        } else {
            this.forging_attack_minus=false;
            this.selected = false;
            this.forge_attack_minus = false;
            this._l = false;
            this.animation_done.emit(1);
        }
    }
    fx_forge_attack_minus_(change?,color?) {
        this.forge_color = color;
        this.forge_amount = change;
        this._l = true;
        this.forge_attack_minus = true;
        this.forging_attack_minus = true;
        setTimeout(()=>{
            this.forging_attack_minus=false;
        },this.forge_speed_1);
        setTimeout(()=>{
            this.forge_attack_minus = false;
            this._l = false;
            this.animation_done.emit(1);
        },this.forge_speed_2);
    }
    forge_health_minus = false;
    forging_health_minus = false;
    @Input('fx_forge_health_minus') fx_forge_health_minus(now: boolean) {
        if(now) {
            this.fx_forge_health_minus_();
        } else {
            this.forging_health_minus=false;
            this.selected = false;
            this.forge_health_minus = false;
            this._l = false;
            this.animation_done.emit(1);
        }
    }
    fx_forge_health_minus_(change?,color?) {
        this.forge_color = color;
        this.forge_amount = change;
        this._l = true;
        this.forge_health_minus = true;
        this.forging_health_minus = true;
        setTimeout(()=>{
            this.forging_health_minus=false;
        },this.forge_speed_1);
        setTimeout(()=>{
            this.forge_health_minus = false;
            this._l = false;
            this.animation_done.emit(1);
        },this.forge_speed_2);
    }
    forge_health_plus = false;
    forging_health_plus = false;
    @Input('fx_forge_health_plus') fx_forge_health_plus(now: boolean) {
        if(now) {
            this.fx_forge_health_plus_();
        } else {
            this.forging_health_plus=false;
            this.selected = false;
            this.forge_health_plus = false;
            this._l = false;
            this.animation_done.emit(1);
        }
    }
    fx_forge_health_plus_(change?,color?) {
        this.forge_color = color;
        this.forge_amount = change;
        this._l = true;
        this.forge_health_plus = true;
        setTimeout(()=>{
            this.forge_health_plus = false;
            this._l = false;
            this.animation_done.emit(1);
        },this.forge_speed_2);
    }
    forge_armor_plus = false;
    forging_armor_plus = false;
    @Input('fx_forge_armor_plus') fx_forge_armor_plus(now: boolean) {
        if(now) {
            this.fx_forge_armor_plus_();
        } else {
            this.forging_armor_plus=false;
            this.selected = false;
            this.forge_armor_plus = false;
            this._l = false;
            this.animation_done.emit(1);
        }
    }
    fx_forge_armor_plus_(change?,color?) {
        this.forge_color = color;
        this.forge_amount = change;
        this._l = true;
        this.forge_armor_plus = true;
        this.forging_armor_plus = true;
        setTimeout(()=>{
            this.forging_armor_plus=false;
        },this.forge_speed_1);
        setTimeout(()=>{
            this.forge_armor_plus = false;
            this._l = false;
            this.animation_done.emit(1);
        },this.forge_speed_2);
    }
    forge_armor_minus = false;
    forging_armor_minus = false;
    @Input('fx_forge_armor_minus') fx_forge_armor_minus(now: boolean) {
        if(now) {
            this.fx_forge_armor_minus_();
        } else {
            this.forging_armor_minus=false;
            this.selected = false;
            this.forge_armor_minus = false;
            this._l = false;
            this.animation_done.emit(1);
        }
    }
    fx_forge_armor_minus_(change?,color?) {
        this.forge_color = color;
        this.forge_amount = change;
        this._l = true;
        this.forge_armor_minus = true;
        this.forging_armor_minus = true;
        setTimeout(()=>{
            this.forging_armor_minus=false;
        },this.forge_speed_1);
        setTimeout(()=>{
            this.forge_armor_minus = false;
            this._l = false;
            this.animation_done.emit(1);
        },this.forge_speed_2);
    }
    forge_ranged_plus = false;
    forging_ranged_plus = false;
    @Input('fx_forge_ranged_plus') fx_forge_ranged_plus(now: boolean) {
        if(now) {
            this.fx_forge_ranged_plus_();
        } else {
            this.forging_ranged_plus=false;
            this.selected = false;
            this.forge_ranged_plus = false;
            this._l = false;
            this.animation_done.emit(1);
        }
    }
    fx_forge_ranged_plus_(change?,color?) {
        this.forge_color = color;
        this.forge_amount = change;
        this._l = true;
        this.forge_ranged_plus = true;
        this.forging_ranged_plus = true;
        setTimeout(()=>{
            this.forging_ranged_plus=false;
        },this.forge_speed_1);
        setTimeout(()=>{
            this.forge_ranged_plus = false;
            this._l = false;
            this.animation_done.emit(1);
        },this.forge_speed_2);
    }
    forge_ranged_minus = false;
    forging_ranged_minus = false;
    @Input('fx_forge_ranged_minus') fx_forge_ranged_minus(now: boolean) {
        if(now) {
            this.fx_forge_ranged_minus_();
        } else {
            this.forging_ranged_minus=false;
            this.selected = false;
            this.forge_ranged_minus = false;
            this._l = false;
            this.animation_done.emit(1);
        }
    }
    fx_forge_ranged_minus_(change?,color?) {
        this.forge_color = color;
        this.forge_amount = change;
        this._l = true;
        this.forge_ranged_minus = true;
        this.forging_ranged_minus = true;
        setTimeout(()=>{
            this.forging_ranged_minus=false;
        },this.forge_speed_1);
        setTimeout(()=>{
            this.forge_ranged_minus = false;
            this._l = false;
            this.animation_done.emit(1);
        },this.forge_speed_2);
    }
    forge_magic_plus = false;
    forging_magic_plus = false;
    @Input('fx_forge_magic_plus') fx_forge_magic_plus(now: boolean) {
        if(now) {
            this.fx_forge_magic_plus_();
        } else {
            this.forging_magic_plus=false;
            this.selected = false;
            this.forge_magic_plus = false;
            this._l = false;
            this.animation_done.emit(1);
        }
    }
    fx_forge_magic_plus_(change?,color?) {
        this.forge_color = color;
        this.forge_amount = change;
        this._l = true;
        this.smoke = true;
        setTimeout(()=>{
            this._l = false;
        },1250);
        setTimeout(()=>{
            this.smoke = false;
            this.animation_done.emit(1);
        },1700);
    }
    forge_magic_minus = false;
    forging_magic_minus = false;
    @Input('fx_forge_magic_minus') fx_forge_magic_minus(now: boolean) {
        if(now) {
            this.fx_forge_magic_minus_();
        } else {
            this.forging_magic_minus=false;
            this.selected = false;
            this.forge_magic_minus = false;
            this._l = false;
            this.animation_done.emit(1);
        }
    }
    fx_forge_magic_minus_(change?,color?) {
        this.forge_color = color;
        this.forge_amount = change;
        this._l = true;
        this.forge_magic_minus = true;
        this.forging_magic_minus = true;
        setTimeout(()=>{
            this.forging_magic_minus=false;
        },this.forge_speed_1);
        setTimeout(()=>{
            this.forge_magic_minus = false;
            this._l = false;
            this.animation_done.emit(1);
        },this.forge_speed_2);
    }
    forge_speed_plus = false;
    forging_speed_plus = false;
    @Input('fx_forge_speed_plus') fx_forge_speed_plus(now: boolean) {
        if(now) {
            this.fx_forge_speed_plus_();
        } else {
            this.forging_speed_plus=false;
            this.selected = false;
            this.forge_speed_plus = false;
            this._l = false;
            this.animation_done.emit(1);
        }
    }
    fx_forge_speed_plus_(change?,color?) {
        this.forge_color = color;
        this.forge_amount = change;
        this._l = true;
        this.forge_speed_plus = true;
        this.forging_speed_plus = true;
        setTimeout(()=>{
            this.forging_speed_plus=false;
        },this.forge_speed_1);
        setTimeout(()=>{
            this.forge_speed_plus = false;
            this._l = false;
            this.animation_done.emit(1);
        },this.forge_speed_2);
    }
    forge_speed_minus = false;
    forging_speed_minus = false;
    @Input('fx_forge_speed_minus') fx_forge_speed_minus(now: boolean) {
        if(now) {
            this.fx_forge_speed_minus_();
        } else {
            this.forging_speed_minus=false;
            this.selected = false;
            this.forge_speed_minus = false;
            this._l = false;
            this.animation_done.emit(1);
        }
    }
    fx_forge_speed_minus_(change?,color?) {
        this.forge_color = color;
        this.forge_amount = change;
        this._l = true;
        this.forge_speed_minus = true;
        this.forging_speed_minus = true;
        setTimeout(()=>{
            this.forging_speed_minus=false;
        },this.forge_speed_1);
        setTimeout(()=>{
            this.forge_speed_minus = false;
            this._l = false;
            this.animation_done.emit(1);
        },this.forge_speed_2);
    }
    blinking: any;
    brightness='1';
    @Input('fx_blinking') set fx_blinking(now: boolean) {
        if(now) {
            this.brightness='1.2';
            setTimeout(()=>{
                this.brightness='0.9';
                setTimeout(()=>{
                    this.brightness='1.3';
                    setTimeout(()=>{
                        this.brightness='0.9';
                        setTimeout(()=>{
                            this.brightness='1.2';
                            setTimeout(()=>{
                                this.brightness='0.9';
                                setTimeout(()=>{
                                    this.brightness='1.17';
                                    setTimeout(()=>{
                                        this.brightness='1';
                                    },100);
                                },100);
                            },100);
                        },100);
                    },100);
                },100);
            },100);
        } else {
            this.blinking = 'off';
            this.animation_done.emit(1);
        }
    }
    attacking: any;
    @Input('fx_attacking') set fx_attacking(now: boolean) {
        if(now) {
            this._l = true;
            this.attacking = '1';
            setTimeout(()=>{this.attacking='2';},300);
            setTimeout(()=>{this.attacking='3';},900);
            setTimeout(()=>{
                this._l = false;
                this.attacking='off';
                this.animation_done.emit(1);
            },1730);
        } else {
            this.attacking = 'off';
            this.animation_done.emit(1);
        }
    }
    hit: any;
    @Input('fx_hit') set fx_hit(now: boolean) {
        if(now) {
            this.hit = 'true';
            this._l = true;
            setTimeout(()=>{
                this._l = false;
                this.hit='off';
                this.animation_done.emit(1);
            },670);
        } else {
            this._l = false;
            this.hit = 'off';
            this.animation_done.emit(1);
        }
    } 
    hit_magic: any;
    @Input('fx_hit_magic') set fx_hit_magic(now: boolean) {
        if(now) {
            this.hit_magic = 'true';
            this._l = true;
            setTimeout(()=>{
                this._l = false;
                this.hit_magic='off';
                this.animation_done.emit(1);
            },670);
        } else {
            this._l = false;
            this.hit_magic = 'off';
            this.animation_done.emit(1);
        }
    }
    @Input('fx_hit_down') set fx_hit_down(now: boolean) {
        if(now) {
            this.hit = 'true';
            this._l = true;
            setTimeout(()=>{
                this._l = false;
                // this.hit='false';
                this.dead = true;
                this.animation_done.emit(1);
            },580);
        } else {
            this._l = false;
            this.hit = 'off';
            this.dead = false;
            this.animation_done.emit(1);
        }
    }
    jump_down: any;
    @Input('fx_jump_down') set fx_jump_down(now: boolean) {
        if(now) {
            this.jump_down = 'true';
            this._l = true;
            setTimeout(()=>{
                this._l = false;
                this.jump_down='off';
                this.dead = true;
                this.animation_done.emit(1);
            },900);
        } else {
            this._l = false;
            this.jump_down = 'off';
            this.dead = false;
            this.animation_done.emit(1);
        }
    }
    miss: any;
    @Input('fx_miss') set fx_miss(now: boolean) {
        if(now) {
            this.miss = 'true';
            setTimeout(()=>{
                this.miss='off';
                this.animation_done.emit(1);
            },400);
        } else {
            this.miss = 'off';
            this.animation_done.emit(1);
        }
    }
    selected: any;
    @Input('fx_selected') set fx_selected(now: boolean) {
        if(now) {
            this._l = true;
            this.selected = true;
            setTimeout(()=>{
                this._l=false;
                this.selected=false;
                this.animation_done.emit(1);
            },450);
        } else {
            this._l = false;
            this.selected = false;
            this.animation_done.emit(1);
        }
    }
    shaken = '0';
    @Input('fx_shaken') set fx_shaken(now: boolean) {
        if(now) {
            this.shaken = '1';
            setTimeout(()=>{this.shaken='2';},60);
            setTimeout(()=>{this.shaken='3';},120);
            setTimeout(()=>{this.shaken='4';},200);
            setTimeout(()=>{
                this.shaken='off';
                this.animation_done.emit(1);
            },280);
        } else {
            this.shaken = 'off';
            this.animation_done.emit(1);
        }
    }
    fade = 'false';
    @Input('fx_fade') set fx_fade(now: boolean) {
        if(now) {
            this.fade = 'true';
            setTimeout(()=>{
                this.animation_done.emit(1);
            },280);
        } else {
            this.fade = 'false';
            this.animation_done.emit(1);
        }
    }
    dissolved = false;
    @Input('fx_dissolve') set fx_dissolve(now: boolean) {
        if(now) {
            this.dissolv();
        } else {
            if(this.dissolve)this.dissolve.pause();
        }
    }
    blue_spark_down = false;
    @Input('fx_blue_spark') set fx_blue_spark(now: boolean) {
        if(now) {
            this.blue_spark_down = true;
        } else {
            this.blue_spark_down = false;
        }
    }
    red_spark_down = false;
    @Input('fx_red_spark') set fx_red_spark(now: boolean) {
        if(now) {
            this.red_spark_down = true;
        } else {
            this.red_spark_down = false;
        }
    }
    dissolve_ = false;
    dissolv() {
        if(this.effects) {
            this.dissolve_ = true;
            this.blend_mode = 'multiply'
            clearTimeout(this.timeout1);
            clearTimeout(this.timeout2);
            if(this.dissolve)this.dissolve.currentTime = 0.6;
            if(this.dissolve)this.dissolve.play();
            this.timeout1 = setTimeout(()=>{
                this.FadeStats = false;
            }, 1700);
            this.timeout2 = setTimeout(()=>{
                this.dissolved = true;
                this.blend_mode = 'normal';
                if(this.dissolve)this.dissolve.pause();
                this.reset_effects();
            }, 5000);
        }
    }
    burst_ = false;
    @Input('fx_spart_burst') set fx_spart_burst(now: boolean) {
        if(now) {
            this.burst_a_spark()
        } else {
            if(this.burst)this.burst.pause();
        }
    }
    bursting_ = false;
    burst_a_spark() {
        if(this.effects) {
            this.bursting_ = true;
            clearTimeout(this.timeout2);
            if(this.burst)this.burst.currentTime = 0;
            if(this.burst)this.burst.play();
            this.timeout2 = setTimeout(()=>{
                this.burst_ = true;
                if(this.burst)this.burst.pause();
                this.reset_effects();
            }, 5000);
        }
    }
    spark_ = false;
    @Input('fx_spark_across') set fx_spark_across(now: boolean) {
        if(now) {
            this.spark_across();
        } else {
            if(this.spark)this.spark.pause();
        }
    }
    sparking_ = false;
    spark_across() {
        if(this.effects) {
            this.sparking_ = true;
            clearTimeout(this.timeout2);
            if(this.spark)this.spark.currentTime = 0;
            if(this.spark)this.spark.play();
            this.timeout2 = setTimeout(()=>{
                this.spark_ = true;
                if(this.spark)this.spark.pause();
                this.reset_effects();
            }, 5000);
        }
    }
    blue_spark_ = false;
    @Input('fx_blue_spark_across') set fx_blue_spark_across(now: boolean) {
        if(now) {
            this.blue_spark_across();
        } else {
            if(this.blue_spark)this.blue_spark.pause();
        }
    }
    blue_sparking_ = false;
    blue_spark_across() {
        if(this.effects) {
            this.blue_sparking_ = true;
            clearTimeout(this.timeout2);
            if(this.blue_spark)this.blue_spark.currentTime = 0;
            if(this.blue_spark)this.blue_spark.play();
            this.timeout2 = setTimeout(()=>{
                this.blue_spark_ = true;
                if(this.blue_spark)this.blue_spark.pause();
                this.reset_effects();
            }, 5000);
        }
    }
    blue_crash_ = false;
    @Input('fx_blue_crash_across') set fx_blue_crash_across(now: boolean) {
        if(now) {
            this.blue_crash_across();
        } else {
            if(this.blue_crash)this.blue_crash.pause();
        }
    }
    blue_crashing_ = false;
    blue_crash_across() {
        if(this.effects) {
            this.blue_crashing_ = true;
            clearTimeout(this.timeout2);
            if(this.blue_crash)this.blue_crash.currentTime=0;
            if(this.blue_crash)this.blue_crash.play();
            this.timeout2 = setTimeout(()=>{
                if(this.blue_crash)this.blue_crash_ = true;
                if(this.blue_crash)this.blue_crash.pause();
                this.reset_effects();
            }, 5000);
        }
    }
    trigger_effect(eff, p=false, i=0) {
        if(this.preamble) {
            if(eff[0]=='forge_attack_plus')this.fx_forge_attack_plus_(eff[1],eff[2]);
            if(eff[0]=='forge_attack_minus')this.fx_forge_attack_minus_(eff[1],eff[2]);
            if(eff[0]=='forge_magic_plus')this.fx_forge_magic_plus_(eff[1],eff[2]);
            if(eff[0]=='forge_magic_minus')this.fx_forge_magic_minus_(eff[1],eff[2]);
            if(eff[0]=='forge_ranged_plus')this.fx_forge_ranged_plus_(eff[1],eff[2]);
            if(eff[0]=='forge_ranged_minus')this.fx_forge_ranged_minus_(eff[1],eff[2]);
            if(eff[0]=='forge_speed_plus')this.fx_forge_speed_plus_(eff[1],eff[2]);
            if(eff[0]=='forge_speed_minus')this.fx_forge_speed_minus_(eff[1],eff[2]);
            if(eff[0]=='forge_health_plus')this.fx_forge_health_plus_(eff[1],eff[2]);
            if(eff[0]=='forge_health_minus')this.fx_forge_health_minus_(eff[1],eff[2]);
            if(eff[0]=='forge_armor_plus')this.fx_forge_armor_plus_(eff[1],eff[2]);
            if(eff[0]=='forge_armor_minus')this.fx_forge_armor_minus_(eff[1],eff[2]);
        }
    }
    reset_effects() {
        this.animation_done.emit(1);
        this.dissolve_ = false;
        this.bursting_ = false;
        this.sparking_ = false;
        this.blue_sparking_ = false;
        this.blue_crashing_ = false;
        this.dissolved = false;
        this.burn_down = false;
        this.burn = false;
    }
    hro_ld() {
        this.loaded.emit(1);
    }
    back_img_load() {
        if(this.flip) {
            this._loaded=true;
            this.image_loaded.emit(1);
        }
    }
    img_errored = 0;
    img_loaded = 0;
    img_load(img?) {
        if(this.img_loaded==0){
            this._loaded=true;
            this.img_loaded=img;
            this.image_loaded.emit(1);
        }
    }
    img_err(e?,n?) {
        // this.card.imgURL = this.card.original_imgURL;
        this.img_errored = n;
        this.img_error = true;
        // var split = this.card.imgURL.split('/');
        // var split = split[split.length-1].split('.');
        // if(n==1) {
        //     console.log('Not found in cache ',this.card.imgURL)
        //     this.helper.cacheImage(this.card.imgURL,(card,resp)=>{
        //         this.card['cached_imgURL'] = this.helper.altUrl+'image_server/'+split[0]+'.png';        
        //     });
        // }
    }
    _h(e){ this.hoverstate.emit(e); }
    ngAfterViewInit() {
        this.card_id = Math.floor(Math.random()*1000);
        this.data.onConfigChange().subscribe(c => this.config = c);
        this.dissolve = document.getElementById('dissolve_'+this.card_id);
        this.burst = document.getElementById('burst_'+this.card_id);
        this.spark = document.getElementById('spark_'+this.card_id);
        this.blue_spark = document.getElementById('blue_spark_'+this.card_id);
        this.blue_crash = document.getElementById('blue_crash_'+this.card_id);
        if(!this.forge_speed_1)this.forge_speed_1=this.forge_speed_2-80;
        if(this.boss) {
            this.name = this.boss.name;
        } else if (this.hero) {
            this.name = this.hero.name?this.hero.name:this.user.username;
        } else if (this.card) {
            var split = this.card.imgURL.split('/');
            var split = split[split.length-1].split('.');
            var result: any;
            //this.card['cached_imgURL'] = this.helper.altUrl+'image_server/'+split[0]+'.png';
            this.card['race']=split[split.length-2];
            if(!this.card['starter'])this.card['starter']=false;
            this.name = this.card.name;
        }
    }
    ngOnChanges(changes: SimpleChanges) {
        //if (changes) console.log(changes);
    }
    ngOnInit() {
        if(this.flip)this.back=true;
        if (this.card) {
            this.card.abilities = this.helper.getAbilities(this.card);
        }
    }
    ngOnDestroy() {
        this._loaded=false;
    }
}