import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { HelperService } from '../_services/helper.service';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.styl']
})
export class HeaderComponent implements OnInit, OnChanges {
  constructor(public helper: HelperService) { }
  @Input('currentUser') currentUser: any;
  @Input('notifications') notifications = 0;
  @Output('show_notifs') show_notif = new EventEmitter();
  @Output('openLogin') openLogin = new EventEmitter();
  @Output('show_donate') show_donate = new EventEmitter();
  @Output('move_sc') move_sc = new EventEmitter();
  @Output('requested') requested = new EventEmitter();
  @Output('use_stamina') use_stamina = new EventEmitter();
  stamina_checker: any;
  globalMod_checker: any;
  daily_checker: any;
  stamina_regen = 0;
  displayNav = false;
  active_page: string;
  introPopup = false; // environment.production;
  dailyPopup = false;
  show_nav_confirmation = false;
  show_nav_confirmation_title: string;
  show_nav_confirmation_label: string;
  pre_sale = false;
  globalMod: number = 0;
  ngOnInit(): void {
    if (this.helper.detail_id && this.helper.detail_id > 0) this.introPopup = false;
    setTimeout(()=> {this.checkLoggedIn();}, 10000);
    //this.daily_checker = setInterval(()=>{
    //  this.checkDaily();
    //}, 60000*15);
    this.stamina_checker = setInterval(()=>{
      this.getStamRegen();
    }, 3500);
    this.globalMod_checker = setInterval(()=>{
      this.getGlobalMod();
    }, 60000*10);
    this.getGlobalMod();
  }
  ngOnChanges() {
    if (this.helper.detail_id && this.helper.detail_id > 0) this.introPopup = false;
    //console.log('helper.active',this.helper.active);
  }
  ngOnDestroy() {
    clearInterval(this.stamina_checker);
    clearInterval(this.globalMod_checker);
  }

  async checkDaily() {
    if (!this.currentUser) setTimeout(()=> {this.checkDaily();}, 5000);
    if (this.currentUser && this.currentUser.settings && this.currentUser.settings.daily) {
      let nextReward = null;
      let last = this.currentUser.settings.daily.timestamp;
      let firstReward = false;
      for (let i = 0; i < this.currentUser.settings.daily.rewards.length; i++) {
          let reward = this.currentUser.settings.daily.rewards[i];
          if (reward.claimed) last = reward.claimed;
          else {
              if (i === 0) firstReward = true;
              nextReward = reward;
              i = this.currentUser.settings.daily.rewards.length;
          }
      }

      let diff = Date.now() - new Date(last).getTime();
      let oneDay = 1000*60*60*24;
      if (diff < oneDay && !firstReward) { // less than 24 hours ago
          let today = new Date().getDate();
          let previous = new Date(last).getDate();
          //console.log(diff, today, previous);
          if (today !== previous) this.dailyPopup = true;
      } else this.dailyPopup = true;
      //this.dailyPopup = true; // test
    }
  }
  async checkLoggedIn() {
    if (!this.currentUser) setTimeout(()=> {this.checkLoggedIn();}, 5000);
    else this.checkDaily();
    //if (!this.dailyPopup) this.dailyPopup = true;
  }
  async getGlobalMod() {
    this.globalMod = await this.helper.getGlobalMod();
  }
  requested_button() {
    this.requested.emit(true);
  }
  user_click() {
    this.notifications > 0 ? this.show_notifications() : this.navigate('profile');
  }
  show_notifications() {
    this.show_notif.emit(1);
  }
  navigate(loc) {
    if (loc === 'quests') {
      this.dailyPopup = !this.dailyPopup;
      return;
    } else this.go(loc);
  }
  go(loc) {
    this.helper.navigate(loc);
    this.displayNav = false;
  }
  openLoginModal(){
    this.openLogin.emit(1);
  }
  respFunc() {
    this.displayNav = this.displayNav?false:true;
  }
  getStamRegen() { 
    this.stamina_regen = this.helper.getStamRegen();
    return this.stamina_regen;
  }
  moveSC() { 
    if (this.currentUser.config.mode === 'testServer') {
      this.helper.showSnackbarMessage('not available on test server');
      return;
    }
    this.move_sc.emit(true);
  }
  useStamina() { 
    this.use_stamina.emit(true); 
  }
  donate() {     this.show_donate.emit(true); }
}
