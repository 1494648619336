import { Component, OnInit, OnDestroy, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { Asset } from '../../_models/user';
import { HelperService } from '../../_services/helper.service';
import { DataService } from '../../_services/data.service';
import { AppComponent } from '../../app.component';
import { fade, flip_up,scale_90, pointer } from '../../app.animations';
@Component({
  selector: 'rewards-modal',
  animations: [fade, flip_up,scale_90, pointer],
  templateUrl: './rewards.component.html',
  styleUrls: [
    './rewards.component.styl'
  ]
})
export class RewardsComponent implements OnInit, OnDestroy, OnChanges {
  @Input('show') show = true;
  @Input('label') top_label = '';
  @Input('title') title = '';
  @Input('boss') boss: any;
  @Input('Forgium') Forgium: any;
  @Input('Damage') Damage: any;
  @Input('Points') Points: any;
  @Input('Electrum') Electrum: any;
  @Input('line_one') line_one = '';
  @Input('line_two') line_two = '';
  @Input('result_id') result_id = '';
  @Output('done') done = new EventEmitter();
  show_obj=0;
  constructor(
    public app: AppComponent,
    public helper: HelperService,
    public data: DataService
  ) {
  }
  stagger_objects() {
    if(this.show_obj<6) {
      this.show_obj=this.show_obj+1;
      setTimeout(()=>{ this.stagger_objects(); },250);
    }
  }
  ngOnChanges(a) {

    //console.log('ngOnChanges', a);
    if(a=='show') {
      setTimeout(()=>{this.stagger_objects();},500);  
    }
  }
  ngOnInit() {
    
  }
  ngOnDestroy() {
    this.show_obj=0;
  }
  done_button(status) {
    this.done.emit(status);
  }
  battle() {
    this.show = false
    this.done.emit(1);
  }
  close() {
    this.show_obj=0;
    this.show = false
    this.done.emit(0);
  }
}