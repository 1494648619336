import { Directive, OnChanges, ElementRef, Input } from '@angular/core'
import { Fireworks } from '../../../../../fireworks-js';
import type { FireworksOptions } from '../../../../../fireworks-js';

@Directive({
  selector: '[ngFireworks], ng-fireworks',
  exportAs: 'ngFireworks'
})
export class FireworksDirective extends Fireworks {
  constructor(elRef: ElementRef) {
    super(elRef.nativeElement)
  }
  @Input() options!: FireworksOptions
  private ngOnInit() {
    this.updateOptions(this.options)
    this.updateSize()
  }
  @Input('update') set update_fw(when: boolean) { 
    if(when){
      this.updateSize(); 
      this.updateOptions(this.options)
    }
  }
  @Input('launch') set launch_fw(n: number) { if(n>0)this.launch(n); }
  @Input('start') set start_fw(when: boolean) { if(when)this.start(); }
  @Input('stop') set stop_fw(when: boolean) { if(when)this.stop(); }
  @Input('trace') set set_trace(a: any) { if(a)this.createTrace(a[0],a[1],a[2],a[3]); }
  private ngOnChanges() {
    this.updateOptions(this.options)
  }
  private ngOnDestroy(): void {
    this.stop()
  }
}
