import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppComponent } from '../../app.component';
import { FetchService } from '../../_services/services';
import { HelperService } from '../../_services/helper.service';
import { DataService } from '../../_services/data.service';
import { fade, flip_shadow, flip, flip_up, pointer, boxlarge } from '../../app.animations';
import { dropdown } from '../../hero/hero.animations';
@Component({
  selector: 'forgium',
  templateUrl: './forgium.component.html',
  animations: [fade, flip, flip_up, pointer, boxlarge, dropdown, flip_shadow],
  styleUrls: ['./forgium.component.styl']
})
export class ForgiumComponent implements OnInit {
  @Input('currentUser') currentUser: any;
  @Input('show') show = false;
  @Output('done') done = new EventEmitter();
  constructor(
    public helper: HelperService, 
    public fetch: FetchService, 
    public data: DataService
  ) { }
  balance: any;
  status: any;
  receiver: string = '';
  amount: number = 0;
  converted_amount: number = 0;
  method='deposit';
  error: any;
  currencies = [];
  prices: any;
  EquivalentCurrency = 'hive'; // or the SC Value
  selectedCurrency = 'hive';
  show_currencies: boolean;
  async getHEBalances() {
    if (this.currentUser && this.currentUser.username) {
      let temp = await this.helper.loadBalances(this.currentUser.username);
      this.balance = temp.find(b => b.symbol === 'FORGE'); 
    }
  }
  toggle_currencies() {
    this.show_currencies=this.show_currencies?false:true;
  }
  ngOnInit(): void {
    //this.getPrices();
    this.show_currencies=false;
    this.getHEBalances();
  }
  setCurrency(c) {
    this.show_currencies=false;
    this.converted_amount=this.amount>0?this.convert_currency(this.amount, this.EquivalentCurrency, c.symbol):0;
    this.selectedCurrency = c.symbol;
  }
  convert_currency(amount, from, to) {
    if(!this.prices)return;
    if(amount){
      var a = amount;
      var x = a*this.prices[from];
      return Number((x/this.prices[to]).toFixed(8));
    }
  }
  change_from_amount($event) {
    this.converted_amount=this.amount>0?Number(this.convert_currency(this.amount,this.EquivalentCurrency,this.selectedCurrency).toFixed(8)):0;
  }
  change_to_amount($event){this.amount=this.converted_amount>0?Number(this.convert_currency(this.converted_amount,this.selectedCurrency,this.EquivalentCurrency).toFixed(2)):0;}
  async getPrices() {
    this.prices = await this.fetch.loadPrices();
    var keys = Object.keys(this.prices);
    for (var i = 0; i < keys.length; ++i) {
      this.currencies.push({
        symbol: keys[i],
        value: this.prices[keys[i]]
      });
    }
  }
  close() {
    this.show = false
    this.done.emit(1);
  } 
  async withdrawToHE() {
    if (this.currentUser.sc.balance < this.amount) {
      this.error='Account Balance Insufficient';
      return;
    } else if (this.amount > 0) {
      this.data.setLoading(true);
      let temp = await this.helper.transferToHE(this.amount, 'FORGE');
      if (!temp) return;
      // TODO check better before removing balance
      this.currentUser.sc.balance -= this.amount;
      this.balance.balance += this.amount;
      this.amount = 0; this.converted_amount = 0;
      this.status = 200;
      this.data.setLoading(false);
    } else {
      this.error='Enter a Positive Amount';
    }
  }
  async depositToSF() {
    if (this.balance?.balance >= this.amount && this.amount > 0) {
      this.data.setLoading(true);
      let temp = await this.helper.depositToSF(this.amount, 'FORGE');
      if (!temp) return;
      if (temp['success'] && temp['tx_id']) {
        this.helper.changeSC(this.amount);
        //this.currentUser.sc.balance += this.amount;
        this.balance.balance -= this.amount;
        this.amount = 0; this.converted_amount = 0;
        this.status = 200;
      } else {
        this.helper.showSnackbarMessage('transaction not sent');
        this.amount = 0; this.converted_amount = 0;
      }
      this.data.setLoading(false);
    } else {
      this.error='Transaction Not Authorized';
    }
  }
  async transferToPlayer() {
    if (this.currentUser.sc.balance < this.amount) {
      this.error='Account Balance Insufficient';
      return;
    } else if (this.receiver && this.receiver.length > 2 && this.amount > 0) {
      this.data.setLoading(true);
      let temp = await this.helper.transferTokenToPlayer(this.receiver.toLowerCase(), this.amount, 'FORGE');
      if (!temp) return;
      if (temp['success']) {
        this.currentUser.sc.balance -= this.amount;
        this.status = 200;
      } else {
        this.error='Transaction Incomplete';
      }
      this.data.setLoading(false);
    } else {
      this.error='Enter a Positive Amount';
    }
  }
}
