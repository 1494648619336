import { Component, OnInit, OnDestroy, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { DataService } from '@app/_services/data.service';
import { HelperService } from '@app/_services/helper.service';

@Component({ 
    templateUrl: 'selectedcard.component.html', 
    selector: 'selectedcard-selector',
    styleUrls: [
        './selectedcard.component.styl',
        '../../openpacks/openpacks.component.styl'
    ]
})
export class SelectedCardComponent implements OnInit, OnDestroy, OnChanges {
    @Input('card') card: any;
    @Input('cards') cards: any;
    @Input('combinables') combinables: any;
    @Input('display') display: any;
    @Input('equippable') equippable: any;
    @Input('equipped') equipped: any;
    @Input('heading') pageHeading = true;
    @Input('background') background = true;
    @Input('smaller') smaller = true;
    @Input('tabName') tabName = 'SelectedCard';
    @Output('close') close_btn = new EventEmitter();
    rechargable: any;
    selectedToRecharge = [];
    cardLevels;
    qty = 1;
    config: any;
    selected: any = [];
    sellable: any = [];
    selling: any = [];
    baseSelling: any = [];
    gems_to_burn: any = [];
    socketCards: any;
    imbuedPreview: any;
    selectedToImbue: any;
    modal_mode: string;
    message: any;
    loading: any;
    viewCard = false;
    show_modal = false;
    transfer_card = false;
    show_burn_modal = false;
    modal_title = '';
    equip_card: any;
    type = '';
    next_threshold = 2;
    BurnValue: number;
    ElectrumBalance: number;
    NumberChecked: number;
    CardPoints: number;
    Projected: number;
    receiver: string = '';
    showSuccessModal: boolean;
    successModalTitle: string;
    successModalMessage: string;
    modal_button = 'Close';
    burnType: string = 'FORGE';
    card_eligible = [];
    burn_value = 0;
    marketAction = 'Sell';
    socketOdds: any;
    constructor(
        private helper: HelperService, 
        private data: DataService
    ) { }
    ngOnInit() {
        this.data.onLoadingChange().subscribe(l => this.loading = l);
        this.data.onConfigChange().subscribe(c => this.config = c);
        this.data.setConfig(this.helper.config);
        this.getNextThreshold();
        this.isMaxSockets();
        this.getElectrumBalance();
        this.getProjected();
        if(this.combinables) this.combinables.forEach(c => {
            c['UsedSocketText']=this.getUsedSocketText(c);
        });
        this.openTab(null, this.tabName);
        let temp = this.helper.getCurrentUsername();
        if (this.helper.config) this.socketOdds = this.helper.config.socketOdds;
    }
    ngOnDestroy() {
        if(this.combinables) this.combinables.forEach(c => {
            c['checked']=false;
        });
    }
    ngOnChanges() {
        this.card_eligible['combine']   = this.eligible('combine');
        this.card_eligible['burn']      = this.eligible('burn');
        this.card_eligible['transfer']  = this.eligible('transfer');
    }
    async addSocketForDisplay() {
        let temp = JSON.parse(JSON.stringify(this.card));
        temp.socket_items.shift();
        temp.socket_items.push(this.selectedToImbue.cid);
        return temp;
    }
    getCooldown(card) {
        let user = this.helper.getCurrentUsername();
        if (!card.last_used_by || card.last_used_by === user) return false;
        else {
            let diff = parseInt(localStorage.getItem('forge:diff'));
            if (!diff) diff = 0;
            let timeSinceLastUsed = Math.floor((Date.now() + diff - new Date(card.last_used_date).getTime())/60000);
            if (timeSinceLastUsed < 1440) return 'Card is on cooldown to ' + card.last_used_by + ' for ' + (1440 - timeSinceLastUsed) + ' minutes';
            return false;
        }
    }
    async modal_done(e) {
        //console.log(e);
        let event;
        let type;
        if (e.status) { event = e.status; type = e.type; }
        else event = e;
        this.show_modal=false;
        if(event === 'confirm add socket') {
            this.data.setLoading(true);
            let temp = await this.helper.addSocketToItem(this.card.cid, this.qty);
            if (temp?.message === 'success' && temp?.worked) {
                this.helper.changeElectrum(-this.qty);
                this.getElectrumBalance();
                this.getSFCards();
                //this.helper.refreshUser();
                this.showSuccessModal = true;
                this.successModalTitle = 'Socket Successful!';
                this.successModalMessage  = 'Successfully socketed ' + this.card.name;
                this.close();
            } else if (temp?.message === 'success' && !temp.worked) {
                this.helper.changeElectrum(-this.qty);
                this.getElectrumBalance()
                this.helper.showSnackbarMessage(temp.note);
            } else if (!temp) {
                // this.helper.showSnackbarMessage();
                this.helper.showSnackbarMessage(temp?.message ? temp.message : 'oops, something went wrong');
            }
            // this.data.setViewCard(false);
            this.data.setLoading(false);
        } else if (event === 'confirm combine') {
            this.data.setLoading(true);
            let selectedIDs = [...new Set(this.selected.map(c => c.cid))];
            let temp = await this.helper.combineCards(this.card, selectedIDs);
            if (temp['message'] === 'success') {
                this.getSFCards();
                //await this.helper.getSFCards();
                // this.helper.showSnackbarMessage('Combine Successful!');
                this.showSuccessModal = true;
                this.successModalTitle = 'Combine Successful!';
                this.successModalMessage  = 'Your cards have been combined successfully.';
                this.close();
            } else if (!temp) {
                this.helper.showSnackbarMessage('Decoding failed');
            } else if (temp['message']) {
                this.helper.showSnackbarMessage(temp['message']);
            }
            this.data.setLoading(false);
        } else if (event === 'confirm salvage') {
            this.data.setLoading(true);
            let selectedIDs = [...new Set(this.selected.map(c => c.cid))];
            let temp: any = await this.helper.salvageRelics(selectedIDs);
            if (temp['message'] === 'success') {
                this.helper.changeFrags(temp.frags);
                this.getSFCards();
                this.showSuccessModal = true;
                this.successModalTitle = 'Salvage Successful!';
                this.successModalMessage  = 'Successfully salvaged card.';
                this.close();
            } else if (!temp) {
                this.helper.showSnackbarMessage('Decoding failed');
            } else if (temp['message']) {
                this.helper.showSnackbarMessage(temp['message']);
            }
            this.data.setLoading(false);
        } else if (event === 'confirm burn') {
            this.data.setLoading(true);
            let selectedIDs = [...new Set(this.selected.map(c => c.cid))];
            let temp = await this.helper.burnCards(selectedIDs, type);
            if (temp['message'] === 'success') {
                if (type==='FORGE')this.helper.changeSC(this.getSCBurnValue());
                if (type==='Electrum') {
                    let q = parseInt(temp['qty']);
                    if (q > 1) {
                        this.helper.changeElectrum(q);
                        this.helper.showSnackbarMessage('You have received ' + q + ' Electrum!');
                    } else this.helper.changeElectrum(this.getSCBurnValue()*10);
                }
                this.getSFCards();
                this.showSuccessModal = true;
                this.successModalTitle = 'Burn Successful!';
                this.successModalMessage  = 'Successfully burned card.';
                this.close();
            } else if (!temp) {
                this.helper.showSnackbarMessage('Decoding failed');
            } else if (temp['message']) {
                this.helper.showSnackbarMessage(temp['message']);
            }
            this.data.setLoading(false);
        } else if (event === 'confirm equip') {
            let card = this.equip_card;
            let user = JSON.parse(JSON.stringify(this.helper.currentUser));
            this.data.setLoading(true);
            let temp = await this.helper.equipCard(card.cid);
            if (temp?.message === 'success') {
                let corrected = card.slot;
                if (corrected.indexOf('eapon') > -1) corrected = 'weapon';
                else if (corrected.indexOf('ffhand') > -1) corrected = 'offhand';
                user.heroes[user.currentClass.toLowerCase()][corrected] = card.cid;
                this.data.setUser(user);
                this.helper.showSnackbarMessage('Successfully equipped ' + card.name);
                //this.showSuccessModal = true;  // when setViewCards(false) is called, this modal disappears with the equip a card modal
                //this.successModalTitle = 'Equip Successful!';
                //this.successModalMessage  = 'Successfully equipped ' + card.name;
            } else {
                console.log(temp);
                this.helper.showSnackbarMessage(temp['message']);
            }
            this.data.setLoading(false);
            
            this.equippable = null;
            this.equipped = null;
            this.data.setViewCard(false);
        } else if (event === 'confirm transfer') {

        } else if (event === 'confirm unequip') {
            let user = JSON.parse(JSON.stringify(this.helper.currentUser));
            this.data.setLoading(true);
            let temp = await this.helper.unequipCard(this.equipped);
            console.log(temp);
            if (temp?.message === 'success') {
                let corrected = this.equipped.slot;
                if (corrected.indexOf('eapon') > -1) corrected = 'weapon';
                else if (corrected.indexOf('ffhand') > -1) corrected = 'offhand';
                user.heroes[user.currentClass.toLowerCase()][corrected] = '';
                this.data.setUser(user);
                this.helper.showSnackbarMessage('Successfully unequipped ' + this.equipped.name);
                //this.showSuccessModal = true;  // when setViewCards(false) is called, this modal disappears with the equip a card modal
                //this.successModalTitle = 'Equip Successful!';
                //this.successModalMessage  = 'Successfully equipped ' + card.name;
            } else {
                console.log(temp);
            }
            this.data.setLoading(false);
            
            this.equippable = null;
            this.equipped = null;
            this.data.setViewCard(false);
        } else if (event === 'confirm emptySockets') {
            let user = JSON.parse(JSON.stringify(this.helper.currentUser));
            this.data.setLoading(true);
            let temp = await this.helper.emptySockets(this.card);
            console.log(temp);
            if (temp?.message === 'success') {
                let corrected = this.equipped.slot;
                if (corrected.indexOf('eapon') > -1) corrected = 'weapon';
                else if (corrected.indexOf('ffhand') > -1) corrected = 'offhand';
                user.heroes[user.currentClass.toLowerCase()][corrected] = '';
                this.data.setUser(user);
                this.helper.showSnackbarMessage('Successfully unequipped ' + this.equipped.name);
                //this.showSuccessModal = true;  // when setViewCards(false) is called, this modal disappears with the equip a card modal
                //this.successModalTitle = 'Equip Successful!';
                //this.successModalMessage  = 'Successfully equipped ' + card.name;
            } else {
                console.log(temp);
            }
            this.data.setLoading(false);
            
            this.equippable = null;
            this.equipped = null;
            this.data.setViewCard(false);
        }
    }
    async transfer_card_now(e) {
        if(e) {
            this.receiver = e;
            this.transfer_card=true;
            await this.transfer_card_confirmed();
        } else {
            this.transfer_card=false;
        }
    }

    async emptyAllSockets() {
        this.data.setLoading(true);
        let temp = await this.helper.emptySockets(this.card);
        if (temp['message'] && temp['message'] === 'success') {
            this.helper.showSnackbarMessage('successfully removed items from sockets');
            await this.getSFCards();
            this.data.setLoading(false);
            
            this.equippable = null;
            this.equipped = null;
            this.data.setViewCard(false);
        } else {
            this.helper.showSnackbarMessage('something went wrong');
            console.log(temp);
        }
    }

    async transfer_card_confirmed() {
        this.data.setLoading(true);
        let selectedIDs = [...new Set(this.selected.map(c => c.cid))];
        let temp = await this.helper.transferCardToPlayer(this.receiver, selectedIDs);
        if (temp['message'] === 'success') {
            await this.helper.waitMS(5000);
            this.getSFCards();
            //await this.helper.getSFCards();
            this.showSuccessModal = true;
            this.successModalTitle = 'Transfer Successful';
            this.successModalMessage  = '<i class="fa-solid green-checkmark fa-check"></i><br />The transfer has successfully been requested.';
            this.close();
        } else if (!temp) {
            //this.message = 'Decoding failed';
            //this.show_modal = true;
            this.helper.showSnackbarMessage('Transaction not sent...');
        } else if (temp['message']) {
            //this.message = temp['message'];
            //this.show_modal = true;
            this.helper.showSnackbarMessage(temp['message']);
        }
        this.data.setLoading(false);
    }
    async addSocketToItem() {
        if (this.card && this.card.market_id && this.card.market_id !== null) {
            this.helper.showSnackbarMessage('You can not modify items listed on the market');
            return;
        }
        this.type = 'confirm add socket';
        this.message = 
            'There is a '+this.calcAddSocketSuccess()+
            '% chance to succeed. If you fail you will lose your Electrum. <br />'+
            '<i>Do you wish to proceed?</i>';
        this.modal_mode = 'confirmation';
        this.modal_title = 'Add Socket';
        this.show_modal = true;
    }
    async burn(type) { 
        //console.log('Burn Card')
        let onMarket = this.selected.filter(c => c.market_type === 'SELL');
        if (onMarket && onMarket.length > 0) {
            this.helper.showSnackbarMessage('Can not burn cards that are listed on the market');
            return;
        }
        let selectedIDs = [...new Set(this.selected.map(c => c.cid))];
        if (!selectedIDs || selectedIDs.length < 1) {
            this.modal_title = 'No Cards Selected';
            this.message = 'No cards selected to burn.';
            this.show_modal = true;
            this.modal_mode='';
            // this.close();
            return;
        }
        if (selectedIDs.length !== this.selected.length) {
            this.modal_title = 'Duplicate Card Selected';
            this.message = 'Somehow you\'ve selected the same card more than once.';
            this.show_modal = true;
            this.modal_mode='';
            // this.close();
            return;
        }

        let numCardsSelected = 0;
        let missmatch = false;
        this.selected.forEach(c => {
            numCardsSelected += c.combined;
            if (c.rarity !== this.card.rarity || c.slot !== this.card.slot) missmatch = true;
        });
        if (missmatch) {
            this.modal_title = 'Unique Rarities Required';
            this.message = 'Rarities selected are not the same or different from the card you are attempting to level';
            this.show_modal = true;
            this.modal_mode='';
            // this.close();
            return;
        }
        let usedCIDs = await this.helper.getUsedCardIDs(); // await this.helper.getUsedCards(this.cards, true);
        //console.log(usedCIDs);

        let usedCard = false;
        this.selected.forEach(c => {
            if (usedCIDs.includes(c.cid)) usedCard = true;
        });
        if (usedCard) {
            this.modal_title = 'Equipped Cards';
            this.message = 'Sorry! You are not ableclose() to burn equipped cards.';
            this.show_modal = true;
            this.modal_mode='';
            // this.close();
            return;
        }
        this.burnType = type;
        this.type = 'confirm burn';
        this.modal_title = 'Burn Card';
        this.burn_value=this.getSCBurnValue();
        this.message = 'Burn ' + this.selected.length + ' card points into ';
        this.show_burn_modal = true;
        this.modal_mode = 'confirmation';
        //this.helper.showSnackbarMessage('not implemented yet');
    }
    calcAddSocketSuccess() {
        // let socketOdds = this.config.socketOdds;
        let socketOdds = {1: [300],2: [500,2500],3: [1000,5000,25000],4: [2500,12500,62500,500000]};
        let rarityArray = socketOdds[this.card.rarity];
        let threshold = this.card.sockets <= rarityArray.length ? rarityArray[this.card.sockets] : 0;
        if (this.qty > threshold && threshold > 0) this.qty = threshold;
        let odds = this.qty/threshold*100;
        return odds.toFixed(7).slice(0, -5);
    }
    checkAll() {
        for (let i = 0; i < this.combinables.length; i++) {
            let c = this.combinables[i];
            if(c.cid === this.card.cid||c.equipped){

            } else {
                if (this.selected.length >= 120) {
                    this.helper.showSnackbarMessage('maximum of 120 cards selected per transaction');
                    i = this.combinables.length;
                    return;
                }
                let found = this.selected.find(s => s.cid === c.cid);
                if(!found) {
                    c.checked = true;
                    this.checkSFCardToCombine(null, c, true);
                }
            }
        }
    }
    checkSFCardToCombine(event, card, all=false) {
        let found = this.selected.find(s => s.cid === card.cid);
        if (found && !all) {
            card.checked = false;
            this.selected = this.selected.filter(c => c.cid !== card.cid);
        } else {
            if (this.selected.length >= 120) {
                this.helper.showSnackbarMessage('maximum of 120 cards selected per transaction');
                return;
            }
            card.checked = true;
            this.selected.push(card);
        }
        this.getNumChecked();
        this.getSCBurnValue();
        this.getCardPointsSelected();
    }
    selectDifferentCard(card) {
        card.checked = false;
        this.selected = this.selected.filter(c => c.cid !== card.cid);
        //if(!this.original_card) this.original_card = this.card;
        this.card = card;
        this.isMaxSockets();
        //console.log('ability_description', card.ability_description)
    }
    close() {
        this.card = null;
        // this.combinables = null;
        this.display = null;
        this.equippable = null;
        this.equipped = null;
        if(this.combinables) this.combinables.forEach(c => {
            c['checked']=false;
        });
        this.close_btn.emit(1);
        this.data.setViewCard(false);
    }
    async combine() {
        // check if card is already max
        let onMarket = this.selected.filter(c => c.market_type === 'SELL');
        //console.log(onMarket);
        if (onMarket && onMarket.length > 0) {
            this.helper.showSnackbarMessage('Can not combine cards that are listed on the market');
            return;
        }
        let selectedChecked = this.selected.find(c => c.cid === this.card.cid);
        if (selectedChecked) {
            this.checkSFCardToCombine(null, selectedChecked);
            this.selected = this.selected.filter(c => c.cid !== this.card.cid);
        }
        let socketBurnCheck = this.selected.find(c => c.sockets > this.card.sockets);
        if (socketBurnCheck) {
            this.helper.showSnackbarMessage('Burning cards with more sockets than target card!');
            if (!confirm('You are about to burn card(s) that have more socket(s) than the card you are trying to level, you will lose these extra socket(s), do you wish to continue?')) return;
        }
        const cardLevels = this.card.foil ? this.helper.config.sfFoilCardLevels[this.card.rarity-2] : this.helper.config.sfCardLevels[this.card.rarity-1];
        const maxLevel = cardLevels[cardLevels.length-1];
        if (this.card.level === 4) {
            this.modal_title = 'Max Level';
            this.message = 'Card is already max level.';
            this.modal_mode='';
            this.show_modal = true;
            return;
        }
        // check for overcombine
        let numCardsSelected = 0;
        let missmatch = false;
        let lessSocketsOnTarget = false; //this.card.sockets;
        this.selected.forEach(c => {
            if (c.sockets > this.card.sockets) lessSocketsOnTarget = true;
            numCardsSelected += c.combined;
            if (c.rarity !== this.card.rarity || c.slot !== this.card.slot) missmatch = true;
        });
        if (numCardsSelected == 0) {
            this.modal_title = 'No Cards Selected';
            this.message = 'No cards selected to combine points.';
            this.show_modal = true;
            this.modal_mode='';
            // this.close();
            return;
        }
        if (lessSocketsOnTarget && !confirm('A card or cards you have selected to burn have more sockets than the target card, do you wish to continue?')) return;
        if (missmatch) {
            this.modal_title = 'Unique Rarities Required';
            this.message = 'Rarities selected are not the same or different from the card you are attempting to level';
            this.modal_mode='';
            this.show_modal = true;
            // this.close();
            return;
        }
        
        /*if (this.selected.filter(c => c.equipped).length > 0) {
            this.modal_title = 'Invalid Combination';
            this.message = 'Can not combine cards that you have equipped.';
            this.modal_mode='';
            this.show_modal = true;
            return;
        }*/
        if (numCardsSelected + this.card.combined > maxLevel) {
            this.modal_title = 'Overcombine Cards';
            this.message = 'You are about to overcombine ' + this.card.name + ' by ' + (numCardsSelected + this.card.combined - maxLevel) + ' cards, are you sure?'
            this.modal_mode='';
            this.show_modal = true;
        }
        this.type = 'confirm combine';
        this.modal_title = 'Combine Cards';
        this.message = 'Combine ' + this.selected.length + ' cards into ' + this.card.name;
        this.show_modal = true;
        this.modal_mode = 'confirmation';
    }
    eligible(type) {
        if (type === 'combine' && !this.selected.find(c => c.cid === this.card.cid) && !this.selected.find(c => c.equipped)) return true;
        else if (type === 'burn' || type === 'transfer' && !this.selected.find(c => c.equipped)) return true;
        else return false;
    }
    enoughSelected(level) {
        const cardLevels = this.card.foil ? this.helper.config.sfFoilCardLevels[this.card.rarity-2] : this.helper.config.sfCardLevels[this.card.rarity-1];
        const checkMark = cardLevels[level-1];
        let avail = this.card.combined;
        this.selected.forEach(c => avail += c.combined);
        return avail >= checkMark;
    }
    enoughToLevel(level) {
        const cardLevels = this.card.foil ? this.helper.config.sfFoilCardLevels[this.card.rarity-2] : this.helper.config.sfCardLevels[this.card.rarity-1];
        const checkMark = cardLevels[level-1];
        let avail = this.card.combined;
        this.combinables.forEach(c => {
            if (c.cid !== this.card.cid && !c.equipped && c.combined < checkMark) {
                avail += c.combined
            }
        });
        return avail >= checkMark;
    }
    async equipCard(card) {
        this.equip_card = card;
        this.modal_title = 'Equip Card';
        this.message = 'Would you like to equip ' + card.name;
        this.type = 'confirm equip';
        this.modal_mode = 'confirmation';
        this.show_modal = true;
        return;
    }
    getCardPointsSelected() {
        let total = 0;
        this.selected.forEach(c => {
            if (c.cid !== this.card.cid) total += c.combined;
        });
        this.CardPoints = total;
    }
    getCombineQtyAvailable(level) {
        const cardLevels = this.card.foil ? this.helper.config.sfFoilCardLevels[this.card.rarity-2] : this.helper.config.sfCardLevels[this.card.rarity-1];
        const checkMark = cardLevels[level-1];
        let avail = 0;
        this.combinables.forEach(c => {
            if (c.cid !== this.card.cid && !c.equipped && c.combined < checkMark) {
                avail += c.combined
            }
        });
        return avail + ' total card points available toward next upgrade';
    }
    getCurrentlySelectedText(c) {
        let text = '';
        if (this.card.cid === c.cid && this.card.type === 'equipment') {
            text = 'The currently selected card is the one that other cards will combine into.<br />It will also be the target card for Imbuing Gems/Runes and Adding Sockets.';
        } else if (this.card.type === 'equipment') {
            text = 'This card can be chosen to one of the following:<br />Combine into the selected card.<br />nBurn for tokens.<br />Transfer to another player.';
        } else {
            text = 'Gems and Runes can only be burnt from this area.<br />Visit the forge to Transmute gems into higher rarities.'
        }
        return text;
    }
    getEquippedStatus(card) {
        let text = '';
        if (card.equipped) text = '(Equipped on ' + card.equippedOn + ')';
        return text;
    }
    getNextThreshold(add = 0) {
        if (!this.card || !this.selected) return;
        const cardLevels = this.card.foil ? this.helper.config.sfFoilCardLevels[this.card.rarity-2] : this.helper.config.sfCardLevels[this.card.rarity-1];
        return cardLevels[this.card.level+add];
    }
    getNumChecked() {
        let value = 0;
        this.selected.forEach(c => {
            value += c.combined;
        });
        this.NumberChecked = this.selected.length;
        return value;
    }
    getProgress() {
        let text = '';
        let left = this.card.combined+this.CardPoints;
        let right = this.getNextThreshold();
        if (left > right && this.card.level < 3) right = this.getNextThreshold(1);
        if (left > right && this.card.level < 2) right = this.getNextThreshold(2);
        return text+left+'/'+right;
    }
    getProjected() {
        if (!this.card || !this.selected) return;
        let count = this.card.combined;
        this.selected.forEach(s => count += s.combined);
        this.Projected = count;
    }
    getSCBurnValue() {
        if(this.config.burnTable){
            let burnTable = this.config.burnTable; // [10,25,100,500];
            let qty = this.getNumChecked();
            this.BurnValue = burnTable[this.card.rarity]*qty;
            if (this.selected.length > 0 && this.selected[0].foil) this.BurnValue *= 10;
            return this.BurnValue;
        } else {
            return 0;
        }
    }
    async getSFCards() {
        await this.helper.getSFCards();
    }
    getElectrumBalance() {
        if(!this.helper.currentUser) return;
        if (!this.helper.currentUser.electrum) this.ElectrumBalance = 0;
        else this.ElectrumBalance = this.helper.currentUser.electrum;
        return;
    }
    async getSocketedStats(card) {
        let stats = [];
        if (card.sockets < 1) return stats;
        else {
            for (let i = 0; i < card.socket_items.length; i++) {
                let currentCard = this.cards.find(c => c.cid === card.socket_items[i]);
                if (currentCard && currentCard.stat !== 'ability') stats.push(currentCard.stat);
            }
        }
        return stats;
    }
    getUsedSocketText(card) {
        if (card.sockets < 1) return '';
        else {
            let text = '(empty)';
            card.socket_items.forEach(s => {
                let found = this.cards.find(c => c.cid === s);
                if (found) {
                    if (text === '(empty)') text = ' - ' + found.name;
                    else text += ', ' + found.name;
                }
            })
            return text;
        }
    }
    
    async imbueItem() {
        if (this.card && this.card.market_id && this.card.market_id !== null) {
            this.helper.showSnackbarMessage('You can not modify items listed on the market');
            return;
        }
        if (!this.isCardEligibleToSocket(this.selectedToImbue)) {
            this.message='This card is not eligible to be socketed in this card';
            this.modal_title='Card Not Eligible';
            this.show_modal=true;
            this.modal_mode='';
            this.type='';
            return;
        }
        //if (confirm('Imbue ' + this.card.name + ' with ' + this.selectedToImbue.name)) {
        this.data.setLoading(true);
        let temp = await this.helper.imbueCard({
            card: this.selectedToImbue.cid,
            user: '',
            target: this.card.cid
        });
        if (!temp) {
            this.helper.showSnackbarMessage('Decoding failed');
        } else if (temp['message']) {
            this.helper.showSnackbarMessage(temp['message']);
        }
        this.data.setLoading(false);
        if (temp['message'] === 'success') {
            this.getSFCards();
            this.showSuccessModal = true;
            this.successModalTitle = 'Imbue Successful!';
            this.successModalMessage  = 'Your imbue was completed successfully.';
            this.modal_mode='';
            this.type='';
            this.close();
        } else {
            this.getSFCards();
            this.message=temp['message'];
            this.helper.showSnackbarMessage('Card Imbue has failed.');
            this.close
        }
        this.data.setLoading(false);
        //}
    }
    async isCardEligibleToSocket (card) {
        let username = this.helper.getCurrentUsername();
        let diff = new Date(card.last_used_date).getTime() - (Date.now() - 1000*60*60*24);
        if (card.last_used_by !== username && diff > 0) return false;
        let restricted = ['Attack', 'Magic', 'Ranged'];
        if (this.card.slot.indexOf('eapon') > -1 && restricted.includes(card.stat) && card.stat !== this.card.stat) return false;
        let currentStats = await this.getSocketedStats(this.card);
        let usedCIDS = this.helper.getUsedCards(this.cards, true);
        let allowed = ['ability','Health','Armor','Speed'];
        if (restricted.includes(this.card.stat)) allowed.push(this.card.stat);
        return !usedCIDS.includes(card.cid) &&
                //card.rarity <= this.card.level &&
                card.type === 'socket' &&
                (allowed.includes(card.stat) || !restricted.includes(card.stat));
                //&& (!currentStats.includes(card.stat) || !restricted.includes(card.stat));
    }
    max_sockets = false;
    isMaxSockets() {
        if(!this.card||!this.card.rarity)return;
        if (this.card.rarity === 1 && this.card.sockets >= 1) this.max_sockets = true;
        else if (this.card.rarity === 2 && this.card.sockets >= 2) this.max_sockets = true;
        else if (this.card.rarity === 3 && this.card.sockets >= 3) this.max_sockets = true;
        else if (this.card.rarity === 4 && this.card.sockets >= 4) this.max_sockets = true;
        else this.max_sockets = false;
    }
    async openTab(event, tabName) {
        if (tabName === 'Imbue') {
            if (this.card && this.card.market_id && this.card.market_id !== null) {
                this.helper.showSnackbarMessage('You can not modify items listed on the market');
                return;
            }
            this.socketCards = [];
            this.selectedToImbue = null;
            this.imbuedPreview = null;
            let temp = this.cards.filter(c => c.type === 'socket' && !c.market_id);
            for (let i = 0; i < temp.length; i++) {
                let allowed = await this.isCardEligibleToSocket(temp[i]);
                if (allowed) this.socketCards.push(temp[i]);
            }
            this.socketCards = await this.helper.getUniqueSFCards(this.socketCards);
            this.socketCards.sort((a,b)=> { return b.rarity-a.rarity});
        } else if (tabName === 'Market') {
            //if (!confirm('Going to the market tab will remove any selected cards that are ineligible to be listed.')) return;
            let u = this.helper.getCurrentUsername();
            //this.sellable = this.combinables.filter(c => { return (!c.market_id || c.market_id === null) && !c.equipped && c.player === u; });
            this.sellable = [];
            this.selling = [];
            this.baseSelling = [];
            let usedCIDs = await this.helper.getUsedCardIDs();
            this.combinables.forEach(c => {
                if ((!c.market_id || c.market_id === null) && c.player === u && !usedCIDs.includes(c.cid)) {
                    c.market_price = 0;
                    this.sellable.push(c);
                } else if (c.market_id && c.market_id !== '') {
                    this.selling.push(c);
                }
            });
            if (this.selling.length > 0) this.baseSelling = JSON.parse(JSON.stringify(this.selling));
        } else if (tabName === 'Socket' && this.card && this.card.market_id && this.card.market_id !== null) {
            this.helper.showSnackbarMessage('You can not modify items listed on the market');
            return;
        } else if (tabName === 'Recharge') {
            let commons = await this.helper.loadSFCardsByRarityType(1, 'socket');
            let rares = await this.helper.loadSFCardsByRarityType(2, 'socket');
            let epics = await this.helper.loadSFCardsByRarityType(3, 'socket');
            let legs = await this.helper.loadSFCardsByRarityType(4, 'socket');
            this.rechargable = commons.concat(rares,epics,legs);
        }
        this.tabName = tabName;
    }
    numWithPrice(type) {
        let total = 0;
        if (type === 'salable') this.sellable.forEach(c => { if (c.market_price >= 0.01) total += 1; })
        else if (type === 'selling') {
            this.selling.forEach(c => {
                let found = this.baseSelling.find(x => x.cid === c.cid);
                if (found && found.market_price !== c.market_price) {
                    total += 1; 
                }
            });
        }
        return total;
    }
    total(type) {
        let total = 0;
        if (type === 'salable') this.sellable.forEach(c => { if (c.market_price >= 0.01) total += c.market_price; });
        else if (type === 'selling') {
            this.selling.forEach(c => {
                let found = this.baseSelling.find(x => x.cid === c.cid);
                if (found && found.market_price !== c.market_price) {
                    total += c.market_price; 
                }
            });
        }
        return total.toFixed(3);
    }
    rarityToString(rarity) {
        if (rarity === 1) return 'Common';
        else if (rarity === 2) return 'Rare';
        else if (rarity === 3) return 'Epic';
        else if (rarity === 4) return 'Legendary';
        else '';
    }
    selectCard(card) {
        console.log(card);
        if (this.selected.length > 0){
           
            let choice = confirm('Selecting a new card while you have cards selected to combine will reset your current selection.\nWould you like to do this?');
            if (choice) { //
                this.selected = [];
                this.combinables.forEach(c => {
                    c.checked = false;
                    //if (c.checked) this.checkSFCardToCombine(null, c);
                })
                this.card = card;
            }

        } else this.card = card;
    }
    async selectCardToImbue(card) {
        this.selectedToImbue = card;
        this.imbuedPreview = await this.addSocketForDisplay();
    }
    selectToLevel(level) {
        const cardLevels = this.card.foil ? this.helper.config.sfFoilCardLevels[this.card.rarity-2] : this.helper.config.sfCardLevels[this.card.rarity-1];
        const checkMark = cardLevels[level-1];
        let avail = this.card.combined;
        this.combinables.forEach(c => {
            if (avail + c.combined <= checkMark && !c.checked && c.cid !== this.card.cid && !c.equipped) {
                avail += c.combined;
                this.checkSFCardToCombine(null, c); // c.checked = true;
            }
        });
        if (avail < checkMark) {
            this.modal_title = 'Change Selection';
            this.message = 'Due to some combinable cards being levelled, manual selection may be required to complete upgrade.';
            this.show_modal = true;
            this.modal_mode='';
        }
    }
    selectToRecharge(card) {
        let found = this.selectedToRecharge.find(c => c.cid === card.cid);
        let total = this.rechargePointsSelected();
        let left = this.card.charges.max - total - this.card.charges.current
        let newPts = this.card.rarity===4?10:this.card.rarity===3?6:this.card.rarity===2?4:2;
        if (newPts > left && !found) {
            let text = 'Adding this card will provides more charges than you need to reach max.\n';
            text += 'You will lose ' + (newPts - left) + ' charges by adding this card.\n';
            text += 'Do you wish to continue?';
            if (!confirm(text)) return;
        }
        if (found) {
            this.selectedToRecharge = this.selectedToRecharge.filter(c => c.cid !== card.cid);
            card.checked=false;
        } else { 
            this.selectedToRecharge.push(card);
            card.checked=true;
        }
    }
    rechargePointsSelected() {
        let total = 0;
        this.selectedToRecharge.forEach(x => {
            if (x.rarity === 4) total += 10;
            else if (x.rarity === 3) total += 6;
            else if (x.rarity === 2) total += 4;
            else if (x.rarity === 1) total += 2;
        })
        return total;
    }
    async rechargeRelic() {
        let rcPts = this.rechargePointsSelected();
        let text = 'You are about to burn ' + this.selectedToRecharge.length + ' gems/runes to add ';
        text += '' + rcPts + ' charges to ' + this.card.name + '.\n';
        text += 'Are you sure?';
        if (!confirm(text)) return;

        this.data.setLoading(true);
        let selectedIDs = [...new Set(this.selectedToRecharge.map(c => c.cid))];
        let temp: any = await this.helper.rechargeRelic(this.card.cid, selectedIDs);
        if (temp['message'] === 'success') {
            this.getSFCards();
            //await this.helper.getSFCards();
            // this.helper.showSnackbarMessage('Combine Successful!');
            this.showSuccessModal = true;
            this.successModalTitle = 'Recharge Successful!';
            this.successModalMessage = 'Your relic has been recharged successfully.';
            this.close();
        } else {
            if (temp.message) this.helper.showSnackbarMessage(temp['message']);
            else this.helper.showSnackbarMessage('error');
        }
        this.data.setLoading(false);
    }

    async salvageRelics() {
        let nonSocket = this.selected.filter(x => { return x.slot !== 'relic' });
        if (nonSocket?.length > 0) {
            this.helper.showSnackbarMessage('may only salvage relics');
            return;
        }
        let selectedIDs = [...new Set(this.selected.map(c => c.cid))];
        if (!selectedIDs || selectedIDs.length < 1) {
            this.modal_title = 'No Cards Selected';
            this.message = 'No cards selected to burn.';
            this.show_modal = true;
            this.modal_mode='';
            // this.close();
            return;
        }
        if (selectedIDs.length !== this.selected.length) {
            this.modal_title = 'Duplicate Card Selected';
            this.message = 'Somehow you\'ve selected the same card more than once.';
            this.show_modal = true;
            this.modal_mode='';
            // this.close();
            return;
        }

        let usedCIDs = await this.helper.getUsedCardIDs(); // await this.helper.getUsedCards(this.cards, true);
        //console.log(usedCIDs);

        let usedCard = false;
        let burnValue = 0;
        this.selected.forEach(c => {
            if (usedCIDs.includes(c.cid)) usedCard = true;
            else {
                burnValue += c.charges.max * 10;
            }
        });
        if (usedCard) {
            this.modal_title = 'Equipped Cards';
            this.message = 'Sorry! You are not able to burn equipped cards.';
            this.show_modal = true;
            this.modal_mode='';
            // this.close();
            return;
        }
        this.burnType = 'FRAGS';
        this.type = 'confirm salvage';
        this.modal_title = 'Salvage Card';
        this.burn_value = burnValue; //this.getSCBurnValue();
        this.message = 'Salvage ' + this.selected.length + ' card points into ';
        this.show_burn_modal = true;
        this.modal_mode = 'confirmation';
        //this.helper.showSnackbarMessage('not implemented yet');
    }

    removeFromSellable(card) {
        if (card && card.cid) {
            this.sellable = this.sellable.filter(c => c.cid !== card.cid);
        }
    }
    removeFromSelling(card) {
        if (card && card.cid) {
            this.selling = this.selling.filter(c => c.cid !== card.cid);
        }
    }
    async transferCards() {
        if (this.config.mode === 'testServer') {
            this.helper.showSnackbarMessage('not available on test server');
            return;
        }
        if (this.receiver === this.helper.currentUser.username) {
            if (this.selected.length > 1) this.helper.showSnackbarMessage('BOOM!  Done!!  You are the proud still owner of these cards!!!');
            if (this.selected.length === 1) this.helper.showSnackbarMessage('BOOM!  Done!!  You are the proud still owner of this card!!!');
            return;
        }
        let onMarket = this.selected.filter(c => c.market_type === 'SELL');
        if (onMarket && onMarket.length > 0) {
            this.helper.showSnackbarMessage('Can not transfer cards that are listed on the market');
            return;
        }
        let selectedIDs = [...new Set(this.selected.map(c => c.cid))];
        if (!selectedIDs || selectedIDs.length < 1) {
            this.modal_title = 'No cards selected';
            this.modal_mode='';
            this.message = 'No cards selected to transfer.';
            this.show_modal = true;
            return;
        }
        if (selectedIDs.length !== this.selected.length) {
            this.message = 'Somehow selected same card more than once...';
            this.show_modal = true;
            this.modal_title = 'Duplicate Card Selected';
            this.modal_mode='';
            this.show_modal = true;
            return;
        }
        //let usedCIDs = await this.helper.getUsedCards(this.helper.mySFCards, true);
        let usedCard = false;
        //this.selected.forEach(c => {
        //    if (usedCIDs.includes(c.cid)) usedCard = true;
        //    this.selected.quantity = this.selected.quantity?this.selected.quantity+1:1
        //});
        if (usedCard) {
            this.message = 'Can not transfer equipped cards or cards with items in their sockets.';
            this.show_modal = true;
            this.modal_title = 'Could Not Transfer';
            this.modal_button = 'OK';
            this.modal_mode='';
            return;
        }
        this.transfer_card = true;
        // this.modal_title = 'Transfer Card';
        // this.type = 'confirm transfer';
        // this.message = 'Transfer ' + this.selected.length + ' card to ' + this.receiver;
        // this.show_burn_modal = true;
        // this.modal_mode = 'confirmation';
        //this.helper.showSnackbarMessage('not implemented yet');
    }

    async listSelected() {
        let mode = this.helper.getMode();
        if (!['live'].includes(mode)) {
            this.helper.showSnackbarMessage('Only available on live server.');
            return;
        }
        let num = this.numWithPrice('salable');
        if (num < 1) {
            this.helper.showSnackbarMessage('Must select something to sell');
            return;
        }

        if (confirm('You are about to list ' + num + ' cards on the market, are you sure?')) {
            let items = [];
            this.sellable.forEach(c => {
                let found = this.combinables.find(x => x.cid === c.cid);
                if (found && found.market_price >= 0.01) {
                    console.log(c.name + ' listing for $' + c.market_price.toFixed(3));
                    items.push([c.cid,c.market_price.toFixed(3)]);
                }
            })
            this.data.setLoading(true);
            let temp: any = await this.listItemsOnMarket(items);
            if (temp && temp.message === 'success') {
                let verifiedTx = await this.helper.verifyTx(temp['id'], 'saleList');
                if (!verifiedTx || verifiedTx['message'] !== 'success') { // can elaborate on why it didn't work from the market api if needed
                    alert('Could not verify transaction was processed yet, processing could be behind, please refresh if your cards do not apear on market.');
                    //return;
                } else {
                    this.helper.getSFCards();
                    this.helper.showSnackbarMessage('Your request has been processed.');
                }
                this.close();
            } else {
                this.helper.showSnackbarMessage('Error broadcasting transaction or Cancelled by user');
            }
            this.data.setLoading(false);
        }
    }
    async listItemsOnMarket(items) {
        let mode = this.helper.getMode();
        if (!['live'].includes(mode)) {
            this.helper.showSnackbarMessage('Only available on live server.');
            return;
        }
        let hive_keychain = window.hive_keychain;
        let username = this.helper.getCurrentUsername();
        if (!username) {
            this.helper.showSnackbarMessage('Username error...');
            return { message: 'error' };
        }
        if (!hive_keychain) {
            this.helper.showSnackbarMessage('Could not load keychain');
            console.log('no keychain');
            return;
        }
        var res = await new Promise(resolve => {
            hive_keychain.requestCustomJson(
                username, 
                'sf_sale_list', 
                'Active', 
                JSON.stringify({ items: items, market: 'sf' }),
                username + ' listing ' + items.length + ' cards on market', 
                response => {
                    resolve(response);
                });
            });
        return res['success'] ? { message: 'success', id: (res['result']['id'] ? res['result']['id'] : res['result']['tx_id']) } : { message: 'transaction cancelled by user' };
    }
    async cancelSelling() {
        let mode = this.helper.getMode();
        if (!['live'].includes(mode)) {
            this.helper.showSnackbarMessage('only available on live server');
            return;
        }
        if (this.selling.length < 1) {
            this.helper.showSnackbarMessage('Must be something to cancel');
            return;
        }

        if (confirm('You are about to cancel ' + this.selling.length + ' cards from the market, are you sure?')) {
            let items = [];
            this.selling.forEach(c => {
                console.log('cancelling: ' + c.market_id);
                items.push(c.market_id);
            })
            this.data.setLoading(true);
            let temp = await this.cancelItemsOnMarket(items);
            if (temp['message'] === 'success') {
                let verifiedTx = await this.helper.verifyTx(temp['id'], 'saleCancel');
                if (!verifiedTx || verifiedTx['message'] !== 'success') {
                    alert('Could not verify transaction was processed yet, processing could be behind, please refresh if your cards still apear on market.');
                    //return;
                } else {
                    this.helper.getSFCards();
                    this.helper.showSnackbarMessage('Your request has been processed.');
                }
                this.close();
            } else {
                this.helper.showSnackbarMessage('Error broadcasting transaction or Cancelled by user');
            }
            this.data.setLoading(false);
        }
    }
    async cancelItemsOnMarket(items) {
        let mode = this.helper.getMode();
        if (!['live'].includes(mode)) {
            this.helper.showSnackbarMessage('Only available on live server.');
            return;
        }
        let hive_keychain = window.hive_keychain;
        let username = this.helper.getCurrentUsername();
        if (!username) {
            this.helper.showSnackbarMessage('Username error...');
            return { message: 'error' };
        }
        if (!hive_keychain) {
            this.helper.showSnackbarMessage('Could not load keychain');
            console.log('no keychain');
            return;
        }
        var res = await new Promise(resolve => {
            hive_keychain.requestCustomJson(
                username, 
                'sf_sale_cancel', 
                'Active', 
                JSON.stringify({ items: items, market: 'sf' }),
                username + ' cancelling ' + items.length + ' cards on market', 
                response => {
                    resolve(response);
                });
            });
        return res['success'] ? { message: 'success', id: (res['result']['id'] ? res['result']['id'] : res['result']['tx_id']) } : { message: 'transaction cancelled by user' };
    }
    async updateSale() {
        let mode = this.helper.getMode();
        let num = this.numWithPrice('selling');
        if (!['live'].includes(mode)) {
            this.helper.showSnackbarMessage('only available on live server');
            return;
        }
        if (num < 1) {
            this.helper.showSnackbarMessage('Must be something to update');
            return;
        }

        if (confirm('You are about to update ' + num + ' cards from the market, are you sure?')) {
            let items = [];
            this.selling.forEach(c => {
                let found = this.baseSelling.find(x => x.cid === c.cid);
                if (found && found.market_price !== c.market_price) {
                    console.log(c.name + ' updating to $' + c.market_price.toFixed(3));
                    items.push([c.market_id,c.market_price.toFixed(3)]);
                }
            })
            this.data.setLoading(true);
            let temp = await this.updateItemsOnMarket(items);
            if (temp['message'] === 'success') {
                let verifiedTx = await this.helper.verifyTx(temp['id'], 'saleUpdate');
                if (!verifiedTx || verifiedTx['message'] !== 'success') {
                    alert('Could not verify transaction was processed yet, processing could be behind, please refresh if your cards still apear on market.');
                    //return;
                } else {
                    this.helper.getSFCards();
                    this.helper.showSnackbarMessage('Your request has been processed.');
                }
                this.close();
            } else {
                this.helper.showSnackbarMessage('Error broadcasting transaction or Cancelled by user');
            }
            this.data.setLoading(false);
        }
    }
    async updateItemsOnMarket(items) {
        let mode = this.helper.getMode();
        if (!['live'].includes(mode)) {
            this.helper.showSnackbarMessage('Only available on live server.');
            return;
        }
        let hive_keychain = window.hive_keychain;
        let username = this.helper.getCurrentUsername();
        if (!username) {
            this.helper.showSnackbarMessage('Username error...');
            return { message: 'error' };
        }
        if (!hive_keychain) {
            this.helper.showSnackbarMessage('Could not load keychain');
            console.log('no keychain');
            return;
        }
        var res = await new Promise(resolve => {
            hive_keychain.requestCustomJson(
                username, 
                'sf_sale_update', 
                'Active', 
                JSON.stringify({ items: items, market: 'sf' }),
                username + ' cancelling ' + items.length + ' cards on market', 
                response => {
                    resolve(response);
                });
            });
        return res['success'] ? { message: 'success', id: (res['result']['id'] ? res['result']['id'] : res['result']['tx_id']) } : { message: 'transaction cancelled by user' };
    }
    unequip() {
        this.modal_title = 'Unequip Card';
        this.message = 'Would you like to unequip ' + this.equipped.name;
        this.type = 'confirm unequip';
        this.modal_mode = 'confirmation';
        this.show_modal = true;
        return;
    }
}