import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'period'
})
export class PeriodPipe implements PipeTransform {
	transform(value: any, args?: any): any {
		if(value[value.length-1]!='.'){
			return value+'.';
		} else {
			return value;
		}
	}
}