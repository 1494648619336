export class User {
    username: string;
    createdDate: Date;
    inGameAssets: Asset[];
    stamina: {
        current: number;
        max: number;
        last: Date;
    };
    sc: { balance: number, transactions: [] };
    lastLogin: Date;
    token: string;
    serverTime: Date;
    stats: {};
    currentClass: string;
    currentHero: string;
    warrior: {
        weapon: string,
        offhand: string,
        head: string,
        upperBody: string,
        lowerBody: string,
        hands: string,
        feet: string,
        ring: string,
        cloak: string,
    };
    wizard: {
        weapon: string,
        offhand: string,
        head: string,
        body: string,
        hands: string,
        feet: string,
        ring: string,
        bracelet: string,
        cloak: string,
    };
    ranger: {
        weapon: string,
        head: string,
        upperBody: string,
        lowerBody: string,
        hands: string,
        feet: string,
        ring: string,
        necklace: string,
        cloak: string,
    };
    heroes: {
        weapon: [],
        offhand: [],
        head: [],
        necklace: [],
        body: [],
        hands: [],
        ring: [],
        legs: [],
        feet: [],
        back: []
    };
    openings: any;
    flags: any;
    uniqueRules: {};
    electrum: number;
    settings: any;
    xp: number;
    essence: number;
    frags: number;
    newHeroes: any;
}

export class Asset {
    type: string;
    name: string;
    qty: number;
    max: number;
    description: string;
    symbol: string;
}

export class Transaction {
    type: string;
    amount: number;
    date: Date;
}
