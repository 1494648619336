import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { fade, flip_up, pointer } from '../../app.animations';
import { HelperService } from '@app/_services/helper.service';
@Component({
  selector: 'equip-card',
  animations: [fade, flip_up, pointer],
  templateUrl: './equip.component.html',
  styleUrls: ['./equip.component.styl']
})
export class EquipComponent implements OnInit {
	constructor(private helper: HelperService) { }
	@Output('done') done = new EventEmitter();  
	@Output('doAction') doAction = new EventEmitter();
	@Input('show') show = false;
	@Input('cards') cards: any;
	@Input('equipped') equipped: any;
	@Input('equippable') equippable: any;
    @Input('selectedHero') selectedHero: any;
	equip_card: any;
	modal_title: string = '';
	message: string;
	type: string;
	modal_mode: string = 'confirmation';
	show_modal = false;
	modal_button = 'Close';

	ngOnInit() {
		//console.log(this.equipped, this.equippable);
	}

	close() {
		this.done.emit(1);
	}

	equipCard(c) {
        this.equip_card = c;
        this.modal_title = 'Equip Card';
        this.message = 'Would you like to equip ' + this.equip_card.name;
        this.type = 'confirm equip';
        this.modal_mode = 'confirmation';
        this.show_modal = true;
        return;
	}

	unequipCard() {
        this.modal_title = 'Unequip Card';
        this.message = 'Would you like to unequip ' + this.equipped.name;
        this.type = 'confirm unequip';
        this.modal_mode = 'confirmation';
        this.show_modal = true;
        return;
	}

	async modal_done(e) {
		let event = e;
		let temp: any = { message: 'success' };
        //console.log(this.selectedHero);
		if (event === 'confirm equip') {
            let temp: any = await this.helper.equipCardOnHero(this.equip_card.cid, this.selectedHero.id);
            if (temp?.message === 'success') {
                let corrected = this.equip_card.slot;
                if (corrected.indexOf('eapon') > -1) corrected = 'weapon';
                else if (corrected.indexOf('ffhand') > -1) corrected = 'offhand';
                this.helper.showSnackbarMessage('Successfully equipped ' + this.equip_card.name);
				this.doAction.emit({ type: 'equip', card: this.equip_card.cid, slot: corrected });
				this.done.emit();
            } else {
                //console.log(temp);
                this.helper.showSnackbarMessage(temp['message']);
            }
		} else if (event === 'confirm unequip') {
            console.log(this.equipped);
            let corrected = this.equipped.slot;
            if (corrected.indexOf('eapon') > -1) corrected = 'weapon';
            else if (corrected.indexOf('ffhand') > -1) corrected = 'offhand';
            let temp: any = await this.helper.unequipCardFromHero(corrected, this.selectedHero.id);
            if (temp?.message === 'success') {
				this.doAction.emit({ type: 'unequip', slot: corrected });
				this.done.emit();
            } else {
                //console.log(temp);
                this.helper.showSnackbarMessage(temp['message']);
            }
		}
	}
}