import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class FetchService {

    //slCardDetailsURL = 'https://api2.splinterlands.com/cards/get_details';
    slCardDetailsURL = 'https://api.splinterlands.com/cards/get_details';
    //slCardDetailsURL = 'https://qa.splinterlands.io/cards/get_details';
    //slCollectionURL = 'https://api2.splinterlands.com/cards/collection/';
    slCollectionURL = 'https://api.splinterlands.com/cards/collection/';
    slSkinsURL = 'https://api2.splinterlands.com/players/skins?username=';
    slPlayerDetail = 'https://api2.splinterlands.com/players/details?name=';
    slPricesURL = 'https://prices.splinterlands.com/prices';
    slBalances = 'https://api2.splinterlands.com/players/balance?username=';

    constructor(private http: HttpClient) {  }

    loadSLBalances(username) {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.slBalances+username)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    loadPrices() {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.slPricesURL)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    loadSLCollection(username) {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.slCollectionURL + username)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    loadSLCardDetailsOLD() {
        let promise = new Promise((resolve, reject) => {
            this.http.get('../assets/details.json')
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    loadSLCardDetails() {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.slCardDetailsURL)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    loadSkins(username) {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.slSkinsURL + username)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }

    verifySLUser(username) {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.slPlayerDetail + username)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
        return promise;
    }
}