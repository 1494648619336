<section class="forgemodal" [@fade]="show">
	<div class="boxwrap" [@flip_up]="show">
		<h5>
            Leaderboards 
            <span><button class="button purple" (click)="changeLB(1)">Bronze</button></span>
            <span><button class="button purple" (click)="changeLB(2)">Silver</button></span>
            <span><button class="button purple" (click)="changeLB(3)">Gold</button></span>
            <span><button class="button purple" (click)="changeLB(4)">Diamond</button></span>
        </h5>
		<div class="itemwrapper" *ngIf="leaderboards[tier].length > 0 && !lbExpired">
            <h5>{{tier===4?'Diamond':tier===3?'Gold':tier===2?'Silver':'Bronze'}} leaderboard</h5>
            <p *ngIf="!nextLB">Ends on: {{endTime | date:'long'}}</p>
            <p *ngIf="nextLB">This LB ended on: {{endTime | date:'long'}}</p>
            <p *ngIf="nextLB" title="Next leaderboard is created by the first fight in this tier after this date.">Next LB: {{nextLB | date:'long'}}</p>
            <h5>Reward Pool: {{xpPool}} XP</h5>
            <div *ngIf="player">
                <h5>You have: {{player.points}} pts</h5>
            </div>
            <div class="leader_list">
                <div *ngFor="let pos of leaderboards[tier]; let i = index">
                    #{{i+1}}. {{pos.player}}: {{pos.points}} Points - ~Reward: {{pos.reward}} XP & {{pos.ele}} Electrum
                </div>
            </div>
            <button class="button purple" (click)="close()">Close</button>
		</div>
        <div class="itemwrapper" *ngIf="leaderboards[tier].length === 0 || lbExpired">
            <h5>There is no current leaderboard for this tier.</h5>
            <p>A leaderboard for this tier will be created when the first battle is sent.</p>
            <button class="button purple" (click)="close()">Close</button>
		</div>
		<div class="closewrapper" (click)="close()"><i class="fa-solid fa-x"></i></div>
    </div>
	<div class="backdrop" [@pointer]="show" (click)="close()"></div>
</section>