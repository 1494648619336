import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { HelperService } from '@app/_services/helper.service';
import { fade } from '../app.animations';
@Component({
  selector: 'app-airdrops',
  animations: [ fade ],
  templateUrl: './airdrops.component.html',
  styleUrls: ['./presale.component.styl', './airdrops.component.styl']
})
export class AirdropsComponent implements OnInit, OnDestroy {

  constructor(private helper: HelperService) { }
  show_name:boolean;
  userAirdropInfo: any;
  alphaBagAirdrops: any;
  alphaBagStats: any;
  alphaCrateAirdrops: any;
  presaleStats: any;
  alphaCrateStats: any;
  alphaExclusive: any;
  star_fader: any;
  AirdropResult = {};
  AirdropAvailable = {};
  EligibleToClaim = {};
  Minimums = {};
  claim_result: string;
  show_success: boolean;
  show_notice: boolean;
  claiming: boolean;
  airFlip: string;
  Astar = [];
  Cstar = [];
  @Output('hide') hide = new EventEmitter();
  close_overlay() { this.hide.emit(1); }
  ngOnDestroy() {
    clearInterval(this.star_fader);
  }
  stars(i=0){
    setTimeout(()=>{
      this.Astar[i] = this.Astar[i] ? false : true;
      if(i<18)this.stars(i+1);
    },50);
  }
  stars_center(i=0){
    setTimeout(()=>{
      this.Cstar[i] = this.Cstar[i] ? false : true;
      if(i<18)this.stars_center(i+1);
    },20);
  }
  fade_stars() {
    this.stars();
    setTimeout(()=>{this.stars();},900);
  }
  fade_stars_center() {
    this.stars_center();
    setTimeout(()=>{this.stars_center();},400);
  }
  ngOnInit(): void {
    if (!this.userAirdropInfo) this.getUserAirdropInfo();
  }
  show_stars() {
    this.airFlip='animate';
    setTimeout(()=>{this.airFlip='done'},2000);
    this.star_fader= setInterval(()=>{
      this.fade_stars();
    },7200);
  }
  async claim(type) {
    this.claiming = true;
    let temp = await this.helper.claimAirdrop(type);
    //console.log(temp);
    if (temp['message'] === 'success') {
      // show succes of how many user got
      this.show_success = true;
      await this.getUserAirdropInfo();
      this.claim_result = 'You received ' + temp['regular'] + ' regular cards and ' + temp['foils'] + ' gold foils.';
      this.claiming = false;
    } else {
      this.show_notice = true;
      this.claim_result = 'Sorry, this airdrop could not claimed.';
      this.claiming = false;
    }
  }
  close_success() {
    this.show_success = false;
  }
  close_notice() {
    this.show_notice = false;
  }
  async getPackStats() {
    this.presaleStats = await this.helper.getPackStats('presale');
    this.alphaCrateStats = await this.helper.getPackStats('CRATE');
    this.alphaBagStats = await this.helper.getPackStats('BAG');
    //console.log(this.alphaCrateStats, this.alphaBagStats);
  }
  isAirdropAvailable(type) {
    return this.helper.config ? this.helper.config.availableAirdrops.includes(type) : false;
  }
  isEligibleToClaim(type) {
    if (type.indexOf('crate') > -1 && this.alphaCrateStats.user < 1) return false;
    if (type.indexOf('bag') > -1 && this.alphaBagStats.user < 1) return false;
    if (type.indexOf('exclusive') > -1 && this.presaleStats.user < 1) return false;
    let found = this.userAirdropInfo?.claims ? this.userAirdropInfo.claims.find(c => c.type === type) : false;
    if (found) found = true;
    return !found;
  }
  getAirdropResult(type) {
      let found = this.userAirdropInfo?.claims ? this.userAirdropInfo.claims.find(c => c.type === type) : false;
      if (!found) return false; // 'data missing...';
      else {
          return 'You received ' + (found.qty-found.foils) + ' regular & ' + (found.foils < 1 ? ' no foils.' : found.foils + ' Gold Foils!');
      }
  }
  async getUserAirdropInfo() {
      await this.getPackStats();
      this.userAirdropInfo = await this.helper.getUserAirdropInfo();
      if(this.helper.config) {
        this.alphaExclusive = this.helper.config.alphaExclusive;
        this.alphaCrateAirdrops = this.helper.config.airdropCards;
        this.alphaBagAirdrops = this.helper.config.gemAirdropCards;
        let guarantees = this.helper.config.airdropGuarantees;
        for(let i = 0; i < this.alphaCrateAirdrops.length; i++) {
          this.Minimums['alpha crate '+(i+1)] = this.alphaCrateStats.user >= guarantees[i] ? Math.floor(this.alphaCrateStats.user/guarantees[i]) : 0;
          this.AirdropAvailable['alpha crate '+(i+1)] = this.isAirdropAvailable('alpha crate '+(i+1));
          this.EligibleToClaim['alpha crate '+(i+1)] = this.isEligibleToClaim('alpha crate '+(i+1));
          this.AirdropResult['alpha crate '+(i+1)] = this.getAirdropResult('alpha crate '+(i+1));
        }
        for(let i = 0; i < this.alphaBagAirdrops.length; i++) {
          this.Minimums['alpha bag '+(i+1)] = this.alphaBagAirdrops.user >= guarantees[i] ? Math.floor(this.alphaBagAirdrops.user/guarantees[i]) : 0;
          this.AirdropAvailable['alpha bag '+(i+1)] = this.isAirdropAvailable('alpha bag '+(i+1));
          this.EligibleToClaim['alpha bag '+(i+1)] = this.isEligibleToClaim('alpha bag '+(i+1));
          this.AirdropResult['alpha bag '+(i+1)] = this.getAirdropResult('alpha bag '+(i+1));
        }
        this.Minimums['alpha exclusive'] = this.presaleStats.user > 49 ? Math.floor(this.presaleStats.user/50) : 0;
        this.AirdropAvailable['alpha exclusive'] = this.isAirdropAvailable('alpha exclusive');
        this.EligibleToClaim['alpha exclusive'] = this.isEligibleToClaim('alpha exclusive');
        this.AirdropResult['alpha exclusive'] = this.getAirdropResult('alpha exclusive');
        //console.log(this.AirdropAvailable, this.EligibleToClaim, this.AirdropResult);
      }
  }
}
