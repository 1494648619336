import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class CardService {

    apiUrl = environment.apiUrl;

    constructor(private http: HttpClient) {  }

    addSocketToItem(params): any {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/card/addSocket', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }

    getCardsByRarityType(user, rarity, type): any {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.apiUrl + '/card/getCardsByRarity?user=' + user + '&rarity=' + rarity + '&type=' + type)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }

    getOpenings(user): any {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.apiUrl + '/card/getOpenings?user=' + user)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }

    getOpening(txID): any {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.apiUrl + '/card/getOpening?tx=' + txID)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }

    getReforgeHistory(user): any {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.apiUrl + '/card/getReforgeHistory?user=' + user)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }

    getTransmuteHistory(user): any {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.apiUrl + '/card/getTransmuteHistory?user=' + user)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }

    getSFCards(user): any {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.apiUrl + '/card/getSFCards?user=' + user)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }

    burnCards(params): any {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/card/burnCards', params)
                .toPromise().then(res => {
                    //console.log(res);
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }

    claimAirdrop(params): any {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/card/claimAirdrop', params)
                .toPromise().then(res => {
                    //console.log(res);
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }

    combineCards(params): any {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/card/combineCards', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }

    conjureRelic(params): any {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/card/conjureRelic', params)
                .toPromise().then(res => {
                    //console.log(res);
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }

    imbueCard(params): any {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/card/imbueCard', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }

    openPacks(params): any {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/card/openPacks', params)
                .toPromise().then(res => {
                    console.log(res);
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }

    rechargeRelic(params): any {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/card/rechargeRelic', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }

    reforgeCards(params): any {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/card/reforgeCards', params)
                .toPromise().then(res => {
                    console.log(res);
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }

    salvageRelics(params): any {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/card/salvageRelics', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }
    
    equipCard(params): any {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/card/equipCard', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }

    unequipCard(params): any {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/card/unequipCard', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }

    emptySockets(params): any {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/card/emptySockets', params)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }

    cardDetails(): any {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.apiUrl + '/data/cardDetails')
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }

    toggleFavorite(u, id, t): any {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.apiUrl + '/card/toggleFavorite?user=' + u + '&id=' + id + '&token=' + t)
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }
}
