import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { trigger, state, animate, transition, style } from '@angular/animations';
export const fade = trigger('fade', [
    state('true', style({
		'opacity': 1
    })),
    state('false', style({
    	'opacity': 0,
        'pointer-events': 'none'
    })),
    transition('*=>*', animate('270ms'))
]);
export const fade_long = trigger('fade_long', [
    state(':enter', style({
        'opacity': 1
    })),
    state(':leave', style({
        'opacity': 0,
        'pointer-events': 'none'
    })),
    transition('*=>*', animate('270ms 2.4s'))
]);
export const scale_90 = trigger('scale_90', [
    state('true', style({
        'transform': 'scale(1)'
    })),
    state('false', style({
        'transform': 'scale(0.89)'
    })),
    transition('*=>*', animate('270ms'))
]);
export const selected_team = trigger('selected_team',[
    state('true', style({
        'transform': 'translateY(-7px)',
    })),
    state('off', style({
        'transform': 'translateY(0px)',
    })),
    state('false', style({
        'transform': 'translateY(0px)',
    })),
    transition('*=>true', animate('170ms')),
    transition('true=>*', animate('220ms')),
])
export const enter_down = trigger('enter_down', [
    state('true', style({
        'top': '53px',
        'opacity': 1,
        'position': 'relative'
    })),
    state('false', style({
        'top': '0px',
        'opacity': 0,
        'position': 'relative'
    })),
    transition('*=>*', animate('470ms 300ms ease-out'))
]);
export const display_animation = trigger('display_animation', [
    state('true', style({
        'opacity': 1, 
        'display': 'inline-block'
    })),
    state('false', style({
        'opacity': 0,
        'display': 'none'
    })),
    transition('*=>*', animate('270ms'))
]);
export const width_in = trigger('width_in', [
    state('true', style({
        'width': '24px',
        'margin': '0 5px !important'
    })),
    state('false', style({
        'width': '0px',
        'margin': '0 0px'
    })),
    transition('*=>*', animate('300ms'))
]);
export const display_block = trigger('display_block', [
    state('true', style({
        'display': 'block'
    })),
    state('false', style({
        'display': 'none'
    })),
    transition('*=>*', animate('270ms'))
]);
export const carddropdown = trigger('carddropdown', [
    state('true', style({
        'display': 'block'
    })),
    state('false', style({
        'display': 'none'
    })),
    transition('*=>*', animate('270ms'))
]);
export const flip = trigger('flip', [
    state('true', style({
    	'transform': 'rotateX(180deg)'
    })),
    state('false', style({
		'transform': 'rotateX(0deg)'
    })),
    transition('*=>*', animate('270ms'))
]);
export const scale_in = trigger('scale_in', [
    state('true', style({'transform': 'scale(0)'})),
    state('false', style({'transform': 'scale(1)'})),
    transition('true=>false', animate('940ms ease-out')),
    transition('false=>true', animate('0ms ease-out')),
]);
export const black_background = trigger('black_background', [
    state('true', style({
        'background': 'black'
    })),
    state('false', style({
        'background': 'transparent'
    })),
    transition('*=>*', animate('1300ms'))
]);
export const flip_shadow = trigger('flip_shadow', [
    state('true', style({
        'transform': 'rotateX(180deg)',
        'text-shadow': '3px -2px 0px #000'
    })),
    state('false', style({
        'transform': 'rotateX(0deg)',
        'text-shadow': '3px 2px 0px #000'
    })),
    transition('*=>*', animate('270ms'))
]);
export const flip_up = trigger('flip_up', [
    state('true', style({
        'opacity': 1,
        'pointer-events': 'all',
        'transform': 'translateY(0px)'
    })),
    state('false', style({
        'opacity': 0,
        'pointer-events': 'none',
        'transform': 'translateY(5px)'
    })),
    transition('*=>*', animate('270ms'))
]);

export const intro_right = trigger('intro_right', [
    state('true', style({
        'right': 'calc(48% - 500px)'
    })),
    state('false', style({
        'right': '-150%'
    })),
    transition('*=>*', animate('900ms cubic-bezier(0, 0, 0, 1)'))
]);
export const intro_left = trigger('intro_left', [
    state('true', style({
        'left': 'calc(48% - 136px)'
    })),
    state('false', style({
        'left': '-600px'
    })),
    transition('*=>*', animate('2300ms cubic-bezier(0, 0, 0, 1)'))
]);
export const pointer = trigger('pointer', [
    state('true', style({
        'pointer-events': 'all',
    })),
    state('false', style({
        'pointer-events': 'none',
    }))
]);
export const overflow = trigger('overflow', [
    state('true', style({
        'overflow': 'visibile',
    })),
    state('false', style({
        'overflow': 'hidden',
    })),
    transition('true=>false', animate('0ms')),
    transition('false=>true', animate('0ms 400ms'))
]);
export const filters_show = trigger('filters_show', [
    state('true', style({
        'max-height': '200px',
        'opacity': 1
    })),
    state('false', style({
        'max-height': '0px',
        'opacity': 0
    })),
    transition('*=>*', animate('320ms ease-in-out'))
]);
export const boxlarge = trigger('boxlarge', [
    state('true', style({'height': '480px'})),
    state('amount', style({'height': '412px'})),
    state('transfer', style({'height': '420px'})),
    state('false', style({'height': '412px'})),
    transition('*=>*', animate('220ms'))
]);
export const boxsmaller = trigger('boxsmaller',[
    state('true', style({
        'height': '385px'
    })),
    state('false', style({
        'height': '444px'
    })),
    transition('*=>*', animate('120ms ease-in-out'))
])
export const shop_category = trigger('shop_category', [
    state('true', style({
        'display': 'flex'
    })),
    state('false', style({
        'display': 'none'
    })),
    transition('*=>*', animate('0ms'))
]);
export const display_inline_block = trigger('display_inline_block', [
    state('true', style({
        'display': 'inline-block'
    })),
    state('false', style({
        'display': 'none'
    }))
]);
export const sidedown = trigger('sidedown', [
    state('true', style({
        'max-height': '500px',
        'opacity': '1'
    })),
    state('false', style({
        'max-height': '0px',
        'opacity': '0'
    })),
    transition('*=>*', animate('200ms ease-in-out'))
]);
export const airdrop_sidedown = trigger('airdrop_sidedown', [
    state('true', style({
        'max-height': '1000px',
        'margin': '10px 14px 12px 14px',
        'opacity': '1'
    })),
    state('false', style({
        'max-height': '0px',
        'opacity': '0'
    })),
    transition('*=>*', animate('200ms ease-in-out'))
]);
export const cat_underline = trigger('cat_underline', [
    state('true', style({
        'opacity': 1
    })),
    state('false', style({
        'opacity': 0
    })),
    transition('*=>*', animate('450ms ease-in-out'))
]);
export const scale_pop = trigger('scale_pop', [
    state('true', style({
        'transform': 'scale(1.05)'
    })),
    state('false', style({
        'transform': 'scale(1)'
    })),
    transition('*=>*', animate('250ms ease-in-out'))
]);
export const field_error = trigger('field_error', [
    state('true', style({
        'border': '1px solid red'
    })),
    transition('*=>*', animate('250ms ease-in-out'))
]);