<section [attr.class]="'forgemodal '+modal_color" [@fade]="show">
    <div class="boxwrap" [@flip_up]="show">
		<h1 [innerHTML]="title"></h1>
		<div class="bottom_border"></div>
      	<div class="background_area"></div><div class="background_area_2"></div>
		<div>
			<h2 *ngIf="subtitle!=''" [innerHTML]="subtitle"></h2>
			<p [innerHTML]="label"></p>
			<div class="button_group">
				<button (click)="close()" tabindex="1" class="button teal" title="Dismiss Window">{{ok}}</button>
				<div class="clear"></div>
			</div>
		</div>
		<div class="closewrapper" (click)="close()" tabindex="2" title="Close Window">
			<i class="fa-solid fa-x"></i>
		</div>
	</div>
	<div class="backdrop" [@pointer]="show" (click)="close()"></div>
</section>