import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {
	transform(value: any, args?: any): any {
		let values = '';
		if(value){
			values = value[0].toUpperCase();
			values = values + value.substring(1);
		}
		return values;
	}
}