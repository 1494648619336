<div [attr.class]="small?extra_small?'extra_small':'small':'large' + (show_stats?' show_stats':'')  + (center?' center':'') + (hero?' hero':'')">
	<div [attr.title]="showTitles?'Mana':''" [attr.class]="(color['mana']?color['mana']:'')+(active['mana']?' active':'')+' stat mana'" [@popin]="(display_mana)?'true':'false'" *ngIf="show_mana">
		<div class="icon">
			<img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/stat_bg_mana.png" loading="lazy">
		</div>
		<div class="overlay" *ngIf="(mana | typeof) !== 'number'">
			<span *ngIf="mana=='∞'"><i class="fa-solid fa-infinity"></i></span>
		</div>
		<div class="overlay" *ngIf="(mana | typeof) === 'number'">{{mana}}</div>
		<div class="overlay" *ngIf="(!mana)">0</div>
		<div *ngIf="active['mana'] && change['mana']" [attr.class]="change['mana']<0?'floater':'floater green'">{{change['mana']>0?'+':''}}{{change['mana']}}</div>
	</div>
	<div [attr.title]="showTitles?'Speed':''" [attr.class]="(color['speed']?color['speed']:'')+(active['speed']?' active':'')+' stat speed' + (adjust_speed?' adj':'')" [@popin]="(display_speed)?'true':'false'"  [@fade]="fade_stats" [@active_stat]="active_speed">
		<div class="icon">
			<img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/speed.png" loading="lazy">
		</div>
		<div class="overlay" *ngIf="(speed | typeof) !== 'number'" [@active_overlay]="active_speed">
			<span *ngIf="speed=='∞'"><i class="fa-solid fa-infinity"></i></span>
			<span *ngIf="speed!='∞'">{{speed|slice:0:1}}</span>
		</div>
		<div class="overlay" *ngIf="(speed | typeof) === 'number'" [@active_overlay]="active_speed">{{speed}}</div>
		<div *ngIf="active['speed'] && change['speed']" [attr.class]="change['speed']<0?'floater':'floater green'">{{change['speed']>0?'+':''}}{{change['speed']}}</div>
	</div>
	<div [attr.title]="showTitles?'Melee':''" [attr.class]="(color['attack']?color['attack']:'')+(active['attack']?' active':'')+' stat melee' + (adjust_melee?' adj':'')" [@popin]="(display_attack)?'true':'false'" [@fade]="fade_stats" [@active_stat]="active_melee">
		<div class="icon">
			<img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/melee-attack.png" loading="lazy">
		</div>
		<div class="overlay" *ngIf="(attack | typeof) !== 'number'" [@active_overlay]="active_melee">
			<span *ngIf="attack=='∞'"><i class="fa-solid fa-infinity"></i></span>
			<span *ngIf="attack!='∞'">{{attack|slice:0:1}}</span>
		</div>
		<div class="overlay" *ngIf="(attack | typeof) === 'number'" [@active_overlay]="active_melee">{{attack}}</div>
		<div *ngIf="active['attack'] && change['attack']" [attr.class]="change['attack']<0?'floater':'floater green'">{{change['attack']>0?'+':''}}{{change['attack']}}</div>
	</div>
	<div [attr.title]="showTitles?'Magic':''" [attr.class]="(color['magic']?color['magic']:'')+(active['magic']?' active':'')+' stat magic' + (adjust_magic?' adj':'')" [@popin]="(display_magic)?'true':'false'" [@fade]="fade_stats" [@active_stat]="active_magic">
		<div class="icon">
			<img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/magic-attack.png" loading="lazy">
		</div>
		<div class="overlay" *ngIf="(magic | typeof) !== 'number'" [@active_overlay]="active_magic">
			<span *ngIf="magic=='∞'"><i class="fa-solid fa-infinity"></i></span>
			<span *ngIf="magic!='∞'">{{magic|slice:0:1}}</span>
		</div>
		<div class="overlay" *ngIf="(magic | typeof) === 'number'" [@active_overlay]="active_magic">{{magic}}</div>
		<div *ngIf="active['magic'] && change['magic']" [attr.class]="change['magic']<0?'floater':'floater green'">{{change['magic']>0?'+':''}}{{change['magic']}}</div>
	</div>
	<div [attr.title]="showTitles?'Ranged':''" [attr.class]="(color['ranged']?color['ranged']:'')+(active['ranged']?' active':'')+' stat ranged' + (adjust_ranged?' adj':'') + (adjust_ranged_2?' adj2':'')" [@active_stat]="active_magic" [@popin]="(display_ranged)?'true':'false'" [@fade]="fade_stats">
		<div class="icon">
			<img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/ranged-attack.png" loading="lazy">
		</div>
		<div class="overlay" *ngIf="(ranged | typeof) !== 'number'" [@active_overlay]="active_magic">
			<span *ngIf="ranged=='∞'"><i class="fa-solid fa-infinity"></i></span>
			<span *ngIf="ranged!='∞'">{{ranged|slice:0:1}}</span>
		</div>
		<div class="overlay" *ngIf="(ranged | typeof) === 'number'" [@active_overlay]="active_magic">{{ranged}}</div>
		<div *ngIf="active['ranged'] && change['ranged']" [attr.class]="change['ranged']<0?'floater':'floater green'">{{change['ranged']>0?'+':''}}{{change['ranged']}}</div>
	</div>
	<div [attr.title]="showTitles?'Health':''" [attr.class]="(color['health']?color['health']:'')+(active['health']?' active':'')+' stat health'" [@popin]="(display_health)?'true':'false'" [@fade]="fade_stats" [@active_stat]="active_health" *ngIf="show_health">
		<div class="icon">
			<img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/health.png" loading="lazy">
		</div>
		<div class="overlay" *ngIf="(health | typeof) !== 'number'" [@active_overlay]="active_health">
			<span *ngIf="health=='∞'"><i class="fa-solid fa-infinity"></i></span>
			<span *ngIf="health!='∞'">{{health>0?(health|slice:0:1):0}}</span>
		</div>
		<div class="overlay percentage" *ngIf="showog && og_health && (health | typeof) === 'number'" [@active_overlay]="active_health">
			{{(health/og_health)*100 | number:'1.0-0'}}%
		</div>
		<div class="overlay" *ngIf="!showog && (health | typeof) === 'number' || health == 0" [@active_overlay]="active_health">
			{{health>0?health:0}}
		</div>
		<div *ngIf="active['health'] && change['health']" [attr.class]="change['health']<0?'floater':'floater green'">{{change['health']>0?'+':''}}{{change['health']}}</div>
	</div>
	<div [attr.title]="showTitles?'Armor':''" [attr.class]="(color['armor']?color['armor']:'')+(active['armor']?' active':'')+' stat armor'" [@popin]="(display_armor)?'true':'false'" [@active_stat]="active_armor" [@fade]="fade_stats" *ngIf="show_armor">
		<div class="icon">
			<img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/armor.png" loading="lazy">
		</div>
		<div class="overlay" *ngIf="(armor | typeof) !== 'number'" [@active_overlay]="active_armor">
			<span *ngIf="armor=='∞'"><i class="fa-solid fa-infinity"></i></span>
			<span *ngIf="armor!='∞'">{{armor>0?(armor|slice:0:1):0}}</span>
		</div>
		<div class="overlay percentage" *ngIf="showog && og_armor && (armor | typeof) === 'number'" [@active_overlay]="active_armor">
			{{(armor/og_armor)*100 | number:'1.0-0'}}%
		</div>
		<div class="overlay" *ngIf="!showog && (armor | typeof) === 'number'" [@active_overlay]="active_armor">
			{{armor>0?armor:0}}
		</div>
		<div *ngIf="active['armor'] && change['armor']" [attr.class]="change['armor']<0?'floater':'floater green'">{{change['armor']>0?'+':''}}{{change['armor']}}</div>
	</div>
</div>