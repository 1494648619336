import { Component, OnInit, AfterViewInit, Injectable } from '@angular/core';
import { User } from '@app/_models';
import { DataService } from '@app/_services';
import { HelperService } from '@app/_services/helper.service';
import { flip } from '../app.animations';
import { stats, dropdown } from './hero.animations';
@Injectable({ providedIn: 'root' })
@Component({
    templateUrl: 'hero.component.html', 
    styleUrls: ['./hero.component.styl'],
    animations: [stats, flip, dropdown]
})
export class HeroComponent implements OnInit, AfterViewInit {
    heroCards = [];
    currentHero: any = null;
    statView = false;
    levelDetails: any;
    skills: any;
    viewSkills = false;
    filteredSkills = [];
    showBurnForXP = false;
    burnForXPQty: number;
    options = false;
    showNameChange = false;
    newName: string;
    showNewLoadOut = false;
    newLoadOutName: string;
    showCurrentLoadOut = false;
    currentLoadOut: string;
    view = 'heroView';
    leaders: any;
    qtyToReset: number;
    skillResetDetails: any;

    sidebar = true;
    heading = true;
    equippedCards: any = null;
    equippable: any = null;
    user: User;
    equippedCIDs: any = null;
    equipped: any = null;
    loading: Boolean = false;
    viewCard: boolean;
    maxMana = 45;
    maxMonsters = 7;
    sockets: any = [];
    heroAbilities: any = [];
    selected_slot: any;
    show_types: boolean;
    show_styles: boolean;
    loaded_cards: boolean;
    hasHeroAbilities = false;
    show_stats = true;
    message: string;
    mana: number;
    attack: number;
    magic: number;
    ranged: number;
    speed: number;
    armor: number;
    health: number;
    img = [];
    img_enabled = [];
    equippedItem = [];
    socket_items = [];
    equippedSocketItem = [];
    currentClass:  any;
    card_style = 'chaos-fire';
    show_no_cards = false;
    hero_card: any;
    card_styles = [
        'alpha-fire',
        'beta-fire',
        'untamed-fire',
        'chaos-fire',
        'gladius-fire'
    ];
    constructor(
        private helper: HelperService, 
        private data: DataService) { }
    ngOnInit() {
        this.populate();
    }
    ngAfterViewInit() {}
    doAction(e) {
        if (e && ['equip','unequip'].includes(e.type)) {
            if (e.type === 'equip') {
                this.currentHero.equipment[e.slot] = e.card;
            } else if (e.type === 'unequip') {
                this.currentHero.equipment[e.slot] = '';
            }
            this.getHeroDetails();
        } else if (e && e.type === 'skillUp') {
            if (e.skill.next !== 0) {
                let found = this.currentHero.skills.find(x => { return x.name === e.skill.name; });
                if (found) {
                    found.tier += 1;
                    if (e.skill.type === 'passive' && ['Armor Boost','Damage Boost','Speed Boost','Health Boost'].includes(e.skill.name)) {
                        let cur = this.skills.find(x => { return x.name === e.skill.name; });
                        if (cur) {
                            found.bonus = cur.bonus[found.level-1];
                            //found.desc = found.desc.replace('+X', '+' + found.bonus[0]);
                            if (found.desc.indexOf('+X') > -1) found.desc = found.desc.replace('+X', '+' + found.bonus);
                            else if (found.desc.indexOf(' X ') > -1) found.desc = found.desc.replace(' X ', ' ' + found.bonus + ' ');
                            else if (found.desc.indexOf('X%') > -1) found.desc = found.desc.replace('X%', found.bonus + '%');
                            this.getStats();
                        }
                    }
                    this.helper.showSnackbarMessage('Successfully raised tier of ' + e.skill.name);
                }
            } else {
                let description = '';
                if (e.skill.desc.indexOf('+X') > -1) description = e.skill.desc.replace('+X', '+' + e.skill.bonus[0]);
                else if (e.skill.desc.indexOf(' X ') > -1) description = e.skill.desc.replace(' X ', ' ' + e.skill.bonus[0] + ' ');
                else if (e.skill.desc.indexOf('X%') > -1) description = e.skill.desc.replace('X%', e.skill.bonus[0] + '%');
                let newSkill = {
                    name: e.skill.name,
                    type: e.skill.type,
                    desc: description,
                    tier: 1,
                    level: 1,
                    bonus: e.skill.bonus[0]
                }
                this.currentHero.skills.push(newSkill);
                this.getStats();
                this.helper.showSnackbarMessage('Successfully aquired ' + e.skill.name);
            }
            this.currentHero.skillPts -= e.skill.cost[e.skill.next];
            if (e.skill.type === 'passive') this.currentHero.passives += 1;
            this.heroAbilities = this.getHeroAbi();
            this.currentHero.abilities = this.heroAbilities;
        }
    }
    async populate() {
        this.user = await this.helper.getCurrentUser();
        this.heroCards = await this.helper.getHeroCards();
        if (!this.skills) this.skills = await this.helper.getSkills();
        if (this.heroCards && this.heroCards.length > 0) {
            // TODO make this check selectedHero on user pref
            let u = this.user;
            if (u && u.currentHero && u.currentHero !== '') {
                let found = this.heroCards.find(x => { return x.id === u.currentHero; });
                if (found) this.changeHero(found);
                else this.changeHero(this.heroCards[0]);
            } else this.changeHero(this.heroCards[0]);
        }
    }
    basicName() {
        return this.currentHero.name.indexOf(this.user.username.toLowerCase() + '_' + this.currentHero.type) > -1;
    }
    async burnForXP() {
        if (this.user.sc.balance < this.burnForXPQty) {
            this.helper.showSnackbarMessage('Not enough FORGE...');
            return;
        }
        let message = 'You are about to BURN ' + this.burnForXPQty + ' FORGE for ' + this.burnForXPQty + ' XP.\n';
        message += 'This will be account XP that can be spent on any hero.\n';
        message += 'Do you wish to continue?';
        if (confirm(message)) {
            this.loading = true;
            let temp = await this.helper.burnForXP(this.burnForXPQty);
            if (temp['message'] === 'success') {
                this.helper.changeXP(this.burnForXPQty);
                this.helper.changeSC(-this.burnForXPQty);
                //this.user.xp += this.burnForXPQty;
                //this.data.setUser(this.user);
                this.helper.showSnackbarMessage('Successfully burnt ' + this.burnForXPQty + ' FORGE for XP.');
                this.burnForXPQty = null;
                this.showBurnForXP = false;
            } else {
                this.helper.showSnackbarMessage(temp['message'] ? temp['message'] : 'error burning FORGE');
                //console.log(temp['message'] ? temp['message'] : 'error changing name');
            }
            this.loading = false;
        }
    }
    changeHero(h) {
        this.showNameChange = false;
        this.showBurnForXP = false;
        this.showNewLoadOut = false;
        this.currentHero = this.heroCards.find(x => { return x.id === h.id });
        //this.user.currentHero = this.currentHero.id;
        this.getHeroDetails();
    }

    async getCompleteHeroes(tier = 0) { // this is used when calling from other components
        if (!this.user) this.user = await this.helper.getCurrentUser();
        this.heroCards = await this.helper.getHeroCards();
        if (!this.heroCards || this.heroCards?.length < 1) return [];
        if (!this.skills) this.skills = await this.helper.getSkills();
        for (let i = 0; i < this.heroCards.length; i++) {
            let c = this.heroCards[i];
            this.currentHero = c;
            await this.getHeroDetails(tier);
        }
        return this.heroCards;
    }

    async getHeroDetails(tier = 0) {
        this.currentHero.skills.forEach(x => {
            if (x.desc.indexOf('+X') > -1) x.desc = x.desc.replace('+X', '+' + x.bonus);
            else if (x.desc.indexOf(' X ') > -1) x.desc = x.desc.replace(' X ', ' ' + x.bonus + ' ');
            else if (x.desc.indexOf('X%') > -1) x.desc = x.desc.replace('X%', x.bonus + '%');
        });
        this.equippedCards = await this.helper.getEquippedCards(this.currentHero);
        this.currentHero.equipped = this.equippedCards;
        if (this.equippedCards.length > 0 && this.currentHero.loadOuts.length > 0) {
            for (let i = 0; i < this.currentHero.loadOuts.length; i++) {
                let lo = this.currentHero.loadOuts[i];
                let correct = true;
                let parts = ['back','necklace','hands','feet','weapon','head','body','legs','ring','offhand','relic'];
                for (let i = 0; i < parts.length; i++) {
                    let part = this.currentHero.equipment[parts[i]];
                    let loPart = lo.equipment[parts[i]];
                    if (part !== loPart) correct = false;
                }
                if (correct) {
                    this.currentLoadOut = lo.name;
                    i = this.currentHero.loadOuts.length;
                }
            }
        }
        if (!this.currentHero.loadOuts) this.currentHero.loadOuts = [];
        this.hasHeroAbilities = false;
        this.heroAbilities = this.getHeroAbi();
        this.levelDetails = this.helper.heroLevelInfo(this.currentHero.xp);
        this.currentHero.levelDetails = this.levelDetails;
        this.currentHero.abilities = this.heroAbilities;
        this.currentHero.level = this.levelDetails.level;
        this.currentHero.promotable = this.currentHero.rank < Math.floor(this.currentHero.level/10);
        
        this.maxMana = 45;
        this.maxMonsters = 7;
        this.get_images();
        this.getStats(tier);
        this.getHeroResetCost();
    }

    getHeroAbi() {
        let config = this.helper.getSettings();
        let abilities = [
            { type: "Life Force", bonus: 0 },
            { type: "Fire Force", bonus: 0 },
            { type: "Earth Force", bonus: 0 },
            { type: "Death Force", bonus: 0 },
            { type: "Water Force", bonus: 0 },
            { type: "Dragon Force", bonus: 0 },
            { type: "Gladius Force", bonus: 0 },
            { type: "Mana Mastery", bonus: 0 },
            { type: "Vassal Mastery", bonus: 0 },
            { type: "Splash Heal", bonus: 0 },
            { type: "Hearts Blood", bonus: 0 },
            { type: "Critical Strike", bonus: 0 }
        ];
        if (!this.equippedCards || this.equippedCards.length < 1) return abilities;

        let socketAbilities = config['socketItems'];
        let nartorsSet = [57,58,59,60,61,62];
        let nartors = 0;
        for (let i = 0; i < this.equippedCards.length; i++) {
            let card = this.equippedCards[i];
            if (nartorsSet.includes(card.detail_id)) nartors += 1;
            let abi = abilities.find(a => { return card.name.indexOf(a.type) > -1; });
            if (abi) {
                let bonusDetails = socketAbilities.find(x => { return x.type.indexOf(abi.type) > -1 });
                abi.bonus += bonusDetails.bonus[card.rarity-1];
            } else if (card.slot === 'relic') {
                abilities.push({ type: card.stat + ' Charges', bonus: card.charges.current });
            }
        }
        for (let i = 0; i < this.currentHero.skills.length; i++) {
            let skill = this.currentHero.skills[i];
            if (skill.name === 'Advanced Training') abilities.push({ type: 'Bonus XP', bonus: skill.bonus });
            else if (skill.name === 'Absorption') abilities.push({ type: 'Absorption', bonus: skill.bonus });
            else if (skill.name === 'Acrobatics') abilities.push({ type: 'Acrobatics', bonus: skill.bonus });
            else if (skill.name === 'Dirty Fighting') abilities[11].bonus += skill.bonus;
            else if (skill.name === 'Sweet Spot') abilities.push({ type: 'Sweet Spot', bonus: skill.bonus });
            else if (skill.name === 'Eletrumagnet') abilities.push({ type: 'Electrumagnet', bonus: skill.bonus });
            else if (skill.name === 'Nulify') abilities.push({ type: 'Nulify', bonus: skill.bonus });
            else if (skill.name === 'Kickback') abilities.push({ type: 'Kickback', bonus: skill.bonus });
            else if (skill.name === 'Void Armor') abilities.push({ type: 'Void Armor', bonus: 1 });
            else if (skill.name === 'Force Master') {
                let forceCount = 0;
                abilities.forEach(x => { if (x.type.indexOf('Force') > -1 && x.bonus > 0) forceCount += 1; });
                if (forceCount > 3) abilities[8].bonus += 1;
            }
        }
        let familiar = this.currentHero.skills ? this.currentHero.skills.find(x => { return x.name === 'Nartors Familiarity'; }) : null;
        if (familiar) nartors += 1;
        if (nartors >= 6) abilities[8].bonus += 1;
        //if (this.currentHero.currentClass === 'Wizard') console.log(abilities);
        return abilities;
    }

    getHeroResetCost() {
        let totalSkillPoints = 0;
        let totalElectrum = 0;

        let ld = this.currentHero.levelDetails; // await utils.heroLevelInfo(hero.xp);
        totalSkillPoints += ld.level;
        totalSkillPoints += this.currentHero.rank;
        let usedSkillPoints = totalSkillPoints - this.currentHero.skillPts;
        
        let skillLevelCosts = [];
        for (let i = 0; i < 19; i++) {
            if (i === 0) skillLevelCosts.push(10000);
            else {
                skillLevelCosts.push(Math.floor(skillLevelCosts[i-1] * 1.33));
            }
        }
        //console.log(skillLevelCosts);
        this.currentHero.skills.forEach(x => {
            let tot = 0;
            if (x.type === 'trained' && x.level > 0) {
                for (let i = 0; i < x.level-1; i++) tot += skillLevelCosts[i];
            }
            //console.log('Electrum used on ' + x.name + ': ' + tot);
            totalElectrum += tot;
        })

        this.skillResetDetails = {
            usedSkillPoints: usedSkillPoints,
            totalElectrum: totalElectrum,
            electrumRefund: Math.round(totalElectrum*0.95),
            cost: (usedSkillPoints*100)
        }
    }

    async getLeaders() {
        let temp: any = await this.helper.getHeroLeaders();
        //console.log(temp);
        if (temp.message === 'success') {
            this.leaders = temp.leaders;
            this.view = 'leaderView';
        } else {
            if (temp.message) this.helper.showSnackbarMessage(temp.message);
            else this.helper.showSnackbarMessage('error retreiving info');
        }
    }

    get_images() {
        var parts = ['back','necklace','hands','feet','weapon','head','body','legs','ring','offhand','relic'];
        for (var i = 0; i < parts.length; i++) {
            var part = parts[i];
            var part_img = this.getEquippedCardURL(part);
            if (part_img) {
                this.img[part] = part_img[0]?part_img[0]:false;
                this.img_enabled[part] = part_img[1]?part_img[1]:false;
            }
            this.getSocketItems(part);
        }
    }
    
    getStats(tier = 0) {
        let stats = this.helper.getCompleteHeroStats(this.equippedCards, this.currentHero);
        this.currentHero.stats = stats;
        let parts = ['mana','attack','magic','ranged','speed','armor','health'];
        let tierStats = { health: 1, armor: 1, speed: 1, attack: 0, ranged: 0, magic: 0, mana: 0, runeStats: 0, nartors: 0 };
        if (this.currentHero.type === 'Warrior') tierStats.attack += 1;
        else if (this.currentHero.type === 'Wizard') tierStats.magic += 1;
        else if (this.currentHero.type === 'Ranger') tierStats.ranged += 1;
        let heroStatTotal = 0;
        for (let part of parts) {
            if ([1,2,3,4].includes(tier)) {
                let config = this.helper.getSettings();
                let maxStat = config.heroStatMaxTiers[tier-1].armor;
                if (maxStat && this.currentHero.stats[part] > maxStat) tierStats[part] = maxStat;
                else tierStats[part] = this.currentHero.stats[part];
                if (part !== 'nartors') heroStatTotal += tierStats[part];
            }
            this[part] = stats[part];
        }
        this.currentHero.tierStats = tierStats;
        this.currentHero.heroStatTotal = heroStatTotal;
        for (var i = 0; i < this.heroAbilities.length; ++i) {
            if(this.heroAbilities[i].bonus>0){
                if (this.heroAbilities[i].type === 'Vassal Mastery') this.maxMonsters += this.heroAbilities[i].bonus;
                if (this.heroAbilities[i].type === 'Mana Mastery') this.maxMana += this.heroAbilities[i].bonus;
                this.hasHeroAbilities = true;
            }
        }
    }
    
    getCard(cid) {
        if (!this.equippedCards) return null;
        let card = this.equippedCards.find(c => c.cid === cid);
        return card ? card : null;
    }
    getEquippedCardURL(slot) {
        var enabled = false;
        let baseURL = 'https://splinterforge.s3.us-east-2.amazonaws.com/frame_art/slots/';
        if(this.currentHero) {
            let slotString = this.currentHero.equipment[slot];
            if (slotString?.length > 5) {
                if (!this.equippedCards) baseURL += slot + '.png';
                //let found = this.cards.find(c => c.cid === slotString);
                let found = this.equippedCards.find(c => c.cid === slotString);
                if (found) {
                    baseURL = found.imgURL;
                } else {
                    baseURL += slot + '.png';
                }
            } else {
                enabled = true;
                baseURL += slot + '.png';
            }
            return [baseURL,enabled];
        }
    }
    getSocketedItem(cid) {
        let item = this.equippedCards.find(i => i.cid === cid);
        return item ? encodeURI(item.imgURL) : 'https://splinterforge.s3.us-east-2.amazonaws.com/frame_art/slots/socket.png';
    }
    getSocketedName(cid) {
        let item = this.equippedCards.find(i => i.cid === cid);
        return item ? item.name:'';
    }

    getSocketItems(slot) {
        if (!this.currentHero) return false;
        let card;
        this.socket_items[slot]=[];
        let cid = this.currentHero.equipment[slot];
        if (cid !== '') card = this.getCard(cid);
        else return false;
        if (card?.sockets > 0) {
            for (let i = 0; i < card.socket_items.length; i++) {
                var item = {};
                if (card.socket_items[i] !== '') {
                    item = {
                        'name': this.getSocketedName(card.socket_items[i]),
                        'socket_item': this.getSocketedItem(card.socket_items[i]),
                        'ability_description': this.helper.getAbilityDescFromCID(card.socket_items[i],this.equippedCards),
                        'class': 'socket_in_use'
                    }
                } else {
                    item = {
                        'name': 'Available Socket',
                        'socket_item': 'https://splinterforge.s3.us-east-2.amazonaws.com/frame_art/slots/socket.png',
                        'ability_description': 'Available Socket',
                        'class': 'available_socket'
                    }
                }
                this.socket_items[slot].push(item)
            }
        }
    }

    async inspectSlot(slot) {
        let u = this.user;
        let cid = this.currentHero.equipment[slot];
        this.equipped = this.equippedCards.find(c => c.cid === cid);
        
        if (slot === 'weapon' && this.currentHero.type === 'Warrior') slot = 'meleeWeapon';
        else if (slot === 'weapon' && this.currentHero.type === 'Wizard') slot = 'magicWeapon';
        else if (slot === 'weapon' && this.currentHero.type === 'Ranger') slot = 'rangedWeapon';
        else if (slot === 'offhand' && this.currentHero.type === 'Warrior') slot = 'meleeOffhand';
        else if (slot === 'offhand' && this.currentHero.type === 'Wizard') slot = 'magicOffhand';
        else if (slot === 'offhand' && this.currentHero.type === 'Ranger') slot = 'rangedOffhand';

        this.equippable = await this.helper.loadEquippableCardsBySlot(slot, this.equipped);

        if ((!this.equippable || this.equippable.length === 0) && !this.equipped) {
            this.equippable = null;
            this.equipped = null;
            this.show_no_cards = true;
            return;
        }
        this.viewCard = true; //this.data.setViewCard(true);
    }
    async isEquipped(card) {
        let found = this.equippedCards.find(c => c.cid === card.cid);
        return found ? true : false;
    }

    async levelHero() {
        let ld = this.levelDetails;
        let gapxp = this.currentHero.xp - ld.used;
        let needed = ld.next - gapxp;
        if (this.user.xp >= needed) {
            this.loading = true;
            let temp: any = await this.helper.levelHero(this.currentHero.id);
            if (temp.message === 'success') {
                this.helper.changeXP(-needed);
                this.currentHero.xp += needed;
                this.currentHero.skillPts += 1;
                this.levelDetails = this.helper.heroLevelInfo(this.currentHero.xp);
                this.currentHero.promotable = this.currentHero.rank < Math.floor(this.levelDetails.level/10);
            } else {
                if (temp.message) this.helper.showSnackbarMessage(temp.message);
                else {
                    //console.log(temp);
                    this.helper.showSnackbarMessage('unknown error');
                }
            }
            this.loading = false;
        } else return { message: 'insufficient balance' };
        
    }
    async promoteHero() {
        if (this.currentHero.rank >= Math.floor(this.levelDetails.level/10)) return;
        let cost = (this.currentHero.rank+1)*50;
        if (confirm('Do you wish to spend '+cost+' Evoulution Essence to promote ' + this.currentHero.name + ' to rank ' + (this.currentHero.rank+1))) {
            this.loading = true;
            let temp: any = await this.helper.promoteHero(this.currentHero.id, this.currentHero.rank+1);
            if (temp.message === 'success') {
                this.helper.changeEssence(-cost);
                this.currentHero.rank += 1;
                this.currentHero.promotable = this.currentHero.rank < Math.floor(this.levelDetails.level/10);
            } else {
                if (temp.message) this.helper.showSnackbarMessage(temp.message);
                else {
                    //console.log(temp);
                    this.helper.showSnackbarMessage('unknown error');
                }
            }
            this.loading = false;
        }
    }
    async resetHeroSkills() {
        //console.log(this.currentHero.upgrades);
        let firstReset = !this.currentHero.freeResets || this.currentHero.freeResets < 1;
        let text = 'Each hero only recieves 1 free skill reset!\n';
        text += 'This will refund all skill points and electrum used on skills.\n';
        text += 'Any further skill resets on this hero will require a FORGE burn.\n';
        text += 'Are you sure you want to reset your skills?';

        let burnText = 'You have already used your free skill reset on this hero.\n';
        burnText += 'Resetting this heroes skills will require a ' + this.skillResetDetails.cost + ' FORGE burn.\n';
        burnText += 'Are you sure you would like to burn ' + this.skillResetDetails.cost + ' FORGE to reset your skills?';

        if (!firstReset && this.user.sc.balance < this.skillResetDetails.cost) {
            this.helper.showSnackbarMessage('Not enough FORGE...');
            return;
        }
        if (confirm(firstReset ? text : burnText)) {
            this.loading = true;
            let temp: any = await this.helper.resetHeroSkills(this.currentHero.id, this.skillResetDetails.cost);
            if (temp.message === 'success') {
                this.options = false;
                this.helper.changeElectrum(temp.electrum);
                this.currentHero.skills = [];
                this.currentHero.skillPts = temp.skillPts;
                if (!this.currentHero.freeResets) {
                    this.currentHero.freeResets = 1;
                } else this.currentHero.freeResets += 1;
                if (temp.cost > 0) this.helper.changeSC(-temp.cost);
            } else {
                if (temp.message) this.helper.showSnackbarMessage(temp.message);
                else {
                    //console.log(temp);
                    this.helper.showSnackbarMessage('unknown error');
                }
            }
            this.loading = false;
        }        
    }
    async levelSkill(skill) {
        let needed = this.skillXPNeeded(skill);
        let tier = 0;
        if (skill.level+1 > 15) tier = 3;
        else if (skill.level+1 > 10) tier = 2;
        else tier = 1;
        if (skill.tier < tier) {
            this.helper.showSnackbarMessage('Must raise skill to next tier to level.');
            return;
        }
        if (this.user.electrum >= needed) {
            this.loading = true;
            let temp: any = await this.helper.levelSkill(this.currentHero.id, skill.name, skill.level+1);
            if (temp.message === 'success') {
                this.helper.changeElectrum(-needed);
                //let found = this.currentHero.skills.find(x => { return x.name === skill.name; });
                skill.level += 1;
                let found = this.skills.find(x => { return x.name === skill.name; });
                if (found) {
                    skill.bonus = found.bonus[skill.level-1];
                    //skill.desc = found.desc.replace('+X', '+' + skill.bonus);
                    // updated description isn't +X/X/X%...
                    if (skill.desc.indexOf('+X') > -1) skill.desc = found.desc.replace('+X', '+' + skill.bonus);
                    else if (skill.desc.indexOf(' X ') > -1) skill.desc = found.desc.replace(' X ', ' ' + skill.bonus + ' ');
                    else if (skill.desc.indexOf('X%') > -1) skill.desc = found.desc.replace('X%', skill.bonus + '%');
                }
                this.helper.showSnackbarMessage('success');
                this.heroAbilities = this.getHeroAbi();
                this.currentHero.abilities = this.heroAbilities;
            } else {
                if (temp.message) this.helper.showSnackbarMessage(temp.message);
                else {
                    //console.log(temp);
                    this.helper.showSnackbarMessage('unknown error');
                }
            }
            this.loading = false;
        } else return { message: 'insufficient balance' };
    }
    useSkillPts() {
        if (!this.currentHero) return;
        this.filteredSkills = [];
        let passives = this.currentHero.skills.filter(x => { return x.type === 'passive' });
        let totPass = 0;
        if (passives.length > 0) passives.forEach(x => { totPass += x.tier });
        this.currentHero.passives = totPass;
        this.skills.forEach(s => {
            let found = this.currentHero.skills.find(x => { return x.name === s.name });
            if (found && found.tier+1 > s.cost.length) {}
            else {
                //console.log(s.type, this.currentHero.passives, this.currentHero.rank, this.currentHero.skillPts, s.cost[s.next]);
                //console.log(s.type === 'passive', this.currentHero.passives >= this.currentHero.rank, this.currentHero.skillPts < s.cost[s.next]);
                let needRank = s.type === 'passive' && this.currentHero.passives >= this.currentHero.rank;
                let needPts = this.currentHero.skillPts < s.cost[found ? found.tier : 0];
                this.filteredSkills.push({
                    name: s.name,
                    type: s.type,
                    desc: s.desc,
                    bonus: s.bonus,
                    cost: s.cost,
                    next: found ? found.tier : 0,
                    needRank: needRank,
                    needPts: needPts
                })
            }
        })
        this.viewSkills = true;
    }
    validName() {
        let pattern = new RegExp('^[a-zA-Z0-9]*$');
        return !this.newName ? false : this.newName.length > 2 && pattern.test(this.newName) && this.newName.length < 17;
    }
    async saveLoadOut() {
        let maxLO = 2;
        if (this.currentHero?.loadOuts?.length >= maxLO) {
            this.helper.showSnackbarMessage('May only have ' + maxLO + ' loadouts on this hero.');
            return;
        } else {
            this.loading = true;
            let temp: any = this.helper.manageLoadOuts('save', this.currentHero.id, this.newLoadOutName);
            if (temp.message === 'success') {
                this.helper.showSnackbarMessage('Saved!');
            } else {
                this.helper.showSnackbarMessage(temp['message'] ? temp['message'] : 'error saving loadout');
            }
        }
        this.loading = false;
    }
    skillXPNeeded(skill) {
        if (skill.type === 'passive') {
            return 999999999;
        }
        let mod = 1.33;
        let next = 0;
        let cur = skill.level;
        let count = 0;

        while (cur > 0) {
            if (count === 0) {
                next = 10000;
            } else { next = Math.floor(next*mod); }
            cur -= 1;
            count += 1;
        }
        return next;
    }
    async submitNewName() {
        if (this.newName.toLowerCase() === this.currentHero.name.toLowerCase()) {
            this.helper.showSnackbarMessage('That is already this heros name...');
            return;
        }
        let message = 'You are about to change your hero name to ' + this.newName + '.\n';
        message += 'This is a FREE ONE TIME change from the basic username assigned.\n';
        message += 'Rude and/or offensive names (determined at the teams discretion) may be changed by the team without notice or compensation.\n';
        message += 'Do you wish to continue?';

        let burnMessage = 'You are about to change your hero name to ' + this.newName + '.\n';
        burnMessage += 'This name change will require a 20,000 FORGE burn.\n';
        burnMessage += 'Rude and/or offensive names (determined at the teams discretion) may be changed by the team without notice or compensation.\n';
        burnMessage += 'Do you wish to continue?';
        let burn = !this.basicName();
        if (burn && this.user.sc.balance < 20000) {
            this.helper.showSnackbarMessage('Not enough FORGE...');
            return;
        }
        if (confirm(burn ? burnMessage : message) && this.validName()) {
            this.loading = true;
            let temp = await this.helper.changeHeroName(this.currentHero.id, this.newName, burn)
            if (temp['message'] === 'success') {
                this.currentHero.name = this.newName;
                this.helper.showSnackbarMessage('name successfully change');
                this.showNameChange = false;
                if (burn) this.helper.changeSC(-20000);
            } else {
                this.helper.showSnackbarMessage(temp['message'] ? temp['message'] : 'error changing name');
                //console.log(temp['message'] ? temp['message'] : 'error changing name');
            }
            this.loading = false;
        }
    }
    toggleOptions() {
        if (!this.options) {
            this.getHeroResetCost();
            this.options = true;
        } else this.options = false;
    }
    hide_cards() {
        this.viewCard = false;
    }
    navigate(loc) {
        this.helper.navigate(loc);
    }
    sendCards() { return this.helper.mySFCards; }
}