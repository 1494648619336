import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppComponent } from '../../app.component';
import { DataService } from '../../_services/data.service';
import { HelperService } from '../../_services/helper.service';
import { fade, flip_up, pointer } from '../../app.animations';
@Component({
  selector: 'donate',
  animations: [fade, flip_up, pointer],
  templateUrl: './donate.component.html',
  styleUrls: [
    './donate.component.styl']
})
export class DonateComponent implements OnInit {
  @Input('show') show = false;
  @Input('currentUser') currentUser: any;
  @Output('done') done = new EventEmitter();
  donor = new Donor();
  assets = false;
  constructor(
    public app: AppComponent, 
    public helper: HelperService, 
    public data: DataService
  ) {}
  ngOnInit(): void {
    this.donor.amount=5
    this.donor.currency='DEC'
  }
  buy() {

  }
  close() {
    this.show = false
    this.done.emit(1);
  }
  donate(symbol) { 
    this.helper.donate(this.donor.amount, symbol, this.donor.memo); 
  }
}
export class Donor {
  constructor(
    public amount?: number,
    public currency?: string,
    public memo?: string
  ) {}
} 