import { Injectable } from '@angular/core';
import { DataService, AuthService, FetchService, BossService, ShopService, CardService, HeroService, MineRunService } from './index';
import { User, Boss } from '../_models/index'
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
declare let ssc: any;

declare global { interface Window { hive_keychain: any; } }

@Injectable({ providedIn: 'root' })
export class HelperService {
    detail_id: any;
    detail_foil: any;
    currentUser: User;
    bosses: Boss[];
    slCollection: [];
    slCards: [];
    mySFCards: any;
    unusable: [];
    result: any;
    config: any;
    scrolling: any;
    globalMod: number;
    usedCIDs: any;
    public login_results: string;
    public login_error: boolean;
    public login_loading: boolean;
    public show_max_levels: boolean;
    public active = '';
    logSpeed: number;
    state = { username: '', error: '', loginType: '' };
    replacedAbilities = ['Blast','Cleanse','Close Range','Dispel','Knock Out','Opportunity','Oppress','Reach','Sneak','Snipe','Trample'];
    prices: any;
    nav_confirmation_title= 'Are You Sure?';
    nav_confirmation_label= 'Are you sure you wish to navigate away from this page?';
    sfCardDetails: any;
    constructor(
            private data: DataService, 
            private auth: AuthService, 
            private cs: CardService,
            private fetch: FetchService, 
            private bs: BossService, 
            private http: HttpClient,
            private shop: ShopService,
            private router: Router,
            private hs: HeroService,
            private mrs: MineRunService) 
    {
        this.data.onUserChange().subscribe(u => { 
            this.currentUser = u;
        });
        this.data.onBossChange().subscribe(b => this.bosses = b);
        this.data.onCollectionChange().subscribe(c => { this.slCollection = c });
        this.data.onCardDetailsChange().subscribe(c => { this.slCards = c });
        this.data.onResultChange().subscribe(r => this.result = r);
        this.data.onScrollingChange().subscribe(s => this.scrolling = s);
        this.data.onLogSpeedChange().subscribe(ls => this.logSpeed = ls);
        this.data.onUnusableChange().subscribe(u => this.unusable = u);
        this.data.onConfigChange().subscribe(c => this.config = c);
        this.data.onMySFCardsChange().subscribe(c => this.mySFCards = c);
    }

    async burnForStamina(rate, qty) {
        if (!this.currentUser) return null;
        let res: any = await this.shop.burnForStamina({ user: this.currentUser.username, token: this.currentUser.token, rate: rate, qty: qty });
        if (res.message === 'success') return res;
        else {
            this.showSnackbarMessage(res.message);
            return null;
        }
    }

    async getHeroCards () {
        if (!this.currentUser) return null;
        let res: any = await this.hs.getHeroCards(this.currentUser.username, this.currentUser.token);
        if (res && !res.message) {
            let ret = JSON.parse(JSON.stringify(res));
            ret.forEach(x => { x.currentClass = x.type });
            this.currentUser.newHeroes = ret;
            this.data.setUser(this.currentUser);
            return ret;
        }else {
            if (res.message) this.showSnackbarMessage(res.message);
            else this.showSnackbarMessage('error');
            return null;
        }
    }

    async getSkills () {
        let res: any = await this.hs.getSkills(this.currentUser.username, this.currentUser.token);
        if (res && !res.message) return res;
        else {
            if (res.message) this.showSnackbarMessage(res.message);
            else this.showSnackbarMessage('error');
            return null;
        }
    }

    async learnSkill (skill, hero, lvl) {
        let res: any = await this.hs.learnSkill({user: this.currentUser.username, token: this.currentUser.token, skill: skill, heroid: hero, level: lvl});
        if (res.message === 'success') return res;
        else {
            this.showSnackbarMessage(res.message);
            return null;
        }
    }

    async levelSkill (hero, skill, lvl) {
        let res: any = await this.hs.levelSkill({user: this.currentUser.username, token: this.currentUser.token, skill: skill, heroid: hero, level: lvl});
        if (res.message === 'success') return res;
        else {
            this.showSnackbarMessage(res.message);
            return null;
        }
    }

    async loadMineRun() {
        if (!this.currentUser) return null;
        let res: any = await this.mrs.loadMineRun(this.currentUser.username, this.currentUser.token);
        if (res.message === 'success') return res.mineRun;
        else {
            this.showSnackbarMessage(res.message);
            return null;
        }
    }

    async promoteHero(hero, rank) {
        let res: any = await this.hs.promoteHero({user: this.currentUser.username, token: this.currentUser.token, heroid: hero, rank: rank});
        if (res.message === 'success') return res;
        else {
            this.showSnackbarMessage(res.message);
            return null;
        }
    }

    async startMineRun(tier, ids, heroType, burnReset, freeSplinter, resetCost) {
        if (!this.currentUser) return false;
        let res: any = await this.mrs.setupMineRun(this.currentUser.username, this.currentUser.token, ids, tier, heroType, burnReset, freeSplinter, resetCost);
        console.log(res);
        if (res.message === 'success') return res.mineRun;
        else {
            this.showSnackbarMessage(res.message);
            return false;
        }
    }

    async toggleFavorite(card) {
        if (!this.currentUser) return false;
        let res: any = await this.cs.toggleFavorite(this.currentUser.username, card.id, this.currentUser.token);
        if (res.message === 'success') {
            let cardsString = localStorage.getItem('slCollection');
            let cards = JSON.parse(cardsString);
            let found = cards.find(x => { return x.uid === card.uid; });
            if (found) {
                found.favorite = res.add ? true : false;
                localStorage.setItem('slCollection', JSON.stringify(cards));
            }
            this.showSnackbarMessage((res.add ? 'Added' : 'Removed') + ' favorite');
        } else this.showSnackbarMessage(res.message);
    }

    async getSFCardDetails() {
        if (!this.sfCardDetails) this.sfCardDetails = await this.cs.cardDetails();
    }

    getAbilityURL(ability) {
        if (ability === '1.5x DMG') return 'https://splinterforge.s3.us-east-2.amazonaws.com/abilities/multiplier.png';
        return 'https://d36mxiodymuqjm.cloudfront.net/website/abilities/ability_' + ability.split(' ').join('-').toLowerCase() + '.png';
    }

    getMode() {
        if (!this.config || !this.config.mode) return 'no mode';
        else return this.config.mode;
    }
    
    changeElectrum(qty) {
        if (!this.currentUser.electrum) this.currentUser.electrum = 0;
        this.currentUser.electrum += qty;
        this.data.setUser(this.currentUser);
    }
    changeFrags(qty) {
        if (!this.currentUser.frags) this.currentUser.frags = 0;
        this.currentUser.frags += qty;
        this.data.setUser(this.currentUser);
    }
    changeEssence(qty) {
        if (!this.currentUser.essence) this.currentUser.essence = 0;
        this.currentUser.essence += qty;
        this.data.setUser(this.currentUser);
    }

    changeSC(qty) {
        this.currentUser.sc.balance += qty;
        this.data.setUser(this.currentUser);
    }

    changeStamina(qty) {
        this.refreshUser();
        return;
        this.currentUser.stamina.current += qty;
        //this.currentUser.stamina.last = new Date();
        this.data.setUser(this.currentUser);
    }

    changeXP(qty) {
        if (!this.currentUser.xp) this.currentUser.xp = 0;
        this.currentUser.xp += qty;
        this.data.setUser(this.currentUser);
    }

    changeBAG(qty) {
        let bag = this.currentUser.inGameAssets.find(a => a.symbol === 'BAG');
        if (bag) bag.qty += qty;
        this.data.setUser(this.currentUser);
    }
    changeCRATE(qty) {
        let crate = this.currentUser.inGameAssets.find(a => a.symbol === 'CRATE');
        if (crate) crate.qty += qty;
        this.data.setUser(this.currentUser);
    }
    async claimDaily() {
        return await this.auth.claimDaily({ user: this.currentUser.username, token: this.currentUser.token });
    }
    async conjureRelic(frags) {
        return await this.cs.conjureRelic({ user: this.currentUser.username, token: this.currentUser.token, frags: frags });
    }
    async getCurrentUser() {
        return this.currentUser;
    }
    getCurrentUsername() {
        return this.currentUser.username;
    }
    async dailyRewards() {
        return await this.auth.getDaily({ username: this.currentUser.username, token: this.currentUser.token });
    }
    async getEquippedCards(hero) {
        //console.log(hero);
        if (!this.currentUser) return null;
        if (!this.mySFCards) await this.getSFCards();
        let equippedCards = [];
        for (const slot in hero.equipment) {
            let cid = hero.equipment[slot];
            if (cid !== '') {
                let card = this.mySFCards.find(c => { return c.cid === cid; });
                if (card) {
                    equippedCards.push(card);
                    if (card.sockets > 0) {
                        for (let i = 0; i < card.socket_items.length; i++) {
                            let socket = card.socket_items[i];
                            if (socket !== '') {
                                let socketCard = this.mySFCards.find(c => { return c.cid === socket; });
                                if (socketCard) equippedCards.push(socketCard);
                                else console.log('did not find gem/rune: ', socket);
                            }
                        }
                    }
                }
            }
        }
        return equippedCards;
    }
    /*async getEquippedCardsTwo(hero) {
        //console.log(hero);
        if (!this.currentUser) return null;
        if (!this.mySFCards) return [];
        let equippedCards = [];
        for (const slot in hero.equipment) {
            let cid = hero.equipment[slot];
            if (cid !== '') {
                let card = this.mySFCards.find(c => { return c.cid === cid; });
                if (card) {
                    equippedCards.push(card);
                    if (card.sockets > 0) {
                        for (let i = 0; i < card.socket_items.length; i++) {
                            let socket = card.socket_items[i];
                            if (socket !== '') {
                                let socketCard = this.mySFCards.find(c => { return c.cid === socket; });
                                if (socketCard) equippedCards.push(socketCard);
                                else console.log('did not find gem/rune: ', socket);
                            }
                        }
                    }
                }
            }
        }
        return equippedCards;
    }*/
    async getGlobalMod() {
        let res: any = await this.auth.getGlobalMod();
        if (res.message === 'success') {
            this.globalMod = parseFloat((res.mod).toFixed(2));
            return this.globalMod;
        } else return 0;
    }

    async getHeroLeaders() {
        return await this.hs.getHeroLeaders();
    }
    getSettings() {
        return this.config;
    }
    async getStakeInfo() {
        return await this.hs.getStakeInfo(this.currentUser.username, this.currentUser.token);
    }
    async getTotalStakeInfo() {
        return await this.hs.getTotalStakeInfo();
    }

    async rechargeRelic(cid, cids) {
        return await this.cs.rechargeRelic({ user: this.currentUser.username, token: this.currentUser.token, target: cid, cards: cids });
    }
    async salvageRelics(cids) {
        return await this.cs.salvageRelics({ user: this.currentUser.username, token: this.currentUser.token, cards: cids });
    }

    async stakeForge(q) {
        return await this.hs.stake(this.currentUser.username, this.currentUser.token, q);
    }

    async unstakeForge(q) {
        return await this.hs.unstake(this.currentUser.username, this.currentUser.token, q);
    }
    async xpRewardHistory() {
        return await this.hs.xpRewardHistory(this.currentUser.username, this.currentUser.token);
    }

    async getRules(tier) {
        return await this.auth.getRules(this.currentUser.username, tier);
    }
    async rerollMiniBossRules(bossID) {
        return await this.mrs.reroll({ user: this.currentUser.username, token: this.currentUser.token, boss: bossID });
    }
    async rerollRules(tier) {
        return await this.auth.rerollRules({ user: this.currentUser.username, token: this.currentUser.token, type: tier });
    }
    async rezMonster(uid) {
        return await this.mrs.rezMonster({ user: this.currentUser.username, token: this.currentUser.token, target: uid });
    }

    async getUserAirdropInfo() {
        return await this.auth.getUserAirdropInfo(this.currentUser.username);
    }

    async userFights(bossID) {
        if (!this.currentUser) return null;
        else {
            let temp = await this.bs.userFights({ username: this.currentUser.username, token: this.currentUser.token, boss: bossID });
            //console.log(temp);
            if (temp && temp.message === 'success') return temp.fights;
        }
    }

    async addSocketToItem(cid, qty) { //params = { target: 'cid', user: 'taunt', qty: 300 };
        if(this.currentUser) {
            let memo = await this.getKeyMemo(this.currentUser.username, 'add socket', 'Posting');
            if (!memo) return false;
            return await this.cs.addSocketToItem({ user: this.currentUser.username, cid: cid, qty: qty, memo: memo });
        }
    }

    async burnCards(selected, type) {
        let memo = await this.getKeyMemo(this.currentUser.username, 'burn cards', 'Active');
        if (!memo) return false;
        let json = { user: this.currentUser.username, cards: selected, memo: memo, type: type }
        
        let encoded = await this.getEncodedOperation(json, 'Active');
        if (!encoded) {
            this.showSnackbarMessage('Cancelled by User');
            return false;
        }
        return await this.cs.burnCards({ op: encoded });
    }

    async burnForXP(qty) {
        if (this.currentUser) {
            return await this.hs.burnForXP({ user: this.currentUser.username, token: this.currentUser.token, qty: qty });
        }
    }

    async buy(type, name, qty, purchaseMemo = '') {
        let memo = await this.getKeyMemo(this.currentUser.username, 'shop purchase', 'Active');
        if (!memo) return false;
        let temp = await this.shop.buy({ username: this.currentUser.username, type: type, name: name, qty: qty, memo: memo, purchaseMemo: purchaseMemo });
        if (temp && temp.message === 'success') {
            this.refreshUser(this.currentUser.username, this.currentUser.token);
            // this.showSnackbarMessage('Purchase successful!');
            return true;
        } else {
            this.showSnackbarMessage(temp.message);
            return false;
        }
    }

    async changeHeroName(heroid, newName, burn) {
        if (this.currentUser) {
            return await this.hs.changeHeroName({ user: this.currentUser.username, token: this.currentUser.token, name: newName, heroid: heroid, burn: burn });
        }
    }

    async claimAirdrop(type) {
        let memo = await this.getKeyMemo(this.currentUser.username, 'claim airdrop', 'Posting');
        if (!memo) return false;
        let json = { user: this.currentUser.username, memo: memo, type: type }
        //let temp = await this.cs.claimAirdrop(json);
        //return;
        
        let encoded = await this.getEncodedOperation(json, 'Active');
        if (!encoded) {
            this.showSnackbarMessage('Cancelled by User');
            return false;
        }
        return await this.cs.claimAirdrop({ op: encoded });
    }

    async confirmSale(tx) {
        return await this.shop.confirmSale({ tx: tx });
    }
    
    async fightBoss(type, team, gear) {
        if (this.currentUser) {
            let memo = await this.getKeyMemo(this.currentUser.username, 'fight boss', 'Posting');
            if (!memo) return false;
            if (memo['message'] && memo['message'] === 'tooSoon') { return { tooSoon: true, message: memo['memo'], remaining: memo['remaining'] } };
            let temp = await this.bs.fightBoss({username: this.currentUser.username, token: this.currentUser.token, type: type, team: team, memo: memo, gear: gear, source: 'bossFight' });
            if (temp && temp['message'] === 'ToS') {
                this.showSnackbarMessage('Please do a fresh login to agree to ToS and Privacy Policy.');
                this.data.setUser(null);
                this.navigate('');
                localStorage.removeItem('forge:key');
                return 'ToS';
            }
            this.data.setResult(temp);
            return null;
        }
    }

    async fightMiniBoss(id, team, expected) {
        if (this.currentUser) {
            //let memo = await this.getKeyMemo(this.currentUser.username, 'fight mini boss', 'Posting');
            //if (!memo) return false;
            let temp = await this.bs.fightBoss({username: this.currentUser.username, token: this.currentUser.token, id: id, team: team, memo: 'memo', source: 'mineRun', expected: expected });
            if (temp && temp['message'] === 'ToS') {
                this.showSnackbarMessage('Please do a fresh login to agree to ToS and Privacy Policy.');
                this.data.setUser(null);
                this.navigate('');
                localStorage.removeItem('forge:key');
                return 'ToS';
            }
            //console.log(temp);
            return temp;
        }
    }

    async emptySockets(card) {
        if(this.currentUser) {
            let memo = await this.getKeyMemo(this.currentUser.username, 'empty sockets', 'Posting');
            if (!memo) return false;
            return await this.cs.emptySockets({ user: this.currentUser.username, cid: card.cid, memo: memo });
        }
        //return await this.cs.equipCard({ user: this.currentUser.username, charClass: this.currentUser.currentClass.toLowerCase(), cid: cid});
    }

    async equipCardOnHero(cid, heroid) {
        if (this.currentUser) {
            return await this.hs.equipCard({ user: this.currentUser.username, token: this.currentUser.token, cid: cid, heroid: heroid });
        }
    }

    async loadUserPoints(type) {
        if (this.currentUser) {
            return await this.auth.loadEventPoints({ user: this.currentUser.username, token: this.currentUser.token });
        }
    }

    async manageLoadOuts(type, heroid, name) {
        if (this.currentUser) {
            return await this.hs.manageLoadOuts({ user: this.currentUser.username, token: this.currentUser.token, heroid: heroid, type: type, name: name });
        }
    }

    async unequipCardFromHero(slot, heroid) {
        if (this.currentUser) {
            return await this.hs.unequipCard({ user: this.currentUser.username, token: this.currentUser.token, slot: slot, heroid: heroid });
        }
    }

    async equipCard(cid) {
        if(this.currentUser) {
            let memo = await this.getKeyMemo(this.currentUser.username, 'equip card', 'Posting');
            if (!memo) return false;
            return await this.cs.equipCard({ user: this.currentUser.username, cid: cid, charClass: this.currentUser.currentClass.toLowerCase(), memo: memo });
        }
        //return await this.cs.equipCard({ user: this.currentUser.username, charClass: this.currentUser.currentClass.toLowerCase(), cid: cid});
    }

    async levelHero(heroid) {
        if (this.currentUser) {
            return await this.hs.levelHero({ user: this.currentUser.username, token: this.currentUser.token, heroid: heroid });
        }
    }

    async resetHeroSkills(heroid, cost) {
        if (this.currentUser) {
            return await this.hs.resetHeroSkills({ user: this.currentUser.username, token: this.currentUser.token, heroid: heroid, cost: cost });
        }
    }

    async unequipCard(card) {
        if(this.currentUser) {
            let memo = await this.getKeyMemo(this.currentUser.username, 'unequip card', 'Posting');
            if (!memo) return false;
            return await this.cs.unequipCard({ user: this.currentUser.username, cid: card.cid, charClass: this.currentUser.currentClass.toLowerCase(), memo: memo, slot: card.slot });
        }
        //return await this.cs.unequipCard({ user: this.currentUser.username, charClass: this.currentUser.currentClass.toLowerCase(), cid: card.cid, slot: card.slot });
    }

    msToTime(ms) {
        let seconds = (ms / 1000) % 60;
        let minutes = (ms / (1000 * 60)) % 60;
        let hours = (ms / (1000 * 60 * 60)) % 24;
        let days = ms / (1000 * 60 * 60 * 24);
        let ret = days > 0 ? days + 'd:' : '';
        ret += hours < 24 ? hours + 'h:' : '00:';
        ret += minutes < 60 ? minutes + 'm:' : '00:';
        ret += seconds < 60 ? seconds + 's' : '00';
        return ret;
    }

    // calculate price estimation using prices from (DEC/FORGE @ soft peg)
    // https://prices.splinterlands.com/prices

    // Use following api to verify user has an account at splinterforge
    // GET https://splinterforge.io/users/verify?user=username  return {"exists":boolean}
    async getReservation(buying, qty, paying, type) {
        return await this.shop.getReservation({
            user: this.currentUser.username,
            source: 'sf_web_app', // this is where we will designate sales from your site
            purchaseToken: buying, // 'CRATE'
            qty: qty,
            paymentToken: paying, // 'DEC','FORGE','SPS','VOUCHER','SWAP.HIVE'
            type: type // 'crate pre sale'
        });
    }

    changeFlag(type, value) {
        this.auth.changeFlag({ username: this.currentUser.username, token: this.currentUser.token, type: type, value: value });
    }

    async checkKeychain() {
        if(!window.hive_keychain) {
            return false;
        } else return true;
    }

    async combineCards(card, selected) {
        let memo = await this.getKeyMemo(this.currentUser.username, 'combine cards', 'Posting');
        if (!memo) return false;

        let json = { user: this.currentUser.username, target: card.cid, cards: selected, memo: memo, date: Date.now() };
        let encoded = await this.getEncodedOperation(json, 'Active');
        if (!encoded) {
            this.showSnackbarMessage('Cancelled by user.');
            return false;
        }
        return await this.cs.combineCards({ op: encoded });
    }

    async doLogin(username, tos) {
        this.login_loading=true;
        this.login_error=false;

        let hive_keychain = window.hive_keychain;
        if(!username || username === '') {
          username = localStorage.getItem('forge:username');

          if(!username || username === '')
            return { success: false, error: 'Username not specified.' };
        }

        // Format the username properly
        username = username.toLowerCase().trim();
        if(username.startsWith('@'))
          username = username.substr(1);

        // this if for automatic login without verification
        let key = localStorage.getItem('forge:key');

        if (key && key.length > 10 && username && username.length > 1) {
            let temp: any = await this.auth.keyLogin(username, key);
            console.log(temp);
            if (temp['message'] === 'success') {
                this.data.setUser(temp);
                this.data.setConfig(temp['config']);
                localStorage.setItem('forge:username', username);
                localStorage.setItem('forge:key', this.currentUser.token);
                let u = this.currentUser;
                let diff = Math.floor((Date.now() - new Date(u.serverTime).getTime())/60000);
                localStorage.setItem('forge:diff', diff.toString());
                this.loadSLCollection();
                return true;
            } else if (temp['message'] === 'stale') {
                localStorage.removeItem('forge:key');
                this.showSnackbarMessage('Please login again.');
                this.login_results='Welcome Back! Please login.';
                this.login_loading=false;
                this.login_error=false;
                return false;
            } else {
                localStorage.removeItem('forge:key');
                this.showSnackbarMessage('Please login again.');
                this.login_results='Welcome! Please login.';
                this.login_loading=false;
                this.login_error=false;
                return false;
            }
        }

        if (!hive_keychain) {
            this.login_results='';
            this.login_loading=false;
            return 'no keychain';
        } else if(hive_keychain) {
            //let verified = await this.verifyAccountOwnership(username, 'login', 'Posting');
            let memo = await this.getKeyMemo(username, 'login', 'Posting');
            if (memo?.length > 10) {
                console.log('logging in...');
                this.login_results='Logging in...';
                this.login_loading=false;
                let temp = await this.auth.login(username, memo, tos);
                console.log(temp);
                if (temp && temp['message'] === 'ToS') {
                    localStorage.removeItem('forge:key');
                    this.showSnackbarMessage('Please agree to the Terms of Service & Privacy Policy.');
                    return 'Please agree to ToS & Privacy Policy';
                }
                if (temp['message'] === 'success') {
                    this.data.setUser(temp);
                    this.data.setConfig(temp['config']);
                    localStorage.setItem('forge:username', username);
                    localStorage.setItem('forge:key', this.currentUser.token);
                    let u = this.currentUser;
                    let diff = Math.floor((Date.now() - new Date(u.serverTime).getTime())/60000);
                    localStorage.setItem('forge:diff', diff.toString());
                    this.loadSLCollection();
                    return true;
                } else { 
                    localStorage.removeItem('forge:key');
                    localStorage.removeItem('forge:diff');
                    var message = temp['message'];
                    this.login_results=message;
                    // this.showSnackbarMessage('error logging in');
                    return false;
                }
            } else {
                console.log(memo);
                if (memo&&memo.message) return memo.message;
                else return false;
            }

        }
    }

    getCardAbilityDesc(card) {
        if (!card || !card.ability || !(card.ability.length > 1)) return '';
        if (card.ability?.includes('Force')) return card.ability;
        let ability = 0;
        if (card.ability && !card.ability.includes('Force')) {
            let ability = this.config['abilities'].find(a => a.name === card.ability);
            if (ability) {
                let byLvl = ability['ability_by_level'];
                if (byLvl && byLvl.length > 1) {
                    ability = parseInt(byLvl[card.level - 1]);
                    if (ability > 0) return '+' + ability + ' ' + card.ability;
                }
            } else console.log('ability not found');
        }
    }

    getCardDesc(card) {
        let type = card.stat.toLowerCase() + 'Levels';
        let cardStats = this.config[type];
        let stats = cardStats[card.rarity - 1];
        let stat = stats[card.level - 1];
        return '+' + stat + ' ' + card.stat;
    }

    getCompleteHeroStats(cards, hero) {
        let equippedCards = cards;
        let stats = { health: 1, armor: 1, speed: 1, attack: 0, ranged: 0, magic: 0, mana: 0, runeStats: 0, nartors: 0, forces: 0 };
        let runeTiers = [1,3,5,7];

        let dmg = '';
        if (hero.type === 'Warrior') {
            stats.attack += 1;
            dmg = 'attack';
        } else if (hero.type === 'Wizard') {
            stats.magic += 1;
            dmg = 'magic';
        } else if (hero.type === 'Ranger') {
            stats.ranged += 1;
            dmg = 'ranged';
        }
        if (!cards || cards.length === 0) return stats;

        if (equippedCards.length > 0) {
            let nartors = 0;
            let nartorsSet = [57,58,59,60,61,62];
            let forcesUsed = [];
            equippedCards.forEach(card => {
                if (card['slot'] === 'relic' && card['stat'] !== 'ability' && card['charges']['current'] > 0) {
                    if (stats[card['stat'].toLowerCase()] > 0) {
                        if (['Speed','Ranged','Attack','Magic'].includes(card.stat)) {
                            stats[card['stat'].toLowerCase()] += 3;
                        } else if (['Health','Armor'].includes(card['stat'])) {
                            stats[card['stat'].toLowerCase()] += 4;
                        }
                    }
                } else if (card['type'] === 'socket' && card['stat'] !== 'ability') {
                    let socketItems = this.config['socketItems'];
                    let found = socketItems.find(i => card.name.indexOf(i.type) > -1);
                    if (found) {
                        let currentStat = found['bonus'][card['rarity'] - 1];
                        stats[card['stat'].toLowerCase()] += currentStat;
                    }
                } else if (card['type'] === 'socket' && card['stat'] === 'ability') {
                    stats.runeStats += runeTiers[card['rarity'] - 1];
                    if (card['name'].indexOf('Force') > -1) {
                        if (card['name'].indexOf('Death') > -1) {
                            let found = forcesUsed.find(x => { return x === 'Death'; });
                            if (!found) { stats.forces += 1; forcesUsed.push('Death'); }
                        } else if (card['name'].indexOf('Life') > -1) {
                            let found = forcesUsed.find(x => { return x === 'Life'; });
                            if (!found) { stats.forces += 1; forcesUsed.push('Life'); }
                        } else if (card['name'].indexOf('Water') > -1) {
                            let found = forcesUsed.find(x => { return x === 'Water'; });
                            if (!found) { stats.forces += 1; forcesUsed.push('Water'); }
                        } else if (card['name'].indexOf('Earth') > -1) {
                            let found = forcesUsed.find(x => { return x === 'Earth'; });
                            if (!found) { stats.forces += 1; forcesUsed.push('Earth'); }
                        } else if (card['name'].indexOf('Fire') > -1) {
                            let found = forcesUsed.find(x => { return x === 'Fire'; });
                            if (!found) { stats.forces += 1; forcesUsed.push('Fire'); }
                        } else if (card['name'].indexOf('Dragon') > -1) {
                            let found = forcesUsed.find(x => { return x === 'Dragon'; });
                            if (!found) { stats.forces += 1; forcesUsed.push('Dragon'); }
                        } else if (card['name'].indexOf('Gladius') > -1) {
                            let found = forcesUsed.find(x => { return x === 'Gladius'; });
                            if (!found) { stats.forces += 1; forcesUsed.push('Gladius'); }
                        }
                    }
                } else if (card['type'] === 'equipment' && card['stat'] === 'ability') {
                    // this is for relic
                } else {
                    if (nartorsSet.includes(card['detail_id'])) nartors += 1;
                    let cardStats = this.config['statLevels'];
                    let rarityStats = cardStats[card['rarity'] - 1];
                    let currentStat = rarityStats[card['level'] - 1];
                    stats[card['stat'].toLowerCase()] += currentStat;
                }
            })

            if (hero.skills && hero.skills.length > 0) {
                let found = hero.skills.find(x => { return x.name === 'Nartors Familiarity'; });
                if (found) nartors += 1;
            }
            
            if (nartors > 3) stats.speed += 1;
            if (nartors >= 6) {
                stats.speed += 3;
                if (stats.attack > 0) stats.attack += Math.round(stats.attack * 0.1);
                else if (stats.magic > 0) stats.magic += Math.round(stats.magic * 0.1);
                else if (stats.ranged > 0) stats.ranged += Math.round(stats.ranged * 0.1);
            }
            stats.nartors = nartors;
        }

        if (hero.skills && hero.skills.length > 0) {
            let ab = hero.skills.find(x => { return x.name === 'Armor Boost'; });
            if (ab) stats.armor += ab.bonus;
            let ra = hero.skills.find(x => { return x.name === 'Reinforced Armor'; });
            if (ra) stats.armor += ra.bonus;
            let db = hero.skills.find(x => { return x.name === 'Damage Boost'; });
            if (db) stats[dmg] += db.bonus;
            let sb = hero.skills.find(x => { return x.name === 'Speed Boost'; });
            if (sb) stats.speed += sb.bonus;
            let hb = hero.skills.find(x => { return x.name === 'Health Boost'; });
            if (hb) stats.health += hb.bonus;
        }

        return stats;
    }

    /*getHeroStats(cards, currentClass) {  // search and purge
        if (!cards) return;
        let equippedCards = cards;
        let stats = { health: 1, armor: 1, speed: 1, attack: 0, ranged: 0, magic: 0, mana: 0 };

        if (currentClass === 'Warrior') stats.attack += 1;
        else if (currentClass === 'Wizard') stats.magic += 1;
        else if (currentClass === 'Ranger') stats.ranged += 1;

        if (equippedCards.length > 0) {
            let nartors = 0;
            let nartorsSet = [57,58,59,60,61,62];
            equippedCards.forEach(card => {
                if (card['type'] === 'socket' && card['stat'] !== 'ability') {
                    let socketItems = this.config['socketItems'];
                    let found = socketItems.find(i => card.name.indexOf(i.type) > -1);
                    if (found) {
                        let currentStat = found['bonus'][card['rarity'] - 1];
                        stats[card['stat'].toLowerCase()] += currentStat;
                    }
                } else {
                    if (nartorsSet.includes(card['detail_id'])) nartors += 1;
                    let cardStats = this.config['statLevels'];
                    let rarityStats = cardStats[card['rarity'] - 1];
                    let currentStat = rarityStats[card['level'] - 1];
                    stats[card['stat'].toLowerCase()] += currentStat;
                }
            })
            if (nartors > 3) stats.speed += 1;
            if (nartors === 6) {
                stats.speed += 3;
                if (stats.attack > 0) stats.attack += Math.round(stats.attack * 0.1);
                else if (stats.magic > 0) stats.magic += Math.round(stats.magic * 0.1);
                else if (stats.ranged > 0) stats.ranged += Math.round(stats.ranged * 0.1);
            }
        }

        //console.log(cards.length, stats);
        return stats;
    }*/

    heroLevelInfo(xp) {
        let total = 0;
        let next = 2000;
        let inc = 1.18;
        let level = 0;

        while (xp >= (total+next) || level === 0) {
            level += 1;
            if (level !== 1) {
                total += next;
                let step = inc-(Math.floor((level+1)/5)/100);
                if (step < 1.01) next = Math.round(next * 1.01);
                else next = Math.round(next * step);
            }
        }

        //console.log(level, next, total, xp - total + ' of ' + next + ' to level: ' + (level+1));
        return { level: level, next: next, used: total };
    }

    async getScTx() {
        if (!this.currentUser) this.navigate('');
        else return await this.auth.getScTx(this.currentUser.username);
    }

    async getSkins() {
        if (!this.currentUser) {
            this.navigate('');
            return [];
        }
        let skins: any = await this.fetch.loadSkins(this.currentUser.username);
        if (skins && this.slCards) {
            skins.forEach(s => {
                let detail = this.slCards.find(d => d['id'] === s['card_detail_id']);
                if (detail) {
                    s.name = detail['name'];
                    s.imgURL = 'https://d36mxiodymuqjm.cloudfront.net/cards_beta/splinterstorm/' + s.name + '.png';
                }
            })
            return skins;
        }
    }

    getUsedCards(cards, cids = false) {
        let usedCards = [];
        let hero = this.currentUser.heroes[this.currentUser.currentClass.toLowerCase()];
        if (!hero || !cards) return [];
        for (let key in hero) {
            if (hero[key] !== '') {
                let card = cards.find(c => c['cid'] === hero[key]);
                if (card) usedCards.push(card);
            }
        }
        cards.forEach(x => {
            if (x.sockets > 0) {
                x.socket_items.forEach(cid => {
                    if (cid !== '') {
                        let card = cards.find(c => c['cid'] === cid);
                        // find the card to push
                        let found = usedCards.find(c => c['cid'] === card.cid);
                        if (!found) usedCards.push(card);
                        found = usedCards.find(c => c['cid'] === x.cid);
                        if (!found) usedCards.push(x);
                    }
                })
            }
        });
        return cids ? [...new Set(usedCards.map((c) => c.cid))] : usedCards;
    }
    async loadShopItems() {
        let temp = await this.shop.getShopItems();
        this.data.setShopItems(temp);
    }
    async refreshUser(user = this.currentUser.username,pass = this.currentUser.token) {
        let temp = await this.auth.refreshUser({name: user, token: pass});
        this.data.setUser(temp);
        let u = this.currentUser;
        let diff = Math.floor((Date.now() - new Date(u.serverTime).getTime())/60000);
        localStorage.setItem('forge:diff', diff.toString());
    }
    async reforge(selected) {
        let memo = await this.getKeyMemo(this.currentUser.username, 'reforge cards', 'Active');
        if (!memo) return false;
        let json = { user: this.currentUser.username, cards: selected, memo: memo }
        
        let encoded = await this.getEncodedOperation(json, 'Active');
        if (!encoded) {
            this.showSnackbarMessage('Cancelled by user');
            return false;
        }
        return await this.cs.reforgeCards({ op: encoded });
    }
    async removeCIDs(cids) {
        if (!this.mySFCards) await this.getSFCards();
        else {
            this.mySFCards = this.mySFCards.filter(c => { return !cids.includes(c.cid); });
            this.data.setMySFCards(this.mySFCards);
        }
    }
    async useConsumable(item, qty) {
        let it = this.currentUser.inGameAssets.find(i => i.name === item.name);
        if (!it || it.qty < qty) {
            this.showSnackbarMessage('Not enough '+item.name.toLowerCase());
            return 400;
        }
        let temp = await this.shop.useConsumable({ username: this.currentUser.username, item: item.name, qty: qty });
        if (temp && temp.message === 'success') {
            this.refreshUser(this.currentUser.username, this.currentUser.token);
            //console.log(temp);
            return JSON.parse(JSON.stringify(temp));
            return 200;
        } else {
            //console.log(temp);
            this.showSnackbarMessage(temp.message);
            return 500;
        }
    }

    async getCardImgUrl(card) {
        let imgURL = "https://d36mxiodymuqjm.cloudfront.net/";
        let dynamic = true;
        let ed = card['editions'] === '0,1' ? 1 : parseInt(card['editions']);
        if (!ed) ed = card.edition;
        if (!ed) ed = 4;
        if (dynamic && card.type !== 'Summoner') {
            if (ed === 1 || ed === 3) imgURL += 'cards_beta/' + card.name;
            else if (ed === 4 || ed === 5) imgURL += 'cards_untamed/' + card.name;
            else if (ed === 2 || ed === 0) imgURL += 'cards_v2.2/' + card.name;
            else if (ed == 6) imgURL += 'cards_gladiator/' + card.name;
            else if (ed === 7) imgURL += 'cards_chaos/' + card.name;
            else if (ed === 8) imgURL += 'cards_riftwatchers/' + card.name;
            else if (ed === 10) imgURL += 'cards_soulbound/' + card.name;
            else if (ed === 12) imgURL += 'cards_rebellion/' + card.name;
            imgURL += card.id > 330 ? '.jpg' : '.png';
        } else {
            imgURL = "https://d36mxiodymuqjm.cloudfront.net/cards_by_level/";
            imgURL += (ed === 3 ? 'reward/' : ed === 5 ? 'dice/' : ed === 4 ? 'untamed/' : ed === 2 ? 'promo/' : ed === 7 ? 'chaos/' : ed === 8 ? 'rift/' : ed === 10 ? 'soulbound/' : ed === 12 ? 'rebellion/' : 'beta/') + (card['name'] + '_lv' + card['level'] + '.png')
        }
        return imgURL;
    }

    tempGetCardImgUrl(card) {
        let imgURL = "https://d36mxiodymuqjm.cloudfront.net/";
        let dynamic = true;
        let ed = card['editions'] === '0,1' ? 1 : parseInt(card['editions']);
        if (!ed) ed = card.edition;
        if (!ed) ed = 4;
        if (dynamic && card.type !== 'Summoner') {
            if (ed === 1 || ed === 3) imgURL += 'cards_beta/' + card.name;
            else if (ed === 4 || ed === 5) imgURL += 'cards_untamed/' + card.name;
            else if (ed === 2 || ed === 0) imgURL += 'cards_v2.2/' + card.name;
            else if (ed == 6) imgURL += 'cards_gladiator/' + card.name;
            else if (ed === 7) imgURL += 'cards_chaos/' + card.name;
            else if (ed === 8) imgURL += 'cards_riftwatchers/' + card.name;
            else if (ed === 10) imgURL += 'cards_soulbound/' + card.name;
            else if (ed === 12) imgURL += 'cards_rebellion/' + card.name;
            imgURL += card.id > 330 ? '.jpg' : '.png';
        } else {
            imgURL = "https://d36mxiodymuqjm.cloudfront.net/cards_by_level/";
            imgURL += (ed === 3 ? 'reward/' : ed === 5 ? 'dice/' : ed === 4 ? 'untamed/' : ed === 2 ? 'promo/' : ed === 7 ? 'chaos/' : ed === 8 ? 'rift/' : ed === 10 ? 'soulbound/' : ed === 12 ? 'rebellion/' : 'beta/') + (card['name'] + '_lv' + card['level'] + '.png')
        }
        return imgURL;
    }

    getAbilities(card) {
        if (this.config.replacedSkills) this.replacedAbilities = this.config.replacedSkills;
        let abilities = [];
        if (card.type === 'Summoner') {
            abilities = card.stats['abilities'] ? card.stats['abilities'] : [];
            if (card['stats']['attack'] > 0) if (!abilities.includes('+' + card['stats']['attack'] + ' Attack')) abilities.push('+' + card['stats']['attack'] + ' Attack');
            if (card['stats']['ranged'] > 0) abilities.push('+' + card['stats']['ranged'] + ' Ranged');
            if (card['stats']['magic'] > 0) abilities.push('+' + card['stats']['magic'] + ' Magic');
            if (card['stats']['speed'] > 0) abilities.push('+' + card['stats']['speed'] + ' Speed');
            if (card['stats']['health'] > 0) abilities.push('+' + card['stats']['health'] + ' Health');
            if (card['stats']['armor'] > 0) abilities.push('+' + card['stats']['armor'] + ' Armor');
            if (card['stats']['attack'] < 0) abilities.push('' + card['stats']['attack'] + ' Attack');
            if (card['stats']['ranged'] < 0) abilities.push('' + card['stats']['ranged'] + ' Ranged');
            if (card['stats']['magic'] < 0) abilities.push('' + card['stats']['magic'] + ' Magic');
            if (card['stats']['speed'] < 0 &&!abilities.includes('' + card['stats']['speed'] + ' Speed')) abilities.push('' + card['stats']['speed'] + ' Speed');
            if (card['stats']['health'] < 0) abilities.push('' + card['stats']['health'] + ' Health');
            if (card['stats']['armor'] < 0) if (!abilities.includes('' + card['stats']['armor'] + ' Armor')) abilities.push('' + card['stats']['armor'] + ' Armor');
        } else {
            if (card.stats && card.stats['abilities']) {
                for (let i = 0; i < card.level; i++) {
                    if (card['stats']['abilities'][i].length > 0) {
                        if (card['stats']['abilities'][i].length > 1) {
                            for (let j = 0; j < card['stats']['abilities'][i].length; j++) {
                                if (this.replacedAbilities.includes(card['stats']['abilities'][i][j])) abilities.push('1.5x DMG');
                                else abilities.push(card['stats']['abilities'][i][j]);
                            }
                        } else {
                            if (this.replacedAbilities.includes(card['stats']['abilities'][i][0])) abilities.push('1.5x DMG');
                            else abilities.push(card['stats']['abilities'][i][0]);
                        }
                    }
                }
            }
        }
        return [...new Set(abilities)];
    }

    async loadSLCollection() {
        if (!this.slCards) await this.loadSLCardDetails();
        if (!this.currentUser) return;
        let filtered = [];
        let cardIDs = [];
        let starter_cards = [];

        console.log('loading sl collection');
        let player = this.currentUser.username;
        if(player) {
            let collection: any;
            try {
                //collection = await this.fetch.loadSLCollection(player);
                collection = await this.fetch.loadSLCollection(player);
            } catch (e) {
                console.log(collection);
                this.showSnackbarMessage('error loading Splinterlands cards, trying again in 10 seconds...');
                setTimeout(() => { this.loadSLCollection(); }, 10000);
                return;
                //this.navigate('slcards');
            }
            //console.log(collection);
            if (collection && collection.cards) {
                collection = collection.cards;
                for (let i = 0; i < collection.length; i++) {
                    let card = collection[i];
                    var exists = filtered.find(f => f.card_detail_id === card.card_detail_id && f.level >= card.level); // is the card (or higher level version) already in the array
                    var details = this.slCards.find(f => f['id'] === card.card_detail_id); // card details
                    let controller = card.delegated_to ? card.delegated_to : card.player; // who is in control of this card
                    //let availableToPlay = controller !== card.last_used_player ? (new Date().getTime() - 1000*60*60*24 > new Date(card.last_used_date).getTime() || card.market_listing_type === 'RENT') : true; // is it on cooldown?
                    let lt = card.market_listing_type;
                    //let forSale = card.market_listing_type === 'RENT' ? false : card.market_listing_type !== null ? true : false;  // is card listed for sale?
                    let onMarket = (lt === 'SELL' || (lt === 'RENT' && controller === card.player));
                    let soulkeep = card.card_set === 'other';
                    if (soulkeep) console.log('soulkeep card');
                    if (player === controller && !onMarket && !exists && !soulkeep) {
                        //let fav = this.currentUser.settings.slFavorites ? this.currentUser.settings.slFavorites.includes(details['id']) : false;
                        //if (!details['stats']['mana'][0]) console.log(card, details);
                        if (details['type'] !== 'Summoner' && details['type'] !== 'Monster') console.log(card);
                        let newCard: any = {
                            cid: card.uid,
                            player: card.player,
                            name: details['name'],
                            color: details['color'],
                            uid: card.uid,
                            card_detail_id: details['id'],
                            id: details['id'],
                            editions: details['editions'],
                            edition: card.edition,
                            type: details['type'],
                            rarity: details['rarity'],
                            delegated_to: card.delegated_to,
                            level: card.level,
                            mana: details['type'] === 'Summoner' ? details['stats']['mana'] : details['stats']['mana'][0],
                            stats: details['stats'],
                            selected: false,
                            filtered: false,
                            imgURL: '',
                            abilities: [],
                            health: 0,
                            armor: 0,
                            speed: 0,
                            ranged: 0,
                            magic: 0,
                            attack: 0,
                            favorite: false
                        }
                        newCard.imgURL = this.tempGetCardImgUrl(newCard);
                        newCard.abilities = this.getAbilities(newCard);
                        filtered.push(newCard);
                        cardIDs.push(newCard.id);
                    }
                }
                /*starter_cards.forEach(card => {
                    if (!cardIDs.includes(card['id'])) {
                        let fav = this.currentUser.settings.slFavorites ? this.currentUser.settings.slFavorites.includes(card['id']) : false;
                        let newCard = {
                            id: card['id'],
                            player: player,
                            name: card['name'],
                            color: card['color'],
                            uid: 'starter-'+card['id']+'-90210',
                            card_detail_id: card['id'],
                            edition: card['editions'] === '0,1' ? 1 : 4,
                            editions: card['editions'],
                            type: card['type'],
                            rarity: card['rarity'],
                            delegated_to: null,
                            level: 1,
                            mana: card['type'] === 'Summoner' ? card['stats']['mana'] : card['stats']['mana'][0],
                            stats: card['stats'],
                            selected: false,
                            filtered: false,
                            imgURL: this.getCardImgUrl(card),
                            abilities: [],
                            health: 0,
                            armor: 0,
                            speed: 0,
                            ranged: 0,
                            magic: 0,
                            attack: 0,
                            favorite: fav
                        }
                        newCard.abilities = this.getAbilities(newCard);
                        filtered.push(newCard);
                    }
                });*/
                filtered.sort((a,b) => (a.color > b.color) ? 1 : ((b.color > a.color) ? -1 : 0));
                this.data.setSLCollection(filtered);
                localStorage.setItem('slCollection', JSON.stringify(filtered));
                //console.log('This is the collection of cards the game will display to you for selection', filtered);
                //let xtemp = filtered.find(x => x.card_detail_id === 330);
                //console.log(xtemp);
            } else {
                console.log('no collection or collection empty...');
                this.showSnackbarMessage('Splinterland API returned empty collection, trying again in 10 seconds...');
                setTimeout(() => { this.loadSLCollection(); }, 10000);
            }
		} else console.log('no player?!!?');
	}

    async getConfig() {
        if (!this.config || !this.config.mode) {
            let temp = await this.auth.getConfig();
            if (temp['message'] === 'success') {
                this.data.setConfig(temp);
                return temp;
            } else return null;
        } else return this.config;
    }

    async loadSLCardDetails() {
        //await this.auth.getConfig();
        let details;
        try {
            details = await this.fetch.loadSLCardDetails();
            //console.log(details);
        } catch (e) {
            console.log(e);
            this.showSnackbarMessage('error loading Splinterlands card details, trying again in 10 seconds...');
            setTimeout(() => { this.loadSLCollection(); }, 10000);
            return;
        }
        
        this.data.setCardDetails(details);
    }

    async loadBoss() {
        this.data.setLoading(true);
        let temp = await this.bs.getBosses();
        this.data.setLoading(false);
        this.data.setBoss(temp);
    }

    async loadBossBattles(id) {
        this.data.setLoading(true);
        let temp = await this.bs.getBossBattles(id);
        this.data.setLoading(false);
        return temp;
    }

    async loadBossLeaderboard(id) {
        this.data.setLoading(true);
        let temp = await this.bs.getBossLeaderboard(id);
        this.data.setLoading(false);
        return temp;
    }

    async loadLeaderboard(id) {
        this.data.setLoading(true);
        let temp = await this.bs.getLeaderboard(id);
        this.data.setLoading(false);
        return temp;
    }

    async loadEquippableCardsBySlot(slot, wearing) {
        if (!this.currentUser?.username) {
            this.navigate('');
            return;
        }
        if (!this.mySFCards) await this.getSFCards();
        if (!this.mySFCards) return [];
        //let equipped = await this.getEquippedSFCards();
        //let equippedCIDs = [...new Set(equipped.map(c => c.cid))];
        let slotCards = this.mySFCards.filter(c => {
            return c.slot === slot && (wearing ? wearing.cid !== c.cid : true);
        });
        slotCards.sort((a,b) => { return b.level - a.level });
        let equippable = [];
        let counts = { 1: 0, 2: 0, 3: 0, 4: 0 };
        for(let i=0;i<slotCards.length;i++) {
            let card = slotCards[i];
            //let included = equippedCIDs.includes(card.cid);
            //if (counts[card.rarity] < 10 && !included) {
            let found = equippable.find(c => c.name === card.name && c.level === card.level && c.foil === card.foil && c.sockets === card.sockets);
            if (found && found.sockets > 0) {
                let hasSocketedItems = false;
                card.socket_items.forEach(x => {
                    if (x !== '') hasSocketedItems = true;
                })
                if (hasSocketedItems) found = null;
            }
            //if (!included && !found) {
            if (!found && card.market_type !== 'SELL' && !card.market_id) {
                //console.log(card);
                equippable.push(card);
                //counts[card.rarity] += 1;
            }
        }
        if (equippable.length > 1) equippable.sort((a,b) => { return b.rarity - a.rarity; });
        
        return equippable;
    }
    async loadSFCardsByRarityType(rarity, type) {
        if (!this.mySFCards) await this.getSFCards();
        //if (!this.usedCIDs) 
        await this.getUsedCardIDs();
        //console.log(this.usedCIDs);
        let usedCIDs = this.usedCIDs; // await this.getUsedCIDs();
        let cards = this.mySFCards.filter(c => {
            let itemInSocket = false;
            if (c.sockets > 0) c.socket_items.forEach(item => { if (item !== '') itemInSocket = true; });
            //return !itemInSocket && !usedCIDs.includes(c.cid) && c.rarity === rarity && c.type === type && !c.foil && !c.market_id;
            return !itemInSocket && !usedCIDs.includes(c.cid) && c.rarity === rarity && c.type === type && !c.foil && !c.market_id && c.slot !== 'relic';
        });
        return cards;
    }
    async loadFullBattle(id) {
        this.data.setLoading(true);
        let temp = await this.bs.getFullBattle(id);
        this.data.setLoading(false);
        return temp;
    }
    async loadOldBosses() {
        let temp = await this.bs.getOldBosses();
        this.data.setOldBosses(temp);
    }
    async loadBalances(account) {
        let balances = await ssc.find('tokens', 'balances', { account: account }, 1000, 0, '', false);
        return balances;
    }
    async loadForgeDECShares() {
        let shares = await ssc.find('marketpools', 'liquidityPositions', { tokenPair: 'FORGE:DEC' }, 1000, 0, []);
        return shares;
    }
    async loadForgeSwapHiveShares() {
        let shares = await ssc.find('marketpools', 'liquidityPositions', { tokenPair: 'SWAP.HIVE:FORGE' }, 1000, 0, []);
        return shares;
    }
    async loadPrices() {
        let temp = await this.fetch.loadPrices();
        this.data.setPrices(temp);
    }
    getCardCharges(cid) {
        if (!this.mySFCards) return null;
        let card = this.mySFCards.find(x => { return x.cid === cid; });
        if (card) return card.charges.current;
        else return 0;
    }
    async getCardStat(card) { // potential purge (still used in active card component)
        //console.log(card);
        if (!card || !card.rarity) return;
        if (this.config && this.config['statLevels'] && this.config['socketItems'] && card) {
            let statLevels = this.config['statLevels'];
            if (card.stat !== 'ability' && card.type !== 'socket') {
                if (card.slot === 'relic') {
                    if (card.name.indexOf('Speed Relic') > -1) return 3;
                    else if (card.name.indexOf('Attack Relic') > -1) return 3;
                    else if (card.name.indexOf('Magic Relic') > -1) return 3;
                    else if (card.name.indexOf('Ranged Relic') > -1) return 3;
                    else if (card.name.indexOf('Health Relic') > -1) return 4;
                    else if (card.name.indexOf('Armor Relic') > -1) return 4;
                } else return statLevels[card.rarity-1][card.level-1];
            } else if (card.type === 'socket') {
                let value = 0;
                let socketAbilities = this.config['socketItems'];
                if (socketAbilities && socketAbilities.length > 0) {
                    for (let i = 0; i < socketAbilities.length; i++) {
                        if (card.name.indexOf(socketAbilities[i].type) > -1) {
                            let bonusByRarity = socketAbilities[i].bonus;
                            value = bonusByRarity[card.rarity-1];
                            i = socketAbilities.length;
                        }
                    }
                }
                if (card.stat !== 'ability') return value;
                //else { console.log('still need to make string for no stat abilities') };
            }
        }
    }
    async getEquippedSFCards() {
        if (!this.mySFCards) await this.getSFCards();
        let equippedCards = [];
        if(!this.currentUser) return;
        if (!this.currentUser.newHeroes) await this.getHeroCards();
        for (const hero in this.currentUser.newHeroes) {
            for (const slot in this.currentUser.newHeroes[hero]) {
                let cid = this.currentUser.newHeroes[hero][slot];
                if (cid !== '') {
                    let card = this.mySFCards.find(c => { return c.cid === cid; });
                    if (card) {
                        equippedCards.push(card);
                        if (card.sockets > 0) {
                            for (let i = 0; i < card.socket_items.length; i++) {
                                let socket = card.socket_items[i];
                                if (socket !== '') {
                                    let socketCard = this.mySFCards.find(c => c.cid === socket);
                                    if (socketCard) equippedCards.push(socketCard);
                                    else console.log('did not find gem/rune: ', socket);
                                }
                            }
                        }
                    }
                }
            }
        }
        return equippedCards;
    }
    async getSFCards(cb?) {
        if (!this.currentUser?.username) {
            this.navigate('');
            return;
        }
        this.data.setLoading(true);
        let temp = await this.cs.getSFCards(this.currentUser.username);
        this.data.setMySFCards(temp);
        this.data.setLoading(false);
        if(cb)cb()
    }
    async getGladius(summoners, forces) {
        let colors = [];
        forces.forEach(f => {
          if (f.type.indexOf('Life Force') > -1) colors.push('White');
          else if (f.type.indexOf('Earth Force') > -1) colors.push('Green');
          else if (f.type.indexOf('Death Force') > -1) colors.push('Black');
          else if (f.type.indexOf('Dragon Force') > -1) colors.push('Gold');
          else if (f.type.indexOf('Fire Force') > -1) colors.push('Red');
          else if (f.type.indexOf('Water Force') > -1) colors.push('Blue');
        });
        let missingCards = [];
        summoners.forEach(s => {
            let found = this.slCards.find(c => c['id'] === s['card_detail_id']);
            if (found && !colors.includes(found['color'])) colors.push(found['color']);
        });
        
        let t = localStorage.getItem('slCollection');
        let temp = JSON.parse(t);
        let filtered = temp.filter(x => { return x['editions'] === '6' && colors.includes(x['color']) }); // this.slCollection.filter(x => { return x['editions'] === '6' && colors.includes(x['color']) });
        for (let i = 0; i < filtered.length; i++) { missingCards.push(filtered[i]); };

        return missingCards;
    }

    getHeroAbilities(currentClass, cards) {
        if(!cards) return;
        let abilities = [
            { type: "Life Force", bonus: 0 },
            { type: "Fire Force", bonus: 0 },
            { type: "Earth Force", bonus: 0 },
            { type: "Death Force", bonus: 0 },
            { type: "Water Force", bonus: 0 },
            { type: "Dragon Force", bonus: 0 },
            { type: "Gladius Force", bonus: 0 },
            { type: "Mana Mastery", bonus: 0 },
            { type: "Vassal Mastery", bonus: 0 },
            { type: "Splash Heal", bonus: 0 },
            { type: "Hearts Blood", bonus: 0 },
            { type: "Critical Strike", bonus: 0 }
        ];
        let tempCards = [];
        if(this.currentUser.heroes) {
            let socketAbilities = this.config['socketItems'];
            let nartorsSet = [57,58,59,60,61,62];
            let nartors = 0;
            for (const key in this.currentUser.heroes[currentClass]) {
                if (this.currentUser.heroes[currentClass][key] !== '') {
                    let card = cards.find(c => c.cid === this.currentUser.heroes[currentClass][key]);
                    if (card && nartorsSet.includes(card.detail_id)) nartors += 1;
                    if (card?.sockets > 0) {
                        card.socket_items.forEach(c => {
                            if (c !== '') {
                                let socketCard = cards.find(i => i.cid === c);
                                tempCards.push(socketCard);
                            }
                        })
                    }
                }
            }
            if (nartors === 6) abilities[8].bonus += 1;
            for (let i = 0; i < tempCards.length; i++) {
                let card = tempCards[i];
                let abi = abilities.find(a => { return card.name.indexOf(a.type) > -1; });
                let abiStats;
                for (let j = 0; j < socketAbilities.length; j++) {
                    let ability = socketAbilities[j];
                    if (abi && ability && ability['type'].indexOf(abi['type']) > -1) {
                        //console.log('here...');
                        abi['bonus'] += ability['bonus'][card.rarity-1];
                    }
                }
            }
            return abilities;
        }
        return abilities;
    }

    async getMissingCardsByColor(forces) {
        let colors = [];
        forces.forEach(f => {
          if (f.type.indexOf('Life Force') > -1) colors.push('White');
          else if (f.type.indexOf('Earth Force') > -1) colors.push('Green');
          else if (f.type.indexOf('Death Force') > -1) colors.push('Black');
          else if (f.type.indexOf('Dragon Force') > -1) colors.push('Gold');
          else if (f.type.indexOf('Fire Force') > -1) colors.push('Red');
          else if (f.type.indexOf('Water Force') > -1) colors.push('Blue');
        });
        let missingCards = [];
        let t = localStorage.getItem('slCollection');
        let temp = JSON.parse(t);
        let filtered = temp.filter(x => { return x['editions'] !== '6' && colors.includes(x['color']); }); // this.slCollection.filter(x => { return x['editions'] !== '6' && colors.includes(x['color']); });
        filtered.forEach(x => { missingCards.push(x); });
        return missingCards;
    }

    async getOpenings() {
        if (this.currentUser) return await this.cs.getOpenings(this.currentUser.username);
        else return [];
    }

    async getOpening(txID) {
        return await this.cs.getOpening(txID);
    }

    async getReforgeHistory() {
        if (this.currentUser) return await this.cs.getReforgeHistory(this.currentUser.username);
        else return [];
    }

    async getTransmuteHistory() {
        if (this.currentUser) return await this.cs.getTransmuteHistory(this.currentUser.username);
        else return [];
    }

    async getPackStats(symbol) {
        return await this.shop.getPackStats(symbol, this.currentUser?.username ? this.currentUser.username : '');
    }

    getStamRegen() {
        if (!this.currentUser) return 0;
        let u = this.currentUser;
        let diff = parseInt(localStorage.getItem('forge:diff'));
        if (!diff) diff = 0;
        let short = u.stamina.max - u.stamina.current;
        if (short > 0) {
            let regen = Math.floor((Date.now() + diff - new Date(u.stamina.last).getTime())/60000);
            if (regen > short) regen = short;
            return regen;
        } else return 0;
    }

    async getUniqueSFCards(cards, newCards = false) {
        let unique = [];
        if (cards?.length > 0) {
            for (let i = 0; i < cards.length; i++) {
                let card = cards[i];
                let likeCards = cards.filter(c => {
                    return c.name === card.name && c.foil === card.foil;
                });
                likeCards.sort((a,b) => {
                    return b.level - a.level;
                });
                if (likeCards?.length > 1) card = likeCards[0];
                let found = 
                    unique.find(c => {
                        return (c.type === 'socket' || c.foil === card.foil) && 
                                c.rarity === card.rarity && 
                                c.slot === card.slot && 
                                c.name === card.name
                                });
                if (!found) unique.push(card);
            }
        }
        if (!newCards) unique.sort((a,b) => b.rarity - a.rarity || a.name.localeCompare(b.name));
        else this.shuffle(unique);
        return unique;
    }

    async getUsedCardIDs() {
        //console.log(u);
        let parts = ['weapon','offhand','head','necklace','body','hands','ring','legs','feet','back','relic'];
        let temp = [];
        let usedCIDs = [];
        if (!this.currentUser.newHeroes) await this.getHeroCards();
        let heroes = this.currentUser.newHeroes; // await Hero.find({ player: u });
    
        if (heroes.length > 0) {
            for (let i = 0; i < heroes.length; i++) {
                let hero = heroes[i];
                //console.log(hero.equipment[parts[0]]);
                for (let p = 0; p < parts.length; p++) {
                    if (hero.equipment[parts[p]] !== '') {
                        temp.push(hero.equipment[parts[p]]);
                    }
                }
            }
        }

        if (!this.mySFCards) await this.getSFCards();
    
        let cards = this.mySFCards.filter(x => { return x.sockets > 0; }); // await Card.find({ player: u, burnt: false, sockets: { $gte: 1 } });
        let equipped = this.mySFCards.filter(x => { return temp.includes(x.cid); }); // await Card.find({ player: u, burnt: false, cid: { $in: temp } });
        
        if (cards?.length > 0) {
            for (let i = 0; i < cards.length; i++) {
                let card = cards[i];
                //let found = equipped.find(c => c.cid === card.cid);
                //if (found) {
                //    usedCIDs.push(card.cid);
                //} else {
                    card.socket_items.forEach(s => {
                        if (s !== '') {
                            if (!usedCIDs.includes(card.cid)) usedCIDs.push(card.cid);
                            if (!usedCIDs.includes(s)) usedCIDs.push(s);
                        }
                    })
                //}
            }
        }
        equipped.forEach(c => {
            c.equipped = true;
            if (!usedCIDs.includes(c.cid)) usedCIDs.push(c.cid);
        });
        //console.log(usedCIDs);
        this.usedCIDs = usedCIDs;
        return usedCIDs;
    }

    async imbueCard(params) {
        if (params.user === '') params.user = this.currentUser.username;
        return await this.cs.imbueCard(params);
    }

    async isSFCardEquipped(card) { // possible purge
        if (!this.currentUser.newHeroes) await this.getHeroCards();

        let heroes = this.currentUser.newHeroes;
        let cardIsEquipped = false;
        let currentClass = '';

        for (let i = 0; i < heroes.length; i++) {
            let hero = heroes[i];
            for (const key in hero.equipment) {
                let slot = hero.equipment[key];
                if (slot !== '') {
                    if (slot === card.cid) {
                        cardIsEquipped = true;
                        i = heroes.length;
                    }
                }
            }
        }

        return { equipped: cardIsEquipped, forClass: (cardIsEquipped ? currentClass : '') };
    }

    navigate(loc, id = null) {
        let mode = this.getMode();
        //if (['no mode', 'presale'].includes(mode) && loc !== 'presale') loc = '';  // enable for pre-sale
        if (this.active !== '' && this.active !== 'profile') {
            let current = document.getElementById(this.active+'Selector');
            current.classList.remove('active');
        }
        if (loc !== '' && loc !== 'profile') {
            let newLoc = document.getElementById(loc+'Selector');
            newLoc.classList.add('active');
        }
        if (this.active === loc && this.currentUser) {
            let url = this.router.url;
            this.router.navigateByUrl('/',{skipLocationChange:true}).then(()=>{
                this.router.navigate([`/${url}`]);
            })
        } else {
            this.active = loc;
            //if (id) this.router.navigate(['/'+loc], { queryParams: { detail_id: id } });
            //else 
            this.router.navigate(['/'+loc]);
        }
    }

    openTab(event, tabName) {
        // Declare all variables
        var i, tabcontent, tablinks;

        // Get all elements with class="tabcontent" and hide them
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        // Get all elements with class="tablinks" and remove the class "active"
        if (event !== 'firstCall') {
            tablinks = document.getElementsByClassName("tablinks");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            event.currentTarget.className += " active";
        }
        // Show the current tab, and add an "active" class to the button that opened the tab
        document.getElementById(tabName).style.display = "block";
    }

    showSnackbarMessage(message) {
        message = message.slice(0,1).toUpperCase() + message.slice(1);
        this.data.setSnackbarMessage(message);
        var x = document.getElementById("snackbar");
        x.className = "show";
        setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
    }

    hideSnackbarMessage() {
        var x = document.getElementById("snackbar");
        x.className = x.className.replace("show", "");
    }

    getAbilityDesc(card) {
        if (!this.config||!card||!card.name)return;
        let abi = '';
        let desc = '';
        let socketAbilities = this.config['socketItems'];
        socketAbilities.push({ type: 'Treasure Relic', bonus: [20,20,20,20] });
        let chargeByRarity = [10,25,50,100];
        if (card.slot === 'relic') {
            if (!card.created) {
                return chargeByRarity[card.rarity-1] + ' maximum charges/uses';
            } else if (card.stat === 'ability') {
                desc = card.charges.current + ' of ' + card.charges.max + ' charges\n';
                if (card.name.indexOf('Treasure Relic') > - 1) {
                    desc += '+20% chance to find treasure';
                }
                return desc;
            } else return card.charges.current + ' of ' + card.charges.max + ' charges remaining.';
        }
        if (socketAbilities && socketAbilities.length > 0) {
            for (let i = 0; i < socketAbilities.length; i++) {
                if (card&&card.name.indexOf(socketAbilities[i].type) > -1) {
                    abi = socketAbilities[i];
                    i = socketAbilities.length;
                }
            }
            if (abi['type'] === 'Treasure Relic') {
                desc = '+' + abi['bonus'][card.rarity-1] + '% chance to find treasure items';
            } else if (abi['type'] === 'Life Force Rune') {
                desc = 'May play ' + abi['bonus'][card.rarity-1] + ' Life monsters without Life Summoner';
            } else if (abi['type'] === 'Fire Force Rune') {
                desc = 'May play ' + abi['bonus'][card.rarity-1] + ' Fire monsters without Fire Summoner';
            } else if (abi['type'] === 'Earth Force Rune') {
                desc = 'May play ' + abi['bonus'][card.rarity-1] + ' Earth monsters without Earth Summoner';
            } else if (abi['type'] === 'Death Force Rune') {
                desc = 'May play ' + abi['bonus'][card.rarity-1] + ' Death monsters without Death Summoner';
            } else if (abi['type'] === 'Water Force Rune') {
                desc = 'May play ' + abi['bonus'][card.rarity-1] + ' Water monsters without Water Summoner';
            } else if (abi['type'] === 'Dragon Force Rune') {
                desc = 'May play ' + abi['bonus'][card.rarity-1] + ' Dragon monsters without Dragon Summoner';
            } else if (abi['type'] === 'Gladius Force Rune') {
                desc = 'May play ' + abi['bonus'][card.rarity-1] + ' Gladius monsters without Gladius Summoner';
            } else if (abi['type'] === 'Mana Mastery Rune') {
                desc = 'May use ' + abi['bonus'][card.rarity-1] + ' extra mana for battle';
            } else if (abi['type'] === 'Vassal Mastery Rune') {
                desc = 'May play ' + abi['bonus'][card.rarity-1] + ' extra monsters for battle';
            } else if (abi['type'] === 'Forgium Egg') {
                desc = 'Receive ' + abi['bonus'][card.rarity-1] + '% more FORGE from boss fights';
            } else if (abi['type'] === 'Hearts Blood Rune') {
                desc = '' + abi['bonus'][card.rarity-1] + '% chance to inflict Bleeding (hero damage / 3, rounded down) for 3 turns, stackable';
            } else if (abi['type'] === 'Critical Strike Rune') {
                desc = '' + abi['bonus'][card.rarity-1] + '% chance on hit to inflict +20% damage';
            } else if (abi['type'] === 'Ruby') {
                desc = '+ ' + abi['bonus'][card.rarity-1] + ' Health';
            } else if (abi['type'] === 'Sapphire') {
                desc = '+ ' + abi['bonus'][card.rarity-1] + ' Speed';
            } else if (abi['type'] === 'Emerald') {
                desc = '+ ' + abi['bonus'][card.rarity-1] + ' Armor';
            } else if (abi['type'] === 'Obsidian') {
                desc = '+ ' + abi['bonus'][card.rarity-1] + ' Attack';
            } else if (abi['type'] === 'Turquoise') {
                desc = '+ ' + abi['bonus'][card.rarity-1] + ' Ranged';
            } else if (abi['type'] === 'Amethyst') {
                desc = '+ ' + abi['bonus'][card.rarity-1] + ' Magic';
            }
            return desc;
        }
    }

    getAbilityDescFromCID(cid,cards) {
        let desc = '';
        let item = cards.find(i => i.cid === cid);
        desc = this.getAbilityDesc(item);
        return desc;
    }

    shuffle(array) {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle.
        while (currentIndex != 0) {
      
          // Pick a remaining element.
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
    }

    async verifyAccountOwnership(username, source, type) {
        this.data.setLoading(true);
        try {
            let decodedMessage = await this.getKeyMemo(username, source, 'Posting');    

            if (decodedMessage) {
                let temp: any = await this.auth.verifyDecodedMessage(username, decodedMessage, source);
                if (temp?.message === 'success') {
                    localStorage.setItem('forge:username', username);
                    localStorage.setItem('forge:key', temp.token);
                    this.login_results = 'Successfully logged in.';
                    return true;
                } else {
                    this.login_results = 'Not able to login with HIVE Keychain.';
                    return false; 
                }
            } else {
                this.login_results = 'Verification cancelled.';
            }
            this.data.setLoading(false);
        } catch {
            this.login_results = 'HIVE keychian login cancelled.';
            this.data.setLoading(false);
        }
    }

    async getEncodedOperation (json, type) {
        if (!this.config?.postingUser || !this.config?.activeUser || ! this.currentUser?.username) return null;
        else {
            let hive_keychain = window.hive_keychain;
            let keychain_response = await new Promise(resolve => {
                hive_keychain.requestEncodeMessage(
                    this.currentUser.username, 
                    type === 'Posting' ? this.config.postingUser : this.config.activeUser, 
                    '#' + JSON.stringify(json), 
                    type, 
                    response => {
                        resolve(response);
                })
            });
            //console.log('requestEncodedMessage response:',keychain_response);
            return keychain_response['result'] ? keychain_response['result'] : null;
        }
    }

    async getKeyMemo(username, source, type) {
        let memo: any = await this.auth.requestEncodedMessage(username, source, type); // source: 'login', type: 'Posting'
        //console.log(memo);
        if (memo && memo.message === 'tooSoon') return memo;
        if (memo && memo.message === 'restricted') {
            //console.log(memo);
            this.showSnackbarMessage('This account has been restricted in connection with policy violations, please open a ticket on our discord if you would like to dispute.');
            return null;
        }
        if (memo && memo.message === 'hive node error') {
            this.showSnackbarMessage('hive node error, please try again');
            return null;
        }
        if (['fight mini boss', 'fight boss'].includes(source) && memo.message === 'success') { return 'gtg'; };
        let encodedMessage = memo?.memo;
        if (!encodedMessage) return memo;

        let hive_keychain = window.hive_keychain;
        let broadcast_promise =
        await new Promise(resolve => {
            hive_keychain.requestVerifyKey(
                username, encodedMessage, type, response => {
                    resolve({
                        message: response.message,
                        method: 'keychain',
                        success: response.success,
                        result: response.result,
                        error: response.success ? null : ((typeof response.error == 'string') ? response.error : JSON.stringify(response.error))
                    });
                });
            })

            //console.log('requestVerifyKey response:',broadcast_promise);
            //return broadcast_promise;
        
        let decodedMessage = broadcast_promise['result'];
        //console.log(broadcast_promise['result']);
        return decodedMessage && decodedMessage.length > 1 ? decodedMessage.slice(1) : decodedMessage;
    }


    // token transfer functions

    async donate(amount, symbol, memo) {
        if (amount < 1) {
            this.showSnackbarMessage('Amount must be at least 1.00.');
            return;
        }
        let fixedAmount = amount.toFixed(7).slice(0, -4);
        let hive_keychain = window.hive_keychain;
        let username = this.currentUser ? this.currentUser.username : '';
        let sendJSON = {
            'contractName' : 'tokens',
            'contractAction' : 'transfer',
            'contractPayload' : {
                'symbol' : symbol,
                'to' : 'splinterforge',
                'quantity' : fixedAmount,
                'memo' : memo
            }
        };

        //var broadcast_promise =
        return await new Promise(resolve => {
            hive_keychain.requestCustomJson(
                username, 'ssc-mainnet-hive', 'Active', JSON.stringify(sendJSON),
                'Donating ' + fixedAmount + ' ' + symbol + ' to SplinterForge', response => {
                    //console.log(response);
                    resolve({
                        type: 'broadcast',
                        method: 'keychain',
                        success: response.success,
                        trx_id: response.success ? response.result.id : null,
                        error: response.success ? null : ((typeof response.error == 'string') ? response.error : JSON.stringify(response.error))
                    });
                });
            })
    }

    async depositToSF(amount, symbol) {
        let mode = this.getMode();
        if (!['presale','live'].includes(mode)) {
            this.showSnackbarMessage('Only available on live server.');
            return;
        }
        if (amount < 1) {
            this.showSnackbarMessage('Amount must be at least 1.00');
            return;
        }
        let fixedAmount = amount.toFixed(7).slice(0, -4);
        let hive_keychain = window.hive_keychain;
        let username = this.currentUser.username;
        let balances = await this.loadBalances(username);
        let sendJSON = {
            'contractName' : 'tokens',
            'contractAction' : 'transfer',
            'contractPayload' : {
                'symbol' : symbol,
                'to' : 'forgemerchant',
                'quantity' : fixedAmount,
                'memo' : 'transfer ' + fixedAmount + ' ' + symbol + ' tokens to Splinterforge.io by ' + username
            }
        };

        //var broadcast_promise =
        return await new Promise(resolve => {
            hive_keychain.requestCustomJson(
                username, 'ssc-mainnet-hive', 'Active', JSON.stringify(sendJSON),
                'Transfering ' + fixedAmount + ' ' + symbol + ' to game by: ' + username, response => {
                    //console.log(response);
                    resolve({
                        type: 'broadcast',
                        method: 'keychain',
                        success: response.success,
                        tx_id: response.success ? response.result.id : null,
                        error: response.success ? null : ((typeof response.error == 'string') ? response.error : JSON.stringify(response.error))
                    });
                });
            })
    }

    async verifyUser(user) {
        return await this.auth.verifyUser(user);
    }
    async transferCardToPlayer(receiver, cids) {
        let mode = this.getMode();
        if (!['live'].includes(mode)) {
            this.showSnackbarMessage('Only available on live server.');
            return;
        }
        let hive_keychain = window.hive_keychain;
        let username = this.currentUser.username;
        let exists = await this.verifyUser(receiver); // await this.auth.verifyUser(receiver);
        //console.log(exists);
        if (exists && !exists['exist']) {
            this.showSnackbarMessage('Receiving user not found...');
            return { message: 'receiving user not found' };
        }

        var keychain_result = await new Promise(resolve => {
            hive_keychain.requestCustomJson(
                username, 
                'sf_transfer_cards', 
                'Active', 
                JSON.stringify({ to: receiver, username: username, cards: cids, memo: username + ' transfering ' + cids.length + ' cards to ' + receiver }),
                username + ' transfering ' + cids.length + ' cards to ' + receiver, 
                response => {
                    resolve(response);
                });
            })
        return keychain_result['success'] ? { message: 'success' } : { message: 'transaction cancelled by user' };
    }

    async transferToHE(amount, symbol) {
        let mode = this.getMode();
        if (!['presale','live'].includes(mode)) {
            this.showSnackbarMessage('Only available on live server.');
            return;
        }
        let hive_keychain = window.hive_keychain;
        let username = this.currentUser.username;

        return await new Promise(resolve => {
            hive_keychain.requestCustomJson(
                username, 'sf_withdraw_token', 'Active', JSON.stringify({ username: username, symbol: symbol, to: username, amount: amount, memo: username + ' requesting ' + amount + ' ' + symbol + ' moved to Hive-Engine' }),
                username + ' requesting ' + amount + ' ' + symbol + ' moved to Hive-Engine', response => {
                    //console.log(response);
                    resolve({
                        type: 'broadcast',
                        method: 'keychain',
                        success: response.success,
                        tx_id: response.success ? response.result.id : null,
                        error: response.success ? null : ((typeof response.error == 'string') ? response.error : JSON.stringify(response.error))
                    });
                });
            })
    }

    async transferTokenToPlayer(receiver, amount, symbol) {
        let mode = this.getMode();
        if (!['','live'].includes(mode)) {
            this.showSnackbarMessage('Only available on live server.');
            return;
        }
        let hive_keychain = window.hive_keychain;
        let username = this.currentUser.username;
        if (username === receiver) {
            this.showSnackbarMessage('You already own these, sending them to yourself will not change that...');
            return;
        }
        let exists = await this.fetch.verifySLUser(receiver);
        //console.log(exists);
        if (exists && exists['error']) {
            this.showSnackbarMessage('Receiving user not found...');
            return;
        }
        
        return await new Promise(resolve => {
            hive_keychain.requestCustomJson(
                username, 
                'sf_transfer_token', 
                'Active', 
                JSON.stringify({ to: receiver, qty: amount, symbol: symbol, username: username, memo: username + ' transfering ' + amount + ' ' + symbol + ' to ' + receiver }),
                username + ' transfering ' + amount + ' ' + symbol + ' to ' + receiver, 
                response => {
                    //console.log(response);
                    resolve({
                        type: 'broadcast',
                        method: 'keychain',
                        success: response.success,
                        trx_id: response.success ? response.result.id : null,
                        error: response.success ? null : ((typeof response.error == 'string') ? response.error : JSON.stringify(response.error))
                    });
                });
            })
    }

    async transferSkins(id, to) {
        let mode = this.getMode();
        if (!['presale','live'].includes(mode)) {
            this.showSnackbarMessage('Only available on live server.');
            return;
        }
        let hive_keychain = window.hive_keychain;
        let username = this.currentUser.username;
        let exists = await this.fetch.verifySLUser(to);
        //console.log(exists);
        if (exists && exists['error']) {
            this.showSnackbarMessage('Receiving user not found...');
            return;
        }
        //var broadcast_promise =
        return await new Promise(resolve => {
            hive_keychain.requestCustomJson(
                username, 'sm_transfer_skins', 'Active', JSON.stringify({ to: to, skins: [{skin:'splinterstorm', card_detail_id: id}] }),
                'Transfering skin id ' + id + ' to ' + to, response => {
                    //console.log(response);
                    resolve({
                        type: 'broadcast',
                        method: 'keychain',
                        success: response.success,
                        trx_id: response.success ? response.result.id : null,
                        error: response.success ? null : ((typeof response.error == 'string') ? response.error : JSON.stringify(response.error))
                    });
                });
            })
        //console.log(broadcast_promise)
    }

    async openPacks(symbol, qty) {
        let mode = this.getMode();
        if (!['live'].includes(mode)) {
            let memo = await this.getKeyMemo(this.currentUser.username, 'open packs', 'Active');
            if (!memo) return false;
            let json = { user: this.currentUser.username, name: (symbol === 'CRATE' ? 'Alpha Pack' : 'Enhancement Pack'), qty: qty, memo: memo }
            
            let encoded = await this.getEncodedOperation(json, 'Active');
            if (!encoded) {
                this.showSnackbarMessage('Cancelled by user');
                return false;
            }
            return await this.cs.openPacks({ op: encoded });
        }

        let hive_keychain = window.hive_keychain;
        let username = this.currentUser.username;

        var res = await new Promise(resolve => {
            hive_keychain.requestCustomJson(
                username, 
                'sf_open_packs', 
                'Active', 
                JSON.stringify({ username: username, symbol: symbol, qty: qty, memo: username + ' opening ' + qty + ' ' + symbol }),
                username + ' opening ' + qty + ' ' + symbol, 
                response => {
                    resolve(response);
                });
            })
        //console.log(res);
        if (res['success']) {
            let data = res['result'];
            return { message: 'success', id: data['id'] ? data['id'] : data['tx_id'] };
        } else return { message: 'transaction cancelled by user' }
    }

    async verifyTx(tx, source) {
        let ret;
        let attempts = 0;
        while (!ret && attempts < 7) {
            await this.waitMS(4500);
            let temp = await this.shop.verifyTx({ tx: tx, source: source });
            //console.log(temp);
            if (temp['message'] === 'success') ret = temp;
            attempts += 1;
        }

        if (!ret) return null;
        else return ret;
    }

    async waitMS(t) {
        return new Promise(resolve => {
            setTimeout(resolve, t);
        });
    }

}
