import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { trigger, state, animate, transition, style } from '@angular/animations';
export const selected = trigger('selected',[
    state('true', style({
        'transform': 'translateY(-7px)',
    })),
    state('off', style({
        'transform': 'translateY(0px)',
    })),
    state('false', style({
        'transform': 'translateY(0px)',
    })),
    transition('*=>true', animate('170ms')),
    transition('true=>*', animate('220ms')),
])
export const jump = trigger('jump',[
    state('true', style({
        'transform': 'translateY(-7px)',
    })),
    state('off', style({
        'transform': 'translateY(0px)',
    })),
    transition('false=>true', animate('750ms ease-out')),
    transition('true=>false', animate('150ms ease-out')),
])
export const slow_fade = trigger('slow_fade',[
    state('true', style({
        'opacity':'1'
    })),
    state('false', style({
        'opacity':'0'
    })),
    transition('*=>*', animate('220ms 1950ms ease-out'))
])
export const darken = trigger('darken',[
    state('true', style({
        'filter': 'brightness(0.5)'
    })),
    state('false', style({
        'filter': 'brightness(1)'
    })),
    transition('*=>*', animate('330ms ease-out'))
])
export const popin = trigger('popin', [
    state('false', style({
        'transform':'scale(0)'
    })),
    state('true', style({
        'opacity':'1'
    })),
    transition('false=>true', animate('300ms 2300ms')),
    transition('true=>false', animate('0ms'))
])
export const popin_fx = trigger('popin_fx', [
    state('false', style({
        'transform':'scale(0)'
    })),
    state('true', style({
        'transform':'scale(1)'
    })),
    transition('false=>true', animate('300ms 2300ms')),
    transition('true=>false', animate('300ms'))
])
export const quick_fade = trigger('quick_fade',[
    state('true', style({
        'opacity':'1'
    })),
    state('false', style({
        'opacity':'0'
    })),
    transition('*=>*', animate('170ms ease-out'))
])
export const quicker_fade = trigger('quicker_fade',[
    state('true', style({
        'opacity':'1',
        'transform':'scale(1) translateY(0%)'
    })),
    state('false', style({
        'opacity':'0',
        'transform':'scale(0.6) translateY(40%)'
    })),
    transition('*=>*', animate('70ms ease-out'))
])
export const sepia_fire = trigger('sepia_fire',[
    state('true', style({
        'filter': 'sepia(0.8) saturate(1.2) brightness(0.8)'
    })),
    state('false', style({
        'filter': 'sepia(0) saturate(1) brightness(1)'
    })),
    state('down', style({
        'filter': 'sepia(0.8) saturate(0) brightness(0)'
    })),
    transition('false=>true', animate('220ms 120ms ease-out')),
    transition('true=>down', animate('2220ms ease-out'))
])
export const sepia_forge = trigger('sepia_forge',[
    state('true', style({
        'filter': 'sepia(0.45) saturate(1.4) brightness(1.16)'
    })),
    state('false', style({
        'filter': 'sepia(0) saturate(1) brightness(1)'
    })),
    transition('false=>true', animate('330ms 570ms ease-out')),
    transition('false=>true', animate('130ms 0ms ease-out'))
])
export const sepia_forge_attack = trigger('sepia_forge_attack',[
    state('true', style({
        'filter': 'brightness(113%) contrast(83%)'
    })),
    state('false', style({
        'filter': 'brightness(100%) contrast(100%)'
    })),
    transition('false=>true', animate('330ms 570ms ease-out')),
    transition('false=>true', animate('130ms 0ms ease-out'))
])
export const blinking = trigger('blinking',[
    state('1', style({
        'filter': 'brightness(1.1)',
    })),
    state('2', style({
        'filter': 'brightness(1)',
    })),
    state('3', style({
        'filter': 'brightness(0.9)',
    })),
    state('4', style({
        'filter': 'brightness(0.9)',
    })),
    state('off', style({
        'filter': 'brightness(1)',
    })),
    transition('false => 1', animate('50ms ease-out')),
    transition('1 => 2', animate('50ms ease-out')),
    transition('2 => 3', animate('50ms ease-out')),
    transition('3 => 4', animate('50ms ease-out')),
    transition('4 => off', animate('50ms ease-out'))
])
export const ranged = trigger('ranged',[
    state('1', style({
        'transform': 'translateY(-6px) rotate(0deg)',
    })),
    state('2', style({
        'transform': 'translateY(-6px) translateX(-3px) rotate(-6deg)',
    })),
    state('3', style({
        'transform': 'translateY(-9px) translateX(1px) rotate(-4deg)',
    })),
    state('4', style({
        'transform': 'translateY(-11px) translateX(2px) rotate(-4deg)',
    })),
    state('off', style({
        'transform': 'translateY(0px) translateX(0px) rotate(0deg)',
    })),
    transition('false => 1', animate('150ms ease-out')),
    transition('1 => 2', animate('300ms ease-out')),
    transition('2 => 3', animate('100ms ease-out')),
    transition('3 => 4', animate('220ms ease-out')),
    transition('4 => false', animate('250ms ease-out'))
])
export const attacking = trigger('attacking',[
    state('1', style({
        'transform': 'translateY(-6px) rotate(0deg)',
    })),
    state('2', style({
        'transform': 'translateY(-6px) translateX(-3px) rotate(-6deg)',
    })),
    state('3', style({
        'transform': 'translateY(-7px) translateX(1px) rotate(3deg)',
    })),
    state('off', style({
        'transform': 'translateY(0px) rotate(0deg)',
    })),
    transition('false => 1', animate('150ms ease-out')),
    transition('1 => 2', animate('300ms ease-out')),
    transition('2 => 3', animate('150ms ease-out')),
    transition('3 => false', animate('250ms ease-out'))
])
export const attack = trigger('attack',[
    state('true', style({
        'border': '3px solid gray'
    })),
    state('false', style({
    })),
    transition('*=>*', animate('122ms'))
])
export const blend = trigger('blend',[
    state('multiply', style({
        'mix-blend-mode': 'multiply'
    })),
    state('plus-lighter', style({
        'mix-blend-mode': 'plus-lighter'
    })),
    state('false', style({
        'mix-blend-mode': 'none'
    })),
    transition('*=>*', animate('0ms'))
])
export const miss = trigger('miss',[
    state('true', style({
        'transform': 'translateX(12px)'
    })),
    state('off', style({
        'transform': 'translateX(0px)'
    })),
    transition('*=>*', animate('400ms ease-out'))
])
export const hit = trigger('hit',[
    state('true', style({
        'transform':'scaleY(1) rotate(0deg)'//0.87//-4deg //translateY(10px) translateX(-2px) 
    })),
    state('off', style({
        'transform':'translateY(0px) translateX(0px) scaleY(1) rotate(0deg)'
    })),
    transition('*=>*', animate('470ms ease-out'))
])
export const hit_magic = trigger('hit_magic',[
    state('true', style({
        'filter': 'hue-rotate(-22deg)'
    })),
    state('off', style({
        'filter': 'hue-rotate(0deg)'
    })),
    transition('*=>*', animate('470ms ease-out'))
])
export const died = trigger('died',[
    state('true', style({
        'filter': 'saturate(0.13) sepia(0.05) '
    })),
    state('false', style({
        'filter': 'saturate(1)'
    })),
    transition('*=>*', animate('2270ms'))
])
export const shake = trigger('shake',[
    state('1', style({
        'transform': 'translateX(-2px)'
    })),
    state('2', style({
        'transform': 'translateX(2px)'
    })),
    state('3', style({
        'transform': 'translateX(-3px)'
    })),
    state('4', style({
        'transform': 'translateX(3px)'
    })),
    state('off', style({
        'transform': 'translateX(0px)'
    })),
    transition('false => 1', animate('60ms')),
    transition('1 => 2', animate('60ms')),
    transition('2 => 3', animate('80ms')),
    transition('3 => 4', animate('80ms')),
    transition('4 => false', animate('80ms'))
])
export const hit_damage = trigger('hit_damage',[
    state('true', style({
        // 'transform':'translateY(10px) translateX(-2px) scaleY(0.87) rotate(-4deg)'
    })),
    state('off', style({
        // 'transform':'translateY(0px) translateX(0px) scaleY(1) rotate(0deg)'
    })),
    transition('*=>*', animate('470ms ease-out'))
])
export const hit_damage_type = trigger('hit_damage_type',[
    state('true', style({
        'filter':'sepia(1)'
    })),
    state('false', style({
        'filter':'sepia(0)'
    })),
    transition('*=>*', animate('270ms'))
])
export const fade = trigger('fade',[
    state('true', style({
        'opacity':'1'
    })),
    state('false', style({
        'opacity':'0'
    })),
    transition('*=>*', animate('270ms'))
])
export const visibility = trigger('visibility',[
    state('true', style({
        'display':'block',
        'opacity':'1'
    })),
    state('false', style({
        'display':'none',
        'opacity':'0'
    })),
    transition('*=>*', animate('270ms'))
])
export const active_stat = trigger('active_stat', [
    state('true', style({
        'transform': 'scale(0.93)'
    })),
    state('false', style({
        'transform': 'scale(1)'
    })),
    transition('*=>*', animate('300ms'))
]);
export const active_overlay = trigger('active_overlay', [
    state('true', style({
        'transform': 'scale(1.25)'
    })),
    state('false', style({
        'transform': 'scale(1)'
    })),
    transition('*=>*', animate('300ms'))
]);