import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HelperService } from '../../_services/helper.service';
import { fade, flip_up, pointer, boxlarge, boxsmaller, field_error } from '../../app.animations';
@Component({
  selector: 'fightSummary',
  animations: [fade, flip_up, pointer, boxlarge, boxsmaller, field_error],
  templateUrl: './fightSummary.component.html',
  styleUrls: [ './fightSummary.component.less']
})
export class FightSummaryComponent implements OnInit {
  
  @Input('show') show = true;
  @Input('battleLog') battleLog;
  @Output('done') done = new EventEmitter();
  
  constructor (public helper: HelperService) { }
  
  ngOnInit() { }
  
  close() {
    this.done.emit(0);
  }
}