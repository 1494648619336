import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'uid'
})
export class UIDPipe implements PipeTransform {
	transform(value: any, args?: any): any {
    if(args == -1 || args == 'All' || args == false) return value;
		let values = [];
		for (var i = 0; i < value.length; i++) {
			if (String(value[i].type) === String(args)) values.push(value[i]);
		}
		return values;
	}
}