import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'id'
})
export class IDPipe implements PipeTransform {
	transform(value: any, args?: any): any {
		let values = [];
		for (var i = 0; i < value.length; i++) {
			if (value[i].id == args) values.push(value[i]);
		}
		return values;
	}
}