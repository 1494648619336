<div class="background night" [@fade]="!fight_screen"></div> 
<div class="fight background" [@fade]="fight_screen"></div>
<div class="black foreground" [@fade]="fight_bg"></div>

<div class="page_heading">
    <h1>Boss Battle
        <h4 *ngIf="view === 'main' && !lockBoss">
            Select a Boss
        </h4>
        <h4 *ngIf="summoners_selected === 2 && (boss && view != 'result' && lockBoss)">
            Select Monster Cards
        </h4>
        <h4 *ngIf="summoners_selected < 2 && (boss && view != 'result' && lockBoss)">
            Select Summoner Cards
        </h4>
        <h4 *ngIf="fight_screen">
            {{fighting?'Fighting':'Fight'}}
        </h4>
    </h1>
    <div class="damage" *ngIf="fight_screen">
        <span class="tablinks start_fight_btn">Damage: <span class="damage">{{Damage}}</span></span>
    </div>
    <div class="tab_bar">
        <button [tabindex]="12" *ngIf="boss || lockBoss && !fight_screen" [disabled]="running" (click)="navigate('slcards')" class="tablinks select_boss_button">
            Pick New Boss
        </button>
        <button *ngIf="view!='result'" [disabled]="loading" 
                [tabindex]="13"
                [attr.class]="showRules?'active tablinks rule_change_btn':'tablinks rule_change_btn'" 
                (click)="showRules=showRules?false:true">
            Ability & Rule Changes
        </button>
        <button *ngIf="view!='result' && 1>2" [disabled]="loading" 
                [tabindex]="13"
                class="tablinks rule_change_btn"
                (click)="doSomething()"
                title="Refresh your Splinterlands collection, use this after you rent/buy new/higher level cards for your collection.">
                &#8634;
        </button>
        <button *ngIf="!fighting && fight_screen" [disabled]="running" (click)="fighting=true" class="tablinks start_fight_btn">
            Start Fight!
        </button>
        <button *ngIf="!viewRewards && !battle_paused && fight_screen" [disabled]="running" (click)="skipToRewards()" class="tablinks start_fight_btn">
            <span>Skip to Rewards</span>
        </button>
        <div *ngIf="!viewRewards && fight_screen" class="log_speed_adjust">
            <button class="tablinks speed_change_btn speedadjust minus" (click)="changeLogSpeed(100)">
                <i class="fa-solid fa-minus"></i>
            </button>
            <button class="tablinks speed_change_btn speedadjust log_speed" (click)="pauseLogSpeed()">
                <span class="pause">
                    <i *ngIf="fighting && !battle_paused" class="fa-solid fa-pause"></i>
                    <i *ngIf="fighting && battle_paused" class="fa-solid fa-play"></i>
                    <i *ngIf="!fighting" class="fa-solid fa-play"></i>
                </span>
                <span class="playback">
                    <i *ngIf="battle_paused" class="fa-solid fa-play"></i>
                    <span *ngIf="!battle_paused">{{1000-logSpeed}}</span>
                </span>
            </button>
            <button class="tablinks speed_change_btn speedadjust plus" (click)="changeLogSpeed(-100)">
                <i class="fa-solid fa-plus"></i>
            </button>
        </div>
        <button *ngIf="fighting && fight_screen" [disabled]="running" (click)="pauseLogSpeed()" class="tablinks start_fight_btn">
            <span *ngIf="!viewRewards && !battle_paused">In Battle</span>
            <span *ngIf="!viewRewards && battle_paused">Paused</span>
            <span *ngIf="viewRewards" (click)="navigate('slcards')">Battle Again</span>
        </button>
    </div>
    <div class="bottom_border"></div>
    <div class="clear"></div>
</div>
<stamina-elixirs 
    [show]="display_no_staimina" 
    (done)="required_done($event)" 
    [low_stamina]="true">
</stamina-elixirs>
<loading-modal
    [show]="fight_loading" 
    [title]="'Please Wait'"
    [label]="'Please wait. The battle is loading...'"
    [subtitle]="'Fight Loading'"
    [color]="'green'"
    (done)="fight_close_loading($event)">
</loading-modal>
<div id="Cards">
    <section *ngIf="view==='main'&&!lockBoss" class="yourteam" [attr.id]="boss?'':'bossselectmenu'">
        <div class="inner">
            <div *ngIf="boss" class="{{boss ? 'teampicked boss' : 'leftside'}}">
                <div [attr.class]="'boss '+(!boss.uniqueRules?'rules_included none':'rules_included ')">
                    <div class="rules_box">
                        <div *ngIf="boss.endTime"></div>
                        <div class="BossMaxLevels">
                            <div class="rules_title">Max Monster Levels</div>
                            <boss-max-levels [small]="true" [maxLevels]="maxLevels"></boss-max-levels>
                        </div>
                        <div [attr.class]="!config.heroStatMaxTiers?'max_tiers none':'max_tiers'">
                            <div class="popover">These are the maximum stats a <b>Hero</b> will go into battle with against this tier of <b>Boss</b>.  If your hero has higher stats, they will be reduced to this level.
                            </div>
                            <div class="rules_title">Max Hero Stats</div>
                            <div class="max_stats_box" *ngFor="let dmg of config.heroStatMaxTiers | slice:boss.type[1]-1:boss.type[1]">
                                <card-stats-icons 
                                    [smaller]="true"
                                    [extra_smaller]="true"
                                    [armor]="dmg.armor"
                                    [attack]="dmg.dmg"
                                    [health]="dmg.health"
                                    [speed]="dmg.speed">
                                </card-stats-icons>
                            </div>
                        </div>
                    </div>
                    <div class="bosswrapper">
                        <div class="overlay">
                           <card-league-icon [type]="boss.type"></card-league-icon>
                        </div>
                        <img src="https://splinterforge.s3.us-east-2.amazonaws.com/frame_art/alpha_frame.png" class="frame" loading="lazy">
                        <div class="imagewrapper">
                            <img src="/assets/boss/{{boss.name}}.png" loading="lazy">
                        </div>
                        <card-stats-icons
                            [show_mana]="false" 
                            [attack]="boss.stats.attack"
                            [ranged]="boss.stats.ranged"
                            [magic]="boss.stats.magic"
                            [speed]="boss.stats.speed"
                            [stats]="boss.stats"
                            [smaller]="false"
                            [boss]="true"
                            [og_health]="boss.ogStats.health"
                            [health]="boss.stats.health"
                            [og_armor]="boss.ogStats.armor"
                            [armor]="boss.stats.armor"
                            [showog]="true"
                            [ogstats]="boss.ogStats">
                        </card-stats-icons>
                        <div class="titlewrap"><h3>{{boss.name}}</h3></div>
                        <card-bars
                            [small]="'mid'"
                            [armor]="boss.stats.armor"
                            [original_armor]="boss.ogStats.armor"
                            [health]="boss.stats.health"
                            [original_health]="boss.ogStats.health">
                        </card-bars>
                        <div class="statswrap">
                            <card-abilities [abilities]="boss.stats.abilities">
                            </card-abilities>
                        </div>
                    </div>
                    <div *ngIf="!boss.uniqueRules || boss.uniqueRules.length < 1" class="no_rules_button black button" (click)="get_rules(boss.type)">
                        Get Rules
                    </div>
                    <div [attr.class]="!boss.uniqueRules?'rules none':'rules'">
                        <div class="popover">
                            These abilities are assigned for this fight against this boss, they will refresh after every fight.
                        </div>
                        <div *ngIf="boss.uniqueRules">
                            <div class="rules_title">Random Rules</div>
                            <card-abilities
                                [show_card_abilities]="true"
                                [smaller]="true"
                                [abilities]="boss.uniqueRules">
                            </card-abilities>
                        </div>
                    </div>
                </div>
                <div class="nextbutton" *ngIf="boss">
                    <button *ngIf="!lockBoss && boss.stats.health > 0" 
                            tabindex="14" 
                            [attr.class]="night?'battle_button night':'battle_button'"
                            (click)="lockBoss = true">
                            <div class="b_bottom"></div><div class="b_right"></div><div class="b_left"></div><div class="b_top"></div>
                        Battle
                        <img src="/assets/menu/menu-boss.png">
                    </button>
                    <button *ngIf="!lockBoss && boss.stats.health < 1" 
                        tabindex="14"
                        class="battle_button red"
                        (click)="new_boss()">
                        <div class="b_bottom"></div><div class="b_right"></div><div class="b_left"></div><div class="b_top"></div>
                        Boss is Dead
                    </button>
                </div>

                <button tabindex="15" [attr.class]="display_leaderboard==true?'button active purple view-leaderboards':'button purple view-leaderboards'" (click)="display_myFights=false;display_leaderboard=true;display_recents=false;display_rewards=false;auto_adj()">
                    Leaderboards
                </button>
                <button tabindex="16" [attr.class]="display_recents==true?'button active purple view-leaderboards':'button purple view-leaderboards'" (click)="display_myFights=false;display_recents=true;display_leaderboard=false;display_rewards=false;auto_adj()">
                    Recent Battles
                </button>
                <button *ngIf="myFights && myFights.length > 0" tabindex="17" [attr.class]="display_myFights==true?'button active purple view-leaderboards':'button purple view-leaderboards'" (click)="display_myFights=true;display_recents=false;display_leaderboard=false;display_rewards=false;auto_adj()">
                    My Battles
                </button>
                <button *ngIf="1>0" tabindex="18" [attr.class]="display_rewards==true?'button active purple view-leaderboards':'button purple view-leaderboards'" (click)="display_myFights=false;display_recents=false;display_leaderboard=false;display_rewards=true;auto_adj()">
                    Rewards
                </button>
                <button tabindex="19" *ngIf="display_recents || display_leaderboard || display_rewards || display_myFights" class="button purple view-leaderboards" (click)="navigate('slcards')">
                    Back
                </button>
            </div>
            <div *ngIf="!lockBoss && bosses" class="{{boss ? 'cards' : 'rightside'}}"> <!--  [@fullwidth]="!boss" 
                <h4 *ngIf="display_leaderboard">Leaderboard: {{boss.name}}</h4>-->
                <h4 *ngIf="display_recents">Recent Battles: {{boss.name}}</h4>
                <div class="bossbuttons" *ngIf="!display_leaderboard && !display_recents && !display_rewards && !display_myFights">
                    <div class="loader_icons" *ngIf="!bosses"><div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
                    <div *ngFor="let b of bosses; let i = index;" 
                         [@fade]="(imgL[i]>0)?true:false"
                         [tabindex]="i+14"
                         (keyup.enter)="selectBoss(b.type)"
                         [attr.class]="'itembox '+(boss&&boss.type==b.type?'active ':'')+(!rules?'rules_included none':' rules_included ')">
                         <div class="selector"></div>
                        <div class="rules_box">
                            <div class="BossMaxLevels" (click)="selectBoss(b.type)">
                                <div class="rules_title">Max Monster Levels</div>
                                <boss-max-levels [small]="true" [maxLevels]="b.max_levels"></boss-max-levels>
                            </div>
                            <div (click)="selectBoss(b.type)" [attr.class]="!config.heroStatMaxTiers?'max_tiers none':'max_tiers'">
                                <div class="popover">These are the maximum stats a <b>Hero</b> will go into battle with against this tier of <b>Boss</b>.  If your hero has higher stats, they will be reduced to this level.
                                </div>
                                <div class="rules_title">Max Hero Stats</div>
                                <div class="max_stats_box" *ngFor="let dmg of config.heroStatMaxTiers | slice:b.type[1]-1:b.type[1]">
                                    <card-stats-icons 
                                        [smaller]="true"
                                        [extra_smaller]="true"
                                        [armor]="dmg.armor"
                                        [attack]="dmg.dmg"
                                        [health]="dmg.health"
                                        [speed]="dmg.speed">
                                    </card-stats-icons>
                                </div>
                            </div>
                        </div>
                        <div class="boss__wrapper" (click)="selectBoss(b.type)">
                            <div class="overlay">
                               <card-league-icon [type]="b.type"></card-league-icon>
                            </div>
                            <img src="https://splinterforge.s3.us-east-2.amazonaws.com/frame_art/alpha_frame.png" class="frame" (load)="imgLoad(i)" loading="lazy">
                            <div class="imagewrapper">
                              <img src="/assets/boss/{{b.name}}.png" (load)="imgLoad(i)" loading="lazy">
                            </div>
                            <div class="title">{{b.name}}</div>
                            <card-stats-icons
                                [armor_top]="true" 
                                [show_mana]="false" 
                                [attack]="b.stats.attack"
                                [ranged]="b.stats.ranged"
                                [magic]="b.stats.magic"
                                [speed]="b.stats.speed"
                                [stats]="b.stats"
                                [boss]="true"
                                [force_show_stats]="true"
                                [og_health]="b.ogStats.health"
                                [health]="b.stats.health"
                                [og_armor]="b.ogStats.armor"
                                [armor]="b.stats.armor"
                                [showog]="true"
                                [smaller]="false"
                                [ogstats]="b.ogStats">
                            </card-stats-icons>
                            <card-bars
                                [small]="'smaller'"
                                [armor]="b.stats.armor"
                                [original_armor]="b.ogStats.armor"
                                [health]="b.stats.health"
                                [original_health]="b.ogStats.health">
                            </card-bars>
                            <div class="statswrap">
                                <card-abilities [abilities]="b.stats.abilities"></card-abilities>
                            </div>
                        </div>
                        <div *ngIf="!b.uniqueRules || b.uniqueRules.length < 1" class="no_rules_button black button" (click)="get_rules(b.type)">
                            Get Rules
                        </div>
                        <div *ngIf="b.uniqueRules" class="reroll_button black button" (click)="rerollRules(b)" title="All FORGE spent on rerolls will be BURNT!">
                            <i class="fa-solid fa-fire"></i> Reroll for {{rerollPrice(b)}} FORGE <i class="fa-solid fa-fire"></i>
                        </div>
                        <div class="rules" *ngIf="b.uniqueRules">
                            <div class="popover">
                                These abilities are assigned for this fight against this boss, they will refresh after every fight.
                            </div>
                            <div (click)="selectBoss(b.type)">
                                <!--  *ngIf="!b.rules" -->
                                <div class="rules_title">Random Rules</div>
                                <card-abilities 
                                    [show_card_abilities]="true" 
                                    [smaller]="true" 
                                    [abilities]="b.uniqueRules">
                                </card-abilities>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="Leaderboards" *ngIf="boss && display_leaderboard">
                    <leaderboard [boss]="boss"></leaderboard>
                </div>
                <div id="Leaderboards" *ngIf="boss && display_recents">
                    <leaderboard [boss]="boss" [view]="'recent'" (watch)="watchReplay($event)" [autorun]="autorun"></leaderboard>
                </div>
                <div id="Leaderboards" *ngIf="boss && display_rewards">
                    <leaderboard [boss]="boss" [view]="'rewards'" [autorun]="autorun"></leaderboard>
                </div>
                <div id="Leaderboards" *ngIf="boss && display_myFights">
                    <leaderboard [boss]="boss" [view]="'myFights'" [myFights]="myFights" (watch)="watchReplay($event)" [autorun]="autorun"></leaderboard>
                </div>
            </div>
        </div>
    </section>

    <section *ngIf="boss && view != 'result' && lockBoss" class="yourteam">
        <div class="inner">

            <!-- Sidebar -->
            <div [attr.class]="(showHeroMaxTiers&&config.heroStatMaxTiers) ? 'teampicked skills' : 'teampicked'">
                <div>
                    <div [attr.class]="'boss '+(!boss.uniqueRules?'rules_included none ':' rules_included ')">
                        <div class="rules_box">
                            <div class="BossMaxLevels">
                                <div class="rules_title">Max Monster Levels</div>
                                <boss-max-levels [small]="true" [maxLevels]="maxLevels"></boss-max-levels>
                            </div>
                            <div [attr.class]="!config.heroStatMaxTiers?'max_tiers none':'max_tiers'">
                                <div class="popover">These are the maximum stats a <b>Hero</b> will go into battle with against this tier of <b>Boss</b>.  If your hero has higher stats, they will be reduced to this level.
                                </div>
                                <div class="rules_title">Max Hero Stats</div>
                                <card-stats-icons [stats]="config.heroStatMaxTiers"></card-stats-icons>
                                <div class="max_stats_box" *ngFor="let dmg of config.heroStatMaxTiers | slice:boss.type[1]-1:boss.type[1]">
                                    <card-stats-icons
                                        [smaller]="true"
                                        [extra_smaller]="true"
                                        [armor]="dmg.armor"
                                        [attack]="dmg.dmg"
                                        [health]="dmg.health"
                                        [speed]="dmg.speed">
                                    </card-stats-icons>
                                </div>
                            </div>
                        </div>
                        <div class="bosswrapper">
                            <div class="overlay">
                                <card-league-icon [type]="boss.type"></card-league-icon>
                            </div>
                            <img src="https://splinterforge.s3.us-east-2.amazonaws.com/frame_art/alpha_frame.png" class="frame" loading="lazy">
                            <div class="imagewrapper">
                                <img src="/assets/boss/{{boss.name}}.png" loading="lazy">
                            </div>
                            <!-- 
                                <card-stats-icons
                                    [mana]="false" 
                                    [stats]="boss.stats" 
                                    [ogstats]="boss.ogStats"> 
                                </card-stats-icons> 
                            -->                          
                            <card-stats-icons
                                [smaller]="small"
                                [show_mana]="false"
                                [stats]="boss.stats"
                                [attack]="boss.stats.attack"
                                [ranged]="boss.stats.ranged"
                                [magic]="boss.stats.magic"
                                [speed]="boss.stats.speed"
                                [showog]="false"
                                [force_show_stats]="true"
                                [og_health]="boss.ogStats.health"
                                [health]="boss.stats.health"
                                [og_armor]="boss.ogStats.armor"
                                [armor]="boss.stats.armor"
                                [showog]="true"
                                [boss]="true"
                                [level]="boss.level">
                            </card-stats-icons>
                            <div class="titlewrap"><h3>{{boss.name}}</h3></div>
                            <card-bars
                                [small]="'mid'"
                                [armor]="boss.stats.armor"
                                [original_armor]="boss.ogStats.armor"
                                [health]="boss.stats.health"
                                [original_health]="boss.ogStats.health">
                            </card-bars>
                            <div class="statswrap">
                                <card-abilities [abilities]="boss.stats.abilities">
                                </card-abilities>
                            </div>
                        </div> 
                        <div *ngIf="!boss.uniqueRules || boss.uniqueRules.length < 1" class="no_rules_button black button" (click)="get_rules(boss.type)">
                            Get Rules
                        </div>
                        <div *ngIf="boss.uniqueRules" [attr.class]="!boss.uniqueRules?'rules none':'rules'">
                            <div class="popover">
                                These abilities are assigned for this fight against this boss, they will refresh after every fight.
                            </div>
                            <div>
                                <div class="rules_title">Random Rules</div>
                                <card-abilities 
                                    [show_card_abilities]="true" 
                                    [smaller]="true" 
                                    [abilities]="boss.uniqueRules">
                                </card-abilities>
                            </div>
                        </div>
                    </div>

                    <div id="fightbutton">
                        <button [disabled]="loading" class="fight_button battle_button" (click)="fight(1)">
                            Battle!
                        </button>
                        <button [disabled]="loading" 
                                class="fight_button battle_button" 
                                (click)="fight(2)" 
                                title="This will use 60 stamina, but will multiply your damage, points, xp and rewards by 2!">
                            Battle x2!
                        </button>
                        <button *ngIf="(summoners_selected != 2 || monsters_selected == 0 || !this.boss)" [disabled]="loading" (click)="navigate('slcards')" class="fight_button pick_button button purple">Pick New Boss</button>
                    </div>
                </div>
                <div>
                    <div class="summoners">
                        <div [attr.class]="night?'titlebox':'titlebox light'" (click)="show_summoners=show_summoners?false:true">
                            <h3>
                                <span class="label">Summoners</span>
                                <!-- <i class="fa-solid fa-chevron-down" [@flip]="show_summoners"></i> -->
                                <i class="fa-solid fa-plus" *ngIf="!show_summoners"></i>
                                <i class="fa-solid fa-minus" *ngIf="show_summoners"></i>
                                <span class="label_max">{{summoners_selected}} / 2</span>
                            </h3>
                        </div>
                        <div class="cardbox" *ngIf="show_summoners" 
                            dragula="DRAGULA_SUMMONERS"
                            (dragulaModelChange)="summonor_position($event)"
                            [(dragulaModel)]="selected_summoners">
                            <div *ngFor="let card of selected_summoners" class="itembox">
                                <div class="heros">
                                    <splinterforge-card
                                        [attr.id]="'summoner_card summoner_card_'+i"
                                        (click)="remove(card)"
                                        [summoner]="true"
                                        [smaller]="true"
                                        [showTitle]="false"
                                        [showStats]="false"
                                        [showLeague]="false"
                                        [showAbilities]="false"
                                        [shadow]="true"
                                        [effects]="false"
                                        [card]="card">
                                    </splinterforge-card>
                                </div>
                            </div>
                        </div>
                        <div class="clear"></div>
                    </div>
                    <section class="hero_selection">
                        <div [attr.class]="night?'nameclassbox hero_selector':'nameclassbox hero_selector light'">
                            <div class="dropdown">
                                <div class="label" (click)="show_types=show_types?false:true">
                                    <span>{{hero.name}}</span>
                                    <i class="fa-solid fa-chevron-down" [@flip]="show_types">
                                    </i>
                                </div>
                            </div>
                            <ul class="menu" [@dropdown]="show_types==true">
                                <li class="item" *ngFor="let h of heroes"><span (click)="changeHero(h)">{{h.name}}</span></li>
                            </ul>
                        </div>
                        <div style="color: white;">{{heroStatReqText}}</div>
                        <splinterforge-card
                            [hero]="hero"
                            [user]="user"
                            [smaller]="false"
                            [effects]="false"
                            [currentClass]="hero.type">
                        </splinterforge-card>
                    </section>
                    <div class="monsters">
                        <div [attr.class]="night?'titlebox':'titlebox light'" (click)="show_monsters=show_monsters?false:true">
                            <h3>
                                <span class="label">Monsters</span>
                                <!-- <i class="fa-solid fa-chevron-down" [@flip]="show_monsters"></i> -->
                                <i class="fa-solid fa-plus" *ngIf="!show_monsters"></i>
                                <i class="fa-solid fa-minus" *ngIf="show_monsters"></i>
                                <span class="label_max">{{monsters_selected}} / {{maxMonsters}}</span>
                            </h3>
                        </div>
                        <div class="cardbox" *ngIf="show_monsters"
                            dragula="DRAGULA_MONSTERS"
                            (dragulaModelChange)="monster_position($event)"
                            [(dragulaModel)]="selected_monsters">
                            <div *ngFor="let card of selected_monsters" class="itembox">
                                <div class="heros">
                                    <splinterforge-card
                                        [attr.id]="'monster_card monster_card_'+i"
                                        (click)="remove(card)"
                                        [summoner]="true"
                                        [smaller]="true"
                                        [showStats]="true"
                                        [effects]="false"
                                        [shadow]="true"
                                        [card]="card">
                                    </splinterforge-card>
                                </div>
                            </div>
                        </div>
                        <div class="clear"></div>
                    </div>
                </div>
            </div>
            <!-- Card Selector -->
            <div class="cards">
                <!-- <div><h4>{{summoners_or_monsters?'Monsters':'Summoners'}}</h4></div> -->
                <!-- Summoners  -->
                <div class="filters float" *ngIf="float_filters && summoners_or_monsters" #filtersBox>
                    <sl-card-filters
                        *ngIf="show_sl_card_filters=='1'"
                        (abilities)="abilities=$event;"
                        (filters)="filters=$event;"
                        [float_filters]="float_filters"
                        [abilities]="abilities"
                        [filters]="filters"
                        [show_sort]="show_sort"
                        (show_sort)="show_sort=$event"
                        [copiedCards]="copiedCards"
                        (copiedCards)="copiedCards=$event"
                        [show_damage]="show_damage"
                        (show_damage)="show_damage=$event"
                        [show_monsters]="show_monsters"
                        (show_monsters)="show_monsters=$event"
                        [show_summoners]="show_summoners"
                        (show_summoners)="show_summoners=$event"
                        [show_abilities]="show_abilities"
                        (show_abilities)="show_abilities=$event"
                        [selectedDamage]="selectedDamage"
                        (selectedDamage)="selectedDamage=$event"
                        [selectedAbility]="selectedAbility"
                        (selectedAbility)="selectedAbility=$event"
                        [mana_placeholder]="mana_placeholder"
                        (mana_placeholder)="mana_placeholder=$event"
                        [summoners_or_monsters]="summoners_or_monsters"
                        (summoners_or_monsters)="summoners_or_monsters=$event"
                    ></sl-card-filters>
                    <p class="show_filters_control" *ngIf="show_sl_card_filters!='1'" (click)="toggle_show_sl_card_filters()">Show Filters</p>
                    <p class="hide_filters_control" *ngIf="show_sl_card_filters=='1'" (click)="toggle_show_sl_card_filters()">Hide Filters</p>
                </div>
                <div class="cardwrap darkbg" *ngIf="!summoners_or_monsters">
                    <!--<div *ngFor="let card of copiedCards | slice:summoners_min:summoners_max | type:'Summoner' | sort:filters.direction:filters.sort">-->
                    <div *ngFor="let card of sums">
                        <div class="heros card_wrapper">
                            <slcard-selector [fav]="!filters.hide"
                                [card]="card"
                                (select)="select(card)"
                                (toggle)="toggleFavorite(card)">
                            </slcard-selector>
                        </div>
                    </div>
                </div>

                <!-- Monsters -->
                <div class="monsters_container darkbg" #filtersContainer>
                    <div class="filters" *ngIf="!float_filters && summoners_or_monsters" #filtersBox>
                        <sl-card-filters
                            (abilities)="abilities=$event;"
                            (filters)="filters=$event;"
                            [float_filters]="float_filters"
                            [abilities]="abilities"
                            [filters]="filters"
                            [show_sort]="show_sort"
                            [copiedCards]="copiedCards"
                            [show_damage]="show_damage"
                            [show_monsters]="show_monsters"
                            [show_summoners]="show_summoners"
                            [show_abilities]="show_abilities"
                            [selectedDamage]="selectedDamage"
                            [selectedAbility]="selectedAbility"
                            [mana_placeholder]="mana_placeholder"
                            [summoners_or_monsters]="summoners_or_monsters"
                        ></sl-card-filters>
                    </div>
                    <div class="cardwrap" *ngIf="summoners_or_monsters">
                        <div class="no_cards" *ngIf="(copiedCards|type:'Monster'|mana:filters.mana|ability:filters.ability|dmgType:filters.dmgType).length==0">
                            No Cards Available<br />
                            <a class="clear_filters" (click)="clearFilters()">Clear Filters</a>
                        </div>
                        <div *ngFor="let card of copiedCards | type:'Monster' | mana:filters.mana | ability:filters.ability | dmgType:filters.dmgType | favorites: filters.favorites | sort:filters.direction:filters.sort" class="card_wrapper">
                            <!-- <div *ngFor="let card of copiedCards" class="card_wrapper"> -->
                            <slcard-selector [fav]="!filters.hide"
                                *ngIf="card.type === 'Monster'"
                                [card]="card"
                                (select)="select(card)"
                                (toggle)="toggleFavorite(card)">
                            </slcard-selector>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>

    <div class="monsters_show" *ngIf="!fight_screen && view != 'result' && lockBoss">
        <h3>
            <span class="label">Monsters</span> 
            <span class="label_max">
                <div class="mana_filter monsters_">
                    <button>
                        <span>{{monsters_selected}}</span>
                    </button>
                </div>
                <div class="mana_seperator"> / </div>
                <div class="mana_filter monsters_">
                    <button>
                        <span>{{maxMonsters}}</span>
                    </button>
                </div>
            </span>
        </h3>
    </div>

    <div class="mana_show" *ngIf="!fight_screen && boss && view != 'result' && lockBoss">
        <div class="message" *ngIf="showSummonerAlert">{{SummonerAlert}}</div>
        <div class="message" *ngIf="showMonsterAlert">{{MonsterAlert}}</div>
        <div class="message" *ngIf="showSummonersAlert">{{SummonersAlert}}</div>
        <div class="message" *ngIf="showManaAlert">{{ManaAlert}}</div>
        <!-- <div class="property_title_inner">Mana</div> -->
        <div class="mana_part first">
            <div class="mana_filter">
                <button>
                    <span>{{mana_count?mana_count:0}}</span>
                </button>
            </div>
            <div class="top">Used</div>
            <div class="clear"></div>
        </div>
        <div class="mana_seperator"> / </div>
        <div class="mana_part last">
            <div class="mana_filter">
                <button>
                    <span>{{maxMana}}</span>
                </button>
            </div>
            <div class="top">Available</div>
            <div class="clear"></div>
        </div>
        <div class="clear"></div>
    </div>
    <button *ngIf="!fight_screen && view != 'result'&& lockBoss" 
            [disabled]="loading" [attr.class]="battle_active?'battle_show active':'battle_show'" 
            [attr.tabindex]="0" 
            (keyup.enter)="fight(1)" (click)="fight(1)">
        <!--<div class="stamina">
            <img src="/assets/menu/menu-stamina.png">
            <span>{{currentUser.stamina.current+stamina_regen}} / {{currentUser.stamina.max}}</span>
        </div>-->
        <div class="battle_fg battle_button"><span class="battle_label">Battle!</span></div>
        <div class="battle_bg"></div>
    </button>
    <button *ngIf="fight_screen" [@fade]="!fighting && !fight_bg" [disabled]="loading" [attr.class]="'fight_show '+(fighting?'active':'')" 
        (click)="fighting=true;unpause();">
        <div class="battle_fg battle_button"><span class="battle_label">Start</span></div>
        <div class="battle_bg"></div>
    </button>
    <button *ngIf="!fight_screen && view!='result' && lockBoss" (click)="top()" class="button top_button">
        <div>Top</div>
    </button>
    <div class="replay" [@opacity]="fight_screen && !fight_bg">
        <!-- <p *ngIf="preparing_for_battle" class="middle_paragraph">Preparing for battle...</p> -->
        <replay
            [play]="play"
            [logSpeed]="logSpeed"
            [run_fight]="fighting"
            [battle_paused]="battle_paused"
            [replay]="replay"
            [quit]="quit_battle"
            (pause)="pause()"
            (resume)="unpause()"
            (reset)="reset_all()"
            (damage)="increase_damage($event)"
            (loaded)="replay_loaded($event)"
            [skipToRewards]="skippingToRewards"
            (change_speed)="changeLogSpeed($event)">
        </replay>
    </div>
    <section id="rules" *ngIf="view!='result' && showRules" [attr.class]="showRules?'show':''">
        <div class="dark_box inner">
            <div class="bord_top"></div>
            <div class="bord_left"></div>
            <div class="bord_right"></div>
            <div class="bord_bottom"></div>
            <i class="fa-solid fa-x closebutton" (click)="showRules=false"></i>
            <h4>Forge Rules:</h4>
            <ul>
                <li><span>Each fight costs 30 stamina, you may use all your available mana and monsters slots to submit your team.</span></li>
                <li><span>Maximum mana limited by tier, Bronze - 60, Silver - 75, Gold - 90, Diamond - no limit.</span></li>
                <li><span>Bosses minimum stats are 3, they can not be debuffed lower.</span></li>
                <li><span>Each boss has base abilities, plus addition random abilities/rules per fight per boss that are re-randomized after every fight.</span></li>
                <li><span>Summoner level doesn't matter.</span></li>
                <li><span>Monsters maximum level is determined by what level you own/rent and tier of boss.</span></li>
                <li [attr.title]="ReplacedAbilitiesTooltip"><span>Some abilities don't work as normal. Hover over this line to see a list of abilities that do 1.5x dmg(1.5x total max, does not stack) instead of their regular function.</span></li>
                <li><span>Tank Heal and Triage can heal any damaged monster.</span></li>
                <li><span>Speed is normalized to use a turn meter type system, faster characters will attack more often instead of everyone attacking every turn.</span></li>
                <li><span>Poison damage happens at start of effected monsters turn.</span></li>
                <li><span>Forcefield reduce damage of magic and ranged attacks by half (rounded down) instead of down to 1, melee attack unaffected.</span></li>
                <li><span>Hero stat levels limited by tier of boss.</span></li>
                <li><span>Maximum points/rewards is based on damage done by tier, Bronze - 400, Silver - 700, Gold - 1200, Diamond - 1900.</span></li>
                <li><span>Maximum points/rewards may be reduced by having an under equipped hero, refer to update notes for details.</span></li>
            </ul>
        </div>
    </section>

    <div class="clear"></div>
</div>
<notice-modal
    [show]="show_notice"
    [title]="notice_title"
    [label]="notice_label"
    (done)="show_notice=false"
></notice-modal>


<div *ngIf="loading" class="loader"></div>
