import { Component, HostListener, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Asset } from '../../_models/user';
import { HelperService } from '../../_services/helper.service';
import { DataService } from '../../_services/data.service';
import { AppComponent } from '../../app.component';
import { fade, flip_up, pointer } from '../../app.animations';
@Component({
  selector: 'success-modal',
  animations: [fade, flip_up, pointer],
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.less']
})
export class SuccessComponent implements OnInit {
  @Input('show') show = false;
  @Input('title') title = 'Success';
  @Input('label') label = 'Modal Box Apperance Successful!';
  @Input('ok_text') ok = 'OK';
  @Input('subtitle') subtitle = '';
  @Input('color') modal_color = 'green';
  @Output('done') done = new EventEmitter();
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    switch(event.key) {
      case 'Enter':
      case 'Escape':
      case ' ':
        this.close();
      break;
    }
  }
  constructor(
    public app: AppComponent,
    public helper: HelperService,
    public data: DataService
  ) {
  }
  ngOnInit() {
  }
  done_button(status) {
    this.done.emit(status);
  }
  close() {
    this.show = false
    this.done.emit(0);
  }
}