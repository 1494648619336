import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { Boss, Card, User } from '../../_models/index';
import { Asset } from '../../_models/user';
import { HelperService } from '../../_services/helper.service';
import { DataService } from '../../_services/data.service';
import { AppComponent } from '../../app.component';
import { fade, flip_up, pointer } from '../../app.animations';
import { Router } from '@angular/router';
@Component({
  selector: 'welcome-modal',
  animations: [fade, flip_up, pointer],
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.styl']
})
export class WelcomeComponent implements OnInit, OnChanges {
  @Input('show') show = false;
  @Input('label') top_label = '';
  @Input('mode') modal_mode = '';
  @Input('title') title = 'Attempt Enhancement';
  @Input('type') type = '';
  @Input('tab') tab = 'Welcome';
  @Output('done') done_emit = new EventEmitter();
  leaderboard_nav: any;
  bosses: Boss[];
  nav_open = false;
  constructor(
    public app: AppComponent,
    public helper: HelperService,
    public router: Router,
    public data: DataService
  ) { }
  ngOnChanges(item) {
    if(item.show)this.ngOnInit();
  }
  ngOnInit() {
    if(this.router.url) {
      switch (this.router.url) {
        case "/":
          this.tab = 'Welcome';
        break;
        case "/openings":
          this.tab = 'OpenPacks';
        break;
        case "/cards":
          this.tab = 'MyCards';
        break;
        case "/myheroes":
          this.tab = 'MyHero';
        break;
        case "/leaderboard":
          this.tab = 'Leaderboard';
        break;
        case "/shop":
          this.tab = 'Shop';
        break;
        case "/slcards":
          this.tab = 'BossBattle';
        break;
        case "/profile":
          this.tab = 'Navigation';
        break;
      }
    }
    this.helper.loadBoss();
    this.get_bosses();
  }
  tabkey(e,t) { if(e.key=="Enter"||e.code=="Space")this.tabClick(t); }
  tabClick(t) {
    this.tab=t;
    this.nav_open=false;
  }
  get_bosses() {
    this.data.onBossChange().subscribe((b) => {
      this.bosses = b;
      this.leaderboard_nav=this.bosses[0];
    });
  }
  done(status?) {
    this.done_emit.emit(status);
  }
  close() {
    this.show = false
    this.done_emit.emit(0);
  }
  toggle_nav() {
    this.nav_open = this.nav_open ? false : true;
  }
}