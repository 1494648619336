<section class="forgemodal" [@fade]="show">
	<div class="boxwrap" [@flip_up]="show">
		<h1>Guide to Electrum Mine Runs</h1>
		<div class="itemwrapper">
			<ul>
				<li><a target="_blank" href="https://splinterforge.gitbook.io/electrum-mine-survival-mode/">Electrum Mine Run user guide, click here...</a></li>
				<li>Burn FORGE to fight and earn Electrum and XP</li>
				<li>This play mode has a daily reset, 23 hours after your last mine run was created</li>
				<li>There is a series of 12 mini bosses with increasing difficulty</li>
				<li>Each mini boss that dies will reward you with 1 Rez credit and 1 Reroll credit that will expire when you reset</li>
				<li>May use 1 rez credit to resurrect a random dead monster, may use 2 rez credits to rez specific monster</li>
				<li>May use a reroll credit to reroll abilities on current boss</li>
				<li>There is 4 difficulty levels, Bronze/Silver/Gold/Diamond</li>
				<li>All 3 heroes enter the mine</li>
				<li>Heroes regenerate to full health after every fight</li>
				<li>Each hero may be used for a maximum of 8 fights before they become exhausted</li>
				<li>Select up to 30 Splinterlands monsters you own/rent/borrow to be eligible until next reset</li>
				<li>Summoners are ineligible for this play mode</li>
				<li>You may only use neutral monsters and monsters of any active Force Runes (across all heroes)</li>
				<li>When a monster is damaged in a fight, they will remain damaged in this play mode until next reset</li>
				<li>When a monster is killed in a fight, they will be unavailable to be used until next reset</li>
				<li>Must send at least 1 monster for each fight</li>
				<li>Base maximum of 5 monsters & 30 mana may be used, these can be boosted with hero abilities</li>
				<li>Monster level limits and hero stat limits per difficulty tier are the same as boss battles</li>
				<li>2 additional rewards are available, Evolution Essence & Relic Frags, both will be used in the new <a target="_blank" href="https://splinterforge.gitbook.io/hero-system/">Hero System</a></li>
			</ul>
		</div>
		<div class="closewrapper" (click)="close()"><i class="fa-solid fa-x"></i></div>
    </div>
	<div class="backdrop" [@pointer]="show" (click)="close()"></div>
</section>