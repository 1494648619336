import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'number_loop',
})
export class NumberLoopPipe implements PipeTransform {
  transform(n: any): number[] {
    if (typeof n==="object")n=n.length;
    return [...Array(n)].map((_,i) => i);
  }
}