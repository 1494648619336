import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'abilities_description'
})
export class AbilityDescriptionPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    switch (value) {
    	case "Amplify":
    		return "Increases Magic Reflect, Return Fire, and Thorns damage to all enemy monsters by 1."
    	break;
    	case "Affliction":
    		return "When a Monster with Affliction hits a target, it has a 50% chance of applying Affliction on the target making it un-healable."
    	break;
    	case "Backfire":
    		return "If an enemy misses this Monster with an attack, the attacker takes 2 damage."
    	break;
    	case "Blast":
    		return "Does additional damage to Monsters adjacent to the target Monster. Additional damage is equivalent to main damage/2 rounded up. Snipe works well with this ability."
    	break;
    	case "Blind":
    		return "Enemy attacks have an increased chance (15%) of missing their target."
    	break;
    	case "Bloodlust":
    		return "Every time it defeats an opponent, it gets +1 to all stats."
    	break;
    	case "Camouflage":
    		return "This Monster cannot be targeted for attacks unless it's in the first position."
    	break;
    	case "Cleanse":
    		return "Removes all negative effects on the Monster in the first position on the friendly team. Does not remove summoner debuffs though."
    	break;
    	case "Close Range":
    		return "Monsters with the Close Range ability can perform ranged attacks from the first position."
    	break;
    	case "Cripple":
    		return "Each time an enemy is hit by a Monster with Cripple it loses one max health. If crippled monster is cleansed, it will recover 1 health for every time it has been crippled since the last cleanse."
    	break;
    	case "Deathblow":
    		return "This Monster does 2x damage if their target is the only Monster left on the enemy team."
    	break;
    	case "Demoralize":
    		return "Reduces the Melee attack (by 1, but can't get lower than 1) of all enemy Monsters."
    	break;
    	case "Dispel":
    		return "When this monster hits an enemy, it clears all positive status effects on that enemy."
    	break;
    	case "Divine Shield":
    		return "The first time the Monster takes damage from a monster or earthquake it is ignored. Poison doesn't trigger divine shield, it will still chip away 2 HP."
    	break;
    	case "Dodge":
    		return "Has an increased (25%) chance of evading Melee or Ranged attacks."
    	break;
    	case "Double Strike":
    		return "Monster attacks twice each round."
    	break;
    	case "Enrage":
    		return "Has increased Melee attack and Speed when damaged. Values are multiplied by 1.5 and rounded up."
    	break;
    	case "Flying":
    		return "Has an increased chance (25%) of evading Melee or Ranged attacks from Monsters who do not have the Flying ability."
    	break;
    	case "Forcefield":
    		return "This Monster takes half damage (rounded down) from magic and ranged attacks with power 5+."
    	break;
    	case "Fury":
    		return "This Monster does double damage to targets with the Taunt ability."
    	break;
    	case "Giant Killer":
    		return "Does double damage against targets that cost 10 or more mana."
    	break;
    	case "Halving":
    		return "Each time this Monster hits a target, the target's attack is cut in half (rounded down)."
    	break;
    	case "Headwinds":
    		return "Reduces the Ranged attack of all enemy Monsters (by 1, but can't get lower than 1)."
    	break;
    	case "Heal":
    		return "Restores a portion of the Monster's health each round. 1/3 of the monsters max health rounded down is restored. Minimum is 2."
    	break;
    	case "Immunity":
			return "This monster is immune to negative status effects. Still gets affected by summoner attack debuffs."
		break;
		case "Inspire":
			return "Gives all friendly Monsters +1 Melee attack."
		break;
		case "Knock Out":
			return "Does double damage when attacking an enemy that is stunned."
		break;
		case "Last Stand":
			return "Gains increased stats if it's the only Monster on the team alive. Values are multiplied by 1.5 and rounded up."
		break;
		case "Life Leech":
			return "Monster's health increases each time it damages an enemy Monster's health in proportion to the damage dealt. Works with blast. Note, if there is a health de-buff on your monster and your monster's HP wasn't enough to pay for that de-buff, the HP gained through Life Leech will get used to pay for that de-buff before it actually starts increasing your monster's HP."
		break;
		case "Magic Reflect":
		case "Magic-Reflect":
			return "When hit with Magic damage, does reduced Magic damage back to the attacker. Equal to damage of the attacker/2 rounded up. Doesn't matter if the monster being hit by magic has void, it only looks at the damage from the attacker."
		break;
		case "Opportunity":
			return "Monsters with the Opportunity ability may attack from any position and will target the enemy Monster with the lowest health."
		break;
		case "Oppress":
			return "Does double damage when attacking an enemy that has no attacks."
		break;
		case "Phase":
			return "Magic attacks can miss this Monster (using the same hit/miss calculation as for Melee and Ranged attacks)."
		break;
		case "Piercing":
			return "If Melee or Ranged attack damage is in excess of the target's Armor, the remainder will damage the target's Health."
		break;
		case "Poison":
			return "Attacks have a chance to apply poison, which does automatic damage to the target at the beginning of each round after the poison is applied."
		break;
		case "Protect":
			return "All friendly Monsters gain +2 Armor."
		break;
		case "Reach":
			return "Melee attack Monsters with the Reach ability may attack from the second position on the team."
		break;
		case "Redemption":
			return "When this Monster dies, it does 1 (melee) damage to all enemy monsters."
		break;
		case "Recharge":
			return "This Monster attacks every other round but does 3x damage."
		break;
		case "Repair":
			return "Restores some armor to the friendly Monster whose armor has taken the most damage."
		break;
		case "Resurrect":
			return "When a friendly Monster dies it is brought back to life with 1 Health. This ability can only trigger once per monsters using the ability per battle."
		break;
		case "Retaliate":
			return "When hit with a Melee attack, Monsters with Retaliate have a chance (50%) of attacking their attacker. Chain is possible."
		break;
		case "Return Fire":
			return "When hit with a Ranged attack, Monsters with Return Fire will return reduced damage back to their attacker. Equal to damage of attacker/2 rounded up."
		break;
		case "Reflection Shield":
			return "This Monster is immune to all indirect, or reflected damage. That means that it will not take damage from Blast, Thorns, Return Fire, or Magic Reflect."
		break;
		case "Rust":
			return "Reduces the Armor of all enemy Monsters by 2."
		break;
		case "Scattershot":
			return "This monster's attacks hit a random enemy target."
		break;
		case "Scavenger":
			return "Gains 1 max health each time any monster dies. Note, if there is a health de-buff on your monster and your monster's HP wasn't enough to pay for that de-buff, the HP gained through Scavenger will get used to pay for that de-buff before it actually starts increasing your monster's HP."
		break;
		case "Shatter":
			return "Target's armor is destroyed when hit by an attack from Monsters with Shatter. Effect isn't affected by divine shield."
		break;
		case "Shield":
			return "Reduced damage from Melee and Ranged attacks. Attack value gets halved (and rounded up) before damage is dealt.<br /><br />Examples:<ul class='monsters_abilities'><li>A monster with 1 attack deals 0 damage</li><li>A monster with 2 attack deals 1 damage</li><li>A monster with 3 attack deals 1 damage</li><li>Thorn Damage = 2, Shield reduces the damage to 1</li></ul>";
		break;
		case "Silence":
			return "Reduces the Magic Attack of all enemy Monsters by 1."
		break;
		case "Slow":
			return "Reduces the Speed of all enemy Monsters by 1. Minimum speed is 1."
		break;
		case "Snare":
			return "When attacking enemies with Flying, removes the Flying ability and cannot miss those currently with flying. Can still miss monsters without flying or monsters that already have been snared."
		break;
		case "Sneak":
			return "Targets the last Monster on the enemy Team instead of the first Monster."
		break;
		case "Snipe":
			return "Targets enemy Monsters with Ranged, Magic, or no attack that are not in the first position."
		break;
		case "Strengthen":
			return "All friendly Monsters have increased Health by 1."
		break;
		case "Stun":
			return "When a Monster with Stun hits a target, it has a 50% chance to stun the target causing it to skip turns until the stunning monster had its turn. This may cause some monsters to miss 2 turns or to miss 0 turns."
		break;
		case "Swiftness":
			return "All friendly Monsters have increased Speed."
		break;
		case "Tank Heal":
			return "Heals a friendly Monster that has taken damage. Heal is max health/3 rounded down. Minimum is 2."
		break;
		case "Taunt":
			return "All enemy Monsters target this Monster (if they are able to). Does not affect monsters with reach that are in 2nd position."
		break;
		case "Thorns":
			return "When hit with a Melee attack, does (2 melee) damage back to the attacker."
		break;
		case "Trample":
			return "When a Monster with Trample hits and kills its target, it will perform another attack on the next Monster on the enemy Team. If front line monster is killed then resurrected, it will still perform an attack on the 2nd monster, ignoring the first."
		break;
		case "Triage":
			return "Heals a friendly Monster that has taken damage. Heal is max health/3 rounded down. Minimum is 2."
		break;
		case "True Strike":
			return "This Monster's attacks cannot miss. Blind debuff and Flying ability will have no effect either."
		break;
		case "Void":
			return "Reduced damage from Magic attacks. Same effects as shield but for magic damage."
		break;
		case "Void-Armor":
			return "Magic attacks hit this Monster's armor before its Health."
		break;
		case "Void Armor":
			return "Magic attacks hit this Monster's armor before its Health."
		break;
		case "Weaken":
			return "Reduces the Health of all enemy Monsters (by 1, but can't get lower than 1). An exception to this is if the enemy monster than scavenges. If it has less than 1 health and then scavenges, its max health will stay at 1."
		break;
		case "1.5x DMG":
			return "Monster does 1.5x damage with all attacks."
		break;
		case "Against All Odds":
			return "May only use odd mana cost cards in battle."
		break;
		case "Even Odds":
			return "May only use even mana cost cards in battle."
		break;
		case "Fallen Legendaries":
			return "May not use any Legendary cards in battle."
		break;
		case "Peasant Infantry":
			return "Only common and rare cards may be used."
		break;
		case "Training Crew":
			return "May only use cards with 6 or less mana cost."
		break;
    	default:
    		return "";
    	break;
    }
  }
}