import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Asset } from '../../_models/user';
import { HelperService } from '../../_services/helper.service';
import { DataService } from '../../_services/data.service';
import { AppComponent } from '../../app.component';
import { fade, flip_up, pointer } from '../../app.animations';
@Component({
  selector: 'notice-modal',
  animations: [fade, flip_up, pointer],
  templateUrl: './notice.component.html',
  styleUrls: [ './notice.component.less' ]
})
export class NoticeComponent implements OnInit {
  @Input('show') show = false;
  @Input('label') top_label = '';
  @Input('mode') modal_mode = '';
  @Input('title') title = 'Attempt Enhancement';
  @Input('button') button = 'Close';
  @Input('category') category: string;
  @Input('item_title') item_title: string;
  @Input('item_image') item_image: string;
  @Input('purchase') purchase: boolean;
  @Input('loading') loading: string;
  @Input('item') item: any;
  @Input('type') type = '';
  @Output('done') done = new EventEmitter();
  @Output('clear') clear = new EventEmitter();
  constructor(
    public app: AppComponent,
    public helper: HelperService,
    public data: DataService
  ) {
  }
  ngOnInit() {
  }
  ngOnDestroy() {
    this.clear.emit(1);
  }
  done_button(status) {
    this.done.emit(status);
  }
  close() {
    this.show = false
    this.done.emit(0);
  }
}