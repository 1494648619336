import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'agilities_place'
})
export class AgilitiesPlacementPipe implements PipeTransform {
	transform(value: any, args?: any): any {
		let values;
		if (value) {
			switch(value) {
				case 'Child of the Forest':
				case 'Azmare Harpoonist':
				case 'Elven Defender':
				case 'Captain\'s Ghost':
				case 'Horny Toad':
				case 'Mantoid':
				case 'Elven Mystic':
				case 'Parasitic Growth':
				case 'Silvershield Paladin':
				case 'Tower Griffin':
				case 'Living Lava':
				case 'Luminous Eagle':
				case 'Fire Elemental':
				case 'Spark Pixies':
				case 'Serpentine Spy':
				case 'Sancient Lich':
				case 'Kobold Bruiser':
				case 'Furious Chicken':
				case 'Sniping Narwhal':
				case 'Bila the Radiant':
				case 'Dark Ferryman':
				case 'Phantom Of The Abyss':
				case 'Lobstradamus':
				case 'Magma Troll':
				case 'Scale Doctor':
				case 'Death Elemental':
				case 'Bone Golem':
				case 'Mushroom Seer':
				case 'Magnor':
				case 'Maggots':
				case 'Khmer Princess':
				case 'Silvershield Knight':
				case 'Molten Ogre':
				case 'Octopider':
				case 'Water Elemental':
				case 'Ruler Of The Seas':
				case 'Lord Arianthus':
				case 'Naga Brute':
				case 'Failed Summoner':
				case 'Phantom Soldier':
				case 'Skeleton Assassin':
				case 'Unicorn Mustang':
				case 'Wave Runner':
				case 'Black Dragon':
				case 'Diamond Dragon':
				case 'Chain Golem':
				case 'High Priest Darius':
				case 'Bila The Radiant':
				case 'Demented Shark':
				case 'Prismatic Energy':
				case 'Kron The Undying':
				case 'Flesh Golem':
				case 'Stonesplitter Orc':
				case 'Spirit Miner':
				case 'Cthulhu':
				case 'Crustacean King':
				case 'Charlok Minotaur':
				case 'Fineas Rage':
				case 'Gold Dragon':
				case 'Shieldbearer':
				case 'Flame Monkey':
				case 'Creeping Ooze':
				case 'Goblin Chariot':
				case 'Goblin Sorcerer':
				case 'Cursed Slimeball':
				case 'Giant Scorpion':
				case 'Undead Badger':
				case 'Dark Astronomer':
				case 'Serpent of Eld':
				case 'Orc Sergeant':
				case 'Goblin Thief':
				case 'Baby Unicorn':
				case 'Albatross':
				case 'Ice Pixie':
				case 'Armorsmith':
				case 'Halfling Alchemist':
				case 'Tortisian Chief':
				case 'Hobgoblin':
				case 'Exploding Dwarf':
				case 'The Vigilator':
				case 'Wood Nymph':
				case 'Divine Healer':
				case 'Sea Monster':
				case 'Phantom Of The Abyss':
				case 'Earth Elemental':
				case 'Soulstorm':
				case 'Grenadier':
				case 'Fire Spitter':
				case 'Cornealus':
				case 'Epona':
					return true;
				break;
				default:
					return false
				break;
			}
		} else {
			values = value;
		}
		return values;
	}
}

