import { Component, EventEmitter, Output, ElementRef, Directive, Input, ViewChild} from '@angular/core';
@Component({
    selector: 'item-image',
    templateUrl: 'item-image.component.html',
    styleUrls: [ 'item-image.component.styl' ]
})
export class SLItemImage {
    @Input('category') category: any;
    @Input('item') item: any;
    @Input('loaded') loaded = new EventEmitter();
    output() { 
        this.loaded.emit(true); 
    }
}