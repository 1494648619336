import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';  
import { DataService } from '@app/_services/data.service';
import { HelperService } from '@app/_services/helper.service';
import { Boss, Card, User } from '../_models/index';
import { HeroComponent } from '../hero/hero.component';

enum Tiers {
    'Bronze' = 1,
    'Silver' = 2,
    'Gold' = 3,
    'Diamond' = 4
}

@Component({ 
    selector: 'survival', 
    templateUrl: 'survival.component.html',
    styleUrls:['./survival.component.styl'] 
})

export class SurvivalComponent implements OnInit, OnDestroy {
    constructor(private data: DataService, 
                private helper: HelperService,
                private heroComp: HeroComponent) {}

    enum: typeof Tiers = Tiers;
    mod: any;
    lb: any;
    sfCards: any;
    equippedOnSelectedHeroes: any;
    loading = false;
    freeSplinter: any;
    mineRun: any;
    baseCards: any;
    slCollection: any;
    tier: number;
    heroType: string = '';
    heroOrder = [];
    display = 'loading';
    og = true;
    currentBoss: any;
    showBoss: any;
    showBossIDX: number;
    bossIDX: number;
    battleLog: any;
    alive: any;
    dead: any;
    available: any;
    showRules = false;
    equippedCards: any;
    heroes: any;
    selectedHero: any;
    cost: any;
    modCost: any;
    resetModCost: any;
    burnReset: boolean = false;
    rezModal: boolean = false;
    showSummary: boolean = false;
    showLeaders: boolean = false;
    resurrected: any;
    filters = { damage: 'ALL', mana: 99, fav: false, ability: 'ALL' };
    totals = { xp: 0, electrum: 0, frags: 0, essence: 0, cost: 0 };

    slCollectionSub = new Subscription();

    baseCost = 10;
    rewardMod = 5;

    ngOnInit() {
        this.loadInfo();
        this.slCollectionSub = this.data.onCollectionChange().subscribe(c => {
            this.baseCards = JSON.parse(JSON.stringify(c));
            this.setTier();
        });
    }

    ngOnDestroy() {
        this.slCollectionSub.unsubscribe();
    }

    async applyForces() {
        //let cards = await this.helper.getEquippedSFCards();
        let cards = this.equippedOnSelectedHeroes;
        //console.log(cards);
        if(!cards) return;
        this.equippedCards = cards;
        let abilities = [
            { type: "Life Force", bonus: 0 },
            { type: "Fire Force", bonus: 0 },
            { type: "Earth Force", bonus: 0 },
            { type: "Death Force", bonus: 0 },
            { type: "Water Force", bonus: 0 },
            { type: "Dragon Force", bonus: 0 },
            { type: "Gladius Force", bonus: 0 }
        ];
        if (this.freeSplinter === 'Dragon') abilities[5].bonus += 5;
        else if (this.freeSplinter === 'Earth') abilities[2].bonus += 5;
        let socketAbilities = this.helper.config['socketItems'];
        for (let i = 0; i < cards.length; i++) {
            let card = cards[i];
            let abi = abilities.find(a => { return card.name.indexOf(a.type) > -1; });
            if (abi) {
                let found = socketAbilities.find(x => { return x.type.indexOf(abi.type) > -1; });
                //console.log(found);
                if (found) {
                    abi.bonus += found.bonus[card.rarity-1];
                }
            }
        }
        //console.log(abilities);
        let avail = ['Gray'];
        abilities.forEach(a => {
            if (a.bonus > 0) {
                if (a.type === 'Life Force' && !avail.includes('White')) avail.push('White');
                else if (a.type === 'Fire Force' && !avail.includes('Red')) avail.push('Red');
                else if (a.type === 'Earth Force' && !avail.includes('Green')) avail.push('Green');
                else if (a.type === 'Death Force' && !avail.includes('Black')) avail.push('Black');
                else if (a.type === 'Water Force' && !avail.includes('Blue')) avail.push('Blue');
                else if (a.type === 'Dragon Force' && !avail.includes('Gold')) avail.push('Gold');
            }
        });
        let gladius = abilities.find(x => x.type === 'Gladius Force').bonus > 0;
        this.available = this.slCollection.filter(c => {
            return avail.includes(c.color) && (c.edition !== 6 || (c.edition === 6 && gladius));
        });
    }

    async checkActiveLB() {
        if (!this.lb) {
            this.tier = this.mineRun.tier;
            let temp: any = await this.helper.loadLeaderboard('tier ' + this.tier + ' mine run leaderboard');
            console.log(temp);
            if (temp && temp.leaderboard && temp.leaderboard.ended) {
                this.lb = temp.leaderboard;
                this.checkActiveLB();
            } else return true;
        } else {
            console.log(this.lb);
            let endTime = this.lb.ended;
            let et = new Date(endTime).getTime();
            if (et < Date.now()) {
                let nextLB = new Date(et+1000*60*60*23);
                let lbExpired = Date.now() < nextLB.getTime();
                console.log(lbExpired);
                return 'ready to fight: ' + !lbExpired;
                if (lbExpired) return false;
                else return true;
            } else return true;
        }
    }

    async fightMiniBoss() {
        let selected = this.mineRun.monsters.filter(x => x.selected);
        if (!this.currentBoss) {
            this.helper.showSnackbarMessage('no boss selected');
            return;
        } else if (!this.selectedHero) {
            this.helper.showSnackbarMessage('no hero selected');
            return;
        } else if (selected.length < 1) {
            this.helper.showSnackbarMessage('no monster(s) selected');
            return;
        }

        let manaCount = 0
        let monsterCount = selected.length;
        selected.forEach(m => { manaCount += m.mana; });
        let extra = this.selectedHero.heroAbilities.find(x => x.type === 'Mana Mastery').bonus;
        let extraMon = this.selectedHero.heroAbilities.find(x => x.type === 'Vassal Mastery').bonus;
        if (!extra) extra = 0;
        if (!extraMon) extraMon = 0;
        if (manaCount > (30+extra)) {
            this.helper.showSnackbarMessage('You have selected too much mana, please remove ' + (manaCount-30+extra) + ' mana from your team to proceed.');
            return;
        }
        
        let activeLB; // = await this.checkActiveLB();
        if (!this.lb) {
            this.tier = this.mineRun.tier;
            let temp: any = await this.helper.loadLeaderboard('tier ' + this.tier + ' mine run leaderboard');
            //console.log(temp);
            if (temp && temp.leaderboard && temp.leaderboard.ended) {
                this.lb = temp.leaderboard;
                //this.checkActiveLB();
                let endTime = this.lb.ended;
                let et = new Date(endTime).getTime();
                if (et < Date.now()) {
                    let nextLB = new Date(et+1000*60*60*23);
                    let lbExpired = Date.now() < nextLB.getTime();
                    //console.log(lbExpired);
                    activeLB = !lbExpired;
                    //if (lbExpired) return false;
                    //else return true;
                } else activeLB = true;
            } else activeLB = true;
        } else {
            //console.log(this.lb);
            let endTime = this.lb.ended;
            let et = new Date(endTime).getTime();
            if (et < Date.now()) {
                let nextLB = new Date(et+1000*60*60*23);
                let lbExpired = Date.now() < nextLB.getTime();
                //console.log(lbExpired);
                activeLB = !lbExpired;
                //if (lbExpired) return false;
                //else return true;
            } else activeLB = true;
        }
        //console.log(activeLB);
        //return;
        if (!activeLB) {
            let noLB = 'There is no current active mine run leaderboard for this tier.\n';
            noLB += 'You may still fight this mini boss, but no points will count on the leaderboard.\n';
            noLB += 'Do you wish to fight this mini boss anyway?'
            if (!confirm(noLB)) return;
        } else {
            console.log('there is an active leaderboard for this tier', this.tier);
            //return;
        }
        
        if (monsterCount > 0 && monsterCount <= 8 && this.currentBoss && this.selectedHero) {
            //this.data.setLoading(true);
            selected.push(this.selectedHero);
            let uids = [...new Set(selected.map(c => c.uid))];
            this.loading = true;
            let res: any = await this.helper.fightMiniBoss(this.currentBoss.id ? this.currentBoss.id : this.currentBoss._id, uids, this.modCost);
            console.log(res);
            if (res === 'ToS') return;
            if (res && res.battleLog) {
                this.helper.changeSC(-res.cost);
                //this.helper.changeSC(-this.cost);
                this.battleLog = res.battleLog;
                this.battleLog.actions = JSON.parse(this.battleLog.actions);
                this.battleLog.team.forEach(m => { m.imgURL = this.helper.tempGetCardImgUrl(m) });
                this.battleLog.fullBoss.type = 'Monster';
                this.battleLog.fullBoss.imgURL = 'https://splinterforge.s3.us-east-2.amazonaws.com/minibosses/' + this.battleLog.fullBoss.name + '.png';
                this.display = 'fightResult';
                if (this.battleLog.rewards && this.battleLog.rewards.length > 0) {
                    for (let i = 0; i < this.battleLog.rewards; i++) {
                        let r = this.battleLog.rewards[i];
                        if (r.name.indexOf('XP') > -1) this.helper.changeXP(r.qty);
                        else if (r.name.indexOf('Electrum') > -1) this.helper.changeElectrum(r.qty);
                        else if (r.name.indexOf('Fragment') > -1) this.helper.changeFrags(r.qty);
                        else if (r.name.indexOf('Essence') > -1) this.helper.changeEssence(r.qty);
                    }
                }
                this.showSummary = true;
            } else if (!res) {
                this.helper.showSnackbarMessage('server error sending fight');
            } else {
                console.log(res);
                if (res && res.message) this.helper.showSnackbarMessage(res.message);
                else if (res && !res['message']) {
                    console.log(res);
                    this.helper.showSnackbarMessage('server error during fight');
                }
            }
            
            this.data.setLoading(false);
        } else this.helper.showSnackbarMessage('Unable to verify information to send for fight...');
        this.loading = false;
    }

    getElectrumBalance() {
        return this.helper.currentUser.electrum;
    }

    getFightButtonText() {
        this.cost = this.mineRun.tier*this.baseCost*(this.bossIDX+1);
        this.modCost = parseFloat((this.cost * (this.mod+5)/ 100).toFixed(1));
        let ret = 'BURN ' + this.modCost + ' FORGE to fight'; // for ' + (this.cost*this.rewardMod) + ' Electrum!';
        return ret;
    }

    getManaStatus() {
        if (!this.selectedHero) return '0/30';
        let mana = 0;
        this.alive.forEach(m => { if (m.selected) mana += m.mana; });
        let extra = this.selectedHero.heroAbilities.find(x => x.type === 'Mana Mastery').bonus;
        if (!extra) extra = 0;
        return mana + '/' + (30 + extra);
    }

    getMonsterStatus() {
        if (!this.selectedHero) return '0/5';
        let mana = 0;
        this.alive.forEach(m => { if (m.selected) mana += 1; });
        let extra = this.selectedHero.heroAbilities.find(x => x.type === 'Vassal Mastery').bonus;
        if (!extra) extra = 0;
        return mana + '/' + (5 + extra);
    }

    getNextFree() {
        if (!this.mineRun) return -1;
        let startDate = this.mineRun.baseDate ? this.mineRun.baseDate : this.mineRun.date;
        let timer = 1000 * 60 * 60 * 23;
        let diff = (new Date(startDate).getTime() + timer) - Date.now();
        if (diff > 0) {
            return Math.ceil(diff/60000);
        }
        return -1
    }

    getNumSelected() {
        if (!this.available) return 0;
        let found = this.available.filter(c => { return c.selected; });
        if (!found) return 0;
        return found.length;
    }

    getXPBalance() {
        return this.helper.currentUser.xp;
    }

    async loadInfo(reset = false, burnReset = false) {
        //this.mineRun = burnReset ? null : await this.helper.loadMineRun();
        this.mod = await this.helper.getGlobalMod();
        if (this.mineRun) this.mineRun.nodes = null;
        this.mineRun = await this.helper.loadMineRun();
        this.selectedHero = null;
        if (!this.mineRun || reset) {
            await this.helper.loadSLCollection();
            await this.applyForces();
            //this.pullHeroes(); // old
            this.getHeroes();  // new
            this.display = 'newRun';
            if (reset) this.mineRun.expired = true;
            return;
        } else {
            //console.log(this.mineRun);
            this.currentBoss = null;
            this.showBoss = null;
            this.mineRun.monsters.forEach(m => { m.imgURL = this.helper.tempGetCardImgUrl(m) });
            let oneCycle = 1000*60*60*23;
            let diff = Date.now() - new Date(this.mineRun.date).getTime();
            //console.log(oneCycle, diff);
            if (diff > oneCycle) {
                await this.helper.loadSLCollection();
                await this.applyForces();
                //this.pullHeroes(); // old
                this.getHeroes();  // new
                this.mineRun.expired = !burnReset // true;
            }
            for (let i = 0; i < this.mineRun.nodes.length; i++) {
                this.mineRun.nodes[i].imgURL = 'https://splinterforge.s3.us-east-2.amazonaws.com/minibosses/' + this.mineRun.nodes[i].name + '.png';
                let boss = JSON.parse(JSON.stringify(this.mineRun.nodes[i]));
                if (boss.stats.health > 0 && !this.currentBoss) {
                    this.currentBoss = boss;
                    this.showBoss = boss;
                    this.bossIDX = i;
                    this.showBossIDX = i;
                    //i = this.mineRun.nodes.length;
                } else if (i === this.mineRun.nodes.length-1 && !this.currentBoss) {
                    this.currentBoss = boss;
                    this.showBoss = boss
                    this.bossIDX = i;
                    this.showBossIDX = i;
                }
            }
            this.totals = { xp: 0, electrum: 0, frags: 0, essence: 0, cost: 0 };
            if (this.mineRun.rewards && this.mineRun.rewards.length > 0) {
                this.mineRun.rewards.forEach(x => {
                    if (x.type === 'XP') this.totals.xp += x.qty;
                    else if (x.type === 'Electrum') this.totals.electrum += x.qty;
                    else if (x.type === 'Frag') this.totals.frags += x.qty;
                    else if (x.type === 'Essence') this.totals.essence += x.qty;
                })   
            }
            if (this.mineRun.burns && this.mineRun.burns.length > 0) {
                this.mineRun.burns.forEach(x => {
                    this.totals.cost += x;
                })
            }
            this.alive = this.mineRun.monsters.filter(x => x.ogHealth > 0);
            this.dead = this.mineRun.monsters.filter(x => x.ogHealth < 1);
            if (this.display !== 'pickTeam') this.display = 'currentRun';
        }
        this.resetCost();
        
    }

    navigate(loc) {
        this.helper.navigate(loc);
    }

    async getHeroes() {
        this.heroes = await this.heroComp.getCompleteHeroes();
        if (this.heroes?.length > 0) {
            this.heroes.forEach(x => { x.uid = x.id + ' hero'; });
            await this.setHeroesStats();
        }
        //console.log(this.heroes);
    }

    getHeroAbi(equippedCards) {
        let config = this.helper.getSettings();
        let abilities = [
            { type: "Life Force", bonus: 0 },
            { type: "Fire Force", bonus: 0 },
            { type: "Earth Force", bonus: 0 },
            { type: "Death Force", bonus: 0 },
            { type: "Water Force", bonus: 0 },
            { type: "Dragon Force", bonus: 0 },
            { type: "Gladius Force", bonus: 0 },
            { type: "Mana Mastery", bonus: 0 },
            { type: "Vassal Mastery", bonus: 0 },
            { type: "Splash Heal", bonus: 0 },
            { type: "Hearts Blood", bonus: 0 },
            { type: "Critical Strike", bonus: 0 }
        ];
        if (!equippedCards || equippedCards.length < 1) return abilities;

        let socketAbilities = config['socketItems'];
        let nartorsSet = [57,58,59,60,61,62];
        let nartors = 0;
        for (let i = 0; i < equippedCards.length; i++) {
            let card = equippedCards[i];
            if (nartorsSet.includes(card.detail_id)) nartors += 1;
            let abi = abilities.find(a => { return card.name.indexOf(a.type) > -1; });
            if (abi) {
                let bonusDetails = socketAbilities.find(x => { return x.type.indexOf(abi.type) > -1 });
                //console.log(bonusDetails);
                abi.bonus += bonusDetails.bonus[card.rarity-1];
            }
        }
        if (nartors === 6) abilities[8].bonus += 1;
        return abilities;
    }

    async setHeroesStats() {
        if (!this.heroes) return;
        let tier = !this.tier ? 4 : this.tier;
        for (let i = 0; i < this.heroes.length; i++) {
            let tempHero = this.heroes[i];
            let equippedCards = await this.helper.getEquippedCards(tempHero);
            let heroAbilities = this.getHeroAbi(equippedCards);
            let levelDetails = this.helper.heroLevelInfo(tempHero.xp);
            tempHero.levelDetails = levelDetails;
            tempHero.abilities = heroAbilities;
            tempHero.level = levelDetails.level;
            tempHero.promotable = tempHero.rank < Math.floor(tempHero.level/10);

            let stats = this.helper.getCompleteHeroStats(equippedCards, tempHero);
            tempHero.stats = stats;
            let parts = ['mana','attack','magic','ranged','speed','armor','health'];
            let tierStats = { health: 1, armor: 1, speed: 1, attack: 0, ranged: 0, magic: 0, mana: 0, runeStats: 0, nartors: 0 };
            if (tempHero.type === 'Warrior') tierStats.attack += 1;
            else if (tempHero.type === 'Wizard') tierStats.magic += 1;
            else if (tempHero.type === 'Ranger') tierStats.ranged += 1;
            let heroStatTotal = 0;
            for (let part of parts) {
                if ([1,2,3,4].includes(tier)) {
                    let config = this.helper.getSettings();
                    let maxStat = config.heroStatMaxTiers[tier-1].armor;
                    if (maxStat && tempHero.stats[part] > maxStat) tierStats[part] = maxStat;
                    else tierStats[part] = tempHero.stats[part];
                    if (part !== 'nartors') heroStatTotal += tierStats[part];
                    tempHero[part] = tierStats[part];
                }
                //this[part] = stats[part];
            }
            tempHero.tierStats = tierStats;
            tempHero.heroStatTotal = heroStatTotal;
        }
    }

    async reroll() {
        let id = this.currentBoss.id ? this.currentBoss.id : this.currentBoss._id;
        let res: any = await this.helper.rerollMiniBossRules(id);
        if (res.message === 'success') {
            this.loadInfo();
            this.helper.showSnackbarMessage('Success!');
        } else {
            //console.log(res);
            this.helper.showSnackbarMessage(res.message);
        }
    }

    reSelectMonsters() {
        if (!this.mineRun) return;
        // this.available
        this.mineRun.monsters.forEach(m => {
            let found = this.available.find(x => x.id === m.id);
            if (found) found.selected = true;
        });
    }

    resetCost() {
        let cost = -1;
        if (!this.mineRun) return cost;
        let startDate = this.mineRun.baseDate ? this.mineRun.baseDate : this.mineRun.date;
        let timer = 1000 * 60 * 60 * 23;
        if (Date.now() > new Date(startDate).getTime() + timer) return 0;
        let baseCosts = [780,1560,2340,3120];
        let resets = this.mineRun.resets ? this.mineRun.resets + 1 : 1;
        cost = baseCosts[this.mineRun.tier-1] * resets;
        this.resetModCost = parseFloat((cost * (this.mod+5)/ 100).toFixed(1));
        //console.log(cost, this.mod, this.resetModCost);
        return this.resetModCost; // cost;
    }

    async resetMineRun(burnReset = false) {
        await this.loadInfo(true, burnReset);
        this.burnReset = burnReset;
        this.display = 'newRun';
    }

    async rezMonster(target) {
        let res: any = await this.helper.rezMonster(target === 'random' ? target : target.uid);
        //console.log(res);
        if (res.message === 'success') {
            this.resurrected = this.dead.find(x => x.uid === res.uid);
            this.helper.showSnackbarMessage('Successfully resurrected ' + this.resurrected.name);
            this.loadInfo();
        } else {
            if (res.message) this.helper.showSnackbarMessage(res.message);
            else this.helper.showSnackbarMessage('Error trying to rez');
        }
        //console.log(target);
    }

    rotate (dir) {
        if (dir === 'next') {
            if (this.showBossIDX < 11) {
                this.showBossIDX += 1;
                this.showBoss = this.mineRun.nodes[this.showBossIDX];
            }
        } else if (dir === 'prev') {
            if (this.showBossIDX > 0) {
                this.showBossIDX -= 1;
                this.showBoss = this.mineRun.nodes[this.showBossIDX];
            }
        }
    }

    selectForBattle(card) {
        //let selected = this.mineRun.monsters.filter(x => x.selected);
        //if (selected < 5) card.selected = !card.selected;
        //else this.helper.showSnackbarMessage('May only select 5 monsters per battle.');
        card.selected = !card.selected;
    }

    selectForTeam(card) {
        if (this.getNumSelected() > 30 && !card.selected) {
            this.helper.showSnackbarMessage('Over MAX!  You may select a maximum of 30 monsters to help you in the mine!');
            return;
        }
        card.selected = !card.selected;
    }

    selectHeroForBattle(h) {
        if (h.energy < 1) {
            this.helper.showSnackbarMessage('This hero is exhausted and can lead no more attacks this run...');
            return;
        } else if (this.selectedHero === h) {
            this.selectedHero = null;
            return;
        }
        this.selectedHero = h;
    }

    setHero(h) {
        let temp = h.uid.split(' ')[0];
        if (!this.heroOrder.includes(temp)) {
            h.selected = true;
            this.heroOrder.push(temp);
        }
        if (this.heroOrder.length === 3) { // this is for new hero system, technically does nothing now.
            if (!this.sfCards) this.sfCards = this.helper.mySFCards;
            let user: any = this.helper.currentUser;
            console.log(this.heroOrder, user.newHeroes);
            let requestedHeroes = [];
            for (let i = 0; i < this.heroOrder.length; i++) {
                let found = user.newHeroes.find(x => { return x.id === this.heroOrder[i]; });
                if (found) requestedHeroes.push(found);
            }
            let equippedCards = [];
            for (const hero in requestedHeroes) {
                console.log(requestedHeroes[hero]);
                for (const slot in requestedHeroes[hero].equipment) {
                    let cid = requestedHeroes[hero].equipment[slot];
                    if (cid !== '') {
                        let card = this.sfCards.find(c => { return c.cid === cid; });
                        if (card) {
                            equippedCards.push(card);
                            if (card.sockets > 0) {
                                for (let i = 0; i < card.socket_items.length; i++) {
                                    let socket = card.socket_items[i];
                                    if (socket !== '') {
                                        let socketCard = this.sfCards.find(c => c.cid === socket);
                                        if (socketCard) equippedCards.push(socketCard);
                                        else console.log('did not find gem/rune: ', socket);
                                    }
                                }
                            }
                        }
                    }
                }
            }
            this.equippedOnSelectedHeroes = equippedCards;
            console.log(this.equippedOnSelectedHeroes);
            this.applyForces();
        }
    }

    setTier(tier = 0) {
        this.tier = tier;
        if(this.helper && this.helper.config && this.helper.config.maxLevelsPerTier){
            this.slCollection = JSON.parse(JSON.stringify(this.baseCards));
            this.slCollection = this.slCollection.filter(card => { return card.type === 'Monster' && card.uid.indexOf('starter') === -1; });
            let maxLevelsPerTier = this.helper.config.maxLevelsPerTier;
            let maxLevels = tier === 0 ? maxLevelsPerTier[3] : maxLevelsPerTier[tier-1];
            for (let i = 0; i < this.slCollection.length; i++) {
                let card = this.slCollection[i];
                let limit = maxLevels[card.rarity - 1];
                card['starter'] = card.uid.indexOf('starter-') > -1 ? true : false;
                card.level = card.level > limit ? limit : card.level;
                card.abilities = this.helper.getAbilities(card);
                //card.imgURL = await this.helper.getCardImgUrl(card);
                if (card.type === 'Monster') {
                    card.attack = card.stats.attack[card.level-1] > 0 ? card.stats.attack[card.level-1] : 0;
                    card.magic = card.stats.magic[card.level-1] > 0 ? card.stats.magic[card.level-1] : 0;
                    card.ranged = card.stats.ranged[card.level-1] > 0 ? card.stats.ranged[card.level-1] : 0;
                    card.armor = card.stats.armor[card.level-1];
                    card.speed = card.stats.speed[card.level-1];
                    card.health = card.stats.health[card.level-1];
                    card.mana = card.stats.mana[0];
                    card.filtered = false;
                }
                this.slCollection[i] = card;
            }
            //this.pullHeroes(); // OLD call
            this.setHeroesStats(); // NEW call
            this.applyForces();
        }
    }

    async startMineRun() {
        if (this.tier === 0) {
            this.helper.showSnackbarMessage('Must select a tier...');
            return;
        }
        let ids = [...new Set(this.available.filter(c => c.selected).map(c => c.uid))];
        //if (ids.length < 30 && !confirm('You have only selected ' + ids.length + '/30 monsters, do you wish to continue?')) return;
        if (!this.heroOrder || this.heroOrder.length !== 3) {
            this.helper.showSnackbarMessage('error with hero selection');
            return;  // maybe navigate to this page for a fresh start
        }

        this.loading = true;
        let result = await this.helper.startMineRun(this.tier, ids, this.heroOrder, this.burnReset, this.freeSplinter, this.resetModCost);
        if (result) {
            if (this.burnReset) {
                let c = this.resetCost();
                if (c > 0) this.helper.changeSC(-c);
            }
            this.heroOrder = [];
            this.mineRun = null;
            this.loadInfo();
        }
        this.loading = false;
    }

    toggleFilter(type, value) {
        if (this.filters[type] === value) return;
        else {
            this.filters[type] = value;
            this.available.forEach(a => {
                if (type === 'damage') {
                    if (value === 'ALL') a.filtered = false;
                    else if (a[value] > 0) a.filtered = false;
                    else a.filtered = true;
                } else if (type === 'mana') {
                    if (value === 99) a.filtered = false;
                    else if (value === 10 && a[type] > value) a.filtered = false;
                    else if (a[type] === value) a.filtered = false;
                    else a.filtered = true;
                } else if (type === 'fav') {
                    if (a.favorite) a.filtered = false;
                    else a.filtered = true;
                }
            })
        }
    }

    username() {
        return this.helper.getCurrentUsername();
    }
}
