import { Component, HostListener, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Asset } from '../../_models/user';
import { HelperService } from '../../_services/helper.service';
import { DataService } from '../../_services/data.service';
import { AppComponent } from '../../app.component';
import { fade, flip_up, pointer } from '../../app.animations';
import { User } from '../../_models/user';
@Component({
  selector: 'login-modal',
  animations: [fade, flip_up, pointer],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.styl']
})
export class LoginComponent implements OnInit {
  @Input('show') loginModal = false;
  @Input('title') title = 'Success';
  @Input('label') label = 'Modal Box Apperance Successful!';
  @Input('ok_text') ok = 'OK';
  @Input('subtitle') subtitle = '';
  @Input('color') modal_color = 'green';
  @Output('done') done = new EventEmitter();
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    switch(event.key) {
      case 'Enter':
      case 'Escape':
      case ' ':
        this.hide_login();
      break;
    }
  }
  username: string;
  socket: any;
  login_error:boolean;
  needToS:boolean;
  currentUser: User;
  acceptedToS = false;
  constructor(
    public app: AppComponent,
    public helper: HelperService,
    public data: DataService
  ) {
    this.data.onUserChange().subscribe(u => this.currentUser = u);
  }
  ngOnInit() {
    let temp = localStorage.getItem('forge:username');
    if (temp && temp.length > 0) {
      this.username = temp;
      if(this.username) this.login();
    } else { this.needToS = true; }
    this.helper.loadSLCardDetails();
  }
  done_button(status) {
    this.done.emit(status);
  }
  hide_login(e?) {
    this.loginModal = false
    this.done.emit(0);
  }
  async login() {
    this.login_error=false;
    if(this.username) {
      let exists = await this.helper.verifyUser(this.username);
      if (((exists && exists['needToS']) || (!exists || !exists.exist)) && !this.acceptedToS) {
          this.helper.showSnackbarMessage('Please agree to the Terms of Service & Privacy Policy.');
          this.needToS = true;
          localStorage.removeItem('forge:key');
          localStorage.removeItem('forge:username');
          return;
      }
      this.helper.login_results = 'Logging in with HIVE...';
      this.data.setLoading(true);
      let res = await this.helper.doLogin(this.username, this.acceptedToS);
      if (res && typeof(res) !== 'string') {
          this.loginModal = false;
          //if (this.socket) this.socket.emit('auth', JSON.stringify({ username: this.username, token: this.currentUser.token }));
          //this.poll(this.username);
          this.done.emit(1);
      } else {
        if (typeof(res) === 'string' && res.indexOf('ToS') > -1) this.needToS = true;
        if (res) this.helper.login_results=res;
        this.data.setLoading(false);
        return;
      }
      this.data.setLoading(false);
      this.helper.login_results = '';
    } else {
      this.login_error = true;
    }
  }
}