import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringify_rarity'
})
export class RarityToStringPipe implements PipeTransform {
  transform(rarity: any, args?: any): any {
		if (rarity === 1) return 'Common';
		else if (rarity === 2) return 'Rare';
		else if (rarity === 3) return 'Epic';
		else if (rarity === 4) return 'Legendary';
		else '';
  }
}