import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mana'
})
export class LevelPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if(args == -1) return value;
    let values = [];
    for (var i = 0; i < value.length; i++) {
      if(args!=10)if (value[i].mana==args) values.push(value[i]);
      if(args==10)if (value[i].mana>=args) values.push(value[i]);
    }
    return values;
  }
}