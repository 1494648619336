<div class="favorite" *ngIf="card.type !== 'Summoner' && card.favorite && fav" (click)="toggleFavorite()" title="Press here to remove this card from your favorites.">&#10084;</div>
<div class="not_favorite" *ngIf="card.type !== 'Summoner' && !card.favorite && fav" (click)="toggleFavorite()" title="Press here to add this card to your favorites."><span>&#10084;</span></div>
<div *ngIf="card" [attr.class]="((card.selected && blur === 'yes') || (card.health && card.health <= 0)?'active cardimgselected':'')+' slcard'" (click)="selectCard()">
    <div *ngIf="showEnergy" class="energyIcon" title="This is how many fights your hero may do before becoming exhausted.">
        <img src="https://splinterforge.s3.us-east-2.amazonaws.com/energy.png" loading="lazy">
        <div class="eioverlay">{{card.energy}}</div>
    </div>
    <div class="starter_ribbon" *ngIf="card.starter"></div>
    <img *ngIf="card.color !== 'ALL'" [id]="card.card_detail_id" class="cardimg" src="{{card.imgURL}}" 
    [style.filter]="card.selected?'drop-shadow(Red 0 0 6.9373px)':'drop-shadow(transparent 0 0 0)'">
    <img *ngIf="card.color === 'ALL'" [id]="card.card_detail_id" class="slcardimg" src="{{card.imgURL}}" 
    [style.filter]="card.selected?'drop-shadow(Red 0 0 6.9373px)':'drop-shadow(transparent 0 0 0)'">
    
    <!--<div class="dead" *ngIf="(card.health || card.health === 0) ? card.health < 1 : card.ogHealth < 1"><div class="red_stripes_bg"></div></div>-->
    <card-stats-icons
        *ngIf="card.type === 'Monster' || card.equipment"
        [stats]="card.stats"
        [effects]="false"
        [mana]="card.type === 'Summoner' ? card.stats.mana : card.color === 'ALL' ? card.mana : card.stats.mana[0]"
        [attack]="card.attack ? card.attack : card.ogAttack"
        [ranged]="card.ranged ? card.ranged : card.ogRanged"
        [magic]="card.magic ? card.magic : card.ogMagic"
        [health]="(card.health || card.health === 0) ? card.health : card.ogHealth"
        [armor]="(card.armor || card.armor === 0) ? card.armor : card.ogArmor"
        [speed]="card.speed ? card.speed : card.ogSpeed" 
        [og_health]="card.ogMaxHealth"
        [og_armor]="card.ogMaxArmor"
        [smaller]="small"
        [level]="card.level">
    </card-stats-icons>
    <card-stats-icons
        *ngIf="['t1','t2','t3','t4'].includes(card.type)"
        [stats]="card.stats"
        [effects]="false"
        [mana]="card.stats.mana"
        [attack]="card.stats.attack"
        [ranged]="card.stats.ranged"
        [magic]="card.stats.magic"
        [health]="card.stats.health"
        [armor]="card.stats.armor"
        [speed]="card.stats.speed"
        [smaller]="small"
        [level]="1">
    </card-stats-icons>
    <div *ngIf="card.type === 'Monster'" [attr.class]="(card.name|agilities_place)?'statswrap alt':'statswrap'">
        <card-abilities [smaller]="small" [abilities]="card.abilities"></card-abilities>
    </div>
    <div *ngIf="['t1','t2','t3','t4'].includes(card.type)" [attr.class]="(card.name|agilities_place)?'statswrap alt':'statswrap'">
        <card-abilities [smaller]="small" [abilities]="card.stats.abilities"></card-abilities>
    </div>
    <div *ngIf="card.type !== 'Summoner'" class="slname">{{card.name}}</div>
    <div *ngIf="card.type !== 'Summoner'" class="sllevel">★ {{card.level}}</div>
</div>