import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'equipped'
})
export class EquippedPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if(args == -1 || args == 'All' || args == false) return value;
    let values = [];
    for (var i = 0; i < value.length; i++) {
      if (value[i].equipped) values.push(value[i]);
    }
    return values;
  }
}