<section *ngIf="currentUser" class="forgemodal blue" [@fade]="show">
	<div class="boxwrap" [@flip_up]="show">
		<div class="topwrap">
		<h1 class="top">SplinterForge Wallet</h1>
		<h1 *ngIf="!status" class="blue">{{method|titlecase}} Forgium</h1>
		<h1 *ngIf="status==200" class="green">
			{{method|titlecase}} Successful
		</h1>
		<div class="bottomwrap">
			<div class="hive_balance">
				<button class="refresh" (click)="getHEBalances()">
					<i class="fa-solid fa-arrow-rotate-right"></i>
				</button>
				<img src="/assets/key.png" width="40" height="40" loading="lazy">
				<span>Hive-Engine FORGE: </span> 
				<span *ngIf="balance">{{balance.balance|number:'1.3-3'}}</span>
				<span *ngIf="!balance">{{0}}</span>
			</div>
			<div class="crystal_balance">
				<img src="https://splinterforge.s3.us-east-2.amazonaws.com/tokens/forgium.png" width="40" height="40" loading="lazy">
				<span>In-game FORGE: </span> 
				<span *ngIf="currentUser&&currentUser.sc.balance">{{currentUser.sc.balance|number:'1.3-3'}}</span>
				<span *ngIf="!currentUser||!currentUser.sc.balance">{{0}}</span>
			</div>
		</div>
		<!-- 
			<div class="amount">
				<div class="title">Username: <br />{{currentUser.username}}</div>
			</div>
			<div class="amount">
				<div class="title" *ngIf="currentUser">Forgium Balance: <br />{{currentUser.sc.balance|number:'1.2-2'}}</div>
			</div> 
			<div class="amount">
				<div class="title" *ngIf="balance">HIVE Balance: <br />{{balance.balance|number:'1.2-2'}}</div>
			</div>
		-->
		<div class="container_wrapper">
			<div>
				<div class="selection">
					<div class="radio" (click)="method='deposit'">
						<i *ngIf="method!='deposit'" class="fa-solid fa-circle"></i>
						<i *ngIf="method=='deposit'" class="fa-solid fa-circle-dot"></i>
						<span>Deposit</span>
					</div>
					<div class="radio" (click)="method='withdraw'">
						<i *ngIf="method!='withdraw'" class="fa-solid fa-circle"></i>
						<i *ngIf="method=='withdraw'" class="fa-solid fa-circle-dot"></i>
						<span>Withdraw</span>
					</div>
					<div class="radio" (click)="method='transfer'">
						<i *ngIf="method!='transfer'" class="fa-solid fa-circle"></i>
						<i *ngIf="method=='transfer'" class="fa-solid fa-circle-dot"></i>
						<span>Transfer</span>
					</div>
				</div>
			</div>
		</div>
		<div class="amount single">
			<div class="title">Amount of Forgium: </div>
			<input [attr.class]="amount>0?'active':''" type="number" min="0" step="1" [(ngModel)]="amount" />
		</div>
	<!-- 
		<div class="balance" (click)="toggle_currencies()">
			<span>Currency: <span class="selected_currency">{{selectedCurrency}}</span></span> 
			<span *ngIf="balance">{{balance.balance|number}}</span>
		</div>
		<div class="container_wrapper">
			<div class="hive_balance" (click)="toggle_currencies()">
				<button class="refresh" (click)="app.getHEBalances()">
					<i class="fa-solid fa-arrow-rotate-right"></i>
				</button>
				<i *ngIf="1>2" class="fa-solid fa-chevron-down type_arrow" [@flip_shadow]="show_currencies"></i>
				<img src="https://splinterforge.s3.us-east-2.amazonaws.com/tokens/forgium.png" width="40" height="40" loading="lazy">
				<span>HE/Tribal Forgium: </span> 
				<span *ngIf="balance">{{balance.balance|number}}</span>
				<span *ngIf="!balance">{{0}}</span>
			</div>
			<ul *ngIf="1>2" class="menu small" [@dropdown]="show_currencies">
				<li *ngFor="let p of prices" (click)="setCurrency(p)" [attr.class]="'item '+(selectedCurrency==p?'active':'')">
					<i *ngIf="selectedCurrency!=p" class="price-circle fa-solid fa-circle"></i> 
					<i *ngIf="selectedCurrency==p" class="price-circle fa-solid fa-circle-dot"></i> 
					{{p}}
				</li>
			</ul>
			<div class="crystal_balance">
				<img src="https://splinterforge.s3.us-east-2.amazonaws.com/tokens/forgium.png" width="40" height="40" loading="lazy">
				<span>Forgium: </span> 
				<span *ngIf="currentUser&&currentUser.sc.balance">{{currentUser.sc.balance|number}}</span>
				<span *ngIf="!currentUser||!currentUser.sc.balance">{{0}}</span>
			</div>
		</div> 
	-->
		<div class="amount full_width" *ngIf="method=='transfer'">
			<div class="title">Player Wallet Address: </div>
			<input type="text" placeholder="Player HIVE Wallet Address" [(ngModel)]="receiver" />
		</div>
		<div class="buttonrow description" *ngIf="(method=='withdraw'||method=='deposit')">
			{{method|titlecase}} Forgium to/from your HIVE account or transfer to other players.
		</div>
		<div class="buttonrow description" *ngIf="method=='transfer'"> Transfer {{amount|number:'1.2-2'}} FORGE to {{receiver?receiver:'a player'}}.</div>
		<div class="buttonrow description" *ngIf="method=='withdraw'"> Withdraw {{amount|number:'1.2-2'}} FORGE to Hive-Engine.</div>
		<div class="buttonrow description" *ngIf="method=='deposit'"> Deposit {{amount|number:'1.2-2'}} FORGE to SplinterForge.</div>
		<div class="buttonrow">
			<button class="red button left" (click)="close()">Cancel</button>
			<button class="green button" *ngIf="method=='transfer'" (click)="transferToPlayer()">Transfer</button>
			<button class="green button" *ngIf="method=='withdraw'" (click)="withdrawToHE()">Withdraw</button>
			<button class="green button" *ngIf="method=='deposit'" (click)="depositToSF()">Deposit</button>
		</div>
		<div class="closewrapper" (click)="close()">
			<i class="fa-solid fa-x"></i>
		</div>
		</div>
	</div>
	<div class="backdrop" [@pointer]="show" (click)="close()"></div>
</section>