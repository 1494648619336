<section class="forgemodal gold" [@fade]="show">
    <div class="boxwrap" [@flip_up]="show" [@scale_90]="show">
        <h1>Fight Result</h1>
        <div class="box_container">
            <div class="bricks_background"></div>
            <splinterforge-card
                class="boss__"
                [boss]="boss"
                [rules_box]="false"
                [effects]="false">
            </splinterforge-card>
            <h2 class="boss_name" *ngIf="boss">Battled {{boss.name}}</h2>
            <div class="boxes_">
                <span *ngIf="Damage" class="Damage">
                    <div class="overlay"><span>Did <b>{{Damage|number:'1.0-0'}}</b> Damage</span></div>
                    <span class="pts">{{Damage|number:'1.0-0'}}</span>
                    <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/melee-attack.png" width="40" height="40" >
                </span>
                <span *ngIf="Points" class="Points">
                    <div class="overlay"><span>Earned <b>{{Points|number:'1.0-0'}}</b> Points</span></div>
                    <span class="PointsPlus">+ </span>
                    <span>{{Points|number:'1.0-0'}}</span> <span class="pts_label">Pts</span>
                </span>
                <span *ngIf="Electrum" class="Electrum">
                    <div class="overlay"><span>Gained <b>{{Electrum|number:'1.0-0'}}</b> Electrum</span></div>
                    <span class="pts">{{Electrum|number:'1.0-0'}}</span>
                    <img src="https://splinterforge.s3.us-east-2.amazonaws.com/Electrum.png" width="40" height="40">
                </span>
                <span *ngIf="Forgium" class="Forgium">
                    <div class="overlay"><span>Gained <b>{{Forgium|number:'1.2-2'}}</b> Forgium</span></div>
                    <span class="pts forged">{{Forgium|number:'1.2-2'}}</span>
                    <img src="https://splinterforge.s3.us-east-2.amazonaws.com/tokens/forgium.png" width="40" height="40">
                </span>
            </div>
            <p class="line_one" *ngIf="line_one">{{line_one}}</p>
            <p class="first" *ngIf="line_two">{{line_two}}</p>
            <div class="button_group">
                <button (click)="close()" class="button gray close_btn">Close Window</button>
                <button (click)="battle()" class="button orange battle_again">Battle Again</button>
                <div class="clear"></div>
            </div>
        </div>
        <div class="closewrapper" (click)="close()">
            <i class="fa-solid fa-x"></i>
        </div>
    </div>
    <div class="backdrop" [@pointer]="show" (click)="close()"></div>
</section>