<section class="forgemodal" [@fade]="show">
    <div class="boxwrap large" [@flip_up]="show">
		<h1 *ngIf="!equippable">No Equippable Items</h1>
		<div *ngIf="!equippable" class="not_equippable_container">
			<p class="not_equippable">You have no items that can be equipped.</p>
			<div class="purple button" (click)="close()">Close</div>
		</div>
		<h1 *ngIf="equippable">Equip A Card</h1>
		<!-- 
			<span *ngFor="let card of cards">{{card.name}}</span>
			<span *ngFor="let equippe of equipped">equippe</span>
			<span>{{equippable}}</span> 
		-->
		<!-- selectedcard-selector -->
		<!-- <sfcard-selector class="sfcard" [sfcards]="cards" [sfcard]="selectedToImbue" [stackView]="false"></sfcard-selector> -->

		<selectedcard-selector
			[cards]="cards"
			[background]="false"
			[heading]="false"
			[smaller]="true"
			[equipped]="equipped"
			[equippable]="equippable">
		</selectedcard-selector>

        <div class="closewrapper" (click)="close()">
            <i class="fa-solid fa-x"></i>
        </div>
    </div>
    <div class="backdrop" [@pointer]="show" (click)="close()"></div>
</section>
