<section class="forgemodal" [@fade]="show">
    <div class="boxwrap large" [@flip_up]="show">
		<h1>Hero Skills - {{hero.skillPts}} Skill Points <a href="https://splinterforge.gitbook.io/hero-system/skills" target="_blank">Details</a> </h1>

		<div *ngIf="skills && hero">
			<!--<div class="leftside">
				<div class="selected_card">
					<h4>Current Skills</h4>
					<div *ngFor="let skill of hero.skills">
						<div [title]="skill.desc">{{skill.name}} (T{{skill.tier}})</div>
					</div>
				</div>
			</div>-->
			<div>
				<h4 class="eligible_cards">Available Skills</h4>
				<h5>Trainable Skills - Levelled with Electrum</h5>
				<div *ngFor="let skill of skills">
					<div *ngIf="skill.type === 'trained'">
						<div>
							<h6 style="color:orangered" [title]="skill.desc">{{skill.name}}</h6>
							<p>{{skill.desc}}</p>
						</div>
						<div *ngIf="skill.needPts">Not enough skill points, {{skill.cost[skill.next]-hero.skillPts}} more skill points needed.</div>
						<div *ngIf="skill.needRank">Insufficient rank, Evolve your hero up to unlock this skill.</div>
						<button *ngIf="!skill.needRank && !skill.needPts" class="button purple" (click)="buySkill(skill)">
							Buy {{skill.name}} tier {{skill.next+1}} for {{skill.cost[skill.next]}} Skill Points
						</button>
					</div>
				</div>
				<h5>Static Skills - Require 1 rank per skill/tier</h5>
				<div *ngFor="let skill of skills">
					<div *ngIf="skill.type === 'passive'">
						<div>
							<h6 style="color:orangered" [title]="skill.desc">{{skill.name}}</h6>
							<p>{{skill.desc}}</p>
						</div>
						<div *ngIf="skill.needPts">Not enough skill points, {{skill.cost[skill.next]-hero.skillPts}} more skill points needed.</div>
						<div *ngIf="skill.needRank">Insufficient rank, Evolve your hero up to unlock this skill.</div>
						<button *ngIf="!skill.needRank && !skill.needPts" class="button purple" (click)="buySkill(skill)">
							Buy {{skill.name}} tier {{skill.next+1}} for {{skill.cost[skill.next]}} Skill Points
						</button>
					</div>
				</div>
			</div>
		</div>

        <div class="closewrapper" (click)="close()">
            <i class="fa-solid fa-x"></i>
        </div>
    </div>
    <div class="backdrop" [@pointer]="show" (click)="close()"></div>
</section>

<notice-modal [label]="message" [title]="modal_title" [show]="show_modal" [mode]="modal_mode" [button]="modal_button"
    [type]="type" (done)="modal_done($event)">
</notice-modal>
