<section class="footer" id="footer1">
  <div class="border_gradient bottom"></div>
  <div class="border_gradient top"></div>
  <div class="inner">
  	<div class="left">
      <a href="https://splinterforge.io/Terms_of_Use_Splinterforge.pdf" target="_blank"> ToS</a>
    </div>
  	<div class="center"> 
    	<p class="copyright">&#169; 2023 Hive Forge Games</p>
  	</div>
  	<div class="right">
      <a href="https://splinterforge.io/Privacy Policy.pdf" target="_blank"> Privacy Policy</a>
    </div>
  </div>
  <div class="clear"></div>
</section>
