import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dmgType'
})
export class DmgTypePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if(args == -1 || args == 'All') return value;
    let values = [];
    for (var i = 0; i < value.length; i++) {
      if (args !== 'All' && value[i].type === 'Monster') {
        let found = false;
        if (args === 'Attack' && value[i].attack != 0) values.push(value[i]);
        if (args === 'Magic' && value[i].magic != 0) values.push(value[i]);  
        if (args === 'Ranged' && value[i].ranged != 0) values.push(value[i]);
      }
    }
    return values;
  }
}