import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'card-league-icon',
  templateUrl: './stats-league.component.html',
  styleUrls: ['./stats-league.component.styl']
})
export class StatsLeagueComponent implements OnInit {

  constructor() { }
  @Input('type') type: any;
  ngOnInit(): void {
  }

}
