<div class="background"></div><!-- #1#3 -->
<div class="page_heading large" *ngIf="!viewCard">
  <div class="bottom_border"></div>
  <div class="titlebar">
    <h1>
      <span class="card_title">My Account</span>
    </h1> 
  </div>
  <div class="tab_bar">
    <i class="transparent-gear tablinks fa-solid fa-gear" (click)="openTab($event, 'Settings')"></i>
    <button [disabled]="loading" class="tablinks active" (click)="openTab($event, 'Inventory')">Inventory</button>
    <button [disabled]="loading" class="tablinks" (click)="openTab($event, 'Profile')">Transactions</button>
    <button *ngIf="1>0" [disabled]="loading" class="tablinks" (click)="openTab($event, 'Staking')">Staking/LPs</button>
    <button [disabled]="loading" class="tablinks" (click)="openTab($event, 'Airdrops')">Airdrops</button>
    <button [disabled]="loading" class="tablinks" (click)="openTab($event, 'FAQs')">FAQs</button>
    <button [disabled]="loading" class="tablinks" (click)="logout()">Logout</button>
  </div>
</div>
<div class="profile_container">
  <div id="Profile" class="tab_container">
    <div class="col-md-12">
      <div *ngIf="sctx" class="tabcontent profile_page">
        <h4>Transactions</h4>
        <div class="table_container" *ngIf="sctx && sctx.length > 0">
            <div class="table_header">
                <div class="table_cell player_header">Type</div>
                <div class="table_cell points_header">Amount</div>
                <div class="table_cell time_header">Date</div>
            </div>
            <div *ngFor="let t of sctx | slice:tx_start:tx_start+tx_limit; let i = index;" 
                 class="table_row">
                <div class="table_cell name"><span>{{t.type|tx_title}}</span></div>
                <div class="table_cell points">{{t.qty>0?'+':''}}{{t.qty|number:'1.2-2'}} FORGE</div>
                <div class="table_cell time" [attr.title]="t.timestamp|date:'MMM d, y, h:mm:ss a'">
                  <span>{{t.timestamp|timeago}}</span>
                </div>
            </div>
        </div>
        <div class="prev_next">
          <div class="control previous" *ngIf="tx_start>0" (click)="prev_tx_page()">
            <i class="fa-solid fa-chevron-left"></i>
            <span>Previous</span>
          </div>
          <div class="control center">
            {{tx_start+1}}-{{tx_start+tx_limit>sctx.length ? sctx.length : tx_start+tx_limit}} / {{sctx.length}}
          </div>
          <div class="control next" *ngIf="tx_start+tx_limit<sctx.length" (click)="next_tx_page()">
            <span>Next</span>
            <i class="fa-solid fa-chevron-right"></i>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </div>
  <div id="Staking" class="tab_container">
    <div *ngIf="stake" class="col-md-12">
      <div class="tabcontent profile_page">
        <div class="left_half">
          <h4>Staking </h4>
          <h4>
            <span *ngIf="totalStakeInfo">(Totals: {{totalStakeInfo.count + ' users staking ' + totalStakeInfo.total + ' FORGE'}})</span>
          </h4>
          <div>
            <ul>
              <li>Stake FORGE to earn account XP.</li>
              <li [attr.title]="getXPStakingScale()">1 xp per day per x FORGE staked.</li>
              <li>Maximum 10,000,000 staked per user.</li>
              <li>XP rewards will be calculated and distributed daily at 1700 UTC</li>
              <li>Unstaking staged over 8 days, 25% returned every 2 days.</li>
            </ul>
            <h4>Current XP: {{user.xp}}</h4>
            <div>
              <h4>Current stake: {{stake.stake}} {{pendingUnstake > 0 ? '('+pendingUnstake+' pending unstake)':''}}</h4>
              <h4 *ngIf="expected > 0">Expected reward: ~{{expected}} XP per day</h4>
              <h4>Eligible to stake: {{10000000-stake.stake > user.sc.balance ? user.sc.balance : 10000000-stake.stake}}</h4>
              <input type="number" step="100" min="0" max="{{user.sc.balance}}" [(ngModel)]="amount" />
              <button class="button purple" [disabled]="user.sc.balance < amount && amount < 100" (click)="stakeForge()">Stake {{amount}} FORGE</button>
            </div>
            <div>
              <h4>Unstaking {{stake.stake>0 ? '('+(stake.stake-pendingUnstake)+' available to unstake)': ''}}</h4>
              <input type="number" step="100" min="0" max="{{stake.stake-pendingUnstake}}" [(ngModel)]="unstakeAmount" />
              <button class="button purple" [diabled]="stake.stake - pendingUnstake < unstakeAmount" (click)="unstakeForge()">Unstake {{unstakeAmount}} FORGE</button>
              <div *ngIf="unstakes.length > 0">
                <div *ngFor="let u of unstakes">
                  Unstake of {{u.qty}} FORGE requested: {{u.date | date:'short'}}
                  <ul>
                    <li *ngFor="let e of u.schedule">{{e.qty}} FORGE on ~{{e.date | date:'short'}} - completed: {{e.sctx ? 'true' : 'false'}}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right_half">
          <h4>LPs</h4>
          <ul>
            <li>DEC:FORGE LP receives 1 xp per 100 shares</li>
            <li>SWAP.HIVE:FORGE LP receives 1 xp per 1 share</li>
            <li>XP rewards will be calculated and distributed daily at 1700 UTC</li>
          </ul>
          <div>
            <h4>Current LP shares</h4>
            <div *ngIf="forgeDEC || forgeDEC === 0">
              FORGE:DEC - {{forgeDEC}} shares
              <div *ngIf="forgeDEC > 0">~{{forgeDEC>0 ? forgeDEC/100 : 0}} xp per day</div>
            </div>
            <div *ngIf="forgeSwapHive || forgeSwapHive === 0">
              FORGE:SWAP.HIVE - {{forgeSwapHive}} shares
              <div *ngIf="forgeSwapHive > 0">~{{forgeSwapHive>0 ? forgeSwapHive : 0}} xp per day</div>
            </div>
          </div>
          <div *ngIf="rewardHistory">
            <h4>XP reward history</h4>
            <div class="histDisplay">
              <div *ngFor="let hist of rewardHistory">
                {{hist.date | date:'longDate'}}{{hist.entry}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="Inventory" class="tab_container">
    <div *ngIf="user && user.inGameAssets && user.inGameAssets.length > 0" class="col-md-12">
      <div class="tabcontent profile_page">
        <h4>Inventory</h4>
        <div class="shop_items" *ngFor="let asset of user.inGameAssets">
            <div class="itemwrapper">
                <div class="shop_item {{asset.type}}">
                    <!-- <div class="rock_bg" *ngIf="type=='Alpha Pack'"></div> -->
                    <div class="shopItem">
                      <div class="image_wrapper">
                        <div class="imagewrapper" (click)="click_item(asset)">
                          <img *ngIf="asset.name=='Electrum'" class="electrum" src="https://splinterforge.s3.us-east-2.amazonaws.com/Electrum.png">
                          <img *ngIf="asset.name=='XP'" class="electrum" src="https://splinterforge.s3.us-east-2.amazonaws.com/XP2.png">
                          <img *ngIf="asset.name=='Evolution Essence'" class="electrum" src="https://splinterforge.s3.us-east-2.amazonaws.com/Essence.png">
                          <img *ngIf="asset.name=='Relic Fragment'" class="electrum" src="https://splinterforge.s3.us-east-2.amazonaws.com/Frag.png">
                          <img *ngIf="asset.name=='Enhancement Pack'" class="enhancement_pack" src="https://splinterforge.s3.us-east-2.amazonaws.com/packs/tempbag.png">
                          <img *ngIf="asset.name=='Alpha Pack'" class="alpha_pack" src="https://splinterforge.s3.us-east-2.amazonaws.com/packs/crate.png">
                          <img *ngIf="asset.name=='Minor Stamina Potion'" class="minor_stamina_elixir" src="https://splinterforge.s3.us-east-2.amazonaws.com/shop/Minor+Stamina+Potion.png">
                          <img *ngIf="asset.name=='Stamina Potion'" class="stamina_elixir" src="https://splinterforge.s3.us-east-2.amazonaws.com/shop/Stamina+Potion.png">
                          <img *ngIf="asset.name=='Major Stamina Potion'" class="major_stamina_elixir" src="https://splinterforge.s3.us-east-2.amazonaws.com/shop/Major+Stamina+Potion.png">
                          <img *ngIf="asset.name=='Stamina Expansion'" class="stamina_expansion" src="https://splinterforge.s3.us-east-2.amazonaws.com/shop/Stamina+Expansion.png">
                        </div>
                      </div>
                      <div class="image_wrapper">
                          <div [attr.class]="'title '+(asset.name.length>17?'lg':'')" (click)="click_item(asset)">
                            {{asset.name}}
                          </div>
                      </div>
                      <button class="button black inventory_button half left" *ngIf="(asset.symbol=='CRATE'||asset.symbol=='BAG') && asset.qty>0" (click)="useConsumable(asset, 1)">
                        Use
                      </button>
                      <button class="button black inventory_button half right" *ngIf="(asset.symbol=='CRATE'||asset.symbol=='BAG')" (click)="openTransfer(asset, 1)">
                        Transfer
                      </button>
                      <button class="button black inventory_button" *ngIf="(asset.symbol!='CRATE'&&asset.symbol!='BAG') && asset.name.indexOf('Stamina') > -1 && asset.qty > 0" (click)="useConsumable(asset, 1)">
                        Use Stamina
                      </button>
                      <button class="button black inventory_button" *ngIf="(asset.symbol!='CRATE'&&asset.symbol!='BAG') && asset.name.indexOf('Pack') > -1 && asset.qty > 0" (click)="navigate('openpacks')">
                        Open Packs
                      </button>
                      <button class="button black inventory_button" *ngIf="(asset.symbol!='CRATE'&&asset.symbol!='BAG') && asset.name=='Electrum'" (click)="navigate('slcards')">
                        Earn Electrum
                      </button>
                      <span class="owned">
                          <span>Owned:&nbsp;</span>
                          <span *ngIf="!['Electrum','XP','Evolution Essence','Relic Fragment'].includes(asset.name)" class="number_owned">{{asset.qty|number}}</span>
                          <span *ngIf="asset.name === 'Electrum'" class="number_owned">{{user.electrum|number}}</span>
                          <span *ngIf="asset.name === 'XP'" class="number_owned">{{user.xp|number}}</span>
                          <span *ngIf="asset.name === 'Evolution Essence'" class="number_owned">{{user.essence|number}}</span>
                          <span *ngIf="asset.name === 'Relic Fragment'" class="number_owned">{{user.frags|number}}</span>
                      </span>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="skin" style="position: fixed; background-color: white; left: 30%; right: 30%; bottom: 30%; top: 30%; z-index: 7500;">
      <p>To: <input type="text" [(ngModel)]="receiver" /></p>
      <button [disabled]="loading" (click)="transferSkin()">Transfer {{skin.name}} to {{receiver}}</button>
      <button [disabled]="loading" (click)="closeSkinModal();">Close</button>
  </div>

  <div id="FAQs" class="tab_container">
    <div class="col-md-12">
      <div class="tabcontent profile_page">
        <h4>Frequently Asked Questions</h4>
        <app-faqs></app-faqs>
      </div>
    </div>
  </div>

  <div id="Settings" class="tab_container">
    <div class="col-md-12">
      <div class="tabcontent profile_page">
        <h4>Game Settings</h4>
          <div class="game_setting sound">
              <!-- <div class="left bar"></div><div class="right bar"></div> -->
              <div class="top bar"></div><div class="bottom bar"></div>
              <div class="internal_control" (click)="toggle_sound_volume()">
                  <i *ngIf="mute_sounds" class="fa-solid fa-volume-high"></i>
                  <i *ngIf="!mute_sounds" class="fa-solid fa-volume-xmark"></i>
                  <span>Sounds</span>
              </div>
              <div class="vol_slider">
                <ngx-slider
                    class="sound-volume-slider" 
                    [(value)]="sound_vol" 
                    [options]="VolumeControls" 
                    (valueChange)="sound_volume($event)" 
                    (userChangeEnd)="sound_volume_end($event)">
                </ngx-slider>
              </div>
          </div>
          <div class="game_setting music">
              <!-- <div class="left bar"></div><div class="right bar"></div> -->
              <div class="top bar"></div><div class="bottom bar"></div>
              <div class="internal_control" (click)="toggle_music_volume()">
                  <i *ngIf="mute_music" class="fa-solid fa-music"></i>
                  <i *ngIf="!mute_music" class="fa-solid fa-volume-xmark"></i>
                  <span>Music</span>
              </div>
              <div class="vol_slider">
                <ngx-slider
                    class="music-volume-slider" 
                    [(value)]="music_vol" 
                    [options]="VolumeControls" 
                    (valueChange)="music_volume($event)" 
                    (userChangeEnd)="music_volume_end($event)">
                </ngx-slider>
              </div>
          </div>
          <div class="game_setting audio_quality">
              <!-- <div class="left bar"></div><div class="right bar"></div> -->
              <div class="top bar"></div><div class="bottom bar"></div>
              <div class="internal_control">
                  <i class="fa-solid fa-headphones"></i>
                  <span>Audio Quality</span>
              </div>
              <div>
                <li (click)="set_audio_quality('low')">
                  <i *ngIf="audio_quality!='low'" [attr.class]="('inactive ')+'fa-regular fa-circle'"></i>
                  <i *ngIf="audio_quality=='low'" [attr.class]="('active ')+'fa-regular fa-circle-check'"></i>
                  <span class="lvl">Low</span> <span class="QualityLevel"><b>MP3</b> (32 Kb/s)</span>
                </li>
                <li (click)="set_audio_quality('mid')">
                  <i *ngIf="audio_quality!='mid'" [attr.class]="('inactive ')+'fa-regular fa-circle'"></i>
                  <i *ngIf="audio_quality=='mid'" [attr.class]="('active ')+'fa-regular fa-circle-check'"></i>
                  <span class="lvl">Medium</span> <span class="QualityLevel"><b>OGG</b> (112 Kb/s)</span>
                </li>
                <li (click)="set_audio_quality('high')">
                  <i *ngIf="audio_quality!='high'" [attr.class]="('inactive ')+'fa-regular fa-circle'"></i>
                  <i *ngIf="audio_quality=='high'" [attr.class]="('active ')+'fa-regular fa-circle-check'"></i>
                  <span class="lvl">High</span> <span class="QualityLevel"><b>WAV</b> (1,410 Kb/s)</span>
                </li>
              </div>
          </div>
      </div>
    </div>
  </div>

  <div id="Airdrops" class="tab_container">
    <app-airdrops></app-airdrops>
  </div>
</div>

<transfer-item
[currentUser]="user"
[pack]="selectedPack"
[show]="showHIVETransfer"
[balance]="balance"
(done)="showHIVETransfer=false">
</transfer-item>