import { Component, OnInit, HostListener, Input, ViewChild, ElementRef } from '@angular/core';
import { Item, User } from '@app/_models';
import { DataService } from '@app/_services/data.service';
import { HelperService } from '@app/_services/helper.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { fade, shop_category } from '../../app.animations';
import { appear } from '../../slcards/slcards.animations';
@Component({ 
    selector: 'battle-log',
    animations: [fade, appear],
    templateUrl: 'log.component.html', 
    styleUrls: ['./log.component.styl'] 
})
export class LogComponent implements OnInit {
    constructor(
            private helper: HelperService, 
            private data: DataService) 
    { }
    @HostListener('window:resize', ['$event'])
        onResize(event) {
            if(window.innerWidth > 1550) {
                this.largeWindow = true;
            } else {
                this.largeWindow = false;
            }
        }
    @ViewChild('scrollbox') private scrollbox: ElementRef;
    @Input('currentClass') currentClass;
    @Input('log_entries') log_entries;
    @Input('in_battle') in_battle;
    @Input('show_log') show_log;
    @Input('result') result;
    @Input('user') user;
    @Input('hero') hero;
    @Input('boss') boss;
    @Input('play') play;
    open_log=false;
    scrolling=false;
    largeWindow=false;
    show_scroll=false;
    production: boolean;
    logScrolled(event) {
        // console.log(this.scrollbox.nativeElement.scrollHeight-this.scrollbox.nativeElement.scrollTop, 620);
        if( (this.scrollbox.nativeElement.scrollHeight-this.scrollbox.nativeElement.scrollTop) > (this.largeWindow?620:396) ) {
            this.show_scroll=true;
        } else {
            this.show_scroll=false;
        }
    }
    @Input('new_entry') set new_entry(now: boolean) {
        if(!this.scrollbox) return;
        // console.log(this.scrollbox.nativeElement.scrollHeight-this.scrollbox.nativeElement.scrollTop, 620);
        if(!this.show_scroll)this.scrollToBottom();
        // if( (this.scrollbox.nativeElement.scrollHeight-this.scrollbox.nativeElement.scrollTop) > (this.largeWindow?620:396) ) {
        //     this.show_scroll=true;
        // } else {
        //     this.show_scroll=false;
        //     this.scrollToBottom();
        // }
    }
    scrollToBottom(): void {
        try {
            if (this.scrolling==false) {
                this.scrolling=true;
                this.show_scroll=false;
                this.scrollbox.nativeElement.scrollTop = this.scrollbox.nativeElement.scrollHeight;
                setTimeout(()=>{ this.scrolling=false; },250);
            }
        } catch(err) { 
            console.error('Error:', err);
        }
    }
    ngOnInit() {
        setTimeout(()=>{this.open_log = true;},1500);
        this.production = environment.production;
    }
    isBossEntry(entry, name) {
        return entry.action.indexOf('hit by ' + name) > -1 || entry.action.indexOf('boss missed') > -1 || entry.action.indexOf('boss swipes') > -1 || entry.action.indexOf('DIED!') > -1 || entry.action.indexOf('poison') > -1 || entry.action.indexOf('enraged') > -1;
    }
    isDmg(entry) {
        return (entry.dmgType === 'ranged' || entry.dmgType === 'attack' || entry.dmgType === 'magic') || entry.value > 0;
    }
}