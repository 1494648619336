import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tx_title'
})
export class TXTitlePipe implements PipeTransform {
	transform(value: any, args?: any): any {
		let values;
		if (value) { 
			switch(value) {
				case 'withdraw to Hive-Engine':
					values = "Withdraw to HIVE";
				break;
				case 'transfer from Hive-Engine':
					values = "Transfer from HIVE";
				break;
				case 'buyPack':
					values = "Buy Pack";
				break;
				case 'buyPotion':
					values = "Buy Potion";
				break;
				case 'buyElixir':
					values = "Buy Elixir";
				break;
				case 'bossbattle':
					values = "Boss Battle";
				break;
				case 'bossFightReward':
					values = "Boss Battle Reward";
				break;
				case 'bossRewards':
					values = "Boss Rewards";
				break;
				default:
					values = value;
				break;
			}
		} else {
			values = value;
		}
		return values;
	}
}