  <div class="forgemodal" [@fade]="loginModal">
    <div class="boxwrap" [@flip_up]="loginModal">
      <div class="topwrap">
        <h1>Login</h1>
        <div class="closewrapper" (click)="hide_login()" (keyup.enter)="hide_login()" tabindex="14">
          <i class="fa-solid fa-x"></i>
        </div>
        <div class="textwrap login_textwrap">
          <h1 class="orange">
            Login using your <a href="https://hive-keychain.com/" target="_blank">Hive Keychain</a>
          </h1>
          <div class="login_status" *ngIf="helper.login_results || login_error">
            <!-- <i class="login-spinner fa-spin fa-solid fa-spinner" *ngIf="helper.login_loading"></i> -->
            <span class="capitalize" *ngIf="!login_error">
              <i class="login-error fa-solid fa-x" *ngIf="helper.login_error"></i>
              <span>{{helper.login_results}}</span>
            </span>
            <span *ngIf="login_error">
              <i class="login-error fa-solid fa-x"></i>
              Enter a correct username.
            </span>
          </div>
          <div class="login_status" *ngIf="!helper.login_results && !login_error">
            Please enter the HIVE Keychain username that you use on SplinterLands and import your deck to SplinterForge.
          </div>
          <div class="hive-keychain-container">
            <a href="https://hive-keychain.com/" target="_blank">
              <i class="fa-brands fa-hive hiveicon"></i>
              <span>HIVE Username:</span>
            </a>
            <input class="username_entry" tabindex="12" placeholder="HIVE Username" type="text" [(ngModel)]="username" (keyup.enter)="login()" />
          </div>
          <div class="login_button_container">
            <div *ngIf="needToS">
              <input type="checkbox" [(ngModel)]="acceptedToS">
              <span class="inputtext">I accept that by logging in I agree to the SplinterForge 
                <a href="https://splinterforge.io/Terms_of_Use_Splinterforge.pdf" target="_blank"> ToS</a> & 
                <a href="https://splinterforge.io/Privacy Policy.pdf" target="_blank"> Privacy Policy</a>.
              </span>
            </div>            
            <button class="red button" tabindex="13" (click)="login()" (keyup.enter)="login()">
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>