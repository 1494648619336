<section class="forgemodal gold" [@fade]="show">
	<div class="boxwrap" [@flip_up]="show">
		<h1 [innerHTML]="title"></h1>
		<div class="left">
			<h3>{{method === 'FRAGS' ? 'Salvage' : 'Burn'}} {{burn_gems.length}} {{burn_gems.length==1?'Card':'Cards'}}</h3>
			<div *ngIf="method !== 'FRAGS'" class="selection">
				<div class="radio" (click)="change_method('FORGE')">
					<i *ngIf="method!='FORGE'" class="fa-solid fa-circle"></i>
					<i *ngIf="method=='FORGE'" class="fa-solid fa-circle-dot"></i>
					<span>Forge</span>
				</div>
				<div class="radio" (click)="change_method('Electrum')">
					<i *ngIf="method!='Electrum'" class="fa-solid fa-circle"></i>
					<i *ngIf="method=='Electrum'" class="fa-solid fa-circle-dot"></i>
					<span>Electrum</span>
				</div>
			</div>
			<p>{{top_label}} {{burn_value}} <span class="uppercase">{{method}}</span>?</p>
			<ul class="gem_list">
				<li *ngFor="let g of burn_gems">
					<i class="fa-solid fa-check"></i>
					{{g.name}}
				</li>
			</ul>
			<div class="button_group" *ngIf="modal_mode=='confirmation'">
				<button (click)="done_button(0)" class="button gray align_left">Cancel</button>
				<button (click)="done_button(type)" class="button orange align_right">{{title==='Transfer Card'?'Transfer': title==='Salvage Card' ? 'Salvage' : 'Burn'}}</button>
				<div class="clear"></div>
			</div>
			<div class="button_group" *ngIf="modal_mode==''">
				<button (click)="close()" class="button gold">Close</button>
				<div class="clear"></div>
			</div>
		</div>
		<div class="right account-card">
		   <sfcard-selector
				class="sfcard"
				[sfcards]="cards"
				[sfcard]="card"
				[stackView]="false">
			</sfcard-selector>
		</div>
		<div class="closewrapper" (click)="close()">
			<i class="fa-solid fa-x"></i>
		</div>
	</div>
	<div class="backdrop" [@pointer]="show" (click)="close()"></div>
</section>