  <div [@fade]="noKeychainModal" class="forgemodal nokeychain">
    <div class="boxwrap" [@flip_up]="noKeychainModal">
      <div class="topwrap">
        <h1>Play SplinterForge</h1>
        <h1 class="red">
          You need a <a href="https://hive-keychain.com/" target="_blank">HIVE account</a> before you can play!
        </h1>
      </div>
      <div class="bottomwrap">
        <div class="itemwrapper">
          <div class="itembox first" tabindex="3" (click)="gourl('https://signup.hive.io/')" (keyup.enter)="gourl('https://signup.hive.io/')">
            <div class="imagewrapper">
              <img src="https://d36mxiodymuqjm.cloudfront.net/website/splinterlands_logo.png">
            </div>
            <ul>
              <li>New Hive Account</li>
              <li>One Summoner's Spellbook included ($10)</li>
              <li>Join one of the most popular game built on blockchain technology</li>
            </ul>
            <div class="buttonwrap">
              <button class="orange button">
                <a href="https://splinterlands.com?ref=rareraptor" target="_blank">Select & Play</a>
              </button>
            </div>
          </div>
          <div class="itembox" tabindex="4" (click)="gourl('https://signup.hive.io/')" (keyup.enter)="gourl('https://signup.hive.io/')">
            <div class="imagewrapper key">
              <img src="/assets/key.png">
            </div>
            <ul>
              <li>New Hive Account</li>
              <li>Select between multiple providers</li>
              <li>Discover new options</li>
              <li>Free</li>
            </ul>
            <div class="buttonwrap">
              <button class="orange button">
                <a href="https://signup.hive.io/" target="_blank">Explore</a>
              </button>
            </div>
          </div>
        </div>
        <div class="textwrap get_keychain" tabindex="5" (click)="gourl('app')" (keyup.enter)="gourl('app')">
          <div class="keylogo">
            <a href="https://chrome.google.com/webstore/detail/hive-keychain/jcacnejopjdphbnjgfaaobbfafkihpep?hl=en&u_producttype=chrome-extension-667709&u_country=au&funnel=convert-pdf&gclid=CjwKCAjwtfqKBhBoEiwAZuesiBiYGbKtD3I_Z8CPJ2YUQffm8BBQZUtl580jb5hIuNlFoDrCW3jR-xoCLPYQAvD_BwE" target="_blank"><img src="/assets/key.png"></a>
          </div>
          SplinterForge only works with Hive Keychain. Click here to download the <b>HIVE Blockchain</b> browser extension that you need to login and play. 
          <a href="https://chrome.google.com/webstore/detail/hive-keychain/jcacnejopjdphbnjgfaaobbfafkihpep?hl=en&u_producttype=chrome-extension-667709&u_country=au&funnel=convert-pdf&gclid=CjwKCAjwtfqKBhBoEiwAZuesiBiYGbKtD3I_Z8CPJ2YUQffm8BBQZUtl580jb5hIuNlFoDrCW3jR-xoCLPYQAvD_BwE" target="_blank" class="download_link">
            Download
            <i class="fa-solid fa-up-right-from-square"></i>
          </a>
        </div>
        <div class="closewrapper" tabindex="6" (click)="close()" (keyup.enter)="close()">
          <i class="fa-solid fa-x"></i>
        </div>
      </div>
    </div>
  </div>