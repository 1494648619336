import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'name'
})
export class NamePipe implements PipeTransform {
	transform(value: any, args?: any): any {
		let values = [];
		if(value)for(var i=0;i<value.length;i++){
			var aa = value[i].name.toLowerCase();
			if(args){
				if(aa.indexOf(args.toLowerCase())>-1)values.push(value[i]);
			} else {
				values.push(value[i]);
			}
		}
		return values;
	}
}