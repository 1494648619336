import { Component, OnInit, Input } from '@angular/core';
import { HelperService } from '../../_services/helper.service';
import { opacity } from '../slcards.animations'
@Component({
  selector: 'boss-max-levels',
  animations: [opacity],
  templateUrl: './max-levels.component.html',
  styleUrls: ['./max-levels.component.styl']
})
export class MaxLevelsComponent implements OnInit {
  constructor(private helper: HelperService) { }
  @Input('maxLevels') maxLevels:any;
  @Input('small') small: boolean;
  ngOnInit(): void {
    var l = localStorage.getItem('show_max_levels');
    if(l)this.helper.show_max_levels = true;
  }
  toggle_labels() {
    this.helper.show_max_levels=this.helper.show_max_levels?false:true;
    if(this.helper.show_max_levels){
      localStorage.setItem('show_max_levels', '1');
    } else {
      localStorage.removeItem('show_max_levels');
    } 
  }
}
