import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'foil'
})
export class FoilPipe implements PipeTransform {
	transform(value: any, args?: any): any {
    if(args == -1 || args == 'All' || args == false) return value;
		let values = [];
		if (value&&value.length>0) for (var i = 0; i < value.length; i++)if (value[i].foil)values.push(value[i]);
		return values;
	}
}