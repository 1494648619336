<div class="background" *ngIf="heading"></div>
<!-- <div *ngIf="!viewCard" class="background"></div>
<div *ngIf="viewCard" class="background view_card"></div> -->
<div class="page_heading" *ngIf="heading">
    <h1 style="display: inline-block;">My Heroes</h1>
    <div *ngIf="heroCards && heroCards.length > 0" class="dd">
        <button class="ddbtn">Select Hero</button>
        <div class="dd-content">
            <a *ngFor="let card of heroCards" (click)="changeHero(card)">{{card.name}}</a>
        </div>
    </div>
    <div style="display: inline-block;">
        <button class="button purple" *ngIf="view !== 'statView'" (click)="view = 'statView'">Stats View</button>
        <button class="button purple" *ngIf="view !== 'heroView'" (click)="view = 'heroView'">Hero View</button>
        <button class="button purple" *ngIf="view !== 'leaderView'" (click)="getLeaders()">Leaders View</button>
    </div>
    <div *ngIf="currentHero && currentHero.loadOuts && 1 > 2" class="dd">
        <button class="ddbtn">Select Loadout</button>
        <div class="dd-content">
            <a (click)="showNewLoadOut = true">Save</a>
            <a *ngFor="let lo of currentHero.loadOuts" (click)="applyLoadOut(lo)">{{lo.name}}</a>
        </div>
        <div style="display: inline-block;" *ngIf="currentLoadOut && !showNewLoadOut">
            Current: <input type="text" [(ngModel)]="currentLoadOut" disabled />
            <button class="button green" (click)="saveLoadOut()">SAVE</button>
        </div>
        <div style="display: inline-block;" *ngIf="showNewLoadOut" title="Enter name of existing loadout to update it, or a new name to create a new loadout.">
            <input type="text" placeholder="loadout name" [(ngModel)]="newLoadOutName" />
            <button class="button green" (click)="saveLoadOut()">SAVE</button>
            <button class="button red" (click)="showNewLoadOut = false;">X</button>
        </div>
    </div>
    <div class="bottom_border"></div>
    <div class="clear"></div>
</div>
<div [attr.class]="!heading?'no_header boxwrap':'boxwrap'">
    <section *ngIf="currentHero" id="heropage" [attr.class]="!heading?'shorter':''">
        <div class="tabcontent hero_container">
            <div style="color: white;">
                <div *ngIf="view === 'leaderView'" class="hero_pose">
                    <h5>Highest Level Heroes!</h5>
                    <p>This leaderboard is just bragging rights!</p>
                    <table *ngIf="leaders">
                        <tr>
                            <th>User</th>
                            <th>Hero</th>
                            <th>Type</th>
                            <th>Level</th>
                        </tr>
                        <tr *ngFor="let r of leaders">
                            <td>{{r.user}}</td>
                            <td>{{r.hero}}</td>
                            <td>{{r.type}}</td>
                            <td>{{r.level}}</td>
                        </tr>
                    </table>
                </div>
                <div *ngIf="view === 'heroView'" [attr.class]="sidebar?'hero_pose':'hero_pose full_width'">
                    <div class="heroleftequip">
                        <div [attr.class]="selected_slot=='back'?'active equippedcard':'equippedcard'" (click)="inspectSlot('back')">
                            <div class="fantacy_borders">
                                <div class="top_left"></div>
                                <div class="top_right"></div>
                                <div class="bottom_right"></div>
                                <div class="bottom_left"></div>
                            </div>
                            <img *ngIf="!img_enabled['back']" src="{{img['back']}}" loading="lazy" [attr.title]="equippedItem['back']" class="item_button">
                            <img *ngIf="img_enabled['back']" src="{{img['back']}}" loading="lazy" [attr.title]="equippedItem['back']" class="character_button">
                            <span class="equipped_label">Back</span>
                            <div class="socketbox">
                                <div *ngFor="let sock of socket_items['back']; let i = index;" 
                                    [attr.class]="'socketloopdiv '+sock.class+' _i'+i"
                                    [ngStyle]="{'background-image': 'url(' +sock.socket_item+ ')'}" 
                                    [attr.title]="sock.ability_description?sock.name+' ('+sock.ability_description+')':''"></div>
                            </div>
                        </div>
                        <div [attr.class]="selected_slot=='necklace'?'active equippedcard':'equippedcard'" (click)="inspectSlot('necklace')">
                            <div class="fantacy_borders">
                                <div class="top_left"></div>
                                <div class="top_right"></div>
                                <div class="bottom_right"></div>
                                <div class="bottom_left"></div>
                            </div>
                            <img *ngIf="!img_enabled['necklace']" src="{{img['necklace']}}" loading="lazy" [attr.title]="equippedItem['necklace']" class="item_button">
                            <img *ngIf="img_enabled['necklace']" src="{{img['necklace']}}" loading="lazy" [attr.title]="equippedItem['necklace']" class="character_button">
                            <span class="equipped_label">Necklace</span>
                            <div class="socketbox">
                                <div *ngFor="let sock of socket_items['necklace']; let i = index;" 
                                    [attr.class]="'socketloopdiv '+sock.class+' _i'+i"
                                    [ngStyle]="{'background-image': 'url(' +sock.socket_item+ ')'}" 
                                    [attr.title]="sock.ability_description?sock.name+' ('+sock.ability_description+')':''"></div>
                            </div>
                        </div>
                        <div [attr.class]="selected_slot=='hands'?'active equippedcard':'equippedcard'" (click)="inspectSlot('hands')">
                            <div class="fantacy_borders">
                                <div class="top_left"></div>
                                <div class="top_right"></div>
                                <div class="bottom_right"></div>
                                <div class="bottom_left"></div>
                            </div>
                            <img *ngIf="!img_enabled['hands']" src="{{img['hands']}}" loading="lazy" [attr.title]="equippedItem['hands']" class="item_button">
                            <img *ngIf="img_enabled['hands']" src="{{img['hands']}}" loading="lazy" [attr.title]="equippedItem['hands']" class="character_button">
                            <span class="equipped_label">Hands</span>
                            <div class="socketbox">
                                <div *ngFor="let sock of socket_items['hands']; let i = index;" 
                                    [attr.class]="'socketloopdiv '+sock.class+' _i'+i"
                                    [ngStyle]="{'background-image': 'url(' +sock.socket_item+ ')'}" 
                                    [attr.title]="sock.ability_description?sock.name+' ('+sock.ability_description+')':''"></div>
                            </div>
                        </div>
                        <div [attr.class]="selected_slot=='feet'?'active equippedcard':'equippedcard'" (click)="inspectSlot('feet')">
                            <div class="fantacy_borders">
                                <div class="top_left"></div>
                                <div class="top_right"></div>
                                <div class="bottom_right"></div>
                                <div class="bottom_left"></div>
                            </div>
                            <img *ngIf="!img_enabled['feet']" src="{{img['feet']}}" loading="lazy" [attr.title]="equippedItem['feet']" class="item_button">
                            <img *ngIf="img_enabled['feet']" src="{{img['feet']}}" loading="lazy" [attr.title]="equippedItem['feet']" class="character_button">
                            <span class="equipped_label">Feet</span>
                            <div class="socketbox">
                                <div *ngFor="let sock of socket_items['feet']; let i = index;" 
                                    [attr.class]="'socketloopdiv '+sock.class+' _i'+i"
                                    [ngStyle]="{'background-image': 'url(' +sock.socket_item+ ')'}" 
                                    [attr.title]="sock.ability_description?sock.name+' ('+sock.ability_description+')':''"></div>
                            </div>
                        </div>
                        <div [attr.class]="selected_slot=='weapon'?'active equippedcard':'equippedcard'" (click)="inspectSlot('weapon')">
                            <div class="fantacy_borders">
                                <div class="top_left"></div>
                                <div class="top_right"></div>
                                <div class="bottom_right"></div>
                                <div class="bottom_left"></div>
                            </div>
                            <img *ngIf="!img_enabled['weapon']" src="{{img['weapon']}}" loading="lazy" [attr.title]="equippedItem['weapon']" class="item_button">
                            <img *ngIf="img_enabled['weapon']" src="{{img['weapon']}}" loading="lazy" [attr.title]="equippedItem['weapon']" class="character_button">
                            <span class="equipped_label">Weapon</span>
                            <div class="socketbox">
                                <div *ngFor="let sock of socket_items['weapon']; let i = index;" 
                                    [attr.class]="'socketloopdiv '+sock.class+' _i'+i"
                                    [ngStyle]="{'background-image': 'url(' +sock.socket_item+ ')'}" 
                                    [attr.title]="sock.ability_description?sock.name+' ('+sock.ability_description+')':''"></div>
                            </div>
                        </div>
                    </div>
                    <div class="heroimage">
                        <img src="{{'/assets/heroes/' + currentHero.type.toLowerCase() + '_sketch.png'}}" 
                             loading="lazy">
                        
                        <div class="relic" (click)="inspectSlot('relic')">
                            <div class="fantacy_borders">
                                <div class="top_left"></div>
                                <div class="top_right"></div>
                                <div class="bottom_right"></div>
                                <div class="bottom_left"></div>
                            </div>
                            <img *ngIf="!img_enabled['relic']" src="{{img['relic']}}" loading="lazy" [attr.title]="equippedItem['relic']" class="item_button">
                            <img *ngIf="img_enabled['relic']" src="{{img['relic']}}" loading="lazy" [attr.title]="equippedItem['relic']" class="character_button">
                            <span class="equipped_label">Relic</span>
                        </div>
                    </div>
                    <div class="herorightequip">
                        <div [attr.class]="selected_slot=='head'?'active equippedcard':'equippedcard'" (click)="inspectSlot('head')">
                            <div class="fantacy_borders">
                                <div class="top_left"></div>
                                <div class="top_right"></div>
                                <div class="bottom_right"></div>
                                <div class="bottom_left"></div>
                            </div>
                            <img *ngIf="!img_enabled['head']" src="{{img['head']}}" loading="lazy" [attr.title]="equippedItem['head']" class="item_button">
                            <img *ngIf="img_enabled['head']" src="{{img['head']}}" loading="lazy" [attr.title]="equippedItem['head']" class="character_button">
                            <span class="equipped_label">Head</span>
                            <div class="socketbox">
                                <div *ngFor="let sock of socket_items['head']; let i = index;" 
                                    [attr.class]="'socketloopdiv '+sock.class+' _i'+i"
                                    [ngStyle]="{'background-image': 'url(' +sock.socket_item+ ')'}" 
                                    [attr.title]="sock.ability_description?sock.name+' ('+sock.ability_description+')':''"></div>
                            </div>
                        </div>
                        <div [attr.class]="selected_slot=='body'?'active equippedcard':'equippedcard'" (click)="inspectSlot('body')">
                            <div class="fantacy_borders">
                                <div class="top_left"></div>
                                <div class="top_right"></div>
                                <div class="bottom_right"></div>
                                <div class="bottom_left"></div>
                            </div>
                            <img *ngIf="!img_enabled['body']" src="{{img['body']}}" loading="lazy" [attr.title]="equippedItem['body']" class="item_button">
                            <img *ngIf="img_enabled['body']" src="{{img['body']}}" loading="lazy" [attr.title]="equippedItem['body']" class="character_button">
                            <span class="equipped_label">Body</span>
                            <div class="socketbox">
                                <div *ngFor="let sock of socket_items['body']; let i = index;"
                                    [attr.class]="'socketloopdiv '+sock.class+' _i'+i"
                                    [ngStyle]="{'background-image': 'url(' +sock.socket_item+ ')'}" 
                                    [attr.title]="sock.ability_description?sock.name+' ('+sock.ability_description+')':''"></div>
                            </div>
                        </div>
                        <div [attr.class]="selected_slot=='legs'?'active equippedcard':'equippedcard'" (click)="inspectSlot('legs')">
                            <div class="fantacy_borders">
                                <div class="top_left"></div>
                                <div class="top_right"></div>
                                <div class="bottom_right"></div>
                                <div class="bottom_left"></div>
                            </div>
                            <img *ngIf="!img_enabled['legs']" src="{{img['legs']}}" loading="lazy" [attr.title]="equippedItem['legs']" class="item_button">
                            <img *ngIf="img_enabled['legs']" src="{{img['legs']}}" loading="lazy" [attr.title]="equippedItem['legs']" class="character_button">
                            <span class="equipped_label">Legs</span>
                            <div class="socketbox">
                                <div *ngFor="let sock of socket_items['legs']; let i = index;"
                                    [attr.class]="'socketloopdiv '+sock.class+' _i'+i"
                                    [ngStyle]="{'background-image': 'url(' +sock.socket_item+ ')'}" 
                                    [attr.title]="sock.ability_description?sock.name+' ('+sock.ability_description+')':''"></div>
                            </div>
                        </div>
                        <div [attr.class]="selected_slot=='ring'?'active equippedcard':'equippedcard'" (click)="inspectSlot('ring')">
                            <div class="fantacy_borders">
                                <div class="top_left"></div>
                                <div class="top_right"></div>
                                <div class="bottom_right"></div>
                                <div class="bottom_left"></div>
                            </div>
                            <img *ngIf="!img_enabled['ring']" src="{{img['ring']}}" loading="lazy" [attr.title]="equippedItem['ring']" class="item_button">
                            <img *ngIf="img_enabled['ring']" src="{{img['ring']}}" loading="lazy" [attr.title]="equippedItem['ring']" class="character_button">
                            <span class="equipped_label">Ring</span>
                            <div class="socketbox">
                                <div *ngFor="let sock of socket_items['ring']; let i = index;"
                                    [attr.class]="'socketloopdiv '+sock.class+' _i'+i"
                                    [ngStyle]="{'background-image': 'url(' +sock.socket_item+ ')'}" 
                                    [attr.title]="sock.ability_description?sock.name+' ('+sock.ability_description+')':''"></div>
                            </div>
                        </div>
                        <div [attr.class]="selected_slot=='offhand'?'active equippedcard':'equippedcard'" (click)="inspectSlot('offhand')">
                            <div class="fantacy_borders">
                                <div class="top_left"></div>
                                <div class="top_right"></div>
                                <div class="bottom_right"></div>
                                <div class="bottom_left"></div>
                            </div>
                            <img *ngIf="!img_enabled['offhand']" src="{{img['offhand']}}" loading="lazy" [attr.title]="equippedItem['offhand']" class="item_button">
                            <img *ngIf="img_enabled['offhand']" src="{{img['offhand']}}" loading="lazy" [attr.title]="equippedItem['offhand']" class="character_button">
                            <span class="equipped_label">Offhand</span>
                            <div class="socketbox">
                                <div *ngFor="let sock of socket_items['offhand']; let i = index;"
                                    [attr.class]="'socketloopdiv '+sock.class+' _i'+i"
                                    [ngStyle]="{'background-image': 'url(' +sock.socket_item+ ')'}" 
                                    [attr.title]="sock.ability_description?sock.name+' ('+sock.ability_description+')':''"></div> 
                            </div>
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
                <div *ngIf="view === 'statView'" class="hero_pose">
                    <div *ngIf="levelDetails">
                        <div *ngIf="options">
                            <h5>Hero Options</h5>
                            <h5>Skill Reset</h5>
                            <div *ngIf="(!currentHero.freeResets || currentHero.freeResets < 1)">
                                <p>Each hero recieves 1 FREE skill reset, the next skill reset will require a FORGE burn based on how many skill points and Electrum will be reset.</p>
                                <div *ngIf="skillResetDetails">
                                    Resetting your skills will restore {{skillResetDetails.usedSkillPoints}} skill points and {{skillResetDetails.totalElectrum}} Electrum!<br/>
                                </div>
                                <button class="button red" (click)="resetHeroSkills()" title="First reset is free!">FREE SKILL RESET</button>
                            </div>
                            <div *ngIf="(currentHero.freeResets && currentHero.freeResets >= 1)">
                                <p>Skill reset requires a FORGE burn based on the number of skill points and Electrum to be reset.</p>
                                <div *ngIf="skillResetDetails">
                                    Each USED skill point will increase burn required by 100 FORGE.<br/>
                                    Resetting your skills will restore {{skillResetDetails.usedSkillPoints}} skill points and {{skillResetDetails.electrumRefund}} out of {{skillResetDetails.totalElectrum}} Electrum!<br/>
                                    <p style="color: red;">This reset will require a {{skillResetDetails.cost}} FORGE burn!</p>
                                </div>
                                <button *ngIf="skillResetDetails.cost > 0" class="button red" (click)="resetHeroSkills()" title="First reset is free!">BURN {{skillResetDetails.cost}} FORGE to RESET skills</button>
                                <button *ngIf="skillResetDetails.cost === 0" class="button red">Nothing to reset...</button>
                            </div>
                            <div>
                                <h5>Name Change</h5>
                                <div *ngIf="!basicName()" title="Additional name change!" style="color: red;">This name change will require a 20,000 FORGE burn!</div>
                                <input title="Must be 3-16 alpha numeric characters." type="text" placeholder="new name" [(ngModel)]="newName" />
                                <button *ngIf="!validName()" class="button red">Not Valid</button>
                                <button [disabled]="loading" *ngIf="validName()" class="button green" (click)="submitNewName()">{{!basicName() ? 'Burn 20,000 FORGE to ' : ''}}Change Name</button>
                            </div>
                        </div>
                        <div *ngIf="!options">
                            <div>
                                <h5>Account balances</h5>
                                <div>
                                    Spendable account XP: {{user.xp}}
                                </div>
                                <div *ngIf="user.sc.balance > 999">
                                    <button *ngIf="!showBurnForXP" [disabled]="user.sc.balance < 1000" class="button green" (click)="showBurnForXP = true">Burn FORGE for XP</button>
                                </div>
                                <div style="display: inline-block;" *ngIf="showBurnForXP">
                                    <h5>Enter qty to BURN!</h5>
                                    <input type="number" placeholder="1000" min="1000" step="1000" [attr.max]="user.sc.balance" [(ngModel)]="burnForXPQty" />
                                    <button [disabled]="loading" class="button green" title="Burn FORGE for equal amount of XP!" (click)="burnForXP()">BURN!</button>
                                    <button class="button red" (click)="showBurnForXP = false;">X</button>
                                </div>
                                <div>Electrum: {{user.electrum}}</div>
                                <div>Evolution Essence: {{user.essence}}</div>
                            </div>
                            <div>
                                <h5>Hero Details</h5>
                                <div>
                                    Name: {{currentHero.name}}
                                    <button class="button purple" *ngIf="!showNameChange && basicName()" (click)="showNameChange = true;">Change Name</button>
                                    <div *ngIf="showNameChange">
                                        <h5>Enter New Name</h5>
                                        <input title="Must be 3-16 alpha numeric characters." type="text" placeholder="new name" [(ngModel)]="newName" />
                                        <button *ngIf="!validName()" class="button red">Not Valid</button>
                                        <button [disabled]="loading" *ngIf="validName()" class="button green" (click)="submitNewName()">Submit new name</button>
                                        <button class="button red" (click)="showNameChange = false;">X</button>
                                    </div>
                                </div>
                                <div>XP: {{currentHero.xp}}</div>
                            </div>
                            <div>
                                Level: {{levelDetails.level}} - Progress: {{currentHero.xp - levelDetails.used}} of {{levelDetails.next}}
                                <button [disabled]="loading" class="button purple" *ngIf="user.xp > levelDetails.used + levelDetails.next - currentHero.xp" (click)="levelHero()">
                                    Level Up!
                                </button>
                            </div>
                            <div>
                                Rank: {{currentHero.rank}} 
                                <button [disabled]="loading" class="button purple" *ngIf="currentHero.promotable && user.essence >= ((currentHero.rank+1)*50)" (click)="promoteHero()">
                                    Promote ({{((currentHero.rank+1)*50)}} Essence)!
                                </button>
                                <span [disabled]="loading" *ngIf="currentHero.promotable && user.essence < ((currentHero.rank+1)*50)">
                                    - You need {{((currentHero.rank+1)*50)-user.essence}} more Essence to promote
                                </span>
                                <span [disabled]="loading" *ngIf="!currentHero.promotable">
                                    - {{(currentHero.rank*10)+10-currentHero.level}} more levels needed for next rank
                                </span>
                            </div>
                            <!--<div>Needed: {{levelDetails.next + levelDetails.used - currentHero.xp}}</div>-->
                            <div *ngIf="currentHero.skills && currentHero.skills.length > 0">
                                <h5>Trainable Skills</h5>
                                <div *ngFor="let cur of currentHero.skills">
                                    <div *ngIf="cur.type === 'trained'" [title]="cur.desc">
                                        <span title="Spend Electrum to raise the level of this skill.">
                                            <button *ngIf="cur.level < 10 || (cur.level < 15 && cur.tier === 2) || (cur.level < 20 && cur.tier === 3)" [disabled]="user.electrum < skillXPNeeded(cur) || loading" class="button green" (click)="levelSkill(cur)">
                                                &uarr; {{skillXPNeeded(cur)}} Electrum
                                            </button>
                                            <button class="button red" *ngIf="(cur.level === 10 && cur.tier === 1) || (cur.level === 15 && cur.tier === 2) || (cur.level === 20 && cur.tier === 3)">
                                                {{cur.tier === 3 ? 'MAX!!!' : 'Tier up to level more...'}}
                                            </button>
                                        </span>
                                        Tier {{cur.tier}}: {{'Lvl ' + cur.level + ' - ' + cur.name}} - {{cur.desc}}
                                    </div>
                                </div>
                                <h5>Untrainable Skills</h5>
                                <div *ngFor="let cur of currentHero.skills">
                                    <div *ngIf="cur.type === 'passive'" [title]="cur.desc">
                                        Tier {{cur.tier}}: {{cur.name}}
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!currentHero.skills || currentHero.skills.length === 0">
                                <h5>No skills yet.</h5>
                                You have {{currentHero.skillPts}} skill points available.
                                <div *ngIf="currentHero.skillPts === 0">You get 1 skill point everytime your hero levels.</div>
                            </div>
                            <div *ngIf="currentHero.skillPts > 0">
                                <h5>You have {{currentHero.skillPts}} skill points to spend.</h5>
                                <button class="button purple" (click)="useSkillPts()">Use Skill Points</button>
                            </div>
                        </div>
                        <button class="button purple" (click)="toggleOptions()">{{options ? 'Close Options' : 'Options'}}</button>
                    </div>
                </div>
                <div class="heroleft" *ngIf="sidebar">
                    <h3 class="nameclassbox glow_box" *ngIf="heading">
                        <span>{{currentHero.name}}</span>
                    </h3>
                    <div class="nameclassbox hero_selector glow_box">
                        <div class="dropdown" (click)="show_types=show_types?false:true">
                            <div class="label">
                                <span>Class: {{currentHero.type}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="statbox glow_box">
                        <div class="label" (click)="show_stats=show_stats?false:true">
                            <span>{{currentHero.name}} Stats</span>
                            <i class="fa-solid fa-chevron-down open" [@flip]="show_stats">
                            </i>
                        </div>
                        <div class="statsbox" [@stats]="show_stats">
                            <div class="stat melee" *ngIf="attack > 0">
                                <div class="icon">
                                    <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/melee-attack.png" loading="lazy">
                                    <div class="overlay">{{attack}}</div>
                                </div>
                                <div class="title">Attack</div>
                            </div>
                            <div class="stat magic" *ngIf="magic > 0">
                                <div class="icon">
                                    <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/magic-attack.png" loading="lazy">
                                    <div class="overlay">{{magic}}</div>
                                </div>
                                <div class="title">Magic</div>
                            </div>
                            <div class="stat ranged" *ngIf="ranged > 0">
                                <div class="icon">
                                    <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/ranged-attack.png" loading="lazy">
                                    <div class="overlay">{{ranged}}</div>
                                </div>
                                <div class="title">Ranged</div>
                            </div>
                            <div class="stat speed" *ngIf="speed > 0">
                                <div class="icon">
                                    <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/speed.png" loading="lazy">
                                    <div class="overlay">{{speed}}</div>
                                </div>
                                <div class="title">Speed</div>
                            </div>
                            <div class="stat armor" *ngIf="armor > 0">
                                <div class="icon">
                                    <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/defense.png" loading="lazy">
                                    <div class="overlay">{{armor}}</div>
                                </div>
                                <div class="title">Armor</div>
                            </div>
                            <div class="stat health" *ngIf="health > 0">
                                <div class="icon">
                                    <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/health.png" loading="lazy">
                                    <div class="overlay">{{health}}</div>
                                </div>
                                <div class="title">Health</div>
                            </div>
                        </div>
                    </div>
                    <div class="abilitiesbox glow_box">
                        <div class="limitsbox">
                            <div class="maxbox">
                                <div class="mana_filter monsters">
                                    <button>
                                        <span>{{maxMonsters}}</span>
                                    </button>
                                </div>
                                <h5>Max Monsters</h5>
                            </div>
                            <div class="maxbox">
                                <div class="mana_filter">
                                    <button>
                                        <span>{{maxMana}}</span>
                                    </button>
                                </div>
                                <h5>Max Mana</h5>
                            </div>
                        </div>
                    </div>
                    <div class="abilitiesbox glow_box">
                        <h3 class="abilities_header">Abilities</h3>
                        <div class="activatedabilities">
                            <div class="no_abilities" *ngIf="!hasHeroAbilities">
                                <h4>No Abilities</h4>
                            </div>
                            <div class="abilitylist" *ngIf="hasHeroAbilities">
                                <div *ngFor="let abi of heroAbilities">
                                    <div *ngIf="abi.bonus>0" class="bonus">
                                        <h5 class="left">{{abi.type|remove_plus}}</h5>
                                        <h4 class="right">+{{abi.bonus}}</h4>
                                        <div class="clear"></div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                <div class="clear"></div>
            </div>
        </div>
    </section>
</div>
<notice-modal
    [title]="'No Cards'"
    [label]="'You do not have any cards.'"
    [show]="show_no_cards"
    (done)="show_no_cards=false"
></notice-modal>
<equip-card
    *ngIf="viewCard"
    (done)="hide_cards()"
    (doAction)="doAction($event)"
    [show]="viewCard"
    [cards]="sendCards()"
    [equipped]="equipped"
    [equippable]="equippable"
    [selectedHero]="currentHero">
</equip-card>
<skills
    *ngIf="viewSkills"
    (done)="viewSkills = false"
    (doAction)="doAction($event)"
    [show]="viewSkills"
    [hero]="currentHero"
    [skills]="filteredSkills">
</skills>
<div class="clear"></div>