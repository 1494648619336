<section class="forgemodal" [@fade]="show" *ngIf="show">
    <div [attr.class]="darken_borders?'boxwrap large_info dark_borders':'boxwrap large_info'" [@flip_up]="show">
      <h1>Welcome to SplinterForge.io</h1>
      <div tabindex="0" class="closewrapper" (click)="done()">
          <i class="fa-solid fa-x"></i>
      </div>
      <div class="welcome_continer">
        <div class="subtitle">
          <div tabindex="1" class="navwrapper" (click)="toggle_nav()">
            <div [attr.class]="nav_open?'bars active':'bars'">
              <div class="one"></div>
              <div class="two"></div>
              <div class="three"></div>
            </div>
          </div>
          <div [attr.class]="nav_open?'tab open':'tab'">
              <button tabindex="2" [attr.class]="(tab=='Welcome')?'active black button':'black button'" 
                      (keyup)="tabKey($event,'Welcome')"
                      (click)="tabClick('Welcome')">
                        Welcome
              </button>
              <button tabindex="3" [attr.class]="(tab=='Navigation')?'active black button':'black button'" 
                      (keyup)="tabKey($event,'Navigation')"
                      (click)="tabClick('Navigation')">
                        Navigation
              </button>
              <button tabindex="4" [attr.class]="(tab=='Shop')?'active black button':'black button'" 
                      (keyup)="tabKey($event,'Shop')"
                      (click)="tabClick('Shop')">
                        Shop
              </button>
              <button tabindex="5" [attr.class]="(tab=='OpenPacks')?'active black button':'black button'" 
                      (keyup)="tabKey($event,'OpenPacks')"
                      (click)="tabClick('OpenPacks')">
                        The Forge
              </button>
              <button tabindex="6" [attr.class]="(tab=='MyCards')?'active black button':'black button'" 
                      (keyup)="tabKey($event,'MyCards')"
                      (click)="tabClick('MyCards')">
                        My Cards
              </button>
              <button tabindex="7" [attr.class]="(tab=='BossBattle')?'active black button':'black button'" 
                      (keyup)="tabKey($event,'BossBattle')"
                      (click)="tabClick('BossBattle')">
                        Boss Battle
              </button>
              <button tabindex="8" [attr.class]="(tab=='Leaderboard')?'active black button':'black button'" 
                      (keyup)="tabKey($event,'Leaderboard')"
                      (click)="tabClick('Leaderboard')">
                        Leaderboard
              </button>
              <button tabindex="9" [attr.class]="(tab=='MyHero')?'active black button':'black button'" 
                      (keyup)="tabKey($event,'MyHero')"
                      (click)="tabClick('MyHero')">
                        My Hero
              </button>
              <!-- <button class="black button" (click)="done()">Close</button> -->
          </div>
          <h4 *ngIf="tab=='Welcome'">Welcome To SplinterForge</h4>
          <h4 *ngIf="tab=='Navigation'">Navigation</h4>
          <h4 *ngIf="tab=='Shop'">
            <!-- <img src="/assets/menu/menu-shop.png" class="micro_image"> -->
            Shop
          </h4>
          <h4 *ngIf="tab=='OpenPacks'">
            <!-- <img src="/assets/menu/menu-open.png" class="micro_image"> -->
            The Forge
          </h4>
          <h4 *ngIf="tab=='MyCards'">
            <!-- <img src="/assets/menu/menu-profile.png" class="micro_image"> -->
            My Cards
          </h4>
          <h4 *ngIf="tab=='BossBattle'">
            <!-- <img src="/assets/menu/menu-boss.png" class="micro_image"> -->
            Boss Battles
          </h4>
          <h4 *ngIf="tab=='Leaderboard'">
            <img src="/assets/menu/menu-leaders.png" class="micro_image">
            Leaderboard
          </h4>
          <h4 *ngIf="tab=='MyHero'">
            <!-- <img src="/assets/heroes/warrior_sketch.png" class="micro_image"> -->
            My Hero
          </h4>
          <h4 *ngIf="tab=='Help'">Help</h4>
        </div>
        <div *ngIf="tab=='Welcome'" id="Welcome" class="tabcontent" style="display: block;">
          <div>
            <div>
              <img src="/assets/splinter-forge-logo-finalx400-min.png" class="splinter-forge-logo" />
              <p>
                SplinterForge is a Boss fight game that lets you use your <span>Splinterlands Cards</span> in a whole new way.
              </p>
              <p class="nav_subtitle">
                Full User Guide available here: <a target="_blank" href="https://splinterforge.gitbook.io/splinterforge-user-guide/">SplinterForge User Guid</a>
              </p>
              <div>
                <div class="top"></div>
                <div class="bottom"></div>
                <div class="_bg"></div>
                <b>Game Highlights:</b>
              </div>
              <ul>
                <li>You are the Hero that leads your team of summoners and monsters into battle!</li>
                <li>Use your collection of Splinterlands cards to form an unstoppable team!</li>
                <li>Fight Bosses with varying abilities! Collect powerful equipment, magical gems and runes to upgrade your heroes!</li>
                <li>Get Forgium (FORGE) for fighting bosses and finishing on the leaderboard when the boss finally falls!</li>
                <li>Earn XP and Electrum to advance your hero!</li>
              </ul>
            </div>
          </div>
        </div>
        <div *ngIf="tab=='Navigation'" id="Navigation" class="tabcontent">
          <div>
            <div>
              <p class="nav_subtitle">Quick rundown of what each menu link is for:</p>
              <table class="nav_table">
                <tr>
                  <td width="50" class="table_icons">
                    <img src="/assets/menu/menu-shop.png">
                  </td>
                  <td width="50" class="nav_img_title">Shop</td>
                  <td class="nav_desc">You can get CRATES, BAGS, stamina potions and any future offerings from SplinterForge here.</td>
                </tr>
                <tr>
                  <td width="50" class="table_icons">
                    <img src="/assets/menu/menu-open.png">
                  </td>
                  <td width="50" class="nav_img_title">Forge</td>
                  <td class="nav_desc">You can have your CRATES opened or the items in your BAGS identified (ie. Open Packs), as well as transmute and reforge items into higher rarity here.
                </tr>
                <tr>
                  <td width="50" class="table_icons">
                    <div class="nav_icon">
                      <img src="/assets/menu/menu-profile.png">
                    </div>
                  </td>
                  <td width="50" class="nav_img_title">Cards</td>
                  <td class="nav_desc">You can see your SplinterForge collection and select cards to Combine/Socket/Burn/ETC... here.</td>
                </tr>
                <tr>
                  <td width="50" class="table_icons">
                    <img src="/assets/menu/menu-boss.png">
                  </td>
                  <td width="50" class="nav_img_title">Battle</td>
                  <td class="nav_desc">You go here when you want to start fighting bosses for rewards!</td>
                </tr>
                <tr>
                  <td width="50" class="table_icons">
                    <img src="/assets/menu/menu-leaders.png">
                  </td>
                  <td width="50" class="nav_img_title">Leaderboard</td>
                  <td class="nav_desc">You go here to see who the most fierce Heroes are, how you rank.</td>
                </tr>
                <tr>
                  <td width="50" class="table_icons">
                    <img src="/assets/heroes/warrior_sketch.png">
                  </td>
                  <td width="50" class="nav_img_title">Hero</td>
                  <td class="nav_desc">You go here to equip your Hero with SplinterForge cards and watch your stats/abilities grow.</td>
                </tr>
                <tr>
                  <td width="50" class="table_icons">
                    <img src="https://splinterforge.s3.us-east-2.amazonaws.com/tokens/forgium.png">
                  </td>
                  <td width="50" class="nav_img_title">Forgium</td>
                  <td class="nav_desc">This is your current balance of FORGE, click here to transfer FORGE.</td>
                </tr>
                <tr>
                  <td width="50" class="table_icons">
                    <img src="https://splinterforge.s3.us-east-2.amazonaws.com/shop/Stamina+Potion.png">
                  </td>
                  <td width="50" class="nav_img_title">Stamina</td>
                  <td class="nav_desc">This is your current/max stamina. 30 Stamina is used per battle. Stamina regen is 1 per minute.</td>
                </tr>
                <tr>
                  <td width="50" class="table_icons">
                    <img src="/assets/menu/menu-profile.png">
                  </td>
                  <td width="50" class="nav_img_title">Profile</td>
                  <td class="nav_desc">You can see your inventory, stake for XP, claim airdrops, and FAQ on the profile page.</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div *ngIf="tab=='Shop'" id="Shop" class="tabcontent">
          <div>
            <img src="/assets/menu/menu-shop.png" class="macro_image">
            <div>
              <p>
                The shop is where you can get new <span>CRATES / BAGS</span> to help make your <span>Heroes</span> the best they can be.
              </p>
              <ul>
                <li>Primary currency is Forgium (FORGE).</li>
                <li>CRATE and BAG are Hive-Engine/Tribaldex tokens that can be moved to and from SplinterForge.</li>
                <li>Stamina potions available for Forgium only.</li>
                <li>Any new items will be available on this page.</li>
                <li>CRATES and BAGS can be opened on Forge page.</li>
              </ul>
            </div>
          </div>
        </div>
        <div *ngIf="tab=='OpenPacks'" id="OpenPacks" class="tabcontent">
          <div>
            <img src="/assets/menu/menu-open.png" class="macro_image">
            <div>
                <p>
                  The is where you can have your Equipment CRATES opened or the Gems / Runes in your BAGS identified.
                </p>
                <ul>
                  <li>History of opened/identified CRATES/BAGS.</li>
                  <li>Open up to 200 at once.</li>
                  <li><span>Reforge</span> (for equipment) tab: Burn your lower rarity equipment to get a piece of random higher rarity equipment.</li>
                  <li><span>Transmute</span> (for gems/runes) tab: Burn your lower rarity gems/runes to get a random higher rarity gem/rune.</li>
                </ul>
            </div>
          </div>
        </div>
        <div *ngIf="tab=='MyCards'" id="MyCards" class="tabcontent">
          <div>
            <img src="/assets/menu/menu-profile.png" class="macro_image">
            <div>
                <p>This is where you view your SplinterForge card collection.</p>
                <ul>
                  <li>View card stats.</li>
                  <li>When more than one of the same card is present use the round checkmark to manipulate the card you want. Level-up equipment by combining the same items.</li>
                  <li>Add sockets to equipment using Electrum. </li>
                  <li>Electrum is a ingame currency only acquired through battling. </li>
                  <li>Max sockets are: 1 for common, 2 for rare, 3 for epic, 4 for legendary.</li>
                  <li>Epic and legendary come out of packs with 1 sockets, common and rare start with 0.</li>
                  <li>Add runes/gems to sockets in equipment.</li>
                  <li>Transfer cards to other players.</li>
                  <li>Burn cards for Forgium or Electrum.</li>
                  <li>Lore (future update).</li>
                </ul>
            </div>
          </div>
        </div>
        <div *ngIf="tab=='BossBattle'" id="BossBattle" class="tabcontent">
          <div>
            <img src="/assets/menu/menu-boss.png" class="macro_image">
            <div>
                <p>Boss Battles Pick a Hero type to use for battle after selecting a boss to fight (Warrior/Wizard/Ranger). </p>
                <ul>
                  <li>
                    Select a boss to fight. Pick one of four tiers (Bronze/Silver/Gold/Diamond).
                    <img src="https://d36mxiodymuqjm.cloudfront.net/website/icons/leagues/league_3.png" 
                         title="Bronze League" loading="lazy" class="league_icon">&nbsp;
                    <img src="https://d36mxiodymuqjm.cloudfront.net/website/icons/leagues/league_6.png" 
                         title="Silver League" loading="lazy" class="league_icon">&nbsp;
                    <img src="https://d36mxiodymuqjm.cloudfront.net/website/icons/leagues/league_9.png" 
                         title="Gold League" loading="lazy" class="league_icon">&nbsp;
                    <img src="https://d36mxiodymuqjm.cloudfront.net/website/icons/leagues/league_12.png" 
                         title="Diamond League" loading="lazy" class="league_icon">
                  </li>
                  <li>Pick a Hero type to use for battle after selecting a boss to fight (Warrior/Wizard/Ranger).</li>
                  <li>Monster level limit set by tier, not summoner level.</li>
                  <li>Use <span>1+</span> Monsters (above 7 only with bonuses from SplinterForge equipment on Hero).</li>
                  <li>Monster and summoner positions don't matter.</li>
                  <li>1+ mana (above 45 only with bonuses from SplinterForge equipment on Hero).</li>
                  <li>1 point of stamina used for each mana used to select team.</li>
                </ul>
            </div>
          </div>
        </div>
        <div *ngIf="tab=='Leaderboard'" id="Leaderboard" class="tabcontent">
          <div class="leaderboard_tab">
            <button *ngFor="let b of bosses; let i = index;" 
              [attr.class]="(leaderboard_nav==b)?'active black button':'black button'" 
              (click)="leaderboard_nav=b;">
              {{b.name}}
            </button>
          </div>
          <leaderboard [boss]="leaderboard_nav" [in_modal]="true" [limit]="11"></leaderboard>
        </div>
        <div *ngIf="tab=='MyHero'" id="MyHero" class="tabcontent">
          <div>
            <img src="/assets/heroes/warrior_sketch.png" class="macro_image">
            <div>
              <p><b>Hero Highlights</b></p>
              <p>
                You are the Hero that leads your team of summoners and monsters into battle! 
              </p>
              <ul>
                <li>Switch between hero loadouts with the dropdown menu under your name. </li>
                <li>Weapon and offhand are hero exclusive (ie. Mages use wands and books, Rangers use bows and quivers, Warriors use axes and shields), the rest of the equipment can be used by everyone and can be switched around.</li>
                <li>Equipment with multiple sockets can only have 1 type of stat gem(ie health, attack, magic, speed) at a time. </li>
                <li>“Force” Runes let you use splinters that are not a part of your teams summoners. (eg You pick Tarsa and Kelya Frendul, and with a Earth Force you can pick earth splinter monsters) making it possible to use extremely fun combo’s.</li>
              </ul>
            </div>
          </div>
        </div>
        <div *ngIf="tab=='Help'">
          <h6>This info is always available by pressing the 🛈 button to the right of your profile pack at the top right of the page.</h6>
        </div>
      </div>
    </div>
  </section>