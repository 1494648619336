<div class="notificationbox" [@filters_show]="show_notifications">
  <div class="popupbox">
      <div (click)="close()" class="close_bar">
        <span>Close</span>
        <i class="close_window fa-solid fa-chevron-up"></i>  
      </div>
      <div class="messages">
        <div class="popup" *ngFor="let notification of notifications">
          <p (click)="app.go(notification)">
            <i class="fa-regular fa-message"></i> {{notification.message}}
            <i class="fa-solid fa-x float_right" (click)="app.ack(notification)"></i>
          </p>
        </div>
      </div>
  </div>
</div>