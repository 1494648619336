import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HelperService } from '../../_services/helper.service';
import { fade, flip_up, pointer, boxlarge, boxsmaller, field_error } from '../../app.animations';
@Component({
  selector: 'rezModal',
  animations: [fade, flip_up, pointer, boxlarge, boxsmaller, field_error],
  templateUrl: './rezModal.component.html',
  styleUrls: [ './rezModal.component.less', '../survival.component.styl']
})
export class RezModalComponent implements OnInit {
  
  @Input('show') show = true;
  @Input('dead') dead = [];
  @Input('credits') credits: number = 0;
  @Input('resurrected') resurrected;
  @Output('done') done = new EventEmitter();
  @Output('rezMonster') rezMonster = new EventEmitter();
  
  constructor (public helper: HelperService) { }
  
  ngOnInit() {}

  rez(target) {
    this.rezMonster.emit(target);
  }
  
  close() { 
    this.done.emit(0);
  }
}