<div class="main_wrapper" (click)="page_clicked($event)">
  <div class="header">
    <app-header
      [currentUser]="currentUser"
      [notifications]="notification.length"
      (openLogin)="openLoginModal()"
      (move_sc)="moveSC=$event"
      (requested)="requested=$event"
      (show_notifs)="showNotifs=$event"
      (use_stamina)="useStamina=$event"
      (show_donate)="showDonate=$event"
    ></app-header>
  </div> 
  <router-outlet></router-outlet>
  <login-modal [show]="loginModal" (done)="login_done($event);"></login-modal>
  <hive-welcome-modal [show]="noKeychainModal" (done)="done_noKeychainModal($event)"></hive-welcome-modal>
  <section *ngIf="notification && notification.length > 0">
    <app-notification 
      [show]="showNotifs"
      (done)="showNotifs=false"
      [notifications]="notification">
    </app-notification>
  </section>
  <welcome-modal [show]="requested" (done)="requested=false"></welcome-modal>
  <forgium [show]="moveSC" [currentUser]="currentUser" (done)="moveSC=false"></forgium>
  <stamina-elixirs
    [show]="useStamina"
    (done)="useStamina=false">
  </stamina-elixirs>
  <donate [show]="showDonate" [currentUser]="currentUser" (done)="showDonate=false"></donate>
  <success-modal
    [color]="'green'"
    [show]="show_modal"
    [label]="modal_desc"
    [title]="modal_title"
    (done)="hideModal()">
  </success-modal>
  <div id="snackbar" (click)="hide_snackbar()" [innerHTML]="snackbarMessage"></div>
  <div class="push"></div>
</div>
<div class="footer">
  <app-footer></app-footer>
</div>
<div class="img_preloader">
  <img src="https://splinterforge.s3.us-east-2.amazonaws.com/frame_art/gf_frame2.png" class="frame" loading="lazy">
  <img src="https://splinterforge.s3.us-east-2.amazonaws.com/frame_art/alpha_frame.png" class="frame" loading="lazy">
</div>