<section class="forgemodal" [@fade]="show">
    <div class="boxwrap" [@flip_up]="show">
        <div class="topwrap">
            <h1> Donate</h1>
            <h1 class="yellow">Donate to SplinterForge.io</h1>
            <div class="shopItem">
	            <div class="imagewrapper">
	                <img src="/assets/menu/menu-shop.png">
	            </div>

	            <div class="donation_amount" *ngIf="assets">
	            	<span>Donate your Splinterlands assets to the Splinterlands account name: <b>splinterforge</b></span>
	            </div>
		        
		        <div class="donation_amount" *ngIf="!assets">
		          <!-- <span class="donation_prefix">Amount: </span> -->
		          <span class="currency"> {{donor.currency}}</span>
		          <input  class="currency_amount" type="number" min="1" rows="2" placeholder="100" [(ngModel)]="donor.amount" />
		          <select class="currency_selector" [(ngModel)]="donor.currency">
		        		<option value='SPS'>SPS</option>
		        		<option value='DEC'>DEC</option>
		        		<option value='SC'>FORGE</option>
		        	</select>
		        </div>

		        <!-- <div>
		          <textarea [@SlideDonationDown]="donor.amount>0" class="memo" type="text" placeholder="Leave a memo (optional)" [(ngModel)]="donor.memo"></textarea>
		        </div> -->
		        <div class="donation_buttons">

		        	<div class="currency_buttons">
			        	<!--
			        		<button (click)="assets=assets?false:true" 
			        				class="orange button">
			        			Donate {{assets?'Currency':'Assets'}}
			        		</button> 
			        	-->
			        	<button *ngIf="!assets" class="blue button float_right" (click)="donate(donor.currency)">
							Donate {{donor.amount|currency:donor.currency}} to SplinterForge
		            	</button>
		            </div>

		          <!-- <div class="currency_buttons">
		            <div class="orange button currency_button" (click)="donor.currency='USD'">
		              <i *ngIf="donor.currency!='USD'" class="fa-regular fa-square"></i>
		              <i *ngIf="donor.currency=='USD'" class="fa-regular fa-square-check"></i>
		              <span class="currency_label" for="dec_currency">USD</span>
		            </div>
		            <div class="orange button currency_button" (click)="donor.currency='DEC'">
		              <i *ngIf="donor.currency!='DEC'" class="fa-regular fa-square"></i>
		              <i *ngIf="donor.currency=='DEC'" class="fa-regular fa-square-check"></i>
		              <span class="currency_label" for="dec_currency">DEC</span>
		            </div>
		            <div class="orange button currency_button last" (click)="donor.currency='SPS'">
		              <i *ngIf="donor.currency!='SPS'" class="fa-regular fa-square"></i>
		              <i *ngIf="donor.currency=='SPS'" class="fa-regular fa-square-check"></i>
		              <span class="currency_label" for="sps_currency">SPS</span>
		            </div>
		            <div class="clear"></div>
		          </div> -->
		          
		          <!-- <div class="donate_button">
		            <button class="orange button" (click)="donate(donor.currency)">
		              Donate {{donor.currency}} to SplinterForge
		            </button>
		          </div> -->
		        </div>
	      	</div>
        </div>
        <div class="closewrapper" (click)="close()">
            <i class="fa-solid fa-x"></i>
        </div>
    </div>
    <div class="backdrop" [@pointer]="show" (click)="close()"></div>
</section>