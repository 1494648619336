<div *ngIf="!uniqueCards" class="forge_background_overlay"></div>
<div *ngIf="uniqueCards" class="forge_background_overlay darker"></div>
<div class="page_heading">
    <h1>Forge</h1>
    <div class="tab_bar tab_buttons" *ngIf="!uniqueCards">
        <button *ngIf="1>0" [attr.class]="show=='relic'?'tablinks active':'tablinks'" (click)="clearFilters();show='relic';">
            Relics
        </button>
        <button [attr.class]="show=='transmute'?'tablinks active':'tablinks'" (click)=" clearFilters();show='transmute';selectAvailableRarity(1, 'socket');">
            Transmute
        </button>
        <button [attr.class]="show=='reforge'?'tablinks active':'tablinks'" (click)=" clearFilters();show='reforge';selectAvailableRarity(1, 'equipment');">
            Reforge
        </button>
        <button [attr.class]="show=='open'?'tablinks active':'tablinks'" (click)=" clearFilters();show='open'">
            Open Packs
        </button>
    </div>
   <div class="tab_bar tab_buttons" *ngIf="uniqueCards">
        <button [disabled]="loading" class="tablinks red" (click)="closeNewCardView()">
            <i class="fa-solid fa-chevron-left"></i>
            Back 
        </button>
        <button class="tablinks" (click)="helper.navigate('cards')">
            View Cards
        </button>
        <button class="tablinks" (click)="revealAll()">
            Reveal All
        </button>
    </div>
    <div class="bottom_border"></div>
    <div class="clear"></div>
</div> 
<div *ngIf="!uniqueCards" class="selectedcardbox inner inner_container">
    <div class="category_holder packs" *ngIf="show=='open'">
        <div class="category_container">
            <div [attr.class]="category_hidden[0]?'titlebox margin':'titlebox margin'" 
                 (click)="category_hidden[0]=category_hidden[0]?false:true">
                <div class="border_gradient bottom"></div>
                <i *ngIf="!category_hidden[0]" class="fa-solid fa-chevron-down"></i>
                <i *ngIf="category_hidden[0]" cladss="fa-solid fa-chevron-up"></i>
                <h3>Packs 
                    <span class="sm_right">({{pack_types.length}})</span>
                </h3>
            </div>
            <div [@shop_category]="!category_hidden[0]" class="itemwrapper elixirs">
                <div class="shop_items large" *ngFor="let type of pack_types; let i = index">
                    <div [@shop_category]="!category_hidden[0]" class="itemwrapper">
                        <div class="shop_item {{type}}">
                            <!-- <div class="rock_bg" *ngIf="type=='Alpha Pack'"></div> -->
                            <div class="shopItem">
                                <div class="image_wrapper">
                                    <div class="imagewrapper">
                                        <img *ngIf="(type === 'Alpha Pack')" src="https://splinterforge.s3.us-east-2.amazonaws.com/packs/crate.png" loading="lazy">
                                        <img *ngIf="(type === 'Enhancement Pack')" src="https://splinterforge.s3.us-east-2.amazonaws.com/packs/tempbag.png" loading="lazy">
                                    </div>
                                </div>
                                <div clas="item_info">
                                    <div class="title">
                                        {{(type === 'Alpha Pack' ? 'CRATE' : 'BAG')}}
                                        <div class="border_bottom_first"></div>
                                    </div>
                                    <div class="pack_counts">
                                        <div class="player_owned">
                                            <span class="span_label">
                                                {{pack_balances[type]==0?'No ':''}}Owned
                                            </span>
                                            <!-- <i class="fa-solid fa-crown"></i> -->
                                            <span class="span_number">{{pack_balances[type]|number}}</span>
                                        </div>
                                    </div>
                                    <div class="textwrap">
                                        <div class="caption buy">
                                            <input class="buy_pack_quantity" type="number" 
                                                min="1" step="1" [attr.value]="1" 
                                                [attr.max]="pack_balances[type]"
                                                [(ngModel)]="select_packs[type]" />
                                            <div [attr.class]="(pack_balances[type]>0?'':'disabled ')+'green add_to_cart button'" (click)="openPacks(type)">
                                                Open {{select_packs[type]?select_packs[type]:''}} {{type==='Alpha Pack'?'CRATE':'BAG'}}{{select_packs[type]!=1?'s':''}}
                                            </div>
                                            <div [attr.class]="'transfer_packs button send_to_hive'" (click)="openTransfer(type)">
                                                Transfer {{type==='Alpha Pack'?'CRATE':'BAG'}}{{select_packs[type]!=1?'s':''}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="clear"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="category_holder history" *ngIf="show=='open'">
        <div class="category_container" *ngIf="user&&user.openings">
            <div [attr.class]="category_hidden[1]?'titlebox margin':'titlebox margin'" 
                 (click)="category_hidden[1]=category_hidden[1]?false:true">
                <div class="border_gradient bottom"></div>
                <i *ngIf="user.openings.length>0&&!category_hidden[1]" class="fa-solid fa-chevron-down"></i>
                <i *ngIf="user.openings.length>0&&category_hidden[1]" class="fa-solid fa-chevron-up"></i>
                <h3>History 
                    <span class="sm_right" *ngIf="user.openings.length>0">
                        ({{user.openings.length}})
                    </span> 
                </h3>
            </div>
            <div [@shop_category]="!category_hidden[1]" class="itemwrapper elixirs">
                <div *ngFor="let hist of user.openings; let i = index" class="hist_openings">
                    <span *ngIf="!hist.viewed" class="newly_added" title="Newly Added">
                        <span class="qtybadge">
                            <span>New</span>
                        </span>
                    </span>
                    <div [attr.class]="hist.viewed?'shop_items':'new shop_items'" [@shop_category]="!category_hidden[1]" class="itemwrapper">
                        <div class="shop_item">
                            <div class="rock_bg"></div>
                            <div class="shopItem">
                                <div class="image_wrapper" (click)="getOpening(hist)">
                                    <div class="imagewrapper">
                                        <img *ngIf="(hist.symbol === 'CRATE')" src="https://splinterforge.s3.us-east-2.amazonaws.com/packs/crate.png" loading="lazy">
                                        <img *ngIf="(hist.symbol === 'BAG')" src="https://splinterforge.s3.us-east-2.amazonaws.com/packs/tempbag.png" loading="lazy">
                                    </div>
                                </div>
                                <div class="details">
                                    <div class="title" (click)="getOpening(hist)">{{hist.symbol}}</div>
                                    <!-- <p>Quantity: {{hist.qty}}</p> -->
                                    <div class="caption buy">
                                        <div [disabled]="loading" class="translusent add_to_cart button" (click)="getOpening(hist)">
                                            View {{hist.qty}} {{hist.symbol}}
                                        </div>
                                    </div>
                                </div>
                                <div class="clear"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="user.openings.length == 0">
                    <div class="cardwrap">
                        <div class="cards no_cards">
                            <h1>No Openings</h1>
                            <h3>You have not opened anything yet.</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="category_holder reforge" *ngIf="show=='reforge'">
        <div class="category_container">
            <div [attr.class]="category_hidden[2]?'titlebox margin':'titlebox margin'" 
                 (click)="category_hidden[2]=category_hidden[2]?false:true">
                <div class="border_gradient bottom"></div>
                <i *ngIf="!category_hidden[2]" class="fa-solid fa-chevron-down"></i>
                <i *ngIf="category_hidden[2]" class="fa-solid fa-chevron-up"></i>
                <h3>Re-Forge</h3>
            </div>
            <div *ngIf="user" [@shop_category]="!category_hidden[2]" class="itemwrapper elixirs reforge_container">
                <div class="reforge_cards">
                    <div [attr.class]="nav_open?'tab open':'tab'">
                        <button [disabled]="loading" [attr.class]="(rarity==1)?'active black button':'black button'" 
                              (click)="selectAvailableRarity(1, 'equipment')">
                                Common
                        </button>
                        <button [disabled]="loading" [attr.class]="(rarity==2)?'active black button':'black button'" 
                              (click)="selectAvailableRarity(2, 'equipment')">
                                Rare
                        </button>
                        <button [disabled]="loading" [attr.class]="(rarity==3)?'active black button':'black button'" 
                              (click)="selectAvailableRarity(3, 'equipment')">
                                Epic
                        </button>
                        <button [disabled]="loading" [attr.class]="(rarity==4)?'active black button':'black button'" 
                              (click)="selectAvailableRarity(4, 'equipment')">
                                Legendary
                        </button>
                    </div>
                    <!-- <h5>Select card rarity you would like to reforge</h5> -->
                    <div class="filters">
                        <div class="filter_control equipped_toggle include_leveled_cards" 
                            (click)="includeLeveled = !includeLeveled;autoSelect();">
                          <span class="filter_title equipped_filter">
                            <span>Include Leveled Cards</span>
                            <div class="icon">
                              <i *ngIf="includeLeveled" class="fa-solid ck fa-check"></i>
                              <i class="fa-regular fa-square-full"></i>
                            </div>
                          </span>
                        </div>
                        <div class="filter_control equipped_toggle autoselect_to_reforge">
                          <span class="filter_title equipped_filter">
                            <span>
                                Auto-select to reforge:
                                <span class="max_amount" [@scale_pop]="flash_max">(Max {{max_possible}})</span>
                            </span>
                            <div class="icon">
                                <input (keyup)="autoSelect()" (change)="autoSelect()" [(ngModel)]="amount" placeholder="0" type="number" min="0" [attr.max]="max_possible" />
                                <div class="indicators">
                                    <i class="fa-solid fa-caret-up up" (click)="add_to_amount()"></i>
                                    <i class="fa-solid fa-caret-down down" (click)="subtract_from_amount()"></i>
                                </div>
                            </div>
                          </span>
                        </div>
                        <div class="clear"></div>
                    </div>
                </div>
                <div *ngIf="available[0]" class="reforgable_trade">
                    <h5>
                        <i class="fa-solid fa-circle-info info-circle"></i>
                        <span *ngIf="available[0].rarity === 1">
                            You can re-forge <b>4 Commons</b> into a random <b>Rare</b> for <b>150 FORGE</b>.
                        </span>
                        <span *ngIf="available[0].rarity === 2">
                            You can re-forge <b>6 Rares</b> into a random <b>Epic</b> for <b>500 FORGE</b>.
                        </span>
                        <span *ngIf="available[0].rarity === 3">
                            You can re-forge <b>8 Epics</b> into a random <b>Legendary</b> for <b>2,500 FORGE</b>.
                        </span>
                        <span *ngIf="available[0].rarity === 4">
                            You can re-forge <b>2 Legendaries</b> into a random <b>Legendary</b> for <b>15,000 FORGE</b>
                        </span>
                    </h5>
                    <h5>
                        <i class="fa-solid fa-circle-info info-circle"></i>
                        <span *ngIf="available[0].rarity !== 4">
                            Cards recieved when reforging have a <b>10%</b> chance to be a Gold Foil (adds to FORGE earned in battle, 8%/12%/16%).
                        </span>
                        <span *ngIf="available[0].rarity === 4">
                            Chance to receive Nartors set items, each Nartors piece has a <b>10%</b> chance to be a Gold Foil +16% FORGE in battles.
                        </span>
                    </h5>
                </div>
                <div *ngIf="available && available.length > 0" class="cardgroup">
                    <section [@overflow]="show_filters" [attr.class]="show_filters?'filters show':'filters'">
                        <div class="secondary_line">
                          <div class="leaderboardbuttons filter_control search_term">
                            <span class="filter_title">Search:</span>
                            <div class="filter_selector">
                               <input class="filter_string" type="text" placeholder="Filter" [(ngModel)]="filters.search" />
                            </div>
                          </div>
                        </div>
                        <span *ngIf="filters.ability==='All'&&filters.type==='All'&&filters.search===''&&!filters.sockets&&filters.rarity==='All'&&filters.slot==='All'&&!filters.equipped" 
                              class="no_filters_container"
                              (click)="clearFilters()">
                            <span>
                              No Filters
                            </span>
                        </span> 
                        <span *ngIf="filters.ability !== 'All'||filters.type!=='All'||filters.sockets||filters.search!==''||filters.rarity!=='All'||filters.slot!=='All'||filters.equipped" class="no_filters_container" (click)="clearFilters()">
                            <span>Clear Filters</span>
                        </span>
                      <div class="clear"></div>
                    </section>
                    <div class="cards_available">
                        Available Cards: 
                        <b>{{(available | type:filters.type | name:filters.search).length|number}}</b>
                        <div class="right">
                            <span class="show_more_info" (click)="toggle_info_window();">
                                <i *ngIf="card_details=='1'" class="fa-solid fa-check"></i>&nbsp;
                                Card Details
                            </span>
                        </div>
                    </div>
                    <div *ngIf="!showAvailableCards" class="cards_available_spacer"></div>
                    <div [@carddropdown]="!showAvailableCards" [attr.class]="available.length==1?'reforgable single':available.length==2?'reforgable double':'reforgable'">
                        <div *ngFor="let c of available | type:filters.type | name:filters.search | slot:filters.slot; let i = index" class="reforgable_item" (mouseenter)="showAvailable=c" (mouseleave)="hide_available()">
                            <div class="card_reforge" (click)="selectToReforge(c)">
                                <span class="reforgable_selector">
                                    <i *ngIf="c.checked" class="fa-solid ck fa-check"></i>
                                    <i class="fa-regular fa-square-full"></i>
                                </span>
                                <span>{{c.name}} - {{c.combined + ' BCX'}}</span>
                                <span class="only_full">{{c.stat}}&nbsp;<i class="fa-solid fa-star"></i>&nbsp;{{c.level}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="reforge_controls">
                        <div *ngIf="available[0].rarity > 0" class="reforgable_show">
                            <h3>
                                <div class="label" *ngIf="available[0].rarity === 1">Commons</div>
                                <div class="label" *ngIf="available[0].rarity === 2">Rares</div>
                                <div class="label" *ngIf="available[0].rarity === 3">Epics</div>
                                <div class="label" *ngIf="available[0].rarity === 4">Legendaries</div>
                                <span class="label_max">{{reforge_count}} / {{reforge_total}}</span>
                            </h3>
                        </div>
                        <button [disabled]="loading" 
                                class="button orange reforge_cards_button" 
                                *ngIf="number_of_reforged_cards && number_of_reforged_cards.count > 0" 
                                (click)="confirm_reforge()"
                                title="All FORGE spent reforging will be BURNT!">
                            <i class="fa-solid fa-fire"></i> Reforge <i class="fa-solid fa-fire"></i>
                        </button>
                    </div>
                </div>
            </div> 
        </div>
    </div>
    <div class="category_holder history" *ngIf="show=='reforge'">
        <div class="category_container" *ngIf="reforgeHistory">
            <div [attr.class]="category_hidden[4]?'titlebox margin':'titlebox margin'" 
                 (click)="category_hidden[4]=category_hidden[4]?false:true">
                <div class="border_gradient bottom"></div>
                <i *ngIf="reforgeHistory&&!category_hidden[4]" class="fa-solid fa-chevron-down"></i>
                <i *ngIf="reforgeHistory&&category_hidden[4]" class="fa-solid fa-chevron-up"></i>
                <h3>History 
                    <span class="sm_right" *ngIf="reforgeHistory.length>0">
                        ({{reforgeHistory.length}})
                    </span> 
                </h3>
            </div>
            <div [@shop_category]="!category_hidden[4]" class="itemwrapper elixirs">
                <div *ngFor="let hist of reforgeHistory; let i = index" class="hist_openings">
                    <span *ngIf="!hist.viewed" class="newly_added" title="Newly Added">
                        <span class="qtybadge">
                            <span>New</span>
                        </span>
                    </span>
                    <div [attr.class]="hist.viewed?'shop_items':'new shop_items'" [@shop_category]="!category_hidden[4]" class="itemwrapper">
                        <div class="shop_item">
                            <div class="rock_bg"></div>
                            <div class="shopItem">
                                <div class="image_wrapper">
                                    <div class="imagewrapper">
                                        <img *ngIf="(hist.symbol === 'reforge')" src="https://splinterforge.s3.us-east-2.amazonaws.com/packs/crate.png" loading="lazy">
                                        <img *ngIf="(hist.symbol === 'transmute')" src="https://splinterforge.s3.us-east-2.amazonaws.com/packs/tempbag.png" loading="lazy">
                                    </div>
                                </div>
                                <div class="details">
                                    <div class="title">{{hist.symbol}}</div>
                                    <!-- <p>Quantity: {{hist.qty}}</p> -->
                                    <div class="caption buy">
                                        <div [disabled]="loading" class="orange button" (click)="getOpening(hist)">
                                            {{hist.qty}} card {{hist.symbol}}
                                        </div>
                                    </div>
                                </div>
                                <div class="clear"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="reforgeHistory.length == 0">
                    <div class="cardwrap">
                        <div class="cards no_cards">
                            <h1>No History</h1>
                            <h3>You have not reforged anything yet.</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="category_holder transmute" *ngIf="show=='transmute'">
        <div class="category_container">
            <div [attr.class]="category_hidden[3]?'titlebox margin':'titlebox margin'" 
                 (click)="category_hidden[3]=category_hidden[3]?false:true">
                <div class="border_gradient bottom"></div>
                <i *ngIf="!category_hidden[3]" class="fa-solid fa-chevron-down"></i>
                <i *ngIf="category_hidden[3]" class="fa-solid fa-chevron-up"></i>
                <h3>Transmute</h3>
            </div>
            <div *ngIf="user" [@shop_category]="!category_hidden[3]" class="itemwrapper elixirs reforge_container">
                <div class="reforge_cards">
                    <!-- <h5>Select card rarity you would like to reforge</h5> -->
                    <div [attr.class]="nav_open?'tab open':'tab'">
                        <button [disabled]="loading" [attr.class]="(rarity==1)?'active black button':'black button'" (click)="selectAvailableRarity(1, 'socket')">Common</button>
                        <button [disabled]="loading" [attr.class]="(rarity==2)?'active black button':'black button'" (click)="selectAvailableRarity(2, 'socket')">Rare</button>
                        <button [disabled]="loading" [attr.class]="(rarity==3)?'active black button':'black button'" (click)="selectAvailableRarity(3, 'socket')">Epic</button>
                        <button [disabled]="loading" [attr.class]="(rarity==4)?'active black button':'black button'" (click)="selectAvailableRarity(4, 'socket')">Legendary</button>
                    </div>
                    <div class="filters">
                        <div class="filter_control equipped_toggle autoselect_to_reforge transmute">
                          <span class="filter_title equipped_filter">
                            <span>
                                <span>Auto-select to reforge: </span>
                                <span class="max_amount" [@scale_pop]="flash_max">(Max {{max_possible}})</span>
                            </span>
                            <div class="icon">
                                <input (change)="autoSelect()" (keyup)="autoSelect()" [(ngModel)]="amount" placeholder="0" type="number" min="0" [attr.max]="max_possible"
                                        (change)="change_amount()" />
                                <div class="indicators">
                                    <i class="fa-solid fa-caret-up up" (click)="add_to_amount()"></i>
                                    <i class="fa-solid fa-caret-down down" (click)="subtract_from_amount()"></i>
                                </div>
                            </div>
                          </span>
                        </div>
                        <div class="clear"></div>
                    </div>
                </div>
                <div *ngIf="available[0]" class="reforgable_trade">
                    <h5>
                        <i class="fa-solid fa-circle-info info-circle"></i>
                        <span *ngIf="available[0].rarity === 1">
                            You can transmute <b>4 Commons</b> into a random <b>Rare</b> for <b>150 FORGE</b>.
                        </span>
                        <span *ngIf="available[0].rarity === 2">
                            You can transmute <b>6 Rares</b> into a random <b>Epic</b> for <b>500 FORGE</b>.
                        </span>
                        <span *ngIf="available[0].rarity === 3">
                            You can transmute <b>8 Epics</b> into a random <b>Legendary</b> for <b>2,500 FORGE</b>.
                        </span>
                        <span *ngIf="available[0].rarity === 4">
                            You can transmute <b>2 Legendaries</b> into a random <b>Legendary</b> for <b>15,000 FORGE</b>.
                        </span>
                    </h5>
                    <h5>
                        <i class="fa-solid fa-circle-info info-circle"></i>
                        <span>
                            Each transmuted item has an equal chance of being a special item (shown below) that is only available through transmute.
                        </span>
                    </h5>
                </div>

                <div *ngIf="available && available.length > 0" class="cardgroup">
                    <section [@overflow]="show_filters" [attr.class]="show_filters?'filters show':'filters'">
                        <div class="secondary_line">
                          <div class="leaderboardbuttons filter_control search_term">
                            <span class="filter_title">Search:</span>
                            <div class="filter_selector">
                               <input class="filter_string" type="text" placeholder="Filter" [(ngModel)]="filters.search" />
                            </div>
                          </div>
                        </div>
                        <span *ngIf="filters.ability==='All'&&filters.type==='All'&&filters.search===''&&!filters.sockets&&filters.rarity==='All'&&filters.slot==='All'&&!filters.equipped" 
                              class="no_filters_container"
                              (click)="clearFilters()">
                            <span>
                              No Filters
                            </span>
                        </span> 
                        <span *ngIf="filters.ability !== 'All'||filters.type!=='All'||filters.sockets||filters.search!==''||filters.rarity!=='All'||filters.slot!=='All'||filters.equipped" 
                                class="no_filters_container "
                                (click)="clearFilters()">
                            <span>
                              Clear Filters
                            </span>
                        </span>
                      <div class="clear"></div>
                    </section>
                    <div class="cards_available">
                        <span>Available Cards: 
                            <b>{{(available | type:filters.type | name:filters.search).length|number}}</b>
                        </span>
                        <div class="right">
                            <span class="show_more_info" (click)="toggle_info_window();">
                                <i *ngIf="card_details=='1'" class="fa-solid fa-check"></i>&nbsp;
                                Card Details
                            </span>
                        </div>
                    </div>
                    <div *ngIf="!showAvailableCards" class="cards_available_spacer"></div>
                    <div [@carddropdown]="!showAvailableCards" [attr.class]="available.length==1?'reforgable single':available.length==2?'reforgable double':'reforgable'">
                        <div *ngFor="let c of available | type:filters.type | name:filters.search; let i = index" class="reforgable_item" (mouseenter)="showAvailable=c" (mouseleave)="hide_available()">
                            <div class="card_reforge" (click)="selectToReforge(c)">
                                <span class="reforgable_selector">
                                    <i *ngIf="c.checked" class="fa-solid ck fa-check"></i>
                                    <i class="fa-regular fa-square-full"></i>
                                </span>
                                <span>{{c.name}} - {{c.combined + ' BCX'}}</span>
                                <span class="only_full">{{c.stat}}&nbsp;<i class="fa-solid fa-star"></i>&nbsp;{{c.level}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="reforge_controls">
                        <div *ngIf="available[0].rarity > 0" class="reforgable_show">
                            <h3>
                                <div class="label" *ngIf="available[0].rarity === 1">Commons</div>
                                <div class="label" *ngIf="available[0].rarity === 2">Rares</div>
                                <div class="label" *ngIf="available[0].rarity === 3">Epics</div>
                                <div class="label" *ngIf="available[0].rarity === 4">Legendaries</div>
                                <span class="label_max">{{reforge_count}} / {{reforge_total}}</span>
                            </h3>
                        </div> 
                        <button [disabled]="loading" 
                                class="button orange reforge_cards_button" 
                                *ngIf="number_of_reforged_cards && number_of_reforged_cards.count > 0" 
                                (click)="confirm_reforge('Transmute')"
                                title="All FORGE spent transmuting will be BURNT!">
                                <i class="fa-solid fa-fire"></i> Transmute <i class="fa-solid fa-fire"></i>
                        </button>
                    </div>
                </div>
            </div> 
        </div>
    </div>
    <div class="category_holder history" *ngIf="show=='transmute'">
        <div class="category_container" *ngIf="bonusCards">
            <div [attr.class]="category_hidden[5]?'titlebox margin':'titlebox margin'" 
                 (click)="category_hidden[5]=category_hidden[5]?false:true">
                <div class="border_gradient bottom"></div>
                <i *ngIf="transmuteHistory&&!category_hidden[5]" class="fa-solid fa-chevron-down"></i>
                <i *ngIf="transmuteHistory&&category_hidden[5]" class="fa-solid fa-chevron-up"></i>
                <h3>Possible Unique Items Available When Transmuting
                    <span class="sm_right" *ngIf="bonusCards.length>0">
                        ({{bonusCards.length}})
                    </span> 
                </h3>
            </div>
            <div [@shop_category]="!category_hidden[5]" class="cardgroup">
                <div *ngFor="let card of bonusCards" class="account-card">
                    <span *ngIf="card.distribution"  class="distqtybadge">
                        <span title="{{card.distribution.rf_cards + ' printed - ' + card.distribution.rf_burnt + ' burnt'}}">
                            <span>{{card.distribution.rf_cards + '/' + promoCardPrintLimits[card.rarity-2]}}</span>
                        </span>
                    </span>
                    <div>
                        <sfcard-selector
                            [sfcard]="card"
                            [smaller]="smaller_size_cards">
                        </sfcard-selector>
                    </div>
                </div>
                <div *ngIf="bonusCards.length == 0">
                    <div class="cardwrap">
                        <div class="cards no_cards">
                            <h1>Unique cards didn't load properly, please try refreshing.</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="category_holder history" *ngIf="show=='transmute'">
        <div class="category_container" *ngIf="transmuteHistory">
            <div [attr.class]="category_hidden[4]?'titlebox margin':'titlebox margin'" 
                 (click)="category_hidden[4]=category_hidden[4]?false:true">
                <div class="border_gradient bottom"></div>
                <i *ngIf="transmuteHistory&&!category_hidden[4]" class="fa-solid fa-chevron-down"></i>
                <i *ngIf="transmuteHistory&&category_hidden[4]" class="fa-solid fa-chevron-up"></i>
                <h3>History 
                    <span class="sm_right" *ngIf="transmuteHistory.length>0">
                        ({{transmuteHistory.length}})
                    </span> 
                </h3>
            </div>
            <div [@shop_category]="!category_hidden[4]" class="itemwrapper elixirs">
                <div *ngFor="let hist of transmuteHistory; let i = index" class="hist_openings">
                    <span *ngIf="!hist.viewed" class="newly_added" title="Newly Added">
                        <span class="qtybadge">
                            <span>New</span>
                        </span>
                    </span>
                    <div [attr.class]="hist.viewed?'shop_items':'new shop_items'" [@shop_category]="!category_hidden[4]" class="itemwrapper">
                        <div class="shop_item">
                            <div class="rock_bg"></div>
                            <div class="shopItem">
                                <div class="image_wrapper">
                                    <div class="imagewrapper">
                                        <img *ngIf="hist.symbol=='reforge'" src="https://splinterforge.s3.us-east-2.amazonaws.com/packs/crate.png" loading="lazy">
                                        <img *ngIf="hist.symbol=='transmute'" src="https://splinterforge.s3.us-east-2.amazonaws.com/packs/tempbag.png" loading="lazy">
                                    </div>
                                </div>
                                <div class="details">
                                    <div class="title">{{hist.symbol}}</div>
                                    <!-- <p>Quantity: {{hist.qty}}</p> -->
                                    <div class="caption buy">
                                        <div [disabled]="loading" class="orange button" (click)="getOpening(hist)">
                                            {{hist.qty}} card {{hist.symbol}}
                                        </div>
                                    </div>
                                </div>
                                <div class="clear"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="reforgeHistory.length == 0">
                    <div class="cardwrap">
                        <div class="cards no_cards">
                            <h1>No History</h1>
                            <h3>You have not transmuted anything yet.</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="category_holder history" *ngIf="show=='relic'">
        <div class="category_container" *ngIf="relicCycle">
            <div [attr.class]="category_hidden[5]?'titlebox margin':'titlebox margin'" 
                 (click)="category_hidden[5]=category_hidden[5]?false:true">
                <div class="border_gradient bottom"></div>
                <i *ngIf="transmuteHistory&&!category_hidden[5]" class="fa-solid fa-chevron-down"></i>
                <i *ngIf="transmuteHistory&&category_hidden[5]" class="fa-solid fa-chevron-up"></i>
                <h3>Relics eligible to be conjured
                    <span class="sm_right" *ngIf="relicCycle.length>0">
                        ({{relicCycle.length}})
                    </span> 
                </h3>
            </div>
            <div [@shop_category]="!category_hidden[5]" class="cardgroup">
                <div *ngFor="let card of relicCycle" class="account-card">
                    <div>
                        <sfcard-selector
                            [sfcard]="card"
                            [smaller]="smaller_size_cards">
                        </sfcard-selector>
                    </div>
                </div>
                <div *ngIf="relicCycle.length == 0">
                    <div class="cardwrap">
                        <div class="cards no_cards">
                            <h1>Unique cards didn't load properly, please try refreshing.</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="category_holder reforge" *ngIf="show=='relic' && newRelic">
        <div class="category_container">
            <div [attr.class]="category_hidden[5]?'titlebox margin':'titlebox margin'" 
                 (click)="category_hidden[5]=category_hidden[5]?false:true">
                <div class="border_gradient bottom"></div>
                <i *ngIf="!category_hidden[5]" class="fa-solid fa-chevron-down"></i>
                <i *ngIf="category_hidden[5]" class="fa-solid fa-chevron-up"></i>
                <h3 *ngIf="newRelic.name">Conjured Relic</h3>
                <h3 *ngIf="!newRelic.name">Conjure Failed</h3>
            </div>
            <div *ngIf="newRelic.name" [@shop_category]="!category_hidden[5]" class="itemwrapper elixirs reforge_container">
                <div class="reforge_cards">
                    <div class="filters">
                        <div style="text-align: left; color: white;">
                            <sfcard-selector
                                [stackView]="false"
                                [sfcard]="newRelic">
                            </sfcard-selector>
                        </div>
                        <div>
                            <button class="button purple" (click)="newRelic = null">Conjure again...</button>
                        </div>
                        <div class="clear"></div>
                    </div>
                </div>
            </div>
            <div *ngIf="!newRelic.name" [@shop_category]="!category_hidden[5]" class="itemwrapper elixirs reforge_container">
                <div class="reforge_cards">
                    <div class="filters">
                        <div style="text-align: left; color: white;">
                            A relic nearly appeared but then disappated into mist, you failed to conjure a relic...<br/>
                            You have lost {{fragsToUse}} Relic Fragments in the process<br/>
                        </div>
                        <div>
                            <button class="button purple" (click)="newRelic = null">Conjure again...</button>
                        </div>
                        <div class="clear"></div>
                    </div>
                </div>
            </div> 
        </div>
    </div>
    <div class="category_holder reforge" *ngIf="show=='relic' && !newRelic">
        <div class="category_container">
            <div [attr.class]="category_hidden[5]?'titlebox margin':'titlebox margin'" 
                 (click)="category_hidden[5]=category_hidden[5]?false:true">
                <div class="border_gradient bottom"></div>
                <i *ngIf="!category_hidden[5]" class="fa-solid fa-chevron-down"></i>
                <i *ngIf="category_hidden[5]" class="fa-solid fa-chevron-up"></i>
                <h3>Conjure Relic</h3>
            </div>
            <div *ngIf="user" [@shop_category]="!category_hidden[5]" class="itemwrapper elixirs reforge_container">
                <div class="reforge_cards">
                    <div class="filters">
                        <div style="text-align: left; color: white;">
                            Use your Relic Fragments to conjure limited use, soulbound Relics to boost your heroes.<br/>
                            Each Relic created will have a limited number of charges. <br/>
                            Each battle a hero participates in while wearing a Relic will use 1 charge from that Relic.<br/>
                            Relics may be recharged by sacrificing Gems or Runes to them.<br/>
                            Each time a relic is recharged, it's max charges decreases.<br/>
                            Relics may be Salvaged (BURNT) for Relic Fragments.<br/>
                            Failed conjures will result in the loss of burnt FORGE and Relic Frags used.<br/>
                            More details can be found in the <a href="https://splinterforge.gitbook.io/splinterforge-user-guide/gameplay/equipment-gems-and-runes/relics" target="_blank">Hero gitbook</a>.<br/><br/>
                        </div>
                        <div class="filter_control equipped_toggle autoselect_to_reforge">
                          <span class="filter_title equipped_filter">
                            <span>
                                Relic Framents to use (min 100, max 1000):
                                <span class="max_amount" [@scale_pop]="flash_max">(Owned {{user.frags}})</span>
                            </span>
                            <div class="icon">
                                <input (keyup)="changeFragsUsed()" (change)="changeFragsUsed()" [(ngModel)]="fragsToUse" placeholder="100" type="number" min="100" [attr.max]="user.frags" />
                                <div class="indicators">
                                    <i class="fa-solid fa-caret-up up" (click)="addToFragsUsed()"></i>
                                    <i class="fa-solid fa-caret-down down" (click)="subFromFragsUsed()"></i>
                                </div>
                            </div>
                          </span>
                        </div>
                        <div *ngIf="fragsToUse > 99 && fragsToUse < 1001">
                            <button class="button purple" (click)="conjureRelic()">Burn {{conjureCost}} FORGE to Conjure Relic</button>
                        </div>
                        <div class="clear"></div>
                    </div>
                </div>
                <div *ngIf="rarityOdds" class="cardgroup">
                    <div class="cards_available">
                        Rarity Odds
                    </div>
                    <div class="cards_available_spacer"></div>
                    <div *ngFor="let r of rarityOdds">
                        <div *ngIf="r.chance > 0">
                            <h5>{{r.rarity}}</h5>
                            <span class="only_full">{{r.disp}}%</span>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</div>

<div #HoverCard class="hover_view_card" 
    *ngIf="showAvailable && (card_details=='1'||show_card=='1')" 
    [fade]="showAvailable"
    (mouseenter)="Available_Hover=true"
    (mouseleave)="Available_Hover=false"
    [style.height]="show_card=='1'?'255px':'160px'"
    [style.left]="HoverCard_left+'px'" 
    [style.top]="(show_card=='1'?HoverCard_top:HoverCard_top+90)+'px'">
    <div *ngIf="card_details=='0' && showAvailable.checked" class="checked"><i class="fa-solid fa-check"></i></div>
    <sfcard-selector
        *ngIf="show_card=='1'"
        [smaller]="true"
        [stackView]="true"
        [sfcard]="showAvailable"
        [sfcards]="available"
    ></sfcard-selector>
    <div class="card_details" *ngIf="card_details=='1'">
        <div class="pow center">
            <i *ngIf="showAvailable.checked" class="fa-solid fa-check"></i>&nbsp;
            <b>{{showAvailable.name}}</b>
        </div>
        <div class="pow">
            Level: 
            <span class="details_level">
                <i class="fa-solid fa-star"></i>
                {{showAvailable.level}}
            </span>
        </div>
        <div class="pow">
            Rarity: 
            <span class="details_rarity">
                {{showAvailable.rarity}}
            </span>
        </div>
        <div class="pow">
            Equipped: 
            <span class="details_equipped">
                {{!showAvailable.equipped?'No':'Yes'}}
            </span>
        </div>
        <div class="pow">
            Slot: 
            <span class="details_slot">
                {{showAvailable.slot|camelcase}}
            </span>
        </div>
        <div class="pow">
            Foil: 
            <span class="details_foil">
                {{!showAvailable.foil?'None':showAvailable.foil}}
            </span>
        </div>
        <div class="pow">
            Stat: 
            <span class="details_stat">
                {{showAvailable.stat}}
            </span>
        </div>
        <div class="pow">
            Type: 
            <span class="details_type">
                {{showAvailable.type}}
            </span>
        </div>
    </div>
</div>

<transfer-item
    [currentUser]="user"
    [pack]="selectedPack"
    [show]="showHIVETransfer"
    [balance]="balance"
    (done)="showHIVETransfer=false">
</transfer-item>

<notice-modal
    [title]="reforgeTitle"
    [label]="confirmation"
    [show]="showReforgeConfirmation"
    [mode]="'confirmation'"
    [type]="true"
    (done)="convert($event)">
</notice-modal>

<success-modal
    [show]="showSuccessModal"
    [title]="successModalTitle"
    [label]="successModalMessage"
    (close)="showSuccessModal=false"
></success-modal>

<section *ngIf="uniqueCards">
    <div class="cardgroup flip_cards">
        <div class="reveal_all_cards_container_top">
            <div *ngIf="!revealed_all" class="gold button reveal_all" (click)="revealAll()">
                Reveal All
            </div>
        </div>
        <div *ngFor="let c of uniqueCards; let i = index;">
            <div [attr.class]="load_stagger[i]?'card_selector loaded':'card_selector unloaded'" >
                <sfcard-selector
                    [stackView]="true"
                    [sfcard]="c"
                    [sfcards]="newCards"
                    (click)="revealOne(i)"
                    (loaded)="card_loaded(i)">
                </sfcard-selector>
            </div>
        </div>
        <div class="reveal_all_cards_container">
            <div class="purple button back_left" (click)="closeNewCardView()">Back</div>
            <div *ngIf="!revealed_all" class="gold button reveal_all_cards" (click)="revealAll()">Reveal All</div>
            <div *ngIf="revealed_all" class="red button reveal_all_cards" (click)="closeNewCardView()">Close</div>
        </div>
    </div>
</section>
<loading-modal
  [title]="'Loading'"
  [subtitle]="'Identifying Items...'"
  [show]="loading"
  [color]="'green'"
  [label]="loadingLabel"
  (done)="loading=false">
</loading-modal>
