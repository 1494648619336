import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppComponent } from '../../app.component';
import { HelperService } from '../../_services/helper.service';
import { DataService } from '../../_services/data.service';
import { fade, flip_up, pointer, boxlarge, boxsmaller, field_error } from '../../app.animations';
@Component({
  selector: 'transfer-card',
  templateUrl: './transfer-card.component.html',
  animations: [fade, flip_up, field_error, pointer, boxlarge, boxsmaller],
  styleUrls: ['./transfer-card.component.styl']
})
export class TransferCardComponent implements OnInit {
  @Input('currentUser') currentUser: any;
  @Input('balance') balance: any;
  @Input('selected_item') selected_item: any;
  @Input('card') card = [];
  @Input('cards') cards = [];
  @Input('selected') selected = [];
  @Input('show') show = false;
  @Input('transfer_mode') transfer_mode = 'player';
  @Output('done') done = new EventEmitter();
  constructor(
    public app: AppComponent, 
    public helper: HelperService, 
    public data: DataService
  ) { }
  status: any;
  receiver: string = '';
  amount: number = 1;
  method='deposit';
  error: any;
  transfer_confrim: boolean;
  quantity_invalid: boolean;
  username_required: boolean;
  ngOnInit(): void {
    //console.log('card', this.card);
    //console.log('selected_item', this.selected_item);
  }
  ngOnDestroy() {
    this.username_required = false;
  }
  close() {
    this.username_required = false;
    this.show = false
    this.done.emit(0);
  }
  getSymbol() {
    if (this.selected_item.name === 'Alpha Pack') return 'CRATE';
    else if (this.selected_item.name === 'Enhancement Pack') return 'BAG';
  }
  async confirm_transfer() {
    if(this.receiver && this.receiver!='') {
      let exists = await this.helper.verifyUser(this.receiver);
        if (exists && !exists['exist']) {
            this.helper.showSnackbarMessage('Receiving user not found...');
            return;
      }
      this.username_required = false;
      if(this.amount > this.selected_item.qty) {
        this.quantity_invalid = true;
      } else {
        this.quantity_invalid = false;
      }
      this.transfer_confrim = true;
    } else {
      this.username_required = true;
      this.helper.showSnackbarMessage('Please enter a HIVE username to transfer items to.');
    }
  }
  async start_transfer() {
      this.transfer_confrim = false;
      this.username_required = false;
      this.show = false
      this.done.emit(this.receiver);
  }
  async withdrawToHE() {
    this.helper.showSnackbarMessage('Disabled during ALPHA testing.');
    return;/*
    let found = this.currentUser.inGameAssets.find(a => a.name === this.hist.name);
    if (found && found.qty < this.amount) {
      this.error='Account Balance Insufficient';
      return;
    } else if (this.amount > 0 && found.qty >= this.amount) {
      this.data.setLoading(true);
      let temp = await this.helper.transferToHE(this.amount, this.getSymbol());
      //console.log(temp);
      this.close()
      found.qty -= this.amount;
      this.data.setUser(this.currentUser);
      this.data.setLoading(false);
      this.amount = 0;
    } else {
      this.error='Enter a Positive Amount';
    }*/
  }
  async depositToSF() {
    this.helper.showSnackbarMessage('Disabled during ALPHA testing.');
    return;/*
    let found = this.currentUser.inGameAssets.find(a => a.name === this.hist.name);
    if (found && found.qty >= this.amount && this.amount > 0) {
      this.data.setLoading(true);
      let temp = await this.helper.depositToSF(this.amount, this.getSymbol());
      //console.log(temp);
      this.close()
      found.qty -= this.amount;
      this.data.setUser(this.currentUser);
      this.data.setLoading(false);
      this.amount = 0;
    } else {
      this.error='Transaction Not Authorized';
    }*/
  }
  async transferToPlayer() {
    let found = this.currentUser.inGameAssets.find(a => a.name === this.selected_item.name);
    if (found && found.qyt < this.amount) {
      this.error='Balance Insufficient';
      return;
    } else if (this.receiver && this.receiver.length > 2 && this.amount > 0) {
      this.data.setLoading(true);
      let temp = await this.helper.transferTokenToPlayer(this.receiver, this.amount, this.getSymbol());
      if (temp['success']) {
        this.close()
        found.qty -= this.amount;
        this.data.setUser(this.currentUser);
      } else {
        this.error='Transaction Incomplete';
      }
      this.data.setLoading(false);
    } else {
      this.error='Enter a Positive Amount';
    }
  }
}
