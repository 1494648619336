import { Component, OnInit, OnDestroy, HostListener, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from '@app/_services/data.service';
import { HelperService } from '@app/_services/helper.service';
import { Router, ActivatedRoute } from '@angular/router';
import { flip,filters_show,overflow } from '../app.animations';
import { loader,opacity } from '../slcards/slcards.animations';
import { dropdown } from '../hero/hero.animations';
import { User } from '../_models/index';
@Component({
  selector:"app-cards",
  animations: [filters_show,dropdown,flip,overflow,loader,opacity],
  templateUrl: 'cards.component.html',
  styleUrls: [
    './cards.component.styl',
    '../slcards/stats-abilities/abilities.component.styl'
  ]
})
export class CardsComponent implements OnInit {
    @HostListener('window:scroll', ['$event'])
      scroll(event) { this.onScroll(); }
    loading: Boolean = false;
    user: User;
    config: any;
    id: any;
    viewCard;
    sfCard: any = null;
    loaded: any = [];
    sfCards: any = [];
    load_stagger = [];
    cardLoad = [];
    uniqueSFCards: any;
    smaller_size_cards = true;
    sfCardCombinables: any = [];
    baseSFCardCombinables: any;
    sfDisplay: any = {};
    show_abilities: boolean;
    show_slot: boolean;
    show_types: boolean;
    affix_top: boolean;
    show_filters = false
    transfer_card = false
    viewsocket: boolean;
    viewmarket: boolean;
    Common: number;
    Rare: number;
    Epic: number;
    Legendary: number;
    rarity = 0;
    filters = new Filter('',false,false,false,'All','All','All','All');
    ability_filters = [
      'All',
      'Vassal Mastery',
      'Mana Mastery',
      'Death Force',
      'Dragon Force',
      'Earth Force',
      'Fire Force',
      'Life Force',
      'Water Force'
    ];
    ability_slots = [
      {id:'All',name:'All'},
      {id:'relic',name:'Relic'},
      {id:'back',name:'Back'},
      {id:'body',name:'Body'},
      {id:'feet',name:'Feet'},
      {id:'hands',name:'Hands'},
      {id:'head',name:'Head'},
      {id:'legs',name:'Legs'},
      {id:'necklace',name:'Necklace'},
      {id:'rangedWeapon',name:'Bows'},
      {id:'rangedOffhand',name:'Quivers'},
      {id:'ring',name:'Ring'},
      {id:'meleeWeapon',name:'Warrior Weapon'},
      {id:'meleeOffhand',name:'Shields'},
      {id:'magicWeapon',name:'Wizard Weapon'},
      {id:'magicOffhand',name:'Wizard Offhand'}
    ];
    HoverCard_top:number;
    HoverCard_left:number;
    card_details='0';
    showAvailable:any;
    Available_Hover:boolean;
    constructor(
      private data: DataService, 
      private activated: ActivatedRoute,
      private helper: HelperService
    ) { 
      this.id = this.activated.snapshot.queryParams.id;
    }
    @ViewChild('HoverCard') HoverCard: ElementRef;
    @HostListener('document:mousemove', ['$event'])
      onMouseMove(e) {
        if(!this.Available_Hover) {
            this.HoverCard_top=Number(e.pageY-277);//-387
            this.HoverCard_left=Number(e.pageX-93);//-122.5    
        }
    }
    hide_available() {
        if(!this.Available_Hover) {
            this.showAvailable=false;
        }
    }
    _h(e){if(e==0)this.hide_available();}
    toggle_info_window() {
      this.card_details=this.card_details=='1'?'0':'1';
      localStorage.setItem('card_details_card',this.card_details);
    }
    onScroll(a=window.pageYOffset,b=window.innerHeight,c=document.body.scrollHeight) {
      this.affix_top = (a > 77) ? true : false; 
      // autoload when scrolling near the bottom  2100 pixels
      // if ( (b + a + 2100) >= c ) {
      // if ( !this.onlyfavs && this.productsCount > 0 ) this.show_more(); } 
    }

    viewCardSub = new Subscription();
    loadingSub = new Subscription();
    userSub = new Subscription();
    configSub = new Subscription();
    sfCardsSub = new Subscription();
    ngOnInit() {
      this.card_details=localStorage.getItem('card_details_card');
      this.viewCardSub = this.data.onViewCardChange().subscribe(b => this.viewCard = b);
      this.loadingSub = this.data.onLoadingChange().subscribe(b => this.loading = b);
      this.userSub = this.data.onUserChange().subscribe(u => this.user = u);
      this.configSub = this.data.onConfigChange().subscribe(c => this.config = c);
      this.data.setUser(this.helper.currentUser);
      this.data.setConfig(this.helper.config);
      this.sfCardsSub = this.data.onMySFCardsChange().subscribe(async (c) => {
        if (c) {
          this.sfCards = JSON.parse(JSON.stringify(c));
          this.uniqueSFCards = [];
          var uc = await this.helper.getUniqueSFCards(this.sfCards);
          for( let c of uc ) {
            c['flip']=false;
            this.uniqueSFCards.push(c);
          }
          this.Common = this.getRarityCount(1);
          this.Rare = this.getRarityCount(2);
          this.Epic = this.getRarityCount(3);
          this.Legendary = this.getRarityCount(4);
          //this.clearFilters();
          await this.filterCards();
          if (this.helper.detail_id && this.helper.detail_id > 0) {
            this.viewmarket = true;
            this.selectSFCard(null);
          } else if(this.id) {
            for( let i = 0; i < this.sfCards.length; i++) {
              if(this.id==this.sfCards[i].id) this.selectSFCard(this.sfCards[i]);
            }
          }
        }
      });
      this.helper.getSFCardDetails();
      this.helper.getSFCards();
    }
    ngOnDestroy() {
      this.viewCardSub.unsubscribe();
      this.loadingSub.unsubscribe();
      this.userSub.unsubscribe();
      this.configSub.unsubscribe();
      this.sfCardsSub.unsubscribe();
    }
    change_size() {
      this.smaller_size_cards = this.smaller_size_cards ? false : true;
    }
    clearFilters() {
      this.filters = new Filter('',false,false,'All','All','All','All');
      this.show_abilities = false;
      this.show_slot = false;
      this.filters.foil = false;
      this.filterCards();
    }
    async filterCards() {
      this.uniqueSFCards.sort((a,b) => {
        if (a.type === b.type) {
          return b.rarity - a.rarity;
        } else return a.type > b.type ? 1 : -1;
      });
      // this.uniqueSFCards = await this.helper.getUniqueSFCards(this.sfCards);
    }
    filterByRarity(sel?) {
      if(this.filters.rarity != sel){
        this.filters.rarity = sel;
      } else {
        this.filters.rarity = 'All';
      }
    }
    filterByAbility(sel?) {
      this.show_abilities= false;
      if(this.filters.ability != sel){
        this.filters.ability = sel;
      } else {
        this.filters.ability = 'All';
      }
    }
    filterBySlot(sel?, name?) {
      this.show_slot= false;
      if(this.filters.slot != sel){
        this.filters.slot = sel;
      } else {
        this.filters.slot = 'All';
      }
    }
    filterEquipped() {
      this.filters.equipped = !this.filters.equipped;
    }
    filterSockets() {
      this.filters.sockets = !this.filters.sockets;
    }
    filterFoil() {
      this.filters.foil = !this.filters.foil;
    }
    toggleType() {
      if(this.filters.type == 'socket')this.filters.type = 'All';
      if(this.filters.type == 'equipment')this.filters.type = 'socket';
    }
    toggleAbilities() {
      this.show_slot=false;
      this.show_types = false;
      this.show_abilities=this.show_abilities?false:true;
    }
    toggleSlots() {
      this.show_types = false;
      this.show_abilities=false;
      this.show_slot=this.show_slot?false:true;
    }
    toggleTypes() {
      this.show_slot=false;
      this.show_abilities=false;
      this.show_types=this.show_types?false:true;
    }
    filterType(t) {
      this.show_types = false;
      if(this.filters.type == t) {
        this.filters.type = 'All';
      } else {
        this.filters.type = t;  
      }
    }
    getRarityCount(rarity) {
      let count = 0;
      if (!this.sfCards) return 0;
      this.sfCards.forEach(c => { if (c.rarity === rarity) count += 1 });
      return count;
    }
    toggle_filters() {
      this.show_abilities = false;
      this.show_slot = false;
      this.show_filters=this.show_filters?false:true;
    }
    async getSFCards() {
      await this.helper.getSFCards();
    }

    isSFCardMaxLevel(card) {
      return card.level === 4;
      //const cardLevels = this.config.sfCardLevels[card.rarity-1];
      //const maxLevel = cardLevels[cardLevels.length-1];
      //let cardsAwayFromMax = maxLevel - card.combined;
      //return cardsAwayFromMax < 1 ? true : false;
    }

    selectSFCard(card, socket?, market = false) {
      this.hide_available();
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      if (!card) {
        let tempFoil = false;
        if (this.helper.detail_foil === 'true') tempFoil = true;
        let found = this.sfCards.filter(c => { return c.detail_id === parseInt(this.helper.detail_id) && c.foil === tempFoil });
        if (found && found.length > 0) {
          this.helper.detail_id = null;
          this.helper.detail_foil = null;
          this.selectSFCard(found[0], null, true);
          return;
        } else {
          this.helper.showSnackbarMessage('You do not own any of those');
          console.log('do not own any of those');
          return;
        }
      } else {
        if (socket) this.viewsocket=true;
        else if (!market) this.viewmarket = false;
        this.sfCard = card;
        let maxLevel = card.level === 4; // this.isSFCardMaxLevel(card);
        this.sfCardCombinables = this.sfCards.filter(c => { return c.name === card.name && c.foil === card.foil; });
        this.baseSFCardCombinables = JSON.parse(JSON.stringify(this.sfCardCombinables));
        let statLevels = this.config['statLevels'];
        let cardLevels = this.sfCard.foil ? this.config['sfFoilCardLevels'] : this.config['sfCardLevels'];
        this.sfDisplay.stats = statLevels[card.rarity - 1];
        this.sfDisplay.levels = cardLevels[card.rarity - (this.sfCard.foil ? 2 : 1)];
        if (card.type === 'socket') {
          let socketAbilities = this.helper.config['socketItems'];
          if (socketAbilities && socketAbilities.length > 0) {
            for (let i = 0; i < socketAbilities.length; i++) {
              if (card.name.indexOf(socketAbilities[i].type) > -1) {
                this.sfDisplay.stats = socketAbilities[i].bonus;
                i = socketAbilities.length;
              }
            }
          }
        }
      }
      this.data.setViewCard(true);
    }

    cardLoaded(c,i) {
      this.loaded[c.id]=true;
      this.cardLoad[i]=true;
      if(this.uniqueSFCards.length == i+1) this.stagger_openings();
    }
    stagger_openings(i=0) {
        if(this.uniqueSFCards) {
            setTimeout(()=>{
              if(this.uniqueSFCards[i]){
                this.uniqueSFCards[i].flip=false;
                if(i<this.uniqueSFCards.length) this.stagger_openings(i+1);
              }
            }, 20);    
        }
    }
}
export class Filter {
  constructor(
    public search?: string,
    public sockets?: any,
    public equipped?: any,
    public foil?: any,
    public rarity?: any,
    public ability?: any,
    public type?: any,
    public slot?: any,
  ) {}
} 