import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class RewardService {

    apiUrl = environment.apiUrl;

    constructor(private http: HttpClient) {  }

    getRewards(tier) : any {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.apiUrl + '/rewards/bossRewards', { tier: tier })
                .toPromise().then(res => {
                    resolve(res);
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        });
        return promise;
    }
}