import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HelperService } from '../../_services/helper.service';
import { fade, flip_up, pointer, boxlarge, boxsmaller, field_error } from '../../app.animations';
@Component({
  selector: 'mineRunRules',
  animations: [fade, flip_up, pointer, boxlarge, boxsmaller, field_error],
  templateUrl: './mineRunRules.component.html',
  styleUrls: [ './mineRunRules.component.less']
})
export class MineRunRulesComponent implements OnInit {
  
  @Input('show') show = true;
  @Output('done') done = new EventEmitter();
  
  constructor (public helper: HelperService) { }
  
  ngOnInit() { }
  
  close() { 
    //this.show = false;
    this.done.emit(0);
  }
}