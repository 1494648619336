<section class="forgemodal gold" [@fade]="show">
    <div class="boxwrap" [@flip_up]="show">
		<h1 [innerHTML]="title"></h1>
		<div>
			<p [innerHTML]="top_label"></p>
			<div class="button_group">
				<button (click)="close()" class="button gold">Close</button>
				<div class="clear"></div>
			</div>
		</div>
		<div class="closewrapper" (click)="close()">
			<i class="fa-solid fa-x"></i>
		</div>
	</div>
	<div class="backdrop" [@pointer]="show" (click)="close()"></div>
</section>