<div class="background"></div>
<!-- displayed while loading cards from server -->
<!-- <section *ngIf="!uniqueSFCards" id="yourteam">
  <div class="inner">
    <p>Loading cards from server...</p>
  </div>
</section> -->

<div [attr.class]="affix_top?'page_heading fixed_top':'page_heading'" *ngIf="!viewCard">
  <div class="bottom_border"></div>
  <h1>My Cards</h1> 
  <!--  [@filters_show]="(sfCards && sfCards.length > 0 && uniqueSFCards)?true:false" -->
  <div class="tab_bar tab_buttons">
    <button (click)="filterByRarity('Common')" 
            *ngIf="Common > 0"
            [attr.class]="filters.rarity=='Common'?'tablinks active':'tablinks'">
      <span>Common</span>
      <span class="filter_count" *ngIf="sfCards.length>0">&nbsp;({{Common}}/{{sfCards.length}})</span>
    </button>
    <button (click)="filterByRarity('Rare')" 
            *ngIf="Rare > 0"
            [attr.class]="filters.rarity=='Rare'?'tablinks active':'tablinks'">
      <span>Rare</span>
      <span class="filter_count" *ngIf="sfCards.length>0">&nbsp;({{Rare}}/{{sfCards.length}})</span>
    </button>
    <button (click)="filterByRarity('Epic')" 
            *ngIf="Epic > 0"
            [attr.class]="filters.rarity=='Epic'?'tablinks active':'tablinks'">
      <span>Epic</span>
      <span class="filter_count" *ngIf="sfCards.length>0">&nbsp;({{Epic}}/{{sfCards.length}})</span>
    </button>
    <button (click)="filterByRarity('Legendary')" 
            *ngIf="Legendary > 0"
            [attr.class]="filters.rarity=='Legendary'?'tablinks active':'tablinks'">
      <span>Legendary</span>
      <span class="filter_count" *ngIf="sfCards.length>0">&nbsp;({{Legendary}}/{{sfCards.length}})</span>
    </button>
    <span class="show_filters_btn" (click)="toggle_filters()">
      <div [attr.class]="show_filters?'bars active':'bars'">
        <div class="one"></div>
        <div class="two"></div>
        <div class="three"></div>
      </div>
    </span>
  </div>
  <div class="size_controller leaderboardbuttons filter_control anchor no_filters_container" (click)="change_size()">
    <i class="fa-solid fa-magnifying-glass-minus" *ngIf="!smaller_size_cards"></i>
    <i class="fa-solid fa-magnifying-glass-plus" *ngIf="smaller_size_cards"></i>
  </div>
  <div class="info_controller leaderboardbuttons filter_control anchor no_filters_container" (click)="toggle_info_window()">
    <i class="fa-solid fa-circle-info" *ngIf="card_details=='1'"></i>
    <i class="fa-solid fa-info" *ngIf="card_details!='1'"></i>
  </div>
  <section [@overflow]="show_filters" [attr.class]="show_filters?'filters show':'filters'">
      <div class="primary_line">
        <div class="leaderboardbuttons filter_control">
          <span class="filter_title">Ability:</span>
          <div [attr.class]="(show_abilities?'over':'')+' filter_selector'">
              <div class="dropdown">
                  <div class="label" (click)="toggleAbilities()">
                      <span>{{filters.ability}}</span>
                      <i class="fa-solid fa-chevron-down" [@flip]="show_abilities">
                      </i>
                  </div>
              </div>
              <ul class="menu" [@dropdown]="show_abilities==true">
                  <li *ngFor="let n of ability_filters"
                      [attr.class]="'item '+(filters.ability==n?' active':'')" 
                      (click)="filterByAbility(n)">
                    {{n}}
                  </li>
              </ul>
          </div>
        </div>
        <div class="leaderboardbuttons filter_control">
          <span class="filter_title">Slot:</span>
          <div [attr.class]="(show_slot?'over':'')+' filter_selector'">
              <div class="dropdown">
                  <div class="label" (click)="toggleSlots()">
                      <span>{{filters.slot}}</span>
                      <i class="fa-solid fa-chevron-down" [@flip]="show_slot">
                      </i>
                  </div>
              </div>
              <ul class="menu" [@dropdown]="show_slot==true">
                <li *ngFor="let n of ability_slots"
                    [attr.class]="'item '+(filters.slot==n.id?'active':'')"
                    (click)="filterBySlot(n.id, n.name)">
                  {{n.name}}
                </li>
              </ul>
          </div>
        </div>
        <div class="leaderboardbuttons filter_control type_menu">
          <span class="filter_title">Type:</span>
          <div [attr.class]="(show_types?'over':'')+' filter_selector'">
              <div class="dropdown">
                  <div class="label" (click)="toggleTypes()">
                      <span>{{filters.type}}</span>
                      <i class="fa-solid fa-chevron-down" [@flip]="show_types">
                      </i>
                  </div>
              </div>
              <ul class="menu" [@dropdown]="show_types==true">
                <li [attr.class]="'item '+(filters.type=='All'?'active':'')" (click)="filterType('All')">All</li>
                <li [attr.class]="'item '+(filters.type=='equipment'?'active':'')" (click)="filterType('equipment')">Equipment</li>
                <li [attr.class]="'item '+(filters.type=='socket'?'active':'')" (click)="filterType('socket')">Socket</li>
                <li [attr.class]="'item '+(filters.type=='foil'?'active':'')" (click)="filterType('foil')">Foil</li>
                <li [attr.class]="'item '+(filters.type=='nonFoil'?'active':'')" (click)="filterType('nonFoil')">Non-Foil</li>
              </ul>
          </div>
        </div>
      </div>
      <div class="secondary_line">
        <div class="leaderboardbuttons filter_control anchor equipped_toggle" (click)="filterEquipped()">
          <span class="filter_title equipped_filter">
            <span>Equipped</span>
            <div class="icon">
              <i *ngIf="filters.equipped" class="fa-solid ck fa-check"></i>
              <i class="fa-regular fa-square-full"></i>
            </div>
          </span>
        </div>
        <div class="leaderboardbuttons filter_control anchor equipped_toggle" (click)="filterSockets()">
          <span class="filter_title socket_filter">
            <span>Sockets</span>
            <div class="icon">
              <i *ngIf="filters.sockets" class="fa-solid ck fa-check"></i>
              <i class="fa-regular fa-square-full"></i>
            </div>
          </span>
        </div>
        <!--
        <div class="leaderboardbuttons filter_control anchor equipped_toggle" (click)="filterFoil()"> 
          <span class="filter_title socket_filter">
            <span>Foil</span>
            <div class="icon">
              <i *ngIf="filters.foil" class="fa-solid ck fa-check"></i>
              <i class="fa-regular fa-square-full"></i>
            </div>
          </span>
        </div>
        -->
        <div class="leaderboardbuttons filter_control anchor type_toggle">
          <span class="filter_title socket_filter">
            <div class="filter_bg" (click)="filterType('All')"></div>
            <span>Type</span>
            <div class="right_radio">
              <div class="radio" (click)="filterType('socket')">
                <span>Socket</span>
                <i *ngIf="filters.type=='socket'" class="fa-regular fa-circle-dot"></i>
                <i *ngIf="filters.type!='socket'" class="fa-regular fa-circle"></i>
              </div>
              <div class="radio" (click)="filterType('equipment')">
                <span>Equipment</span>
                <i *ngIf="filters.type=='equipment'" class="fa-regular fa-circle-dot"></i>
                <i *ngIf="filters.type!='equipment'" class="fa-regular fa-circle"></i>
              </div>
            </div>
          </span>
        </div>
        <div class="leaderboardbuttons filter_control search_term">
          <span class="filter_title">Search:</span>
          <div class="filter_selector">
             <input class="filter_string" type="text" placeholder="Filter" [(ngModel)]="filters.search" />
          </div>
        </div>
        <div *ngIf="filters.ability==='All'&&filters.type==='All'&&filters.search===''&&!filters.sockets&&filters.rarity==='All'&&filters.slot==='All'&&!filters.equipped&&!filters.foil" 
             class="leaderboardbuttons filter_control anchor no_filters_container" (click)="clearFilters()">
          <span class="filter_title no_filters">
            No Filters
          </span>
        </div>
        <div *ngIf="filters.ability !== 'All'||filters.type!=='All'||filters.sockets||filters.search!==''||filters.rarity!=='All'||filters.slot!=='All'||filters.equipped||filters.foil" class="leaderboardbuttons filter_control anchor clear_filters no_filters_container" (click)="clearFilters()">
          <span class="filter_title clear_filters">
            Clear Filters
            <i class="fa-solid fa-x"></i>
          </span>
        </div>
      </div>
    <div class="clear"></div>
  </section>
  <div class="clear"></div>
</div>
<section *ngIf="!viewCard">
  <div class="loader_icons" *ngIf="!uniqueSFCards">
      <div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
  </div>
  <div *ngIf="uniqueSFCards" [attr.class]="affix_top?'cardgroup margin_top':'cardgroup'">
    <div class="account-card card_hover_large" *ngFor="let c of uniqueSFCards | ability_name:filters.ability | equipped:filters.equipped | rarity:filters.rarity | slot:filters.slot | sockets:filters.sockets | type:filters.type | name_includes:filters.search | foil:filters.foil; let i = index"> 
      <div (mouseenter)="showAvailable=cardLoad[i]?c:false" (mouseleave)="hide_available()">
        <sfcard-selector
          [sfcard]="c" 
          [sfcards]="sfCards" 
          [stackView]="true" 
          [smaller]="smaller_size_cards"
          [flipped]="cardLoad[i]?false:true"
          (hoverstate)="_h($event)"
          (loaded)="cardLoaded(c,i)"
          (select_socket)="selectSFCard(c,true)"
          (click)="selectSFCard(c)">
        </sfcard-selector>
      </div>
    </div>
    <section *ngIf="uniqueSFCards && uniqueSFCards.length === 0">
      <div class="cards no_cards">
        <h1>No Cards</h1>
        <h3>You don't have any of these yet.</h3>
        <ul>
          <li>CRATEs may be ordered in the shop for 3000 FORGE or 3000 DEC each.</li>
          <li>BAGs may be ordered in the shop for 4000 FORGE, all FORGE spent on BAGs is burnt.</li>
          <li>To open CRATEs/BAGs, visit the Forge page.</li>
        </ul>
      </div>
    </section>
  </div>
</section>


<div #HoverCard class="hover_view_card" 
    *ngIf="showAvailable && (card_details=='1')" 
    [fade]="showAvailable"
    (mouseenter)="Available_Hover=true"
    (mouseleave)="Available_Hover=false"
    [style.height]="show_card=='1'?'255px':'160px'"
    [style.left]="HoverCard_left+'px'" 
    [style.top]="(show_card=='1'?HoverCard_top:HoverCard_top+90)+'px'">
    <div class="card_details" *ngIf="card_details=='1'">
        <div class="pow center">
            <i *ngIf="showAvailable.checked" class="fa-solid fa-check"></i>&nbsp;
            <b>{{showAvailable.name}}</b>
        </div>
        <div class="pow">
            Level: 
            <span class="details_level">
                <i class="fa-solid fa-star"></i>
                {{showAvailable.level}}
            </span>
        </div>
        <div class="pow">
            Rarity: 
            <span class="details_rarity">
                {{showAvailable.rarity}}
            </span>
        </div>
        <div class="pow">
            Equipped: 
            <span class="details_equipped">
                {{!showAvailable.equipped?'No':'Yes'}}
            </span>
        </div>
        <div class="pow">
            Slot: 
            <span class="details_slot">
                {{showAvailable.slot|camelcase}}
            </span>
        </div>
        <div class="pow">
            Foil: 
            <span class="details_foil">
                {{!showAvailable.foil?'None':showAvailable.foil}}
            </span>
        </div>
        <div class="pow">
            Stat: 
            <span class="details_stat">
                {{showAvailable.stat}}
            </span>
        </div>
        <div class="pow">
            Type: 
            <span class="details_type">
                {{showAvailable.type}}
            </span>
        </div>
    </div>
</div>

<transfer-card
  [selected_item]="sfCard"
  [show]="transfer_card"
  (done)="transfer_card=false;"
></transfer-card>
<selectedcard-selector
  *ngIf="viewCard"
  [card]="sfCard"
  [cards]="sfCards"
  [combinables]="sfCardCombinables"
  (close)="viewsocket=false;"
  [tabName]="viewsocket?'Imbue':viewmarket?'Market':'SelectedCard'"
  [display]="sfDisplay">
</selectedcard-selector>