<section class="forgemodal" [@fade]="show">
	<div class="boxwrap large_stam" [@flip_up]="show">
		<h1>Stamina</h1>
		<div>
			<div>
				<h1 class="green">Burn FORGE for stamina.</h1>
				<div class="itemwrapper">
					<h1>Current rate is 1 stamina per {{burnRate}} FORGE burnt.</h1>
					<div class="centered">
						<input class="stam_qty" type="number" min="10" step="10" [(ngModel)]="stamQty" />
						<div class="button green half" (click)="burnForStamina()">BURN {{(burnRate*stamQty) | number : '1.0-0'}} FORGE for {{stamQty}} Stamina</div>
					</div>
				</div>
			</div>
			<div *ngIf="hasPotions && currentUser && currentUser.inGameAssets && currentUser.inGameAssets.length > 0">
				<h1 *ngIf="!error" class="green">
					{{ success?result_message:"Stamina Elixirs" }}
				</h1>
				<h1 *ngIf="error == 400" class="red">{{result_message}}</h1>
				<h1 *ngIf="error == 500" class="yellow">{{result_message}}</h1>
				<h1 *ngIf="low_stamina" class="red">
					<img class="icon" src="/assets/menu/menu-stamina.png">
					Stamina Required
				</h1>
				<p class="stamina_elixirs">
					Stamina Elixirs are a discontinued resource, once you have used all your elixirs, this section should disappear.
				</p>
				<div class="itemwrapper elixirs">
					<div *ngFor="let asset of items | name:'Stamina'" 
						[attr.class]="asset==selected_asset?'itembox active':'itembox'">
						<div class="shopItem">
							<div  class="image">
								<div class="imagewrapper">
									<img src="https://splinterforge.s3.us-east-2.amazonaws.com/shop/Stamina+Potion.png">
									<span  *ngIf="asset.stamina_number" class="card_quantity stamina" title="+{{asset.stamina}} Stamina">
										<span class="qtybadge">
											<span>+{{asset.stamina}}</span>
										</span>
									</span>
								</div>
							</div>
							<div class="itemtitle">{{asset.name}}</div>
							<div class="textwrap text_container">
								<p class="description">{{asset.description}}</p>
								<div class="price" *ngIf="asset.price">
									{{asset.price[0].amount|number}} FORGE
								</div>
								<div class="clear"></div>
								<div class="caption buy">
									<div *ngIf="!available(asset)" title="No {{asset.name}} left to use." class="button green disabled">Use</div>
									<div *ngIf="available(asset)" title="Consume 1 {{asset.name}}." class="button green" (click)="useConsumable(asset, 1)">Use ({{available(asset)}})</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="!currentUser || !currentUser.inGameAssets || currentUser.inGameAssets.length === 0">
				<h4>You don't have any stamina elixirs</h4>
			</div>
		</div>
		<div class="closewrapper" (click)="close()"><i class="fa-solid fa-x"></i></div>
	</div>
	<div class="backdrop" [@pointer]="show" (click)="close()"></div>
</section>
