import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelcase'
})
export class CamelcasePipe implements PipeTransform {
  transform(s: any, args?: any): any {
  	s = s.replace(/([A-Z])/g, ' $1').trim();
    return s;
  }
}