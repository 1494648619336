import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { trigger, state, animate, transition, style } from '@angular/animations';

export const ActiveCard = trigger('active_card', [
    state('true', style({
        'box-shadow': 'inset 0px 0px 64px #f50, 2px 2px 4px #000',
        'background': '#bd2424'
    })),
    state('false', style({
        'box-shadow': 'inset 0px 0px 64px transparent, 2px 2px 4px transparent',
        'background': 'url("https://splinterforge.s3.us-east-2.amazonaws.com/frame_art/bg.jpg")'
    })),
    transition('*=>*', animate('270ms'))
]);
export const loader = trigger('loader', [
    state('true', style({
        'transform':'scale(1)'
    })),
    state('false', style({
        'transform':'scale(0)'
    })),
    transition('*=>*', animate('200ms'))
]);
export const opacity = trigger('opacity', [
    state('true', style({
        'opacity':'1'
    })),
    state('false', style({
        'opacity':'0'
    })),
    transition('*=>*', animate('100ms'))
]);
export const display = trigger('display', [
    state('true', style({
        'display':'inline-block'
    })),
    state('false', style({
        'display':'none'
    })),
    transition('*=>*', animate('0ms'))
]);
export const fullwidth = trigger('fullwidth', [
    state('true', style({
        'width': '100%'
    })),
    state('false', style({
        'width': 'calc(100% - 295px)'
    })),
    transition('*=>*', animate('0ms'))
]);
export const SlideDonationDown = trigger('SlideDonationDown', [
    state('true', style({
        'height': '70px',
        'border': '1px solid #ffffff75',
        'opacity': 1
    })),
    state('false', style({
        'padding-top': '0px',
        'padding-bottom': '0px',
        'margin': '0px',
        'height': '0px',
        'border': '0px solid transparent',
        'opacity': 0
    })),
    transition('*=>*', animate('300ms'))
]);
export const appear = trigger('appear', [
    state(':enter', style({
        'opacity': 1
    })),
    state(':leave', style({
        'opacity': 0
    })),
    transition('*=>*', animate('300ms'))
]);
export const rotate_appear = trigger('rotate_appear', [
    state(':enter', style({
        'transform':'rotateX(0deg)'
    })),
    state(':leave', style({
        'transform':'rotateX(90deg)'
    })),
    transition('*=>*', animate('100ms'))
]);
export const active_stat = trigger('active_stat', [
    state('true', style({
        'transform': 'scale(0.93)'
    })),
    state('false', style({
        'transform': 'scale(1)'
    })),
    transition('*=>*', animate('300ms'))
]);
export const active_overlay = trigger('active_overlay', [
    state('true', style({
        'transform': 'scale(1.25)'
    })),
    state('false', style({
        'transform': 'scale(1)'
    })),
    transition('*=>*', animate('300ms'))
]);