<div *ngIf="maxLevels" [attr.class]="small?'smaller max_levels':'max_levels'" (click)="toggle_labels()">
	<div class="max_level c">
		<div class="level_popover">Max level for <b>Common</b> monsters against this boss: 
			<span class="c label"><span [@opacity]="helper.showLabels" class="letter">C</span></span>
			<b>{{maxLevels[0]}} Commons.</b></div>
		<span class="c label"><span [@opacity]="helper.showLabels" class="letter">C</span></span>
		<span class="level_indi">{{maxLevels[0]}}</span>
	</div>
	<div class="max_level r">
		<div class="level_popover">Max level for <b>Rare</b> monsters against this boss: 
			<span class="r label"><span [@opacity]="helper.showLabels" class="letter">R</span></span>
			<b>{{maxLevels[1]}} Rares.</b></div>
		<span class="r label"><span [@opacity]="helper.showLabels" class="letter">R</span></span>
		<span class="level_indi">{{maxLevels[1]}}</span>
	</div>
	<div class="max_level e">
		<div class="level_popover">Max level for <b>Epic</b> monsters against this boss: 
			<span class="e label"><span [@opacity]="helper.showLabels" class="letter">E</span></span>
			<b>{{maxLevels[2]}} Epics.</b></div>
		<span class="e label"><span [@opacity]="helper.showLabels" class="letter">E</span></span>
		<span class="level_indi">{{maxLevels[2]}}</span>
	</div>
	<div class="max_level l">
		<div class="level_popover">Max level for <b>Legendary</b> monsters against this boss: 
			<span class="l label"><span [@opacity]="helper.showLabels" class="letter">L</span></span>
			<b>{{maxLevels[3]}} Legendaries.</b></div>
		<span class="l label"><span [@opacity]="helper.showLabels" class="letter">L</span></span>
		<span class="level_indi">{{maxLevels[3]}}</span>
	</div>
</div>

