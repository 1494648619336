import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppComponent } from '../../app.component';
import { HelperService } from '../../_services/helper.service';
import { DataService } from '../../_services/data.service';
import { fade, flip_up, pointer, boxlarge, boxsmaller } from '../../app.animations';
@Component({
  selector: 'transfer-item',
  templateUrl: './transfer-item.component.html',
  animations: [fade, flip_up, pointer, boxlarge, boxsmaller],
  styleUrls: ['./transfer-item.component.styl']
})
export class TransferItemComponent implements OnInit {
  @Input('currentUser') currentUser: any;
  @Input('balance') balance: any;
  @Input('pack') selected_item: any;
  @Input('show') show = false;
  @Input('transfer_mode') transfer_mode = 'player';
  @Output('done') done = new EventEmitter();
  constructor(
    public app: AppComponent, 
    public helper: HelperService, 
    public data: DataService
  ) { }
  status: any;
  receiver: string = '';
  amount: number = 1;
  method='deposit';
  error: any;
  transfer_confrim: boolean;
  quantity_invalid: boolean;
  ngOnInit(): void {

  }
  close() {
    this.show = false
    this.done.emit(1);
  }
  getSymbol() {
    if (this.selected_item.name === 'Alpha Pack') return 'CRATE';
    else if (this.selected_item.name === 'Enhancement Pack') return 'BAG';
  }
  confirm_transfer(item) {
    //console.log(this.amount, this.transfer_mode, this.selected_item.qty, this.balance);
    if (this.amount > (this.transfer_mode === 'from' ? this.amount : this.selected_item.qty)) {
      this.helper.showSnackbarMessage('Not enough '+item.name+'s to transfer.');
    } else if (this.transfer_mode === 'player' && (!this.receiver || this.receiver.length < 3)) {
      this.helper.showSnackbarMessage('Please specify a receiving user.');
    } else {
      this.transfer_confrim = true;  
      this.quantity_invalid = false;
    } 
    return;

    if( (this.transfer_mode=='from' && this.balance>0)||(this.transfer_mode!='from'&&this.selected_item.qty>0) ) {
      if(this.transfer_mode=='from' || this.transfer_mode=='to' || (this.transfer_mode=='player' && this.receiver)) {
        if(this.amount > this.balance) {
          this.quantity_invalid = true;
          this.helper.showSnackbarMessage('Too many selected to transfer.');
        } else {
          this.transfer_confrim = true;  
          this.quantity_invalid = false;
        }
      } else {
        this.helper.showSnackbarMessage('Please specify a receiving user.');
      }
    } else {
      this.helper.showSnackbarMessage('Not enough '+item.name+'s to transfer.');
    }
  }
  async start_transfer() {
    this.transfer_confrim = false;
    if (this.transfer_mode === 'to') {
      await this.withdrawToHE();
    } else if (this.transfer_mode === 'from') {
      await this.depositToSF();
    } else if (this.transfer_mode === 'player') {
      await this.transferToPlayer();
    }
  }
  async withdrawToHE() {
    let found = this.currentUser.inGameAssets.find(a => a.name === this.selected_item.name);
    if (found && found.qty < this.amount) {
      this.error='Account Balance Insufficient';
      return;
    } else if (this.amount > 0 && found.qty >= this.amount) {
      this.data.setLoading(true);
      let temp = await this.helper.transferToHE(this.amount, this.getSymbol());
      if (!temp) return;
      if (temp['success']) {
        this.close();
        if (this.getSymbol() === 'CRATE') this.helper.changeCRATE(-this.amount);
        else if (this.getSymbol() === 'BAG') this.helper.changeBAG(-this.amount);
        this.amount = 0;
      } else {
        this.error='Transaction Incomplete';
      }
      this.data.setLoading(false);
    } else {
      this.error='Enter a Positive Amount';
    }
  }
  async depositToSF() {
    if (this.amount > 0) { // TODO - confirm available HE balance (maybe as helper function);
      this.data.setLoading(true);
      let temp = await this.helper.depositToSF(this.amount, this.getSymbol());
      if (!temp) return;
      if (temp['success']) {
        this.close();
        if (this.getSymbol() === 'CRATE') this.helper.changeCRATE(this.amount);
        else if (this.getSymbol() === 'BAG') this.helper.changeBAG(this.amount);
        this.amount = 0;
      } else {
        this.error='Transaction Incomplete';
      }
      this.data.setLoading(false);
    } else {
      this.error='Transaction Not Authorized';
    }
  }
  
  async transferToPlayer() {
    let found = this.currentUser.inGameAssets.find(a => a.name === this.selected_item.name);
    let symbol = this.getSymbol();
    if (found && found.qyt < this.amount) {
      this.error='Balance Insufficient';
      return;
    } else if (this.receiver && this.receiver.length > 2 && this.amount > 0) {
      this.data.setLoading(true);
      let temp = await this.helper.transferTokenToPlayer(this.receiver, this.amount, symbol);
      if (!temp) return;
      if (temp['success']) {
        this.close();
        if (symbol === 'BAG') this.helper.changeBAG(-this.amount);
        else if (symbol === 'CRATE') this.helper.changeCRATE(-this.amount);
        this.amount = 0;
      } else {
        this.error='Transaction Incomplete';
      }
      this.data.setLoading(false);
    } else {
      this.error='Enter a Positive Amount';
    }
  }
}
