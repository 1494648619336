<div [attr.class]="in_modal?'absolute_positioned':''">
    <div class="loading" *ngIf="loading">
      <div class="lds-lines"><div></div><div></div><div></div></div>
    </div>
    <div *ngIf="boss?.standings">
        <div *ngIf="!loading">
            <div *ngIf="view === 'standings'"> 
                <div>
                    <div>
                        <span>Spawned: {{boss.spawned}}</span>
                        <span *ngIf="boss.killed"> - Killed: {{boss.killed}}</span>
                    </div>
                </div>
                <div class="table_container" *ngIf="boss.standings">
                    <div class="table_header" *ngIf="user_standings.length>0">
                        <div class="table_cell player_header">Player</div>
                        <div class="table_cell points_header">Points</div>
                        <div class="table_cell battles_header">Battles</div>
                        <div class="table_cell reward_header">Reward</div>
                    </div>
                    <div *ngFor="let player of user_standings | slice:0:1" class="table_row user_header">
                        <div class="table_cell name">
                            <span># </span>
                            <span class="score sm">{{player.place}}</span>
                            <span class="player">{{player.player}}</span>
                        </div>
                        <div class="table_cell points sm">{{player.dmg|number}}</div>
                        <div class="table_cell battles sm">{{player.fights|number}}</div>
                        <div class="table_cell crystals" *ngIf="player.newReward && player.newReward.xp > 0">
                            <span class="sl_crystals_balance" *ngIf="player.newReward.xp > 0">
                                <b>{{player.newReward.xp|number}}</b> XP, <b>{{player.newReward.electrum}}</b> Electrum, <b>{{player.newReward.frags}}</b> Frags
                            </span>
                        </div>
                    </div>
                    <div class="table_header">
                        <div class="table_cell player_header">Player</div>
                        <div class="table_cell points_header">Points</div>
                        <div class="table_cell battles_header">Battles</div>
                        <div class="table_cell reward_header">Reward</div>
                    </div>
                    <div *ngFor="let player of boss.standings | slice:b:b+b_limit; let i = index"
                        [attr.class]="(!loaded[i]?'table_row inactive':'table_row')+' '+(filter_player==player.player?'highlight':'')">
                        <div class="table_cell name">
                            <span># </span>
                            <span [attr.class]="'_'+_sum(b,i)+' score'">{{b+i+1}}</span>
                            <span class="player">{{player.player}}</span>
                        </div>
                        <div class="table_cell points">{{player.dmg|number}}</div>
                        <div class="table_cell battles">{{player.fights|number}}</div>
                        <div class="table_cell crystals" *ngIf="player.newReward && player.newReward.xp > 0">
                            <span class="sl_crystals_balance" *ngIf="player.newReward.xp > 0">
                                <b>{{player.newReward.xp|number}}</b> XP, <b>{{player.newReward.electrum}}</b> Electrum, <b>{{player.newReward.frags}}</b> Frags
                            </span>
                        </div>

<!--
                        <div class="table_cell crystals" *ngIf="player.reward > 0">
                            <span class="sl_crystals_balance" *ngIf="player.reward > 0">
                                <b>{{player.reward|number}}</b> FORGE
                                <img class="crystals" src="https://splinterforge.s3.us-east-2.amazonaws.com/tokens/forgium.png" />
                            </span>
                        </div>-->
                    </div>
                </div>
            </div>
            <div *ngIf="view === 'recent'">
                <div class="table_container" *ngIf="boss.battles">
                    <div class="table_header">
                        <div class="table_cell player_header">Player</div>
                        <div class="table_cell points_header">Points</div>
                        <div class="table_cell time_header">Date</div>
                        <div class="table_cell replay_header">Team</div>
                    </div>
                    <div *ngFor="let fight of boss.battles | sort:'desc':'date' | slice:b:b+b_limit; let i = index" [attr.class]="(!loaded[i]?'table_row inactive':'table_row')+' act '+(filter_player==fight.player?'highlight':'')" (click)="showTeam(fight, false, i)">
                        <div class="table_cell name"><span>{{fight.player}}</span></div>
                        <div class="table_cell points">{{fight.points|number}}</div>
                        <div class="table_cell time" [attr.title]="fight.date|date:'MMM d, y, h:mm:ss a'"><span>{{fight.date|timeago}}</span></div>
                        <div class="table_cell replay">
                            <span *ngIf="1>1" [attr.class]="watchingFight[i]?'sl_crystals_balance watch active':'sl_crystals_balance watch'" 
                                  (click)="showTeam(fight, true, i)">
                                <span *ngIf="!watchingFight[i]">Watch</span>
                                <span *ngIf="watchingFight[i]">Watch...</span>
                            </span>
                            <span [attr.class]="(viewTeam&&team==fight.team)?'sl_crystals_balance show_team active':'sl_crystals_balance show_team'" 
                                  (click)="showTeam(fight, false, i)">
                                <span *ngIf="viewTeam && team==fight.team">Viewing...</span>
                                <span *ngIf="!viewTeam || team!=fight.team">Show Team</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="view === 'myFights'">
                <div>
                    <h5>My Fights vs. {{boss.name}}</h5>
                </div>
                <div class="table_container" *ngIf="myFights">
                    <div class="table_header">
                        <div class="table_cell player_header">Player</div>
                        <div class="table_cell points_header">Points</div>
                        <div class="table_cell time_header">Date</div>
                        <div class="table_cell replay_header">Team</div>
                    </div>
                    <div *ngFor="let fight of myFights | sort:'desc':'date' | slice:b:b+b_limit; let i = index" [attr.class]="(!loaded[i]?'table_row inactive':'table_row')+' act '+(filter_player==fight.player?'highlight':'')" (click)="showTeam(fight, false, i)">
                        <div class="table_cell name"><span>{{fight.player}}</span></div>
                        <div class="table_cell points">{{fight.points|number}}</div>
                        <div class="table_cell time" [attr.title]="fight.date|date:'MMM d, y, h:mm:ss a'"><span>{{fight.date|timeago}}</span></div>
                        <div class="table_cell replay">
                            <span [attr.class]="watchingFight[i]?'sl_crystals_balance watch active':'sl_crystals_balance watch'" 
                                  (click)="showTeam(fight, true, i)">
                                <span *ngIf="!watchingFight[i]">Watch</span>
                                <span *ngIf="watchingFight[i]">Watch...</span>
                            </span>
                            <span [attr.class]="(viewTeam&&team==fight.team)?'sl_crystals_balance show_team active':'sl_crystals_balance show_team'" 
                                  (click)="showTeam(fight, false, i)">
                                <span *ngIf="viewTeam && team==fight.team">Viewing...</span>
                                <span *ngIf="!viewTeam || team!=fight.team">Show Team</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="view === 'rewards'">
                <div *ngIf="!rewards">
                    <h5>No rewards found for this boss.</h5>
                </div>
                <div *ngIf="rewards">
                    <div *ngFor="let re of rewards">
                        <div>
                            <h3 [attr.title]="re.description">{{re.name}}</h3>
                            <h5>Rewards (each player may only win once per boss)</h5>
                            <ul>
                                <li>{{tier}} CRATE{{tier>1?'s':''}}</li>
                                <li>{{tier===4?'25k':tier===3?'15k':tier===2?'10k':'5k'}} XP</li>
                                <li>{{tier===4?'10k':tier===3?'6k':tier===2?'4k':'2k'}} Electrum</li>
                                <li>{{tier===4?'10':tier===3?'6':tier===2?'4':'2'}} Frags</li>
                            </ul>
                            <span>({{re.description}})</span>
                        </div>
                        <div *ngIf="re.winner && re.winner !== ''">
                            <span>
                                Winner: {{re.winner}} - 
                            </span>
                            <!--<span *ngIf="re.name === 'Last Rush'">
                                {{re.amount}} damage in last 100 fights
                            </span>
                            <span *ngIf="re.name === 'No punches pulled!'">
                                {{re.amount}} average damage
                            </span>-->
                            <span *ngIf="re.name === 'Take a stab at it!'">
                                {{re.amount}} fights
                            </span>
                        </div>
                        <div *ngIf="!re.winner || re.winner === ''">
                            <h5>The winner for this reward will be determined after the boss is dead.</h5>
                            <!--<span *ngIf="re.name === 'Last Rush'">
                                Just for fun, you can <span><button class="button purple" (click)="predictWinner('last 100')">calculate</button></span> who would win now IF the boss was dead. Pretend Winner: {{calculatedWinner}}
                            </span>
                            <span *ngIf="re.name === 'No punches pulled!'">
                                Current leader: {{highestAvg}}
                            </span>-->
                            <span *ngIf="re.name === 'Take a stab at it!'">
                                Just for fun, you can <span><button class="button purple" (click)="predictWinner('random')">generate</button></span> a random winner as if the boss was dead. Pretend Winner: {{generatedWinner}}
                            </span>
                        </div>
                    </div>
                </div>
                
            </div>
            <div *ngIf="view !== 'rewards'" class="prev_next">
                <div class="control previous" *ngIf="b>0" (click)="prev_b_page()">
                    <i class="fa-solid fa-chevron-left"></i>
                    <span>Previous</span>
                </div>
                <div [attr.class]="b>0?'white control center':'control center'">
                    {{b+1}}-{{b+b_limit>(view === 'standings' ? boss.standings.length : boss.battles.length)?(view === 'standings' ? boss.standings.length : boss.battles.length):b+b_limit}} / 
                    {{view === 'standings' ? boss.standings.length : boss.battles.length}}
                </div>
                <div class="control next" *ngIf="b+b_limit<(view === 'standings' ? boss.standings.length : boss.battles.length)" (click)="next_b_page()">
                    <span>Next</span>
                    <i class="fa-solid fa-chevron-right"></i>
                </div>
                <div class="clear"></div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="team && result">
    <team-results 
        [player]="result.player"
        [totalDmg]="result.totalDmg"
        [rules]="result.uniqueRules"
        [points]="result.points"
        [gear]="result.deckPower"
        [team]="team"
        [show]="viewTeam"
        (watch)="watchReplay()"
        (done)="closeTeamModal()"
    ></team-results>
</div>