import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { OpenPacksComponent } from './openpacks/openpacks.component';
import { SLCardsComponent } from './slcards/slcards.component';
import { ProfileComponent } from './profile/profile.component';
import { ReplayComponent } from './replay/replay.component';
import { CardsComponent } from './cards/cards.component';
import { ShopComponent } from './shop/shop.component';
import { HeroComponent } from './hero/hero.component';
import { FaqsComponent } from './faqs/faqs.component';
import { HomeComponent } from './home/home.component';
import { SurvivalComponent } from './survival/survival.component';
import { BattleComponent } from './battle/battle.component';
const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'home', component: HomeComponent },
    { path: 'shop', component: ShopComponent },
    { path: 'faqs', component: FaqsComponent },
    { path: 'battle', component: BattleComponent },
    { path: 'cards', component: CardsComponent },
    { path: 'cards/:id', component: CardsComponent },
    { path: 'cards/:detail_id', component: CardsComponent },
    { path: 'slcards', component: SLCardsComponent },
    { path: 'slcards/:replay_id', component: SLCardsComponent },
    { path: 'replay', component: ReplayComponent },
    { path: 'replay/:id', component: ReplayComponent },
    { path: 'roadmap', component: ShopComponent },
    { path: 'profile', component: ProfileComponent },
    { path: 'leaderboard', component: LeaderboardComponent },
    { path: 'openpacks', component: OpenPacksComponent },
    { path: 'heroes', component: HeroComponent },
    { path: 'survival', component: SurvivalComponent },
    { path: '**', redirectTo: 'home' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
