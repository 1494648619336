<section class="forgemodal" [@fade]="show">
	<div class="boxwrap" [@flip_up]="show">
		<h1>Welcome to Splinterforge</h1>
		<div>
			<div>
				<h1 class="red">Latest News</h1>
				<p class="notewrapper">
					Most recent update items listed below.
					For any questions/comments or just to hang out with the Splinterforge community, please visit our <a target="_blank" href="https://discord.gg/umMfWvu2v7">discord</a> server
				</p>
				<div class="itemwrapper" *ngIf="news">
					<div *ngFor="let n of news">
						<h1>{{n.date}}</h1>
						<h5 *ngIf="news.memo">{{n.memo}}</h5>
						<ul>
							<li class="itemwrapper" *ngFor="let item of n.items">{{item}}</li>
						</ul>	
					</div>
				</div>
			</div>
		</div>
		<div class="closewrapper" (click)="close()"><i class="fa-solid fa-x"></i></div>
	</div>
	<div class="backdrop" [@pointer]="show" (click)="close()"></div>
</section>