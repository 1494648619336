import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AppComponent } from '../app.component'
import { filters_show } from '../app.animations'
@Component({
  selector: 'app-notification',
  animations: [filters_show],
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.styl']
})
export class NotificationComponent implements OnInit {
  constructor(
    public app: AppComponent
  ) { }
  @Output('done') done = new EventEmitter();
  @Input('show') show_notifications: any;
  @Input('notifications') notifications: any;
  ngOnInit(): void {
    //console.log(this.notifications);
  }
  close() {
    this.show_notifications = false
    this.done.emit(0);
  }
}
