import { Component, OnInit } from '@angular/core';
import { User } from '@app/_models';
import { Router } from '@angular/router';
import { DataService } from '@app/_services';
import { SoundsService } from '../_services/sounds.service';
import { HelperService } from '@app/_services/helper.service';
import { Options, LabelType } from '@angular-slider/ngx-slider';
@Component({ 
    templateUrl: 'profile.component.html', 
    styleUrls: [
        '../leaderboard/leaderboard.component.styl',
        './profile.component.styl'
    ]
})
export class ProfileComponent implements OnInit {
    userStats: any;
    skins: any;
    skin: any;
    stake: any;
    user: User;
    tabname: string = '';
    receiver: string = '';
    loading: Boolean = false;
    viewCard: Boolean = false;
    showHIVETransfer: Boolean = false;
    amount: number = 0;
    unstakeAmount: number = 0;
    pendingUnstake: number = 0;
    forgeDEC: number = 0;
    forgeSwapHive: number = 0;
    totalStakeInfo: any;
    expected: number = 0;
    rewardHistory = [];
    unstakes = [];
    sctx: any;
    tx_start=0;
    tx_limit=10;
    alphaExclusive: any;
    alphaCrateStats: any;
    alphaCrateAirdrops: any;
    alphaBagStats: any;
    alphaBagAirdrops: any;
    userAirdropInfo: any;
    balance = 0;
    audio_quality: any;
    selectedPack: any;
    loaded=[];
    b_limit = 0;
    VolumeControls: Options = {
        ceil: 0,
        floor: -100,
        vertical: false,
        translate: (value: number, label: LabelType): string => {
            switch (label) {
                case LabelType.Low:
                    return "";
                break;
                case LabelType.High:
                    return "";
                break;
                default:
                    return "";
                break;
            }
        }
    };
    constructor(
        private router: Router,
        private sound: SoundsService,
        private helper: HelperService, 
        private data: DataService
    ) { }
    ngOnInit() {
        this.data.onUserChange().subscribe((u) => {
            this.user = u;
            this.user.sc.balance = Math.floor(this.user.sc.balance);
            this.user.inGameAssets = this.user.inGameAssets.filter(x => {
                return ['Electrum','XP','Evolution Essence','Relic Fragment','Enhancement Pack','Alpha Pack'].includes(x.name) || x.qty > 0;
            })
            let found = this.user.inGameAssets.find(x => x.name === 'XP');
            if (!found) {
                this.user.inGameAssets.push({
                    type: 'xp',
                    name: 'XP',
                    qty: 0,
                    max: 0,
                    description: 'Used to level heroes.',
                    symbol: 'XP'
                })
            }
            found = this.user.inGameAssets.find(x => x.name === 'Evolution Essence');
            if (!found) {
                this.user.inGameAssets.push({
                    type: 'essence',
                    name: 'Evolution Essence',
                    qty: 0,
                    max: 0,
                    description: 'Used to Evolve heroes to higher rank.',
                    symbol: 'EE'
                })
            }
            found = this.user.inGameAssets.find(x => x.name === 'Relic Fragment');
            if (!found) {
                this.user.inGameAssets.push({
                    type: 'fragment',
                    name: 'Relic Fragment',
                    qty: 0,
                    max: 0,
                    description: 'Used to craft Relics.',
                    symbol: 'RFRAG'
                })
            }
        });
        this.data.onLoadingChange().subscribe(l => this.loading = l);
        this.data.setUser(this.helper.currentUser);
        //this.loadSkins();
        this.openTab('firstCall','Inventory');
        this.getScTx();
        this.getStakeInfo();
        //console.log(this.alphaCrateAirdrops);
        this.getPackStats();
        this.sound.get_music_volume((v)=>{
            this.music_vol=v*100;
            console.log('sound get_music_volume', v, this.music_vol)
            this.mute_music=true;
            if(this.music_vol==-100)this.mute_music=false;
        });
        this.sound.audio_qual((q)=>{
            console.log('audio_qual',q);
            this.audio_quality = q;
        });
        this.sound.get_sound_volume((v)=>{
            this.mute_sounds=true;
            this.sound_vol=v*100;
            if(this.sound_vol==-100)this.mute_sounds=false;
        });
        //this.xpStakingScale = this.getXPStakingScale();
    }
    music_vol: number;
    sound_vol: number;
    mute_music: boolean;
    mute_sounds: boolean;
    toggle_sound_volume() {
        this.sound_vol=-100;
        this.sound_volume();
    }
    toggle_music_volume() { 
        this.music_vol=-100;
        this.music_volume();
    }
    blip: any;
    blip_sound(e?){
        // this.blip = this.sound.play_sfx('tone','play','sfx',false);
        // console.log('blip_sound');
    }
    blip_music(e?){
        // this.blip = this.sound.play_sfx('tone','play','music',false);
        // setTimeout(()=>{this.sound.stop_music()},3000);
        // console.log('blip_music');
    }
    music_volume(e?) {
        this.mute_music=true;
        this.sound.set_music_volume(this.music_vol);
        if(this.music_vol==-100)this.mute_music=false;
        // if(!this.sound.music&&this.mute_music)this.sound.start_music();
    }
    sound_volume(e?) {
        this.mute_sounds=true;
        this.sound.set_sound_volume(this.sound_vol);
        if(this.sound_vol==-100)this.mute_sounds=false;
    }
    sound_volume_end(e?) {
        this.blip_sound();
    }
    music_volume_end(e?) {
        this.blip_music();
    }
    
    async getPackStats() {
        this.alphaCrateStats = await this.helper.getPackStats('CRATE');
        this.alphaBagStats = await this.helper.getPackStats('BAG');
        //console.log(this.alphaCrateStats, this.alphaBagStats);
    }

    openTab(event, tabName) {
        this.tabname = tabName;
        // Declare all variables
        var i, tabcontent, tablinks;

        // Get all elements with class="tabcontent" and hide them
        tabcontent = document.getElementsByClassName("tab_container");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        // Get all elements with class="tablinks" and remove the class "active"
        if (event !== 'firstCall') {
            tablinks = document.getElementsByClassName("tablinks");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            event.currentTarget.className += " active";
        }
        // Show the current tab, and add an "active" class to the button that opened the tab
        document.getElementById(tabName).style.display = "block";

        if(tabName=='Profile') {
            this.loaded=[];
            this.tx_start=0;
            // this.stagger_openings();
        }
    }
    calcReward(s) {
        let rateScale = [
            { lower: 0, upper: 250000, rate: 125 },
            { lower: 250000, upper: 500000, rate: 120 },
            { lower: 500000, upper: 750000, rate: 115 },
            { lower: 750000, upper: 1000000, rate: 110 },
            { lower: 1000000, upper: 1250000, rate: 105 },
            { lower: 1250000, upper: 1500000, rate: 100 },
            { lower: 1500000, upper: 1750000, rate: 95 },
            { lower: 1750000, upper: 2000000, rate: 100 },
            { lower: 2000000, upper: 2250000, rate: 105 },
            { lower: 2250000, upper: 2500000, rate: 110 },
            { lower: 2500000, upper: 2750000, rate: 115 },
            { lower: 2750000, upper: 3000000, rate: 120 },
            { lower: 3000000, upper: 3250000, rate: 125 },
            { lower: 3250000, upper: 3500000, rate: 130 },
            { lower: 3500000, upper: 3750000, rate: 135 },
            { lower: 3750000, upper: 4000000, rate: 140 },
            { lower: 4000000, upper: 4250000, rate: 145 },
            { lower: 4250000, upper: 4500000, rate: 150 },
            { lower: 4500000, upper: 4750000, rate: 155 },
            { lower: 4750000, upper: 5000000, rate: 160 },
            { lower: 5000000, upper: 5250000, rate: 170 },
            { lower: 5250000, upper: 5500000, rate: 180 },
            { lower: 5500000, upper: 5750000, rate: 190 },
            { lower: 5750000, upper: 6000000, rate: 200 }
        ];
        let total = 0;
        let reward = 0;
        let stake = s;
        let rate = stake >= 250000 ? -1 : 125; // rateScale.find(x => { return stakers[i].stake >= x.lower && stakers[i].stake < x.upper });
        if (!rate || rate === -1) {
            for (let r = 0; r < rateScale.length; r++) {
                let rate = rateScale[r].rate;
                let remove = r*250000;
                let portion = stake - remove;
                //console.log(rate, remove, portion);
                if (portion > 0) {
                    if (portion > 250000) portion = 250000;
                    reward += Math.round(portion/rate);
                }
            }
            if (stake > 6000000) {
                let portion = stake - 6000000;
                reward += Math.round(portion/200);
            }
        } else {
            reward = Math.round(stake/rate);
        }
        this.expected = reward;
        //console.log(reward);
        //return reward;
    }
    click_item(asset) {
        if(asset.name.indexOf('Stamina') > -1 && asset.qty > 0) this.useConsumable(asset);
        if(asset.name.indexOf('Pack') > -1 && asset.qty > 0) this.navigate('openpacks');
        if(asset.name.indexOf('Stamina') > -1 && asset.qty == 0) this.navigate('shop');
        if(asset.name=='Electrum') this.navigate('slcards');
        //console.log('Asset', asset);
    }
    async getScTx() {
        this.sctx = await this.helper.getScTx();
        if(this.sctx && this.sctx.length > 0){
            // this.stagger_openings();
            //this.sctx.sort((a,b)=> {
            //    return b.timestamp > a.timestamp ? 1 : -1
            //});
            this.sctx.forEach(t => {
                this.b_limit = this.b_limit+1;
                t.timestamp = new Date(t.timestamp).toUTCString();
                if (t.type === 'p2ptransfer') {
                    if (t.qty < 0) t.type = 'Transferred ' + Math.abs(t.qty) + ' FORGE to ' + t.to.toUpperCase();
                    else t.type = 'Received ' + Math.abs(t.qty) + ' FORGE from ' + t.from.toUpperCase();
                }
            });
        }
    }
    async getStakeInfo() {
        this.stake = await this.helper.getStakeInfo();
        if (!this.stake || this.stake.message !== 'success') {
            this.helper.showSnackbarMessage('Unable to load staking information...');
        } else {
            this.stake = this.stake.stake;
            this.unstakes = [];
            this.pendingUnstake = 0;
            this.calcReward(this.stake.stake);
            this.stake.stakes.forEach(x => {
                if (x.type === 'unstake') {
                    for (let i = 0; i < x.schedule.length; i++) {
                        if (x.schedule[i].sctx === null) {
                            let found = this.unstakes.find(us => us.date === x.date);
                            if (!found) this.unstakes.push(x);
                            this.pendingUnstake += x.schedule[i].qty;
                            //i = x.schedule.length;
                        }
                    }
                }
            })
            
            //console.log(this.stake.stake, typeof(this.stake.stake));
        }
        let decForgeShare = await this.helper.loadForgeDECShares();
        if (decForgeShare) {
            let found = decForgeShare.find(x => x.account === this.helper.currentUser.username);
            if (found) this.forgeDEC = Math.round(found.shares);
        }
        //console.log(decForgeShare);
        let hiveForgeShare = await this.helper.loadForgeSwapHiveShares();
        if (hiveForgeShare) {
            let found = hiveForgeShare.find(x => x.account === this.helper.currentUser.username);
            if (found) this.forgeSwapHive = Math.round(found.shares);
        }
        //console.log(hiveForgeShare)

        let stakeTotals: any = await this.helper.getTotalStakeInfo();
        if (stakeTotals && stakeTotals.message === 'success') this.totalStakeInfo = stakeTotals;
        //console.log(stakeTotals);

        let tempHistory: any = await this.helper.xpRewardHistory();
        if (tempHistory && tempHistory.message === 'success') {
            this.rewardHistory = tempHistory.history;
            this.rewardHistory.forEach(x => {
                if (x.type === 'xp distribution') {
                    x.entry = ': ' + x.xp + ' XP for staking ' + x.stake + ' FORGE';
                } else if (x.type === 'dec lp xp distribution') {
                    x.entry = ': ' + x.xp + ' XP for ' + (x.shares ? x.shares : 'N/A') + ' shares in the DEC:FORGE LP';
                } else if (x.type === 'swaphive lp xp distribution') {
                    x.entry = ': ' + x.xp + ' XP for ' + (x.shares ? x.shares : 'N/A') + ' shares in the SWAP.HIVE:FORGE LP';
                }
            })
        }
    }

    getXPStakingScale() {
        let levels = [
            '0 to 250k FORGE = 1xp for every 125 FORGE staked',
            '250k to 500k FORGE = 1xp for every 120 FORGE staked',
            '500k to 750k FORGE = 1xp for every 115 FORGE staked',
            '750k to 1 Million FORGE = 1xp for every 110 FORGE staked',
            '1 Million to 1.25 Million FORGE = 1xp for every 105 FORGE staked',
            '1.25 Million to 1.5 Million FORGE = 1xp for every 100 FORGE staked',
            '1.5 Million to 1.75 Million FORGE = 1xp for every 95 FORGE staked',
            '1.75 Million to 2 Million FORGE = 1xp for every 100 FORGE staked',
            '2 Million to 2.25 Million FORGE = 1xp for every 105 FORGE staked',
            '2.25 Million to 2.5 Million FORGE = 1xp for every 110 FORGE staked',
            '2.5 Million to 2.75 Million FORGE = 1xp for every 115 FORGE staked',
            '2.75 Million to 3 Million FORGE = 1xp for every 120 FORGE staked',
            '3 Million to 3.25 Million FORGE = 1xp for every 125 FORGE staked',
            '3.25 Million to 3.5 Million FORGE = 1xp for every 130 FORGE staked',
            '3.5 Million to 3.75 Million FORGE = 1xp for every 135 FORGE staked',
            '3.75 Million to 4 Million FORGE = 1xp for every 140 FORGE staked',
            '4 Million to 4.25 Million FORGE = 1xp for every 145 FORGE staked',
            '4.25 Million to 4.5 Million FORGE = 1xp for every 150 FORGE staked',
            '4.5 Million to 4.75 Million FORGE = 1xp for every 155 FORGE staked',
            '4.75 Million to 5 Million FORGE = 1xp for every 160 FORGE staked',
            '5 Million to 5.25 Million FORGE = 1xp for every 17 FORGE staked',
            '5.25 Million to 5.5 Million FORGE = 1xp for every 180 FORGE staked',
            '5.5 Million to 5.75 Million FORGE = 1xp for every 190 FORGE staked',
            'Over 5.75 Million = 1xp for every 200 FORGE staked'
        ]
        let title = 'XP amount based on how much staked:\n';
        for (let i = 0; i < levels.length; i++) {
            title += levels[i] + (i < levels.length-1 ? '\n' : '');
        }
        return title;
    }

    async stakeForge() {
        let res: any = await this.helper.stakeForge(this.amount);
        if (res.message === 'success') {
            await this.getStakeInfo();
        } else this.helper.showSnackbarMessage(res.message);
    }
    async unstakeForge() {
        if (this.stake.stake - this.pendingUnstake < this.unstakeAmount) {
            this.helper.showSnackbarMessage('Staked FORGE minus pending unstakes is less than requested unstake amount.');
            return;
        }
        let res: any = await this.helper.unstakeForge(this.unstakeAmount);
        if (res.message === 'success') {
            await this.getStakeInfo();
        } else this.helper.showSnackbarMessage(res.message);        
    }
    async openTransfer(selectedPack) {
        let balances = await this.helper.loadBalances(this.user.username);
        this.selectedPack = selectedPack;
        let found = balances.find(b => b.symbol === this.selectedPack.symbol);
        if (found) this.balance = found.balance;
        else this.balance = 0;
        this.showHIVETransfer = true;
    }
    async loadSkins() {
        //this.skins = await this.helper.getSkins();
        //console.log(this.skins);
    }
    navigate(loc) {
      this.helper.navigate(loc);
    }
    async transferSkin() { // check receiver exists happens in helper function
        if (this.skin && this.receiver.length > 0) {
            let temp = await this.helper.transferSkins(this.skin.card_detail_id, this.receiver);
            console.log(temp);
        }
    }
    stagger_openings(i=0) {
        setTimeout(()=>{
            this.loaded[i]=true;
            if(i<this.b_limit) this.stagger_openings(i+1);
        },40);
    }
    set_audio_quality(setting) {
        localStorage.setItem('audio_qual',setting);
        this.audio_quality = setting;
        this.sound.get_audio_quality();
    }
    transferAsset(item) {
        //console.log('transfer Asset',item);
        this.showHIVETransfer=true;
    }
    selectSkin(skin) {
        this.skin = skin;
    }
    closeSkinModal() {
        this.skin = null;
    }
    next_tx_page() { 
        this.loaded=[];
        this.tx_start=(this.tx_start+this.tx_limit<this.sctx.length)?this.tx_start+this.tx_limit:this.tx_start; 
        // this.stagger_openings();
    }
    prev_tx_page() { 
        this.loaded=[];
        this.tx_start=(this.tx_start>0)?this.tx_start-this.tx_limit:this.tx_start; 
        // this.stagger_openings();
    }
    async useConsumable(item) {
        if(item.symbol=="BAG"||item.symbol=="CRATE") {
            this.navigate('openpacks'); 
        } else {
            this.data.setLoading(true);
            let temp = await this.helper.useConsumable(item, 1);
            this.data.setLoading(false);    
        }
    }
    logout() {
        this.data.setUser(null);
        this.helper.navigate('');
        localStorage.removeItem('forge:key');
    }
}
