<div class="top">
    <div [attr.class]="open_log?'open logbox player_box_left':'logbox player_box_left'" id="PlayerLogBox">
        <div [@fade]="in_battle?false:true" class="battle_log">Battle Log</div>
        <div class="log_entries" #scrollbox (scroll)="logScrolled($event)">
            <div *ngFor="let entry of log_entries; let i = index;" 
                [attr.class]="(entry.type=='action'&&entry.action!='preamble')?'card_action':entry.action=='preamble'?'preamble':'none'">
                
                <div>
                    <span>{{entry.action | capitalize}}</span>
                </div>
            <!--
                <div *ngIf="entry.action && (!((entry.dmgType === 'ranged'||entry.dmgType === 'attack'||entry.dmgType === 'magic')||entry.value>0))" 
                    [attr.class]="((entry.turn=='post fight'||entry.turn==boss.name)?'logentry boss first_log _one':'logentry player first_log _one') + (entry.type=='action'&&entry.turn=='post fight'?' post_fight':'')">
                    <div class="leftside leftcards_0">
                        <div class="play_card" *ngIf=":entry.turn && entry.turn!='post fight' && entry.type!='ability' && entry.action!='preamble' && entry.target && entry.dmgType!='team' && entry.dmgType!='death'">
                            <div *ngFor="let card of play.team | name:entry.turn" [@appear]>
                                <div class="log_card log_card_1">
                                    <splinterforge-card
                                        *ngIf="play.player==card.name"
                                        class="hero_card_left"
                                        [hero]="card"
                                        [attr_name]="false"
                                        [attr.id]="'player_boss_card first_card'"
                                        [currentClass]="currentClass"
                                        [effects]="false"
                                        [summoner]="true"
                                        [smaller]="true"
                                        [rules_box]="false"
                                        [user]="user">
                                    </splinterforge-card>
                                    <splinterforge-card
                                        *ngIf="play.player!=card.name"
                                        class="small_box splinterforge_8"
                                        [attr_name]="false"
                                        [effects]="false"
                                        [small]="true"
                                        [boss]="false"
                                        [rules_box]="false"
                                        [player]="(play.player==entry.turn)?true:false"
                                        [card]="card">
                                    </splinterforge-card>
                                </div>
                            </div>
                        </div>
                        <span *ngIf="entry.type=='ability'" class="stat abilities">
                            <card-abilities
                                [show_card_abilities]="false"
                                [abilities]="[entry.ability]">
                            </card-abilities>
                        </span>
                        <span *ngIf="entry.action=='preamble'" class="stat battle">
                            <div class="icon">
                                <img src="/assets/menu/menu-boss.png" width="25" height="25" loading="lazy">
                            </div>
                        </span>
                        <span *ngIf="entry.action=='plus armor'||entry.action=='minus armor'" class="dmgtype armor">
                            <div class="icon">
                                <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/defense.png" width="40" height="40" loading="lazy">
                            </div>
                            <div *ngIf="entry.action=='plus armor'" class="overlay">+</div>
                            <div *ngIf="entry.action=='minus armor'" class="overlay">-</div>
                        </span>
                        <span *ngIf="entry.action=='plus speed'||entry.action=='minus speed'" class="dmgtype speed">
                            <div class="icon">
                                <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/speed.png" width="40" height="40" loading="lazy">
                            </div>
                            <div *ngIf="entry.action=='plus speed'" class="overlay">+</div>
                            <div *ngIf="entry.action=='minus speed'" class="overlay">-</div>
                        </span>
                        <span *ngIf="entry.action=='plus magic'||entry.action=='minus magic'" class="dmgtype magic">
                            <div class="icon">
                                <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/magic-attack.png" width="40" height="40" loading="lazy">
                            </div>
                            <div *ngIf="entry.action=='plus magic'" class="overlay">+</div>
                            <div *ngIf="entry.action=='minus magic'" class="overlay">-</div>
                        </span>
                        <span *ngIf="entry.action=='plus health'||entry.action=='minus health'" class="dmgtype health">
                            <div class="icon">
                                <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/health.png" width="40" height="40" loading="lazy">
                            </div>
                            <div *ngIf="entry.action=='plus health'" class="overlay">+</div>
                            <div *ngIf="entry.action=='minus health'" class="overlay">-</div>
                        </span>
                        <span *ngIf="entry.action=='plus attack'||entry.action=='minus attack'" class="dmgtype attack">
                            <div class="icon">
                                <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/attack.png" width="40" height="40" loading="lazy">
                            </div>
                            <div *ngIf="entry.action=='plus attack'" class="overlay">+</div>
                            <div *ngIf="entry.action=='minus attack'" class="overlay">-</div>
                        </span>
                        <span *ngIf="entry.action=='plus ranged'||entry.action=='minus ranged'" class="dmgtype ranged">
                            <div class="icon">
                                <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/ranged.png" width="40" height="40" loading="lazy">
                            </div>
                            <div *ngIf="entry.action=='plus ranged'" class="overlay">+</div>
                            <div *ngIf="entry.action=='minus ranged'" class="overlay">-</div>
                        </span>
                        <div *ngIf="entry.turn==boss.name || entry.turn=='post fight'">
                            <div>
                                <splinterforge-card
                                    class="boss__ boss_box" 
                                    [boss]="boss"
                                    [attr_name]="false"
                                    [rules_box]="false"
                                    [effects]="false">
                                </splinterforge-card>
                            </div>
                        </div>
                        <div *ngIf="play && play.team && entry.dmgType!='poison' && entry.turn!='post fight' && entry.dmgType!='miss' && entry.dmgType!='ranged_miss' && entry.dmgType!='heal' && entry.dmgType!='heal_failed' && play && play.team && entry.action!='preamble' && entry.turn!=boss.name"
                             [attr.class]="entry.turn=='DEATH'?'dead_card':'resurrected_card'">
                            <div *ngFor="let card of play.team | name:entry.target">
                                <splinterforge-card
                                    class="small_box hero_card_adj splinterforge_1"
                                    *ngIf="play.player==card.name"
                                    [hero]="card"
                                    [attr.id]="'player_boss_card third_card'"
                                    [currentClass]="currentClass"
                                    [summoner]="true"
                                    [smaller]="true"
                                    [attr_name]="false"
                                    [effects]="false"
                                    [rules_box]="false"
                                    [dead]="entry.turn=='DEATH'?true:false"
                                    [user]="user">
                                </splinterforge-card>
                                <splinterforge-card
                                    *ngIf="play.player!=card.name"
                                    class="small_box splinterforge_1"
                                    [attr_name]="false"
                                    [small]="true"
                                    [boss]="false"
                                    [effects]="false"
                                    [rules_box]="false"
                                    [dead]="entry.turn=='DEATH'?true:false"
                                    [player]="(play.player==entry.turn)?true:false"
                                    [card]="card">
                                </splinterforge-card>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="entry.action" [attr.class]="!entry.turn||!entry.type?'log_entry_text left':'log_entry_text'">
                        <span *ngIf="entry.dmgType=='repair'||entry.dmgType=='repair_failed'||entry.type=='action'" class="action_span">{{entry.action|capitalize}}{{entry.action[entry.action.length-1]!='.'&&entry.action[entry.action.length-1]!='!'?'.':''}}</span>
                        <span class="log_text" *ngIf="(entry.type=='buff' || entry.type=='ability') && (entry.target != entry.turn)">
                            <span class="capitalize">
                                {{entry.action|capitalize}} on {{entry.target|capitalize}}
                            </span>
                            <div class="ability_desc" *ngIf="entry.type=='ability'">
                                {{entry.ability|abilities_description}}
                            </div>
                        </span>
                    </div>
                    <div [attr.class]="entry.target==boss.name?'rightside boss_container boss_1':'rightside'" *ngIf="entry.target">
                        <div class="splinterforge_3_container" *ngIf="(entry.dmgType!='retaliation_failed' && entry.turn==boss.name) || entry.target==boss.name">
                            <splinterforge-card
                                class="small_box boss_box splinterforge_3"
                                [boss]="boss"
                                [attr_name]="false"
                                [rules_box]="false"
                                [effects]="false">
                            </splinterforge-card>
                        </div>
                        <div *ngIf="entry.turn != 'pre fight' || entry.dmgType == 'heal_failed'">
                            <div *ngFor="let card of play.team | name:(entry.dmgType=='heal_failed'?entry.target:entry.turn)" [@appear]>
                                <div class="log_card log_card_2">
                                    <splinterforge-card
                                        *ngIf="entry.target==card.name && entry.dmgType!='heal_failed'"
                                        class="hero_card"
                                        [hero]="card"
                                        [attr.id]="'player_boss_card first_card'"
                                        [currentClass]="currentClass"
                                        [summoner]="true"
                                        [attr_name]="false"
                                        [rules_box]="false"
                                        [effects]="false"
                                        [smaller]="true"
                                        [user]="user">
                                    </splinterforge-card>
                                    <splinterforge-card
                                        *ngIf="entry.target==card.name && entry.dmgType=='heal_failed'"
                                        class="small_box splinterforge_4"
                                        [attr_name]="false"
                                        [small]="true"
                                        [boss]="false"
                                        [effects]="false"
                                        [rules_box]="false"
                                        [player]="(play.player==entry.turn)?true:false"
                                        [card]="card">
                                    </splinterforge-card>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
                <div *ngIf="(entry.action.indexOf('hit by ' + boss.name) > -1 || entry.action.indexOf('boss missed') > -1 || entry.action.indexOf('boss swipes') > -1 || entry.action.indexOf('DIED!') > -1 || entry.action.indexOf('poison') > -1 || entry.action.indexOf('enraged') > -1) && ((entry.dmgType === 'ranged' || entry.dmgType === 'attack' || entry.dmgType === 'magic') || entry.value > 0)" [attr.class]="entry.turn==boss.name?'logentry boss first_log _two':'logentry player first_log _two'">
                    <div class="leftside leftbuff_1" *ngIf="entry.type=='buff'">
                        <span *ngIf="dmgType=='armor'||dmgType=='armor'" class="stat armor">
                            <div class="icon">
                                <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/defense.png" width="40" height="40" loading="lazy">
                            </div>
                            <div *ngIf="dmgType=='armor'" class="overlay">+</div>
                            <div *ngIf="dmgType=='armor'" class="overlay">-</div>
                        </span>
                        <span *ngIf="dmgType=='speed'||dmgType=='speed'" class="stat magic">
                            <div class="icon">
                                <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/speed.png" width="40" height="40" loading="lazy">
                            </div>
                            <div *ngIf="dmgType=='speed'" class="overlay">+</div>
                            <div *ngIf="dmgType=='speed'" class="overlay">-</div>
                        </span>
                        <span *ngIf="dmgType=='magic'||dmgType=='magic'" class="stat magic">
                            <div class="icon">
                                <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/magic-attack.png" width="40" height="40" loading="lazy">
                            </div>
                            <div *ngIf="dmgType=='magic'" class="overlay">+</div>
                            <div *ngIf="dmgType=='magic'" class="overlay">-</div>
                        </span>
                        <span *ngIf="dmgType=='health'||dmgType=='health'" class="stat health">
                            <div class="icon">
                                <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/health.png" width="40" height="40" loading="lazy">
                            </div>
                            <div *ngIf="dmgType=='health'" class="overlay">+</div>
                            <div *ngIf="dmgType=='health'" class="overlay">-</div>
                        </span>
                        <span *ngIf="dmgType=='attack' || entry.dmgType=='retaliate' || dmgType==''" class="stat attack">
                            <div class="icon">
                                <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/attack.png" width="40" height="40" loading="lazy">
                            </div>
                            <div *ngIf="dmgType=='attack'" class="overlay">+</div>
                            <div *ngIf="dmgType=='attack'" class="overlay">-</div>
                        </span>
                        <span *ngIf="dmgType=='ranged'||dmgType=='ranged'" class="stat ranged">
                            <div class="icon">
                                <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/ranged.png" width="40" height="40" loading="lazy">
                            </div>
                            <div *ngIf="dmgType=='ranged'" class="overlay">+</div>
                            <div *ngIf="dmgType=='ranged'" class="overlay">-</div>
                        </span>
                        <div class="dmgtype {{entry.type=='buff'?'':'larger'}} {{entry.dmgType?entry.dmgType:'melee'}}">
                            <img *ngIf="entry.dmgType=='attack' || entry.dmgType=='backfire' || entry.dmgType=='retaliate' || entry.dmgType==''" 
                                 src="https://d36mxiodymuqjm.cloudfront.net/website/stats/melee-attack.png" width="40" height="40" loading="lazy">
                            <img *ngIf="entry.dmgType=='range' || entry.dmgType=='ranged'" 
                                 src="https://d36mxiodymuqjm.cloudfront.net/website/stats/ranged-attack.png" width="40" height="40" loading="lazy">
                            <img *ngIf="entry.dmgType=='magic'" 
                                 src="https://d36mxiodymuqjm.cloudfront.net/website/stats/magic-attack.png" width="40" height="40" loading="lazy">
                            <img *ngIf="entry.dmgType=='armor'" 
                                 src="https://d36mxiodymuqjm.cloudfront.net/website/stats/armor.png" width="40" height="40" loading="lazy">
                            <img *ngIf="entry.dmgType=='health'" 
                                 src="https://d36mxiodymuqjm.cloudfront.net/website/stats/health.png" width="40" height="40" loading="lazy">
                            <img *ngIf="entry.dmgType=='speed'" 
                                 src="https://d36mxiodymuqjm.cloudfront.net/website/stats/speed.png" width="40" height="40" loading="lazy">
                            <div class="value">{{entry.value}}</div>
                        </div>
                    </div>
                    <div class="leftside leftcards_1" *ngIf="entry.type!='buff'">
                        <div *ngFor="let card of play.team | name:entry.turn" [@appear]>
                            <div class="log_card log_card_3">
                                <splinterforge-card
                                    class="small_box splinterforge_5"
                                    [attr_name]="false"
                                    [currentClass]="currentClass"
                                    [player]="(play.player==entry.turn)?true:false"
                                    [rules_box]="false"
                                    [effects]="false"
                                    [boss]="card">
                                </splinterforge-card>
                            </div>
                        </div>
                        <span *ngIf="entry.type=='ability'" class="stat abilities">
                            <card-abilities
                                [show_card_abilities]="false"
                                [abilities]="[entry.ability]">
                            </card-abilities>
                        </span>
                        <splinterforge-card
                            class="boss__ boss_box"
                            [boss]="boss"
                            [attr_name]="false"
                            [rules_box]="false"
                            [effects]="false">
                        </splinterforge-card>
                    </div>
                    <div *ngIf="entry.action" [attr.class]="entry.turn=='DEATH'||!entry.turn||!entry.type?'log_entry_text left':'log_entry_text'">
                        <span *ngIf="(entry.target==entry.turn)">{{entry.action | capitalize}}</span>
                        <span *ngIf="entry.dmgType=='repair'||entry.dmgType=='repair_failed'">{{entry.action}}{{entry.action[entry.action.length-1]!='.'&&entry.action[entry.action.length-1]!='!'?'.':''}}</span>
                        <span *ngIf="entry.type!='ability' && entry.type=='action' && (entry.target != entry.turn) && entry.dmgType!='heal'">
                            {{entry.turn | capitalize}} hit {{entry.target | capitalize}} with 
                            <b *ngIf="entry.value==0">no</b> 
                            <span *ngIf="entry.value>0" 
                                class="dmgtype inline {{entry.dmgType ? entry.dmgType : 'melee'}}">
                                <img *ngIf="entry.dmgType == 'attack'||entry.dmgType == 'melee'" src="https://d36mxiodymuqjm.cloudfront.net/website/stats/melee-attack.png" loading="lazy">
                                <img *ngIf="entry.dmgType == 'ranged'" src="https://d36mxiodymuqjm.cloudfront.net/website/stats/ranged-attack.png" loading="lazy">
                                <img *ngIf="entry.dmgType == 'magic'" src="https://d36mxiodymuqjm.cloudfront.net/website/stats/magic-attack.png" loading="lazy">
                                <div class="value">{{entry.value}}</div>
                            </span> damage.
                        </span>
                        <span *ngIf="entry.type!='ability' && entry.type=='action' && (entry.target != entry.turn) && entry.dmgType=='heal'">
                            {{entry.target==entry.turn?'':entry.turn|capitalize}} 
                            {{entry.action.includes('life leeched')?'life leeched':'healed'}} 
                            {{entry.target | capitalize}} with 
                            <span class="dmgtype inline heal">
                                <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/health.png" loading="lazy">
                                <div class="value">{{entry.value}}</div>
                            </span> health.
                        </span>
                        <span class="log_text" *ngIf="entry.dmgType!='heal' && entry.dmgType!='repair' && (entry.type=='buff' || entry.type=='ability') && (entry.target != entry.turn)">
                            <span class="capitalize">{{entry.action|capitalize}} on {{entry.target | capitalize}}</span>
                            <div class="ability_desc" *ngIf="entry.type=='ability'">{{entry.ability|abilities_description}}</div>
                        </span>
                    </div>
                    <div [attr.class]="entry.target==boss.name?'rightside boss_container boss_2':'rightside'" *ngIf="entry.target">
                        <div *ngIf="entry.target==boss.name">
                            <splinterforge-card
                                class="small_box boss_box splinterforge_6"
                                [boss]="boss"
                                [rules_box]="false"
                                [attr_name]="false"
                                [effects]="false">
                            </splinterforge-card>
                        </div>
                        <div *ngFor="let card of play.team | name:(entry.target==boss.name)?boss.name:entry.target" [@appear]>
                            <div *ngIf="entry.target != boss.name" class="log_card log_card_4">
                                <splinterforge-card
                                    *ngIf="play.player==card.name"
                                    class="hero_card_adj"
                                    [hero]="card"
                                    [attr.id]="'player_boss_card second_card'"
                                    [currentClass]="currentClass"
                                    [attr_name]="false"
                                    [summoner]="true"
                                    [smaller]="true"
                                    [rules_box]="false"
                                    [effects]="false"
                                    [user]="user">
                                </splinterforge-card>
                                <splinterforge-card
                                    *ngIf="play.player!=card.name"
                                    class="small_box splinterforge_82"
                                    [attr_name]="false"
                                    [small]="true"
                                    [boss]="false"
                                    [rules_box]="false"
                                    [effects]="false"
                                    [player]="(play.player==entry.turn)?true:false"
                                    [card]="card">
                                </splinterforge-card>
                            </div>
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
               <div *ngIf="!(entry.action.indexOf('hit by ' + boss.name) > -1 || entry.action.indexOf('boss missed') > -1 || entry.action.indexOf('boss swipes') > -1 || entry.action.indexOf('DIED!') > -1 || entry.action.indexOf('poison') > -1 || entry.action.indexOf('enraged') > -1) && ((entry.dmgType === 'ranged' || entry.dmgType === 'attack' || entry.dmgType === 'magic') || entry.value > 0)" [attr.class]="entry.turn==boss.name?'logentry boss first_log _three':'logentry player first_log _three'">
                    <div class="leftside leftbuffs_2" *ngIf="entry.type=='buff'">
                        <div class="dmgtype {{entry.type=='buff'?'':'larger'}} {{entry.dmgType?entry.dmgType:'melee'}}">
                            <img *ngIf="entry.dmgType=='attack' || entry.dmgType=='retaliate' || entry.dmgType==''" 
                                 src="https://d36mxiodymuqjm.cloudfront.net/website/stats/melee-attack.png" width="40" height="40" loading="lazy">
                            <img *ngIf="entry.dmgType == 'range' || entry.dmgType == 'ranged'"
                                  src="https://d36mxiodymuqjm.cloudfront.net/website/stats/ranged-attack.png" width="40" height="40" loading="lazy">
                            <img *ngIf="entry.dmgType == 'magic'" 
                                 src="https://d36mxiodymuqjm.cloudfront.net/website/stats/magic-attack.png" width="40" height="40" loading="lazy">
                            <img *ngIf="entry.dmgType == 'armor'" 
                                 src="https://d36mxiodymuqjm.cloudfront.net/website/stats/armor.png" width="40" height="40" loading="lazy">
                            <img *ngIf="entry.dmgType == 'health'" 
                                 src="https://d36mxiodymuqjm.cloudfront.net/website/stats/health.png" width="40" height="40" loading="lazy">
                            <img *ngIf="entry.dmgType == 'speed'" 
                                 src="https://d36mxiodymuqjm.cloudfront.net/website/stats/speed.png" width="40" height="40" loading="lazy">
                            <div class="value">{{entry.value}}</div>
                        </div> 
                    </div>
                    <div class="leftside leftcard_2" *ngIf="entry.type!='buff'">
                        <div *ngIf="entry.turn != 'pre fight'">
                            <div *ngIf="entry.turn==boss.name">
                                <splinterforge-card
                                    class="small_box boss_box splinterforge_9"
                                    [boss]="boss"
                                    [attr_name]="false"
                                    [rules_box]="false"
                                    [effects]="false">
                                </splinterforge-card>
                            </div>
                            <div *ngFor="let card of play.team | name:(entry.turn==boss.name)?boss.name:entry.turn" [@appear]>
                                <div class="log_card log_card_5">
                                    <splinterforge-card
                                        *ngIf="play.player==card.name"
                                        [hero]="card"
                                        [attr.id]="'player_boss_card third_card'"
                                        [attr_name]="false"
                                        [currentClass]="currentClass"
                                        [summoner]="true"
                                        [effects]="false"
                                        [rules_box]="false"
                                        [smaller]="true"
                                        [user]="user">
                                    </splinterforge-card>
                                    <splinterforge-card
                                        *ngIf="play.player!=card.name"
                                        class="small_box splinterforge_83"
                                        [attr_name]="false"
                                        [small]="true"
                                        [effects]="false"
                                        [boss]="false"
                                        [rules_box]="false"
                                        [player]="(play.player==entry.turn)?true:false"
                                        [card]="card">
                                    </splinterforge-card>
                                </div>
                            </div>
                        </div>
                        <span *ngIf="entry.type=='ability'" class="stat abilities">
                            <card-abilities
                                [show_card_abilities]="false"
                                [abilities]="[entry.ability]">
                            </card-abilities>
                        </span>
                    </div>
                    <div *ngIf="entry.action" [attr.class]="entry.turn=='DEATH'||!entry.turn||!entry.type?'log_entry_text left':'log_entry_text'">
                        <span *ngIf="(entry.target==entry.turn && entry.dmgType!='heal') && (entry.dmgType!='heal') && (entry.dmgType!='repair')">{{entry.action|capitalize}}</span>
                        <span *ngIf="entry.dmgType=='repair'||entry.dmgType=='repair_failed' && (entry.dmgType!='heal')">{{entry.action|capitalize}}{{entry.action[entry.action.length-1]!='.'&&entry.action[entry.action.length-1]!='!'?'.':''}}</span>
                        <span *ngIf="entry.type=='action' && (entry.dmgType!='heal') && (entry.target != entry.turn) && (entry.turn==boss.name||entry.target==boss.name)">
                            {{entry.turn|capitalize}} hit {{entry.target | capitalize}} with 
                            <b *ngIf="entry.value==0">no</b>
                            <span *ngIf="entry.value>0" 
                                class="dmgtype inline {{entry.dmgType ? entry.dmgType : 'melee'}}">
                                <img *ngIf="entry.dmgType == 'attack'||entry.dmgType == 'melee'||entry.dmgType == 'retaliate' || entry.dmgType=='backfire'" src="https://d36mxiodymuqjm.cloudfront.net/website/stats/melee-attack.png" loading="lazy">
                                <img *ngIf="entry.dmgType == 'ranged'" src="https://d36mxiodymuqjm.cloudfront.net/website/stats/ranged-attack.png" loading="lazy">
                                <img *ngIf="entry.dmgType == 'magic'" src="https://d36mxiodymuqjm.cloudfront.net/website/stats/magic-attack.png" loading="lazy">
                                <div class="value">{{entry.value}}</div>
                            </span> damage.
                        </span>
                        <span *ngIf="entry.type!='ability' && entry.type=='action' && entry.dmgType=='heal'">
                            {{entry.target==entry.turn?'':entry.turn|capitalize}} 
                            {{entry.action.includes('life leeched')?'life leeched':'healed'}} 
                            {{entry.target | capitalize}} with 
                            <span class="dmgtype inline buff">
                                <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/health.png" loading="lazy">
                                <div class="value">{{entry.value}}</div>
                            </span> health.
                        </span>
                        <span class="log_text" *ngIf="(entry.type=='buff' || entry.type=='ability') && (entry.target != entry.turn) && (entry.dmgType!='heal')">
                            <span class="capitalize">{{entry.action|capitalize}} on {{entry.target|capitalize}}</span>
                            <div class="ability_desc" *ngIf="entry.type=='ability'">{{entry.ability|abilities_description}}</div>
                        </span>
                    </div>
                    <div [attr.class]="entry.target==boss.name?'rightside boss_container boss_3':'rightside'" *ngIf="entry.target">
                        <div *ngIf="entry.target==boss.name">
                            <splinterforge-card
                                class="small_box boss_box splinterforge_9"
                                [boss]="boss"
                                [attr_name]="false"
                                [rules_box]="false"
                                [effects]="false">
                            </splinterforge-card>
                        </div>
                        <div *ngIf="play">
                            <div *ngFor="let card of play.team | name:(entry.target==boss.name)?boss.name:entry.target"
                                 [@appear]>
                                <div class="log_card log_card_6">
                                    <splinterforge-card
                                        class="small_box hero_card_right splinterforge_10"
                                        *ngIf="play.player==card.name"
                                        [hero]="card"
                                        [attr.id]="'player_boss_card third_card'"
                                        [attr_name]="false"
                                        [currentClass]="currentClass"
                                        [summoner]="true"
                                        [effects]="false"
                                        [rules_box]="false"
                                        [smaller]="true"
                                        [user]="user">
                                    </splinterforge-card>
                                    <splinterforge-card
                                        *ngIf="play.player!=card.name"
                                        class="small_box player_card splinterforge_10"
                                        [attr_name]="false"
                                        [small]="true"
                                        [effects]="false"
                                        [boss]="false"
                                        [rules_box]="false"
                                        [player]="(play.player==entry.turn)?true:false"
                                        [card]="card">
                                    </splinterforge-card>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clear"></div>
               </div>
            -->
            </div>
        </div>
        <div [attr.class]="show_scroll?'scroll_entries show':'scroll_entries'" (click)="scrollToBottom()">
            <span>View the Latest Entries</span>
        </div>
    </div>
</div>