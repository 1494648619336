import { Component, HostListener, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Asset } from '../../_models/user';
import { HelperService } from '../../_services/helper.service';
import { DataService } from '../../_services/data.service';
import { AppComponent } from '../../app.component';
import { fade, flip_up, pointer } from '../../app.animations';
import { User } from '../../_models/user';
@Component({
  selector: 'hive-welcome-modal',
  animations: [fade, flip_up, pointer],
  templateUrl: './hive-welcome.component.html',
  styleUrls: ['./hive-welcome.component.styl']
})
export class HiveWelcomeComponent implements OnInit {
  @Input('show') noKeychainModal = false;
  @Input('title') title = 'Success';
  @Input('label') label = 'Modal Box Apperance Successful!';
  @Input('ok_text') ok = 'OK';
  @Input('subtitle') subtitle = '';
  @Input('color') modal_color = 'green';
  @Output('done') done = new EventEmitter();
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    switch(event.key) {
      case 'Enter':
      case 'Escape':
      case ' ':
        this.close();
      break;
    }
  }
  username: string;
  socket: any;
  login_error:boolean;
  currentUser: User;
  constructor(
    public app: AppComponent,
    public helper: HelperService,
    public data: DataService
  ) {
    this.data.onUserChange().subscribe(u => this.currentUser = u);
  }
  ngOnInit() {
  }
  done_button(status) {
    this.done.emit(status);
  }
  close() {
    this.noKeychainModal = false
    this.done.emit(0);
  }
  gourl(url) { 
    if(url=='app'){
      open('https://chrome.google.com/webstore/detail/hive-keychain/jcacnejopjdphbnjgfaaobbfafkihpep?hl=en&u_producttype=chrome-extension-667709&u_country=au&funnel=convert-pdf&gclid=CjwKCAjwtfqKBhBoEiwAZuesiBiYGbKtD3I_Z8CPJ2YUQffm8BBQZUtl580jb5hIuNlFoDrCW3jR-xoCLPYQAvD_BwE');
    }else{ open(url); }
  }
  openTab(event, tabName) {
    this.helper.openTab(event, tabName);
  }
  navigate(loc) {
    this.helper.navigate(loc);
  }
}