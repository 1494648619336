<section class="forgemodal gold" [@fade]="show">
    <div class="boxwrap" [@flip_up]="show">
        <h1>Fight Result</h1>
        <div *ngIf="battleLog" class="box_container">
            <div class="bricks_background"></div>
            
            <h5 *ngIf="battleLog.fullBoss" class="boss_name">Battled {{battleLog.fullBoss.name}}</h5>
            <div class="boss_holder">
                <div class="boss" *ngIf="battleLog.fullBoss">
                    <slcard-selector [card]="battleLog.fullBoss" [fav]="false"></slcard-selector>
                </div>
            </div>
            <div>
                <span *ngIf="battleLog.totalDmg" class="Damage">
                    <div class="overlay"><span>You did <b>{{battleLog.totalDmg > 0 ? battleLog.totalDmg : 'NO'}}</b> Damage</span></div>
                </span>
                <div *ngIf="battleLog.rewards && battleLog.rewards.length > 0">
                    <span *ngFor="let r of battleLog.rewards">
                        <div class="overlay"><span>You earned <b>{{r.qty|number:'1.0-0'}}</b> {{r.name}}</span></div>
                    </span>
                    <div>You also recieve 1 boss ability reroll credit and 1 monster rez credit!</div>
                </div>
                <div *ngIf="!battleLog.rewards || battleLog.rewards.length === 0">
                    <h5>{{battleLog.fullBoss.name}} has defeated you...</h5>
                    <div>You must defeat the mini boss to earn rewards.</div>
                </div>
            </div>
            <div class="button_group">
                <button (click)="close()" class="button gray close_btn">Close Window</button>
                <div class="clear"></div>
            </div>
        </div>
        <div class="closewrapper" (click)="close()">
            <i class="fa-solid fa-x"></i>
        </div>
    </div>
    <div class="backdrop" [@pointer]="show" (click)="close()"></div>
</section>