import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { trigger, state, animate, transition, style } from '@angular/animations';

export const stats = trigger('stats', [
    state('true', style({
        'max-height': '1000px',
        'margin-top': '7px'
    })),
    state('false', style({
        'max-height': '0px',
        'margin-top': '0px'
    })),
    transition('*=>*', animate('450ms ease-in-out'))
]);
export const dropdown = trigger('dropdown', [
    state('true', style({
        'pointer-events': 'all',
        'transform': 'translateY(0)',
        'opacity': 1,
    })),
    state('false', style({
        'pointer-events': 'none',
        'transform': 'translateY(-5px)',
        'opacity': 0,
    })),
    transition('*=>*', animate('290ms ease-in-out'))
]);