<success-modal
	(done)="close_success()"
	[show]="show_success"
	[label]="claim_result"
	[title]="'Airdrop Claimed'"
	[ok_text]="'Confirm'"
></success-modal>
<notice-modal
	(done)="close_notice()"
	[show]="show_notice"
	[title]="'Airdrop Notice'"
	[label]="claim_result"
></notice-modal>
<div class="col-md-12" style="color: white;">
	<span class="close_btn" (click)="close_overlay()">X</span>
  <div class="tabcontent profile_page">
	  <h4 *ngIf="alphaExclusive && 1>1">Pre Sale Exclusive</h4>
    <h4 *ngIf="!alphaExclusive">Airdrops</h4>
	  <div class="alphaCrates">
	    <div class="alphaStats" *ngIf="presaleStats">
	    	<img class="mini_crate" src="https://splinterforge.s3.us-east-2.amazonaws.com/packs/crate.png">
	    	<span>You have <b>{{presaleStats.user}}</b> eligible <b>CRATEs</b>.</span>
	    </div>
	  </div>
		<div *ngIf="presaleStats && presaleStats.user > 49" class="alphaCrateGaurenteeContainer">
			<p class="alphaCrateGaurentee">
				You are guaranteed to receive a minimum of <b>{{Minimums['alpha exclusive']}}</b> based on your eligible <b>CRATEs</b>.
			</p>
		</div>
    <div *ngIf="alphaExclusive && 1>1" class="alphaExclusive">
      <div class="airdropBox">
		    <div class="airdrop_card_container">
					<div class="inside">
						<div class="border_long_left"></div>
						<div class="border_long_right"></div>
						<div [attr.class]="'fx '+(airFlip=='animate'?'animate ':'')+(airFlip=='done'?'done ':'')">
							<img [attr.class]="'ball_1 '+(Astar[11]||Cstar[7]?'_lr':'')" src="/assets/effects/ball_7.png" />
							<img [attr.class]="'ball_2 '+(Astar[14]||Cstar[12]?'_lr':'')" src="/assets/effects/ball_7.png" />
							<img [attr.class]="'ball_3 '+(Astar[9]||Cstar[2]?'_lr':'')" src="/assets/effects/ball_3.png" />
							<img [attr.class]="'ball_4 '+(Astar[0]||Cstar[18]?'_lr':'')" src="/assets/effects/ball_5.png" />
							<img [attr.class]="'ball_5 '+(Astar[2]||Cstar[13]?'_lr':'')" src="/assets/effects/ball_4.png" />
							<img [attr.class]="'ball_6 '+(Astar[8]||Cstar[0]?'_lr':'')" src="/assets/effects/ball_6.png" />
							<img [attr.class]="'ball_7 '+(Astar[7]||Cstar[3]?'_lr':'')" src="/assets/effects/ball_7.png" />
							<img [attr.class]="'ball_8 '+(Astar[17]||Cstar[17]?'_lr':'')" src="/assets/effects/ball_8.png" />
							<img [attr.class]="'ball_9 '+(Astar[3]||Cstar[9]?'_lr':'')" src="/assets/effects/ball_9.png" />
							<img [attr.class]="'ball_10 '+(Astar[16]||Cstar[15]?'_lr':'')" src="/assets/effects/ball_10.png" />
							<img [attr.class]="'star_1 '+(Astar[15]||Cstar[14]?'_lr':'')" src="/assets/effects/star_1.png" />
							<img [attr.class]="'star_2 '+(Astar[1]||Cstar[16]?'_lr':'')" src="/assets/effects/star_2.png" />
							<img [attr.class]="'star_3 '+(Astar[10]||Cstar[4]?'_lr':'')" src="/assets/effects/star_3.png" />
							<img [attr.class]="'star_4 '+(Astar[5]||Cstar[6]?'_lr':'')" src="/assets/effects/star_4.png" />
							<img [attr.class]="'star_5 '+(Astar[6]||Cstar[5]?'_lr':'')" src="/assets/effects/star_5.png" />
							<img [attr.class]="'sparkle_2 '+(Astar[12]||Cstar[10]?'_lr':'')" src="/assets/effects/sparkle_2.png" />
							<img [attr.class]="'sparkle_3 '+(Astar[7]||Cstar[1]?'_lr':'')" src="/assets/effects/sparkle_3.png" />
							<img [attr.class]="'sparkle_7 '+(Astar[13]||Cstar[8]?'_lr':'')" src="/assets/effects/sparkle_7.png" />
							<img [attr.class]="'burst_star '+(Astar[4]||Cstar[11]?'_lr':'')" src="/assets/effects/burst_star.png" />
						</div>
						<div class="backdrop"></div>
						<!-- <h1>AirDrop Card: {{alphaAirdropCard.name}}</h1> -->
						<div class="left-card"></div>
						<div class="card-container">
							<sfcard-selector
								[sfcard]="alphaExclusive"
								[stackView]="false"
								[flipped]="true"
								[animate_in]="true"
								(flipping)="show_stars()"
								[smaller]="false">
							</sfcard-selector>
						</div>
						<h1 class="card_name" [@fade]="show_name">{{alphaExclusive.name}}</h1>
						<div class="right-card"></div>
						<h6 class="airdrop_result" *ngIf="AirdropResult['alpha exclusive']">{{ AirdropResult['alpha exclusive'] }}</h6>
					</div>
				</div>
      </div>  
      <p *ngIf="!AirdropResult['alpha exclusive'] && AirdropAvailable['alpha exclusive'] && EligibleToClaim['alpha exclusive']" class="claim_airdrop_desc">Claim this Airdrop on your Account.</p>
      <p *ngIf="AirdropResult['alpha exclusive'] && AirdropAvailable['alpha exclusive'] && EligibleToClaim['alpha exclusive']" class="claim_airdrop_desc">You have already claimed this Airdrop!</p>
		<button [attr.class]="(claiming?'act':'')+' black_red button'" *ngIf="AirdropAvailable['alpha exclusive'] && EligibleToClaim['alpha exclusive']" (click)="claim('alpha exclusive')" (mouseover)="fade_stars_center()">
			{{claiming?'Claiming...':'Claim'}}
		</button>
    </div>
    <div *ngIf="1>0" class="airdrop_area">
      <div *ngIf="alphaCrateAirdrops" class="alphaCrateAirdrops">
        <div *ngFor="let card of alphaCrateAirdrops; let i = index" class="airdropBox">
        	<div class="header crate">
        		<h1>
        			<div class="green left"></div><div class="green right"></div><div class="green top"></div><div class="green bottom"></div>
        			#{{i+1}}
        		</h1>
        		<div class="title">CRATE Airdrop</div>
        		<div class="subtitle">Available after {{(i+1)*200}}k CRATEs</div>
        	</div>
			<h5>{{card.name}}</h5>
			<div class="airdrop_card_container">
				<div class="inside">
					<div class="border_long_left"></div>
					<div class="border_long_right"></div>
					<div class="backdrop"></div>
					<div class="left-card"></div>
					
					<div class="card-container">
						<sfcard-selector
							[sfcard]="card"
							[stackView]="false" 
							[smaller]="false">
						</sfcard-selector>
					</div>

					<div class="right-card">
					</div>
				</div>
				<div>
					<img class="border_box_right_orange" src="/assets/textures/right_border_box_orange.png" />

					<button [attr.class]="(claiming?'act':'')+' black_red button'" *ngIf="AirdropAvailable['alpha crate '+(i+1)] && EligibleToClaim['alpha bag '+(i+1)]" (click)="claim('alpha crate '+(i+1))">
						{{claiming?'Claiming...':'Claim'}}
					</button>
					<img class="border_box_left_orange" src="/assets/textures/left_border_box_orange.png" />
					<h6 *ngIf="!AirdropAvailable['alpha crate '+(i+1)] && alphaCrateStats">Airdrop pending: {{((i+1)*200000)-alphaCrateStats.overall}} CRATEs to go!</h6>
					<h6 *ngIf="AirdropResult['alpha crate '+(i+1)]">{{ AirdropResult['alpha crate '+(i+1)] }}</h6>
				</div>
			</div>
        </div>
      </div>
      <div *ngIf="alphaBagAirdrops" class="alphaBagAirdrops">
        <div *ngFor="let card of alphaBagAirdrops; let i = index" class="airdropBox">
        	<div class="header bag">
          		<h1>
          			<div class="yellow left"></div><div class="yellow right"></div><div class="yellow top"></div><div class="yellow bottom"></div>
          			#{{i+1}}
          		</h1>
          		<div class="title">BAG Airdrop</div>
          		<div class="subtitle">{{(i+1)*200}}k BAGs distributed.</div>
          	</div>
          	<div class="airdrop_card_container"><div class="inside">
				<div class="border_long_left"></div>
				<div class="border_long_right"></div>
				<div class="backdrop"></div>
				<div class="left-card"></div>
				<div class="card-container">
					<sfcard-selector
            [sfcard]="card"
            [stackView]="false" 
            [smaller]="false">
          </sfcard-selector>
				</div>
				<div class="right-card">
				</div>

	            <button [attr.class]="(claiming?'act':'')+' black_red button'" *ngIf="AirdropAvailable['alpha bag '+(i+1)] && EligibleToClaim['alpha bag '+(i+1)]" (click)="claim('alpha bag '+(i+1))">
	            	{{claiming?'Claiming...':'Claim'}}
	            </button>

	            <h6 *ngIf="!AirdropAvailable['alpha bag '+(i+1)] && alphaBagStats">
	            	Airdrop pending: {{((i+1)*200000)-alphaBagStats.overall}} BAGs to go!
	            </h6>
	            
	            <h6 *ngIf="AirdropResult['alpha bag '+(i+1)]">{{ AirdropResult['alpha bag '+(i+1)] }}</h6>

	    	</div></div>
        </div>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</div>