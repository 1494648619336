import { Component, Input, OnInit, OnDestroy, ElementRef, ViewChild, ChangeDetectorRef, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';  
import { ShopService } from '@app/_services';
import { DataService } from '@app/_services/data.service';
import { HelperService } from '@app/_services/helper.service';
import { Boss, Card, User } from '../_models/index';
import { DragulaService } from 'ng2-dragula';
import { ActiveCard, fullwidth, appear, opacity } from './slcards.animations';
import { dropdown } from '../hero/hero.animations';
import { SoundsService } from '../_services/sounds.service';
import { fade, flip_up, flip, display_animation } from '../app.animations';
import { AppComponent } from '../app.component';
import { HeroComponent } from '../hero/hero.component';
declare var Vibrant: any;
@Component({ 
    selector: 'slcards',
    animations: [ActiveCard, fullwidth, appear, fade, flip, flip_up, dropdown, display_animation, opacity],
    providers: [SoundsService],
    templateUrl: 'slcards.component.html', 
    styleUrls:['./slcards.component.styl'] 
})
export class SLCardsComponent implements OnInit, OnDestroy {
    constructor(
        private app: AppComponent, 
        private data: DataService, 
        private router: Router,
        private route: ActivatedRoute,
        private sound: SoundsService,
        private dragulaService: DragulaService,
        public helper: HelperService, 
        private heroComp: HeroComponent,
        private cdr: ChangeDetectorRef
        ) { 
            dragulaService.createGroup("DRAGULA_MONSTERS", {
            });
            dragulaService.createGroup("DRAGULA_SUMMONERS", {
            });
    }
    @HostListener('window:keyup', ['$event'])
        keyEvent(event: KeyboardEvent) {
        if (event.code === "Enter") {
            this.fighting=true;
        }
        if (event.code === "Escape" || event.code === "KeyP") {
            this.pauseLogSpeed();
        }
    }
    @HostListener('window:resize', ['$event'])
    resizeWindow() {
        if(window.innerWidth < 768) {
            this.mobile_version = true;
        } else {
            this.mobile_version = false;
        }
    }
    auto_adj() { }
    top() { 
        window.scrollTo(0, 0); 
    }
    heroes: any;
    myFights: any;
    preparing_for_battle = false;
    mobile_version = false;
    monsters_min = 0;
    monsters_max = 30;
    summoners_min = 0;
    summoners_max = 30;
    fight_loading = false;
    enable_day = false;
    config: any;
    regen_interval: any;
    replay: Boolean = false;
    loading: Boolean = false;
    show_sl_card_filters = '1';
    user: User;
    bosses: Boss[];
    boss: Boss;
    result: any;
    cards: Card[] = [];
    sums: any;
    selected: Card[] = [];
    selected_summoners: Card[] = [];
    selected_monsters: Card[] = [];
    copiedCards: Card[] = [];
    hero: any;
    baseCards: Card[] = [];
    scrolling = [];
    heroAbilities: any = [];
    activeHeroAbilities = [];
    forceUnlocked = [];
    removed = [];
    loaded_monster = [];
    loaded_summoner = [];
    itemDesc: string = '';
    logSpeed: number;
    ppd: number = 0;
    Damage: number = 0;
    maxMana: number = 45;
    maxMonsters: number = 7;
    summoners_selected = 0;
    monsters_selected = 0;
    view = 'main';
    viewRewards: Boolean = false;
    viewReplay: Boolean = false;
    display_no_staimina = false;
    display_leaderboard = false;
    display_recents = false;
    display_rewards = false;
    display_myFights = false;
    show_abilities = false;
    show_sort = false;
    show_damage = false;
    show_summoners = true;
    show_monsters = true;
    skippingToRewards = false;
    battle_active = false;
    health: number;
    armor: number;
    speed: number;
    attack: number;
    magic: number;
    ranged: number;
    replay_id: any;
    replacedAbilities = [];
    imgL = [];
    play: any;
    fight_screen = false;
    fighting = false;
    showRules = false;
    show_types = false;
    lockBoss = false;
    quit_battle = false;
    float_filters = false;
    float_filters_offset: number;
    summoners_or_monsters = false;
    hero_loaded = false;
    fight_bg = false;
    //autorun = false;
    show_notice = false;
    notice_title: string;
    notice_label: string;
    showHeroMaxTiers = true;
    ReplacedAbilitiesTooltip: string;
    color_mode='2'; // '0', '1', '2', '3',
    filters = { mana: -1, manaSort: false, ability: 'All', dmgType: 'All', sort: 'None', direction: 'DESC', favorites: false, hide: true };
    mana_placeholder = [0,1,2,3,4,5,6,7,8,9,10];
    manaTiers = [60,75,90,999];
    abilities = ['1.5x DMG',
        'Affliction','Amplify','Backfire','Blind','Bloodlust',
        'Camouflage','Cleanse','Cripple','Deathblow','Demoralize','Divine Shield',
        'Dodge','Double Strike','Enrage','Flying','Forcefield',
        'Halving','Headwinds','Heal','Immunity','Inspire',
        'Last Stand','Life Leech','Magic Reflect','Phase','Piercing',
        'Poison','Protect','Redemption','Repair','Resurrect',
        'Retaliate','Return Fire','Rust','Scavenger','Shatter',
        'Shield','Silence','Slow','Snare','Strengthen',
        'Stun','Swiftness','Tank Heal','Taunt','Thorns',
        'Triage','True Strike','Void','Void Armor','Weaken'
    ];
    selectedAbility = 'All';
    selectedDamage = 'All';
    selectedSort = 'None';
    subs = new Subscription();
    forces = [];
    maxLevels: any;
    mana_count = 0;
    battle_paused: boolean;
    night = true;
    day = false;
    day_timer: any;
    monsters_loaded = 0;
    summoners_loaded = 0;
    filtersHeight = 0;
    stamina_regen = 0;
    currentUser: any;
    show_boss_rules = true;
    rules = true;
    heroStatTotal = 0;
    heroStatReqTiers = [20,30,40,50];
    heroStatReqText = '';
    @ViewChild('filtersBox') filtersBox: ElementRef;
    @ViewChild('filtersContainer') filtersContainer: ElementRef;
    @HostListener("window:scroll", []) onWindowScroll() {
        if(!this.filtersBox)return;
        const verticalOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        var filtersOffset = this.filtersContainer.nativeElement.getBoundingClientRect();
        this.filtersHeight = this.filtersBox.nativeElement.offsetHeight;
        this.onScroll();
        if(filtersOffset.y<0 && filtersOffset.height>390){
            if (verticalOffset > filtersOffset.height-10) {
                this.float_filters = true;
            } else {
                this.float_filters = true;    
            }
        } else {
            this.filtersHeight = 0;
            this.float_filters_offset = 0;
            this.float_filters = false;
        }
    }
    navigate(loc) {
        this.helper.navigate(loc);
    }
    onScroll(a=window.pageYOffset,b=window.innerHeight,c=document.body.scrollHeight) {
        if ( (b + a + 700) >= c ) {
            this.show_more_monsters(); 
        }
    }
    show_more_monsters() {
        this.monsters_max = this.monsters_max+24;
    }
    show_more_summoners() {
        this.summoners_max = this.summoners_max+24;
    }
    monster_loaded(id) {
        this.monsters_loaded++;
        this.loaded_monster[id]=true;
    }
    summoner_loaded(id) {
        this.summoners_loaded++;
        this.loaded_summoner[id]=true;
    }
    increase_damage(ev) { 
        this.Damage=Number(Number(this.Damage)+Number(ev));
    }
    async get_rules(type) {
        let rules = await this.helper.getRules(type);
        if (rules['message'] === 'success') {
            if (!this.user.uniqueRules) this.user.uniqueRules = {};
            this.user.uniqueRules[type] = rules.rules;
            this.data.setUser(this.user);
            if (this.boss) this.boss.uniqueRules = rules.rules.rules;
            let found = this.bosses.find(b => b.type === type);
            if (found) found.uniqueRules = rules.rules.rules;
        } else {
            this.helper.showSnackbarMessage(rules['message']);
        }
    }

    async rerollRules(boss) {
        let type = boss.type;
        if (boss.stats.health < 1) {
            this.helper.showSnackbarMessage('This boss is dead, no need to reroll rules...');
            return;
        }
        if (!confirm('Are you sure you want to burn ' + this.rerollPrice(boss) + ' FORGE to reroll the rules for this boss?')) {
            return;
        }
        let rules = await this.helper.rerollRules(type);
        if (rules['message'] === 'success') {
            this.helper.changeSC(-this.rerollPrice(boss));
            this.user.uniqueRules[type] = rules.rules;
            this.data.setUser(this.user);
            let found = this.bosses.find(b => b.type === type);
            if (found) found.uniqueRules = rules.rules.rules;
            this.helper.navigate('slcards');
            //this.helper.loadBoss();
            this.helper.showSnackbarMessage('Successfully rerolled rules!');
        } else {
            this.helper.showSnackbarMessage(rules['message']);
        }
    }
    rerollPrice(boss) {
        let mod = this.helper.globalMod;
        let type = boss.type;
        let currentRules = this.user.uniqueRules[type];
        let cost = 0;
        let tier = parseInt(type.slice(1));
        let prices = [
            [3,6,12],
            [6,12,24],
            [9,18,36],
            [16,32,64]
        ]
        if (!currentRules.rerolls || currentRules.rerolls === 0) { cost = prices[tier-1][0]; }
        else if (currentRules.rerolls <= 2) { cost = prices[tier-1][currentRules.rerolls]; }
        else if (currentRules.rerolls > 2) { cost = (prices[tier-1][2] * 1.5); }
        if (mod && mod > 0) cost = parseFloat((mod/100*cost).toFixed(1));
        return cost;
    }
    configSub = new Subscription();
    loadingSub = new Subscription();
    collectionSub = new Subscription();
    userSub = new Subscription();
    bossSub = new Subscription();
    resultSub = new Subscription();
    scrollingSub = new Subscription();
    logSpeedSub = new Subscription();
    showRewardsSub = new Subscription();
    bossViewSub = new Subscription();
    fightScreenSub = new Subscription();

    ngOnInit() {
        this.populate();
        this.configSub = this.data.onConfigChange().subscribe(c=>{
            this.config = c;
            if (this.config&&this.config.replacedSkills) this.replacedAbilities = this.config.replacedSkills;
        });
        this.collectionSub = this.data.onCollectionChange().subscribe(x => { this.populate(); });
        this.show_more_monsters();
        this.show_more_monsters();
        this.loadingSub = this.data.onLoadingChange().subscribe(b => this.loading = b);
        this.userSub = this.data.onUserChange().subscribe((u) => { 
            if(u){
                this.user = u;
            }
        });
        this.replay_id = this.route.snapshot.paramMap.get('replay_id');
        if(this.replay_id) this.watchReplay();
        this.bossSub = this.data.onBossChange().subscribe((b) => {
            if(this.helper && this.helper.config && this.helper.config.maxLevelsPerTier) {
                for (var i = 0; i < b.length; ++i) {
                    let boss = b[i];
                    let userRules;
                    if (this.user && this.user.uniqueRules && this.user.uniqueRules[boss.type]) userRules = this.user.uniqueRules[boss.type];
                    if (this.user && userRules?.active && userRules.boss_id === JSON.stringify(boss.id)) boss.uniqueRules = userRules.rules;
                    else boss.uniqueRules = null;
                    b[i].max_levels = b[i]?.type ? this.helper.config.maxLevelsPerTier[parseInt(b[i].type.slice(1))-1] : this.helper.config.maxLevelsPerTier[3];
                    for (var a = 0; a < b[i].stats.abilities.length; a++) {
                        b[i].stats.abilities[a] = b[i].stats.abilities[a].replace(' ', '-');
                    }
                }
            }
            this.bosses = b;
            this.bosses.sort((a,b) => (a.type > b.type) ? 1 : -1);
            //if(this.autorun)setTimeout( ()=> {this.test_replay()}, 600 );
        });
        this.resultSub = this.data.onResultChange().subscribe(r => this.result = r);
        this.scrollingSub = this.data.onScrollingChange().subscribe(s => this.scrolling = s);
        this.logSpeedSub = this.data.onLogSpeedChange().subscribe(ls => this.logSpeed = ls);
        this.showRewardsSub = this.data.onShowRewardsChange().subscribe((r) => {
            this.viewRewards = r;
        });
        this.bossViewSub = this.data.onBossViewChange().subscribe(bv => this.view = bv);
        this.fightScreenSub = this.data.onFightScreen().subscribe((fc) => {
            this.fight_screen = fc;
            if(this.fight_screen) {
                this.view = 'result';
            }
        });
        this.data.setShowRewards(false);
        this.logSpeed=Number(localStorage.getItem('logSpeed'));
        this.show_sl_card_filters=localStorage.getItem('show_sl_card_filters')?localStorage.getItem('show_sl_card_filters'):'1';
        if (!this.logSpeed) this.logSpeed=500;
        this.data.setLogSpeed(this.logSpeed);
        this.data.setResult(null);
        this.data.setUser(this.helper.currentUser);
        this.helper.loadBoss();
        this.getReplacedAbilitiesTooltip();
        this.sound.get_audio_quality();
        //if(this.enable_day){
        //    this.day_time();
        //    this.day_timer = setInterval( () => { this.day_time(); },2000);
        //}
        //this.regen_interval = setInterval(()=>{
        //    this.stamina_regen = this.helper.getStamRegen();
        //    this.currentUser=this.helper.currentUser;
        //}, 1000);
    }

    async populate() {
        //this.helper.showSnackbarMessage('Loading Splinterlands cards...');
        let t = localStorage.getItem('slCollection');
        //t = JSON.parse(t);
        //console.log(t);
        if (t) {
            this.cards = JSON.parse(t);
            this.copiedCards = this.cards;
            //console.log('loaded from localstorage', this.cards);
            if (!this.user) this.user = await this.helper.getCurrentUser();
            if (this.user?.settings?.slFavorites) {
                this.user.settings.slFavorites.forEach(x => {
                    let found = this.cards.find(y => { return y['id'] === x; });
                    if (found) found.favorite = true;
                })
            }
            this.sums = this.cards.filter(x => { return x.type === 'Summoner'; });
        } else {
            console.log('loading fresh collection');
            await this.helper.loadSLCollection();
        }/*
        if (this.helper.slCollection) {
            console.log('loading collection from cache');
            let temp = JSON.parse(JSON.stringify(this.helper.slCollection));
            this.baseCards = temp; // JSON.parse(JSON.stringify(temp));
            this.cards = JSON.parse(JSON.stringify(this.baseCards));
            if (!this.user) this.user = await this.helper.getCurrentUser();
            if (this.user?.settings?.slFavorites) {
                this.user.settings.slFavorites.forEach(x => {
                    let found = this.cards.find(y => { return y['id'] === x; });
                    if (found) found.favorite = true;
                })
            }
            this.sums = this.cards.filter(x => { return x.type === 'Summoner'; });
            //console.log('these are the summoners that made it to the fight page', this.sums);
            //await this.reset('grey', 'grey', true, 'collection population function');
        } else {
            console.log('loading fresh collection');
            await this.helper.loadSLCollection();
        }*/
        //console.log(this.helper.config);
        if (!this.helper.config) this.config = this.helper.getConfig();
        else this.config = this.helper.config;
    }

    toggle_show_sl_card_filters() {
        this.show_sl_card_filters = this.show_sl_card_filters=='1'?'0':'1';
        localStorage.setItem('show_sl_card_filters',this.show_sl_card_filters);
    }
    show_rewards() { this.data.setShowRewards(true); }
    /*day_time() {
        const d = new Date();
        let hour = d.getHours();
        if(hour > 19 || hour < 7) {
            this.day=false;
            this.night=true;
        } else {
            this.night=false;
            if(hour > 9 && hour < 19) {
                this.day=true;
            } else {
                this.day=false;
            }
        }
    }*/
    /*test_replay() {
        this.autorun = true;
        this.selectBoss(this.bosses[3].type);
        this.watchReplay();
    }*/
    /*test_battle() {
        this.selectBoss(this.bosses[3].type);
        setTimeout( () => {
            this.select(this.copiedCards[36]);
            this.select(this.copiedCards[4]);
            setTimeout( () => {
                this.select(this.copiedCards[32]);
                this.select(this.copiedCards[33]);
                this.select(this.copiedCards[18]);
                this.select(this.copiedCards[10]);
                this.select(this.copiedCards[5]);
                this.select(this.copiedCards[11]);
                this.select(this.copiedCards[16]);
                setTimeout( () => {
                    this.fight(1);
                }, 3000);
            }, 1000);
        }, 1000);
    }*/
    imgLoad(i) {
        if(this.imgL[i]>0){
            this.imgL[i]++;
        } else {
            this.imgL[i]=1;
        }
    }
    new_boss(){
        this.unlock_boss();
        this.view = 'main';
        this.fight_screen=false;
        this.fighting = false;
        this.replay = false;
        this.data.setFightScreen(false);
        this.battle_active = false;
        this.display_recents=false;
        this.selected = [];
        this.display_leaderboard=false
        this.summoners_selected = 0;
        this.monsters_selected = 0;
        this.quit_battle=true;
    }
    hro_ld() {
        this.hero_loaded = true;
    }
    skipBattle() {
        this.unlock_boss();
        this.display_recents=false;
        this.display_leaderboard=false;
    }
    skipToRewards() {
        this.pause();
        this.data.setShowRewards(true);
    }
    pauseLogSpeed() {
        if(!this.fighting) {
            this.fighting = true;
            return;
        }
        if(!this.battle_paused) {
            this.pause();
        } else {
            this.unpause();
        }
    }
    pause() {
        this.battle_paused=true;
        localStorage.setItem('logSpeed',''+this.logSpeed);
        this.logSpeed=0;
        this.data.setLogSpeed(0);
        this.sound.pause_game();
    }
    unpause() {
        this.battle_paused=false;
        this.logSpeed=Number(localStorage.getItem('logSpeed'));
        if (!this.logSpeed) this.logSpeed = 500;
        this.data.setLogSpeed(this.logSpeed)
        this.sound.resume_game();
    }
    changeLogSpeed(n) {
        if (n > 0 && this.logSpeed==999) return;
        if (n < 0 && this.logSpeed==0) return;
        if (n > 0) {
            if(this.logSpeed+n > 999){
                this.data.setLogSpeed(999);
            } else {
                this.data.setLogSpeed(this.logSpeed+n);
            }
        } else if (n < 0) {
            this.logSpeed+n < 0 ? this.data.setLogSpeed(1) : this.data.setLogSpeed(this.logSpeed+n);
        }
        localStorage.setItem('logSpeed',''+this.logSpeed);
    }
    ngAfterContentChecked(): void {
      // this.cdr.detectChanges();
    }
    ngOnDestroy() {
        this.configSub.unsubscribe();
        this.loadingSub.unsubscribe();
        this.collectionSub.unsubscribe();
        this.userSub.unsubscribe();
        this.bossSub.unsubscribe();
        this.resultSub.unsubscribe();
        this.scrollingSub.unsubscribe();
        this.logSpeedSub.unsubscribe();
        this.showRewardsSub.unsubscribe();
        this.bossViewSub.unsubscribe();
        this.fightScreenSub.unsubscribe();
        this.replay = false;
        this.fighting = false;
        this.battle_active = false;
        this.quit_battle = false;
        this.pause();
        this.subs.unsubscribe();
        this.sound.stop_music();
        this.sound.stop_sounds();
        this.dragulaService.destroy('DRAGULA_MONSTERS');
        this.dragulaService.destroy('DRAGULA_SUMMONERS');
        if(this.day_timer)clearInterval(this.day_timer);
        if(this.regen_interval)clearInterval(this.regen_interval);
        this.monsters_loaded=0;
        this.summoners_loaded=0;
        //this.helper.slCollection = null; // not a good solution
    }
    async changeHero(h) {
        let found = this.heroes.find(x => { return x.id === h.id });
        if (!found) {
            this.helper.showSnackbarMessage('Unable to find hero');
            return;
        }
        this.hero = found;
        //console.log(this.hero);
        this.heroAbilities = this.hero.abilities;
        this.sums.forEach(c => { c.selected = false });
        //this.copiedCards.forEach(c => { c.selected = false });
        this.clear_monsters();
        this.user.settings.currentHero = found.id;
        this.data.setUser(this.user);
        await this.reset('grey', 'grey', true, 'change hero');
        this.applyAbilities();
        this.show_types=false;
    }
    clearFilters() {
        this.filters.mana = -1;
        this.filters.ability = 'All';
        this.selectedAbility = 'All';
        this.selectedDamage = 'All';
    }
    filterByDamage(s?) {
        if(s)this.selectedDamage = s;
        this.show_damage = false;
        this.filters.dmgType = this.filters.dmgType === this.selectedDamage ? 'All' : s;
    }
    filterByMana(n) {
        this.filters.mana === n ? this.filters.mana = -1 : this.filters.mana = n;
    }
    filterByAbility(s?) {
        if(s)this.selectedAbility = s;
        this.show_abilities = false;
        this.filters.ability = this.filters.ability === this.selectedAbility ? 'All' : s;
    }
    filterBySorting(s?, d?) {
        this.show_sort = false;
        this.filters.sort = s;
        this.filters.direction = d;
    }
    getAbilitiesForDisplay() { // hero call
        let active = [];
        for (let i = 0; i < this.heroAbilities.length; i++) {
            if (this.heroAbilities[i].bonus > 0) active.push(this.heroAbilities[i].type);
        }
        this.activeHeroAbilities = active;
        return active;
    }
    
    getMaxLevelText() {
        if (this.maxLevels) {
            return 'C: ' + this.maxLevels[0] + ', R: ' + this.maxLevels[1] + ', E: ' + this.maxLevels[2] + ', L: ' + this.maxLevels[3];
        } else return '';
    }
    getReplacedAbilitiesTooltip() {
        let title = 'Current abilities replaced with 1.5x damage:\n'
        for (let i = 0; i < this.replacedAbilities.length; i++) {
            title += this.replacedAbilities[i] + (i < this.replacedAbilities.length-1 ? '\n' : '');
        }
        this.ReplacedAbilitiesTooltip = title;
    }
    
    async applyAbilities() {  // hero call included
      this.forceUnlocked = [];
      let gladius = this.heroAbilities.find(a => { return a.type === 'Gladius Force' && a.bonus > 0; });
      if (this.heroAbilities?.length > 0) {
        let forces = [];
        this.maxMana = 45;
        this.maxMonsters = 7;
        //console.log(this.heroAbilities);
        this.heroAbilities.forEach(abi => {
            if (abi.type.indexOf('Force') > -1 && abi.bonus > 0 && abi.type !== 'Gladius Force') {
                forces.push(abi);
            } else if (abi.type === 'Vassal Mastery' && abi.bonus > 0) {
                this.maxMonsters += abi.bonus;
            } else if (abi.type === 'Mana Mastery' && abi.bonus > 0) {
                //console.log('should be adding ' + abi.bonus + ' available mana');
                this.maxMana += abi.bonus;
            }
        }); // end this.heroAbilities.forEach
        
        if (this.boss && this.boss.type && this.boss.type !== 't4') {
            let tier = parseInt(this.boss.type.slice(1));
            if (this.manaTiers[tier-1] < this.maxMana) this.maxMana = this.manaTiers[tier-1];
        }
        if (forces.length > 0) {
            if (gladius) {
                this.forceUnlocked = await this.helper.getGladius(this.selected.filter(s => s.type === 'Summoner'), forces);
            }
            //this.forceUnlocked = 
            let unlocked = await this.helper.getMissingCardsByColor(forces);
            this.forceUnlocked = this.forceUnlocked.concat(unlocked);
            this.forceUnlocked.forEach(c => {
                let found = this.copiedCards.find(i => { return i['id'] === c.id; });
                c.favorite = this.user.settings.slFavorites ? this.user.settings.slFavorites.includes(c.id) : false;
                if (!found) this.copiedCards.push(c);
            })
            this.copiedCards.sort((a,b) => b.color.localeCompare(a.color));
            this.forces = forces;
            await this.setStats();
        }
        if (this.monsters_selected > this.maxMonsters || this.mana_count > this.maxMana) {
            await this.reset('grey', 'grey', true, 'apply abilities'); 
        }
      }
    }
    getTooltip(card) {
        if (!card || !card.rarity) return '';
        let rarity = card.rarity === 1 ? 'C' : card.rarity === 2 ? 'R' : card.rarity === 3 ? 'E' : card.rarity === 1 ? 'L' : 'P';
        return '(' + rarity + ') ' + card.name + ' - Lvl ' + card.level;
    }
    async reset(color1, color2, toEmpty, trigger = '') { // hero call included
        //console.log(color1, color2, toEmpty, trigger);
        if (toEmpty) {
            this.selected = [];
            let t = localStorage.getItem('slCollection');
            this.cards = JSON.parse(t); // JSON.parse(JSON.stringify(this.baseCards));
            if (!this.user) this.user = await this.helper.getCurrentUser();
            if (this.user?.settings?.slFavorites) {
                this.user.settings.slFavorites.forEach(x => {
                    let found = this.cards.find(y => { return y['id'] === x; });
                    //console.log(x, found);
                    if (found) found.favorite = true;
                })
            }
        }
        let colors = ['Gray'];
        colors.push(color1);
        colors.push(color2);
        let gladiusAbility = this.heroAbilities.find(a => a.type === 'Gladius Force');
        let includeGladius = false;
        if (gladiusAbility && gladiusAbility.bonus > 0) includeGladius = true;
        
        //if (toEmpty) this.cards.forEach(x => { x.selected = false; });

        this.copiedCards = this.cards.filter(c => {
            if (includeGladius) return colors.includes(c.color);
            else return colors.includes(c.color) && c.edition !== 6;
        });

        if (this.boss && this.boss.uniqueRules) {
            if (this.boss.uniqueRules.includes('Against All Odds')) {
                this.copiedCards = this.copiedCards.filter(c => { return c.mana % 2 !== 0; });
            }
            if (this.boss.uniqueRules.includes('Even Odds')) {
                this.copiedCards = this.copiedCards.filter(c => { return c.mana % 2 === 0; });
            }
            if (this.boss.uniqueRules.includes('Fallen Legendaries')) {
                this.copiedCards = this.copiedCards.filter(c => { return c.rarity < 4; });
            }
            if (this.boss.uniqueRules.includes('Peasant Infantry')) {
                this.copiedCards = this.copiedCards.filter(c => { return c.rarity < 3; });
            }
            if (this.boss.uniqueRules.includes('Training Crew')) {
                this.copiedCards = this.copiedCards.filter(c => { return c.mana < 7; });
            } 
        }

        await this.setStats();
    }
    
    watchReplay() {
        //return;
        this.fight_bg=true;
        this.fight_loading = true;
        this.data.setReplayBoss(this.boss);
        this.data.setBossView('result');
        this.data.setScrolling([]);
        this.clearFilters();
        setTimeout(()=>{
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
            this.fight_screen=true;
            this.replay = true;
            this.display_leaderboard = false;
            this.display_recents = false;
            this.lockBoss = false;
            this.boss = null;
            this.data.setFightScreen(true);
        }, 1000);
        setTimeout(()=>{
            this.fight_bg=false;
        }, 2000);
    }
    replay_loaded(e) {
        this.preparing_for_battle=false;
        setTimeout(()=>{
            this.fight_loading = false;
        }, 2600);
    }
    async fight(gear = 1) { // hero call -sends built hero
        if (this.mana_count < 30) {
            if (!confirm('This team is using a small amount of mana, you can use as much mana as you available for 30 stamina.\nWould you like to submit this team anyway?')) return;
        } else if (this.mana_count > this.maxMana) {
            this.helper.showSnackbarMessage('Too much mana selected!');
            return;
        }
        if (this.hero.equipment.relic !== '') {
            let charges = this.helper.getCardCharges(this.hero.equipment.relic);
            if (charges < 1) {
                if (!confirm('Your relic is out of charges, do you want to continue?')) return;
            }
        }
        if (this.getStamRegen() + this.user.stamina.current < 30) {
            this.display_no_staimina = true;
        } else if (gear === 2 && this.getStamRegen() + this.user.stamina.current < 60) {
            this.display_no_staimina = true;
        } else if (this.summoners_selected === 2 && this.monsters_selected > 0  && this.boss) {
            this.fight_loading = true;
            //this.data.setLoading(true);
            this.selected = [];
            for (let s of this.selected_summoners) this.selected.push(s);
            for (let m of this.selected_monsters) this.selected.push(m);
            this.hero.uid = this.hero.id + ' hero';
            let found = this.selected.find(c => { return this.hero.name === c.name; }); ///////////////////////////////////////////////
            if (!found) this.selected.push(this.hero);
            let uids = [...new Set(this.selected.map(c => c.uid))];
            let temp = await this.helper.fightBoss(this.boss.type, uids, gear);
            if (temp === 'ToS') return;
            if (temp && temp['tooSoon']) {
                this.helper.showSnackbarMessage('Must wait a minimum of 30 seconds between fights, try again ' + ((parseInt(temp['remaining'])/1000)+1) + ' seconds');
                this.fight_loading = false;
                this.data.setLoading(false);
                return;
            }
            if (this.result && !this.result.message) {
                //console.log(this.result);
                this.preparing_for_battle=true;
                this.data.setReplayBoss(this.boss);
                this.data.setScrolling([]);
                this.fight_bg=true;
                setTimeout(()=>{
                    this.data.setBossView('result');
                    this.data.setFightScreen(true);
                    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
                    this.view = 'result';
                    this.battle_active = true;
                    this.fight_screen=true;
                }, 500);
                setTimeout(()=>{
                    this.fight_bg=false;
                    this.lockBoss = false;
                    this.boss = null;
                }, 2000);
                this.clearFilters();
                this.helper.loadBoss();
                await this.helper.refreshUser(this.user.username, this.user.token);
                this.helper.getSFCards();
                this.reset('grey', 'grey', true, 'post fight');
                if (this.result['newRules'] && this.result['newRules']['message'] === 'success') {
                    let found = this.bosses.find(b => b.name === this.boss.name);
                    if (found) found.uniqueRules = this.result['newRules']['rules']['rules'];
                }
                //this.sums.forEach(x => { x.selected = false; });
                this.data.setLoading(false);
            } else {
                if (this.result && this.result.message) this.helper.showSnackbarMessage(this.result.message);
                else if (temp['message']) {
                    this.helper.showSnackbarMessage(temp['message']);
                }
                this.data.setLoading(false);
            }
        } else {
            if (!this.boss) {
                this.helper.showSnackbarMessage('You must select a boss to battle.');
            } else {
                this.helper.showSnackbarMessage('You must select at least 2 summoners and 1 monster.');
            }
        }
    }
    calculate_mana_selected() {
        this.mana_count = 0;
        this.selected.forEach(card => {
            this.mana_count += card.mana;
        });
        return this.mana_count;
    }
    numSumSelected() {
        let count = 0;
        this.selected.forEach(card => {
            if (card['type'] === 'Summoner') count++;
        });
        this.summoners_selected = count;
        return count;
    }
    numMonSelected() {
        let count = 0;
        this.selected.forEach(card => {
            if (card['type'] === 'Monster') count++;
        });
        this.monsters_selected = count;
        return count;
    }
    async select(card: Card) {
        let found = this.selected.find(x => { return x.id === card.id; });
        if(this.selected.includes(card) || found) {
            this.remove(card);
        } else {
            if (card['type'] === 'Summoner' && this.summoners_selected > 1) {
                this.helper.showSnackbarMessage('You have already selected 2 summoners!');
                return;
            } else if (card['type'] === 'Monster' && this.monsters_selected >= this.maxMonsters) {
                this.helper.showSnackbarMessage('You have already selected ' + this.maxMonsters + ' monsters!');
                return;
            } else if (card['type'] === 'Monster' && this.summoners_selected < 2) {
                this.helper.showSnackbarMessage('You must select your Summoners first!');
                return;
            } else if (this.mana_count + card.mana > this.maxMana) {
                this.helper.showSnackbarMessage('You can only use a maximum of ' + this.maxMana + ' mana for your team!');
                return;
            } else {
                if (card['type'] === 'Summoner') {
                    let summoners = this.selected.filter(s => s.type === 'Summoner');
                    let color1 = summoners.length > 0 ? summoners[0].color : card.color;
                    let color2 = card.color;
                    card.selected = true;
                    this.summoners_selected++;
                    this.selected_summoners.push(card);
                    this.selected.push(card);
                    this.numSumSelected();
                    if (this.summoners_selected === 2) {
                        await this.reset(color1, color2, false, 'after 2nd summoner selected');
                        this.applyAbilities();
                    }
                    this.calculate_mana_selected();
                    this.calculate_selected_cards();
                }
                if (card['type'] === 'Monster') {
                    if (await this.validateForce(card)) {
                        card.selected = true;
                        this.monsters_selected++;
                        this.selected_monsters.push(card);
                        this.selected.push(card);
                        this.numMonSelected();
                        this.calculate_mana_selected();
                        this.calculate_selected_cards();
                    } else {
                        this.helper.showSnackbarMessage('Already have max extra monsters of that type.');
                    }
                }
            }
        }
    }
    async remove(card: Card) {
        this.removed.push(card);
        card.selected = false;
        if (card.type === 'Monster') { // || (this.summoners_selected <= 2 && this.monsters_selected === 0)
            this.monsters_selected--;
            var monsters = []
            for (var i = 0; i < this.selected_monsters.length; ++i) {
                if(this.selected_monsters[i]!=card){
                    monsters.push(this.selected_monsters[i]);
                }
            };
            this.selected_monsters = monsters;
            this.selected = this.selected.filter(c => { return c.uid !== card.uid });
            let collectionCard: any;
            collectionCard = this.cards.find(c => { return c['uid'] === card.uid });
            if (collectionCard) collectionCard.selected = false;
            if (this.selected.length === 0) await this.reset('grey', 'grey', true, 'last monster removed');
        } else if (card.type === 'Summoner') {
            if (this.monsters_selected > 0) {
                this.clear_monsters();
                return;
            }
            this.summoners_selected--;
            var summoners = []
            for (var i = 0; i < this.selected_summoners.length; ++i) {
                if(this.selected_summoners[i]!=card){
                    summoners.push(this.selected_summoners[i]);
                }
            };
            this.selected_summoners = summoners;
            this.selected = this.selected.filter(c => { return c.uid !== card.uid });
            let collectionCard: any;
            collectionCard = this.cards.find(c => { return c['uid'] === card.uid });
            if (collectionCard) collectionCard.selected = false;
            if (this.selected.length === 0) await this.reset('grey', 'grey', true, 'last card removed');
        } else if (this.monsters_selected > 0) { // this shouldn't ever trigger
            console.log('this should never trigger');
            if (this.summoners_selected === 1) {
                await this.reset('grey', 'grey', true, 'odd scenario, monster selected, but not summoners');
                return;
            } else { 
                this.setStats(); 
            }
            let summoners = this.selected.filter(c => { return (c.type === 'Summoner' && c.selected) });
            let remainingSummoner;
            summoners.forEach(s => { if (s.uid !== card.uid) remainingSummoner = s; });
            let color = remainingSummoner?.color ? remainingSummoner.color : 'Gray';
            let monsters = [];
            this.selected.forEach(c => {
                if (c.type === 'Monster' && c.selected && (c.color === 'Gray' || c.color === color)) monsters.push(c);
                else c.selected = false;
            });
            await this.reset(color, 'grey', false, 'not caught by "monster"?');
            let collectionCard: any;
            collectionCard = this.copiedCards.find(c => { return c['uid'] === remainingSummoner?.uid });
            if (collectionCard) {
                collectionCard.selected = true;
                this.selected = [collectionCard];
                monsters.forEach(m => {
                    collectionCard = this.copiedCards.find(c => { return c['uid'] === m.uid });
                    collectionCard.selected = true;
                    this.selected.push(collectionCard);
                });
            }
        }
        this.calculate_selected_cards();
        this.calculate_mana_selected();
        setTimeout(() => { this.removed = []; }, 200);
    }

    async validateForce(card: Card) {  // hero call
        if (card.color === 'Gray') return true;
        let valid = false;
        let isGladius = card.edition === 6; // card.uid.indexOf('gladius') > -1;
        let summoners = this.selected.filter(c => c.type === 'Summoner');
        let same = this.selected.filter(c => {
            let isMonster = c.type === 'Monster';
            let isColor = c.color === card.color;
            return isColor && isMonster; //  c.type === 'Monster' && (c.color === card.color || (card.uid.indexOf('gladius') > -1 && c.uid.indexOf('gladius') > -1));
        });
        if (!isGladius) {
            summoners.forEach(s => {
                if(s.color === card.color) valid = true;
            });
        }
        if (valid) {
            return valid;
        } else {
            if (isGladius) {
                let found = this.heroAbilities.find(a => a.type.indexOf('Gladius Force') > -1);
                if (found) {
                    let glads = this.selected.filter(x => {
                        return x.edition === 6;
                    })
                    //console.log(valid, glads.length, found.bonus);
                    if (glads.length+1 <= found.bonus) {
                        //valid = true;
                        summoners.forEach(s => {
                            if(s.color === card.color) valid = true;
                        });
                        this.heroAbilities.forEach(abi => {
                            //console.log(abi.type, abi.bonus, card.color, same.length);
                            if (abi.type.indexOf('Life Force') > -1 && card.color === 'White' && same.length+1 <= abi.bonus) valid = true;
                            else if (abi.type.indexOf('Earth Force') > -1 && card.color === 'Green' && same.length+1 <= abi.bonus) valid = true;
                            else if (abi.type.indexOf('Death Force') > -1 && card.color === 'Black' && same.length+1 <= abi.bonus) valid = true;
                            else if (abi.type.indexOf('Dragon Force') > -1 && card.color === 'Gold' && same.length+1 <= abi.bonus) valid = true;
                            else if (abi.type.indexOf('Fire Force') > -1 && card.color === 'Red' && same.length+1 <= abi.bonus) {
                                valid = true;
                                console.log(valid, 'here');
                            }
                            else if (abi.type.indexOf('Water Force') > -1 && card.color === 'Blue' && same.length+1 <= abi.bonus) valid = true;
                        });
                    }
                }
            } else {
                this.heroAbilities.forEach(abi => {
                    if (abi.type.indexOf('Life Force') > -1 && card.color === 'White' && same.length+1 <= abi.bonus) valid = true;
                    else if (abi.type.indexOf('Earth Force') > -1 && card.color === 'Green' && same.length+1 <= abi.bonus) valid = true;
                    else if (abi.type.indexOf('Death Force') > -1 && card.color === 'Black' && same.length+1 <= abi.bonus) valid = true;
                    else if (abi.type.indexOf('Dragon Force') > -1 && card.color === 'Gold' && same.length+1 <= abi.bonus) valid = true;
                    else if (abi.type.indexOf('Fire Force') > -1 && card.color === 'Red' && same.length+1 <= abi.bonus) valid = true;
                    else if (abi.type.indexOf('Water Force') > -1 && card.color === 'Blue' && same.length+1 <= abi.bonus) valid = true;
                });
            }
            return valid;
        }
    }
    calculate_selected_cards() {
        if(this.summoners_selected === 2) {
            // setTimeout(()=>{this.summoners_or_monsters = true;},500);
            this.summoners_or_monsters = true;
        } else if(this.summoners_selected < 2) {
            this.summoners_or_monsters = false;
        }
    }
    async setStats() {
        if(this.helper && this.helper.config && this.helper.config.maxLevelsPerTier){
            let maxLevelsPerTier = this.helper.config.maxLevelsPerTier;
            this.maxLevels = this.boss?.type ? maxLevelsPerTier[parseInt(this.boss.type.slice(1))-1] : maxLevelsPerTier[3];
            for (let i = 0; i < this.copiedCards.length; i++) {
                let card = this.copiedCards[i];
                let limit = this.maxLevels[card.rarity - 1];
                card['starter'] = card.uid.indexOf('starter-') > -1 ? true : false;
                card.level = card.level > limit ? limit : card.level;
                card.abilities = this.helper.getAbilities(card);
                card.imgURL = await this.helper.getCardImgUrl(card);
                if (card.type === 'Monster') {
                    card.attack = card.stats.attack[card.level-1] > 0 ? card.stats.attack[card.level-1] : 0;
                    card.magic = card.stats.magic[card.level-1] > 0 ? card.stats.magic[card.level-1] : 0;
                    card.ranged = card.stats.ranged[card.level-1] > 0 ? card.stats.ranged[card.level-1] : 0;
                    card.armor = card.stats.armor[card.level-1];
                    card.speed = card.stats.speed[card.level-1];
                    card.health = card.stats.health[card.level-1];
                } else if (card.type === 'Summoner') {
                    card.attack = Math.ceil(this.attack/10) > 0 ? Math.ceil(this.attack/10) : 0;
                    card.magic = Math.ceil(this.magic/10) > 0 ? Math.ceil(this.magic/10) : 0;
                    card.ranged = Math.ceil(this.ranged/10) > 0 ? Math.ceil(this.ranged/10) : 0;
                    card.armor = Math.ceil(this.armor/10) > 0 ? Math.ceil(this.armor/10) : 0;
                    card.speed = Math.ceil(this.speed/10);
                    card.health = Math.ceil(this.health/10);
                    //console.log(card);
                }
                this.copiedCards[i]=card;
            }
        }
    }
    required_done(event) {
        this.display_no_staimina = false;
    }
    fight_close_loading($event) {
        this.fight_loading=false;
    }
    async setHeroesStats() {
        let tier = parseInt(this.boss.type.slice(1));
        for (let i = 0; i < this.heroes.length; i++) {
            let tempHero = this.heroes[i];
            let equippedCards = await this.helper.getEquippedCards(tempHero);
            //let heroAbilities = this.getHeroAbi(equippedCards);
            //if (tempHero.currentClass === 'Wizard') console.log(equippedCards, heroAbilities);
            let levelDetails = this.helper.heroLevelInfo(tempHero.xp);
            tempHero.levelDetails = levelDetails;
            //tempHero.abilities = heroAbilities;
            tempHero.level = levelDetails.level;
            tempHero.promotable = tempHero.rank < Math.floor(tempHero.level/10);

            let stats = this.helper.getCompleteHeroStats(equippedCards, tempHero);
            tempHero.stats = stats;
            let parts = ['mana','attack','magic','ranged','speed','armor','health'];
            let tierStats = { health: 1, armor: 1, speed: 1, attack: 0, ranged: 0, magic: 0, mana: 0, runeStats: 0, nartors: 0 };
            if (tempHero.type === 'Warrior') tierStats.attack += 1;
            else if (tempHero.type === 'Wizard') tierStats.magic += 1;
            else if (tempHero.type === 'Ranger') tierStats.ranged += 1;
            let heroStatTotal = 0;
            for (let part of parts) {
                if ([1,2,3,4].includes(tier)) {
                    let config = this.helper.getSettings();
                    let maxStat = config.heroStatMaxTiers[tier-1].armor;
                    if (maxStat && tempHero.stats[part] > maxStat) tierStats[part] = maxStat;
                    else tierStats[part] = tempHero.stats[part];
                    if (part !== 'nartors') heroStatTotal += tierStats[part];
                    //this.hero[part] = this.hero.stats[part];
                    //console.log(this.hero.stats[part], this.hero[part], this[part]);
                }
                this[part] = stats[part];
            }
            tempHero.tierStats = tierStats;
            tempHero.heroStatTotal = heroStatTotal;
        }
        if (this.user.currentHero) {
            //console.log(this.heroes, this.user.currentHero);
            let found = this.heroes.find(x => { return x.id === this.user.currentHero; });
            if (found) this.hero = found;
            else this.hero = this.heroes[0];
        } else this.hero = this.heroes[0];
        //console.log(this.hero);
        this.heroAbilities = this.hero.abilities;
        this.reset('grey', 'grey', true, 'set hero stats');
        this.hero.bossTier = tier;
    }
    getHeroAbi(equippedCards) { // wasted step - done in hero comp
        let config = this.helper.getSettings();
        let abilities = [
            { type: "Life Force", bonus: 0 },
            { type: "Fire Force", bonus: 0 },
            { type: "Earth Force", bonus: 0 },
            { type: "Death Force", bonus: 0 },
            { type: "Water Force", bonus: 0 },
            { type: "Dragon Force", bonus: 0 },
            { type: "Gladius Force", bonus: 0 },
            { type: "Mana Mastery", bonus: 0 },
            { type: "Vassal Mastery", bonus: 0 },
            { type: "Splash Heal", bonus: 0 },
            { type: "Hearts Blood", bonus: 0 },
            { type: "Critical Strike", bonus: 0 }
        ];
        if (!equippedCards || equippedCards.length < 1) return abilities;

        let socketAbilities = config['socketItems'];
        let nartorsSet = [57,58,59,60,61,62];
        let nartors = 0;
        for (let i = 0; i < equippedCards.length; i++) {
            let card = equippedCards[i];
            if (nartorsSet.includes(card.detail_id)) nartors += 1;
            let abi = abilities.find(a => { return card.name.indexOf(a.type) > -1; });
            if (abi) {
                let bonusDetails = socketAbilities.find(x => { return x.type.indexOf(abi.type) > -1 });
                //console.log(bonusDetails);
                abi.bonus += bonusDetails.bonus[card.rarity-1];
            }
        }
        if (nartors === 6) abilities[8].bonus += 1;
        return abilities;
    }
    async selectBoss(type) {
        if (!this.cards) {
            console.log('loading fresh collection');
            this.helper.showSnackbarMessage('loading Splinterlands collection...')
            await this.helper.loadSLCollection();
        }
        if (type && this.bosses) {
            let temp = this.bosses.find(b => b.type === type);
            if (temp) {
                this.boss = temp;
                let tier = parseInt(this.boss.type.slice(1));
                if (!this.boss.uniqueRules || this.boss.uniqueRules.length < 1) await this.get_rules(this.boss.type);
                this.heroes = await this.heroComp.getCompleteHeroes(tier);
                //console.log(this.heroes);
                if (this.heroes?.length > 0) {
                    await this.setHeroesStats();
                }
            } else {
                console.log(type + 'boss not found');
                return;
            }
            this.display_leaderboard = true;
            let maxLevelsPerTier = this.helper.config.maxLevelsPerTier;
            this.maxLevels = this.boss?.type ? maxLevelsPerTier[parseInt(this.boss.type.slice(1))-1] : maxLevelsPerTier[0];
            this.reset('grey', 'grey', true, 'select boss');
            if (this.boss && !this.boss.effects) {
                this.copiedCards.forEach(x => { x.selected = false; });
                if (!this.user.uniqueRules || !this.user.uniqueRules[type] || !this.user.uniqueRules[type].boss_id || !this.user.uniqueRules[type].rules) {
                    let rules = await this.helper.getRules(type);
                    if (rules['message'] === 'success') {
                        if (!this.user.uniqueRules) this.user.uniqueRules = {};
                        this.user.uniqueRules[type] = rules.rules;
                        this.data.setUser(this.user);
                        this.boss.effects = rules.rules.rules;
                        let found = this.bosses.find(b => b.type === type);
                        if (found) found.uniqueRules = rules.rules.rules;
                    } else {
                        this.helper.showSnackbarMessage(rules['message']);
                        this.new_boss();
                        return;
                    }
                } else {
                    if (this.user.uniqueRules[type].boss_id !== JSON.stringify(temp['id'])) {
                        let rules = await this.helper.getRules(type);
                        if (rules['message'] === 'success') {
                            if (!this.user.uniqueRules) this.user.uniqueRules = {};
                            this.user.uniqueRules[type] = rules.rules;
                            this.data.setUser(this.user);
                            let found = this.bosses.find(b => b.type === type);
                            if (found) found.uniqueRules = rules.rules.rules;
                            this.boss.effects = rules.rules.rules;
                        } else {
                            this.helper.showSnackbarMessage(rules['message']);
                            this.new_boss();
                            return;
                        }
                    } else {
                        this.boss.effects = this.user.uniqueRules[type].rules;
                        //console.log(this.boss.effects);
                    }
                }
            }
            this.applyAbilities();
            this.myFights = await this.helper.userFights(this.boss.id);
        } else {
            console.error('Missing boss selection data.');
        }
        this.preload_audio();
    }
    reset_all() {
        this.subs.unsubscribe();
        this.helper.navigate('slcards');
        return;
        this.replay = false;
        this.fighting = false;
        this.battle_active = false;
        this.quit_battle = false;
        this.pause();
        this.subs.unsubscribe();
        this.monsters_loaded=0;
        this.summoners_loaded=0;
        this.sound.stop_sounds();
        this.sound.stop_music();
        this.replay = false;
        this.display_leaderboard = false;
        this.display_recents = false;
        this.fighting = false;
        this.battle_active = false;
        this.boss = null;
        this.data.setReplayBoss(null);
        this.data.setResult(null);
        this.data.setFightScreen(false);
        this.data.setBossView('main');
        this.data.setScrolling([]);
        this.pause();
        this.fight_screen = false;
        this.clearFilters();
        this.unlock_boss();
        this.filtersHeight = 0;
        this.float_filters_offset = 0;
        this.float_filters = false;
    }
    unlock_boss() {
        this.lockBoss=false;
        this.boss=null;
        this.clear_monsters();
    }
    clear_monsters() {
        this.summoners_or_monsters = false;
        this.summoners_selected = 0;
        this.monsters_selected = 0;
        this.selected_summoners = [];
        this.selected_monsters = [];
        this.selected = [];
        this.mana_count=0;
        //this.copiedCards.forEach(c => { c.selected = false });
    }
    summonor_position(e) {
        //console.log('summonor_position', e)
    }
    monster_position(e) {
        //console.log('monster_position', e)
    }
    getStamRegen() {
        var stam = this.helper.getStamRegen()
        return stam;
    }
    healthLeft() {
        let val = (this.boss.stats.health / this.boss.ogStats.health) * 100;
        return val > 0 ? val : 0;
    }
    armorLeft() {
        let val = (this.boss.stats.armor / this.boss.ogStats.armor) * 100;
        return val > 0 ? val : 0;
    }
    get_img_color(src,cb?) {
        this.color_mode = String(Math.round(Math.random()*3));
        var numb = this.color_mode;
        var img = document.createElement('img');
        img.setAttribute('src', src+'?'+new Date().getTime());
        img.setAttribute('crossOrigin', '');
        img.addEventListener('load', function() {
            var vibrant = new Vibrant(img,64);
            var swatches = vibrant.swatches();
            if(numb=='0')cb(swatches.Vibrant.getHex());
            else if(numb=='1')cb(swatches.Muted.getHex());
            else if(numb=='2')cb(swatches.DarkVibrant.getHex());
            else if(numb=='3')cb(swatches.DarkMuted.getHex());
        });
    }
    preload_audio() {
        this.sound.preload('sacred_battle_loop');
        this.sound.preload('mixkit-cinematic-impact-thunder-1286');
        for(let i = 0; i < 10; i++)this.sound.preload('playing_cards_'+i);
        this.sound.preload('Elemental_Sword_EarthAttack_0'+1);
        this.sound.preload('Elemental_Sword_PoisonAttack_0'+2);
        this.sound.preload('Elemental_Sword_IceAttack V'+1);
        this.sound.preload('Magic_Sword_Attack_0'+1);
    }

    toggleFavorite(card) {
        if (card) {
            card.favorite = !card.favorite;
            this.helper.toggleFavorite(card);
        }
    }
}
