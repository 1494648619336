<section id="bossfight" *ngIf="boss">

    <!-- Overlay (when paused) -->
    <div *ngIf="battle_paused && production" (click)="resume_game()" class="paused">
        <i class="fa-solid fa-pause unhovered"></i>
        <i class="fa-solid fa-play hovered"></i>
    </div>

    <!-- Preamble -->
    <div class="preamble" [@black_background]="preamble_fade" *ngIf="preamble">
        <div class="blue_spark center_effect" *ngIf="blue_spark_down"></div>
        <div class="red_spark center_effect" *ngIf="red_spark_down"></div>
        <div class="card_wrapper intro" [@intro_left]="intro_cards" 
             *ngFor="let c of result.team | id:'0'; let i = index">
            <splinterforge-card
                [show_card_id]="false"
                [hero]="c"
                [show_mana]="false"
                [smaller]="false"
                [larger]="true"
                [user]="user"
                [effects]="true"
                [currentClass]="c.uid_split">
            </splinterforge-card>
            <h1 class="card_name_vs">{{c.name}}</h1>
        </div>
        <div class="boss intro" [@intro_right]="intro_cards">
            <div class="bosswrapper">
                <splinterforge-card 
                    [show_card_id]="false"
                    class="boss__" 
                    [force_show_stats]="true"
                    [rules_box]="true"
                    [boss]="boss">
                </splinterforge-card>
            </div>
            <h1 class="boss_name_vs">{{boss.name}}</h1>
        </div>
        <h1 class="cards_vs" *ngIf="preamble_fade" [@fade]="fade_vs">VS.</h1>
        <div class="skip_intro" [@fade]="show_skip_intro" (click)="skip_intro()">
            <div class="internal_control">
                <i class="fa-solid fa-share"></i>
                <span>Skip Intro</span>
            </div>
        </div>
    </div>
    <!-- canvas -->
    <div class="full_inner" [@darken]="battle_paused&&production" *ngIf="boss&&result">

        <!-- Animations -->
        <div id="damage_arrow_weapon"
            *ngIf="show_effects"
            [style.display]="showDamageArrow?'block':'none'"
            [style.transform]="'translate('+weapon_left+'px, '+weapon_top+'px)'"
            [style.transition]="DamageTransitionTime"
            [attr.class]="(damageArrowType?'weapon_container '+damageArrowType:'weapon_container')+(positioning_marker?' animated':'')">
            <div class="weapon_rotate" [style.transition]="DamageTransitionTime" *ngIf="damageArrowType && damageArrowType != 'death'"> 
                <div [style.transition]="DamageTransitionTime" [style.transform]="'rotate('+weapon_rotate+'deg)'">
                    <!-- Melee -->
                    <div *ngIf="damageArrowType == 'attack' && damageArrowType">
                        <div *ngFor="let img of weapons; let i = index">
                            <img class="aimed_weapon" *ngIf="hit_damage==i" [attr.src]="'/assets/weapons/'+img" />
                        </div>
                    </div>
                    <!-- Magic -->
                    <div *ngIf="damageArrowType == 'magic' && damageArrowType" class="magic">
                       <div [attr.class]="'magical_'+(hit_damage>10)?hit_damage:10"></div>
                    </div>
                    <!-- Ranged -->
                    <div *ngIf="damageArrowType == 'ranged' && damageArrowType">
                        <div *ngFor="let img of weapons; let i = index">
                            <img class="aimed_weapon" *ngIf="hit_damage==i && hit_damage < weapons.length" [attr.src]="'/assets/weapons/'+img" />
                        </div>
                        <img class="aimed_weapon" *ngIf="hit_damage > weapons.length" src="/assets/weapons/gold_sword.png" />
                    </div>
                    <!-- Miss -->
                    <!-- <img class="aimed_weapon" *ngIf="!damageArrowType || damageArrowType == 'miss'" src="/assets/weapons/silver-sword.png" /> -->
                    <img *ngIf="!damageArrowType || damageArrowType == 'miss'" class="miss_sword" src="/assets/badges/sword.png"  />
                    <img *ngIf="damageArrowType == 'death'"  src="/assets/badges/skull.png" />
                </div>
            </div>
        </div>

        <!-- Non-Animations -->
        <div id="damage_arrow"
            *ngIf="!show_effects"
            [style.display]="showDamageArrow?'block':'none'"
            [style.transform]="'translate('+damage_left+'px, '+damage_top+'px)'"
            [style.transition]="DamageTransitionTime"
            [attr.class]="(damageArrowType ? 'damage_container '+damageArrowType : 'damage_container')+(positioning_marker?' animated':'')">
            <span class="hit_damage" *ngIf="damageArrowType!='miss'&&damageArrowType!='ranged_miss'&& damageArrowType!='death' ">{{hit_damage}}</span>
            <span class="hit_damage" *ngIf="damageArrowType == 'miss' || damageArrowType == 'ranged_miss'">Miss!</span>
            <img *ngIf="damageArrowType == 'attack' && (!hit_damage || hit_damage==1)" src="/assets/badges/sword.png" />
            <img *ngIf="damageArrowType == 'attack' && hit_damage==2" src="/assets/badges/weapon.png" />
            <img *ngIf="damageArrowType == 'attack' && hit_damage==3 " src="/assets/badges/swords.png" />
            <img *ngIf="damageArrowType == 'attack' && hit_damage==4 " src="/assets/badges/axe.png" />
            <img *ngIf="damageArrowType == 'attack' && hit_damage>4 " src="/assets/badges/dual.png" />
            <img *ngIf="damageArrowType == 'backfire'" src="/assets/badges/helmit.png" />
            <img *ngIf="damageArrowType == 'ranged' || damageArrowType == 'ranged_miss'" src="/assets/badges/arrows.png" />
            <img *ngIf="damageArrowType == 'poison'" src="/assets/badges/potion.png" />
            <img *ngIf="damageArrowType == 'magic'"  src="/assets/badges/magic.png"  />
            <img *ngIf="damageArrowType == 'heal' || damageArrowType == 'buff'" style="position: relative;top:6px;" src="/assets/stats_icons/health.png" />
            <img *ngIf="damageArrowType == 'miss'" src="/assets/badges/sword.png"  />
            <img *ngIf="damageArrowType == 'death'"  src="/assets/badges/skull.png" />
        </div>

        <!-- Battle Zone -->
        <div (click)="battle_zone_click($event)" class="battle_zone" [style.min-height]="playfeild_min_height+'px'">
            <div class="boss" *ngIf="boss">
                <div *ngIf="boss && boss.name" [attr.class]="stagger_cards > (1+(result.team|type:'Summoner').length+(result.team|type:'Monster').length) ? 'bosswrapper boss_box_container' : 'bosswrapper boss_box_container undealt_boss'">
                    <splinterforge-card 
                        *ngIf="boss"
                        class="boss__"
                        [boss]="boss"
                        [effects]="boss_effects"
                        [dead]="dead[boss.name]"
                        [ability]="boss_ability"
                        [fx_ability]="ability_popover[boss.name]"
                        [fx_selected]="selected[boss.name]"
                        [fx_blinking]="selected[boss.name]"
                        [fx_attacking]="attacking[boss.name]"
                        [fx_shaken]="shaken[boss.name]"
                        [fx_fade]="fade[boss.name]"
                        [fx_ranged]="ranged[boss.name]"
                        [fx_hit_magic]="hit_magic[boss.name]"
                        [fx_jump_down]="jump_down[boss.name]"
                        [fx_hit_down]="hit_down[boss.name]"
                        [fx_miss]="miss[boss.name]"
                        [fx_forge]="forge[boss.name]"
                        [fx_forge_attack_plus]="forge_attack_plus[boss.name]"
                        [fx_forge_attack_minus]="forge_attack_minus[boss.name]"
                        [fx_blue_spark]="blue_spark[boss.name]"
                        [fx_red_spark]="red_spark[boss.name]"
                        [fx_splash_dmg]="show_splash_damage[boss.name]"
                        [hit_damage]="hit_damage"
                        [force_show_stats]="true"
                        [card_style_top]="card_style_top[boss.name]"
                        [card_style_left]="card_style_left[boss.name]"
                        [card_style_float]="card_style_float[boss.name]"
                        [hit_damage_type]="hit_damage_type"
                        [hit_damage]="hit_damage[boss.name]"
                        [hit_damage_type]="hit_damage_type[boss.name]"
                        [forge_speed]="forge_speed"
                        [starter]="show_starter_ribbon"
                        [preamble]="preamble_animations">
                    </splinterforge-card>
                </div>
            </div>
            <!-- Log -->
            <battle-log
                [boss]="boss"
                [play]="play"
                [user]="user"
                [result]="result"
                [in_battle]="running_fight"
                [show_log]="show_log"
                [currentClass]="currentClass"
                [new_entry]="fresh_entry"
                [log_entries]="scrolling"> 
            </battle-log>
            <!-- Monsters -->
            <div class="monsters_in_the_canvas">
                <div class="centerbox summoners" #SummonerContainer>
                    <div class="card_container">
                        <div *ngFor="let c of result.team | type:'Summoner'; let i = index" 
                            [@selected_team]="selected_team"
                            [attr.class]="stagger_cards > i+1 ? 'card_wrapper' : 'card_wrapper undealt'">
                            <splinterforge-card
                                [card]="c"
                                [attr.id]="'summoner_card summoner_card_'+i"
                                class="itembox"
                                [summoner]="true"
                                [showTitle]="false"
                                [showStats]="false"
                                [showLeague]="false"
                                [showAbilities]="true"
                                [smaller]="true"
                                [effects]="true"
                                [dead]="dead[c.name]"
                                [ability]="ability"
                                [fx_ability]="ability_popover[c.name]"
                                [fx_selected]="selected[c.name]"
                                [fx_blinking]="selected[c.name]"
                                [fx_attacking]="attacking[c.name]"
                                [fx_shaken]="shaken[c.name]"
                                [fx_fade]="fade[c.name]"
                                [fx_ranged]="ranged[c.name]"
                                [fx_hit_magic]="hit_magic[c.name]"
                                [fx_jump_down]="jump_down[c.name]"
                                [fx_hit_down]="hit_down[c.name]"
                                [fx_miss]="miss[c.name]"
                                [fx_forge]="forge[c.name]"
                                [fx_forge_attack_plus]="forge_attack_plus[c.name]"
                                [fx_forge_attack_minus]="forge_attack_minus[c.name]"
                                [fx_blue_spark]="blue_spark[c.name]"
                                [fx_red_spark]="red_spark[c.name]"
                                [fx_splash_dmg]="show_splash_damage[c.name]"
                                [hit_damage]="hit_damage[c.name]"
                                [hit_damage_type]="hit_damage_type[c.name]"
                                [force_show_stats]="true"
                                [card_style_top]="card_style_top[c.name]"
                                [card_style_left]="card_style_left[c.name]"
                                [card_style_float]="card_style_float[c.name]"
                                [forge_speed]="forge_speed"
                                [preamble]="preamble_animations"
                                [starter]="show_starter_ribbon"
                                (loaded)="cards_loaded('summoners')">
                            </splinterforge-card>
                        </div>
                        <div *ngFor="let c of result.team | id:'0'; let i = index" 
                            [@selected_team]="selected_team"
                            [attr.class]="stagger_cards > (i+1+(result.team|type:'Summoner').length) ? 'player_boss card_wrapper' : 'player_boss card_wrapper undealt'">
                            <splinterforge-card
                                [hero]="c"
                                [attr.id]="'player_boss_card player_boss_card_'+i"
                                class="itembox"
                                [summoner]="true"
                                [smaller]="true"
                                [effects]="true"
                                [user]="user"
                                [dead]="dead[c.name]"
                                [showAbilities]="true"
                                [ability]="ability"
                                [fx_ability]="ability_popover[c.name]"
                                [fx_selected]="selected[c.name]"
                                [fx_blinking]="selected[c.name]"
                                [fx_attacking]="attacking[c.name]"
                                [fx_shaken]="shaken[c.name]"
                                [fx_fade]="fade[c.name]"
                                [fx_ranged]="ranged[c.name]"
                                [fx_hit_magic]="hit_magic[c.name]"
                                [fx_jump_down]="jump_down[c.name]"
                                [fx_hit_down]="hit_down[c.name]"
                                [fx_miss]="miss[c.name]"
                                [fx_forge]="forge[c.name]"
                                [fx_forge_attack_plus]="forge_attack_plus[c.name]"
                                [fx_forge_attack_minus]="forge_attack_minus[c.name]"
                                [fx_blue_spark]="blue_spark[c.name]"
                                [fx_red_spark]="red_spark[c.name]"
                                [fx_splash_dmg]="show_splash_damage[c.name]"
                                [hit_damage]="hit_damage[c.name]"
                                [hit_damage_type]="hit_damage_type[c.name]" 
                                [force_show_stats]="true"
                                [card_style_top]="card_style_top[c.name]"
                                [card_style_left]="card_style_left[c.name]"
                                [card_style_float]="card_style_float[c.name]"
                                [forge_speed]="forge_speed"
                                [currentClass]="c.currentClass"
                                [preamble]="preamble_animations"
                                [starter]="show_starter_ribbon"
                                (loaded)="cards_loaded('boss')">
                            </splinterforge-card>
                        </div>
                    </div>
                </div>
                <div class="centerbox monster" #MonsterContainer>
                    <div class="card_container">
                        <div 
                            class="card_loop" 
                            #monster_cont 
                            [@selected_team]="selected_team"
                            *ngFor="let c of result.team | type:'Monster'; let i = index">
                            <div *ngIf="c.uid_hero_index < 0" [attr.id]="'monster_card monster_card_'+i" [attr.class]="stagger_cards > (i+2+(result.team|type:'Summoner').length) ? 'card_wrapper' : 'card_wrapper undealt'">
                                <splinterforge-card
                                    [card]="c"
                                    class="itembox"
                                    *ngIf="c.uid_hero_index < 0"
                                    [smaller]="true"
                                    [attack]="attack[c.name]"
                                    [hit_damage]="hit_damage"
                                    [showStats]="true"
                                    [hit_damage_type]="hit_damage_type[c.name]"
                                    [effects]="true"
                                    [dead]="dead[c.name]"
                                    [ability]="c.ogAbilities"
                                    [fx_ability]="ability_popover[c.name]"
                                    [fx_selected]="selected[c.name]"
                                    [fx_blinking]="selected[c.name]"
                                    [fx_attacking]="attacking[c.name]"
                                    [fx_shaken]="shaken[c.name]"
                                    [fx_fade]="fade[c.name]"
                                    [fx_ranged]="ranged[c.name]"
                                    [fx_hit_magic]="hit_magic[c.name]"
                                    [fx_jump_down]="jump_down[c.name]"
                                    [fx_hit_down]="hit_down[c.name]"
                                    [fx_miss]="miss[c.name]"
                                    [fx_forge]="forge[c.name]"
                                    [fx_forge_attack_plus]="forge_attack_plus[c.name]"
                                    [fx_forge_attack_minus]="forge_attack_minus[c.name]"
                                    [fx_blue_spark]="blue_spark[c.name]"
                                    [fx_red_spark]="red_spark[c.name]"
                                    [fx_splash_dmg]="show_splash_damage[c.name]"
                                    [hit_damage]="hit_damage"
                                    [hit_damage_type]="hit_damage_type"
                                    [force_show_stats]="true"
                                    [card_style_top]="card_style_top[c.name]"
                                    [card_style_left]="card_style_left[c.name]"
                                    [card_style_float]="card_style_float[c.name]"
                                    [hit_damage]="hit_damage[c.name]"
                                    [hit_damage_type]="hit_damage_type[c.name]"
                                    [forge_speed]="forge_speed"
                                    [preamble]="preamble_animations"
                                    [starter]="show_starter_ribbon"
                                    (loaded)="cards_loaded('monsters')">
                                </splinterforge-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <ng-fireworks 
                [update]="update_fw" 
                [start]="start_fw" 
                [stop]="stop_fw" 
                [options]="running_fw_options" 
                [trace]="fw_trace"
                class="fireworks">
            </ng-fireworks> -->
        </div>

        <div class="bottomnav">
            <button class="btn btn-danger" (click)="dismissResults()">Dismiss</button>
            <button class="btn btn-success" (click)="viewRewards=true">Rewards</button>
        </div>
    </div>

    <!-- Volume Control -->
    <div class="skip_intro sound" *ngIf="enable_sound">
        <div class="internal_control">
            <i *ngIf="mute_sounds" (click)="toggle_sound_mute()" class="fa-solid fa-volume-high"></i>
            <i *ngIf="!mute_sounds" (click)="toggle_sound_mute()" class="fa-solid fa-volume-xmark"></i>
            <span (click)="toggle_sound_volume()">Sound</span>
        </div>
        <ngx-slider
            *ngIf="show_sound_vol"
            class="sound-slider" 
            [(value)]="sound_vol" 
            [options]="VolumeControls" 
            (valueChange)="sound_volume($event)" 
            (userChangeEnd)="sound_volume_end($event)">
        </ngx-slider>
    </div>
    <div class="skip_intro music" *ngIf="enable_music">
        <div class="internal_control">
            <i *ngIf="mute_music" (click)="toggle_music_mute()" class="fa-solid fa-music"></i>
            <i *ngIf="!mute_music" (click)="toggle_music_mute()" class="fa-solid fa-volume-xmark"></i>
            <span (click)="toggle_music_volume()">Music</span>
        </div>
        <ngx-slider
            *ngIf="show_music_vol"
            class="music-slider" 
            [(value)]="music_vol" 
            [options]="VolumeControls" 
            (valueChange)="music_volume($event)" 
            (userChangeEnd)="music_volume_end($event)">
        </ngx-slider>
    </div>
    <div class="skip_intro effects">
        <div class="internal_control" (click)="toggle_effects()">
            <i *ngIf="enable_fw" class="fa-solid fa-check"></i>
            <i *ngIf="!enable_fw" class="fa-solid fa-x"></i>
            <span>Effects</span>
        </div>
    </div>

    <!-- Rewards / End Game Modal -->
    <rewards-modal
        [show]="viewRewards"
        [title]="'Rewards'"
        [Damage]="Damage"
        [Points]="Points"
        [boss]="boss"
        (done)="close_rewards($event)"
        [Forgium]="rewardedForgium"
        [Electrum]="rewardedElectrum"
        [line_one]="winning_message['items']"
        [line_two]="winning_message['points']"
    ></rewards-modal>
</section>
<img class="hidden" src="/assets/badges/sword.png" />
<img class="hidden" src="/assets/badges/weapon.png" />
<img class="hidden" src="/assets/badges/swords.png" />
<img class="hidden" src="/assets/badges/axe.png" />
<img class="hidden" src="/assets/badges/dual.png" />
<img class="hidden" src="/assets/badges/arrows.png" />
<img class="hidden" src="/assets/badges/potion.png" />
<img class="hidden" src="/assets/badges/magic.png"  />
<img class="hidden" src="/assets/badges/sword.png"  />
<img class="hidden" src="/assets/stats_icons/health.png" /> 
<img class="hidden" src="/assets/badges/skull.png" />
<img class="hidden" src="/assets/sprites/blue_spark.png" />
<img class="hidden" src="/assets/sprites/red_spark.png" />
<div class="hidden">{{sound.license_code}}</div>