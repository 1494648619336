<div [attr.class]="'sf_card '+(selected?'active ':'')+(hover?'card_hover_large ':'')+(smaller?' small':'')+(animate_in?' scale_down':'')" 
     *ngIf="sfcard" [@scale_in]="animate_in" [attr.name]="sfcard.name">
    <a class="card_marker" [attr.id]="sfcard.cid"></a>
    <div [attr.class]="(sfcard.flip||flipped?'content flip':'content')+(animate_in?' slow':'')">
        <div class="back card_hover">
            <img (load)="img_load()" 
                 src="https://splinterforge.s3.us-east-2.amazonaws.com/packs/Alpha+Card+Back.png" 
                 width="222" 
                 height="310" 
                 loading="lazy">
        </div> 
        <div class="cardselector front" [ngStyle]="{'box-shadow': style_boxshadow}">
            <div class="sfcard">

                <img *ngIf="sfcard.foil" (load)="img_load()" src="https://splinterforge.s3.us-east-2.amazonaws.com/frame_art/gf_frame2.png" class="frame" loading="lazy">
                <img *ngIf="!sfcard.foil" (load)="img_load()" src="https://splinterforge.s3.us-east-2.amazonaws.com/frame_art/alpha_frame.png" class="frame" loading="lazy">

                <div class="sfcardimg">
                    <img (load)="img_load()" src="{{sfcard.imgURL}}">
                </div>

                <div *ngIf="sfcard && sfcard.rarity" class="sfrarity" [attr.title]="card_slot_type">
                    <img class="staticon" src="{{'https://splinterforge.s3.us-east-2.amazonaws.com/frame_art/rarity/' + sfcard.rarity + '.png'}}" loading="lazy">
                </div>

                <span *ngIf="stackView && sfcards && quantity > 1" 
                      class="card_quantity" title="Quantity owned: {{quantity}}">
                    <span class="qtybadge">
                        <i class="fa-solid fa-xmark"></i>
                        <span [attr.class]="quantity>99?quantity>999?'small':'mid':''">{{quantity}}</span>
                    </span>
                </span>

                <span *ngIf="!stackView && sfcards && sfcard.charges && sfcard.charges.current" 
                        class="card_quantity" title="Charges: {{sfcard.charges.current}}">
                    <span class="qtybadge">
                        <span class="small">{{sfcard.charges.current}}/{{sfcard.charges.max}}</span>
                    </span>
                </span>

                <div class="" *ngIf="sfcard.type !== 'socket'">
                    <div class="stat speed scaled" [@popin]="sfcard.stat === 'Speed'" [attr.title]="'Speed: '+card_stat">
                        <div class="icon">
                            <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/speed.png" loading="lazy">
                        </div>
                        <div class="overlay"><span>{{card_stat}}</span></div>
                    </div>
                    <div class="stat melee scaled" [@popin]="sfcard.stat === 'Attack'" [attr.title]="'Attack: '+card_stat">
                        <div class="icon">
                            <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/melee-attack.png" loading="lazy">
                        </div>
                        <div class="overlay"><span>{{card_stat}}</span></div>
                    </div>
                    <div class="stat magic scaled" [@popin]="sfcard.stat === 'Magic'" [attr.title]="'Magic: '+card_stat">
                        <div class="icon">
                            <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/magic-attack.png" loading="lazy">
                        </div>
                        <div class="overlay"><span>{{card_stat}}</span></div>
                    </div>
                    <div class="stat ranged scaled" [@popin]="sfcard.stat === 'Ranged'" [attr.title]="'Ranged: '+card_stat">
                        <div class="icon">
                            <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/ranged-attack.png" loading="lazy">
                        </div>
                        <div class="overlay"><span>{{card_stat}}</span></div>
                    </div>
                    <div class="stat health scaled" [@popin]="sfcard.stat === 'Health'" [attr.title]="'Health: '+card_stat">
                        <div class="icon">
                            <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/health.png" loading="lazy">
                        </div>
                        <div class="overlay"><span>{{card_stat}}</span></div>
                    </div>
                    <div class="stat armor scaled" [@popin]="sfcard.stat === 'Armor'" [attr.title]="'Armor: '+card_stat">
                        <div class="icon">
                            <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/armor.png" loading="lazy">
                        </div>
                        <div class="overlay"><span>{{card_stat}}</span></div>
                    </div>
                </div>
                <div class="sockets" *ngIf="sfcard.type === 'socket'">
                    <div class="stat melee scaled" [@popin]="sfcard.stat === 'Attack'" *ngIf="sfcard.stat === 'Attack'" [attr.title]="'Attack: '+card_stat">
                        <div class="icon">
                            <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/melee-attack.png" loading="lazy">
                        </div>
                        <div class="overlay"><span>{{card_stat}}</span></div>
                    </div>
                    <div class="stat magic scaled" [@popin]="sfcard.stat === 'Magic'" *ngIf="sfcard.stat === 'Magic'" [attr.title]="'Magic: '+card_stat">
                        <div class="icon">
                            <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/magic-attack.png" loading="lazy">
                        </div>
                        <div class="overlay"><span>{{card_stat}}</span></div>
                    </div>
                    <div class="stat ranged scaled" [@popin]="sfcard.stat === 'Ranged'" *ngIf="sfcard.stat === 'Ranged'" [attr.title]="'Ranged: '+card_stat">
                        <div class="icon">
                            <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/ranged-attack.png" loading="lazy">
                        </div>
                        <div class="overlay"><span>{{card_stat}}</span></div>
                    </div>
                    <div class="stat speed scaled" [@popin]="sfcard.stat === 'Speed'" *ngIf="sfcard.stat === 'Speed'" [attr.title]="'Speed: '+card_stat">
                        <div class="icon">
                            <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/speed.png" loading="lazy">
                        </div>
                        <div class="overlay"><span>{{card_stat}}</span></div>
                    </div>
                    <div class="stat health scaled" [@popin]="sfcard.stat === 'Health'" *ngIf="sfcard.stat === 'Health'" [attr.title]="'Health: '+card_stat">
                        <div class="icon">
                            <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/health.png" loading="lazy">
                        </div>
                        <div class="overlay"><span>{{card_stat}}</span></div>
                    </div>
                    <div class="stat armor scaled" [@popin]="sfcard.stat === 'Armor'" *ngIf="sfcard.stat === 'Armor'" [attr.title]="'Armor: '+card_stat">
                        <div class="icon">
                            <img src="https://d36mxiodymuqjm.cloudfront.net/website/stats/armor.png" loading="lazy">
                        </div>
                        <div class="overlay"><span>{{card_stat}}</span></div>
                    </div>
                </div>
                <div [attr.class]="'sockets sm_'+sfcard.sockets" *ngIf="sfcard.sockets > 0">
                    <div *ngFor="let sock of sfcard.socketed">
                       <div class="abilities" (mouseenter)="_h(1)" (mouseleave)="_h(0)">
                            <img class="unequipped_socket" *ngIf="sock.img_url === ''" src="https://splinterforge.s3.us-east-2.amazonaws.com/frame_art/slots/socket.png" title="Imbue a Gem or Rune" loading="lazy" (click)="click_socket()">
                            <img class="equipped_socket" *ngIf="sock.img_url !== ''" src="{{sock.img_url}}" (click)="click_socket()" loading="lazy">
                            <div class="card_abilities">
                                <card-abilities-window
                                    [img]="sock.img_url===''?'https://splinterforge.s3.us-east-2.amazonaws.com/frame_art/slots/socket.png':sock.img_url"
                                    [name]="sock.img_url===''?'Empty Socket':sock.name"
                                    [desc]="sock.img_url===''?'Imbue a Gem or Rune in this socket.':sock.description"
                                    [description]="true"

                                ></card-abilities-window>
                            </div>
                        </div>
                    </div>
                </div>

                <div [attr.class]="(sfcard.name&&sfcard.name.length>21) ? 'slname wide smaller' : 'slname wide'" [attr.title]="sfcard.name">
                    {{sfcard.name}}
                </div>
                
                <div *ngIf="sfcard.type !== 'socket'" class="sllevel" [attr.title]="'★ '+sfcard.level">
                    <span class="star">★ </span>
                    <span class="level_indicator">{{sfcard.level}}</span>
                </div>
                
                <div *ngIf="sfcard.stat === 'ability' || sfcard.slot === 'relic'" [attr.title]="sfcard.ability_description" class="abilitydesc">
                    {{sfcard.ability_description}}
                </div>

                <!--<boss-max-levels
                    [maxLevels]="sfcard.boss_levels"
                    [small]="true"
                ></boss-max-levels>-->
            </div>
        </div>
    </div>
</div>