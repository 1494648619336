import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'abilities_available'
})
export class AbilitiesAvailablePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if(!args) return value;
    let values = [];
    for (var i = 0; i < value.length; i++) {
      for (var j = 0; j < args.length; j++) {
        if ( args[j].abilities.includes(value[i]) ) {
          if(!values.includes(value[i]))values.push(value[i]);
        }
      }
    }
    return values;
  }
}