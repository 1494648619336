<section class="forgemodal gold" [@fade]="show">
    <div *ngIf="product" class="boxwrap" [@flip_up]="show">
		<h1 [innerHTML]="product.name"></h1>
		<div>
			<item-image [item]="product"></item-image>
			<div class="item_info">
				<h2 class="item_title" *ngIf="product&&product.name">{{product.name}}</h2>
				<p [innerHTML]="product.description"></p>
				<div class="button_group">
					<button (click)="done_button(0)" class="button red">Close</button>
					<button (click)="done_button(1)" class="button gold purchase">Purchase</button>
					<div class="clear"></div>
				</div>
			</div>
		</div>
		<div class="closewrapper" (click)="close()">
			<i class="fa-solid fa-x"></i>
		</div>
	</div>
	<div class="backdrop" [@pointer]="show" (click)="close()"></div>
</section>