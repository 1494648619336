import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'selected'
})
export class SelectedPipe implements PipeTransform {
	transform(value: any, args?: any): any {
		let values = [];
		for (var i = 0; i < value.length; i++) {
			if (value[i].selected) values.push(value[i]);
		}
		return values;
	}
}