import { Component, OnChanges, Input } from '@angular/core';
@Component({
  selector: 'card-bars',
  templateUrl: './bars.component.html',
  styleUrls: ['./bars.component.styl']
})
export class BarsComponent implements OnChanges {
  constructor() { }
  @Input('armor') armor: any;
  @Input('health') health: any;
  @Input('small') smaller: any;
  @Input('degrees') degrees = 270;
  @Input('armor_active') armor_active: any;
  @Input('health_active') health_active: any;
  @Input('original_armor') original_armor: any;
  @Input('original_health') original_health: any;
  ngOnChanges(): void {
    this.degrees = Number(Number(Number(this.armor/this.original_armor)*180)+270);
  }
}