import { Component, OnInit, OnDestroy, ViewChild, HostListener, ElementRef } from '@angular/core';
import { Item, User } from '@app/_models';
import { DataService } from '@app/_services/data.service';
import { HelperService } from '@app/_services/helper.service';
import { flip,filters_show, overflow, shop_category, fade, scale_pop, carddropdown } from '../app.animations';
import { loader,opacity } from '../slcards/slcards.animations';
import { dropdown } from '../hero/hero.animations';
@Component({ 
    templateUrl: 'openpacks.component.html', 
    animations: [filters_show,dropdown,flip,overflow,shop_category,loader,opacity,scale_pop,carddropdown],
    styleUrls: [
        '../cards/cards.component.styl',
        '../shop/shop.component.styl',
        './openpacks.component.styl'
    ]
})
export class OpenPacksComponent implements OnInit, OnDestroy {
    loading: Boolean = false;
    newRelic: any;
    user: User;
    quantity = [];
    receiver: string = '';
    selectedPack: any;
    eqqty: number = 1;
    enqty: number = 1;
    amount: number = 0;
    fragsToUse: number = 0;
    conjureCost: number = 0;
    rarityOdds: any;
    qty = 1;
    show = 'open';
    sendPacks: Boolean = false;
    includeLeveled: Boolean = true;
    filters = new Filter('',false,false,'All','All','All','All');
    reforgeHistory = [];
    transmuteHistory = [];
    load_stagger = [];
    newCards: any = null;
    uniqueCards: any = null;
    engineBalance: number;
    select_packs = [];
    showAvailable = false;
    pack_balances = [];
    sfCards = [];
    number_of_reforged_cards = { count: 0, total: 0, divider: 0 };
    category_hidden = [false, false, false, false, false, false];
    showOpenPack = false;
    revealed_all = false;
    showHIVETransfer = false;
    revealed = false;
    total_forge = '';
    reforge_count = 0;
    reforge_total = 0;
    reforge_rarity = 0;
    transmute_rarity = 0;
    currentPrice = 0;
    showSuccessModal: boolean;
    successModalTitle: string;
    successModalMessage: string;
    max_possible: number;
    showReforgeConfirmation: boolean;
    reforgeTitle = 'Reforge';
    flash_max: boolean;
    confirmation: string;
    show_slots: boolean;
    show_types: boolean;
    card_details='0';
    show_card='1';
    detail_card: any;
    history = [
        'Alpha Pack',
        'Enhancement Pack'
    ]
    pack_types = [
        'Alpha Pack',
        'Enhancement Pack'
    ]
    available = [];
    selected = [];
    rarity = 0;
    balance = 0;
    loadingLabel = 'Opening';
    config: any;
    bonusCards = [];
    relicCycle = [];
    promoCardPrintLimits = [3000,1000,200];
    HoverCard_top:number;
    HoverCard_left:number;
    Available_Hover:boolean;
    @ViewChild('HoverCard') HoverCard: ElementRef;
    @HostListener('document:mousemove', ['$event'])
    onMouseMove(e) {
        if(!this.Available_Hover) {
            this.HoverCard_top=Number(e.pageY-277);//-387
            this.HoverCard_left=Number(e.pageX-90);//-122.5    
        }
    }
    hide_available() {
        if(!this.Available_Hover) {
            this.showAvailable=false;
        }
    }
    toggle_info_window() {
        this.card_details=this.card_details=='1'?'0':'1';
        localStorage.setItem('card_details',this.card_details);
    }
    toggle_show_card() {
        this.show_card=this.show_card=='1'?'0':'1';
        localStorage.setItem('show_card',this.show_card);
    }
    constructor(private helper: HelperService, private data: DataService) {
        this.data.onLoadingChange().subscribe(b => this.loading = b);
        this.data.onUserChange().subscribe(c => {
            this.user = c;
            for (var t of this.pack_types) {
                this.pack_balances[t]=this.getPackBalance(t);
                this.select_packs[t] = 1;
            }
            this.config = c.config;
            this.bonusCards = this.config.bonusCards;
            this.relicCycle = this.config.relicCycle;
            //console.log(this.relicCycle, this.config);
        });
        this.data.onMySFCardsChange().subscribe(async (c) => {
            if (c) this.sfCards = JSON.parse(JSON.stringify(c));
        });
        this.data.setUser(this.helper.currentUser);
    }

    addToFragsUsed() {
        let max = 1000;
        let min = 100;
        if (this.fragsToUse+1 <= this.user.frags && this.fragsToUse+1 <= max) {
            if (this.fragsToUse+1 < min && this.user.frags < min) {
                this.helper.showSnackbarMessage('Minimum ' + min + ' relic fragments needed to manifest a relic');
                return;
            } else if (this.fragsToUse+1 < min) this.fragsToUse = min;
            else this.fragsToUse += 1;
            this.changeFragsUsed();
        } else {
            this.helper.showSnackbarMessage('May only use a maximum of ' + max + ' relic frags at once.');
        }
    }
    changeFragsUsed() {
        if (this.fragsToUse > 1000) this.fragsToUse = 1000;
        else if (this.fragsToUse > this.user.frags) this.fragsToUse = this.user.frags;
        let baseOdds = [
            { rarity: 'Common', base: 0.69, chance: 0, disp: '' },
            { rarity: 'Rare', base: 0.25, chance: 0, disp: '' },
            { rarity: 'Epic', base: 0.05, chance: 0, disp: '' },
            { rarity: 'Legendary', base: 0.0075, chance: 0, disp: '' },
            { rarity: 'Fail', base: 0.0025, chance: 0, disp: '' }
        ]

        let f = baseOdds[4].base * this.fragsToUse;
        let l = baseOdds[3].base * this.fragsToUse;
        let e = baseOdds[2].base * this.fragsToUse;
        let r = baseOdds[1].base * this.fragsToUse;
        let c = baseOdds[0].base * this.fragsToUse;

        baseOdds[4].chance = f;
        baseOdds[3].chance = l;
        baseOdds[2].chance = e;
        if (l+e+r+f > 100) baseOdds[1].chance = 100 - l - e - f;
        else baseOdds[1].chance = r;
        if (l+e+r+f >= 100) baseOdds[0].chance = 0;
        else if (l+e+r+c > 100) baseOdds[0].chance = 100-l-e-r-f;
        else baseOdds[0].chance = c;

        baseOdds.forEach(x => { x.disp = x.chance.toFixed(2); });
        this.rarityOdds = baseOdds;
        this.conjureCost = this.fragsToUse * 2;
    }
    async conjureRelic() {
        if (this.fragsToUse < 100 || this.fragsToUse > 1000) {
            this.helper.showSnackbarMessage('Minimum 100 frags, Maximum 1000 frags');
            return;
        }
        let cost = this.fragsToUse * 2; // Math.round(this.fragsToUse/2);
        if (this.user.sc.balance < cost) {
            this.helper.showSnackbarMessage('You do not have enough FORGE...');
            return;
        } else if (this.user.frags < this.fragsToUse) {
            this.helper.showSnackbarMessage('You do not have enough Relic Fragments...');
            return;
        }

        if (!confirm('You are about to burn ' + cost + ' FORGE to attempt conjuring a relic with ' + this.fragsToUse + ' fragments.\nAre you sure?')) return;
        let temp: any = await this.helper.conjureRelic(this.fragsToUse);
        if (temp.message === 'success') {
            this.newRelic = temp.card;
            this.helper.getSFCards();
            this.helper.changeSC(-cost);
            this.helper.changeFrags(-this.fragsToUse);
            console.log(temp);
        } else {
            console.log(temp);
            if (temp.message) this.helper.showSnackbarMessage(temp.message);
            else this.helper.showSnackbarMessage('error trying to conjure relic');
        }
    }
    subFromFragsUsed() {
        this.fragsToUse = this.fragsToUse > 100 ? this.fragsToUse-1 : this.fragsToUse;
        this.changeFragsUsed();
    }
    autoSelect() {
        this.selected = [];
        this.available.forEach(c => { c.checked = false; });
        let r = this.rarity;
        let milestone = r===1?4:r===2?6:r===3?8:r===4?2:99;
        let targetTotal = this.amount * milestone;
        //console.log(targetTotal);
        let currentTotal = 0;
        for (let i = 0; i < this.available.length; i++) {
            let card = this.available[i];
            if (this.includeLeveled) {
                if (currentTotal+card.combined <= targetTotal) {
                    if (this.selectToReforge(card))
                        currentTotal += card.combined;
                } 
            } else {
                if (card.level === 1 && currentTotal+card.combined <= targetTotal) {
                    if (this.selectToReforge(card))
                        currentTotal += card.combined;
                } 
            }
            if (currentTotal >= targetTotal) i = this.available.length;
        }
        this.reforgeCount();
    }
    change_amount() {
        if(this.amount > this.max_possible){
            this.amount = this.max_possible;
        }
        this.autoSelect();
    }
    subtract_from_amount() {
        this.amount=this.amount>0?this.amount-1:this.amount;
        this.autoSelect();
    }
    add_to_amount() {
        if(this.amount+1<=this.max_possible){
            this.amount=this.amount+1;
        } else {
            this.flash_max=true;
            setTimeout(()=>{ this.flash_max=false; }, 200);
            setTimeout(()=>{ this.flash_max=true; }, 400);
            setTimeout(()=>{ this.flash_max=false; }, 600);
            this.helper.showSnackbarMessage('Surpasses maximum amount to reforge.');
        }
        this.autoSelect();
    }
    open_pack(p,t) {
        this.selectedPack = p;
        this.showOpenPack = true;
    }
    ngOnInit() {
        this.getOpenings();
        this.maxPossible();
        this.card_details=localStorage.getItem('card_details');
        this.show_card=localStorage.getItem('show_card');
    }
    ngOnDestroy() {
        this.load_stagger = [];
    }
    hiveSend(p) {
        this.selectedPack = p;
        this.showHIVETransfer = true;
    }
    closeNewCardView() {
        this.newCards = null;
        this.revealed_all = false;
        this.uniqueCards = null;
        this.eqqty = 1;
        this.enqty = 1;
        this.load_stagger = [];
        window.scroll({ top: 0, left: 0  });
    }
    
    getNumReforgeCards() {
        let total = 0;
        if (this.selected?.length>0) {
            let rarity = 0;
            this.selected.forEach(s => { total += s.combined; if (rarity === 0) rarity = s.rarity; });
            let divider = (rarity === 1 ? 4 : rarity === 2 ? 6 : rarity === 3 ? 8 : rarity === 4 ? 2 : 99);
            let upgradeTo = (rarity === 1 ? 'Rare' : rarity === 2 ? 'Epic' : rarity === 3 ? 'Legendary' : 'error');
            this.number_of_reforged_cards = { count: Math.floor(total/divider), total: total, divider: divider };
        } else this.number_of_reforged_cards = { count: 0, total: 0, divider: 0 };
        return this.number_of_reforged_cards
    }
    async getOpenings() {
        if (!this.user) {
            this.helper.navigate('');
        } else {
            for (var t of this.pack_types) {
                this.pack_balances[t]=this.getPackBalance(t);
                this.select_packs[t] = 1;
            }
            this.user.openings = await this.helper.getOpenings();
            for(let i = 0; i < this.user.openings.length; i++) {
                this.user.openings[i]['symbol_image']=this.getPackImage(this.user.openings[i].symbol);
            }
            this.reforgeHistory = await this.helper.getReforgeHistory();
            this.transmuteHistory = await this.helper.getTransmuteHistory();
            //console.log(this.reforgeHistory, this.transmuteHistory);
            this.data.setUser(this.user);
        }
    }
    async getOpening(opening) {
        this.revealed = false;
        this.data.setLoading(true);
        //console.log(opening);
        let temp = await this.helper.getOpening(opening['id']);
        this.newCards = temp.cards;
        this.uniqueCards = await this.helper.getUniqueSFCards(temp.cards, true);
        this.uniqueCards.forEach((c) => {  
            c.flip = true
        });
        opening.viewed = true;
        this.data.setLoading(false);
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }

    getPackBalance(type) {
        if (!this.user) {
            this.helper.navigate('');
            return 0;
        }
        let found = this.user.inGameAssets.find(a => a.name === type);
        return found ? found.qty : 0;
    }
    getPackImage(symbol) {
        if (symbol === 'CRATE') return 'Alpha Pack';
        else if (symbol === 'BAG') return 'Enhancement Pack';
    }
    getPrice(rarity, numToMake) {
        this.currentPrice = 0;
        if (rarity === 1) this.currentPrice = numToMake*150;
        else if (rarity === 2) this.currentPrice = numToMake*500;
        else if (rarity === 3) this.currentPrice = numToMake*2500;
        else if (rarity === 4) this.currentPrice = numToMake*15000;
        return this.currentPrice;
    }
    getTotal() {
        let total = 0;
        if (this.selected?.length>0) {
            let rarity = 0;
            this.selected.forEach(s => { if (rarity === 0) rarity = s.rarity; });
            let upgradeTo = (rarity === 1 ? 'Rare' : rarity === 2 ? 'Epic' : rarity === 3 ? 'Legendary' : rarity === 4 ? 'Legendary' : 'error');
            let count = this.getNumReforgeCards(); // Math.floor(total/divider);
            //if (total%divider !== 0 && count > 0) this.helper.showSnackbarMessage('if possible, this reforge will use ' + total%divider + ' less cards');
            if(count['count']>0){
                this.total_forge = '' + count['count'] + ' ' + upgradeTo + ' card'+(count['count']==1?'s':'')+' from ' + this.selected.length + ' card'+(count['count']==1?'s':'')+' for ' + this.getPrice(rarity, count['count']) + ' FORGE. Do you want to continue?';
                if (count['total']%count['divider'] === 0) this.helper.showSnackbarMessage('Reached ' + count['count'] + ' reforged cards.');
            } else {
                this.total_forge = '';
            }
        } else {
            this.total_forge = '';
        }
    }
    filterType(t) {
      this.show_types = false;
      if(this.filters.type == t) {
        this.filters.type = 'All';
      } else {
        this.filters.type = t;  
      }
    }
    filterSlot(t) {
      this.show_slots = false;
      if(this.filters.slot == t) {
        this.filters.slot = 'All';
      } else {
        this.filters.slot = t;  
      }
    }
    toggleTypes() {
      this.show_types=this.show_types?false:true;
    }
    toggleSlot() {
      this.show_slots=this.show_slots?false:true;
    }
    clearFilters() {
      this.filters = new Filter('',false,false,'All','All','All','All');
    }
    getUpper(rarity) {
        let divisor = rarity === 1 ? 4 : rarity === 2 ? 6 : rarity === 3 ? 8 : rarity === 4 ? 2 : 999;
        let count = 0;
        this.selected.forEach(c => { count += c.combined; });
        let upper = Math.ceil(count/divisor);
        return divisor*upper;
    }
    async openTransfer(type) {
        if (! this.config || this.config.mode === 'testServer') {
            this.helper.showSnackbarMessage('not available on test server');
            return;
        }
        //console.log('openTransfer',type);
        let balances = await this.helper.loadBalances(this.user.username);
        this.selectedPack = this.user.inGameAssets.find(a => a.name === type);
        let found = balances.find(b => b.symbol === this.selectedPack.symbol);
        if (found) this.balance = found.balance;
        else this.balance = 0;
        this.showHIVETransfer = true;
    }
    maxPossible() {
        let total = 0;
        if (this.available?.length>0) {
            let rarity = 0;
            this.available.forEach(s => {
                if ((!this.includeLeveled && s.level === 1) || this.includeLeveled) total += s.combined;
                if (rarity === 0) rarity = s.rarity; 
            });
            let divider = (rarity === 1 ? 4 : rarity === 2 ? 6 : rarity === 3 ? 8 : rarity === 4 ? 2 : 99);
            this.max_possible =  Math.floor(total/divider);
        } else this.max_possible = total;
    }
    reforgeCount() {
        let count = 0;
        this.selected.forEach(c => { count += c.combined; });
        this.reforge_count =  count;
    }
    getUpperLimit() {
        this.rarity === 1 ? 
            this.reforge_total=this.getUpper(1) :
        this.rarity === 2 ?
            this.reforge_total=this.getUpper(2) :
        this.rarity === 3 ?
            this.reforge_total=this.getUpper(3) :
        this.rarity === 4 ?
            this.reforge_total=this.getUpper(4) : 0;
    }
    selectToReforge(card) {
        if (this.selected.length >= 200) {
            this.helper.showSnackbarMessage('Can select a maximum of 200 cards at once.');
            return false;
        }
        //if (type === 'reforge') {
            var cards = 0;
            switch(this.available[0].rarity) {
                case 1:
                    cards = 4;
                break
                case 2:
                    cards = 6;
                break
                case 3:
                    cards = 8;
                break;
                case 4:
                    cards = 2;
                break;
            }
            let found = this.selected.find(c => c.cid === card.cid);
            if (found) {
                this.selected = this.selected.filter(c => c.cid !== card.cid);
                card.checked=false;
            } else { 
                this.selected.push(card);
                card.checked=true;
            }
            this.getNumReforgeCards();
            this.getUpperLimit();
            this.reforgeCount();
        //}
        this.getTotal();
        return true;
    }
    confirm_reforge(type = 'Reforge') {
        this.showReforgeConfirmation = true;
        this.reforgeTitle = type;
        this.confirmation = type + ' ' + this.total_forge;
    }
    async convert(confirmed?) {
        if(!confirmed) {
            this.showReforgeConfirmation = false;
            return;
        }
        if (this.selected.length < 1) {
            this.helper.showSnackbarMessage('Must select cards to reforge first');
            return;
        }
        if (this.selected.length > 200) {
            this.helper.showSnackbarMessage('Can only select a maximum of 200 cards to reforge at a time');
            return;
        }
        let stats = this.getNumReforgeCards();
        let approved = false;
        if (stats['total']%stats['divider']!==0) approved = confirm('You will lose ' + stats['total']%stats['divider'] + ' bcx/card points if you proceed.');
        else approved = true;
        if (approved) {
            let selectedIDs = [...new Set(this.selected.map(c => c.cid))];
            if (!selectedIDs || selectedIDs.length < 1) {
                this.helper.showSnackbarMessage('error with selection');
                return;
            }
            if (selectedIDs.length !== this.selected.length) {
                this.helper.showSnackbarMessage('Somehow selected same card more than once...');
                return;
            }
            this.data.setLoading(true);
            this.loadingLabel = this.selected[0].type === 'socket' ? 'Transmuting gems/runes...' : 'Reforging equipment...';
            let response = await this.helper.reforge(selectedIDs);
            if (response?.message === true && response.newCards) {
                await this.helper.removeCIDs(selectedIDs);
                await this.selectAvailableRarity(this.selected[0].rarity, this.selected[0].type);
                await this.helper.changeSC(-this.currentPrice);
                this.selected = [];
                this.amount = 0;
                //console.log(response);
                this.newCards = response.newCards;
                this.uniqueCards = await this.helper.getUniqueSFCards(response.newCards, true);
                this.uniqueCards.forEach((c) => {  
                    c.flip = true
                });
                //console.log(this.uniqueCards);
                window.scroll({ top: 0, left: 0, behavior: 'smooth' });
                this.helper.getSFCards();
                this.selected = [];
                this.showReforgeConfirmation = false;
                this.reforgeCount();
                this.getUpperLimit();
            } else this.helper.showSnackbarMessage('transaction not broadcast');
            this.data.setLoading(true);
        }
    }
    async openPacks(type) {
        this.showOpenPack = true;
        let qty = this.select_packs[type];
        // need to account for multiple pack opening request instead of just singles
        if (qty > 200) {
            alert('Can only open 200 packs at a time.');
            return;
        }
        let item = this.user.inGameAssets.find(a => a.name === type);
        if (item && item?.qty < qty || item === undefined) {
            alert('You do not have that many packs.');
            return;
        }
        this.data.setLoading(true);
        this.loadingLabel = item.symbol === 'CRATE' ? 'Opening ' + qty + ' CRATEs...' : 'Identifiying gems & runes in ' + qty + ' BAGs...';
        let temp = await this.helper.openPacks(item.symbol, qty);
        //console.log(temp);
        if (temp['message']==='success') {
            let found = this.helper.currentUser.inGameAssets.find(a => a.name === type);
            if (found) {
                found.qty -= qty;
                this.data.setUser(this.helper.currentUser);
                this.pack_balances[type] = found.qty;
            }

            let mode = this.helper.getMode();
            if (!['live'].includes(mode)) {
                this.newCards = temp['cards'];
                this.uniqueCards = await this.helper.getUniqueSFCards(this.newCards, true);
                this.uniqueCards.forEach((c) => {  
                    c.flip = true
                });
                window.scroll({ top: 0, left: 0, behavior: 'smooth' });
                this.helper.getSFCards();
            } else {
                let verifiedTx = await this.helper.verifyTx(temp['id'], 'openPacks');
                if (!verifiedTx || verifiedTx['message'] !== 'success') {
                    alert('Could not verify pack was processed yet, processing could be behind, please refresh to see if your packs were opened.');
                    //return;
                } else {
                    this.newCards = verifiedTx['cards'];
                    this.uniqueCards = await this.helper.getUniqueSFCards(this.newCards, true);
                    this.uniqueCards.forEach((c) => {  
                        c.flip = true
                    });
                    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
                    this.helper.getSFCards();
                }
            }
            //this.showSuccessModal = true;
            //this.successModalTitle = 'Crates Have Been Opened';
            //this.successModalMessage = 'Your crates are being opened by the forgemaster.<br />You will be notified when they are ready.';
        } else this.helper.showSnackbarMessage('Error completing transaction. Please try again.');
        
        this.data.setLoading(false);
    }
    openTab(event, tabName) {
        // Declare all variables
        var i, tabcontent, tablinks;

        // Get all elements with class="tabcontent" and hide them
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        // Get all elements with class="tablinks" and remove the class "active"
        if (event !== 'firstCall') {
            tablinks = document.getElementsByClassName("tablinks");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            event.currentTarget.className += " active";
        }
        // Show the current tab, and add an "active" class to the button that opened the tab
        document.getElementById(tabName).style.display = "block";
    }
    
    revealAll() {
        let skipped = 0;
        for (let i = 0; i < this.uniqueCards.length; i++) {
            if (!this.uniqueCards[i].flip) {
                skipped += 1;
            } else if (this.uniqueCards[i].flip) {
                setTimeout(()=>{this.revealOne(i,true);},(i-skipped)*400);
            }
        }
        this.revealed = true;
        this.revealed_all = true;
    }
    revealOne(i,scroll=false) {
        if(this.uniqueCards && this.uniqueCards[i]) {
            this.uniqueCards[i].flip = false;
            const element = document.querySelector("#"+this.uniqueCards[i].cid);
            if (scroll) element.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }
    async selectAvailableRarity(n, type) {
        this.rarity = n;
        this.selected.forEach(c => { c.checked = false; });
        this.selected = [];
        this.available = await this.helper.loadSFCardsByRarityType(n, type);
        //console.log('available card', this.available);
        //if (type === 'equipment') await this.helper
        this.available.sort((a,b) => { return a.name.localeCompare(b.name) || b.combined - a.combined; });
        this.maxPossible();
    }
    reveal_one(c) {

    }

    card_loaded(i) {
        if(this.uniqueCards.length == i+1) this.stagger_openings();
    }
    stagger_openings(i=0) {
        if(this.uniqueCards) {
            setTimeout(()=>{
                this.load_stagger[i]=true;
                if(i<this.uniqueCards.length) {
                    this.stagger_openings(i+1);
                }
            }, 100);    
        }
    }
}
export class Filter {
  constructor(
    public search?: string,
    public sockets?: any,
    public equipped?: any,
    public rarity?: any,
    public ability?: any,
    public type?: any,
    public slot?: any,
  ) {}
} 