import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ability'
})
export class AbilityPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if(args == -1 || args == 'All') return value;
    let values = [];
    for (var i = 0; i < value.length; i++) {
      if (value[i].abilities.includes(args)) values.push(value[i]);
    }
    return values;
  }
}