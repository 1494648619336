import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HelperService } from '../../_services/helper.service';
import { fade, flip_up, pointer, boxlarge, boxsmaller, field_error } from '../../app.animations';
@Component({
  selector: 'introduction',
  animations: [fade, flip_up, pointer, boxlarge, boxsmaller, field_error],
  templateUrl: './introduction.component.html',
  styleUrls: [ './introduction.component.less']
})
export class IntroductionComponent implements OnInit {
  
  news: any;
  @Input('show') show = false;
  
  constructor (public helper: HelperService) { }
  
  ngOnInit() {
    this.getNews();
  }

  async getNews() {
    let viewed = localStorage.getItem('forge:news');
    if (!this.helper.config) {
      let temp = await this.helper.getConfig();
      if (temp) this.news = temp.news;
    } else this.news = this.helper.config.news;

    //console.log(viewed, this.news[0].date);

    if (!viewed || viewed !== this.news[0].date) { 
      this.show = true;
    } else this.show = false;
  }
  
  close() {
    let mark = this.news[0].date;
    if (mark) {
      localStorage.setItem('forge:news', this.news[0].date);
    }
    this.show = false;
  }
}