<section class="forgemodal" [@fade]="show">
    <div class="boxwrap" [@flip_up]="show">
		<h1>
			{{topTitle}}
			<span *ngIf="!showHeroEvent && 1>1"><button class="button green" (click)="changeView('heroEvent')">Hero Event (ENDED)</button></span>
			<span *ngIf="!showElectrumBurnEvent"><button class="button green" (click)="changeView('electrumBurnEvent')">Electrum Mini Event</button></span>
			<span *ngIf="!showDaily"><button class="button green" (click)="changeView('daily')">30 Day Rewards</button></span>
		</h1>
		<div *ngIf="showElectrumBurnEvent">
			<h5>This event is one in a series of rotating mini events!</h5>
			<p>
				During this mini event, when burning Gems OR Runes for Electrum, you will recieve 5x the amount of Electrum<br/>
				Please join our <a href="https://discord.gg/umMfWvu2v7" target="_blank">discord</a> community!
			</p>
			
			<div>
				<h5>This is a non competative event that will run until the next mini event is ready!</h5>
			</div>
		</div>
		<div *ngIf="showHeroEvent && 1>1">
			<h5>This event has ended, event details will remain for a short period to allow users to view their results.</h5>
			<p>
				@denvic won a PLOT but decided to receive a Nartors Ring instead, so we did a redraw for the PLOT denvic didn't take!<br/>
				All CRATEs and rings were distributed following the draw, if you won a plot, please coordinate with @BlueGoose on
				<a href="https://discord.gg/umMfWvu2v7" target="_blank">discord!</a>
			</p>
			
			<div>
				<h5>The winners!!!</h5>
				<ul>
					<h5>PLOTs</h5>
					<li>jovak</li>
					<li>denvic - decided to take a Nartors Ring</li>
					<li>bruce1495</li>
					<li>xensational - redraw winner of denvics plot</li>
				</ul>
				<ul>
					<h5>Nartors Rings</h5>
					<li>dmitro-777ch</li>
					<li>sckrpnch</li>
					<li>kitsuki</li>
					<li>silver-stone</li>
					<li>sow1495</li>
				</ul>
				<ul>
					<h5>CRATEs</h5>
					<li>vyrusvv x2</li>
					<li>sow1495 x3</li>
					<li>sckrpnch x5</li>
					<li>enira x1</li>
					<li>sirsmokesalot96 x1</li>
					<li>denvic x1</li>
					<li>cedroc x2</li>
					<li>supermani x1</li>
					<li>konoha x2</li>
					<li>gelo05 x4</li>
					<li>xensational x4</li>
					<li>krizalid x3</li>
					<li>bruce1495 x5</li>
					<li>silver-stone x5</li>
					<li>olygator x1</li>
					<li>moenke x2</li>
					<li>mrbobhead x1</li>
					<li>rjaden x1</li>
					<li>penass x1</li>
					<li>kirroyale x1</li>
					<li>friddu x2</li>
					<li>hamerad x1</li>
					<li>kureppi x1</li>
				</ul>
			</div>
		</div>
		<div *ngIf="user && user.settings.daily && showDaily">
			<div class="shop_items" *ngFor="let asset of user.settings.daily.rewards; let i = index;">
				<div class="itemwrapper">
					<div class="shop_item">
						<div class="shopItem">
							<div class="image_wrapper">
								<div class="title">
								Day {{i+1}} {{asset.claimed ? ' - Claimed!' : ''}}
								</div>
							</div>
							<div class="image_wrapper">
								<div class="imagewrapper">
									<img *ngIf="asset.name=='Electrum'" class="electrum" src="https://splinterforge.s3.us-east-2.amazonaws.com/Electrum.png">
									<img *ngIf="asset.name=='XP'" class="electrum" src="https://splinterforge.s3.us-east-2.amazonaws.com/XP2.png">
									<img *ngIf="asset.name=='Evolution Essence'" class="electrum" src="https://splinterforge.s3.us-east-2.amazonaws.com/Essence.png">
									<img *ngIf="asset.name=='Relic Fragment'" class="electrum" src="https://splinterforge.s3.us-east-2.amazonaws.com/Frag.png">
									<img *ngIf="asset.name=='CRATE'" class="alpha_pack" src="https://splinterforge.s3.us-east-2.amazonaws.com/packs/crate.png">
									<img *ngIf="asset.name=='Stamina'" class="alpha_pack" src="https://splinterforge.s3.us-east-2.amazonaws.com/Stamina.png" width="160">
								</div>
							</div>
							<div class="image_wrapper">
								<div [attr.class]="'title '+(asset.name.length>17?'lg':'')">
								{{asset.name}}
								</div>
							</div>
							<button [disabled]="asset.ttg !== 'Claim'" class="button green inventory_button" *ngIf="asset.it" (click)="claimDaily()">
								{{asset.ttg ? asset.ttg : Claim}}
							</button>
							<span class="owned">
								<span>Qty:&nbsp;</span>
								<span class="number_owned">{{asset.qty|number}}</span>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="closewrapper" (click)="close()">
			<i class="fa-solid fa-x"></i>
		</div>
	</div>
	<div class="backdrop" [@pointer]="show" (click)="close()"></div>
</section>