import { Component, OnInit, OnDestroy, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { AppComponent } from '../app.component'
import { HelperService } from '@app/_services/helper.service';
import { SlideDonationDown } from '../slcards/slcards.animations';
import { ActivatedRoute } from '@angular/router';
declare var jQuery:any; declare var $ :any; declare var MasterSlider :any;
@Component({ 
    selector: 'home',
    animations: [SlideDonationDown],
    templateUrl: 'home.component.html', 
    styleUrls: ['./home.component.styl','./clouds.scss']
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
    @Output('login') login_output = new EventEmitter();
    detail_id: any;
    foil: any;
    constructor(
        private route: ActivatedRoute,
        private app: AppComponent,
        private helper: HelperService
    ) {
        this.detail_id = this.route.snapshot.queryParams.detail_id;
        this.foil = this.route.snapshot.queryParams.foil;
        if (this.detail_id && this.detail_id > 0) this.helper.detail_id = this.detail_id;
        this.helper.detail_foil = this.foil;
    }
    slide = 0;
    slide_cards = 0;
    loaded: boolean;
    interval: any;
    ngOnDestroy() {
        clearInterval(this.interval);
    }
    ngAfterViewInit() {
        setTimeout(()=> {this.loaded=true;},1500);
    }
    ngOnInit() {
    }
    donate() {
        this.app.showDonate=true
    }
    login() { this.app.openLoginModal(); }
}