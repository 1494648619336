import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DataService {

    private userSubject = new Subject<any>();
    private slCollectionSubject = new Subject<any>();
    private cardDetailsSubject = new Subject<any>();
    private unusableSubject = new Subject<any>();
    private bossSubject = new Subject<any>();
    private resultSubject = new Subject<any>();
    private scrollingSubject = new Subject<any>();
    private logSpeedSubject = new Subject<number>();
    private configSubject = new Subject<any>();
    private shopItemsSub = new Subject<any>();
    private showRewardsSub = new Subject<Boolean>();
    private replayBossSub = new Subject<any>();
    private bossViewSub = new Subject<any>();
    private oldBossesSub = new Subject<any>();
    private loadingSub = new Subject<Boolean>();
    private myCardsSub = new Subject<any>();
    private pricesSub = new Subject<any>();
    private fightScreen = new Subject<any>();
    private mySFCardsSub = new Subject<any>();
    private viewCardSub = new Subject<boolean>();
    private snackbarMessageSub = new Subject<string>();

    constructor() { }

    setSnackbarMessage(m) {
        this.snackbarMessageSub.next(m);
    }

    onSnackbarMessageChange(): Observable<string> {
        return this.snackbarMessageSub.asObservable();
    }

    setViewCard(b) {
        this.viewCardSub.next(b);
    }

    onViewCardChange(): Observable<boolean> {
        return this.viewCardSub.asObservable();
    }

    setPrices(p) {
        this.pricesSub.next(p);
    }

    onPricesChange(): Observable<any> {
        return this.pricesSub.asObservable();
    }

    setMySFCards(c) {
        this.mySFCardsSub.next(c);
    }

    onMySFCardsChange(): Observable<any> {
        return this.mySFCardsSub.asObservable();
    }

    setMyCards(c) {
        this.myCardsSub.next(c);
    }

    onMyCardsChange(): Observable<any> {
        return this.myCardsSub.asObservable();
    }

    setLoading(b) {
        this.loadingSub.next(b);
    }

    onLoadingChange(): Observable<Boolean> {
        return this.loadingSub.asObservable();
    }

    setOldBosses(o) {
        this.oldBossesSub.next(o);
    }

    onOldBossesChange(): Observable<any> {
        return this.oldBossesSub.asObservable();
    }

    setBossView(b) {
        this.bossViewSub.next(b);
    }

    onBossViewChange(): Observable<any> {
        return this.bossViewSub.asObservable();
    }

    setReplayBoss(b) {
        this.replayBossSub.next(b);
    }

    onReplayBossChange(): Observable<any> {
        return this.replayBossSub.asObservable();
    }

    setShowRewards(b: boolean) {
        this.showRewardsSub.next(b);
    }

    onShowRewardsChange(): Observable<Boolean> {
        return this.showRewardsSub.asObservable();
    }

    setShopItems(i) {
        this.shopItemsSub.next(i);
    }

    onShopItemsChange(): Observable<any> {
        return this.shopItemsSub.asObservable();
    }

    setConfig(c) {
        this.configSubject.next(c);
    }

    onConfigChange(): Observable<any> {
        return this.configSubject.asObservable();
    }

    setUnusable(u: any) {
        this.unusableSubject.next(u);
    }

    onUnusableChange(): Observable<any> {
        return this.unusableSubject.asObservable();
    }

    setLogSpeed(ls: number) {
        this.logSpeedSubject.next(ls);
    }

    onLogSpeedChange(): Observable<number> {
        return this.logSpeedSubject.asObservable();
    }

    setScrolling(s: any) {
        this.scrollingSubject.next(s);
    }

    onScrollingChange(): Observable<any> {
        return this.scrollingSubject.asObservable();
    }

    setResult(r: any) {
        this.resultSubject.next(r);
    }

    onResultChange(): Observable<any> {
        return this.resultSubject.asObservable();
    }

    setUser(u: any) {
        this.userSubject.next(u);
    }

    onUserChange(): Observable<any> {
        return this.userSubject.asObservable();
    }

    setSLCollection(c: any) {
        this.slCollectionSubject.next(c);
    }

    onCollectionChange(): Observable<any> {
        return this.slCollectionSubject.asObservable();
    }

    setCardDetails(c: any) {
        this.cardDetailsSubject.next(c);
    }

    onCardDetailsChange(): Observable<any> {
        return this.cardDetailsSubject.asObservable();
    }

    setBoss(b: any) {
        this.bossSubject.next(b);
    }

    onBossChange(): Observable<any> {
        return this.bossSubject.asObservable();
    }

    setFightScreen(b: any) {
        this.fightScreen.next(b);
    }

    onFightScreen(): Observable<any> {
        return this.fightScreen.asObservable();
    }
}